import { DashboardSnapshot, DashboardPreference, TDeviceMode, FilterPreference, AppWidgetState, WidgetPosition } from '@ddv/models';

export class LastState {
    dashboards: DashboardSnapshot[] = [];
    selectedDashboardId = '';

    public constructor(init?: Partial<LastState>) {
        Object.assign(this, init);
        this.dashboards = this.dashboards.map((dashboard) => new DashboardSnapshot(dashboard));
    }
}

export class WidgetFiltersAndLocationOnBoards {
    widgetOnBoardId = 0;
    widgetId = 0;
    width = 0;
    height = 0;
    left = 0;
    top = 0;
    maximized = false;
    widgetPosition: WidgetPosition | undefined;
    filters: string | undefined;

    public constructor(widget?: AppWidgetState) {
        if (!widget) {
            return;
        }

        this.widgetOnBoardId = widget.id ?? this.widgetOnBoardId;
        this.widgetId = widget.coreWidgetId ?? this.widgetId;
        this.width = widget.width ?? this.width;
        this.height = widget.height ?? this.height;
        this.left = widget.left ?? this.left;
        this.top = widget.top ?? this.top;
        this.maximized = false;
        this.widgetPosition = widget.widgetPosition;
        this.filters = widget.widgetFilters ? JSON.stringify(this.fromIFilterPreference(widget.widgetFilters)) : undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromIFilterPreference(widgetFilters: FilterPreference): any {
        return {
            funds: widgetFilters.funds?.map((f) => ({ fundId: f.fundId })),
            clients: widgetFilters.clients,
            startDate: widgetFilters.startDate,
            endDate: widgetFilters.endDate,
            activeDate: widgetFilters.activeDate,
            filters: widgetFilters.filters,
        };
    }
}

export class SaveDashboard {
    id: string | number;
    widgetFiltersAndLocationOnBoards: WidgetFiltersAndLocationOnBoards[];
    deviceMode: TDeviceMode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParams: any;

    public constructor(id: string | number, deviceMode: TDeviceMode, queryParams: DashboardPreference, widgets: AppWidgetState[]) {
        this.id = id;
        this.deviceMode = deviceMode;
        this.queryParams = queryParams;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.queryParams.funds = this.queryParams.funds.map((f: any) => ({ fundId: f.fundId, fundName: f.fundName }));
        this.widgetFiltersAndLocationOnBoards = widgets.map((widget) => new WidgetFiltersAndLocationOnBoards(widget));
    }
}

