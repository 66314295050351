import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { WidgetDragElementModel } from './widget-drag.model';

export class WidgetDragEventEmitter extends Subject<WidgetDragElementModel> {
    emit(value: WidgetDragElementModel): void {
        super.next(value);
    }
}

@Injectable()
export class WidgetDragPubSubService {
    stream: WidgetDragEventEmitter;

    constructor() {
        this.stream = new WidgetDragEventEmitter();
    }
}
