import { NgModule } from '@angular/core';
import { ReferenceDataModule } from '@ddv/reference-data';

import { ClientDatasetFilterService } from './services/client-dataset-filter.service';
import { QueryParamsService } from './services/query-params.service';

@NgModule({
    imports: [
        ReferenceDataModule,
    ],
    providers: [
        ClientDatasetFilterService,
        QueryParamsService,
    ],
})
export class FiltersModule {}
