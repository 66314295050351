<div class="dropdown-settings" [class.activesmallscreen]="isSmallDevice">
    <div class="user-detail-block">
        <span>{{ fullName }}</span>

        <span>{{ roleName }}</span>
    </div>

    <div class="app-version">
        <label class="option-label text-label">DDV UI version</label>
        <span class="option">{{ versions?.ui }}</span>

        <label class="option-label text-label">DDV MW version</label>
        <span class="option">{{ versions?.mw }}</span>

        <label class="option-label text-label">DDV API version</label>
        <span class="option">{{ versions?.api }}</span>
    </div>

    <div class="auto-dismiss-error">
        <label class="option-label">Auto Dismiss Alerts</label>

        <cc-option-toggle
            *ngIf="userPreference"
            id="auto-dismiss-error"
            [(value)]="userPreference.autoDismissErrors"
            [labels]="['Yes', 'No']"
            (valueChange)="updateErrorAcknowledgementPreference($event)">
        </cc-option-toggle>
    </div>

    <div class="fund-names-source" *ngIf="hasBothFundAvailability">
        <label class="option-label">Fund Names</label>

        <cc-option-toggle
            id="fund-names-source"
            [(value)]="areHIFunds"
            [labels]="['Investors', 'HSMain']"
            (valueChange)="updateFundNamesSourcePreference($event)">
        </cc-option-toggle>
    </div>

    <div class="default-dates-funds">
        <div class="errormessage" *ngIf="errorMessage">{{ errorMessage }}</div>

        <label class="option-label">Default From Date</label>
        <div class="option">
            <app-dropdown
                [options]="fuzzyDatesFromOptions"
                [(selected)]="selectedDefaultFromDate"
                (selectedChange)="validateDateRangeAndSave()">
            </app-dropdown>
        </div>

        <label class="option-label">Default To Date</label>
        <div class="option">
            <app-dropdown
                [options]="fuzzyDatesToOptions"
                [(selected)]="selectedDefaultToDate"
                (selectedChange)="validateDateRangeAndSave()">
            </app-dropdown>
        </div>

        <label class="option-label">{{ isMultiClient ? 'Default Clients' : 'Default Funds' }}</label>
        <div class="option funds-dropdown">
            <span>{{ isMultiClient ? userSelectedClients.length : userSelectedFunds.length }} selected</span>

            <div *ngIf="!isMultiClient" class="dropdown-wrapper">
                <span
                    class="icon-filterarrow-close"
                    (click)="toggleDashboardFunds()"
                    [class.smalldevicehight]="isSmallDevice"
                    zommeddevice="landscape">
                </span>

                <div class="dropdown right-position smalld" *ngIf="showDashboardFunds">
                    <app-funds-form
                        [selectedFunds]="userSelectedFunds"
                        (fundsApply)="applySelectedFunds($event)">
                    </app-funds-form>
                </div>
            </div>

            <div *ngIf="isMultiClient" class="dropdown-wrapper">
                <span
                    class="icon-filterarrow-close"
                    (click)="toggleDashboardClients()"
                    [class.smalldevicehight]="isSmallDevice"
                    zommeddevice="landscape">
                </span>

                <div class="dropdown right-position smalld" *ngIf="showDashboardClients">
                    <app-clients-form
                        [selectedClients]="userSelectedClients"
                        (clientsApply)="applySelectedClients($event)">
                    </app-clients-form>
                </div>
            </div>
        </div>
    </div>

    <div
        class="one-line-option manage-datasets"
        *ngIf="showManageDatasetsMenu"
        [routerLink]="'../../../dataset-definitions/edit/none'"
        [queryParams]="{from: 'preferences'}"
        (click)="userPreferencesClosed.emit()"
    >
        Manage Datasets
    </div>
</div>
