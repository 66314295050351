import { TemplateRef } from '@angular/core';

import { GLOBAL_SAVE_MESSAGE } from '../constants/dashboard-constants';
import { ConfirmDialogOptions } from './confirmation-dialog-options';

export function getGlobalSaveDialogOptions(msg?: TemplateRef<string>): ConfirmDialogOptions {
    const message = msg ?? GLOBAL_SAVE_MESSAGE;
    const confirmButtonText = 'Yes';
    const denyButtonText = 'No';
    return { message, confirmButtonText, denyButtonText };
}
