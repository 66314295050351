import { Component, OnInit } from '@angular/core';
import { DropdownOption, ModalDialogActive } from '@ddv/common-components';
import {
    AGG_TYPES_BY_SECTION_AND_DISPLAY,
    AggType,
    ColumnNegativeValues,
    LabelValuePair,
    MetadataFormatUtils,
    NumberFormat,
    NumberUnit,
    SummaryColumnConfig,
} from '@ddv/models';
import { deepClone, mapStringsToLabelValuePairs } from '@ddv/utils';

const AGGREGATION_TYPES = [
    'Count',
    'Count Excluding Zeros',
    'Count Distinct',
    'Count Distinct Excluding Zeros',
    'First',
    'Last',
    'Calc on Count - Percentage',
    'Calc on Count - Fraction',
    'Calc on Count Distinct - Percentage',
    'Calc on Dount Distinct - Franction',
];

@Component({
    selector: 'app-summary-column-formatting',
    templateUrl: './column-formatting-summary.component.html',
    styleUrls: ['../column-formatting-grid/column-formatting-grid.component.scss'],
})
export class ColumnFormattingSummaryComponent implements OnInit {
    parentData: {
        displayType: string;
        isAttribute: boolean;
        columnName: string;
        columnConfiguration: SummaryColumnConfig;
    } | undefined;
    summaryConfig: SummaryColumnConfig | undefined;

    aggTypes: DropdownOption[] | undefined;
    selectedAggType: DropdownOption | undefined;

    formats: DropdownOption[] | undefined;
    selectedFormat: DropdownOption | undefined;

    negativeValues: DropdownOption[] = [];
    selectedNegativeValue: DropdownOption | undefined;

    numberUnits: DropdownOption[] = [];
    selectedNumberUnits: DropdownOption | undefined;

    isApplyDisabled = false;
    showFormatter = true;

    constructor(public modalDialogActive: ModalDialogActive) { }

    ngOnInit(): void {
        if (!this.parentData) {
            return console.error('Cannot init without parentData');
        }

        this.aggTypes = AGG_TYPES_BY_SECTION_AND_DISPLAY.summary[this.parentData.displayType].map((t: LabelValuePair<string>) => {
            return { text: t.label, key: t.value, value: t.value };
        });
        this.formats = MetadataFormatUtils.getNumberFormats().map((t) => {
            return { text: t.label, key: t.value, value: t.value };
        });
        this.negativeValues = MetadataFormatUtils.getNegativeValues().map((t) => {
            return { text: t.label, key: t.value, value: t.value, css: t.value.startsWith('red') ? 'redcolor' : '' };
        });
        this.numberUnits = MetadataFormatUtils.getNumberNotations().map((t) => {
            return { text: t.label, key: t.value, value: t.value };
        });
        this.summaryConfig = deepClone(this.parentData.columnConfiguration);
        this.updateFormatterVisibility(this.summaryConfig.aggregationType);

        this.selectedAggType = this.aggTypes?.find((t) => t.key === this.summaryConfig?.aggregationType);
        this.selectedFormat = this.formats.find((f) => f.key === this.summaryConfig?.numberFormat);
        this.selectedNegativeValue = this.negativeValues.find((v) => v.key === this.summaryConfig?.negativeValue);
        this.selectedNumberUnits = this.numberUnits.find((u) => u.key === this.summaryConfig?.numberUnits);
    }

    onAggregationTypeChange(aggregationType: AggType): void {
        if (this.summaryConfig) {
            this.summaryConfig.aggregationType = aggregationType;
        }
        this.updateFormatterVisibility(aggregationType);
    }

    onSelectedFormatChange(format: NumberFormat): void {
        if (this.summaryConfig) {
            this.summaryConfig.numberFormat = format;
        }
    }

    onSelectedNegativeFormatChange(negativeFormat: ColumnNegativeValues): void {
        if (this.summaryConfig) {
            this.summaryConfig.negativeValue = negativeFormat;
        }
    }

    onSelectedNumberUnitsChange(numberUnits: NumberUnit): void {
        if (this.summaryConfig) {
            this.summaryConfig.numberUnits = numberUnits;
        }
    }

    private updateFormatterVisibility(aggregationType: AggType): void {
        const aggregationTypesWithNoFormatter = mapStringsToLabelValuePairs<AggType>(AGGREGATION_TYPES);
        this.showFormatter = !aggregationTypesWithNoFormatter.some((agg) => agg.value === aggregationType);
    }
}
