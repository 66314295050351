import { LicenseManager } from '@ag-grid-enterprise/core';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DataGridUtilities {
    private static themeClass: string | undefined;

    static getThemeClass(): string | undefined {
        return DataGridUtilities.themeClass;
    }

    static setThemeClass(themeClass: string): void {
        DataGridUtilities.themeClass = themeClass;
    }

    static registerLicenseKey(licenceKey: string): void {
        LicenseManager.setLicenseKey(licenceKey);
    }
}

