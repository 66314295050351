import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GlobalIconComponent } from './global-icon/global-icon.component';
import { StackedAreaChartIconComponent } from './stacked-area-chart-icon/stacked-area-chart-icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [StackedAreaChartIconComponent, GlobalIconComponent],
    exports: [StackedAreaChartIconComponent, GlobalIconComponent],
})
export class LibSvgIconsModule {}
