import { DatasetFetchRuntimeParams, Query } from '../dataset-definitions/dataset-definition-details';
import { dummyKey } from '../datasets/dataset-fetch-key';
import { Fund } from '../fund';
import { QueryType, QueryTypeName } from './query-type';

export type RuntimeParameterType = 'string' | 'fund-list' | 'date' | 'date-range' | 'boolean';

export interface RuntimeParameter {
    name: string;
    displayName: string;
    type: RuntimeParameterType;
    required: boolean;
}

export interface NamedQueryInit {
    readonly template: string;
    readonly periodType: QueryPeriodType;
    readonly type: QueryType;
    readonly crosstalkOptions?: CrosstalkOptions;
    readonly clientAllocationIsLocked?: boolean;
    readonly isOptIn: boolean;
    readonly clientCodes: string[];
    readonly name: string;
    readonly tags: string;
    id: string;
    createdDate: Date;
    createdBy: string;
    updatedDate?: Date;
    updatedBy?: string;
    datasetDefinitionId?: string;
    parameters?: RuntimeParameter[];
}

export class NamedQuery {
    id = '';
    name = '';
    tags = '';
    template = '';
    periodType: QueryPeriodType;
    type: QueryType;
    isOptIn = false;
    clientCodes = [];
    createdDate?: Date;
    createdBy?: string;
    updatedDate?: Date | null;
    updatedBy?: string | null;
    crosstalkOptions?: CrosstalkOptions;
    datasetDefinitionId?: string;
    parameters?: RuntimeParameter[];

    constructor(init: NamedQueryInit, config: MetadataAndQueryURLsConfig) {
        Object.assign(this, init);

        this.periodType = init.periodType;
        this.type = this.getQueryType(init.type, config);
    }

    isQueryTemplateStacked(): boolean {
        return this.template.indexOf('@@UNION@@') !== -1;
    }

    getRootDir(): string {
        const dummyParams = new DatasetFetchRuntimeParams(dummyKey(), '', { from: '', to: '' }, []);
        return this.populateQueryTemplate(dummyParams).rootFMPath ?? 'NO ROOT FM PATH';
    }

    populateQueryTemplate(params: DatasetFetchRuntimeParams, fundOptions?: Fund[]): Query {
        this.nullSafeReplace('timeseriesRange.from', params.dateRange.from);
        this.nullSafeReplace('timeseriesRange.to', params.dateRange.to);
        this.nullSafeReplace('timeseriesRange.granularity', params.timeseriesGranularity?.toLowerCase());
        this.nullSafeReplace('includeLinks', params.includeLinks);
        this.nullSafeReplace('fundCodeList', JSON.stringify(params.convertFundCodesToDescriptors()));

        this.template = this.template.split('@@UNION@@')[0];
        const query = JSON.parse(this.template) as Query;

        if (query?.timeseriesRange?.granularity) {
            query.timeseriesRange.granularity = query.timeseriesRange.granularity.toLowerCase();
        }

        if (query.includeFunds?.length && fundOptions?.length) {
            query.includeFunds = query.includeFunds.filter((f) => this.shouldFundBeIncluded(fundOptions, f.code, this.type.name));
        }

        return query;
    }

    populateStackedQueryTemplate(params: DatasetFetchRuntimeParams, fundOptions?: Fund[]): Query[] {
        this.checkForNullSafeReplace('timeseriesRange.from', params.dateRange.from);
        this.checkForNullSafeReplace('timeseriesRange.to', params.dateRange.to);
        this.checkForNullSafeReplace('timeseriesRange.granularity', params.timeseriesGranularity?.toLowerCase());
        this.checkForNullSafeReplace('includeLinks', params.includeLinks);
        this.checkForNullSafeReplace('fundCodeList', JSON.stringify(params.convertFundCodesToDescriptors()));

        const queryTemplateArray = this.template.split('@@UNION@@');
        const queries: Query[] = [];

        queryTemplateArray.forEach((queryTemplate) => {
            const query = JSON.parse(queryTemplate) as Query;

            if (query?.timeseriesRange?.granularity) {
                query.timeseriesRange.granularity = query.timeseriesRange.granularity.toLowerCase();
            }

            if (query.includeFunds?.length && fundOptions?.length) {
                query.includeFunds = query.includeFunds.filter((f) => this.shouldFundBeIncluded(fundOptions, f.code, this.type.name));
            }

            queries.push(query);
        });

        return queries;
    }

    private getQueryEndpoint(replaceHostInURLs: boolean, apiLocation: string): string {
        const dataEndpoint = this.type.dataEndpoint;
        if (replaceHostInURLs) { // since we are getting a URL to Trebek Edge for localhost, we need to adjust
            const pathname = new URL(dataEndpoint).pathname;
            const dataEndpointPath = pathname.substring(pathname.indexOf('rest'), pathname.length);
            return `${apiLocation}/public-api/${dataEndpointPath}`;
        }
        return dataEndpoint;
    }

    private getMetadataEndpoint(replaceHostInURLs: boolean, apiLocation: string): string {
        const metadataEndpoint = this.type.metadataEndpoint;
        if (replaceHostInURLs) { // since we are getting a URL to NQS RND for localhost, we need to adjust
            const metadataEndpointPath = new URL(metadataEndpoint).pathname;
            return `${apiLocation}${metadataEndpointPath}`;
        }
        return metadataEndpoint;
    }

    private getQueryType(queryType: QueryType, config: MetadataAndQueryURLsConfig): QueryType {
        return {
            ...queryType,
            dataEndpoint: this.getQueryEndpoint(config.replaceHostInURLs, config.trebekApiLocation),
            metadataEndpoint: this.getMetadataEndpoint(config.replaceHostInURLs, config.nqsApiLocation),
        };
    }

    private checkForNullSafeReplace(key: string, value?: string | null | boolean): void {
        if (value == null || value === 'null') {
            return;
        }

        while (this.template.indexOf(`@@${key}@@`) !== -1) {
            this.nullSafeReplace(key, value);
        }
    }

    private shouldFundBeIncluded(funds: Fund[], fundCode: string, queryType: string): boolean {
        const fund = funds.find((f) => f.fundId === fundCode);
        return !!fund && (queryType === QueryTypeName.ECDI ? fund.isHiOrBoth : fund.isHsMainOrBoth);
    }

    private nullSafeReplace(key: string, value?: string | null | boolean): void {
        if (value == null || value === 'null') {
            return;
        }

        this.template = this.template.replace(`@@${key}@@`, `${value}`);
    }
}

export interface MetadataAndQueryURLsConfig {
    replaceHostInURLs: boolean;
    trebekApiLocation: string;
    nqsApiLocation: string;
}

interface CrosstalkOptions {
    readonly conversableType: string;
    readonly showDuplicateRowsWarning: boolean;
}

type QueryPeriodType = 'No Calendar' | 'Point in Time' | 'Range' | 'Range with Active Date';
