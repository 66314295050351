import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DatasetFetchKey } from '@ddv/models';

import { DatasetManagerService } from './dataset-manager.service';

export const datasetRefreshIntervalInjectionToken = new InjectionToken('datasetRefreshInterval');

@Injectable()
export class DatasetRefresherService {
    private readonly refreshInterval: number;
    private readonly refreshIntervals: Map<DatasetFetchKey, number> = new Map();

    constructor(
        private readonly datasetManagerService: DatasetManagerService,
        @Inject(datasetRefreshIntervalInjectionToken) @Optional() refreshInterval: number | null,
    ) {
        this.refreshInterval = refreshInterval ?? 20000;
    }

    start(clientCode: string, datasetKey: DatasetFetchKey): void {
        if (this.refreshIntervals.has(datasetKey)) {
            return;
        }

        this.refreshIntervals.set(
            datasetKey,
            setInterval(
                () => this.datasetManagerService.refreshDataset(clientCode, datasetKey),
                this.refreshInterval,
            ) as unknown as number);
    }

    stop(datasetKey: DatasetFetchKey): void {
        if (!this.refreshIntervals.has(datasetKey)) {
            return;
        }

        clearInterval(this.refreshIntervals.get(datasetKey));
        this.refreshIntervals.delete(datasetKey);
    }
}
