import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    private readonly isLoadingSrc = new Subject<boolean>();

    isLoading(): Observable<boolean> {
        return this.isLoadingSrc.asObservable();
    }

    showLoader(): void {
        this.isLoadingSrc.next(true);
    }

    hideLoader(): void {
        this.isLoadingSrc.next(false);
    }

    showButtonLoader(): void {
        this.isLoadingSrc.next(true);
    }

    hideButtonLoader(): void {
        this.isLoadingSrc.next(false);
    }
}
