import { TAG_TYPE } from './constants/dashboard-constants';

export interface Tag {
    id: number;
    name: string;
    type: TagType;
}

export type TagType = 'USER' | 'SHARED';

export function combineUserAndSharedTags(userTags: Tag[], sharedTags: Tag[]): Tag[] {
    const tags = userTags.map((tag) => ({
        id: tag.id,
        name: tag.name,
        type: TAG_TYPE.USER,
    }));
    sharedTags.forEach((tag) => {
        tags.push({
            id: tag.id,
            name: tag.name,
            type: TAG_TYPE.SHARED,
        });
    });
    return tags;
}
