import { Injectable } from '@angular/core';
import { DashboardService, appWidgetStateToConfig } from '@ddv/dashboards';
import { QueryParamsService } from '@ddv/filters';
import { setWidgetExtraParamsFromDashboardDetails, ManagerService } from '@ddv/layout';
import { TMode, MODE, DashboardModel, DashboardPreference } from '@ddv/models';

@Injectable()
export class RevertDashboardService {
    constructor(
        private readonly dashboardService: DashboardService,
        private readonly manager: ManagerService,
        private readonly queryParamsService: QueryParamsService,
    ) {}

    revert(dashboardId: string, workspaceMode: TMode, isUpdateQueryParam?: boolean): void {
        this.dashboardService.getDashboard(dashboardId)
            .subscribe((dashboard) => {
                if (this.manager.getCurrentDashboardId() !== dashboard.id?.toString()) {
                    return;
                }

                const workSpace = this.manager.getWorkspace();
                if (!workSpace) {
                    return console.error('cannot revert without a workspace');
                }
                setWidgetExtraParamsFromDashboardDetails(dashboard, workSpace);

                const extraParams: DashboardModel = workSpace.getExtraParameters();

                if (isUpdateQueryParam) {
                    const defaultQueryParams: DashboardPreference = { funds: [], filters: [] };
                    const queryParams = extraParams.dashboardPreferences.find((preference) => {
                        return preference.dashboardPreferenceMode === MODE.EDIT_WORKSPACE;
                    });

                    this.queryParamsService.dispatchNewQueryParams(queryParams || defaultQueryParams);
                }

                this.manager.createWidgets(appWidgetStateToConfig(extraParams.widgets, workspaceMode));
            });
    }
}
