import { BarChartData } from '../../models/chart-data';

// This comes from https://hedgeserv.atlassian.net/browse/DDV-6113
// the intent was to allocate the same amount of space to a label for any size from 0 - 400
// after that, we want to allocate 1/3 of the extra space to the labels.  No one knows why 9 does that, but it seems to
export function maxNumberOfSlicerCharactersToDisplayForAGivenChartWidth(charCount: number, width: number): number {
    return charCount + (width > 400 ? Math.floor((width - 400) / 9) : 0);
}

export function copyAndConcat(data?: BarChartData[], compareData?: BarChartData[]): BarChartData[] {
    return [...(data ?? []), ...(compareData ?? [])];
}

// The data and compare data can both be "sparse" with respect to each other.  Meaning, a given slicer
// value may be only in the data, only in the compareData or in both.  This function gets the distinct set of values
export function distinctSlicerValues(data: BarChartData[], compareData: BarChartData[] | undefined): string[] {
    const tempDataSource = copyAndConcat(data, compareData);
    return Array.from(tempDataSource.reduce((set, bar) => set.add(bar.key.value), new Set<string>()));
}

// This has long been deprecated on String
export function substr(s: string, start: number, numCharacters: number): string {
    return s.substring(start, Math.min(s.length, start + numCharacters));
}

export const tickOffset = 75;

// When the range of our data is really small (like when you're doing a bar on the count of something)
// simply filling the space evenly with ticks looks noisy, so this function will limit the number of
// ticks displayed in that case.  Note: this same function is used for the gridlines so they end up with the same count
export function deriveNumberOfTicksToPutOnTheAxis(
    data: BarChartData[],
    valueOrKey: 'value' | 'key',
    axisPosition: string | undefined,
    widthForBars: number,
    heightForBars: number,
): number {
    const dimension = axisPosition === 'bottom' ? widthForBars : heightForBars;
    const defaultNumberOfTicksForSpace = dimension / tickOffset;
    if (valueOrKey === 'key') {
        return Math.max(defaultNumberOfTicksForSpace, 2);
    }

    const maxValue = data.reduce((max, d) => Math.max(max, Number(d.value.value)), 0);
    if (maxValue - 1 < defaultNumberOfTicksForSpace) {
        return Math.max(maxValue - 1, 2);
    }

    return Math.max(defaultNumberOfTicksForSpace, 2);
}
