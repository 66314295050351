export function camelCaseString(text: string): string {
    const matches = text.match(/[a-z]+|\d+/gi);
    if (matches == null) {
        return '';
    }

    return matches.map((match, index) => {
        let formattedMatch = match.toLocaleLowerCase();
        if (index !== 0) {
            formattedMatch = formattedMatch
                .split('')
                .map((char, charIndex) => charIndex === 0 ? char.toLocaleUpperCase() : char)
                .join('');
        }
        return formattedMatch;
    }).join('');
}

export function mapStringsToLabelValuePairs<T extends string>(names: string[]): { label: string, value: T }[] {
    return names.map((name) => ({ label: name, value: camelCaseString(name) as T }));
}

export function getValidString(stringToValidate: string): string {
    const validString = stringToValidate
        .replace(/\\/g, '')
        .replace(/""/g, '')
        .replace('"[', '[')
        .replace(']"', ']');

    return validString.replace('"value":}', '"value":""}');
}

export function initialCapitalization<T>(str: T): string | T {
    return typeof str === 'string' ? str.replace(/^([a-z])/, (s) => s.toLocaleUpperCase()) : str;
}

export function convertStringToAscii(stringToConvert: string): string {
    let tempString = stringToConvert
        .replace(/[ÀÁÂÄÆÃÅĀ]/g, 'A')
        .replace(/[àáâäæãåā]/g, 'a')
        .replace(/[ÈÉÊËĒĖĘ]/g, 'E')
        .replace(/[èéêëēėę]/g, 'e')
        .replace(/[ÎÏÍĪĮÌ]/g, 'I')
        .replace(/[îïíīįì]/g, 'i')
        .replace(/[ÔÖÒÓØŌÕ]/g, 'O')
        .replace(/[ôöòóøōõ]/g, 'o')
        .replace(/Œ/g, 'Oe')
        .replace(/œ/g, 'oe')
        .replace(/[ÛÜÙÚŪ]/g, 'U')
        .replace(/[ûüùúū]/g, 'u')
        .replace(/[ÇĆČ]/g, 'C')
        .replace(/[çćč]/g, 'c')
        .replace(/[ÑŃ]/g, 'N')
        .replace(/[ñń]/g, 'n')
        .replace(/Ł/g, 'L')
        .replace(/ł/g, 'l')
        .replace(/[ŚŠ]/g, 'S')
        .replace(/[śš]/g, 's')
        .replace(/ß/g, 'ss')
        .replace(/[ŽŹŻ]/g, 'Z')
        .replace(/[žźż]/g, 'z')
        .replace(/[‘’‛]/g, '\'')
        .replace(/[“”‟]/g, '"')
        .replace(/[‐‑‒–—―]/g, '-')
        .replace(/А/g, 'A')
        .replace(/а/g, 'a')
        .replace(/Б/g, 'B')
        .replace(/б/g, 'b')
        .replace(/В/g, 'V')
        .replace(/в/g, 'v')
        .replace(/Г/g, 'G')
        .replace(/г/g, 'g')
        .replace(/Д/g, 'D')
        .replace(/д/g, 'd')
        .replace(/[ЕЁЭ]/g, 'E')
        .replace(/[еёэ]/g, 'e')
        .replace(/Ж/g, 'Zh')
        .replace(/ж/g, 'zh')
        .replace(/З/g, 'Z')
        .replace(/з/g, 'z')
        .replace(/И/g, 'I')
        .replace(/и/g, 'i')
        .replace(/Й/g, 'J')
        .replace(/й/g, 'j')
        .replace(/К/g, 'K')
        .replace(/к/g, 'k')
        .replace(/Л/g, 'L')
        .replace(/л/g, 'l')
        .replace(/М/g, 'M')
        .replace(/м/g, 'm')
        .replace(/Н/g, 'N')
        .replace(/н/g, 'n')
        .replace(/О/g, 'O')
        .replace(/о/g, 'o')
        .replace(/П/g, 'P')
        .replace(/п/g, 'p')
        .replace(/Р/g, 'R')
        .replace(/р/g, 'r')
        .replace(/С/g, 'S')
        .replace(/с/g, 's')
        .replace(/Т/g, 'T')
        .replace(/т/g, 't')
        .replace(/У/g, 'U')
        .replace(/у/g, 'u')
        .replace(/Ф/g, 'F')
        .replace(/ф/g, 'f')
        .replace(/Х/g, 'X')
        .replace(/х/g, 'x')
        .replace(/Ц/g, 'C')
        .replace(/ц/g, 'c')
        .replace(/Ч/g, 'Ch')
        .replace(/ч/g, 'ch')
        .replace(/Ш/g, 'Sh')
        .replace(/ш/g, 'sh')
        .replace(/Щ/g, 'Shch')
        .replace(/щ/g, 'shch')
        .replace(/Ъ/g, '\'')
        .replace(/ь/g, '\'')
        .replace(/Ы/g, 'Y')
        .replace(/ы/g, 'y')
        .replace(/Ю/g, 'Ju')
        .replace(/ю/g, 'ju')
        .replace(/Я/g, 'Ja')
        .replace(/я/g, 'ja')
        .replace(/Ъ/g, '"')
        .replace(/ъ/g, '"');
    tempString = tempString.split('').filter((char) => char.charCodeAt(0) < 128).join('');
    return tempString;
}

export function hashValue(str: string): string {
    let hash = 5381;
    let index = str.length;
    while (index) {
        index -= 1;
        // eslint-disable-next-line no-bitwise
        hash = (hash * 33) ^ str.charCodeAt(index);
    }
    // eslint-disable-next-line no-bitwise
    return (hash >>> 0).toString(16);
}
