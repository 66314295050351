import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
    selector: 'app-modal-dialog-overlay',
    template: '',
})
export class ModalDialogOverlayComponent implements OnInit {
    private shown = false;
    private readonly dialogOverlay = true;
    private readonly timeout = 20;

    @HostBinding('class.modal-dialog-overlay') get modalDialogOverlay(): boolean {
        return this.dialogOverlay;
    }

    @HostBinding('class.shown') get fadeIn(): boolean {
        return this.shown;
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.shown = true;
        }, this.timeout);
    }
}
