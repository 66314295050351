import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartsModule } from '@ddv/charts';

import { VisualizationWrapperModule } from '../base/visualization-wrapper/visualization-wrapper.module';
import { DonutChartVisualizationComponent } from './components/donut-chart-visualization/donut-chart-visualization.component';
import {
    HorizontalBarChartVisualizationDataService,
} from './components/horizontal-bar-chart-visualization/horizontal-bar-chart-visualization-data.service';
import {
    HorizontalBarChartVisualizationComponent,
} from './components/horizontal-bar-chart-visualization/horizontal-bar-chart-visualization.component';
import {
    HorizontalStackedBarChartVisualizationComponent,
} from './components/horizontal-stacked-bar-chart-visualization/horizontal-stacked-bar-chart-visualization.component';
import {
    LineChartVisualizationComponent,
} from './components/line-chart-visualization/line-chart-visualization.component';
import {
    PieChartVisualizationComponent,
} from './components/pie-chart-visualization/pie-chart-visualization.component';
import {
    StackedAreaChartVisualizationComponent,
} from './components/stacked-area-chart-visualization/stacked-area-chart-visualization.component';
import {
    VerticalBarChartVisualizationComponent,
} from './components/vertical-bar-chart-visualization/vertical-bar-chart-visualization.component';
import {
    VerticalMirrorBarChartVisualizationComponent,
} from './components/vertical-mirror-bar-chart-visualization/vertical-mirror-bar-chart-visualization.component';
import {
    VerticalStackedBarChartVisualizationComponent,
} from './components/vertical-stacked-bar-chart-visualization/vertical-stacked-bar-chart-visualization.component';

@NgModule({
    imports: [
        ChartsModule,
        CommonModule,
        VisualizationWrapperModule,
    ],
    declarations: [
        DonutChartVisualizationComponent,
        HorizontalBarChartVisualizationComponent,
        HorizontalStackedBarChartVisualizationComponent,
        LineChartVisualizationComponent,
        PieChartVisualizationComponent,
        StackedAreaChartVisualizationComponent,
        VerticalBarChartVisualizationComponent,
        VerticalMirrorBarChartVisualizationComponent,
        VerticalStackedBarChartVisualizationComponent,
    ],
    exports: [
        DonutChartVisualizationComponent,
        HorizontalBarChartVisualizationComponent,
        HorizontalStackedBarChartVisualizationComponent,
        LineChartVisualizationComponent,
        PieChartVisualizationComponent,
        StackedAreaChartVisualizationComponent,
        VerticalBarChartVisualizationComponent,
        VerticalMirrorBarChartVisualizationComponent,
        VerticalStackedBarChartVisualizationComponent,
    ],
    providers: [
        HorizontalBarChartVisualizationDataService,
    ],
})
export class ChartVisualizationsModule {}
