<div class="manage-dsd-wrapper">
    <div class="sidenav">
        <div class="back-button">
            <button class="ghost-btn dashboard-btn" (click)="onReturnToLaunchPointClick()">
                <span class="icon-left-expand"></span>
                {{ returnToLabel }}
            </button>
        </div>

        <app-dsd-selector
            [datasetDefinition]="datasetDefinition"
            (datasetDefinitionSelected)="onDatasetDefinitionSelected($event)"
            [enabled]="!datasetDefinition">
        </app-dsd-selector>

        <ng-container>
            <div class="dsd-widgets" *ngIf="datasetDefinition && datasetDefinition.id">
                <label>Widgets using dataset definition:</label>

                <div *ngIf="!usingWidgetNamesAndVisualizations">
                    Loading...
                </div>

                <div class="widgets-using" *ngIf="usingWidgetNamesAndVisualizations">
                    <span class="no-widgets" *ngIf="!usingWidgetNamesAndVisualizations.length">None</span>

                    <ng-container *ngIf="usingWidgetNamesAndVisualizations.length">
                        <div class="available-widgets" *ngFor="let widgetAndVisualizations of usingWidgetNamesAndVisualizations">
                            <span class="widget-title">{{ widgetAndVisualizations.name }}</span>

                            <ng-container *ngFor="let viz of widgetAndVisualizations.visualizations">
                                <span class="{{viz}}" *ngIf="viz !== 'icon-stacked-area-chart'"></span>

                                <ddv-stacked-area-chart-icon
                                    *ngIf="viz === 'icon-stacked-area-chart'"
                                    class="{{viz}}">
                                </ddv-stacked-area-chart-icon>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="layout-wrapper">
        <header>
            <div class="dashboard-name">
                <ng-container *ngIf="datasetDefinition">
                    <h1>{{ datasetDefinition.name }}</h1>

                    <div class="btn-group">
                        <button
                                id="dataset-definition-save-btn"
                                class="ghost-btn"
                                (click)="onSaveDataSetDefinition()"
                                [disabled]="!canSave">
                            Save
                        </button>

                        <button
                                id="dataset-definition-delete-btn"
                                class="ghost-btn"
                                (click)="onDeleteDataSetDefinition()"
                                [disabled]="!canDelete">
                            Delete
                        </button>

                        <button
                                id="dataset-definition-cancel-btn"
                                class="empty-btn"
                                (click)="confirmCancel()">
                            Cancel
                        </button>
                    </div>
                </ng-container>
            </div>

            <button class="ghost-btn" (click)="onCreateDataSetDefinition()" [disabled]="datasetDefinition">Create New</button>
        </header>

        <div class="dashboard-form" *ngIf="datasetDefinition">
            <div class="input-box">
                <div class="normal-control">
                    <label class="section-label" for="new-dsd-name-input">Name</label>

                    <input
                        type="text"
                        id="new-dsd-name-input"
                        class="formControl"
                        autocomplete="off"
                        [(ngModel)]="datasetDefinition.name" />
                </div>
            </div>

            <div class="input-box">
                <div class="labeled-dropdown dropdown">
                    <label class="section-label">DataType</label>

                    <app-selector
                        id="new-dsd-data-type-selector"
                        displayField="name"
                        [searchable]="true"
                        [options]="dataTypes"
                        [(selected)]="datasetDefinition.dataType"
                        (selectedChange)="dsdSelector!.queryType = $event"
                        (newValueAdded)="onNewDataType($event)"
                        [addBtnVisible]="true">
                    </app-selector>
                </div>
            </div>

            <div class="input-box">
                <div class="labeled-dropdown dropdown">
                    <label class="section-label">Query Type</label>

                    <app-selector
                        id="new-dsd-query-type-selector"
                        displayField="name"
                        [options]="queryTypes"
                        [(selected)]="datasetDefinition.queryType"
                        [addBtnVisible]="true">
                    </app-selector>
                </div>
            </div>

            <div class="input-box">
                <div class="labeled-dropdown dropdown">
                    <label class="section-label">Query Period Type</label>

                    <app-selector
                        id="dsd-query-period-type-selector"
                        displayField="name"
                        [options]="queryPeriodTypes"
                        [(selected)]="datasetDefinition.queryPeriodType">
                    </app-selector>
                </div>
            </div>

            <div class="input-box">
                <div class="labeled-dropdown dropdown">
                    <label class="section-label">CrossTalk Type</label>

                    <span class="icon-export" title="export" (click)="exportCrosstalkTypes()"></span>

                    <label class="toggle-checkbox-label">Show Duplicate Warning</label>
                    <cc-option-toggle
                        class="toggle-checkbox"
                        id="show-duplicate-rows-warning"
                        [(value)]="datasetDefinition.showDuplicateRowsWarning"
                        [labels]="['YES', 'NO']">
                    </cc-option-toggle>

                    <app-selector
                        id="new-dsd-conversable-type-selector"
                        [headers]="['TYPE', 'DISPLAY NAME', 'FIELDS', 'DISPLAY FIELDS', 'CREATED BY']"
                        [options]="commentOptions"
                        [(selected)]="conversableType"
                        (selectedChange)="onConversableTypeSelectedChange($event)"
                        [searchable]="true"
                        [displayField]="'value'"
                        (newValueAdded)="launchCrosstalkTypeModal()"
                        [addBtnVisible]="canAddCrosstalkType">
                    </app-selector>
                </div>
            </div>

            <div class="input-box">
                <div class="textarea">
                    <div class="label-with-button">
                        <label class="section-label" for="new-dsd-query-input">Query</label>

                        <button class="ghost-btn" (click)="onLoadQueryFromSavedView()">Load From Saved View</button>
                    </div>

                    <textarea
                        id="new-dsd-query-input"
                        class="formControl"
                        rows="120"
                        cols="80"
                        [(ngModel)]="datasetDefinition.queryTemplate">
                    </textarea>
                </div>
            </div>

            <div class="input-box">
                <div class="visualisations">
                    <label class="vis-label section-label">Supported Vizualizations</label>

                    <ul class="viz-wrapper">
                        <li class="checkbox" *ngFor="let vis of visualizations">
                            <cc-checkbox
                                [label]="vis.caption"
                                [(value)]="vis.selected">
                            </cc-checkbox>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="input-box">
                <div class="check-radio-section">
                    <div class="settings-checkbox-group">
                        <h4 class="section-label">Settings</h4>

                        <div
                                *ngIf="!datasetDefinition.isGlobal; else locked"
                                class="checkbox">
                            <cc-checkbox
                                [label]="'HS Supported'"
                                [disabled]="!canManageHSSupportedDataset"
                                [(value)]="datasetDefinition.isHSSupported">
                            </cc-checkbox>
                        </div>

                        <ng-template #locked>
                            <div class="global-indicator" *ngIf="datasetDefinition.isGlobal">
                                <ddv-global-icon></ddv-global-icon>
                                Global
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <app-socket></app-socket>
    </div>
</div>
