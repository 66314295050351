import { Injectable } from '@angular/core';
import { MANAGE_WIDGET_WS_ID } from '@ddv/models';

import { CurrentStateService } from './current-state.service';

@Injectable()
export class DirtyFlagService {
    // TODO: public state
    isDirty = false;
    private currentMode: string | null = null;

    constructor(currentStateService: CurrentStateService) {
        currentStateService.dashboardModeAndId$.subscribe(({ mode }) => {
            this.currentMode = mode;
        });
    }

    enterDirtyState(dashboardId: string): void {
        if (dashboardId !== MANAGE_WIDGET_WS_ID && this.currentMode === 'edit') {
            this.isDirty = true;
        }
    }

    exitDirtyState(): void {
        this.isDirty = false;
    }
}
