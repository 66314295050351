import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { IRowNode } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { CustomCellRendererParams, RowGroupActionService } from '@ddv/data-grid';
import { RowGroupOpenedEvent, TrebekConversationFields } from '@ddv/models';

import { PostRealtimeCommentUpdateNotificationService } from './post-realtime-comment-update-notification.service';

@Component({
    selector: 'app-crosstalk-comment-counter',
    templateUrl: './crosstalk-comment-counter.component.html',
    styleUrls: ['./crosstalk-comment-counter.component.scss'],
})
export class CrosstalkCommentCounterComponent extends MultiSubscriptionComponent implements ICellRendererAngularComp {
    showCommentCounter = false;
    commentCount = 0;
    private params: CustomCellRendererParams | undefined;
    private rowGroupId: string | undefined;
    private nodesAfterFilterOrSort: IRowNode[] = [];
    private colId: string | undefined;

    constructor(
        private readonly rowGroupActionService: RowGroupActionService,
        private readonly postRealtimeUpdateNotificationService: PostRealtimeCommentUpdateNotificationService,
    ) {
        super();
    }

    agInit(params: CustomCellRendererParams): void {
        this.params = params;
        this.rowGroupId = this.params.node.group ? this.params.node.id : undefined;
        this.showCommentCounter = !!this.rowGroupId && !this.params.node.expanded;
        this.colId = this.params.colDef?.colId;

        if (this.rowGroupId) {
            this.nodesAfterFilterOrSort = [];
            this.getAllSubNodes(this.params.node.childrenAfterFilter ?? []);

            this.nodesAfterFilterOrSort.forEach((node) => {
                this.commentCount = this.getCommentCount(node, this.commentCount);
            });

            this.subscribeToRowGroupOpenAndClose();
            this.subscribeToCrosstalkRealTimeConversationUpdates();
            this.subscribeToRowGroupsAfterFilterOrSort();
            if (this.colId) {
                this.params.node.setDataValue(this.colId, this.commentCount);
            }
            this.setColumnSort();
        }
    }

    refresh(): boolean {
        return true;
    }

    private subscribeToRowGroupOpenAndClose(): void {
        this.subscribeTo(this.rowGroupActionService.rowGroupOpened, (rowGroupOpened: RowGroupOpenedEvent) => {
            if (this.rowGroupId === rowGroupOpened.rowGroupId) {
                this.showCommentCounter = !rowGroupOpened.expanded;
            }
        });
    }

    private subscribeToCrosstalkRealTimeConversationUpdates(): void {
        this.subscribeTo(this.postRealtimeUpdateNotificationService.realTimeCommentUpdate, () => {
            if (this.rowGroupId) {
                let newCommentCount = 0;

                this.nodesAfterFilterOrSort = [];
                this.getAllSubNodes(this.params?.node.childrenAfterFilter ?? []);

                this.nodesAfterFilterOrSort.forEach((node) => {
                    newCommentCount = this.getCommentCount(node, newCommentCount);
                });

                if (this.commentCount !== newCommentCount) {
                    this.commentCount = newCommentCount;
                    if (this.colId) {
                        this.params?.node.setDataValue(this.colId, this.commentCount);
                    }
                    this.setColumnSort();
                }
            }
        });
    }

    private getCommentCount(row: IRowNode, commentCount: number): number {
        let count = commentCount;
        if (row.data?.[TrebekConversationFields.ClientComment]) {
            count += 1;
        }

        if (row.data?.[TrebekConversationFields.HSComment]) {
            count += 1;
        }

        return count;
    }

    private getAllSubNodes(nodes: IRowNode[]): void {
        nodes.forEach((node) => {
            this.nodesAfterFilterOrSort.push(node);
            if (node.group) {
                this.getAllSubNodes(node.childrenAfterFilter ?? []);
            }
        });
    }

    private subscribeToRowGroupsAfterFilterOrSort(): void {
        this.subscribeTo(this.rowGroupActionService.hasGroupsAfterFilterOrSort, (groupsAndEvent) => {
            let newCommentCount = 0;
            this.nodesAfterFilterOrSort = [];
            const nodes = groupsAndEvent.appliedEvent === 'FILTER' ? this.params?.node.childrenAfterFilter : this.params?.node.childrenAfterSort;
            this.getAllSubNodes(nodes ?? []);

            this.nodesAfterFilterOrSort.forEach((node) => {
                newCommentCount = this.getCommentCount(node, newCommentCount);
            });

            if (this.commentCount !== newCommentCount) {
                this.commentCount = newCommentCount;
                if (this.colId) {
                    this.params?.node.setDataValue(this.colId, this.commentCount);
                }
                this.setColumnSort();
            }
        });
    }

    // This is here because of FXB-6018 - Issue 33
    // Since we get comments asynchronously, sometimes we have more comments and the sorting is already set.
    // So we need to force the grid to update the sorting after we receive the last comment and make the calculation.
    private setColumnSort(): void {
        const commentCounter = this.params?.api.getColumnState().find((column) => column.colId === this.colId);
        if (commentCounter?.sort) {
            this.params?.api.onSortChanged();
        }
    }
}
