<div class="calendar">
    <div class="calendar__header">
        <i class="calendar__arrow" [ngClass]="{'icon-left-expand': options?.allowPrev}" (click)="addMonths(-1)"></i>

        <app-dropdown id="selectMonth" class="calendar__dropdown" [options]="allMonths" [(selected)]="selectedMonth" (selectedChange)="selectMonth($event.value)"></app-dropdown>

        <app-dropdown id="selectYear" class="calendar__dropdown" [options]="allYears" [(selected)]="selectedYear" (selectedChange)="selectYear($event.value)"></app-dropdown>

        <i class="calendar__arrow" [ngClass]="{'icon-right-expand': options?.allowNext}" (click)="addMonths(1)"></i>
    </div>

    <div class="calendar__dates">
        <div class="week-day" *ngFor="let day of weekDays">{{day}}</div>
        <div
                class="calendar__date"
                (click)="selectDate(date); $event.stopPropagation()"
                *ngFor="let date of allDates; let i = index"
                [ngClass]="{'empty':!date,
                            'date-today':isSelected(date),
                            'date-range':isInRange(date),
                            'date-weekend': isWeekend(date, i),
                            'date-range-start': isRangeBoundary(date, 'start'),
                            'date-range-end': isRangeBoundary(date, 'end'),
                            'all': isHIDataAvailable && checkForHIDate(date) === 'all',
                            'partial': isHIDataAvailable && checkForHIDate(date) === 'partial',
                            'none': isHIDataAvailable && checkForHIDate(date) === 'none'}">
            {{date}}
        </div>
    </div>
</div>
