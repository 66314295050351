/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/no-this-alias */
import { Injectable } from '@angular/core';
import { deepClone } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import * as d3 from 'd3';

import { ChartData } from '../models/chart-data';
import { ChartSettings } from '../models/chart-settings';
import { DrillInfo } from '../models/drill-info';
import { Level } from '../models/level';
import { PieChartDataModel } from '../models/pie-chart-data-model';
import { Series } from '../models/series';
import { Tooltip } from '../models/tooltip';
import { BaseChartService } from './base-chart.service';
import { DrilldownService } from './drilldown.service';
import { ColorProvider } from './interfaces';

@Injectable()
export class PieChartService extends BaseChartService implements ColorProvider {
    readonly drillService: DrilldownService;
    private dataSourceAbs: ChartData[] = [];
    theme: Theme = Theme.light;

    constructor(private readonly themeService: ThemeService) {
        super();
        this.drillService = new DrilldownService();
        this.themeService.currentTheme$.subscribe((theme) => this.theme = theme);
    }

    initGraph(config: ChartSettings): void {
        this.config = config;
        let dataSource: any = this.config.dataSource;  // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.config.enableDrilldown) {
            this.drillService.setDefaults(this.config.drilldown as any, this.config.selector); // eslint-disable-line @typescript-eslint/no-explicit-any
            if (this.config.drilldown?.defaultLevel) {
                if (this.config.drillInfo?.list) {
                    this.setDrillLevelData(this.config.drillInfo.list);
                }
                dataSource = this.drillService.getData(true);
            } else {
                this.drillService.setData(this.config.dataSource, '');
            }
        }
        this.initSvgPie();
        this.drawPie(dataSource);
    }

    reDrawGraph(): void {
        this.initSvgPie();
        this.drawPie(this.drillService.getData(true) ?? this.config?.dataSource);
    }

    getDrillInfo(): DrillInfo {
        return {
            level: this.drillService.getLevel(),
            list: this.drillService.getLevelData(),
        };
    }

    setDrillLevelData(list: Level[]): void {
        this.drillService.setLevelData(list);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected drawPie(data: any, drilling?: boolean): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!this.config) {
            throw new Error('cannot drawPie without config');
        }

        const series = this.config.series[0];
        const params: PieChartDataModel = {
            source: [...this.config.dataSource],
            series: this.config.series.slice(0, 1),
            xf: series.xField[0],
            yf: series.yField[0],
            color: null,
            isDrillUp: false,
            selectedSlicer: this.config.selectedSlicer,
        };

        if (data && ((data.key === 'others') || (data.label === 'others'))) {
            params.source = data.values ? [...data.values] : data.dataSet;
            if (this.drillService.drillConfig?.keys.length) {
                const index = this.drillService.getLevel() - this.drillService.levelList.filter((level) => level.label === 'others').length;
                params.xf = (this.drillService.drillConfig.keys[index - (drilling ? 1 : 0)].value) ?? '';
            }
            params.isDrillUp = (data.label === 'others');
        } else if (this.drillService.getLevel() > 0) {
            const levelData: any = this.drillService.getData(); // eslint-disable-line @typescript-eslint/no-explicit-any
            params.source = levelData || [...data.values];
            params.xf = this.drillService.getX();
            params.color = data.color;
        }
        this.prepareData(params);
        this.dataSourceAbs = this.dataSource?.map((datum) => {
            const numericValue = Math.abs(datum[params.yf]);
            return { ...datum, [params.yf]: numericValue };
        }) ?? [];
        const gArc = this.svg.selectAll('.arc')
            .data(this.pie(this.dataSourceAbs))
            .enter()
            .append('g')
            .attr('class', 'arc');

        gArc.append('path')
            .attr('d', this.arc)
            .attr('data-legend', (d: any) => d.data.key) // eslint-disable-line @typescript-eslint/no-explicit-any
            .style('fill', (d: any) => d.data.color); // eslint-disable-line @typescript-eslint/no-explicit-any
        this.applyPieChartConfigs(gArc);
        if (this.config.enableDrilldown) {
            this.updateDrillInfo(!!drilling, params, data.key);
        }
        this.highlightSlice(this.config.highlight?.data);
    }

    private createLabels(): void {
        if (!this.config) {
            throw new Error('cannot createLabels without config');
        }

        const midAngle = (d: any): number => (d.startAngle as number) + (d.endAngle - d.startAngle) / 2; // eslint-disable-line @typescript-eslint/no-explicit-any
        const series = deepClone(this.config.series[0]);
        if (this.drillService.getLevel()) {
            series.xField = [this.drillService.getX()];
        }
        this.svg.selectAll('.pie-Label').remove();
        this.svg.selectAll('.pie-polyLine').remove();
        let filterData: ChartData[] = this.config.label?.minLabelValue ?
            this.pie(this.dataSourceAbs).filter((d: any) => {  // eslint-disable-line @typescript-eslint/no-explicit-any
                return d.endAngle - d.startAngle > (this.config?.label?.minLabelValue ?? 0);
            }) :
            this.pie(this.dataSourceAbs);

        if (this.radius <= 50) {
            const labelCount = this.radius > 40 ? 6 : 4;
            const minVal = filterData.map((d) => d.value).sort((x, y) => y - x)[labelCount];
            filterData = this.radius > 30 ? filterData.filter((d) => d.value > minVal) : [];
        }

        if (this.config.label?.projectedLines) {
            // labels overlap after certain amount of items
            // and our algorithm for arranging labels is known to produce infinite loops
            // so we have to set some limits
            // https://gist.github.com/martinjc/e46f38d44a049a61ab1c2d97a2413439
            const LABEL_THRESHOLD = 20;
            if (this.radius > 50 && filterData.length > LABEL_THRESHOLD) {
                if (filterData.every((item) => item.value === filterData[0].value)) {
                    const step = Math.ceil(filterData.length / LABEL_THRESHOLD);
                    filterData = filterData.filter((item, index) => index === 0 || index % step === 0).splice(0, LABEL_THRESHOLD);
                } else {
                    filterData = this.pie(this.dataSource)
                        .sort((x: any, y: any) => (y.endAngle - y.startAngle) - (x.endAngle - x.startAngle)) // eslint-disable-line @typescript-eslint/no-explicit-any
                        .slice(0, LABEL_THRESHOLD);
                }
            }
        }

        filterData.forEach((filterDatum) => {
            filterDatum.value = this.applyActualValue(series, filterDatum);
        });

        const outerArc = d3.arc()
            .outerRadius(this.radius + 5)
            .innerRadius(this.radius + 20);
        const outerArcPadding = d3.arc()
            .outerRadius(this.radius + 5)
            .innerRadius(this.radius + 5);

        const text = this.svg.append('g').selectAll('text')
            .data(filterData, (d: any) => d.data.key) // eslint-disable-line @typescript-eslint/no-explicit-any
            .enter()
            .append('text')
            .attr('dy', '.35em')
            .attr('class', 'pie-Label')
            .attr('id', (d: any, j: any) => `l-${j}`) // eslint-disable-line @typescript-eslint/no-explicit-any
            .text((d: any) => this.applyFormatter(d.value)) // eslint-disable-line @typescript-eslint/no-explicit-any
            .attr('transform', (d: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                const pos = outerArc.centroid(d);
                pos[0] = (this.radius + 20) * 0.95 * (midAngle(d) < Math.PI ? 1 : -1);
                pos[0] = pos[0] > 0 ? pos[0] + 8 : pos[0] - 8;
                const relPosition = this.config?.label?.projectedLines ? pos : outerArc.centroid(d);
                return `translate(${relPosition})`;
            })
            .style('text-anchor', (d: any) => midAngle(d) < Math.PI ? 'start' : 'end'); // eslint-disable-line @typescript-eslint/no-explicit-any
        this.arrangeLabels(this.svg, '.pie-Label');

        if (this.config.label?.projectedLines) {
            this.svg.append('g')
                .selectAll('polyline')
                .data(filterData)
                .enter()
                .append('polyline')
                .attr('class', 'pie-polyLine')
                .attr('points', (d: any, j: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                    const label = this.svg.select(`#l-${j}`);
                    const offset = midAngle(d) < Math.PI ? -10 : 10;
                    const transform = this.getTransformation(label.attr('transform'));
                    const pos = outerArc.centroid(d);
                    pos[0] = (transform.translateX as number) + offset;
                    pos[1] = transform.translateY;
                    const mid = outerArc.centroid(d);
                    mid[1] = transform.translateY;
                    return [outerArcPadding.centroid(d), mid, pos];
                });
        }

        this.truncateWithEllipsis(text);
    }

    private truncateWithEllipsis(textGroup: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!this.config) {
            throw new Error('cannot truncateWithEllipsis without config');
        }

        const dockedPosition = this.config.legend?.docked;
        const legendVisibility = this.config.legend?.showCustom;
        const svgRect = d3.select(this.svg.node().parentNode).node().getBoundingClientRect();
        const leftEdge = svgRect.left;
        const rightEdge = (legendVisibility && dockedPosition === 'right') ? (this.config.width ?? 0) * 0.8 : svgRect.right;
        textGroup.each(function (this: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
            const txtNode = d3.select(this);
            if (txtNode.node()) {
                const txtRect = txtNode.node().getBoundingClientRect();
                const textLeft = txtRect.left;
                const textRight = (legendVisibility && dockedPosition === 'right') ? txtRect.right - leftEdge : txtRect.right;
                const text = txtNode.text();
                if (textLeft < leftEdge) {
                    const visChars = text.length - Math.ceil((leftEdge - textLeft) / (Math.floor(txtRect.width / text.length)));
                    txtNode.text(text.substring(0, visChars - 3));
                    txtNode.append('tspan').attr('class', 'elip').text('...');
                } else if (textRight > rightEdge) {
                    const visChars = text.length - Math.ceil((textRight - rightEdge) / (Math.floor(txtRect.width / text.length)));
                    txtNode.text(text.substring(0, visChars - 3));
                    txtNode.append('tspan').attr('class', 'elip').text('...');
                }
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected updateGraph(data: any, drilldown?: boolean): void {  // eslint-disable-line @typescript-eslint/no-explicit-any
        this.resetHighlightData();
        this.initSvgPie();
        this.drawPie(data, drilldown);
    }

    private applyPieChartConfigs(gArc: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.initEvents();

        if (this.config?.showLabels) {
            this.showLabels(gArc);
        }

        if (this.config?.legend?.showCustom) {
            this.createLegends(this.dataSource);
        }

        if (this.config?.legend?.inline) {
            addInlineLegends(gArc, this.labelArc);
        }

        if (this.config?.series[0].type === 'donut') {
            this.addDisplayTotal();
        }
    }

    private showLabels(gArc: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.config?.label?.inline) {
            this.addInlineLabels(gArc);
        } else {
            this.createLabels();
        }
    }

    private addDisplayTotal(): void {
        const filterData: ChartData[] = this.pie(this.dataSource);
        if (filterData.length && this.config?.displayTotal && this.radius >= 50) {
            const total = filterData.reduce((r, v) => r + (v.value as number), 0);
            const radius = this.radius - 10;
            let hideTotal = false;
            let labelText;
            const getFontSize = (el: any): number => { // eslint-disable-line @typescript-eslint/no-explicit-any
                return Math.min(2 * radius, (2 * radius) / el.getComputedTextLength() * 8);
            };
            const numberText = this.svg
                .append('text')
                .attr('class', 'display-total-number')
                .attr('text-anchor', 'middle')
                .text(this.applyFormatter(total))
                .style('font-size', function (this: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
                    const fontSize = getFontSize(this);
                    hideTotal = fontSize < 8;
                    return fontSize > 30 ? '30px' : `${fontSize}px`;
                });

            if (!hideTotal) {
                labelText = this.svg.append('text')
                    .attr('class', 'display-total-label')
                    .attr('text-anchor', 'middle')
                    .text(this.config.displayTotalLabel)
                    .style('font-size', function (this: SVGTextElement) {
                        const fontSize = getFontSize(this);
                        hideTotal = fontSize < 9;
                        return fontSize > 20 ? '20px' : `${fontSize}px`;
                    });
            }

            if (hideTotal) {
                d3.select('.display-total-number').remove();
                d3.select('.display-total-label').remove();
            } else {
                const numberTextHeight = numberText.node().getBoundingClientRect().height as number;
                const labelTextHeight = labelText.node().getBoundingClientRect().height as number;
                let labelHeight = Math.floor((numberTextHeight + labelTextHeight) / 2);

                this.svg.select('.display-total-number')
                    .attr('y', function () {
                        labelHeight = numberTextHeight - labelHeight - 5;
                        return labelHeight;
                    });

                this.svg.select('.display-total-label')
                    .attr('dy', function () {
                        return labelTextHeight + labelHeight;
                    });
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected createTooltip(event: MouseEvent, data: any, info: Tooltip[]): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        let html = '';
        if (this.config?.series[0].tooltipHTML) {
            html = this.config.series[0].tooltipHTML(data);
        } else {
            html += '<div class=\'tooltip-data\'>';
            if (info?.length) {
                html = info.reduce((tooltip: string, value) => {
                    let tooltipHTML = tooltip + (value.name ? `<label>${value.name}: </label>` : '');
                    tooltipHTML += `<span>${data.data[value.key!]}</span>`;
                    return tooltipHTML;
                }, html);
            } else {
                html += `<span>${data.value}</span>`;
            }
            html += '</div>';
        }
        this.getTooltipDiv('pie')
            .classed('hidden', false)
            .style('left', `${event.pageX + 15}px`)
            .style('top', `${event.pageY + 10}px`)
            .style('opacity', 1)
            .style('z-index', 5000)
            .html(html);
    }

    protected initEvents(): void {
        const self = this;
        const g = this.svg.selectAll('.arc');
        const path = this.svg.selectAll('.arc path');
        path.on('click touchend', (_: MouseEvent, d: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            let chartUpdated = false;
            if (d.data.key === 'others') {
                this.highlightSlice(d);
            } else if ((this.config?.enableDrilldown && this.drillService.shouldContinueDrilling())) {
                chartUpdated = true;
                if (this.config.enableDrilldown) {
                    this.drillService.updateLevel();
                }
                if (this.config.drilldown?.aggregateDataOnDrill) {
                    d.data.values = this.config.drilldown.aggregateDataOnDrill(d.data.values, this.drillService.getX());
                }
                this.updateGraph(d.data, chartUpdated);
            } else if (this.config?.highlightSlice || (this.config?.enableDrilldown && this.drillService.isLastLevel())) {
                this.highlightSlice(d);
                if (!this.config.highlight?.data) {
                    chartUpdated = true;
                }
            }
            this.onChartClicked(d, chartUpdated);
        });

        if (self.config?.showTooltip) {
            g.on('mousemove', (event: MouseEvent, d: any) => this.createTooltip(event, d, self.config?.tooltip ?? [])); // eslint-disable-line @typescript-eslint/no-explicit-any
        }
        g.on('mouseover', function (this: any, _: MouseEvent, d: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
            self.animateSlice(d, this);
        }).on('mouseout', function (this: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
            d3.select(this).transition()
                .duration(150)
                .attr('transform', 'translate(0,0)');
            if (self.config?.showTooltip) {
                self.getTooltipDiv()
                    .classed('hidden', true);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected animateSlice(d: any, g: SVGElement): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        /**
         * Mouseout effect if no transition has started
         * Calculate angle bisector
         */
        let ang = (d.startAngle as number) + (d.endAngle - d.startAngle) / 2;
        /* Transformate to SVG space */
        ang = (ang - (Math.PI / 2)) * -1;
        /**
         * Calculate a 10% radius displacement
         */
        const x = Math.cos(ang) * this.radius * (0.05);
        const y = Math.sin(ang) * this.radius * (-0.05);
        d3.select(g).transition()
            .duration(250)
            .attr('transform', `translate(${x},${y})`);
    }

    protected updateDrillInfo(drilling: boolean, params: PieChartDataModel, key?: string): void {
        if (drilling) {
            this.drillService.setData(params.source, params.color, key);
        }
        this.drillService.addLevels((data) => {
            this.updateGraph(data);
        });
    }

    highlightSlice(details: { data: { key: string } } | undefined): void {
        const selArc = this.svg.selectAll('.arc path');
        if (!details?.data || selArc.filter((el: any) => el.data.key === details.data.key).size() === 0) { // eslint-disable-line @typescript-eslint/no-explicit-any
            return;
        }

        const gArc: d3.Selection<d3.BaseType, unknown, HTMLElement, any> = this.svg.selectAll('.arc'); // eslint-disable-line @typescript-eslint/no-explicit-any
        const gPath = gArc.selectAll('path');
        const disabledPaths = gPath.filter('.disabled').size();
        const selPie = gPath.filter((el: any) => el.data.key === details.data.key); // eslint-disable-line @typescript-eslint/no-explicit-any
        const isHighlight = disabledPaths === 0 || (disabledPaths > 0 && !selPie.classed('enabled'));
        if (isHighlight) {
            this.addHighlight(gArc, selPie, details);
            this.config!.highlight!.data = details;
        } else {
            this.removeHighlight(gPath);
            this.resetHighlightData();
        }
    }

    private addHighlight(
        gArc: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
        selPie: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>,
        details: { data: { key: string } },
    ): void {
        gArc.selectAll('path').classed('disabled', true).classed('enabled', false);
        selPie.classed('disabled', false).classed('enabled', true);
        d3.select(this.svg.node().parentNode)
            .selectAll('.legend-items')
            .classed('disabled', (n: any) => details.data.key !== n.data.key) // eslint-disable-line @typescript-eslint/no-explicit-any
            .classed('enabled', (n: any) => details.data.key === n.data.key); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private removeHighlight(gPath: d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown>): void {
        gPath.classed('disabled', false).classed('enabled', false);
        d3.select(this.svg.node().parentNode)
            .selectAll('.legend-items')
            .classed('disabled', false)
            .classed('enabled', false);
    }
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    createLegends(data?: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.legendsService.setDefaults({
            svg: this.svg,
            config: this.config!,
            dataSource: data,
            pie: this.pie,
            clickFn: this.highlightSlice.bind(this),
            onChartClicked: this.onChartClicked, // eslint-disable-line @typescript-eslint/unbound-method
        });
        this.legendsService.createCustomLegends();
    }

    private addInlineLegends(gArc: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        addInlineLegends(gArc, this.labelArc);
    }

    private addInlineLabels(gArc: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        const series = this.config!.series[0];
        gArc.append('text')
            .attr('transform', (d: any) => `translate(${this.labelArc!.centroid(d)})`) // eslint-disable-line @typescript-eslint/no-explicit-any
            .attr('dy', '.35em')
            .text((d: any) => this.applyFormatter(this.applyActualValue(series, d))) // eslint-disable-line @typescript-eslint/no-explicit-any
            .attr('text-anchor', (d: any) => ((d.endAngle as number) + (d.startAngle as number)) / 2 > Math.PI ? 'end' : 'start'); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private arrangeLabels(selection: any, labelClass: string): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        let move = 1;
        const self = this;
        while (move > 0) {
            move = 0;
            selection.selectAll(labelClass)
                .each(function (this: HTMLElement) {
                    const that = this;
                    let a = this.getBoundingClientRect();
                    selection.selectAll(labelClass)
                        .each(function (this: HTMLElement) {
                            if (this !== that) {
                                const b = this.getBoundingClientRect();
                                if ((Math.abs(a.left - b.left) * 2 <
                                    (a.width + b.width)) && (Math.abs(a.top - b.top) * 2 <
                                    (a.height + b.height))) {
                                    const dx = (Math.max(0, a.right - b.left) + Math.min(0, a.left - b.right)) * 0.01;
                                    const dy = (Math.max(0, a.bottom - b.top) + Math.min(0, a.top - b.bottom)) * 0.02;
                                    const tt = self.getTransformation(d3.select(this)
                                        .attr('transform'));
                                    const to = self.getTransformation(d3.select(that)
                                        .attr('transform'));
                                    move += Math.abs(dx) + Math.abs(dy);

                                    to.translate = [(to.translateX as number) + dx, (to.translateY as number) + dy];
                                    tt.translate = [tt.translateX - dx, tt.translateY - dy];
                                    d3.select(this)
                                        .attr('transform', `translate(${tt.translate})`);
                                    d3.select(that)
                                        .attr('transform', `translate(${to.translate})`);
                                    a = this.getBoundingClientRect();
                                }
                            }
                        });
                });
        }
    }

    private getTransformation(transform: any): any { // eslint-disable-line @typescript-eslint/no-explicit-any
        const g = document.createElementNS(d3.namespaces.svg, 'g');

        g.setAttributeNS(null, 'transform', transform);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const matrix = (g as any).transform.baseVal.consolidate().matrix;

        let { a, b, c, d, e, f } = matrix;
        let scaleX = Math.sqrt(a * a + b * b);
        let skewX = a * c + b * d;
        const scaleY = Math.sqrt(c * c + d * d);
        e = matrix.e;
        f = matrix.f;
        if (scaleX) {
            a = a / scaleX;
            b = b / scaleX;
        }

        if (skewX) {
            c = c - a * skewX;
            d = d - b * skewX;
        }

        if (scaleY) {
            c = c / scaleY;
            d = d / scaleY;
            skewX = skewX / scaleY;
        }

        if (a * d < b * c) {
            a = -a;
            b = -b;
            skewX = -skewX;
            scaleX = -scaleX;
        }

        return {
            translateX: e,
            translateY: f,
            rotate: Math.atan2(b, a) * Math.PI / 180,
            skewX: Math.atan(skewX) * Math.PI / 180,
            scaleX,
            scaleY,
        };
    }

    private resetHighlightData(): void {
        this.config!.highlight!.data = undefined;
    }

    private applyActualValue(series: Series, filterDatum: ChartData): ChartData {
        return this.dataSource?.find((datum) => datum.key === filterDatum.data.key)?.[series.yField[0]];
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function addInlineLegends(gArc: any, labelArc: d3.Arc<any, d3.DefaultArcObject> | undefined): void {
    gArc.append('text')
        .attr('transform', (d: any) => `translate(${labelArc!.centroid(d)})`) // eslint-disable-line @typescript-eslint/no-explicit-any
        .attr('dy', '.35em')
        .text((d: any) => d.data.key) // eslint-disable-line @typescript-eslint/no-explicit-any
        .attr('text-anchor', (d: any) => ((d.endAngle as number) + (d.startAngle as number)) / 2 > Math.PI ? 'end' : 'start'); // eslint-disable-line @typescript-eslint/no-explicit-any
}
