import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'app-find-widget-icon',
    templateUrl: './find-widget-icon.component.html',
    styleUrls: ['./find-widget-icon.component.scss'],
})
export class FindWidgetIconComponent {
    @Input() @HostBinding('class.readonly') readonly = false;
    @Output() pressed: EventEmitter<void> = new EventEmitter();

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (!this.readonly) {
            this.pressed.emit();
        }
    }
}
