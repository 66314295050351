import { Pipe, PipeTransform } from '@angular/core';
import { DdvDate } from '@ddv/models';

import { DateRange } from '../calendar/date-range';
import { HiDate } from '../hi-date';

@Pipe({ name: 'filterDates' })
export class FilterDatesPipe implements PipeTransform {
    transform(value: HiDate[], selectedDate?: DdvDate | DateRange): HiDate[] {
        if (!selectedDate || !value) {
            return value;
        }

        let dateRange: DateRange;
        if (selectedDate instanceof DdvDate) {
            dateRange = {
                dateFrom: selectedDate,
                dateTo: selectedDate,
            };
        } else {
            dateRange = selectedDate;
        }

        return value.filter(
            (date) =>
                (!dateRange.dateFrom || date.date.toMilliseconds() >= dateRange.dateFrom.toMilliseconds()) &&
                (!dateRange.dateTo || date.date.toMilliseconds() <= dateRange.dateTo.toMilliseconds()),
        );
    }
}
