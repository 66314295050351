import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { ParsedRoute, ParsedRouteService } from './parsed-route.service';

function ignorePromise(promise: Promise<unknown>): void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    promise.then(() => {}, () => {});
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private currentRoute: ParsedRoute | undefined;

    constructor(private readonly router: Router, parsedRouteService: ParsedRouteService) {
        parsedRouteService.parsedRoute.subscribe((route) => this.currentRoute = route);
    }

    toManageWidgets(widgetId?: string): void {
        let path: string;
        const options: NavigationExtras = {};

        if (this.currentRoute?.section === 'views') {
            path = `../../../widgets/edit/${widgetId ?? 'none'}`;
            options.relativeTo = this.currentRoute.activatedRoute;
        } else if (this.currentRoute?.section === 'widgets') {
            path = '';
        } else {
            path = '';
        }

        ignorePromise(this.router.navigate([path], options));
    }

    async toViewBoard(dashboardId?: string | number): Promise<boolean> {
        return this.navigateToBoardAndMode('view', dashboardId);
    }

    async toEditBoard(dashboardId?: string | number): Promise<boolean> {
        return this.navigateToBoardAndMode('edit', dashboardId);
    }

    private async navigateToBoardAndMode(mode: string, dashboardId?: string | number): Promise<boolean> {
        let path: string;
        const options: NavigationExtras = {};

        if (this.currentRoute?.section === 'views') {
            path = `../../${mode}/${dashboardId ?? 'none'}`;
            options.relativeTo = this.currentRoute.activatedRoute;
        } else if (this.currentRoute?.section === 'widgets') {
            path = '';
        } else {
            path = '';
        }

        return this.router.navigate([path], options);
    }
}
