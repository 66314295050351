import { NgModule } from '@angular/core';
import { DatasetDefinitionsModule } from '@ddv/dataset-definitions';
import { EntitlementsModule } from '@ddv/entitlements';
import { HttpModule } from '@ddv/http';

import { ClientsService } from './clients.service';
import { FundsService } from './funds.service';
import { FuzzyDatesServiceV2 } from './fuzzy-dates-v2.service';
import { FuzzyDatesService } from './fuzzy-dates.service';

@NgModule({
    imports: [
        DatasetDefinitionsModule,
        EntitlementsModule,
        HttpModule,
    ],
    providers: [
        ClientsService,
        FundsService,
        FuzzyDatesService,
        FuzzyDatesServiceV2,
    ],
})
export class ReferenceDataModule {}
