import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { Observable } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    private $clients: Observable<string[]> | undefined;

    constructor(
        private readonly currentState: CurrentStateService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
    ) {}

    clients(): Observable<string[]> {
        if (!this.$clients) {
            this.$clients = this.getClients().pipe(
                map((clients) => clients.sort((a, b) => a.localeCompare(b))),
                shareReplay(1),
            );
        }

        return this.$clients;
    }

    private get clientsEndpoint(): Observable<{ clientCode: string, route: string }> {
        return this.currentState.clientCode$.pipe(
            map((clientCode) => {
                return {
                    clientCode,
                    route: '/reference-data/client-list',
                };
            }),
        );
    }

    private getClients(): Observable<string[]> {
        return this.clientsEndpoint.pipe(
            mergeMap(({ clientCode, route }) => this.trebekApiExecutor.invokeServiceWithParams<string[]>(clientCode, route)),
        );
    }
}
