import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { ViewEditDashboardComponent } from '../components/view-edit-dashboard/view-edit-dashboard.component';

@Injectable()
export class CanDeactivateViewEditGuard implements CanDeactivate<ViewEditDashboardComponent> {
    canDeactivate(
        component: ViewEditDashboardComponent,
        _currentRoute: ActivatedRouteSnapshot,
        _currentState: RouterStateSnapshot,
        _nextState?: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return component.guardAgainstUnsavedChanges();
    }
}
