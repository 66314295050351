import { Injectable } from '@angular/core';
import { FormatterService } from '@ddv/formatters';
import { Summary, SummaryColumnConfig, SummaryColumnFilter, SummaryField, AggType, calculateAggFunction } from '@ddv/models';

@Injectable()
export class SummaryService {
    constructor(private readonly formatter: FormatterService) {}

    getSummaries(data: { [key: string]: string | number }[], summaries: Summary[]): SummaryField[] {
        return summaries.map((summary) => {
            const aggregate = this.getSummaryAggregate(summary, data);
            return {
                columnName: summary.summaryWidgetColumnConfiguration.label || summary.summaryLabel,
                columnValue: aggregate.summaryColumnValue,
                isRed: aggregate.isRed,
            };
        });
    }

    private getSummaryAggregate(
        summary: Summary,
        data: { [key: string]: string | number }[],
    ): { isRed: boolean, summaryColumnValue: string } {
        let newData: { [key: string]: string | number }[];

        if (summary.summaryDatatype === 'decimal' && typeof data[0]?.[summary.summaryField] === 'string') {
            newData = this.updateDataBeforeAggregation(data, summary.summaryField);
        } else {
            newData = data;
        }

        const filteredData = this.getFilteredData(newData, summary.summaryWidgetFilters);

        if (!filteredData.length) {
            return { isRed: false, summaryColumnValue: '-' };
        }

        const valueConfig = summary.summaryWidgetColumnConfiguration;

        if (summary.summaryDatatype === 'date' || summary.displayType === 'date') {
            return {
                isRed: false,
                summaryColumnValue: this.calculateDateValue(filteredData, summary.summaryField, valueConfig.aggregationType),
            };
        }

        const aggregate = this.calculateAggregate(valueConfig.aggregationType, newData, filteredData, summary.summaryField);

        if (valueConfig.aggregationType === 'calcOnCountPercentage' || valueConfig.aggregationType === 'calcOnCountDistinctPercentage') {
            valueConfig.numberFormat = '%';
        }

        if (aggregate === '') {
            return { isRed: false, summaryColumnValue: '-' };
        }

        if (isNaN(aggregate as number)) {
            return { isRed: false, summaryColumnValue: aggregate as string };
        }

        return this.getFormattedNumber(aggregate as number, valueConfig);
    }

    private getFormattedNumber(value: number, valueConfig: SummaryColumnConfig): { isRed: boolean, summaryColumnValue: string } {
        return this.formatter.summaryFormatting(
            value,
            valueConfig.decimalPlaces,
            valueConfig.numberFormat,
            valueConfig.numberUnits,
            valueConfig.negativeValue.includes('arenthesis'),
            valueConfig.negativeValue.includes('red'),
        );
    }

    private getFilteredData(
        data: { [key: string]: string | number }[],
        summaryColumnFilters: SummaryColumnFilter[],
    ): { [key: string]: string | number }[] {
        if (!data?.length) {
            return [];
        }

        const summaryFilterValues: { [key: string]: string[] } = {};
        summaryColumnFilters.forEach((summaryColFilter) => {
            if (!summaryColFilter.summaryWidgetFilterValues.length) {
                return;
            }

            summaryFilterValues[summaryColFilter.filterField] = summaryColFilter.summaryWidgetFilterValues.map(
                (filterVal) => filterVal.filterValue == null ? '' : filterVal.filterValue.toString().toLocaleLowerCase());
        });

        const filterFieldList = Object.keys(summaryFilterValues);
        if (!filterFieldList.length) {
            return data;
        }

        return data.filter((datum) => !filterFieldList.some(
            (field) => {
                const datumField = datum[field] == null ? '' : datum[field].toString().toLocaleLowerCase();
                return summaryFilterValues[field].indexOf(datumField) === -1;
            }));
    }

    private calculateDateValue(data: { [key: string]: string | number }[], summaryField: string, aggregationType: AggType): string {
        const dateValues = new Set<number>();
        data.forEach((datum) => dateValues.add((new Date(datum[summaryField])).getTime()));
        const calculatedDateValue = aggregationType === 'max' ? Math.max(...dateValues) : Math.min(...dateValues);
        return data.find((datum) => (new Date(datum[summaryField]).getTime()) === calculatedDateValue)![summaryField] as string;
    }

    private calculateAggregate(
        aggregationType: AggType,
        data: { [key: string]: string | number }[],
        filteredData: { [key: string]: string | number }[],
        summaryField: string,
    ): string | number | null {
        if (aggregationType === 'calcOnCountPercentage') {
            return filteredData.length / data.length;
        }
        if (aggregationType === 'calcOnCountFraction') {
            return `${filteredData.length} / ${data.length}`;
        }
        if (aggregationType === 'calcOnCountDistinctPercentage') {
            return this.calculateDistinctAggregate(data, filteredData, summaryField, aggregationType);
        }
        if (aggregationType === 'calcOnCountDistinctFraction') {
            return this.calculateDistinctAggregate(data, filteredData, summaryField, aggregationType);
        }
        return calculateAggFunction(filteredData, summaryField, aggregationType);
    }

    private calculateDistinctAggregate(
        data: { [key: string]: string | number }[],
        filteredData: { [key: string]: string | number }[],
        summaryField: string,
        aggregationType: AggType,
    ): string | number {
        const distinctDataLength = calculateAggFunction(data, summaryField, 'countDistinct');
        const distinctFilteredDataLength = calculateAggFunction(filteredData, summaryField, 'countDistinct');

        return aggregationType === 'calcOnCountDistinctPercentage' ?
            (distinctFilteredDataLength as number) / (distinctDataLength as number) :
            `${distinctFilteredDataLength} / ${distinctDataLength}`;
    }

    private updateDataBeforeAggregation(data: { [key: string]: string | number }[], field: string): { [key: string]: string | number }[] {
        data.forEach((datum) => datum[field] = +datum[field]);
        return data;
    }
}
