<div class="widget-finder">
    <div class="widget-finder-header">
        <ul>
            <li
                    *ngIf="!showOnlyGlobal" [ngClass]="{selected: selectedTab === tabs.ALL}"
                    id="widget-finder-all-tab"
                    (click)="onTabChange(tabs.ALL)">
                All
            </li>

            <li
                    [ngClass]="{'selected': selectedTab === tabs.GLOBAL}"
                    id="widget-finder-global-tab"
                    (click)="onTabChange(tabs.GLOBAL)">
                Global
            </li>

            <li
                id="widget-finder-private-tab"
                *ngIf="!showOnlyGlobal && showPrivateTab"
                [ngClass]="{selected: selectedTab === tabs.PRIVATE}"
                (click)="onTabChange(tabs.PRIVATE)">
                Private
            </li>
        </ul>

        <div class="hide-tags">
            <span (click)="toggleTags()">{{showTags ? 'Hide Tags' : 'Show Tags'}}</span>
        </div>

        <div class="menu-dropdown" appMousedownOutside (mousedownOutside)="showFiltersDropdown = false">
            <div class="group-by-picker">Group By:
                <label>{{ groupText }}</label>

                <cc-icon
                    [icon]="'chevron-down2'"
                    [title]="'More Options'"
                    (iconClicked)="toggleFiltersDropdown()"
                />
            </div>

            <div class="dropdown" [hidden]="!showFiltersDropdown">
                <div
                    *ngFor="let group of groupsList | keyvalue"
                    [class.selected]="groupText === group.value ? 'selected' : null"
                    [attr.selected]="groupText === group.value ? 'selected' : null"
                    (click)="updateSelectedGroup(group.value)">
                    {{ group.value }}
                </div>
            </div>
        </div>
    </div>

    <div class="search-box">
        <span class="icon-search1"></span>

        <input
            #searchBox
            appAutoFocus
            appInputRestrict
            type="search"
            id="searchParam"
            name="searchParam"
            autocomplete="off"
            allowPattern="^[\w\s\/\-%&^#@()<>]+$"
            placeholder="Quick search..."
            [(ngModel)]="searchParam"
            (ngModelChange)="filterSearchResults()"
            [focus]="!loaderInWidgetFinder"/>
    </div>

    <div class="search-div-scroll" *ngIf="groupedWidgets.length">
        <div
                class="widgets"
                [ngClass]="{'empty-group': !item.widgets.length}"
                *ngFor="let item of groupedWidgets; let i = index;">

            <div class="group-by" [hidden]="groupText === groupsList.NO_GROUP || !item.widgets.length">
                <span class="icon-arrow-down" (click)="toggleWidgetsData(i)"></span>

                <label>{{ item.groupName }}</label>
            </div>

            <div class="widget-item" [hidden]="!toggleWidgetData[i]" *ngFor="let widget of item.widgets; let j = index">
                <div class="list-visual">
                    <ng-container *ngFor="let viz of widget.vizForms">
                        <span *ngIf="viz.cssClass !== 'icon-stacked-area-chart'" class="{{viz.cssClass}}"></span>

                        <ddv-stacked-area-chart-icon
                            *ngIf="viz.cssClass === 'icon-stacked-area-chart'"
                            class="{{viz.cssClass}}">
                        </ddv-stacked-area-chart-icon>
                    </ng-container>
                </div>

                <div class="widget-item-title" (click)="addWidget(widget)">
                    <h2
                        [ngClass]="{'no-grouping': groupText === groupsList.NO_GROUP, 'whats-new': groupText === groupsList.WHATS_NEW}"
                        title="{{widget.name}}"
                        [innerHTML]="widget.name | highlight : searchParam">
                    </h2>

                    <span *ngIf="groupText === groupsList.WHATS_NEW" class="created-at">
                        Created:<span> {{ widget.createdDate | date:'MM/dd/yyyy HH:mm:ss a' }}</span>
                    </span>

                    <div class="tags-list" *ngIf="showTags">
                        <div
                            class="tag"
                            *ngFor="let tag of widget.widgetTags"
                            [innerHTML]="tag.name | highlight : searchParam">
                        </div>
                    </div>
                </div>

                <div class="widget-icons">
                    <div class="widget-icons-wrapper">
                        <cc-icon
                            *ngIf="widget.isGlobal"
                            [icon]="'global'"
                            [flat]="true"
                            [title]="'Global'"
                        />

                        <cc-icon
                            *ngIf="!widget.isGlobal && !widget.isShared"
                            [icon]="'lock'"
                            [flat]="true"
                            [title]="'Private'"
                        />

                        <cc-icon
                            [icon]="'trashcan'"
                            (iconClicked)="removeWidget(widget.id, i, j)"
                            [disabled]="!(!widget.isGlobal && (widget.privilege === 'EDIT' || hasViewAndEditAll))"
                            [title]="'Remove'"
                        />
                    </div>

                    <cc-icon
                        [icon]="'dots-vertical'"
                        (iconClicked)="toggleWidgetDescription(widget.id)"
                        [title]="'Properties'"
                    />
                </div>

                <div *ngIf="widget.id === showWidgetDescById" class="info-block">
                    <h4>Description</h4>
                    <p>{{ widget.description }}</p>

                    <h4>Owner</h4>
                    <p>{{ widget.createdBy }}</p>

                    <h4>Dataset</h4>
                    <p>{{ widget.datasetName }}</p>

                    <h4>Created</h4>
                    <p>{{ widget.createdDate | date: 'MM/dd/yyyy HH:mm:ss a' }}</p>
                </div>
            </div>
        </div>
    </div>

    <app-loader [isDataLoading]="loaderInWidgetFinder"></app-loader>

    <div class="no-data error parent-height-widget-finder" *ngIf="groupedWidgets.length === 0">
        <span>NO WIDGETS AVAILABLE</span>
    </div>
</div>

