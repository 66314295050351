import { Component, EventEmitter, Input, Output, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { DirtyFlagService } from '@ddv/behaviors';
import { DashboardGroup, DashboardService } from '@ddv/dashboards';
import { Entitlements, Roles, UserEntitlements, UserEntitlementService } from '@ddv/entitlements';
import { Workspace, ManagerService } from '@ddv/layout';
import { PERMISSION, DashboardDetails, DashboardSnapshot } from '@ddv/models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-hs-dashboard-header',
    templateUrl: 'hs-dashboard-header.component.html',
    styleUrls: ['./hs-dashboard-header.component.scss'],
})
export class HSDashboardHeaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() currentDashboard: Workspace | undefined;
    @Input() isLastDashboardClosed = false;
    @Input() inPresentationMode = false;
    @Input() dashboardGroups: DashboardGroup[] | undefined;
    @Input() dashboardSnapshots: DashboardSnapshot[] | undefined;

    @Output() saveClick = new EventEmitter();
    @Output() cancelEdit = new EventEmitter();
    @Output() revertChanges = new EventEmitter();
    @Output() dashboardSwitch = new EventEmitter<string>();

    views: DashboardGroup | undefined;
    userEntitlements: UserEntitlements | undefined;
    dashboardEdit = false;
    userEntitlementSubscription: Subscription | undefined;

    constructor(
        public readonly dirtyFlagService: DirtyFlagService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly dashboardService: DashboardService,
        private readonly manager: ManagerService,
    ) {}

    ngOnInit(): void {
        this.userEntitlementSubscription = this.userEntitlementService.entitlementsForClientCode$.subscribe({
            next: (entitlements) => {
                this.userEntitlements = entitlements;
                this.dashboardEdit = entitlements.hasPermission(Entitlements.DASHBOARD_EDIT);
                this.initViews();
            },
        });
    }

    ngOnChanges(): void {
        this.initViews();
    }

    initViews(): void {
        this.views = undefined;
        if (this.dashboardGroups && this.currentDashboard) {
            this.dashboardGroups.forEach((group) => {
                group.dashboards = group.dashboards.filter((db) => {
                    return this.dashboardSnapshots?.some((snapshot) => snapshot.id?.toString() === db.id?.toString());
                });
                const viewIds = group.dashboards.map((view) => view.id?.toString());
                if (viewIds.indexOf(this.currentDashboard?.id) !== -1) {
                    this.views = group;
                    return;
                }
            });
        }
    }

    switchDashboard(dashboardId?: string): void {
        if (dashboardId?.toString() === this.currentDashboard?.id) {
            return;
        }

        this.dashboardSwitch.emit(dashboardId);
    }

    hideEditIcon(): boolean {
        if (!this.currentDashboard ||
            !this.userEntitlements ||
            (this.currentDashboard?.extraParameters?.privilege === 'VIEW')
        ) {
            return true;
        }

        const roleName = this.userEntitlements.user.roleName;
        const additionalRoles = this.userEntitlements.user.additionalRoles ?? [];
        const dashboardDetails = this.dashboardService.getDashboardSnapshotById(this.currentDashboard.id.toString()) as DashboardDetails;

        if (roleName === Roles.DDV_Editor || roleName === Roles.DDV_PowerUser) {
            return this.hideEditIconForPowerUserOrEditor(dashboardDetails, additionalRoles);
        }

        if (roleName === Roles.DDV_ClientBaseUser || roleName === Roles.DDV_HSBaseUser) {
            return this.hideEditIconForBaseUser(dashboardDetails);
        }

        return false;
    }

    get editDashboardPath(): string {
        const currentDashboardId = this.manager.getCurrentDashboardId();
        return `../../edit/${currentDashboardId != null ? currentDashboardId : 'none'}`;
    }

    ngOnDestroy(): void {
        this.userEntitlementSubscription?.unsubscribe();
    }

    private hideEditIconForPowerUserOrEditor(dashboardDetails: DashboardDetails, additionalRoles: string[]): boolean {
        if (this.currentDashboard?.extraParameters?.isGlobal && !this.userEntitlements?.haveGlobalEditPartial) {
            return true;
        }

        if (!this.isCreatedBySameAsCurrentUser(dashboardDetails) && !dashboardDetails?.dashboardPermissions?.length ||
            !dashboardDetails.createdBy) {
            return true;
        }

        if (this.isCreatedBySameAsCurrentUser(dashboardDetails)) {
            return false;
        }

        if (dashboardDetails?.dashboardPermissions) {
            const permission = dashboardDetails.dashboardPermissions
                .find((p) => {
                    return (
                        p.name.toLocaleLowerCase() === this.userEntitlements?.user.username.toLocaleLowerCase() ||
                        additionalRoles.includes(p.name)
                    ) && p.privilege === PERMISSION.EDIT;
                });
            return !(permission && this.dashboardEdit);
        }

        return false;
    }

    private hideEditIconForBaseUser(dashboardDetails: DashboardDetails): boolean {
        if (!dashboardDetails?.dashboardPermissions || !dashboardDetails.createdBy) {
            return true;
        }

        if (this.isCreatedBySameAsCurrentUser(dashboardDetails)) {
            return false;
        }

        if (!this.userEntitlements?.hasPermission(Entitlements.DASHBOARD_EDIT) &&
            !this.userEntitlements?.hasPermission(Entitlements.DASHBOARD_EDIT_ALL)) {
            return true;
        }

        return false;
    }

    private isCreatedBySameAsCurrentUser(dashboardDetails: DashboardDetails): boolean {
        return dashboardDetails?.createdBy?.toLocaleLowerCase() === this.userEntitlements?.user.username.toLocaleLowerCase();
    }
}
