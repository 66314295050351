export function convertJsonToCsv<T extends {[key: string]: unknown}>(data: T[]): string {
    interface FieldType {
        name: string;
        type: 'atom' | 'array'; // for full implementation we need here 'object' too
        length: number; // maximum length of an array
    }

    const fieldsMap = new Map<string, FieldType>();

    data.forEach((datum) => Object.keys(datum).forEach((key) => {
        const value = datum[key];
        if (value instanceof Array) {
            let field = fieldsMap.get(key);
            if (!field) {
                field = { name: key, type: 'array', length: value.length };
                fieldsMap.set(key, field);
            }

            field.length = field.length < value.length ? value.length : field.length;
        } else {
            fieldsMap.set(key, { name: key, type: 'atom', length: 0 });
        }
    }));

    const headers: string[] = [];
    for (const [fieldName, field] of fieldsMap) {
        if (field.type === 'array') {
            for (let i = 0; i < field.length; i++) {
                headers.push(fieldName + (i + 1).toString());
            }
        } else {
            headers.push(fieldName);
        }
    }
    const headerRow = headers.join(',');

    const rows = [headerRow];
    data.forEach((datum) => {
        const row: string[] = [];
        for (const [fieldName, field] of fieldsMap) {
            const value = datum[fieldName];
            if (field.type === 'array' && value && Array.isArray(value)) {
                row.push(...value);
                for (let i = value.length; i < field.length; i++) {
                    row.push('');
                }
            } else {
                row.push(value != null ? `${value}` : '');
            }
        }
        rows.push(row.map((f) => JSON.stringify(f)).join(','));
    });

    return rows.join('\r\n');
}

export function exportToCSV(csv: string, fileName: string): void {
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = `${fileName}.csv`;
    anchor.href = url;
    anchor.click();
}
