import { Injectable } from '@angular/core';
import { ActionHandlerBody } from '@ddv/models';

import { WrappedArrayBody } from '../models/action-handler';

@Injectable()
export class ActionHandlerBodyBuilderService {
    buildActionHandlerBody(config: ActionHandlerBody, selectedRows: unknown[]): WrappedArrayBody {
        if (config.type === 'wrapped-array') {
            return this.buildWrappedPayloadBody(config, selectedRows);
        }

        throw new Error(`unsupported body type: ${config.type}`);
    }

    private buildWrappedPayloadBody(config: ActionHandlerBody, selectedRows: unknown[]): WrappedArrayBody {
        const wrapperPropertyName = config.wrapperPropertyName;
        if (!wrapperPropertyName) {
            throw new Error('wrapperPropertyName is required for wrapped-array body type');
        }

        const itemsConfig = config.items;
        if (!itemsConfig) {
            throw new Error('items is required for wrapped-array body type');
        }

        return {
            [wrapperPropertyName]: selectedRows.map((row) => this.rowToPayloadItem(row, itemsConfig.properties)),
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private rowToPayloadItem(row: any, propertiesMapping: Record<string, string>): Record<string, unknown>[] {
        return Object.values(propertiesMapping).reduce((item, rowPropertyName) => {
            return row[rowPropertyName];
        }, [] as Record<string, unknown>[]);
    }
}
