import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { Attachment, UserDefinedField } from '@ddv/models';
import { first, Observable, ReplaySubject, Subject } from 'rxjs';

import { CrosstalkComment } from '../models/crosstalk-comment';
import { createWebSocket, webSocketFactoryToken } from './socket.factory';

export const SOCKET_CLOSE_DELAY_MS = 10000;

export function hasDuplicates(conversations: string[]): boolean {
    return new Set(conversations).size !== conversations.length;
}

export enum CrosstalkRealtimeEvents {
    SUBSCRIBE_TO_EVENT_FOR_CONVERSATIONS = '[Conversations] Subscribe to Event for Conversations',
    UNSUBSCRIBE_FROM_EVENT_FOR_CONVERSATIONS = '[Conversations] Unsubscribe from Event for Conversations',
    UNSUBSCRIBE_FROM_EVENT_FOR_ALL_CONVERSATIONS = '[Conversations] Unsubscribe from Event for All Conversations',
    UNSUBSCRIBE_FROM_ALL_EVENTS_FOR_ALL_CONVERSATIONS = '[Conversations] Unsubscribe from All Events for All Conversations',
    LAST_COMMENT_CHANGED = '[Conversations] Conversation Last Comment Changed',
    LAST_ATTACHMENTS_CHANGED = '[Conversations] Conversation Last Attachments Changed',
    USER_DEFINED_FIELDS_CHANGED = '[Conversations] User Defined Fields Changed',
}

export interface CrosstalkSocketError {
    statusCode: number;
    message: string;
}

export interface ConversationEvent {
    conversationId: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface ConversationLastCommentChanged extends ConversationEvent {
    comment: CrosstalkComment;
    isHedgeServComment?: boolean;
}

export interface ConversationLastAttachmentsChanged extends ConversationEvent {
    lastAttachments: Attachment[];
}

export interface UserDefinedFieldsChanged extends ConversationEvent {
    userDefinedFields: UserDefinedField[];
}

export const xtlkWsConnectionUrlToken: InjectionToken<string> = new InjectionToken('xtlkWsConnectionUrlToken');

@Injectable({ providedIn: 'root' })
export class CrosstalkRealtimeConversationService {
    private readonly conversationToSubjects: Map<string, Set<Subject<ConversationEvent[]>>> = new Map();
    private readonly subjectToConversations: Map<Subject<ConversationEvent[]>, string[]> = new Map();
    private readonly observableToSubject: Map<Observable<ConversationEvent[]>, Subject<ConversationEvent[]>> = new Map();

    private clientCode = '';
    private socket: WebSocket | undefined;

    constructor(
        currentStateService: CurrentStateService,
        @Inject(webSocketFactoryToken) private readonly webSocketFactory: typeof createWebSocket,
        @Inject(xtlkWsConnectionUrlToken) private readonly url: string,
    ) {
        // I'm not crazy about this implementation - ideally we'd fetch this when connecting the WebSocket.
        // However, making the connection code asynchronous proved to be incredibly difficult.
        currentStateService.clientCode$
            .pipe(first((clientCode) => !!clientCode))
            .subscribe((clientCode) => {
                this.clientCode = clientCode;
            });
    }

    subscribeTo(conversations: string[]): Observable<ConversationEvent[]> | undefined {
        if (conversations.length === 0) {
            return;
        }

        return this.createSubscription(Array.from(new Set(conversations)));
    }

    unsubscribeFrom(observable: Observable<ConversationEvent[]>): void {
        if (this.observableToSubject.has(observable)) {
            this.removeSubscription(observable);
        }
    }

    unsubscribeFromAll(forceDisconnect = false): void {
        this.clearAllConversations();
        this.completeAllSubjects();
        this.sendSocketMessage(CrosstalkRealtimeEvents.UNSUBSCRIBE_FROM_ALL_EVENTS_FOR_ALL_CONVERSATIONS);
        this.disconnectSocketIfNoSubscriptions(forceDisconnect);
    }

    private connectToSocket(): void {
        this.socket = this.webSocketFactory(`${this.url}?clientCode=${this.clientCode}`);

        this.socket.addEventListener('error', (error): void => {
            console.error('Crosstalk websocket error:', error);
            this.unsubscribeFromAll(true);
            this.connectToSocket();
        });

        this.socket.addEventListener('message', (message): void => {
            try {
                const { data } = JSON.parse(message.data) as { data: ConversationEvent[] | CrosstalkSocketError };

                if (isCrosstalkError(data)) {
                    console.error(`Crosstalk returned error ${data.statusCode}:`, data.message);
                    return;
                }

                const notifications = this.buildNotifications(data);
                this.flushNotifications(notifications);
            } catch (e) {
                console.error('Error processing Crosstalk socket message', e, message);
            }
        });
    }

    private disconnectSocketIfNoSubscriptions(force = false): void {
        const disconnect = (): void => {
            this.socket?.close();
            this.socket = undefined;
        };

        if (force) {
            disconnect();
        } else {
            // the delay here allows us to reuse a socket connection if a re-subscribe happens immediately
            setTimeout(() => {
                if (this.hasNoSubscriptions()) {
                    disconnect();
                }
            }, SOCKET_CLOSE_DELAY_MS);
        }
    }

    private sendSocketMessage(event: CrosstalkRealtimeEvents, data?: [string, string[]]): void {
        // if the socket isn't connected when we send, you'll end up with a cryptic error message:
        // "An attempt was made to use an object that is not, or is no longer, usable"
        if (this.socket?.readyState !== 1) {
            setTimeout(() => this.sendSocketMessage(event, data), 10);
        } else {
            this.socket.send(JSON.stringify({ event, data }));
        }
    }

    private createSubscription(conversations: string[]): Observable<ConversationEvent[]> {
        const observable = this.setupSubscription(conversations);
        this.initiateSubscription(observable);
        return observable;
    }

    private removeSubscription(observable: Observable<ConversationEvent[]>): void {
        this.cancelSubscription(observable);
        this.teardownSubscription(observable);
    }

    private initiateSubscription(observable: Observable<ConversationEvent[]>): void {
        const newConversations = this.calculateConversationsToManage(observable);

        if (newConversations.length) {
            this.subscribeToEventForConversations(CrosstalkRealtimeEvents.LAST_COMMENT_CHANGED, newConversations);
            this.subscribeToEventForConversations(CrosstalkRealtimeEvents.LAST_ATTACHMENTS_CHANGED, newConversations);
            this.subscribeToEventForConversations(CrosstalkRealtimeEvents.USER_DEFINED_FIELDS_CHANGED, newConversations);
        }
    }

    private subscribeToEventForConversations(event: CrosstalkRealtimeEvents, newConversations: string[]): void {
        this.sendSocketMessage(CrosstalkRealtimeEvents.SUBSCRIBE_TO_EVENT_FOR_CONVERSATIONS, [event, newConversations]);
    }

    private cancelSubscription(observable: Observable<ConversationEvent[]>): void {
        const conversations = this.calculateConversationsToManage(observable);
        this.unsubscribeFromEventForConversations(CrosstalkRealtimeEvents.LAST_COMMENT_CHANGED, conversations);
        this.unsubscribeFromEventForConversations(CrosstalkRealtimeEvents.LAST_ATTACHMENTS_CHANGED, conversations);
        this.unsubscribeFromEventForConversations(CrosstalkRealtimeEvents.USER_DEFINED_FIELDS_CHANGED, conversations);
    }

    private unsubscribeFromEventForConversations(event: CrosstalkRealtimeEvents, conversations: string[]): void {
        this.sendSocketMessage(CrosstalkRealtimeEvents.UNSUBSCRIBE_FROM_EVENT_FOR_CONVERSATIONS, [event, conversations]);
    }

    private setupSubscription(conversations: string[]): Observable<ConversationEvent[]> {
        if (!this.socket) {
            this.connectToSocket();
        }

        const subject = new ReplaySubject<ConversationEvent[]>(1);
        const observable = subject.asObservable();

        this.observableToSubject.set(observable, subject);
        this.subjectToConversations.set(subject, conversations);

        for (const conversation of conversations) {
            if (this.conversationToSubjects.has(conversation)) {
                this.conversationToSubjects.get(conversation)?.add(subject);
            } else {
                this.conversationToSubjects.set(conversation, new Set<Subject<ConversationEvent[]>>([subject]));
            }
        }

        return observable;
    }

    private teardownSubscription(observable: Observable<ConversationEvent[]>): void {
        const subject = this.observableToSubject.get(observable);
        if (subject) {
            const conversations = this.subjectToConversations.get(subject) ?? [];
            this.observableToSubject.delete(observable);
            subject.complete();
            this.subjectToConversations.delete(subject);
            for (const conversation of conversations) {
                const interestedSubjects = this.conversationToSubjects.get(conversation);
                if (interestedSubjects) {
                    interestedSubjects.delete(subject);
                    if (interestedSubjects.size === 0) {
                        this.conversationToSubjects.delete(conversation);
                    }
                }
            }

            this.disconnectSocketIfNoSubscriptions();
        }
    }

    private calculateConversationsToManage(observable: Observable<ConversationEvent[]>): string[] {
        const subject = this.observableToSubject.get(observable);
        const conversations = subject ? this.subjectToConversations.get(subject) : [];
        return conversations?.filter((conversation) => this.conversationToSubjects.get(conversation)?.size === 1) ?? [];
    }

    private clearAllConversations(): void {
        const allConversations = this.conversationToSubjects.keys();
        for (const conversation of allConversations) {
            this.conversationToSubjects.delete(conversation);
        }
    }

    private completeAllSubjects(): void {
        const allSubjects = this.subjectToConversations.keys();
        for (const subject of allSubjects) {
            subject.complete();
        }
    }

    private hasNoSubscriptions(): boolean {
        return this.conversationToSubjects.size === 0;
    }

    private buildNotifications(conversationEvents: ConversationEvent[]): Map<Subject<ConversationEvent[]>, ConversationEvent[]> {
        const notifications = new Map<Subject<ConversationEvent[]>, ConversationEvent[]>();
        for (const event of conversationEvents) {
            const subjects = this.conversationToSubjects.get(event.conversationId) ?? [];
            for (const subject of subjects) {
                const buffer = notifications.get(subject);
                if (buffer) {
                    buffer.push(event);
                } else {
                    notifications.set(subject, [event]);
                }
            }
        }
        return notifications;
    }

    private flushNotifications(notifications: Map<Subject<ConversationEvent[]>, ConversationEvent[]>): void {
        for (const [subject, buffer] of notifications.entries()) {
            subject.next(buffer);
        }
    }
}

function isCrosstalkError(data: unknown): data is CrosstalkSocketError {
    return Object.prototype.hasOwnProperty.call(data, 'statusCode')
        && (data as CrosstalkSocketError).statusCode >= 400
        && Object.prototype.hasOwnProperty.call(data, 'message');
}
