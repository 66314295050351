import { Directive, EventEmitter, OnDestroy, OnInit, Output, Renderer2, HostListener } from '@angular/core';
import { ManagerService } from '@ddv/layout';
import { Subscription } from 'rxjs';

import { WidgetDragPubSubService } from './widget-drag-pub-sub.service';
import { WidgetDragElementModel } from './widget-drag.model';

@Directive({ selector: '[appWidgetDragTarget]' })
export class WidgetDragTargetDirective implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() dragOver = new EventEmitter<any>();

    private element: WidgetDragElementModel | undefined;
    private widgetDragStreamSubscription: Subscription | undefined;

    constructor(
        private readonly widgetDragPubSubService: WidgetDragPubSubService,
        private readonly renderer: Renderer2,
        private readonly manager: ManagerService) {}

    ngOnInit(): void {
        this.widgetDragStreamSubscription = this.widgetDragPubSubService.stream.subscribe((element) => {
            this.element = element;
        });
    }

    @HostListener('dragenter', ['$event'])
    @HostListener('dragover', ['$event'])
    onDragOver(event: DragEvent): boolean | void {
        event.preventDefault();
        if (!this.element?.dragStarted) {
            return true;
        }

        const rectBoundary = {
            top: this.element.widgetElement?.offsetTop,
            left: this.element.widgetElement?.offsetLeft,
            bottom: (this.element.widgetElement?.offsetTop ?? 0) + (this.element.widgetElement?.offsetHeight ?? 0),
            right: (this.element.widgetElement?.offsetLeft ?? 0) + (this.element.widgetElement?.offsetWidth ?? 0),
        };
        this.moveWidget(event);
        this.dragOver.emit({ widgetRectangle: rectBoundary, widgetId: this.element.widgetElement?.id });
    }

    moveWidget(event: DragEvent): void {
        const eventTarget = event.currentTarget as HTMLElement;
        const containerRect = eventTarget.getBoundingClientRect();
        const layerRect = this.element?.widgetElement?.getBoundingClientRect();
        const widgetPaddingLeft: number = (this.element?.header?.getBoundingClientRect().left ?? 0) - (layerRect?.left ?? 0);
        const widgetPaddingTop: number = (this.element?.header?.getBoundingClientRect().top ?? 0) - (layerRect?.top ?? 0);

        const x: number = event.pageX;
        const y: number = event.pageY;

        let mx = 0;
        let my = 0;

        const verticalScrollWidth: number = eventTarget.offsetWidth - eventTarget.clientWidth;
        const horizontalScrollHeight: number = eventTarget.offsetHeight - eventTarget.clientHeight;

        const workspaceScrollBehaviour = this.manager.getWorkspaceScrollBehaviour();
        let allowInfiniteVScroll = true;
        let allowInfiniteHScroll = false;
        if (workspaceScrollBehaviour) {
            allowInfiniteVScroll = workspaceScrollBehaviour.allowInfiniteVScroll;
            allowInfiniteHScroll = workspaceScrollBehaviour.allowInfiniteHScroll;
        }

        const widgetLeft = x - (this.element?.layerX ?? 0) - widgetPaddingLeft;
        const widgetTop = y - (this.element?.layerY ?? 0) - widgetPaddingTop;
        const widgetRight = x + (layerRect?.width ?? 0) - (this.element?.layerX ?? 0) + verticalScrollWidth;
        const widgetBottom = y + (layerRect?.height ?? 0) - (this.element?.layerY ?? 0) + horizontalScrollHeight;

        if (widgetLeft < containerRect.left) {
            mx = 0;
        } else if (widgetRight >= containerRect.right && !allowInfiniteHScroll) {
            mx = containerRect.right - verticalScrollWidth - (layerRect?.width ?? 0) - containerRect.left;
        } else {
            mx = x - (this.element?.layerX as number) + eventTarget.scrollLeft - containerRect.left;
        }

        if (widgetTop < containerRect.top) {
            my = 0;
        } else if (widgetBottom >= containerRect.bottom && !allowInfiniteVScroll) {
            my = containerRect.bottom - horizontalScrollHeight - (layerRect?.height ?? 0) - containerRect.top;
        } else {
            my = y - (this.element?.layerY as number) + eventTarget.scrollTop - containerRect.top;
        }

        this.doTranslation(mx, my, event.currentTarget);
    }

    doTranslation(x: number, y: number, element: EventTarget | null): void {
        this.renderer.setStyle(this.element?.widgetElement, 'top', `${y}px`);
        this.renderer.setStyle(this.element?.widgetElement, 'left', `${x}px`);
        this.renderer.setAttribute(element, 'scrollTop', `${y}px`);
        this.renderer.setAttribute(element, 'scrollLeft', `${x}px`);
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        if (this.element?.dragStarted) {
            event.preventDefault();
            this.moveWidget(event);
            this.triggerDragEndEvent(event);
        }
    }

    @HostListener('dragend', ['$event'])
    public onDragEnd(event: DragEvent): void {
        if (this.element?.dragStarted) {
            event.preventDefault();
            this.triggerDragEndEvent(event);
        }
    }

    triggerDragEndEvent(event: DragEvent): void {
        const rectBoundary = {
            top: this.element?.widgetElement?.offsetTop ?? 0,
            left: this.element?.widgetElement?.offsetLeft ?? 0,
            bottom: (this.element?.widgetElement?.offsetTop ?? 0) + (this.element?.widgetElement?.offsetHeight ?? 0),
            right: (this.element?.widgetElement?.offsetLeft ?? 0) + (this.element?.widgetElement?.offsetWidth ?? 0),
        };
        const widgetDragElementModel: WidgetDragElementModel = {
            dragEventConfig: { event, rectangle: rectBoundary },
        };
        this.widgetDragPubSubService.stream.emit(widgetDragElementModel);
    }

    ngOnDestroy(): void {
        if (this.widgetDragStreamSubscription) {
            this.widgetDragStreamSubscription.unsubscribe();
        }
    }
}

