import { Injectable } from '@angular/core';
import { ConversableType, ConversationHighlight, CrosstalkService } from '@ddv/crosstalk';
import { UserEntitlements } from '@ddv/entitlements';
import {
    crosstalkCommentFields,
    CrosstalkFields,
    ExportFilteredData,
    LinkConfiguration,
    TrebekConversationFields,
    UserDefinedField,
    WidgetData,
} from '@ddv/models';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { PublicApiResponseRow } from './dataset-manager.service';

@Injectable()
export class CrosstalkDataService {
    // this only gets updated if the user has the entitlement to import comments
    // i think what this is doing is adding these keys to exports (*_crosstalk_key) to make the exported file
    // format work easier to turn around and import comments
    private readonly crosstalkSchemaFields: Map<number | string, { name: string, displayName: string }[]> = new Map();

    constructor(private readonly crosstalkService: CrosstalkService) {}

    // this method is only useful as a side effect of what happens in fetchUpdatedConversations
    getCrosstalkSchemaFields(queryId: string | number): { name: string, displayName: string }[] | undefined {
        return this.crosstalkSchemaFields.get(queryId);
    }

    // this is only called by the advanced datagrid visualization
    fetchUpdatedConversations(
        clientCode: string,
        conversableType: string,
        datasetId: string | number,
        userEntitlements: UserEntitlements | undefined,
        data: (WidgetData & { links: { [key: string]: LinkConfiguration } })[],
    ): Observable<WidgetData[]> {
        return this.crosstalkService.getSchema(clientCode, conversableType)
            .pipe(
                mergeMap((schema) => {
                    if (userEntitlements?.hasCrosstalkCommentImport()) {
                        this.cacheImportKeysForDataset(datasetId, schema);
                    }

                    const keyValues = data.map((row) => schema.liftValuesForKeyFields(row));
                    return this.crosstalkService.fetchUpdatedConversations(clientCode, conversableType, keyValues)
                        .pipe(
                            map((highlights) => {
                                highlights.forEach((highlight, index) => {
                                    crosstalkCommentFields.forEach(() => {
                                        addCrosstalkAttachmentsToData(
                                            data[index],
                                            clientCode,
                                            highlight,
                                            userEntitlements?.canUploadCrosstalkAttachments() ?? false);
                                    });
                                });

                                return data;
                            }));
                }),
            );
    }

    // returning the keys that were added to the export data
    // this isn't the greatest as it's closely related to the ExportFilteredData interface
    // but i don't want to mess with that yet and at least we're getting everything
    // that's relevant clearly visible here on this method
    addCrosstalkImportKeysToData(
        datasetId: string | number,
        data: WidgetData[],
        exportData: Pick<ExportFilteredData, 'data'>,
        ignoreFirstRow: boolean,  // this is only true when exporting from an advanced grid with grouped data
    ): string[] {
        const crosstalkFields = this.getCrosstalkSchemaFields(datasetId);
        if (!crosstalkFields) {
            return [];
        }

        data.forEach((row, index: number) => {
            crosstalkFields?.forEach((field) => {
                let rowNumber = index;
                if (ignoreFirstRow) {
                    if (index === 0) {
                        exportData.data[index][field.name] = '';
                    }
                    // we increase index by 1, because there is one additional row in exportData.data for Control Total, which is not present in data
                    rowNumber += 1;
                }
                const valueToString = row[field.displayName]?.toString();
                exportData.data[rowNumber][field.name] = valueToString ? valueToString : '';
            });
        });

        return crosstalkFields.map((field) => field.name);
    }

    // this is only used for grouper-comments
    addCrosstalkFieldsToData(
        data: WidgetData & { links: { [key: string]: LinkConfiguration } },
        clientCode: string,
        highlight: ConversationHighlight,
        commenterType: CrosstalkFields.ClientComment | CrosstalkFields.HSComment,
        isUserEntitledToUploadAttachments: boolean,
    ): void {
        const comment = highlight[commenterType];
        const colId = `conversation${commenterType[0].toUpperCase()}${commenterType.substring(1)}`;

        data[colId] = comment?.message;
        data[`${colId}Author`] = comment?.createdBy;
        data[`${colId}Created`] = comment?.created ?? '';
        data[`${colId}_settings`] = highlight.settings;
        data.conversationId = highlight.id;

        addCrosstalkLinksToData(clientCode, data);
        addCrosstalkAttachmentsToData(
            data,
            clientCode,
            highlight,
            isUserEntitledToUploadAttachments,
        );
    }

    // this is only used for grouper comments
    addUDFsToData(
        udfs: UserDefinedField[],
        highlight: ConversationHighlight,
        data: WidgetData & { links: { [key: string]: LinkConfiguration } },
    ): void {
        if (highlight.userDefinedFields?.length) {
            highlight.userDefinedFields.forEach((udf) => {
                udf.conversationId = highlight.id;
                if ((udf.type === 'string' || udf.type === 'choice') && udf.value == null) {
                    udf.value = '';
                }
                data[`udf_${udf.name}`] = udf;
            });
        } else {
            udfs.forEach((udf) => {
                data[`udf_${udf.name}`] = {
                    conversationId: highlight.id,
                    name: udf.name,
                    type: udf.type,
                    valueEditableBy: udf.valueEditableBy,
                    value: (udf.type === 'string' || udf.type === 'choice') ? '' : undefined,
                };
            });
        }
    }

    private cacheImportKeysForDataset(datasetId: string | number, schema: ConversableType): void {
        this.crosstalkSchemaFields.set(
            datasetId,
            schema.fields.map((field) => {
                return {
                    name: `${field}_crosstalk_key`,
                    displayName: field,
                };
            }));
    }
}

function addCrosstalkAttachmentsToData(
    data: (WidgetData & { links: { [key: string]: LinkConfiguration } }),
    clientCode: string,
    highlight: ConversationHighlight,
    isUserEntitledToUploadAttachments: boolean,
): void {
    data[CrosstalkFields.Attachments] = highlight.lastAttachments?.length ? 'Attachments' : 'No Attachments';

    data.links = {
        ...data.links,
        [CrosstalkFields.Attachments]: {
            label: 'Add Comment',
            uri: `/two-way/#/${clientCode}/conversation/${highlight.id}`,
            launchType: 'modal',
            size: {
                width: 620,
                height: 600,
            },
            conversationId: highlight.id,
            clientCode,
            isUserEntitledToUploadAttachments,
            lastAttachments: highlight.lastAttachments,
        },
    };
}

export function addCrosstalkLinksToData(
    clientCode: string,
    data: PublicApiResponseRow | (WidgetData & { links: { [key: string]: LinkConfiguration } }),
): void {
    data.links = {
        ...data.links,
        [TrebekConversationFields.ClientComment]: {
            label: 'Add Comment',
            uri: `/two-way/#/${clientCode}/conversation/${data.conversationId}`,
            launchType: 'modal',
            size: {
                width: 620,
                height: 600,
            },
        },

        [TrebekConversationFields.HSComment]: {
            label: 'Add Comment',
            uri: `/two-way/#/${clientCode}/conversation/${data.conversationId}`,
            launchType: 'modal',
            size: {
                width: 620,
                height: 600,
            },
        },
    };
}
