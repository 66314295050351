export const lightBaseColors = [
    { label: 'Light Blue', value: '#E5F5FD' },
    { label: 'Blue', value: '#00AEEF' },
    { label: 'Dark Blue', value: '#07506D' },
    { label: 'Light Red', value: '#FDE1DE' },
    { label: 'Red', value: '#F47D7F' },
    { label: 'Dark Red', value: '#C30B1E' },
    { label: 'Light Green', value: '#ECF4E1' },
    { label: 'Green', value: '#C2DC9D' },
    { label: 'Dark Green', value: '#8DC449' },
    { label: 'Yellow', value: '#FFD050' },
    { label: 'Light Gray', value: '#F1F2F2' },
    { label: 'Gray', value: '#DFE1E6' },
    { label: 'Medium Gray', value: '#B7BCC6' },
    { label: 'Dark Gray', value: '#7E8C95' },
    { label: 'Black', value: '#5B676E' },
];

export const darkBaseColors = [
    { label: 'Light Blue', value: '#95C1DD' },
    { label: 'Blue', value: '#1485B6' },
    { label: 'Dark Blue', value: '#07506D' },
    { label: 'Light Red', value: 'rgba(195, 11, 30, 1.00)' },
    { label: 'Red', value: 'rgba(195, 11, 30, 0.68)' },
    { label: 'Dark Red', value: 'rgba(195, 11, 30, 0.30)' },
    { label: 'Light Green', value: 'rgba(141, 196, 73, 0.89)' },
    { label: 'Green', value: 'rgba(141, 196, 73, 0.50)' },
    { label: 'Dark Green', value: 'rgba(141, 196, 73, 0.20)' },
    { label: 'Yellow', value: '#F9BE14' },
    { label: 'Light Gray', value: 'rgba(250, 251, 255, 0.70)' },
    { label: 'Gray', value: 'rgba(250, 251, 255, 0.50)' },
    { label: 'Medium Gray', value: 'rgba(250, 251, 255, 0.30)' },
    { label: 'Dark Gray', value: 'rgba(250, 251, 255, 0.15)' },
    { label: 'Black', value: 'rgba(250, 251, 255, 0.08)' },
];
