import { IRowNode } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { ConversationEvent, CrosstalkRealtimeEvents, CrosstalkService } from '@ddv/crosstalk';
import { DataGridComponent } from '@ddv/data-grid';
import { CrosstalkDataService, WidgetDataSourceService } from '@ddv/datasets';
import { Entitlements, UserEntitlementService } from '@ddv/entitlements';
import {
    crosstalkCommentFields,
    CrosstalkFields,
    DatasetFetchKey,
    ROOT_NODE_ID,
    TrebekConversationFields,
    UserDefinedField,
    WidgetData,
} from '@ddv/models';
import { hashValue } from '@ddv/utils';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GrouperCommentData } from '../models/grouper-comment-data';

@Injectable()
export class GrouperCommentService {
    public readonly updateCustomFilters: Observable<boolean>;

    private readonly updateCustomFiltersSubject: Subject<boolean> = new Subject<boolean>();
    private readonly grouperCommentData: Map<string, GrouperCommentData> = new Map();
    private readonly conversationIdToGroupHashMap: Map<string, string> = new Map();
    private readonly tempKeyMap: Map<string, string> = new Map();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private grouperCommentFields: any = [];

    constructor(
        private readonly crosstalkService: CrosstalkService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly widgetDatasourceService: WidgetDataSourceService,
        private readonly crosstalkDataService: CrosstalkDataService,
    ) {
        this.updateCustomFilters = this.updateCustomFiltersSubject.asObservable();
    }

    fetchGroupComments(
        rowGroups: IRowNode[],
        clientCode: string,
        uniqueKey: DatasetFetchKey,
        conversableType: string,
    ): Observable<IRowNode[]> {
        this.grouperCommentFields = [];
        this.addGroupNodes(rowGroups);

        if (!this.grouperCommentFields.length) {
            return of([]);
        }

        const dataSource = this.widgetDatasourceService.getDataSource(uniqueKey);
        const data = dataSource?.originalData ?? dataSource?.data ?? [];

        return combineLatest([
            this.crosstalkService.fetchUpdatedConversations(clientCode, conversableType, this.grouperCommentFields),
            this.userEntitlementService.entitlementsForClientCode$,
            this.crosstalkService.userDefinedFields,
        ]).pipe(map(([highlights, entitlements, udfMap]) => {
            const isUserEntitledToUploadAttachments = entitlements.hasPermission(Entitlements.ATTACHMENT_EDIT) ||
                entitlements.hasPermission(Entitlements.CROSSTALK_RESTRICTED_ATTACHMENT);

            const udfs = udfMap.get(conversableType);
            highlights.forEach((highlight, index) => {
                rowGroups[index].setData({ ...rowGroups[index].data });

                crosstalkCommentFields.forEach((columnId) => {
                    this.crosstalkDataService.addCrosstalkFieldsToData(
                        rowGroups[index].data,
                        clientCode,
                        highlight,
                        columnId,
                        isUserEntitledToUploadAttachments);

                    updateDataSourceWithGroupComments(data, columnId, { conversationId: highlight.id, comment: highlight[columnId] });
                });

                const nonNumberUDFs = this.filterOutNumberUDFs(udfs ?? []);
                highlight.userDefinedFields = this.filterOutNumberUDFs(highlight.userDefinedFields ?? []);
                this.crosstalkDataService.addUDFsToData(nonNumberUDFs, highlight, rowGroups[index].data);

                if (this.grouperCommentFields[index]) {
                    const groupHash = hashValue(JSON.stringify(this.grouperCommentFields[index]));
                    this.grouperCommentData.set(groupHash, {
                        isUserEntitledToUploadAttachments,
                        highlight,
                    });
                    this.conversationIdToGroupHashMap.set(highlight.id, groupHash);
                }
            });

            this.updateCustomFiltersSubject.next(true);
            return rowGroups;
        }));
    }

    loadGroupCommentFromCache(
        rowGroups: IRowNode[],
        clientCode: string,
        dataGridComponent: DataGridComponent | undefined,
        conversableType: string,
    ): void {
        this.grouperCommentFields = [];
        this.addGroupNodes(rowGroups);

        if (this.grouperCommentFields.length && this.grouperCommentData.size) {
            this.crosstalkService.userDefinedFields.subscribe((udfMap) => {
                const udfs = udfMap.get(conversableType);

                rowGroups.forEach((group, index) => {
                    if (this.grouperCommentFields[index]) {
                        const data = this.grouperCommentData.get(hashValue(JSON.stringify(this.grouperCommentFields[index])));
                        if (data) {
                            if (typeof rowGroups[index].data !== 'object') {
                                rowGroups[index].setData({ ...rowGroups[index].data });
                            }
                            crosstalkCommentFields.forEach((columnId) => {
                                this.crosstalkDataService.addCrosstalkFieldsToData(
                                    rowGroups[index].data,
                                    clientCode,
                                    data.highlight,
                                    columnId,
                                    data.isUserEntitledToUploadAttachments);
                            });

                            const nonNumberUDFs = this.filterOutNumberUDFs(udfs ?? []);
                            data.highlight.userDefinedFields = this.filterOutNumberUDFs(data.highlight.userDefinedFields ?? []);
                            this.crosstalkDataService.addUDFsToData(nonNumberUDFs, data.highlight, rowGroups[index].data);

                            dataGridComponent?.refreshCells({ rowNodes: [rowGroups[index]], force: true });
                        }
                    }
                });
            });
        }
    }

    updateGroupCommentCache(newComment: ConversationEvent): void {
        const groupComment = this.grouperCommentData.get(this.conversationIdToGroupHashMap.get(newComment.conversationId) ?? '');
        if (groupComment) {
            if (newComment.name === CrosstalkRealtimeEvents.LAST_COMMENT_CHANGED) {
                // this is a problem
                // There are TWO enums: CrosstalkFields and TrebekConversationFields that do not overlap
                // the type for groupComment.highlight uses CrosstalkFields not TrebekConversationFields
                const commentColumn = newComment.comment?.isHedgeServComment || newComment.isHedgeServComment ?
                    TrebekConversationFields.HSComment :
                    TrebekConversationFields.ClientComment;

                if (newComment.comment) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (groupComment.highlight as any)[commentColumn] = newComment.comment;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } else if ((groupComment.highlight as any)[commentColumn]) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (groupComment.highlight as any)[commentColumn].message = '';
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (groupComment.highlight as any)[commentColumn].updated = '';
                }
            }

            if (newComment.name === CrosstalkRealtimeEvents.USER_DEFINED_FIELDS_CHANGED) {
                groupComment.highlight.userDefinedFields = newComment.userDefinedFields;
            }

            if (newComment.name === CrosstalkRealtimeEvents.LAST_ATTACHMENTS_CHANGED) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (groupComment as any)[CrosstalkFields.Attachments] = newComment.lastAttachments?.length ? 'Attachments' : 'No Attachments';
                // this has to be a mistake.  lastAttachments is an array
                // CrosstalkFields.attachment is a string
                // my guess is the type on Highlight.lastAttachments should actually be Record<string, Attachment[]>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (groupComment.highlight.lastAttachments as any)[CrosstalkFields.Attachments] = {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...(groupComment.highlight.lastAttachments as any)[CrosstalkFields.Attachments],
                    lastAttachments: newComment.lastAttachments,
                };
            }
        }
    }

    private addGroupNodes(groupNodes: IRowNode[]): void {
        groupNodes.forEach((group) => {
            this.generateGroupId(group);
            const key = Array.from(this.tempKeyMap);
            this.grouperCommentFields.push(key);
            this.tempKeyMap.clear();
        });
    }

    private generateGroupId(row: IRowNode | undefined): void {
        if (row?.id !== ROOT_NODE_ID) {
            this.tempKeyMap.set(row?.field ?? '', row?.key ?? '');
            this.generateGroupId(row?.parent ?? undefined);
        }
    }

    private filterOutNumberUDFs(udfs: UserDefinedField[]): UserDefinedField[] {
        return udfs.filter((udf) => udf.type !== 'decimal');
    }
}

function updateDataSourceWithGroupComments(data: WidgetData[], updateColumn: string, updateData: Partial<ConversationEvent>): void {
    const dataToUpdate = data.filter((d) => d.conversationId === updateData.conversationId);
    dataToUpdate.forEach((datum) => {
        datum[updateColumn] = updateData.comment?.message;
        datum[`${updateColumn}Author`] = updateData.comment?.createdBy;
        datum[`${updateColumn}Created`] = updateData.comment?.created;
    });
}
