<div class="add-datasets">
    <div class="search-box">
        <input
            type="search"
            class="formControl"
            name="searchAttributeFilter"
            autocomplete="off"
            [(ngModel)]="searchAttributeFilter"/>

        <span class="icon-search1"></span>
    </div>

    <ul class="dataset-list">
        <li *ngFor="let attribute of dashboardFiltersService.metadataList ?? [] | textValueFilter: searchAttributeFilter">
            <div
                class="empty-btn"
                (click)="onSelectAttribute(attribute)"
                [ngClass]="{'selected': (selectedDashboardFilter?.name === attribute.value)}"
                innerHTML="{{ attribute.text | highlight : searchAttributeFilter }}">
            </div>
        </li>
    </ul>
</div>
