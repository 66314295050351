import { Pipe, PipeTransform } from '@angular/core';
import { GroupedWidgets } from '@ddv/models';

@Pipe({
    name: 'filterWidgets',
})
export class FilterWidgetsPipe implements PipeTransform {
    transform(items: GroupedWidgets[], searchText: string): GroupedWidgets[] {
        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        const text = searchText.toLocaleLowerCase();

        const tempWidgets = JSON.parse(JSON.stringify(items));

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return tempWidgets.filter((item: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item.widgets = item.widgets.filter((widget: any) => {
                const name = widget.displayNameType === 'CUSTOM' ? widget.customDisplayName : widget.name;
                return name.toLowerCase().includes(text) ||
                    widget.description.toLocaleLowerCase().includes(text) ||
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    widget.widgetTags.some((tag: any) => tag.type === 'SHARED' && tag.name.toLocaleLowerCase().includes(text) ||
                    widget.datasetName.toLocaleLowerCase().includes(text));
            });

            if (item.widgets.length) {
                return item;
            }
        });
    }
}
