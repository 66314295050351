import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ddv-search-box',
    templateUrl: './search-box.component.html',
})
export class SearchBoxComponent {
    @Input() quickSearchPlaceholder: string | undefined;
    @Input() rowSearchText: string | undefined;
    @Output() quickFilterChanged: EventEmitter<string> = new EventEmitter();

    protected onInputChange(event: Event): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.quickFilterChanged.emit((event.target as any)?.value ?? '');
    }
}
