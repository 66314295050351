import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';

@Component({
    selector: 'app-bigw-table-toggle',
    templateUrl: './table-toggle.component.html',
    styleUrls: ['./table-toggle.component.scss'],
})
export class TableToggleComponent implements OnChanges {
    @Input() shown = true;
    @Output() shownChange: EventEmitter<boolean> = new EventEmitter();

    protected isHovered = false;
    protected text = 'Hide Table';

    ngOnChanges(): void {
        this.setText();
    }

    @HostListener('mousedown')
    protected toggle(): void {
        this.shown = !this.shown;
        this.setText();
        this.shownChange.emit(this.shown);
    }

    @HostListener('mouseenter')
    private onMouseEnter(): void {
        this.isHovered = true;
    }

    @HostListener('mouseleave')
    private onMouseLeave(): void {
        this.isHovered = false;
    }

    private setText(): void {
        this.text = `${this.shown ? 'Hide' : 'Show'} Table`;
    }
}
