// that probably needs to be removed and replaced with logic in DdvDate
import { DdvDate } from '@ddv/models';

export const dateFormatter = {
    // I'm pretty sure that only strings ever get passed into this function in real code, but there are a lot of tests that don't
    // eslint-disable-next-line complexity
    asDisplayValue: (
        value: unknown,
        {
            input = 'MM/DD/YYYY',
            output = 'MM/DD/YYYY',
        }: { [key: string]: string } = {},
    ): string => {
        let date: DdvDate;

        if (value instanceof Date) {
            date = DdvDate.fromDate(value);
        } else if (input === 'MM/DD/YYYY') {
            date = DdvDate.fromUSFormat(`${value}`);
        } else if (input === 'DD/MM/YYYY') {
            date = DdvDate.fromEUFormat(`${value}`);
        } else if (input === 'YYYY-MM-DD') {
            date = DdvDate.fromDashFormat(`${value}`);
        } else {
            date = DdvDate.fromUSFormat(`${value}`);
        }

        if (!date.isValid()) {
            return `${value}`;
        }

        if (output === 'QTR') {
            return date == null ? '' : `Q${date.quarter}`; // for financial quarters
        }

        if (output === 'MMM') {
            return date?.monthName ?? '';
        }

        if (output === 'DD') {
            return date?.day == null ? '' : (date.day < 10 ? '0' : '') + date.day.toString();
        }

        if (output === 'MM/DD/YYYY') {
            return date.toUSPaddedFormat();
        }

        if (output === 'M/D/YYYY') {
            return date.toUSFormat();
        }

        if (output === 'YYYY-MM-DD') {
            return date.toReversePaddedDashFormat();
        }

        if (output === 'MMM YYYY') {
            return `${date.monthName} ${date.year}`;
        }

        if (output === 'DD/MM/YYYY') {
            return date.toEUPaddedFormat();
        }

        if (output === 'YYYY') {
            return date.year?.toString() ?? '';
        }

        if (output === 'MM/DD') {
            return `${(date.month ?? 0) + 1}/${date.day}`;
        }

        return '';
    },
    asFilterValue: (value: string): Date => {
        return DdvDate.fromDashFormat(value).toDate();
    },
};
