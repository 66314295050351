<div class="configure">
    <span class="label-text">* Required Fields</span>

    <div class="disp-flex" cdkDropListGroup>
        <div class="left-flex">
            <app-field-picker-tree
                [isAttached]="isAttached"
                [tree]="groupedMetadata"
                [readonly]="isReadOnly"
                (dragStateChanged)="dragStateChanged($event)">
            </app-field-picker-tree>
        </div>

        <div class="right-flex">
            <ng-container *ngFor="let zone of dropZones;">
                <div class="value-drag-parent" [ngClass]="{'readonly': isReadOnly}">
                    <h3>{{ zone.label }}{{ zone.mandatory ? '*' : '' }}</h3>

                    <div class="drag-wrap {{zone.configKey}}"
                         cdkDropList
                         [cdkDropListData]="dataConfigs[zone.configKey]"
                         (cdkDropListDropped)="onCdkDropSuccess($event, zone)"
                         [ngClass]="{'disabled':!zone.allowed && activelyDragging,
                                     'allowed-area':zone.allowed && activelyDragging}">
                        <div
                                class="drag-list"
                                cdkDrag
                                [id]="i"
                                *ngFor="let item of dataConfigs[zone.configKey]; let i = index;"
                                cdkDragBoundary=".{{zone.configKey}}">

                            <span class="icon-threedots"></span>

                            <label class="item">{{ item.displayName }}</label>

                            <div class="dropdown-wrapper">
                                <span class="icon-arrow-down" (click)="showColumnFormatters(item, zone.id)"></span>
                            </div>

                            <span class="icon-close1" (click)="removeConfigAtIndex(zone.configKey, i)"></span>
                        </div>

                        <label class="drag-placeholder">
                            {{ zone.placeholder }}
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
