import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Injector,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { ColorChartComponent } from './color-chart/color-chart.component';
import { ConfigureComponent } from './configure/configure.component';
import { FormatComponent } from './format.component';

@Component({
    selector: 'app-dynamic-component',
    template: '<div #dynamicComponentContainer></div>',
})
export class DynamicComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentComponent: ComponentRef<any> | undefined;
    isReadOnly = false;
    isTableSortDirectionCustom = false;

    @Output() componentAdded = new EventEmitter<ComponentRef<ColorChartComponent | ConfigureComponent | FormatComponent>>();

    @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) dynamicComponentContainer: ViewContainerRef | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set componentData(data: { component: any, inputs: any }) {
        if (!data) {
            return;
        }
        const inputProviders = Object.keys(data.inputs).map((inputName) => {
            return { provide: inputName, useValue: data.inputs[inputName] };
        });
        const options = { providers: inputProviders, parent: this.dynamicComponentContainer?.parentInjector };
        const injector = Injector.create(options);
        const factory = this.resolver.resolveComponentFactory(data.component);
        const component = factory.create(injector);
        this.dynamicComponentContainer?.insert(component.hostView);
        if (this.currentComponent) {
            this.currentComponent?.destroy();
        }
        this.currentComponent = component;
        this.currentComponent.instance.isReadOnly = this.isReadOnly;
        this.currentComponent.instance.isTableSortDirectionCustom = this.isTableSortDirectionCustom;
        this.componentAdded.emit(this.currentComponent);
    }

    constructor(private readonly resolver: ComponentFactoryResolver) { }
}
