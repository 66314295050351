export const enum FundAvailability {
    BOTH = 'both',
    HSMAIN = 'hsmain',
    HI = 'hi'
}

export const enum PortfolioLevel {
    MULTI = 'Multi Compound',
    FUND = 'Fund',
    INVESTOR_FUND = 'Investor Fund'
}

export class Fund {
    portfolioFundCode?: string;
    portfolioLegalName?: string;
    portfolioClientCode?: string;
    investorFundCode?: string;
    investorLegalName?: string;
    availability?: string;
    multiCompound?: boolean;
    fundNamesSource: string;

    constructor(init: Partial<Fund>, fundNamesSource: string) {
        Object.assign(this, init);
        this.fundNamesSource = fundNamesSource;
    }

    get fundId(): string {
        if (this.availability === FundAvailability.HSMAIN ||
            (this.availability === FundAvailability.BOTH && this.fundNamesSource === FundAvailability.HSMAIN)) {
            return this.portfolioFundCode ?? 'PORTFOLIO FUND CODE WAS UNDEFINED AND SHOULD NOT HAVE BEEN';
        } else {
            return this.investorFundCode ?? 'INVESTOR FUND CODE WAS UNDEFINED AND SHOULD NOT HAVE BEEN';
        }
    }

    get fundName(): string {
        if (this.availability === FundAvailability.HSMAIN ||
            (this.availability === FundAvailability.BOTH && this.fundNamesSource === FundAvailability.HSMAIN)) {
            return this.portfolioLegalName ?? 'PORTFOLIO LEGAL NAME WAS UNDEFINED AND SHOULD NOT HAVE BEEN';
        } else {
            return this.investorLegalName ?? 'INVESTOR LEGAL NAME WAS UNDEFINED AND SHOULD NOT HAVE BEEN';
        }
    }

    get portfolioLevel(): string {
        switch (this.availability) {
            case FundAvailability.HSMAIN: return this.multiCompound ? PortfolioLevel.MULTI : PortfolioLevel.FUND;
            case FundAvailability.HI: return PortfolioLevel.INVESTOR_FUND;
            case FundAvailability.BOTH: return PortfolioLevel.FUND;
            default: return 'SWITCH STATEMENT FALL THROUGH SHOULD NEVER HAPPEN';
        }
    }

    get isHiOrBoth(): boolean {
        return this.availability === FundAvailability.HI || this.availability === FundAvailability.BOTH;
    }

    get isHsMainOrBoth(): boolean {
        return this.availability === FundAvailability.HSMAIN || this.availability === FundAvailability.BOTH;
    }

    sameAs(other: Fund): boolean {
        return (this.fundId === other.fundId) ||
            (this.availability === FundAvailability.BOTH &&
            other.availability === FundAvailability.BOTH &&
            (this.portfolioFundCode === other.portfolioFundCode || this.investorFundCode === other.investorFundCode));
    }

    isIncludedInArrayOfIds(fundIds: string[]): boolean {
        return fundIds.includes(this.fundId) ||
            (
                this.availability === FundAvailability.BOTH &&
                (
                    (!!this.portfolioFundCode && fundIds.includes(this.portfolioFundCode)) ||
                    (!!this.investorFundCode && fundIds.includes(this.investorFundCode))
                )
            );
    }
}
