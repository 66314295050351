<form *ngIf="dashboardForm"
        [formGroup]="dashboardForm"
        novalidate
        class="dashboard-form"
        (ngSubmit)="onSaveDashboard(dashboardForm.value)">
    <h2 class="properties-modal-header" *ngIf="selectedOption === 'edit' && !isDashboardCopy">View Properties</h2>

    <div class="view-name">
        <label for="view-name">
            {{ !isDashboardCopy ? 'Name' : 'Save as' }}

            <span>(Maximum of 40 characters)</span>
        </label>

        <input
            id="view-name"
            type="text"
            formControlName="name"
            maxlength="40"
            placeholder="Maximum of 40 characters"
            autocomplete="off"
            [ngClass]="{'errorborderactive': dashboardFormErrors.name}"
            [readOnly]="isReadOnly || isGlobalEditPartial"
            (focusout)="checkNameAvailability()"/>

        <div *ngIf="dashboardFormErrors.name" class="error-message">{{ dashboardFormErrors.name }}</div>
    </div>

    <div class="abrv">
        <label for="abbr">ABRV</label>

        <input
            id="abbr"
            type="text"
            formControlName="abbreviation"
            maxlength="4"
            placeholder="XXXX"
            autocomplete="off"
            [readOnly]="isReadOnly || isGlobalEditPartial"/>
    </div>

    <div class="group-name" *ngIf="dashboardGroupName">
        <label for="group-name">Family
            <span>(Maximum of 40 characters)</span>
        </label>

        <input
            id="group-name"
            class="readonly"
            type="text"
            maxlength="40"
            placeholder="Maximum of 40 characters"
            autocomplete="off"
            [ngClass]="{'errorborderactive': dashboardFormErrors.family}"
            [readOnly]="true"
            [value]="dashboardGroupName"/>

        <div *ngIf="dashboardFormErrors.family" class="error-message">{{ dashboardFormErrors.family }}</div>
    </div>

    <div class="description">
        <label for="description">Description</label>

        <textarea
            id="description"
            formControlName="description"
            placeholder="Add View Description"
            autocomplete="off"
            [readOnly]="isReadOnly || isGlobalEditPartial">
        </textarea>
    </div>

    <div class="sharing">
        <label>Sharing</label>

        <div class="radio-btn" [hidden]="isReadOnly && !sharing_private.checked" [class.readonly]="isReadOnly || isGlobalEditPartial">
            <input
                id="sharing_private" #sharing_private
                name="source"
                type="radio"
                formControlName="source"
                value="private"
                [attr.checked]="sharing_private.checked ? 'checked' : null"
                [attr.disabled]="(isReadOnly || isGlobalEditPartial) ? true : null"/>

            <label for="sharing_private">
                <span [hidden]="isReadOnly && sharing_private.checked"></span>Private
            </label>
        </div>

        <div class="radio-btn" [hidden]="isReadOnly && !sharing_shared.checked" [class.readonly]="isReadOnly || isGlobalEditPartial">
            <input
                id="sharing_shared" #sharing_shared
                name="source"
                type="radio"
                value="shared"
                formControlName="source"
                [attr.disabled]="(isReadOnly || isGlobalEditPartial) ? true : null"
                [attr.checked]="sharing_shared.checked ? 'checked' : null"/>

            <label for="sharing_shared">
                <span [hidden]="isReadOnly && sharing_shared.checked"></span>Shared
            </label>
        </div>
    </div>

    <div class="settings" *ngIf="isGlobal">
        <label>Settings</label>

            <div class="global-indicator">
                <ddv-global-icon></ddv-global-icon>
                Global
            </div>
    </div>

    <div class="app-user-select" *ngIf="dashboardForm!.value!.source === 'shared'" [class.readonly]="isReadOnly">
        <app-user-select
            id="view-user-select"
            appScrollToChild
            [title]="'View only:'"
            [featureType]="'DASHBOARD'"
            [(selectedUsers)]="dashboardPermissions"
            [permission]="'VIEW'">
        </app-user-select>

        <app-user-select
            id="edit-user-select"
            appScrollToChild
            [title]="'View &amp; Edit:'"
            [featureType]="'DASHBOARD'"
            [(selectedUsers)]="dashboardPermissions"
            [permission]="'EDIT'">
        </app-user-select>
    </div>

    <div class="tag user-tags">
        <label>USER TAGS</label>

        <app-custom-autocompleter appScrollToChild [config]="tagsConfiguration"></app-custom-autocompleter>
    </div>

    <div class="tag shared-tags">
        <label>SHARED TAGS</label>

        <app-custom-autocompleter appScrollToChild [config]="tagsConfiguration"></app-custom-autocompleter>
    </div>

    <div class="form-actions">
        <button class="empty-btn" type="button" (click)="closeForm.emit()">Cancel</button>

        <button class="ghost-btn" type="submit" [disabled]="disableSaveButton()">{{saveButtonText}}</button>
    </div>
</form>
