import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Client } from '@ddv/models';

import { AdvancedGridConfigService } from '../../services/advanced-grid-config.service';

@Component({
    selector: 'app-upload-modal',
    templateUrl: './upload-modal.component.html',
    styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent {
    @Input() client: Client | undefined;
    @Output() closeModal = new EventEmitter<string>();
    fileType: string | undefined;
    fileName: string | undefined;
    fileSize: number | undefined;
    fileSelected: boolean | undefined;
    private file: File | undefined;

    constructor(private readonly gridConfigService: AdvancedGridConfigService) { }

    onFileSelected($event: Event): void {
        this.file = ($event.target as HTMLInputElement).files?.[0];
        this.fileType = this.file?.type;
        this.fileName = this.file?.name;
        this.fileSize = (this.file?.size ?? 0) / 1000;
        this.fileSelected = true;
    }

    closeUploadModal(outcome: string): void {
        this.closeModal.emit(outcome);
    }

    uploadFile(): void {
        if (!this.file) {
            return;
        }

        const url = '/tfl/upload/';
        const method = 'POST';
        const formData = new FormData();
        formData.append(this.fileName ?? '', this.file, this.file.name);
        this.gridConfigService.uploadFile(this.client?.clientName ?? '', url, method, formData)
            .subscribe({
                next: (response) => this.closeUploadModal(response.success ? 'Success' : 'Failed'),
                error: () => this.closeUploadModal('Failed'),
            });
    }
}
