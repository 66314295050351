import { DEFAULT_WIDGET_POSITION, MANAGE_WIDGET_ID, MANAGE_WIDGET_WS_ID } from '@ddv/models';

export const WS_DEFINITION = {
    MANAGE_WIDGET: {
        id: MANAGE_WIDGET_WS_ID,
        allowInfiniteHScroll: false,
        allowInfiniteVScroll: true,
        dashboardTags: [],
        layoutType: 'custom',
        name: '',
        widgets: [
            {
                id: MANAGE_WIDGET_ID,
                coreWidgetType: 'LIVE_DEMO_WIDGET',
                maximized: false,
                enableWidgetHeader: true,
                widgetPosition: DEFAULT_WIDGET_POSITION,
                enableCompareMode: false,
            },
        ],
    },
};
