import {
    Component,
    HostBinding,
    Output,
    EventEmitter,
    Input,
    ElementRef,
    Renderer2,
    OnInit,
    AfterViewInit,
    OnDestroy,
    HostListener,
} from '@angular/core';

@Component({
    selector: 'app-modal-dialog-window',
    template: `
    <div [class]="'modal-dialog-document' + (size ? ' modal-dialog-' + size : '')" role="document">
        <div class="modal-dialog-close" (click)="dismiss('CLOSE_BTN')"><span></span></div>
        <div class="modal-dialog-content"><ng-content></ng-content></div>
    </div>
    `,
})
export class ModalDialogWindowComponent implements OnInit, AfterViewInit, OnDestroy {
    private elWithFocus: Element | null = null;
    private shown = false;
    private readonly timeout = 20;

    @Input() backdrop: boolean | string = true;
    @Input() keyboard = true;
    @Input() size: string | undefined;
    @Input() windowClass: string | undefined;

    @Output() dismissEvent = new EventEmitter();

    @HostBinding('class.shown') get fadeIn(): boolean {
        return this.shown;
    }
    @HostBinding('attr.class') modalWindowClass: string | undefined;
    @HostBinding('attr.role') role = 'dialog';
    @HostBinding('attr.tabindex') tabIndex = -1;
    @HostListener('mousedown', ['$event']) onClick(event: MouseEvent): void {
        this.backdropClick(event);
    }
    @HostListener('keydown.escape', ['$event']) onKeyDown(event: KeyboardEvent): void {
        this.escKey(event);
    }

    constructor(
        private readonly elRef: ElementRef,
        private readonly renderer: Renderer2) {}

    backdropClick(event: MouseEvent): void {
        if (this.backdrop === true && this.elRef.nativeElement === event.target) {
            this.dismiss('BACKDROP_CLICK');
        }
    }

    escKey(event: KeyboardEvent): void {
        if (this.keyboard && !event.defaultPrevented) {
            this.dismiss('ESC');
        }
    }

    dismiss(reason: unknown): void { this.dismissEvent.emit(reason); }

    ngOnInit(): void {
        this.elWithFocus = document.activeElement;
        this.renderer.addClass(document.body, 'modal-dialog-open');

        const windowClass = this.windowClass ? ` ${this.windowClass}` : '';
        this.modalWindowClass = `modal-dialog-window${windowClass}`;

        setTimeout(() => {
            this.shown = true;
        }, this.timeout);
    }

    ngAfterViewInit(): void {
        if (!this.elRef.nativeElement.contains(document.activeElement)) {
            this.elRef.nativeElement.focus.apply(this.elRef.nativeElement, []);
        }
    }

    ngOnDestroy(): void {
        const body = document.body;
        const elWithFocus = this.elWithFocus;

        let elementToFocus: HTMLElement;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((elWithFocus as any)?.focus && body.contains(elWithFocus)) {
            elementToFocus = elWithFocus as HTMLElement;
        } else {
            elementToFocus = body;
        }
        elementToFocus.focus.apply(elementToFocus, []);

        this.elWithFocus = null;
        this.renderer.removeClass(body, 'modal-dialog-open');
    }
}
