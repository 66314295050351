import { FieldMetadata } from '../fields/field-metadata';
import { CrosstalkFields } from './constants';

export const crosstalkFieldMetadatas: FieldMetadata[] = [
    {
        agBand: undefined,
        alignment: 'left',
        canAggregate: false,
        canPivotOn: false,
        datatype: 'string',
        description: 'Number of latest comments by HedgeServ and client users',
        displayName: CrosstalkFields.CommentCounter,
        displayType: 'string',
        format: undefined,
        hierarchy: 'Conversable Type Attributes',
        isAttribute: true,
        isOrdinal: false,
        name: CrosstalkFields.CommentCounter,
        value: CrosstalkFields.CommentCounter,
        zeroIfNone: false,
    },
    {
        agBand: undefined,
        alignment: 'left',
        canAggregate: false,
        canPivotOn: true,
        datatype: 'string',
        description: 'Attachments add, download and view',
        displayName: CrosstalkFields.Attachments,
        displayType: 'string',
        format: undefined,
        hierarchy: 'Conversable Type Attributes',
        isAttribute: true,
        isOrdinal: false,
        name: CrosstalkFields.Attachments,
        value: CrosstalkFields.Attachments,
        zeroIfNone: false,
    },
];
