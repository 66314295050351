import { crosstalkCellEditors, crosstalkCellRenderers } from '@ddv/crosstalk';

import {
    CheckboxCellRendererComponent,
} from './cell-renderers/checkbox-cell-renderer/checkbox-cell-renderer.component';
import {
    ColumnConditionCellRendererComponent,
} from './cell-renderers/column-condition-cell-renderer/column-condition-cell-renderer.component';
import {
    DatetimeCellRendererComponent,
} from './cell-renderers/datetime-cell-renderer/datetime-cell-renderer.component';
import { URLCellRendererComponent } from './cell-renderers/url-cell-renderer/url-cell-renderer.component';
import { CustomColumnFilterComponent } from './components/custom-column-filter/custom-column-filter.component';
import { GroupTooltipComponent } from './components/group-tooltip/group-tooltip.component';
import { CheckboxHeaderComponent } from './headers/checkbox-header/checkbox-header.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cellRenderers: Record<string, new (...args: any[]) => any> = {
    checkboxCellRendererComponent: CheckboxCellRendererComponent,
    columnConditionCellRendererComponent: ColumnConditionCellRendererComponent,
    datetimeCellRendererComponent: DatetimeCellRendererComponent,
    urlCellRendererComponent: URLCellRendererComponent,
    ...crosstalkCellRenderers,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cellEditors: Record<string, new (...args: any[]) => any> = {
    ...crosstalkCellEditors,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const headers: Record<string, new (...args: any[]) => any> = {
    checkboxHeaderComponent: CheckboxHeaderComponent,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const allFrameworkComponents: Record<string, new (...args: any[]) => any> = {
    customColumnFilterComponent: CustomColumnFilterComponent,
    groupTooltipComponent: GroupTooltipComponent,
    ...cellRenderers,
    ...cellEditors,
};
