import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiServices, SharedApiExecutorService } from '@ddv/http';
import { Observable, ReplaySubject, Subject, map } from 'rxjs';

@Injectable()
export class FeatureFlagService {
    private clientCode = '';
    private readonly subjects: Record<string, Subject<boolean>> = {};

    constructor(
        @Inject(ApiServices.flags) private readonly flagsApiExecutor: SharedApiExecutorService,
        private readonly currentState: CurrentStateService,
    ) {
        this.currentState.clientCode$.subscribe((clientCode) => {
            this.clientCode = clientCode;
            this.refreshFlags();
        });
    }

    isFlagEnabled(name: string): Observable<boolean> {
        if (this.subjects[name]) {
            return this.subjects[name].asObservable();
        }

        return this.fetchFlag(name);
    }

    private fetchFlag(name: string): Observable<boolean> {
        this.subjects[name] = new ReplaySubject(1);
        this.callApi(name);
        return this.subjects[name].asObservable();
    }

    private refreshFlags(): void {
        Object.keys(this.subjects).forEach((name) => this.callApi(name));
    }

    private callApi(name: string): void {
        this.flagsApiExecutor.invokeServiceWithParams<{ enabled: boolean }>(`/user/${name}/${this.clientCode}`)
            .pipe(map((response) => this.subjects[name].next(response.enabled)))
            .subscribe();
    }
}
