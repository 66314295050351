import { Input, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appAutoFocus]',
})
export class AutoFocusDirective {
    @Input() set focus(enableFocus: boolean) {
        if (enableFocus) {
            this.elementref.nativeElement.focus();
        }
    }

    constructor(private readonly elementref: ElementRef) {
    }
}
