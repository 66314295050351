import { Component, Input } from '@angular/core';

import { ModalDialogActive } from '../modal-dialog/modal-dialog-ref.service';

@Component({
    selector: 'app-error-modal',
    templateUrl: 'error-modal.component.html',
    styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
    @Input() exception = { message: '', time: '' };
    @Input() widgetName: string | undefined;

    constructor(private readonly modalDialogActive: ModalDialogActive) {}

    onClose(): void {
        this.modalDialogActive.close();
    }
}
