import { Injectable } from '@angular/core';
import { DatedPublicApiResponseRow } from '@ddv/datasets';
import * as d3 from 'd3';

import { BarChartData } from '../../models/chart-data';
import { Tooltip } from '../../models/tooltip';
import { safeStringFormatter } from '../../safe-string-formatter';
import { ValueAggregationService } from '../../services/value-aggregation.service';
import { ValueFormatterService } from '../../services/value-formatter.service';

export type PagePosition = Pick<MouseEvent, 'pageX' | 'pageY'>;

@Injectable()
export class HorizontalBarChartTooltipService {
    private tooltip: d3.Selection<HTMLDivElement, undefined, HTMLElement, undefined> | undefined;

    constructor(
        private readonly valueFormatterService: ValueFormatterService,
        private readonly valueAggregationService: ValueAggregationService<DatedPublicApiResponseRow>,
    ) {}

    createOrMoveTooltip(
        data: BarChartData,
        event: PagePosition,
        config: Tooltip[] = [],
    ): void {
        if (!this.tooltip) {
            this.createTooltip(config, data);
        }

        this.moveTooltip(event);
    }

    clearTooltip(): void {
        this.tooltip?.remove();
        this.tooltip = undefined;
    }

    private createTooltip(
        config: Tooltip[],
        data: BarChartData,
    ): void {
        const html = this.buildTooltip(config, data);
        this.tooltip = d3.select<HTMLBodyElement, undefined>('body')
            .append('div')
            .attr('class', 'toolTip bar')
            .style('opacity', 1)
            .style('position', 'fixed')
            .style('z-index', 5000)
            .html(html);
    }

    private buildTooltip(tooltipsItems: Tooltip[], hoveredBar: BarChartData): string {
        let html = '<div class="tooltip-data"><table class="disp-table">';

        const name = hoveredBar.key.value.toString().trim() ? safeStringFormatter(hoveredBar.key.value) : 'Blanks';
        html += `<tr><td class="item-title">${name.toUpperCase()}</td></tr>`;

        const tooltipEntries = tooltipsItems.map((tooltip) => {
            const itemLabel = `${tooltip.aggregationType?.split(/(?=[A-Z])/).join(' ')} of ${tooltip.showCustomName ? tooltip.customName : tooltip.label}`;
            const aggregateValue = this.valueAggregationService.aggregateValue(hoveredBar.sourceRows, tooltip.key ?? '', tooltip.aggregationType);
            const formattedValue = this.valueFormatterService.applyFormatter(aggregateValue, tooltip);

            return {
                itemLabel,
                itemValue: formattedValue == null ? 'Null' : (formattedValue.toString().trim() ? formattedValue : 'Blanks'),
            };
        });

        if (tooltipEntries.length) {
            tooltipEntries.forEach((entry) =>
                html += `<tr class="tooltip-item-row">
                            <td class="item-label">${entry.itemLabel}</td>
                            <td class="item-value">${entry.itemValue}</td>
                         </tr>`);
        }

        return `${html}</table></div>`;
    }

    private moveTooltip(event: PagePosition): void {
        this.tooltip?.style('left', `${event.pageX + 15}px`)
            .style('top', `${event.pageY + 15}px`);
    }
}
