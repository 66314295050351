<div class="tab-container">
    <span *ngIf="navArrowsVisible"
        class="icon-left-expand"
        [class.disabled-icon]="!prevNavPermits"
        (click)="onNavigation('prev')">
    </span>

    <div #scrollContainer
        class="parent-tab border-none"
        [class.visibleicon]="nextNavPermits || prevNavPermits"
        (scroll)="onScroll($event)">
        <ng-container *ngFor="let vizTab of vizTabs; let vti=index">
            <ul class="navTabs scrollmenu"
                [class.max-width]="nextNavPermits || prevNavPermits"
                cdkDropList cdkDropListOrientation="horizontal"
                [cdkDropListData]="vizTab.vizTabs"
                (cdkDropListDropped)="onDragEnd($event)">

                <li *ngIf="!vizTab.isViz; else elseBlock"
                    #headers
                    id="{{vizTab.guid}}"
                    cdkDrag
                    [ngClass]="{'active': vizTab.active, 'readonly': isReadOnly}">
                    <a (click)="navTabSelection(vizTab)">
                        <label [ngClass]="vizTab.cssClass ? vizTab.cssClass : vizTab.cssClass">{{vizTab.label}}</label>
                        <span class="add-icon icon-close1" (click)="removeSelectedTab(vizTab, vti, 'root')"></span>
                    </a>
                </li>

                <ng-template #elseBlock>
                    <li *ngFor="let tab of vizTab.vizTabs; let ti=index"
                        id="{{tab.guid}}"
                        #headers
                        cdkDrag
                        [ngClass]="{'active': tab.active, 'readonly': isReadOnly}"
                        (dragend)="onNavTabDropEnd()">

                        <ng-container *ngIf="!vizTab.isColumnGrid;">
                            <a (click)="navTabSelection(tab)">
                                <label [ngClass]="tab.cssClass">{{tab.label}}
                                    <ddv-stacked-area-chart-icon
                                        *ngIf="isStackedAreaChartVizType(tab.visualizationType)">
                                    </ddv-stacked-area-chart-icon>
                                </label>

                                <span
                                    class="add-icon icon-close1"
                                    [ngClass]="{'stacked-area-chart': isStackedAreaChartVizType(tab.visualizationType)}"
                                    (click)="removeSelectedTab(tab, ti, '')">
                                </span>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="vizTab.isColumnGrid;">
                            <a (click)="navTabSelection(tab)">
                                <span>{{tab.label}}</span>
                                <span class="add-icon icon-close1" (click)="removeSelectedTab(tab, ti, '')"></span>
                            </a>
                        </ng-container>
                    </li>
                </ng-template>
            </ul>
        </ng-container>
    </div>

    <span *ngIf="navArrowsVisible"
        class="icon-right-expand"
        [class.disabled-icon]="!nextNavPermits"
        (click)="onNavigation('next')">
    </span>
</div>
