import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { DdvDate } from '@ddv/models';
import { Observable } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

import { FundPeriodsInfo } from './fund-periods-info';
import { HiDate } from './hi-date';

@Injectable()
export class HiDatesService {
    private hiDates$: Observable<HiDate[]> | undefined;

    constructor(
        private readonly currentState: CurrentStateService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
    ) {}

    private get hiDatesEndpoint(): Observable<{ clientCode: string, route: string }> {
        return this.currentState.clientCode$
            .pipe(map((clientCode) => {
                return {
                    clientCode,
                    route: 'investors/fundsPeriodsInfo',
                };
            }));
    }

    hiDates(): Observable<HiDate[]> {
        if (!this.hiDates$) {
            this.hiDates$ = this.getFundsPeriodsInfo()
                .pipe(map((raw) => this.transformToHiDates(raw)))
                .pipe(shareReplay(1)); // replays only most recent
        }

        return this.hiDates$;
    }

    private getFundsPeriodsInfo(): Observable<FundPeriodsInfo[]> {
        return this.hiDatesEndpoint
            .pipe(mergeMap(({ clientCode, route }) => {
                return this.trebekApiExecutor.invokeServiceWithBody<FundPeriodsInfo[]>(clientCode, route, 'POST', { includeFunds: [] });
            }));
    }

    private transformToHiDates(fundsPeriodsInfo: FundPeriodsInfo[]): HiDate[] {
        const dates = new Map<string, number>(); // key is the date, value is the number of the funds posted
        let numFunds = 0;

        fundsPeriodsInfo.forEach((fpi) => {
            numFunds += 1;
            fpi.periodsInfo.forEach((pi) => {
                if (!dates.has(pi.date)) {
                    dates.set(pi.date, 0);
                }

                if (pi.posted) {
                    dates.set(pi.date, (dates.get(pi.date) ?? 0) + 1);
                }
            });
        });

        const result: HiDate[] = [];
        dates.forEach((numFundsPosted, date) => {
            result.push(new HiDate({
                date: DdvDate.fromUSFormat(date),
                numPosted: numFundsPosted,
                numTotal: numFunds,
            }));
        });
        result.sort((a, b) => b.date.toMilliseconds() - a.date.toMilliseconds());

        return result;
    }
}
