<div class="column-formatting-form">
    <form *ngIf="configForm" [formGroup]="configForm" (ngSubmit)="modalDialogActive.close(configForm.value)">
        <div class="section column-name">
            <div class="subsection">
                <div class="title">Column Name</div>

                <div class="column-label">{{ parentData?.label }}</div>
            </div>

            <div class="subsection">
                <div class="content">
                    <div class="radio-btn">
                        <input
                            #display_as_configured
                            id="display_as_configured"
                            name="showCustomName"
                            formControlName="showCustomName"
                            type="radio"
                            [attr.checked]="display_as_configured.checked ? 'checked' : null"
                            [value]=false
                            (change)="toggleCustomNameField()" />

                        <label for="display_as_configured">As Configured</label>
                    </div>

                    <div class="radio-btn">
                        <input
                            #display_as_custom
                            id="display_as_custom"
                            name="showCustomName"
                            formControlName="showCustomName"
                            type="radio"
                            [attr.checked]="display_as_custom.checked ? 'checked' : null"
                            [value]=true
                            (change)="toggleCustomNameField()" />

                        <label for="display_as_custom">Custom</label>
                    </div>
                </div>

                <div class="content">
                    <input
                        type="text"
                        class="formControl"
                        name="displayname"
                        autocomplete="off"
                        formControlName="customName"
                        placeholder="Display Name"
                        maxlength="20" />
                </div>
            </div>
        </div>

        <div class="section" *ngIf="!parentData?.hideAggType">
            <div class="subsection">
                <div class="title">Aggregation Type</div>

                <div class="custom-select">
                    <app-dropdown
                        name="aggregationtype"
                        formControlName="aggregationType"
                        [options]="aggTypes">
                    </app-dropdown>
                </div>
            </div>
        </div>

        <div class="section general-formatting" *ngIf="!parentData?.hideGenFormatting && parentData?.displayType === 'value'">
            <p class="title">General Formatting</p>

            <div class="subsection">
                <div class="title">Format</div>

                <div class="custom-select">
                    <app-dropdown
                        name="numberFormat"
                        formControlName="numberFormat"
                        [options]="numberFormats">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection" *ngIf="parentData?.displayType === 'value'">
                <div class="title">Decimal Places</div>

                <input
                    type="number"
                    min="0"
                    name="decimal"
                    formControlName="decimalPlaces" />
            </div>
        </div>

        <div class="save-cancel">
            <button
                    class="empty-btn"
                    type="button"
                    (click)="modalDialogActive.close('cancel')">
                Cancel
            </button>

            <button
                    class="fill-btn"
                    type="submit"
                    [disabled]="!configForm.valid">
                Apply
            </button>
        </div>
    </form>
</div>
