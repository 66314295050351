import { AgGridSortDirection } from '../data-grid/sort-direction';
import { NumberFormat, NumberUnit } from '../numbers';
import { IAxis } from './axis';
import { TableSortType } from './table-sort-type';
import { VisualizationType } from './visualization-type';
import { VizConfigs } from './viz-configs';
import { IZoomLevelInfo } from './zoom-level-info';

export class VisualizationConfigs {
    id?: number;
    isDefault?: boolean;
    visualizationType: VisualizationType = 'SIMPLE_GRID';
    enableTotal?: boolean;
    enableTooltip?: boolean;
    enableLabels?: boolean | null;
    labelStyle?: 'showLines' | 'hideLines' | 'inline' | null;
    enableInsideLabel?: boolean;
    labelOrientation?: 'horizontal' | 'vertical' | null;
    enableTotalLabel?: boolean | null;
    highlightSlice?: boolean;
    inChartLabel?: boolean;
    showBrush?: boolean;
    zoomLevels?: IZoomLevelInfo[] = [];
    showGridLines?: boolean;
    gridLineStyle?: string;
    slicer?: string | null;
    stacked?: boolean;
    xAxis?: string | null;
    yAxis?: string | null;
    axisConfigurations: IAxis[] = [];
    showHoverLines?: boolean;
    numberUnits?: NumberUnit;
    numberFormat?: NumberFormat;
    decimalPlaces?: number;
    enableXAxisLabels?: boolean;
    enableYAxisLabels?: boolean;
    enableLegend?: boolean;
    legendPosition?: string;
    displaySeparator?: boolean;
    displayWidgetName?: boolean;
    enableSliceManagement?: boolean;
    sliceManagementType?: string;
    groupByPercent?: number;
    groupByMaxCount?: number;
    showOthers?: boolean = true;
    colorRange?: string[] = [];
    bottomXAxisOrientation?: string;
    leftYAxisLabels?: boolean;
    configs?: VizConfigs;
    sortTableBy?: TableSortType;
    tableSortDirection?: string;
    sortOrderSlicer?: string;

    // Used for Grid only
    showGrandTotal?: boolean | null;
    showTotalAtTop?: boolean | null;
    showSubTotal?: boolean | null;
    showSubTotalAtTop?: boolean | null;
    alternateRowShading?: boolean | null;
    rowHeight?: number | null;
    showHyperlinks?: boolean | null;
    repeatGroupData?: boolean | null;
    autoSizeColumns?: boolean | null;
    showColumnHeaderToolTip?: boolean | null;
    isPivotMode?: boolean | null;
    hideGridHeaders?: boolean;
    showQuickSearch?: boolean;
    distinctRowsOnly?: boolean;
    groupDefaultExpanded?: number | null;
    groupSort?: AgGridSortDirection | null;
    groupSortIndex?: number | null;
    customWidthWhenGroup?: number;
    displayTotal?: boolean | null;
    displayTotalLabel?: string;
    showGrouperCount?: boolean | null;
    autoAddDynamicColumns?: boolean | null;
    useCustomHeaderNames?: boolean | null;

    // this is being returned by the API but was not previously on the type
    customName?: string | null;
    // this is being returned by the API but was not previously on the type
    showCustomName?: string | null;

    public constructor(init?: Partial<VisualizationConfigs>) {
        Object.assign(this, init);
        this.configs = new VizConfigs(this.configs);
    }

    // the ? is here so that we can pass things that aren't actually classes of type around as
    // though they are.  for example, something like { visualizationType: 'foo' }
    // doing this is, of course, incredibly stupid
    public isAdvancedGrid?(): boolean {
        return this.visualizationType === 'ADVANCED_GRID';
    }
}
