import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DefaultQueryParamsService, SlowDataApprovalService } from '@ddv/datasets';
import { FeatureFlagService } from '@ddv/entitlements';
import { QueryParamsService } from '@ddv/filters';
import { LayoutHandlerDetail, ManagerService } from '@ddv/layout';
import {
    WIDGET_LIFECYCLE_EVENT,
    WorkspaceSize,
    Widget,
    WidgetRectangle,
    FuzzyDates,
    QueryTypeName,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { WINDOW } from '@hs/ui-core-presentation';
import { Subscription, combineLatest } from 'rxjs';

import { WidgetOnBoardComponent } from '../widget-on-board/widget-on-board.component';
import { WorkspaceSizerService } from './workspace-sizer.service';

const windowResizeTimeoutDuration = 100;

// Dynamically created ONLY by DashboardComponent, so you won't see the selector referenced in source
@Component({
    selector: 'app-workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements AfterContentInit, OnInit, OnDestroy {
    @Input() clientCode: string | undefined;
    @Input() widgets: Widget[] = [];
    @Input() isGlobal = false;
    @Input() isManagingWidget = false;

    protected styleAttributes: Record<string, unknown> = {};
    protected mode = '';
    protected dashboardId = 0;
    protected useNewLegend = false;
    protected dateToCheckSlowness: string | undefined;
    protected showSlowDataApproval = false;
    protected fuzzyDates: FuzzyDates | undefined;

    private widgetInitCount = 0;
    @ViewChildren(WidgetOnBoardComponent) private readonly widgetComponents: QueryList<WidgetOnBoardComponent> | undefined;
    private windowResizeTimeoutId: number | undefined;
    private layoutHandlersSubscription: Subscription | undefined;
    private dashboardAndModeSubscription: Subscription | undefined;
    private newLegendFlagSubscription: Subscription | undefined;
    private dashboardQueryParamsSubscription: Subscription | undefined;
    private fuzzyDatesSubscription: Subscription | undefined;
    private hasReconDataset = false;
    private endDate: string | undefined;

    constructor(
        @Inject(WINDOW) private readonly window: Window,
        private readonly workspaceContainer: ElementRef,
        private readonly manager: ManagerService,
        private readonly currentState: CurrentStateService,
        private readonly cdr: ChangeDetectorRef,
        private readonly featureFlagsService: FeatureFlagService,
        private readonly queryParamsService: QueryParamsService,
        private readonly fuzzyDateService: FuzzyDatesService,
        private readonly sizer: WorkspaceSizerService,
        private readonly slowDataApprovalService: SlowDataApprovalService,
        private readonly defaultQueryParamsService: DefaultQueryParamsService,
    ) {}

    ngOnInit(): void {
        this.layoutHandlersSubscription = this.manager.layoutHandlers
            .subscribe({
                next: (layoutHandlerDetail: LayoutHandlerDetail) => {
                    this.styleAttributes.height = `${layoutHandlerDetail.workspaceSize.height}px`;
                    this.styleAttributes.width = `${layoutHandlerDetail.workspaceSize.width}px`;
                },
            });

        this.dashboardAndModeSubscription = this.currentState.dashboardModeAndId$
            .subscribe({
                next: (dashboardModeAndId) => {
                    this.mode = dashboardModeAndId.mode ?? '';
                    this.dashboardId = dashboardModeAndId.id ?? 0;
                },
            });

        this.window.addEventListener('resize', () => this.resizeWorkspace());

        this.dashboardQueryParamsSubscription = combineLatest([
            this.queryParamsService.dashboardQueryParams,
            this.defaultQueryParamsService.defaultQueryParams$,
        ]).subscribe({
            next: ([dashboardPref, defaults]) => {
                this.endDate = dashboardPref.endDate ?? defaults.endDate;
                this.checkEndDateForSlowDataApproval();
            },
        });

        this.fuzzyDatesSubscription = this.fuzzyDateService.fuzzyDates()
            .subscribe({
                next: (fuzzyDates) => {
                    this.fuzzyDates = fuzzyDates;
                    this.checkEndDateForSlowDataApproval();
                },
            });

        this.setInitialWorkspaceSize();

        this.newLegendFlagSubscription = this.featureFlagsService.isFlagEnabled('ddv-new-legend')
            .subscribe({
                next: (useNewLegend) => {
                    this.useNewLegend = useNewLegend;

                    // this moronicness is because of all dynamic components
                    this.widgetComponents?.forEach((wob) => {
                        wob.useNewLegend = this.useNewLegend;
                    });
                    this.cdr.detectChanges();
                },
            });

        this.checkForReconDataset();
    }

    // This is dodgy and is almost certainly (if its even really necessary) here because of dynamic components
    ngAfterContentInit(): void {
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.layoutHandlersSubscription?.unsubscribe();
        this.dashboardAndModeSubscription?.unsubscribe();
        this.newLegendFlagSubscription?.unsubscribe();
        this.dashboardQueryParamsSubscription?.unsubscribe();
        this.fuzzyDatesSubscription?.unsubscribe();
    }

    // all this does is trick the function below (onWorkspaceStateChange) so that as an upstream component (ViewEdit for example)
    // is removing and re-adding widgets, things that shouldn't happen until all of the widgets are initialized, won't
    prepareToReinitializeWidgets(): void {
        this.widgetInitCount = 0;
        const workspace = this.manager.getWorkspace();
        if (workspace) {
            workspace.isRendered = false;
        }
    }

    // this is only necessary because WorkspaceComponent is created dynamically and therefore does not get change detection
    onWidgetsUpdated(): void {
        this.checkForReconDataset();
    }

    protected onDragOver(widgetDragDetail: { widgetRectangle: WidgetRectangle, widgetId: string }): void {
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        if (layoutHandler?.isCustomDragHandlingSupported) {
            layoutHandler.onDragOver(widgetDragDetail.widgetRectangle, Number(widgetDragDetail.widgetId));
        }
    }

    protected onWorkspaceStateChange(eventName: WIDGET_LIFECYCLE_EVENT): void {
        if (eventName === WIDGET_LIFECYCLE_EVENT.INIT_WIDGET) {
            this.widgetInitCount += 1;
            const workspace = this.manager.getWorkspace();
            const layoutHandler = this.manager.getWorkspaceLayoutHandler();
            if (workspace && layoutHandler && this.widgetInitCount === this.widgets.length) {
                workspace.isRendered = true;
                layoutHandler.configureWorkspaceLayout();
            }
        }
        const workspaceStateChangeHandler = this.manager.getWorkspaceStateChangeHandler();
        if (typeof workspaceStateChangeHandler === 'function') {
            const applicationLayoutState = this.manager.getApplicationLayoutState();
            const isWorkspaceRendered = !!this.manager.getWorkspace()?.isRendered;
            workspaceStateChangeHandler(applicationLayoutState, isWorkspaceRendered, eventName);
        }
    }

    protected unselectAllWidgets(): void {
        if (this.widgetComponents) {
            this.widgetComponents.forEach((widgetComponent) => {
                widgetComponent.unselectWidget();
            });
        }
    }

    protected onSlowDataResponse(): void {
        this.showSlowDataApproval = false;
    }

    private checkForReconDataset(): void {
        this.hasReconDataset = this.widgets.some((w) => {
            return w.extraParameters?.preferences?.datasetDefinition?.queryType?.name === QueryTypeName.RECON;
        });
        this.checkEndDateForSlowDataApproval();
    }

    private resizeWorkspace(): void {
        clearTimeout(this.windowResizeTimeoutId);

        if (!this.manager.getCurrentDashboardId()) {
            return;
        }

        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        this.windowResizeTimeoutId = setTimeout(() => {
            const workspaceSize: WorkspaceSize = this.sizer.getWorkspaceSize(this.workspaceContainer.nativeElement);
            const workspaceResized = layoutHandler?.onWindowResize(workspaceSize, true);
            if (!workspaceResized) {
                this.manager.resizeWorkspace(workspaceSize.width, workspaceSize.height);
            }
        }, windowResizeTimeoutDuration) as unknown as number;

        layoutHandler?.onWindowResize(this.sizer.getWorkspaceSize(this.workspaceContainer.nativeElement), false);
    }

    private setInitialWorkspaceSize(): void {
        const workspaceSize = this.sizer.getWorkspaceSize(this.workspaceContainer.nativeElement);
        this.manager.setInitialWorkspaceSize(workspaceSize.width, workspaceSize.height);
    }

    private checkEndDateForSlowDataApproval(): void {
        if (this.endDate === this.dateToCheckSlowness || !this.fuzzyDates || !this.hasReconDataset) {
            return;
        }

        this.dateToCheckSlowness = this.endDate;
        if (this.shouldRequestSlowDataApproval(this.dateToCheckSlowness)) {
            this.showSlowDataApproval = true;
        }
    }

    private shouldRequestSlowDataApproval(endDate: string | undefined): boolean {
        return this.hasReconDataset &&
            !!this.clientCode &&
            !!endDate &&
            !this.slowDataApprovalService.isApproved(this.clientCode, endDate);
    }
}
