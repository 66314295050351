import { Injectable } from '@angular/core';

@Injectable()
export class PrettyPrintService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    format(str: string, configs: any = {}): string {
        // "type" defines whether to pretty print for JSON, GQL, or none
        switch (configs.type) {
            case 'JSON':
                return this.jsonFormat(str, configs);
            case 'GQL':
                return this.gqlFormat(str, configs);
            default:
                return str;
        }
    }

    gqlFormat(str: string, configs?: { indent?: number }): string {
        // "indent" determines how many spaces each indent level is
        const indent = configs?.indent ?? 4;

        const [_, verb, query] = str.replace(/\s+/g, ' ').match(/^(mutation |query |variables )?(.*?)$/) ?? [];
        let level = 0;
        let inParens = false;

        const formatted = query.split('')
            .reduce((out, char) => {
                if (inParens) {
                    if (char === ')') {
                        inParens = !inParens;
                    }
                    return `${out}${char}`;
                }

                switch (char) {
                    case '(':
                        inParens = !inParens;
                        return `${out}(`;
                    case ' ':
                        return `${out}\n${this.indent(level, indent)}`;
                    case '{':
                        level += 1;
                        return `${out}{`;
                    case '}':
                        level -= 1;
                        return `${out}\n${this.indent(level, indent)}}`;
                    default:
                        return `${out}${char}`;
                }
            }, '');

        return this.gqlCleanup(`${verb}${formatted}`);
    }

    minimalFormatter(str: string): string {
        let level = 0;
        let inQuotes = false;

        return str.split('')
            .reduce((out, char) => {
                let output = out;
                if (inQuotes) {
                    if (char === '"') {
                        inQuotes = !inQuotes;
                    }
                    return `${output}${char}`;
                }

                if (char === '"') {
                    inQuotes = !inQuotes;
                }

                if (/\s/.test(char)) {
                    return output;
                }
                output += char;

                switch (char) {
                    case ':':
                        output += ' ';
                        break;
                    case '{':
                        level += 1;
                        output += level === 1 ? '\n' : '';
                        break;
                    case '[':
                        level += 1;
                        output += level === 1 || level === 2 ? '\n' : '';
                        break;
                    case ']':
                    case '}':
                        level -= 1;
                        output += level === 1 ? '\n\n' : '\n';
                        break;
                    case ',':
                        output += level === 1 ? '\n\n' : level === 2 ? '\n' : ' ';
                }

                return output;
            }, '')
            .replace(/\n+,/g, ',')
            .replace(/,\n"/g, ', "')
            .replace(/\[\s+]/g, '[]')
            .replace(/\{\s+}/g, '{}')
            .replace(/\[\s+"/g, '["')
            .replace(/([\]}])\n([\]}])\n([\]}]),/g, '$1$2\n$3,')
            .replace(/([\]}])\n([\]}],)\n([[{])/g, '$1$2\n$3')
            .replace(/\n*}\s*$/g, '\n}');
    }

    jsonFormat(
        str: string,
        configs?: {
            type?: string;
            indent?: number;
            maxPropsPerLine?: number;
            newline?: boolean;
            arrayPadding?: boolean;
            objectPadding?: boolean;
            minimal?: boolean;
        },
    ): string {
        if (configs?.minimal) {
            return this.minimalFormatter(str);
        }

        // "indent" determines how many spaces each indent level is
        const indent = configs?.indent ?? 4;

        // "newline" determines whether top level properties should be separated by newlines to enhance readability
        const newline = configs?.newline ?? false;

        // "maxPropsPerLine" determines how many properties can fit on a single line. Allowed values are { 0, 1, 2, 3 }
        let maxPropsPerLine = 0;

        if (configs?.maxPropsPerLine && configs?.maxPropsPerLine > 0 && configs.maxPropsPerLine < 4) {
            maxPropsPerLine = configs.maxPropsPerLine;
        }

        let level = 0;
        let inQuotes = false;

        let formatted = str.split('')
            .reduce((out, char) => {
                let output = out;
                if (inQuotes) {
                    if (char === '"') {
                        inQuotes = !inQuotes;
                    }
                    return `${output}${char}`;
                }

                if (/\s/.test(char)) {
                    return output;
                }

                if (char === '"') {
                    inQuotes = !inQuotes;
                }

                switch (char) {
                    case ':':
                        output += ': ';
                        break;
                    case '{':
                    case '[':
                        level += 1;
                        output += `${char}\n${this.indent(level, indent)}`;
                        break;
                    case ']':
                    case '}':
                        level -= 1;
                        output += `\n${this.indent(level, indent)}${char}\n${this.indent(level, indent)}`;
                        break;
                    case ',':
                        output += `${char}\n${this.indent(level, indent)}`;
                        break;
                    default:
                        output += char;
                }

                return output;
            }, '');

        formatted = this.jsonCleanup(formatted);

        if (newline) {
            formatted = this.addNewlines(formatted, indent);
        }

        if (maxPropsPerLine === 1) {
            formatted = this.onePropPerLine(formatted);
        }

        if (maxPropsPerLine === 2) {
            formatted = this.twoPropsPerLine(formatted);
        }

        if (maxPropsPerLine === 3) {
            formatted = this.threePropsPerLine(formatted);
        }

        if (configs && configs.indent === 0) {
            formatted = formatted.replace(/\n +/g, '\n');
        }

        if (configs?.arrayPadding) {
            formatted = formatted.replace(/\[ *([^\n]+?) *]/g, '[ $1 ]');
        } else {
            formatted = formatted.replace(/\[ *([^\n]+?) *]/g, '[$1]');
        }

        if (typeof configs?.objectPadding === 'boolean' && !configs.objectPadding) {
            formatted = formatted.replace(/\{ *([^\n]+?) *}/g, '{$1}');
        }

        return formatted;
    }

    private indent(level: number, indentation: number): string {
        return level === 0 ? '' : `${Array(level * indentation).join(' ')} `;
    }

    private jsonCleanup(str: string): string {
        return str
            // remove spaces from inside square brackets
            .replace(/\[\s+]/g, '[]')
            // remove spaces from inside curly braces
            .replace(/\{\s+}/g, '{}')
            // remove extra blank lines and lines with spaces after closing brackets/braces
            .replace(/([\]}])\n *\n/g, '$1\n')
            // remove newlines and spaces immediately preceding commas
            .replace(/\n\s+,\n/g, ',\n')
            // remove all spaces and newlines from the end of the string
            .replace(/\s*$/g, '');
    }

    private gqlCleanup(str: string): string {
        return str
            // make sure there are no newlines and extra spacing after colons
            .replace(/:\s*/g, ': ')
            // remove newlines and extra spaces after closing parentheses
            .replace(/}\n *\n/g, '}\n')
            // make sure there are no newlines before opening braces
            .replace(/\s*\{/g, ' {')
            // remove excessive newlines and spaces between items
            .replace(/\n\s*\n/g, '\n')
            // remove all spaces and newlines from the end of the string
            .replace(/\s*$/g, '');
    }

    private addNewlines(str: string, indentation: number): string {
        // add the proper indentation when adding a newline
        const regex = new RegExp(`,\\n( {${indentation}}\\S)`, 'g');
        return str.replace(regex, ',\n\n$1');
    }

    private onePropPerLine(str: string): string {
        // takes objects and arrays with 1 element and places them on a single line
        return str.replace(/([[{])\n\s+(.*?)\n\s+([\]}])/g, '$1 $2 $3');
    }

    private twoPropsPerLine(str: string): string {
        // takes objects and arrays with 1 element and places them on a single line
        const updatedString = this.onePropPerLine(str);
        // takes objects and arrays with 2 elements and places them on a single line
        return updatedString.replace(/([[{])\n\s+(.*?,)\n\s+(.*?)\n\s+([\]}])/g, '$1 $2 $3 $4');
    }

    private threePropsPerLine(str: string): string {
        // takes objects and arrays with 1 element and places them on a single line
        let updatedString = this.onePropPerLine(str);
        // takes objects and arrays with 2 elements and places them on a single line
        updatedString = this.twoPropsPerLine(updatedString);
        // takes objects and arrays with 3 elements and places them on a single line
        return updatedString.replace(/([[{])\n\s+(.*?,)\n\s+(.*?,)\n\s+(.*?)\n\s+([\]}])/g, '$1 $2 $3 $4 $5');
    }
}
