<div class="saved-view-selector" *ngIf="masterReportsLoaded">

    <div class="labeled-dropdown">
        <label>Master</label>

        <app-typeahead
            [dataSource]="masters"
            [configuration]="masterSelectorConfig"
            (itemSelected)="onMasterSelected($event)">
        </app-typeahead>
    </div>

    <div class="labeled-dropdown">
        <label>Saved View</label>

        <div *ngIf="masterReportSelected && savedViews">
            <app-typeahead
                [dataSource]="savedViews"
                [configuration]="savedViewSelectorConfig"
                (itemSelected)="selectedSavedView = $event">
            </app-typeahead>
        </div>

        <div *ngIf="!masterReportSelected">
            <h5>Select a master report</h5>
        </div>

        <div *ngIf="masterReportSelected && !savedViews">
            <h5>Loading Saved Views...</h5>
        </div>
    </div>

    <button
            class="fill-btn"
            type="button"
            (click)="onLoadQueryClicked()"
            [disabled]="!selectedSavedView">
        Load
    </button>
</div>

<div *ngIf="!masterReportsLoaded">
    <h4>Loading Master Reports...</h4>
</div>
