@if (filterTitle) {
    <h2 class="title-modal">{{ filterTitle }}</h2>
}

<div class="dashboard-funds">
    <ul class="list-grid">
        @if (isMultiClient && isSingleClientPicker !== undefined) {
            <li
                [@enterLeaveAnimation]
                class="first-grid funds"
                [ngClass]="{'single-select-dropdown': this.isSingleClientPicker || this.showSingleClientPickerOnDetailWidget}"
            >
                @if (!(this.isSingleClientPicker || this.showSingleClientPickerOnDetailWidget)) {
                    <div>
                        <div class="grid-text">{{ selectedClientsCount }} selected</div>

                        <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showClientsDashboardForm = false">
                            <div
                                class="icon-filterarrow-close"
                                [class.icon-filterarrow-open]="showClientsDashboardForm"
                                (click)="toggleClientsDashboardForm()"
                            >
                            </div>

                            @if (showClientsDashboardForm) {
                                <div class="dropdown right-position client-drop-wrap">
                                    <app-clients-form
                                        [selectedClients]="queryParams.clients ?? []"
                                        (clientsApply)="applySelectedClients($event)"
                                    >
                                    </app-clients-form>
                                </div>
                            }
                        </div>
                        <p>Clients</p>
                    </div>
                }

                @if (this.isSingleClientPicker || this.showSingleClientPickerOnDetailWidget) {
                    <cc-single-select-dropdown
                        label="Client Code"
                        class="clients-dropdown"
                        anchorType="filter"
                        type="filter"
                        size="xsmall"
                        [searchable]="true"
                        cssClass="client-list"
                        searchPlaceholderText="Quick search..."
                        [autoScrollSelectedIntoView]="true"
                        [options]="clientOptions"
                        [selected]="selectedClient"
                        (selectedChange)="applySelectedClients($event)"
                    >
                    </cc-single-select-dropdown>
                }
            </li>
        }

        @if (!isMultiClient) {
            <li [@enterLeaveAnimation] class="first-grid funds">
                <div class="grid-text">{{ selectedFundsCount }} selected</div>

                <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showFundsDashboardForm = false">
                    <span
                        class="icon-filterarrow-close"
                        [class.icon-filterarrow-open]="showFundsDashboardForm"
                        (click)="toggleFundsDashboardForm()"
                    >
                    </span>

                    @if (showFundsDashboardForm) {
                        <div class="dropdown right-position fund-drop-wrap">
                            <app-funds-form
                                [selectedFunds]="queryParams.funds ?? []"
                                (fundsApply)="applySelectedFunds($event)"
                            >
                            </app-funds-form>
                        </div>
                    }
                </div>
                <p>Funds</p>
            </li>
        }

        @if (isCalendarVisible) {
            <li
                [@enterLeaveAnimation]
                class="second-mini-grid date-range-picker"
                [ngClass]="{'grey-text': isCompareDateChecked}"
                appMousedownOutside
                (mousedownOutside)="closeRangeDatepicker()"
            >
                @if (isDateRangeSupported) {
                    <div class="grid__item">
                        <span
                            class="grid-text"
                            title="{{validateDate(queryParams.startDate) ? (queryParams.startDate | date: 'MMM dd yyyy') : queryParams.startDate || 'Use Defaults'}}">
                            {{ validateDate(queryParams.startDate) ? (queryParams.startDate | date: 'MMM dd yyyy') : queryParams.startDate ? (getDateFromFuzzy('from', queryParams.startDate) | date: 'MMM dd yyyy') : 'Use Defaults' }}
                        </span>

                        <p>{{ validateDate(queryParams.startDate) ? 'From Date' : 'Dynamic From' }}</p>
                    </div>
                }

                <div class="grid__item">
                    <span
                        class="grid-text"
                        title="{{validateDate(queryParams.endDate) ? (queryParams.endDate |  date: 'MMM dd yyyy') : queryParams.endDate || 'Use Defaults'}}">
                        {{ validateDate(queryParams.endDate) ? (queryParams.endDate | date: 'MMM dd yyyy') : queryParams.endDate ? (getDateFromFuzzy('to', queryParams.endDate) | date: 'MMM dd yyyy') : 'Use Defaults'}}
                    </span>

                    @if (!isDateRangeSupported) {
                        <p>{{ validateDate(queryParams.endDate) ? 'Date' : 'Dynamic Date' }}</p>
                    } @else {
                        <p>{{ validateDate(queryParams.endDate) ? 'To Date' : 'Dynamic To' }}</p>
                    }
                </div>

                <div class="dropdown-wrapper">
                    <span
                        class="icon-filterarrow-close"
                        [ngClass]="{'icon-filterarrow-open':showRangeDatepicker}"
                        (click)="toggleRangeDatePickers()"
                    >
                    </span>
                </div>

                @if (showRangeDatepicker) {
                    <div class="datepickerdialog">
                        @if (!isDateRangeSupported) {
                            <app-single-date-picker-dialog
                                [date]="queryParams.endDate"
                                [fuzzyDates]="fuzzyDates.to"
                                [isHIDataAvailable]="isHIDataAvailable"
                                (dateSelected)="checkPickerSelectedDates($event)"
                            >
                            </app-single-date-picker-dialog>
                        } @else {
                            <app-date-range-picker-dialog
                                [dateRange]="initPickerDates"
                                [fuzzyDates]="fuzzyDates"
                                [isHIDataAvailable]="isHIDataAvailable"
                                (dateSelected)="checkPickerSelectedDates($event)"
                            >
                            </app-date-range-picker-dialog>
                        }
                    </div>
                }
            </li>
        }

        @if (isActiveDateSupported) {
            <li
                [@enterLeaveAnimation]
                class="third-grid active-date"
                [ngClass]="{'grey-text': isCompareDateChecked, 'no-border': !showAdditionalFilters}"
                appMousedownOutside
                (mousedownOutside)="showActiveDatePickerDialog = false"
            >
                <div class="grid-text" [title]="getActiveDateRange()">
                    {{ getActiveDateRange() }}
                </div>

                <div class="dropdown-wrapper">
                    <span
                        class="icon-filterarrow-close"
                        [ngClass]="{'icon-filterarrow-open': showActiveDatePickerDialog}"
                        (click)="toggleActiveDatePickerDialog()"
                    >
                    </span>
                </div>

                <p>{{ getActiveDateRange() === 'RANGE' || validateDate(queryParams.activeDate!) ? 'Active Date': 'Dynamic Active' }}</p>

                @if (showActiveDatePickerDialog) {
                    <div class="datepickerdialog">
                        <app-active-date-picker-dialog
                            [isActiveDate]="isActiveDate"
                            [date]="queryParams.activeDate || ''"
                            [fuzzyDates]="fuzzyDates.from"
                            (dateSelected)="applyActiveDate($event)"
                            (isActiveDateChanged)="updateIsActiveDate($event)"
                        >
                        </app-active-date-picker-dialog>
                    </div>
                }
            </li>
        }

        @if (showAdditionalFilters) {
            <li
                [@enterLeaveAnimation]
                class="third-mini-grid additional-filters"
                [ngClass]="{'no-border': !showHighlight}"
            >
                <div class="grid-text">{{ queryParams.filters?.length }} selected</div>

                <div class="dropdown-wrapper">
                    <span class="icon-filterarrow-close" (click)="openFilterDialog()"></span>
                </div>

                <p>Additional Filters</p>
            </li>
        }

        @if (filterType !== 'coreWidget') {
            <li class="fifth-grid">
                @if (showRestore) {
                    <cc-icon id="refresh-dashboard" [icon]="'refresh'" [size]="'medium'" title="Refresh" (iconClicked)="refreshCurrentDashboard()"></cc-icon>

                    <span class="show-restore-modal-wrapper" appMousedownOutside (mousedownOutside)="showRestoreModal = false">
                        <cc-icon [icon]="'chevron-down2'" [size]="'medium'" (iconClicked)="showRestoreModal = !showRestoreModal"></cc-icon>

                        @if (showRestoreModal) {
                            <div class="restore-wrapper">
                                <span (click)="restoreCurrentDashboard()">Restore Default View</span>
                            </div>
                        }
                    </span>
                }
            </li>
        }
    </ul>
</div>

@if (showSlowDataApproval) {
    <ddv-slow-data-approval
        [clientCode]="clientCode"
        [selectedDate]="dateToCheckSlowness"
        [fuzzyDates]="fuzzyDates"
        (approved)="onSlowDataResponse($event)"
    >
    </ddv-slow-data-approval>
}
