import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CurrentStateService, DirtyFlagService } from '@ddv/behaviors';
import { ModalDialogService, MultiSubscriptionComponent } from '@ddv/common-components';
import { DashboardFiltersService, DdvWidgetService } from '@ddv/dashboards';
import { Datasource, DataWrapper, MetadataService, WidgetDataSourceService } from '@ddv/datasets';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService, SelectedWidgetAction } from '@ddv/layout';
import {
    AdditionalFilter,
    AppWidgetState,
    BoardWidgetDefinitionIds,
    CompareMode,
    DashboardFilter,
    EXCLUDE,
    FILTER_TYPE,
    FilterCriteria,
    FilterPreference,
    getAvailableCriteriaOptions,
    getAvailableOperators,
    MANAGE_WIDGET_ID,
    MetadataLookup,
    WidgetData,
} from '@ddv/models';
import { FuzzyDatesServiceV2 } from '@ddv/reference-data';
import { deepCompare, deepClone, compareSortOptions } from '@ddv/utils';
import { FilterableField, FilterSelection } from '@hs/ui-core-additional-filters';
import { FieldDataType } from '@hs/ui-core-common';
import { FuzzyDate } from '@hs/ui-core-date';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { WidgetFilterComponent } from '../widget-filter/widget-filter.component';

interface Filter {
    name: string;
    value: string | number | boolean | undefined;
    isMasterWidgetFilter: boolean;
    isDetailWidgetFilter?: boolean;
}

@Component({
    selector: 'app-filters-bar',
    templateUrl: './filters-bar.component.html',
    styleUrls: ['./filters-bar.component.scss'],
})
export class FiltersBarComponent extends MultiSubscriptionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isDashboardMode = false;
    @Input() dashboardId: string | undefined;
    @Input() inPresentationMode = false;
    @Input() widgetId: number | undefined;
    @Output() filtersApplied = new EventEmitter();

    subscriptionState: 'All Subscribed' | 'Selected Widget' | undefined;

    filters: Filter[] = [];

    selectedWidgetFilters: FilterPreference | undefined;
    filterAttributeList: FilterableField[] = [];

    protected selectedFilterAttributes: FilterSelection[] | undefined;
    protected noFiltersSelected = 'Add Filter';
    protected fuzzyDates: FuzzyDate[] = [];

    private deltas: AdditionalFilter[] | undefined;
    private paramsSubscription: Subscription | undefined;
    private readonly unsubscribeNotifier$ = new Subject<void>();
    private widgetPrefs: AppWidgetState | undefined;
    private metadataSubscription: Subscription | undefined;
    private readonly appliedUrlQueryParams: FilterPreference = {};
    private isMultiClient = false;
    private currentDatasources: DataWrapper[] = [];
    private readonly availableOperators = getAvailableOperators();
    private readonly availableCriteriaOptions: FilterCriteria[] = getAvailableCriteriaOptions();
    private selectedBubbleFilter: { [key: string]: DashboardFilter | AdditionalFilter } = {};
    private selectedOperators: { [key: string]: FilterCriteria | undefined } = {};
    private currentId: string | undefined;
    private selectedWidgetId: number | undefined;
    private dashboardParamsChangedOnView = false;
    private noFiltersAvailable = true;
    private readonly rankSuffix = '__rank';

    constructor(
        private readonly queryParamsService: QueryParamsService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly dirtyFlagCheck: DirtyFlagService,
        private readonly dashboardFiltersService: DashboardFiltersService,
        private readonly metadataService: MetadataService,
        private readonly manager: ManagerService,
        private readonly modalService: ModalDialogService,
        private readonly cdr: ChangeDetectorRef,
        private readonly activatedRoute: ActivatedRoute,
        private readonly currentStateService: CurrentStateService,
        private readonly ddvWidgetService: DdvWidgetService,
        private readonly fuzzyDatesService: FuzzyDatesServiceV2,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(
            combineLatest([
                this.manager.selectedWidget$,
                this.widgetDataSourceService.dataSource$.pipe(filter((ds) => ds.datasources.length !== 0)),
                this.activatedRoute.queryParams,
                this.ddvWidgetService.currentOpenedDetailWidgetId,
            ]), (values: [SelectedWidgetAction, Datasource, Params, number | undefined]) => {
                const [ws, ds, queryParams, detailWidgetId] = values;

                this.currentId = this.dashboardId;

                if (ws.config) {
                    this.selectedWidgetId = ws.config.id;
                    this.widgetPrefs = ws.config.extraParameters?.preferences;

                    this.selectedWidgetFilters = this.widgetPrefs?.widgetFilters;
                    this.subscriptionState = this.widgetPrefs?.isSubscribedToDashboardFilters ? 'All Subscribed' : 'Selected Widget';
                } else {
                    this.selectedWidgetId = undefined;
                    this.selectedWidgetFilters = undefined;
                    this.subscriptionState = 'All Subscribed';
                }

                if (queryParams && Object.keys(queryParams).length &&
                    (!this.appliedUrlQueryParams || !deepCompare(this.appliedUrlQueryParams.filters, queryParams.filters))
                ) {
                    this.queryParamsService.dispatchUpdatedQueryParams({
                        filters: JSON.parse(decodeURIComponent(queryParams.filters)) || [],
                    });
                    Object.assign(this.appliedUrlQueryParams, queryParams);
                }

                this.mapFilters(ds);

                if (this.isDashboardMode && this.subscriptionState === 'All Subscribed') {
                    this.dashboardFiltersService.filterType = FILTER_TYPE.DASHBOARD;
                }

                const contexts = this.getContexts();
                this.dashboardFiltersService.setSlicerValues();

                this.noFiltersAvailable = contexts.length === 0;

                if (!deepCompare(this.currentDatasources, ds.datasources)) {
                    this.currentDatasources = deepClone(ds.datasources);

                    if (!this.noFiltersAvailable) {
                        this.onFetchMetadata(this.setContexts(contexts, detailWidgetId));
                    }
                }
            });

        this.subscribeTo(this.currentStateService.isMultiClient$, (isMultiClient) => this.isMultiClient = isMultiClient);

        this.subscribeTo(this.fuzzyDatesService.getFuzzyDatesCC(), (fuzzyDates) => this.fuzzyDates = fuzzyDates);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dashboardId) {
            this.onDashboardChanges();
        }

        if (changes.inPresentationMode) {
            this.onModeChanges();
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        this.manager.selectWidget(false, undefined);

        this.unsubscribeNotifier$.next();
        this.unsubscribeNotifier$.complete();

        this.unsubscribeFromMetadata();
    }

    openFilterDialog(): void {
        const widgetFilterModeDialogRef = this.modalService.open(WidgetFilterComponent, { windowClass: 'filter-popup' });
        widgetFilterModeDialogRef.componentInstance.widgetId = this.selectedWidgetId;
        widgetFilterModeDialogRef.componentInstance.isSubscribedToDashboardFilters = false;
    }

    onSelectedChange(selectedFilterAttributes: FilterSelection[]): void {
        this.selectedFilterAttributes = selectedFilterAttributes;
        const filters = this.getSelectedAdditionalFilters(selectedFilterAttributes);
        this.cdr.detectChanges();

        this.queryParamsService.dispatchUpdatedQueryParams({
            filters,
            areFiltersAppliedByMaster: false,
            comparing: this.widgetPrefs?.widgetFilters?.isComparing ? CompareMode.BOTH : undefined,
        });

        this.onEnterDirtyState(filters);
    }

    removeFilter(filterToRemove: Filter): void {
        const filters = this.dashboardFiltersService.dashboardFilters?.filter((df) => df.displayName !== filterToRemove.name);
        if (this.selectedWidgetFilters) {
            this.selectedWidgetFilters.filters = filters;
        }
        if (this.selectedWidgetFilters?.isComparing) {
            this.selectedWidgetFilters.comparing = CompareMode.BOTH;
        }
        this.queryParamsService.addWidgetQueryParam(this.selectedWidgetId ?? 0, this.selectedWidgetFilters);
        this.onEnterDirtyState(undefined);
    }

    private onEnterDirtyState(deltas: AdditionalFilter[] | undefined): void {
        if (!this.inPresentationMode) {
            this.dirtyFlagCheck.enterDirtyState(this.dashboardId ?? '');
        } else {
            this.dashboardParamsChangedOnView = true;
            if (deltas) {
                this.deltas = deltas;
            }
        }
    }

    private onFetchMetadata(contextsByDefinitionId: BoardWidgetDefinitionIds[]): void {
        let fetchedMetadata: MetadataLookup = {};
        let observable: Observable<MetadataLookup | { [widgetId: number]: MetadataLookup }>;
        if (!this.isMultiClient) {
            observable = this.metadataService.fetchMetadataUniqueMetadata(contextsByDefinitionId[0]);
        } else {
            observable = this.metadataService.fetchMetadataAllMetadata(contextsByDefinitionId);
        }

        this.metadataSubscription = observable
            .subscribe((uniqueMetadata: MetadataLookup | { [widgetId: number]: MetadataLookup }) => {
                if (Number(Object.keys(uniqueMetadata)[0])) {
                    const metadataLookup = uniqueMetadata as { [widgetId: number]: MetadataLookup };
                    contextsByDefinitionId.forEach((context) => {
                        const metadata: MetadataLookup = metadataLookup[context.widgetId!];
                        Object.entries(metadata).forEach(([key, value]) => {
                            value.name = key;
                        });
                        this.metadataService.addMetadataToState(context.widgetId!, metadata);
                    });
                    Object.keys(uniqueMetadata).forEach((key) => {
                        fetchedMetadata = { ...fetchedMetadata, ...metadataLookup[Number(key)] };
                    });
                } else {
                    Object.entries(uniqueMetadata).forEach(([key, value]) => {
                        value.name = key;
                    });
                    this.metadataService.addMetadataToState(contextsByDefinitionId[0].widgetId!, uniqueMetadata as MetadataLookup);
                    fetchedMetadata = uniqueMetadata as MetadataLookup;
                }

                this.dashboardFiltersService.updateMetadata(fetchedMetadata);

                if (this.subscriptionState === 'All Subscribed') {
                    this.setFilterAttributeList(fetchedMetadata);
                    this.selectedFilterAttributes = this.getSelectedFilterAttributes();
                    this.setMasterFilterIcon();
                }
            });
    }

    private setFilterAttributeList(metadata: MetadataLookup): void {
        this.filterAttributeList = this.getFilterableAttributeList();
        this.setFilterableAttributeMetadata(metadata);
        this.setFilterableAttributeChoices();
        this.setFilterableAttributeOperators();
        this.filterAttributeList.sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1);
    }

    private getFilterableAttributeList(): FilterableField[] {
        return [
            ...this.dashboardFiltersService.dashboardFilters?.map((dashboardFilter) => ({
                label: dashboardFilter.displayName ?? '',
                fieldId: dashboardFilter.name ?? '',
                choices: dashboardFilter.values?.map((value) => ({ text: value.toString(), value: value.toString() })) ?? [],
            })) ?? [],
            ...this.dashboardFiltersService.metadataList?.map((md) => ({
                label: md.text,
                fieldId: md.value,
            })) ?? [],
        ];
    }

    private setFilterableAttributeMetadata(metadata: MetadataLookup): void {
        this.filterAttributeList.forEach((filterAttribute) => {
            if (filterAttribute.fieldId.endsWith(this.rankSuffix)) {
                filterAttribute.fieldMetadata = {
                    name: filterAttribute.fieldId,
                    displayName: filterAttribute.label,
                    description: '',
                    agBand: null,
                    alignment: 'right',
                    canAggregate: true,
                    canPivotOn: false,
                    datatype: 'decimal',
                    displayType: 'value',
                    editable: false,
                    format: null,
                    hierarchy: [''],
                    isAttribute: false,
                    isDynamic: false,
                    isOrdinal: false,
                    isMultiValued: false,
                    zeroIfNone: true,
                };
            } else {
                filterAttribute.fieldMetadata = {
                    name: metadata[filterAttribute.fieldId].name,
                    displayName: metadata[filterAttribute.fieldId].displayName,
                    description: metadata[filterAttribute.fieldId].description,
                    agBand: metadata[filterAttribute.fieldId].agBand,
                    alignment: metadata[filterAttribute.fieldId].alignment,
                    canAggregate: metadata[filterAttribute.fieldId].canAggregate,
                    canPivotOn: metadata[filterAttribute.fieldId].canPivotOn,
                    datatype: metadata[filterAttribute.fieldId].datatype as FieldDataType,
                    displayType: metadata[filterAttribute.fieldId].displayType,
                    editable: metadata[filterAttribute.fieldId].editable,
                    format: metadata[filterAttribute.fieldId].format,
                    hierarchy: [metadata[filterAttribute.fieldId].hierarchy],
                    isAttribute: metadata[filterAttribute.fieldId].isAttribute,
                    isDynamic: metadata[filterAttribute.fieldId].isDynamic,
                    isOrdinal: metadata[filterAttribute.fieldId].isOrdinal,
                    isMultiValued: false,
                    zeroIfNone: metadata[filterAttribute.fieldId].zeroIfNone,
                };
            }
        });
    }

    private setFilterableAttributeChoices(): void {
        this.filterAttributeList.forEach((attribute) => {
            const choices: Set<string> = new Set();

            this.dashboardFiltersService.getAllValueOptions().forEach((option) => {
                if (option[attribute.fieldId] != null) {
                    choices.add(option[attribute.fieldId].toString());
                }
            });

            if (attribute.choices) {
                attribute.choices.forEach((choice) => {
                    choices.add(choice.value);
                });
            }

            attribute.choices = [...choices]
                .sort((a, b) => compareSortOptions(a, b))
                .map((choice) => ({ text: choice, value: choice }));
        });
    }

    private setFilterableAttributeOperators(): void {
        this.filterAttributeList.forEach((attribute) => {
            if (this.selectedOperators[attribute.fieldId]) {
                attribute.operatorSelected = this.getSelectedOperator(this.selectedOperators[attribute.fieldId]);
            }
        });
    }

    private getSelectedFilterAttributes(): FilterSelection[] {
        return this.dashboardFiltersService.dashboardFilters?.map((dashboardFilter) => {
            const values = dashboardFilter.values?.filter((value) => value !== '') ?? [];
            const areBlanksSelected = dashboardFilter.values?.some((value) => value === '');

            return {
                fieldId: dashboardFilter.name ?? '',
                choices: values.map((value) => ({ text: value.toString(), value: value.toString() })),
                areBlanksSelected,
            };
        }) ?? [];
    }

    private onDashboardChanges(): void {
        this.manager.selectWidget(false, undefined);
        this.dashboardParamsChangedOnView = false;
        this.deltas = undefined;
        this.noFiltersAvailable = true;
        this.unsubscribeFromMetadata();
    }

    private onModeChanges(): void {
        const newDashboardCreated = !(this.currentId === this.dashboardId);
        if (!newDashboardCreated && !this.inPresentationMode && this.dashboardParamsChangedOnView) {
            if (this.deltas) {
                this.queryParamsService.dispatchUpdatedQueryParams({ filters: this.deltas, areFiltersAppliedByMaster: false });
            }
            this.dirtyFlagCheck.enterDirtyState(this.dashboardId ?? '');
            this.dashboardParamsChangedOnView = false;
            this.deltas = undefined;
        } else {
            this.currentId = this.dashboardId;
        }
    }

    private unsubscribeFromMetadata(): void {
        if (this.metadataSubscription) {
            this.metadataSubscription.unsubscribe();
        }
    }

    private getContexts(): BoardWidgetDefinitionIds[] {
        if (this.isDashboardMode && this.subscriptionState === 'All Subscribed') {
            return this.dashboardFiltersService.initFilterDashboard();
        }
        if (this.isDashboardMode && this.subscriptionState === 'Selected Widget') {
            return this.dashboardFiltersService.initFilterWidget(this.selectedWidgetId);
        }
        return this.dashboardFiltersService.initFilterWidget(this.widgetId);
    }

    private setContexts(contexts: BoardWidgetDefinitionIds[], detailWidgetId: number | undefined): BoardWidgetDefinitionIds[] {
        if (detailWidgetId) {
            return contexts.filter((context) => context.widgetId === detailWidgetId);
        }

        const definitionIds = new Set<number | string>();
        return contexts.filter((context) => {
            if (!definitionIds.has(context.definitionId!)) {
                definitionIds.add(context.definitionId!);
                return context;
            }
        });
    }

    private getSelectedAdditionalFilters(selectedFilterAttributes: FilterSelection[]): AdditionalFilter[] {
        return selectedFilterAttributes.map((attribute) => ({
            name: attribute.fieldId,
            displayName: this.filterAttributeList.find((fa) => fa.fieldId === attribute.fieldId)?.label ?? '',
            criteria: this.getCriteria(this.getOperator(attribute)),
            values: getAdditionalFilterValues(attribute),
            filterValuesType: this.getFilterValuesType(attribute.choices.map((choice) => choice.value)),
        }));
    }

    private getCriteria(operator: string | undefined): FilterCriteria {
        return this.availableCriteriaOptions[this.availableOperators.indexOf(operator ?? 'Includes')];
    }

    private getSelectedOperator(criteria: FilterCriteria | undefined): string {
        return this.availableOperators[this.availableCriteriaOptions.indexOf(criteria ?? 'INCLUDE')];
    }

    private getOperator(attribute: FilterSelection): string | undefined {
        return attribute.operator ?? this.filterAttributeList.find((fa) => fa.fieldId === attribute.fieldId)?.operatorSelected;
    }

    private getFilterValuesType(filterValues: (string | number | boolean)[]): string {
        const allValuesTypes = [...new Set(filterValues?.map((value) => value == null ? 'null' : typeof value))];

        if (allValuesTypes.length === 1) {
            return allValuesTypes[0];
        }

        return allValuesTypes.length === 2 ? allValuesTypes.find((valuesType) => valuesType !== 'null') ?? '' : '';
    }

    private mapFilters(data: Datasource): void {
        const params = this.getQueryParams();

        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }

        this.paramsSubscription = params.subscribe((p) => {
            // Remove saved UDF filters until we start fetching the data for UDF from Trebek
            const updatedFilters = p?.filter((f) => !f.name?.startsWith('udf_')) ?? [];
            this.dashboardFiltersService.dashboardFilters = [];
            this.onMapFilters(updatedFilters, data);
        });
    }

    private getQueryParams(): Observable<(DashboardFilter | AdditionalFilter)[] | undefined> {
        if (this.isDashboardMode && this.subscriptionState === 'All Subscribed') {
            return this.queryParamsService.dashboardQueryParams.pipe(map((qp) => qp.filters));
        }

        if (this.isDashboardMode && this.subscriptionState === 'Selected Widget') {
            return this.queryParamsService.widgetQueryParams.pipe(
                filter((qp) => !!qp),
                map((qp) => qp?.widgetFilters.get(this.selectedWidgetId ?? 0)?.filters),
                takeUntil(this.unsubscribeNotifier$),
            );
        }

        return this.queryParamsService.widgetQueryParams.pipe(
            filter((qp) => !!qp),
            map((qp) => qp?.widgetFilters.get(MANAGE_WIDGET_ID)?.filters),
        );
    }

    private onMapFilters(paramFilters: (DashboardFilter | AdditionalFilter)[], datasource: Datasource): void {
        if (this.subscriptionState === 'All Subscribed') {
            paramFilters.forEach((f) => {
                this.dashboardFiltersService.dashboardFilters?.push(f);
                this.selectedOperators[f.name ?? ''] = f.criteria;
            });
            this.setMasterFilterIcon();
            this.selectedFilterAttributes = this.getSelectedFilterAttributes();
        }

        if (this.subscriptionState === 'Selected Widget') {
            this.filters = paramFilters.map((f) => {
                const name = f.displayName ?? '';

                this.selectedBubbleFilter[name] = f;
                this.dashboardFiltersService.dashboardFilters?.push(f);

                const value = this.getFilterValue(f, datasource);

                return { name, value, isMasterWidgetFilter: false };
            });

            this.filters.sort((a, b) => a.name > b.name ? 1 : -1);
        }
    }

    private setMasterFilterIcon(): void {
        this.dashboardFiltersService.dashboardFilters?.forEach((dashboardFilter) => {
            const filterAttribute = this.filterAttributeList.find((f) => f.label === dashboardFilter.displayName);

            if (filterAttribute) {
                filterAttribute.icon = dashboardFilter.isMasterWidgetFilter ? 'notification-m' : undefined;
            }
        });
    }

    private getFilterValue(paramFilter: DashboardFilter | AdditionalFilter, datasource: Datasource): string | number | boolean | undefined {
        const { criteria, values } = paramFilter;
        const displayName = paramFilter.displayName ?? '';
        const filterName = paramFilter.name ?? '';

        if (criteria === EXCLUDE && values?.length) {
            return `EX: ${values.length.toString()} OF ${this.getFilterOptionsCount(datasource, filterName, this.selectedBubbleFilter[displayName].values)}`;
        }
        if (values?.length === 1) {
            return values[0];
        }
        if (Number(values?.length) > 1) {
            return `${values?.length.toString()} OF ${this.getFilterOptionsCount(datasource, filterName, this.selectedBubbleFilter[displayName].values)}`;
        }
        return undefined;
    }

    private getFilterOptionsCount(ds: Datasource, filterName: string, selectedValues: (string | number | boolean)[] | undefined): number {
        const id = this.isDashboardMode ? this.selectedWidgetId : MANAGE_WIDGET_ID;

        const slicerValues = ([] as WidgetData[]).concat(...ds.datasources
            .filter((dw) => dw.uniqueKey.sourceType === 'widget' && dw.uniqueKey.sourceId === id)
            .map((dw) => dw.data));

        const filterOptions = new Set();

        slicerValues.forEach((value) => {
            if (value[filterName] != null) {
                filterOptions.add(value[filterName].toString());
            }
        });

        selectedValues?.forEach((selectedValue) => filterOptions.add(selectedValue));

        return filterOptions.size;
    }
}

function getAdditionalFilterValues(selectedFilterAttribute: FilterSelection): string[] {
    const values = selectedFilterAttribute.choices.map((choice) => choice.value) ?? [];

    if (selectedFilterAttribute.areBlanksSelected) {
        values.push('');
    }

    return values;
}
