<div class="dropdown-funds">
    <app-list-browser
        buttonLabel="Apply"
        placeholder="Quick Search..."
        [headers]="['Fund Name', 'Fund Code', 'Fund Type']"
        [options]="(fundsOptions | async) ?? []"
        [showHeader]="true"
        [showCriteria]="false"
        (addListValues)="onAddingFunds($event)">
    </app-list-browser>
</div>
