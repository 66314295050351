import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams, IRowNode } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { CheckboxValue } from '@ddv/models';

import { CrosstalkService } from '../../../services/crosstalk.service';

@Component({
    selector: 'app-crosstalk-custom-header-checkbox',
    templateUrl: './crosstalk-custom-header-checkbox.component.html',
    styleUrls: ['./crosstalk-custom-header-checkbox.component.scss'],
})
export class CrosstalkCustomHeaderCheckboxComponent extends MultiSubscriptionComponent implements IHeaderAngularComp {
    value: CheckboxValue | undefined;
    private params: IHeaderParams | undefined;

    constructor(private readonly crosstalkService: CrosstalkService) {
        super();
    }

    agInit(params: IHeaderParams): void {
        this.params = params;

        this.toggleHeaderCheckbox();

        this.subscribeTo(this.crosstalkService.gridFilterChanged, (gridFilterChanged) => {
            if (gridFilterChanged) {
                this.toggleHeaderCheckbox();
            }
        });
    }

    refresh(): boolean {
        this.toggleHeaderCheckbox();
        return true;
    }

    onClick(value: boolean): void {
        this.value = value;
        if (this.value) {
            this.params?.api.selectAllFiltered();
        } else {
            this.params?.api.deselectAllFiltered();
        }
        this.params?.api.refreshCells({ force: true });
    }

    private toggleHeaderCheckbox(): void {
        const rows = { selected: 0, unselected: 0 };
        this.params?.api.forEachNodeAfterFilter((node: IRowNode) => {
            if (node.isSelected()) {
                rows.selected += 1;
            } else {
                rows.unselected += 1;
            }
        });

        this.value = rows.selected ? (rows.unselected ? undefined : true) : false;
        this.params?.api.refreshCells({ force: true });
    }
}
