import { NgModule } from '@angular/core';
import { provideRouter, RouterModule, Routes, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { CanActivateDSDGuard } from '@ddv/dataset-definitions';
import { ManageDatasetDefinitionsComponent } from '@ddv/manage-dataset-definitions';
import { CanActivateManageWidgetsGuardService, ManageWidgetsComponent } from '@ddv/manage-widgets';
import { ViewEditDashboardComponent, CanDeactivateViewEditGuard } from '@ddv/views';

import { MainComponent } from './main/main.component';

// ParsedRouteService makes assumptions about the route configuration here
// if you add/modify these routes, we check the comments in ParsedRouteService
const appRoutes: Routes = [
    {
        path: ':clientCode',
        component: MainComponent,
        children: [
            { path: '', redirectTo: 'views/view/none', pathMatch: 'full' },
            { path: 'home', redirectTo: 'views/view/none', pathMatch: 'full' },
            {
                path: 'dataset-definitions/edit/:datasetDefinitionId',
                component: ManageDatasetDefinitionsComponent,
                canActivate: [CanActivateDSDGuard],
            },
            {
                path: 'widgets/edit/:coreWidgetId',
                component: ManageWidgetsComponent,
                canActivate: [CanActivateManageWidgetsGuardService],
            },
            {
                path: 'views/:mode/:dashboardId',
                component: ViewEditDashboardComponent,
                canDeactivate: [CanDeactivateViewEditGuard],
            },
            {
                path: 'boards',
                loadChildren: async () => import('@ddv/feature-dashboards').then((m) => m.DashboardsModule),
            },
        ],
    },
];

@NgModule({
    providers: [
        provideRouter(appRoutes, withComponentInputBinding(), withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule { }
