import { Injectable } from '@angular/core';
import { RowGroupOpenedEvent, GridEventType } from '@ddv/models';
import { Observable, Subject } from 'rxjs';

export interface PostFilterSortStatusEvent {
    hasGroupsAfterFilterOrSort: boolean;
    appliedEvent: GridEventType;
}

@Injectable()
export class RowGroupActionService {
    public readonly rowGroupOpened: Observable<RowGroupOpenedEvent>;
    public readonly hasGroupsAfterFilterOrSort: Observable<PostFilterSortStatusEvent>;

    private readonly rowGroupOpenedSubject: Subject<RowGroupOpenedEvent> = new Subject();
    private readonly hasGroupsAfterFilterOrSortSubject: Subject<PostFilterSortStatusEvent> = new Subject();

    constructor() {
        this.rowGroupOpened = this.rowGroupOpenedSubject.asObservable();
        this.hasGroupsAfterFilterOrSort = this.hasGroupsAfterFilterOrSortSubject.asObservable();
    }

    emitWhenRowGroupIsOpenedOrClosed(rowGroupOpened: RowGroupOpenedEvent): void {
        this.rowGroupOpenedSubject.next(rowGroupOpened);
    }

    emitWhenFilterOrSortIsApplied(hasGroupsAfterFilterOrSort: boolean, appliedEvent: GridEventType): void {
        this.hasGroupsAfterFilterOrSortSubject.next({ hasGroupsAfterFilterOrSort, appliedEvent });
    }
}
