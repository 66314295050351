import {
    AGFilter,
    AgGridDateFilterState,
    AgGridDateMultiConditionFilterState,
    AgGridFilterState,
    AgGridNumberFilterState,
    AgGridNumberMultiConditionFilterState,
    AGGridSetFilterState,
    GridDateFilter,
    GridDateMultiConditionFilter,
    GridNumberFilter,
    GridNumberMultiConditionFilter,
    GridSetFilter,
} from '@ddv/models';

// type guards
export function isAgGridSetFilterState(filter?: AgGridFilterState): filter is AGGridSetFilterState {
    return filter?.filterType === 'set';
}

export function isAgGridDateMultiConditionFilterState(filter?: AgGridFilterState): filter is AgGridDateMultiConditionFilterState {
    const filterState = filter as AgGridDateMultiConditionFilterState;
    return !!(filterState?.filterType === 'date' && filterState.operator && !!filterState.conditions[0] && !!filterState.conditions[1]);
}

export function isAgGridDateFilterState(filter?: AgGridFilterState): filter is AgGridDateFilterState {
    const filterState = filter as AgGridDateFilterState;
    return filterState?.filterType === 'date' && filterState.type && !!filterState.dateFrom;
}

export function isAgGridNumberMultiConditionFilterState(filter?: AgGridFilterState): filter is AgGridNumberMultiConditionFilterState {
    const filterState = filter as AgGridNumberMultiConditionFilterState;
    return !!(filterState?.filterType === 'number' && filterState.operator && !!filterState.conditions[0] && !!filterState.conditions[1]);
}

export function isAgGridNumberFilterState(filter?: AgGridFilterState): filter is AgGridNumberFilterState {
    const filterState = filter as AgGridNumberFilterState;
    return filterState?.filterType === 'number' && filterState.type && filterState.filter != null;
}

export function isGridSetFilter(filterDef?: AGFilter | null): filterDef is GridSetFilter {
    return (filterDef as GridSetFilter)?.includedStrings != null;
}

export function isGridDateMultiConditionFilter(filterDef?: AGFilter | null): filterDef is GridDateMultiConditionFilter {
    const filter = filterDef as GridDateMultiConditionFilter;
    return !!(filter?.filterType === 'date' && filter.operator && !!filter.condition1 && !!filter.condition2);
}

export function isGridDateFilter(filterDef?: AGFilter | null): filterDef is GridDateFilter {
    const filter = filterDef as GridDateFilter;
    return filter?.filterType === 'date' && filter.type && !!filter.filterFrom;
}

export function isGridNumberMultiConditionFilter(filterDef?: AGFilter | null): filterDef is GridNumberMultiConditionFilter {
    const filter = filterDef as GridNumberMultiConditionFilter;
    return !!(filter?.filterType === 'number' && filter.operator && !!filter.condition1 && !!filter.condition2);
}

export function isGridNumberFilter(filterDef?: AGFilter | null): filterDef is GridNumberFilter {
    const filter = filterDef as GridNumberFilter;
    return filter?.filterType === 'number' && filter.type && filter.filterFrom != null;
}
