import { NgModule } from '@angular/core';

import { LegendComponent } from './legend/legend.component';

@NgModule({
    declarations: [
        LegendComponent,
    ],
    exports: [
        LegendComponent,
    ],
})
export class LegendsModule {}
