import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BehaviorsModule } from '@ddv/behaviors';
import { CommonComponentsModule } from '@ddv/common-components';
import { DatasetDefinitionsModule } from '@ddv/dataset-definitions';
import { SocketModule } from '@ddv/socket';
import { LibSvgIconsModule } from '@ddv/svg-icons';
import { CheckboxComponent, OptionToggleComponent } from '@hs/ui-core-inputs';

import {
    ManageDatasetDefinitionsComponent,
} from './components/manage-dataset-definitions/manage-dataset-definitions.component';
import { SavedViewSelectorComponent } from './components/saved-view-selector/saved-view-selector.component';
import { SelectorComponent } from './components/selector/selector.component';
import { PrettyPrintService } from './services/pretty-print.service';
import { PublicApiService } from './services/public-api.service';

@NgModule({
    imports: [
        BehaviorsModule,
        CommonComponentsModule,
        CommonModule,
        DatasetDefinitionsModule,
        FormsModule,
        SocketModule,
        LibSvgIconsModule,
        CheckboxComponent,
        OptionToggleComponent,
    ],
    providers: [
        PrettyPrintService,
        PublicApiService,
    ],
    declarations: [
        ManageDatasetDefinitionsComponent,
        SavedViewSelectorComponent,
        SelectorComponent,
    ],
    exports: [
        ManageDatasetDefinitionsComponent,
    ],
})
export class ManageDatasetDefinitionsModule {}
