import { TMode } from '../application-mode';
import { BoardWidgetDefinitionIds } from '../board-widget-definition-ids';
import { Client } from '../client';
import { TPermissionType } from '../dashboards/permission-type';
import { UserGridColumnOverridesMap } from '../data-grid/user-grid-column-overrides';
import { DatasetDefinition } from '../dataset-definitions/dataset-definition';
import { DatasetDefinitionDetails } from '../dataset-definitions/dataset-definition-details';
import { UserPermission } from '../entitlements/user-permission';
import { FilterPreference } from '../filters/filter-preference';
import { Fund } from '../fund';
import { SummaryConfig } from '../summary/summary-config';
import { Tag } from '../tag';
import { ConfigItem } from '../visualizations/config-item';
import { TableSortType } from '../visualizations/table-sort-type';
import { VisualizationConfigs } from '../visualizations/visualization-configs';
import { VisualizationType } from '../visualizations/visualization-type';
import { DisplayNameType } from './display-name-type';
import { WidgetPosition } from './widget-position';
import { IWidgetSettings } from './widget-settings';
import { WidgetState } from './widget-state';
import { TWidgetTypes } from './widget-types';

export interface NameValueChoice {
    label: string;
    value: string;
}

export class AppWidgetState extends WidgetState implements IWidgetSettings {
    name?: string;
    description?: string;
    coreWidgetId?: number;
    datasetDefinition?: DatasetDefinition;
    defaultViz?: string;
    enableWidgetHeader?: boolean;
    visualizationConfigs: VisualizationConfigs[] = [];
    currentVisualization?: VisualizationType;
    summaryConfig?: SummaryConfig;
    widgetPermissions?: UserPermission[] = [];
    widgetPosition?: WidgetPosition;
    privilege?: TPermissionType;
    isMaster?: boolean;
    isShared?: boolean;
    defaultWidth?: number;
    defaultHeight?: number;
    coreWidgetType?: TWidgetTypes;
    customDisplayName?: string;
    displayNameType?: DisplayNameType;
    widgetSettings?: IWidgetSettings[] = [];
    widgetTags?: Tag[] = [];
    version?: number;
    clientCode?: string;
    // IWidgetSettings
    isSubscribedToDashboardFilters?: boolean;
    isActiveDate?: boolean;
    tableSortDirection?: string;
    isLinkedToMaster?: boolean;
    sortTableBy?: TableSortType;
    enableAutoRefresh?: boolean;
    enableCompareMode?: boolean;
    mode?: TMode;
    widgetFilters?: FilterPreference;
    isGlobal?: boolean;
    isSelectable?: boolean;
    realtimeUpdates?: boolean;
    hideLoaderAfterFirstDataLoad?: boolean;
    isDetailWidget?: boolean;
    userGridColumnOverrides?: UserGridColumnOverridesMap;
    namedQueryId: string | undefined;

    public constructor(init?: Partial<AppWidgetState>, funds?: Fund[], clients?: Client[]) {
        super();
        Object.assign(this, init);

        this.coreWidgetId = this.coreWidgetId ?? this.id;
        this.visualizationConfigs = this.visualizationConfigs?.map((vizConfig: VisualizationConfigs) => {
            return new VisualizationConfigs(vizConfig);
        });

        if (funds && this.widgetFilters?.funds) {
            const wfFunds = this.widgetFilters.funds;
            this.widgetFilters.funds = funds.filter((f) => wfFunds.findIndex((fund) => fund.fundId === f.fundId) !== -1);
        }

        if (clients && this.widgetFilters) {
            this.widgetFilters.clients = clients;
        }
        if (typeof this.datasetDefinition?.id === 'number') {
            this.datasetDefinition = new DatasetDefinitionDetails(this.datasetDefinition as DatasetDefinitionDetails);
        }
    }

    private get defaultVisualizationConfig(): VisualizationConfigs | null {
        if (!this.visualizationConfigs) {
            return null;
        }

        return this.visualizationConfigs.find((vc) => vc.isDefault) ?? this.visualizationConfigs[0];
    }

    public getDefaultVisualizationType(): VisualizationType | null {
        const defaultVisualizationConfig = this.defaultVisualizationConfig;
        return defaultVisualizationConfig ? defaultVisualizationConfig.visualizationType : null;
    }

    public setDefaultVisualizationType(type: VisualizationType | undefined | null): void {
        if (!this.visualizationConfigs) {
            return;
        }

        this.visualizationConfigs.forEach((vc) => vc.isDefault = vc.visualizationType === type);
    }

    public get configuredVisualizationTypes(): VisualizationType[] {
        return this.visualizationConfigs?.map((vc) => vc.visualizationType) ?? [];
    }

    public getDefaultSlicerForVisualizationType(type: VisualizationType | undefined | null): string | undefined {
        const vc = this.getVisualizationConfigForType(type);
        if (!vc?.configs?.slicers) {
            return;
        }

        return vc.configs.slicers.find((slicer) => slicer.isDefault)?.value;
    }

    public getDefaultSlicerObjectForVisualizationType(type: VisualizationType): Partial<ConfigItem> | null {
        const vc = this.getVisualizationConfigForType(type);
        if (!vc?.configs?.slicers) {
            return null;
        }

        const defaultSlicer = vc.configs.slicers.find((slicer) => slicer.isDefault) ?? vc.configs.slicers[0];
        if (defaultSlicer) {
            return ({ label: defaultSlicer.label, value: defaultSlicer.value });
        }

        return null;
    }

    private getVisualizationConfigForType(type: VisualizationType | undefined | null): VisualizationConfigs | undefined {
        return this.visualizationConfigs?.find((vc) => vc.visualizationType === type);
    }

    public getSlicersForVisualizationType(type: VisualizationType): (string | undefined)[] {
        const vc = this.getVisualizationConfigForType(type);
        if (!vc?.configs?.slicers) {
            return [];
        }

        return vc.configs.slicers.map((slicer) => slicer.value);
    }

    public getSortOnChoicesForVisualizationType(type: VisualizationType): NameValueChoice[] {
        const vc = this.getVisualizationConfigForType(type);
        if (!vc) {
            return [];
        }

        const sortOnChoices: NameValueChoice[] = [];
        if (['PIE_CHART',
            'DONUT_CHART',
            'HORIZONTAL_BAR_CHART',
            'VERTICAL_BAR_CHART',
            'HORIZONTAL_STACKED_BAR_CHART',
            'VERTICAL_STACKED_BAR_CHART',
            'VERTICAL_MIRRORED_BAR_CHART'].includes(type)) {
            sortOnChoices.push({ label: 'Slicer', value: 'SLICER' });

            if (type !== 'VERTICAL_MIRRORED_BAR_CHART') {
                sortOnChoices.push({ label: 'Value', value: 'VALUE' });
            }
        }

        return sortOnChoices;
    }

    public getDefaultSortDirectionOptionsForDefaultVisualization(sortOn: string): NameValueChoice[] {
        const sortOptions = [
            { label: 'Asc', value: 'ASC' },
            { label: 'Desc', value: 'DESC' },
        ];
        if (sortOn !== 'VALUE') {
            const defaultSlicer = this.defaultVisualizationConfig?.configs?.slicers
                .find((slicer) => slicer.isDefault) ?? this.defaultVisualizationConfig?.configs?.slicers[0];
            if (defaultSlicer?.configCustomSortOrder?.length) {
                const customSortOption = { label: 'Custom', value: 'CUSTOM' };
                sortOptions.push(customSortOption);
            }
        }
        return sortOptions;
    }

    public permissionForUser(username: string): string | undefined {
        const widgetEditRights = this.widgetPermissions?.some((widgetPermission: UserPermission) => {
            return widgetPermission.privilege === 'EDIT' && widgetPermission.name.toLocaleLowerCase() === username.toLowerCase();
        });

        return widgetEditRights ? 'EDIT' : this.privilege;
    }

    public toBWD(): BoardWidgetDefinitionIds {
        return new BoardWidgetDefinitionIds(undefined, this.id, this.datasetDefinition?.id);
    }
}

export function anyWidgetIsRealtime(widgetStates: AppWidgetState[]): boolean {
    return widgetStates.some((w) => w.realtimeUpdates);
}

export function getCurrentVisualizationId(widgetPrefs: AppWidgetState | undefined | null): number | undefined {
    const currentVisualization = widgetPrefs?.currentVisualization;
    return widgetPrefs?.visualizationConfigs?.find((vc) => vc.visualizationType === currentVisualization)?.id;
}
