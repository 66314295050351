import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegex } from '@ddv/utils';

@Pipe({ name: 'highlight' })
export class Highlight implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    transform(value: any, args?: string): string {
        if (args) {
            const re = new RegExp(escapeRegex(args), 'gi');
            return `${value}`.replace(re, (match) => `<mark>${match}</mark>`);
        }
        return value;
    }
}
