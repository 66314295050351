<div class="color-config-template">
    <div class="slicer-wrapper" *ngIf="!!configColorSlicers?.length">
        <label>Slicer</label>

        <div class="custom-parent">
            <app-dropdown
                [options]="colorSlicerOptions"
                [selected]="selectedSlicerOption"
                [readonly]="isReadOnly"
                (selectedChange)="setSelectedSlicerValue($event.value); onSlicerSelect()">
            </app-dropdown>
        </div>
    </div>

    <div class="color-selection-wrapper">
        <ng-container *ngFor="let colorConfig of isLineValue2 ? dynamicColorConfigList : colorConfigList">
            <ng-container *ngIf="isMirrored || isLineValue2; else normalColorBlock">
                <div class="color-block line-chart">
                    <label>{{colorConfig.type}}</label>

                    <ng-container *ngTemplateOutlet="multiColorPicker;context: {ctx: colorConfig,isChild: false}"></ng-container>
                </div>
            </ng-container>

            <ng-template #normalColorBlock>
                <ng-container *ngIf="!isLineChart; else lineChartColorBlock">
                    <div class="color-block">
                        <span class="radio-btn" [ngClass]="{'readonly': isReadOnly}">
                            <input
                                name="colorConfigType"
                                type="radio"
                                id="{{colorConfig.type}}"
                                [value]="colorConfig.configType"
                                [(ngModel)]="selectedColorType"
                                (change)="onColorTypeSelect()" />

                            <label for="{{colorConfig.type}}">{{colorConfig.type}}</label>
                        </span>

                        <ng-container *ngTemplateOutlet="colorTemplate;context: {ctx: colorConfig}"></ng-container>

                        <div *ngIf="colorConfig.typeDetail" class="description">{{colorConfig.typeDetail}}</div>

                        <span *ngIf="isStackedAreaChart" class="toggle-heading">Transparency</span>

                        <div *ngIf="isStackedAreaChart" class="toggle-container">
                            <ng-container *ngTemplateOutlet="transparencyToggleTemplate;context: {ctx: colorConfig}"></ng-container>
                        </div>

                        <div class="color-sorting"
                             *ngIf="shouldShowColorSorting(colorConfig)"
                             [ngClass]="{'disabled': selectedColorType !== colorConfig.configType}">

                            <label>
                                <span
                                    class="icon"
                                    [ngClass]="isClosed ? 'icon-arrow-right' : 'icon-expand'"
                                    title="{{ isClosed ? 'Expand' : 'Collapse' }}"
                                    (click)="isClosed = !isClosed">
                                </span>

                                Color Sorting
                            </label>

                            <p *ngIf="!isClosed" class="subtitle">Sort By</p>

                            <app-dropdown
                                *ngIf="!isClosed"
                                [options]="colorSortDirectionOptions"
                                [selected]="selectedColorSortDirection"
                                [readonly]="isReadOnly"
                                (selectedChange)="onColorSortDirectionSelect($event)">
                            </app-dropdown>
                        </div>

                        <div class="custom-style">
                            <ng-template [ngIf]="colorConfig.customStyleConfig!.customColorConfigList.length > 0">
                                <div
                                    class="custom-style-child"
                                    *ngFor="let customColor of colorConfig.customStyleConfig?.customColorConfigList; let i = index;">

                                    <ng-container *ngTemplateOutlet="downArrowTemplate;context:{ctx: customColor,i: i}"></ng-container>

                                    <ng-container *ngTemplateOutlet="multiColorPicker;context: {ctx: colorConfig,isCustomColor: true,index: i,customColor:customColor}"></ng-container>

                                    <span class="icon-close1" (click)="removeConfigAtIndex(i)"></span>
                                </div>
                            </ng-template>

                            <ng-container *ngTemplateOutlet="addCustomStyle;context:{colorConfig: colorConfig, callback: addMoreColor}"></ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-template>

            <ng-template #lineChartColorBlock>
                <ng-container *ngFor="let customColor of colorConfig.customStyleConfig?.customColorConfigList; let i = index">
                    <div class="color-block line-chart">
                        <label *ngIf="!customColor.lineChartCustomConfig">{{customColor.attributeValue}}</label>

                        <div class="add-custom-row" *ngIf="customColor.lineChartCustomConfig">
                            <input
                                class="formControl"
                                type="text"
                                autocomplete="off"
                                maxlength="50"
                                name="customAttribute"
                                [(ngModel)]="customColor.attributeValue"/>

                            <button class="empty-btn" type="button" (click)="addLineChartCustomAttributeValue(i)">Add</button>
                        </div>

                        <ng-container *ngTemplateOutlet="multiColorPicker;context: {ctx: colorConfig,isCustomColor: true,index: i,customColor:customColor}"></ng-container>
                    </div>
                </ng-container>

                <div class="color-block custom-style">
                    <ng-container *ngTemplateOutlet="addCustomStyle;context:{colorConfig: colorConfig, callback: addAdditionalLineChartColor}"></ng-container>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>

<ng-template #addCustomStyle let-colorConfig="colorConfig" let-callback="callback">
    <div
        class="add-custom-style"
        *ngIf="colorConfig.configType === selectedColorType && !!slicerValue">

        <cc-icon
            class="add-visualization-icon"
            [icon]="'plus-circle'"
            [size]="'xsmall'"
            [disabled]="isReadOnly"
            (iconClicked)="callback.call(this)">
        </cc-icon>
        <span [ngClass]="{'readonly': isReadOnly}">Add</span>
    </div>
</ng-template>

<ng-template #colorTemplate let-ctx="ctx">
    <div class="custom-select-wrap" [ngClass]="{'monochromatic-color-dropdown': ctx.type === 'Monochromatic', 'readonly': isReadOnly}">
        <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="el.hidden = true">
            <div class="select-component" (click)="el.hidden = !el.hidden">
                <div class="selected-list">
                    <div
                        [ngClass]="ctx.colorPaletteList.length === 1 ? 'square' : 'not-square'"
                        [style.background]="color"
                        *ngFor="let color of ctx.colorPaletteList">
                    </div>
                </div>

                <span class="icon-arrow-down"></span>
            </div>

            <div class="dropdown" #el [hidden]=true>
                <div class="dropdown-search-list">
                    <ul class="search-list" [ngClass]="{'multi-colored': ctx.type === 'Multi-Colored'}">
                        <li *ngFor="let item of ctx.colorValues" (click)="onColorSelected(ctx, item, el)">
                            <span
                                [ngClass]="item.displayValues.length === 1 ? 'square' : 'not-square'"
                                [style.background]="color"
                                *ngFor="let color of item.displayValues">
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #transparencyToggleTemplate let-ctx="ctx">
    <cc-option-toggle
        class="toggle-checkbox"
        id="{{ctx.type}}-toggle"
        [disabled]="selectedColorType !== ctx.configType"
        [(value)]="ctx.isTransparencyOn"
        [labels]="['On', 'Off']"
        (valueChange)="onColorTransparencyChanged(ctx)">
    </cc-option-toggle>
</ng-template>

<ng-template #multiColorPicker let-ctx="ctx" let-isCustomColor="isCustomColor" let-index="index" let-customColor="customColor">
    <div class="custom-select-wrap" [ngClass]="{'readonly': isReadOnly}">
        <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="el.hidden = true">
            <div class="select-component" (click)="el.hidden = !el.hidden">
                <div class="selected-list">
                    <span class="square" [style.background]="isCustomColor ? customColor.attributeColorName : ctx.selectedValue"></span>
                </div>

                <span class="icon-arrow-down"></span>
            </div>

            <div class="dropdown" #el [ngClass]="getDropdownClass(ctx.type)" [hidden]=true>
                <div class="color-search-list">
                    <ul class="search-list">
                        <li *ngFor="let item of isCustomColor ? ctx.customColorPalette : ctx.colorValues">
                            <span
                                [style.background]="color"
                                *ngFor="let color of item.displayValues"
                                (click)="onColorSelected(ctx, color, el, isCustomColor, index)">
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #downArrowTemplate let-ctx="ctx" let-i="i">
    <div class="dropdown-wrapper custom-style-wrapper" appMousedownOutside (mousedownOutside)="hideFiltersValues(filterValueList)">
        <div class="input-box" [ngClass]="{'readonly': isReadOnly}">
            <input class="formControl" type="text" autocomplete="off" placeholder="Select Value" [(ngModel)]="ctx.attributeValue">

            <span class="icon-arrow-down" (click)="toggleFilterValues(filterValueList,ctx)"></span>
        </div>

        <div #filterValueList class="dropdown custom-style-wrapper-list" [hidden]=true>
            <div class="search-box">
                <input
                    class="formControl"
                    type="search"
                    autocomplete="off"
                    maxlength="50"
                    name="filterSearchValue"
                    [(ngModel)]="filterSearchValue"/>

                <span class="icon-search1"></span>

                <button class="empty-btn" type="button" (click)="addCustomAttribute(i)">Add</button>
            </div>

            <div class="custom-style-wrapper-list-values">
                <ul>
                    <li
                        *ngFor="let item of filterAttributeList | textValueFilter : filterSearchValue"
                        (click)="onFilterValueSelect(item,i)">

                        <span innerHTML="{{ item.text | highlight : filterSearchValue }}"></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>
