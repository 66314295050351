<div class="select-wrap">
    <h4 class="form-label">{{ title }}</h4>

    <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showUserList = false; searchParam = '';">
        <ul class="select-component disp-flex" (click)="toggleUserList()">
            <li class="selected-list1" *ngFor="let selectedUser of selectedUsers; let i=index">
                <span *ngIf="selectedUser.privilege === permission">{{ selectedUser.name }}
                    <label
                        class="icon-close1"
                        [hidden]="selectedUser.cannotBeRemoved"
                        (click)="removeUserPermission($event, i)">
                    </label>
                </span>
            </li>

            <li class="dropdown-arrow">
                <span class="icon-arrow-down"></span>
            </li>
        </ul>

        <div class="dropdown" *ngIf="showUserList">
            <div class="dropdown-user-select">
                <ul class="top-menu clearfix">
                    <li class="tab-list">
                        <span class="selected" [ngClass]="{'selected':(selectedTab === 'ALL')}" (click)="selectedTab = 'ALL'">All</span>
                    </li>

                    <li class="tab-list">
                        <span [ngClass]="{'selected':(selectedTab === 'USER')}" (click)="selectedTab = 'USER'">Individual</span>
                    </li>

                    <li class="tab-list">
                        <span [ngClass]="{'selected':(selectedTab === 'TEAM')}" (click)="selectedTab = 'TEAM'">Teams</span>
                    </li>

                    <li class="tab-list" *ngIf="false">
                        <span [ngClass]="{'selected':(selectedTab === 'GROUP')}" (click)="selectedTab = 'GROUP'">Groups</span>
                    </li>
                </ul>

                <div class="search-box">
                    <span class="icon-search1"></span>

                    <input
                        appAutoFocus
                        type="search"
                        name="searchParam"
                        placeholder="Quick Search..."
                        autocomplete="off"
                        [(ngModel)]="searchParam"
                        [focus]="true"/>

                    <button class="fill-btn" type="button" (click)="applySelectedUsers($event)">Apply</button>
                </div>

                <ul class="search-list">
                    <li
                        class="checkbox"
                        *ngFor="let user of userList | userListFilter: selectedTab : searchParam; index as index;"
                        [ngClass]="{'selected': user.selected}"
                    >
                        <cc-checkbox
                            class="checkbox-custom-label clearfix"
                            [label]="!searchParam ? user.name : ''"
                            [(value)]="user.selected"
                            (valueChange)="onUserSelect(user.name)"
                        >
                            <label
                                class="highlight"
                                [innerHTML]="searchParam && user.name | highlight: searchParam">
                            </label>
                        </cc-checkbox>

                        <span class="user-text" *ngIf="user.privilege">{{ user.displayPrivilege }}</span>

                        <span class="user-text noAccess" *ngIf="!user.privilege">No Access</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
