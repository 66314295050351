import { Injectable } from '@angular/core';
import { DropdownOption } from '@ddv/common-components';
import { Datasource, WidgetDataSourceService } from '@ddv/datasets';
import { ManagerService } from '@ddv/layout';
import {
    BoardWidgetDefinitionIds,
    EXCLUDE,
    FILTER_TYPE,
    INCLUDE,
    TextValuePair,
    WidgetData,
    FieldMetadata,
    DashboardFilter,
    ValueFilterOption,
    AppWidgetState,
} from '@ddv/models';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { distinct } from 'rxjs/operators';

@Injectable()
export class DashboardFiltersService {
    // TODO: public state
    public metadataList: TextValuePair[] | undefined;
    public valueFilterOptionsObserver: Observable<ValueFilterOption[]>;
    public dashboardFilters: DashboardFilter[] | undefined;
    public completeMetadata: { [fieldName: string]: FieldMetadata } | undefined;
    public totalValueCount: { [key: string]: number } = {};
    public filterType = FILTER_TYPE.DASHBOARD;
    public widgetId = 0;

    private readonly valueFilterOptionsSubject: BehaviorSubject<ValueFilterOption[]>;
    private widgetDataSourceObserver: Subscription | undefined;
    private slicerValues: WidgetData[] = [];

    constructor(
        private readonly manager: ManagerService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
    ) {
        this.valueFilterOptionsSubject = new BehaviorSubject<ValueFilterOption[]>([]);
        this.valueFilterOptionsObserver = this.valueFilterOptionsSubject.asObservable();
    }

    initFilterDashboard(): BoardWidgetDefinitionIds[] {
        const widgetIds = this.manager.getWidgetIdsForWorkspace();
        return widgetIds
            .map((widgetId) => this.manager.getWidgetPreferences(widgetId))
            .filter((widget: AppWidgetState | undefined | null) => widget?.isSubscribedToDashboardFilters)
            .map((widget: AppWidgetState | undefined | null) => {
                return new BoardWidgetDefinitionIds(undefined, widget?.id, widget?.namedQueryId ?? widget?.datasetDefinition?.id);
            });
    }

    initFilterWidget(widgetId?: number): BoardWidgetDefinitionIds[] {
        const wsMgrSavedPrefs = this.manager.getWidgetPreferences(widgetId ?? 0);
        if (wsMgrSavedPrefs?.datasetDefinition?.id) {
            const id = wsMgrSavedPrefs.namedQueryId ?? wsMgrSavedPrefs.datasetDefinition.id;
            return [new BoardWidgetDefinitionIds(undefined, wsMgrSavedPrefs.id, id)];
        } else {
            return [];
        }
    }

    setSlicerValues(): void {
        this.slicerValues = [];
        if (this.widgetDataSourceObserver) {
            this.widgetDataSourceObserver.unsubscribe();
        }
        this.widgetDataSourceObserver = this.widgetDataSourceService.dataSource$.subscribe({
            next: (res: Datasource) => {
                res.datasources.forEach((dw) => {
                    if (
                        (this.filterType === FILTER_TYPE.DASHBOARD && dw.uniqueKey.sourceType === 'dashboard') ||
                        (this.filterType !== FILTER_TYPE.DASHBOARD && dw.uniqueKey.sourceType === 'widget' && dw.uniqueKey.sourceId === this.widgetId)
                    ) {
                        this.slicerValues = this.slicerValues.concat(dw.data);
                    }
                });
            },
        });
    }

    getCriteriaOptions(): DropdownOption[] {
        return [
            { text: 'Include', value: INCLUDE, key: INCLUDE.toLocaleLowerCase() },
            { text: 'Exclude', value: EXCLUDE, key: EXCLUDE.toLocaleLowerCase() },
        ];
    }

    updateMetadata(data: { [fieldName: string]: FieldMetadata }): void {
        this.completeMetadata = data;
        this.convertToMetadataList();
    }

    convertToMetadataList(): void {
        let dataKeys: string[] = this.completeMetadata ? Object.keys(this.completeMetadata) : [];
        // remove UDFs as filterable attributes
        // until we start fetching the data for them from Trebek
        dataKeys = dataKeys.filter((k) => !k.startsWith('udf_'));
        this.metadataList = [];
        dataKeys.forEach((key) => {
            if (this.completeMetadata?.[key] && !this.dashboardFilters?.some((filter) => filter.name === key)) {
                const metadata: TextValuePair = {
                    text: this.completeMetadata[key].displayName ?? '',
                    value: key,
                };
                this.metadataList?.push(metadata);
            }
        });
    }

    getUniqueValueCount(attribute: string): number {
        return this.slicerValues.filter((value, index, self) => {
            return value[attribute] != null && self.findIndex((x) => x[attribute] === value[attribute]) === index;
        }).length;
    }

    getAllValueOptions(): WidgetData[] {
        return this.slicerValues;
    }

    getValueOptionsByAttribute(attribute: string | undefined, values?: WidgetData[]): ValueFilterOption[] {
        const valueFilterOptions: ValueFilterOption[] = [];
        const filterOptions: string[] = [];
        const slicerValues = values ?? this.slicerValues;
        if (attribute) {
            from(slicerValues)
                .pipe(distinct((group) => group[attribute]))
                .subscribe((value) => {
                    if (Object.prototype.hasOwnProperty.call(value, attribute)) {
                        const text: string = (value[attribute] === 0 || value[attribute] || typeof value[attribute] === 'boolean' ?
                            value[attribute]?.toString() :
                            'Blanks') ?? '';

                        if (filterOptions.indexOf(text) === -1) {
                            const attribVal: ValueFilterOption = {
                                text,
                                value: value[attribute] == null ? '' : value[attribute]?.toString() ?? '',
                            };
                            valueFilterOptions.push(attribVal);
                            filterOptions.push(text);
                        }
                    }
                });
        }
        return valueFilterOptions;
    }

    updateValueOptions(attribute: string): void {
        this.valueFilterOptionsSubject.next(this.getValueOptionsByAttribute(attribute));
    }

    updateTotalValueCount(): void {
        this.dashboardFilters?.forEach((filter) => {
            this.totalValueCount[filter.name ?? ''] = this.getUniqueValueCount(filter.name ?? '');
        });
    }
}

