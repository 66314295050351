import { Directive, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { WidgetDragPubSubService } from './widget-drag-pub-sub.service';
import { DragEventConfig, WidgetDragElementModel } from './widget-drag.model';

@Directive({ selector: '[appWidgetDrag]' })
export class WidgetDragDirective implements OnInit, OnDestroy {
    @Input() isDraggable = true;
    @Input() dragImagePath = '';

    @Output() dragStart = new EventEmitter<DragEventConfig>();
    @Output() dragEnd = new EventEmitter<DragEventConfig>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private sub: any;
    private dragModel: WidgetDragElementModel = {};
    private readonly imageDragElement: HTMLImageElement = document.createElement('img');

    constructor(private readonly widgetDragPubSubService: WidgetDragPubSubService) {}

    ngOnInit(): void {
        this.sub = this.widgetDragPubSubService.stream.subscribe((event) => {
            if (this.dragModel.dragStarted && event.dragEventConfig) {
                this.dragModel.dragStarted = false;
                this.dragEnd.emit(event.dragEventConfig);
            }
        });
        this.imageDragElement.src = this.dragImagePath;
    }

    @HostBinding('draggable')
    get draggable(): boolean {
        return this.isDraggable;
    }

    @HostListener('dragstart', ['$event'])
    onDragStart(event: DragEvent): void {
        const dragElement: HTMLElement = event.currentTarget as HTMLElement;
        const widgetElement: HTMLElement = dragElement.parentElement!.parentElement as HTMLElement;
        const rectBoundary = {
            top: widgetElement.offsetTop,
            left: widgetElement.offsetLeft,
            bottom: widgetElement.offsetTop + widgetElement.offsetHeight,
            right: widgetElement.offsetLeft + widgetElement.offsetWidth,
        };
        this.dragStart.emit({ event, rectangle: rectBoundary });
        event.dataTransfer?.setData('text', '');
        if (event.dataTransfer?.setDragImage) {
            if (window.navigator.userAgent.indexOf('Safari') >= 0 && window.navigator.vendor.indexOf('Apple') >= 0) {
                event.dataTransfer.setDragImage(this.imageDragElement, -9999, -9999);
            } else {
                event.dataTransfer.setDragImage(new Image(), -9999, -9999);
            }
        }
        this.dragModel = {
            header: dragElement,
            widgetElement,
            layerX: event.offsetX,
            layerY: event.offsetY,
            dragStarted: true,
        };
        this.widgetDragPubSubService.stream.emit(this.dragModel);
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}

