import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { AlertService } from '@ddv/common-components';

import { ErrorLogger } from './error-logger';

export type FetchFunction = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;
export const fetchInjectionToken: InjectionToken<FetchFunction> = new InjectionToken<FetchFunction>('window.fetch');
export const errorHandlerConfigInjectionToken: InjectionToken<{ url: string, shouldRelay: boolean }> = new InjectionToken('errorHandlerConfigInjectionToken');

@Injectable()
export class ErrorLoggerService implements ErrorLogger {
    private readonly url: string;
    // this is stomped on in tests, so cannot be readonly
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private shouldRelayToBackend = false;

    constructor(
        private readonly alertService: AlertService,
        @Inject(fetchInjectionToken) @Optional() private readonly fetch: FetchFunction | null,
        @Inject(errorHandlerConfigInjectionToken) config: { url: string, shouldRelay: boolean },
    ) {
        this.url = config.url;
        this.shouldRelayToBackend = config.shouldRelay;
    }

    logError(_: string, error: Error): void {
        if (error.message && !error.message.includes('/client_usage')) {
            this.alertService.warn(error.message);

            if (this.shouldRelayToBackend) {
                this.relayErrorToBackend(error);
            }
        }
    }

    private relayErrorToBackend(error: Error): void {
        if (!(this.fetch || window.fetch)) {
            console.warn('not posting error.  no window.fetch');
            return;
        }

        const clientSideLoggerPayload = {
            time: new Date().toString(),
            log: {  // you have to copy the error like this because it can't be JSON.stringify'd
                message: error.message,
                stack: error.stack,
                uiRoute: window.location.hash,
            },
        };

        (this.fetch || window.fetch)(this.url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(clientSideLoggerPayload),
                credentials: 'include',
            })
            .then()
            .catch((relayError) => console.error('Failed to relay error to backend:', relayError));
    }
}
