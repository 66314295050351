import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appScrollToChild]',
})
export class ScrollToChildDirective {
    @Output() public scrollToChild = new EventEmitter();

    @HostListener('click', ['$event.target'])
    public setScrollToChildElement(element: HTMLElement): void {
        const modalContent = document.getElementsByClassName('modal-dialog-content');
        if (!modalContent.length) {
            return;
        }

        const scrollHeight = modalContent[0].scrollTop;
        modalContent[0].scrollTop = element.getBoundingClientRect().bottom;
        if (!scrollHeight) {
            this.scrollToChild.emit(null);
        }
    }
}
