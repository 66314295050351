import { AgEditorComponent } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'app-user-defined-field-cell-editor',
    templateUrl: './user-defined-field-cell-editor.component.html',
    styleUrls: ['./user-defined-field-cell-editor.component.scss'],
})
export class UserDefinedFieldCellEditorComponent implements AgEditorComponent, AfterViewInit {
    params: Partial<ICellEditorParams> | undefined;
    inputValue: string | number = '';
    originalValue: Value = {};

    @ViewChild('input', { read: ViewContainerRef, static: true }) private readonly input: ViewContainerRef | undefined;

    agInit(params: Partial<ICellEditorParams>): void {
        this.params = params;

        if (params?.data && params.colDef?.field) {
            this.originalValue = { ...params.data[params.colDef.field] };
            this.inputValue = params.data[params.colDef.field]?.value || '';

            if (params.node?.isRowPinned()) {
                params.stopEditing?.();
            }
        }
    }
    getFrameworkComponentInstance(): void {}

    getValue(): Value {
        this.originalValue.value = this.inputValue;
        return this.originalValue;
    }

    isCancelAfterEnd(): boolean {
        this.inputValue = this.originalValue.type === 'decimal' ?
            Number(this.inputValue) :
            (this.inputValue as string)?.trim();

        return this.inputValue === this.originalValue.value;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    ngAfterViewInit(): void {
        // this is straight from the ag-grid documentation and it doesn't work without the setTimeout()
        window.setTimeout(() => {
            this.input?.element.nativeElement.focus();
            this.input?.element.nativeElement.select();
        });
    }
}

interface Value {
    value?: string | number;
    type?: string;
}
