import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output } from '@angular/core';
import { DropdownOption } from '@ddv/common-components';
import { UserEntitlementService } from '@ddv/entitlements';
import { SliceManagement, ValueFilterOption, VizFormat, VizConfigs, VisualizationPreferences } from '@ddv/models';
import { SelectedWidgetRelayService } from '@ddv/visualizations';
import { combineLatest } from 'rxjs';

import { ConfigValue } from '../../models/config.value';

@Component({ template: '' })
export abstract class FormatComponent {
    @Output() updatePreview = new EventEmitter();

    private vizFormatters: VizFormat[] | undefined;

    set formatters(value: VizFormat[]) {
        this.vizFormatters = value;
        this.dropdownOptions = {};
        this.dropdownSelectedValues = {};

        const formattersConfigs = this.vizFormatters.filter((f) => !!f.configs);

        if (formattersConfigs.length) {
            formattersConfigs.forEach((f) => f.configs?.forEach((c) => {
                if (c.values) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.dropdownOptions[c.id] = c.values.map((v: any) => ({ text: v.label, key: v.value, value: v.value }));
                    this.dropdownSelectedValues[c.id] = this.dropdownOptions[c.id].find((o) => o.key === c.selectedValue);
                }
            }));
        } else {
            // Summary widget
            this.vizFormatters.filter((f) => !!f.values).forEach((fv) => {
                if (fv.values) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    this.dropdownOptions[fv.id] = fv.values.map((v: any) => ({ text: v.label, key: v.value, value: v.value }));
                    this.dropdownSelectedValues[fv.id] = this.dropdownOptions[fv.id].find((o) => o.key === fv.selectedValue);
                }
            });
        }
    }

    get formatters(): VizFormat[] {
        return this.vizFormatters ?? [];
    }

    filterAttributeList: Map<string, ValueFilterOption[]> = new Map();
    selectedSlicerValue: string | undefined;

    dropdownOptions: Record<string, DropdownOption[]> = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dropdownSelectedValues: Record<string, any> = {};

    abstract initialize(): void;

    isFormatterValid = true;
    isReadOnly = false;
    customSortOption: string | undefined;

    constructor(
        private readonly userEntitlementService: UserEntitlementService,
        private readonly selectedWidgetRelayService: SelectedWidgetRelayService,
    ) {
        combineLatest([this.userEntitlementService.entitlementsForClientCode$, this.selectedWidgetRelayService.isWidgetGlobal])
            .subscribe(([entitlements, isWidgetGlobal]) => {
                this.isReadOnly = entitlements.haveGlobalEditPartial && isWidgetGlobal;
            });
    }

    addCustomSortOption(): void {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onMultiSelectChanged(_list: any[], _config: any): void {}

    dropItem(_event: CdkDragDrop<string[]>, _itemsList?: ValueFilterOption[]): void {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getSelectedConfigs(_: VizConfigs | any): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const appliedFormatters: Record<string, any> = {};
        this.formatters.forEach((config) => {
            const prefs = this.getMergedConfigs(config as VisualizationPreferences);
            Object.keys(prefs).forEach((preference) => {
                appliedFormatters[preference] = prefs[preference];
            });
        });
        return appliedFormatters;
    }

    showConfigCustomSortOrder(context: { id: string, hidden: boolean, type: string }): boolean | undefined {
        const { id, hidden, type } = context;
        const currentList = this.filterAttributeList.get(this.selectedSlicerValue ?? '');
        return id === 'sortOrderSlicer' && !hidden && type === 'select' && currentList && !!currentList.length;
    }

    setSliceManagement(sliceManagementFormatter: VizFormat): void {
        const findConfig = (configId: string): VizFormat | undefined => sliceManagementFormatter.configs?.find((c) => c.id === configId);
        const managementType = findConfig('sliceManagementType');
        const groupByPercent = findConfig('groupByPercent');
        const groupByMaxCount = findConfig('groupByMaxCount');
        const percentageSelected = managementType?.selectedValue === SliceManagement.PERCENTAGE;
        this.toggleConfigVisibility(groupByPercent, percentageSelected ? '' : 'hidden');
        this.toggleConfigVisibility(groupByMaxCount, percentageSelected ? 'hidden' : '');
    }

    getOptionLabels(ctx: { valueLabels?: string[] }): [string, string] {
        return ctx.valueLabels?.length ? [ctx.valueLabels[0], ctx.valueLabels[1]] : ['On', 'Off'];
    }

    checkCustomValue(_: string): void { }

    onFocusOut(_: { selectedValue: string }): void { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderPreview(_event?: { target: any }, _configId?: string): void {
        const vizFormatter = this.vizFormatters?.find((vz) => vz.label === 'Chart Sorting');
        const [sortBy, sortDirection] = vizFormatter?.configs
            ?.filter((c) => c.id === 'sortTableBy' || c.id === 'tableSortDirection')
            .map((c) => c.selectedValue) ?? [null, null];
        const isTableSortDirectionCustom = sortBy === 'SLICER' && sortDirection === 'CUSTOM';
        this.updatePreview.emit({ isTableSortDirectionCustom });
    }

    protected getMergedConfigs(
        vizConfig: VisualizationPreferences,
        accumulator: { [key: string]: ConfigValue } = {},
    ): { [key: string]: ConfigValue } {
        if (vizConfig.id) {
            accumulator[vizConfig.id] = vizConfig.selectedValue;
        }
        if (vizConfig.configs) {
            vizConfig.configs.forEach((option) => this.getMergedConfigs(option, accumulator));
        }
        return accumulator;
    }

    protected hasValidFormatters(): boolean {
        return this.isFormatterValid;
    }

    private toggleConfigVisibility(config: VizFormat | undefined, cssClass: string): void {
        if (!config) {
            return;
        }

        config.hidden = !!cssClass;
        config.cssClass = cssClass;
    }
}
