<svg version="1.1"
        id="copy-icon-svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="15px"
        height="15px"
        viewBox="0 0 15 15"
        enable-background="new 0 0 15 15"
        xml:space="preserve">
    <title>Unsubscribed Widget</title>
    <path fill="none" stroke="#939598" stroke-miterlimit="10" d="M11.578,3.912c0.682,0.683,0.683,1.791,0,2.475L8.984,8.979 c-0.684,0.684-1.792,0.682-2.475,0L6.097,8.566c-0.684-0.684-0.684-1.792,0-2.475l2.592-2.593c0.684-0.684,1.791-0.684,2.475,0 L11.578,3.912z"/>
    <line fill="none" stroke="#6D6E71" stroke-miterlimit="10" x1="5.584" y1="4.096" x2="4.995" y2="1.346"/>
    <line fill="none" stroke="#6D6E71" stroke-width="1.0593" stroke-miterlimit="10" x1="3.704" y1="3.913" x2="1.787" y2="2.033"/>
    <line fill="none" stroke="#6D6E71" stroke-width="1.0593" stroke-miterlimit="10" x1="4.105" y1="5.783" x2="0.882" y2="5.366"/>
    <g>
        <line fill="none" stroke="#6D6E71" stroke-miterlimit="10" x1="9.18" y1="11.201" x2="9.797" y2="13.945"/>
        <line fill="none" stroke="#6D6E71" stroke-width="1.0593" stroke-miterlimit="10" x1="10.947" y1="11.198" x2="12.883" y2="13.059"/>
        <line fill="none" stroke="#6D6E71" stroke-width="1.0593" stroke-miterlimit="10" x1="10.643" y1="9.5" x2="13.869" y2="9.885"/>
    </g>
    <path fill="#939598" d="M6.567,10.689l-1.597,1.597c-0.472,0.473-1.295,0.473-1.768,0L2.79,11.873c-0.236-0.236-0.366-0.55-0.366-0.884s0.13-0.647,0.366-0.884l2.593-2.593c0.236-0.236,0.55-0.366,0.884-0.366c0.333,0,0.647,0.13,0.884,0.366l0.383,0.383c0.299-0.153,0.642-0.265,1.032-0.311C8.481,7.456,8.384,7.332,8.271,7.219L7.857,6.806c-0.85-0.851-2.333-0.849-3.182,0L2.083,9.398c-0.425,0.425-0.659,0.99-0.659,1.591s0.234,1.166,0.659,1.591l0.413,0.413c0.425,0.425,0.99,0.659,1.591,0.659s1.166-0.234,1.591-0.659l2.096-2.097C7.373,10.855,6.93,10.789,6.567,10.689z"/>
</svg>
