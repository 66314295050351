import { Component, OnInit, ViewChild } from '@angular/core';
import {
    Axis,
    ChartSettings,
    Series,
    safeStringFormatter,
    ColorMetadataService,
    ValueFormatterService,
    ValueAggregationService,
} from '@ddv/charts';
import { MetadataService } from '@ddv/datasets';
import { ClientDatasetFilterService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    SliceManagement,
    WIDGET_LIFECYCLE_EVENT,
    ExportFilteredData,
    DashboardClientQueryParam,
    ConfigItem,
    LegendConfigurationService,
    TableSortType,
    AppWidgetState,
    DataUpdateBody,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
} from '@ddv/models';
import { getDefault, clone, cloneArray } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

import { BaseVisualizationComponent } from '../../../base/base-visualization.component';
import { ChartsSharedService } from '../../../base/visualization-wrapper/charts-shared.service';
import { VisualizationWrapperComponent } from '../../../base/visualization-wrapper/visualization-wrapper.component';
import { ILabelValue } from '../../../base/visualization-wrapper/visualization-wrapper.interface';

@Component({ template: '' })
export abstract class BaseCircleChartVisualizationComponent extends BaseVisualizationComponent implements OnInit {
    @ViewChild(VisualizationWrapperComponent, { static: true }) vizWrapperComponent: VisualizationWrapperComponent | undefined;

    visualizationModel: ChartSettings | undefined;
    protected xAxis: Axis[] = [];
    protected yAxis: Axis[] = [];
    protected chartSeries: Series[] = [];

    constructor(
        metadataService: MetadataService,
        protected legendConfigurationService: LegendConfigurationService,
        protected chartsSharedService: ChartsSharedService,
        protected clientDatasetFilterService: ClientDatasetFilterService,
        protected colorMetadataService: ColorMetadataService,
        private readonly manager: ManagerService,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private readonly valueAggregationService: ValueAggregationService<any>,
        private readonly valueFormatterService: ValueFormatterService,
        private readonly themeService: ThemeService,
    ) {
        super(metadataService);
    }

    abstract configureChart(): void;

    override ngOnInit(): void {
        super.ngOnInit();
        this.isChartInitialized = true;
        this.vizInfo = this.vizInfo ?? {
            slicer: { label: '', value: '' },
            values: [],
        };
        this.configureChart();

        this.subscribeTo(this.themeService.currentTheme$, ((theme: Theme) => {
            this.theme = theme;
            this.setVizSlicer();
        }));

        this.subscribeTo(this.manager.isStackedQuery(this.datasetId), (isStackedQuery) => this.isStackedQuery = isStackedQuery);
    }

    setVizSlicer(): void {
        if (this.vizInfo?.slicer.label) {
            this.vizSlicer.next(this.vizInfo.slicer);
            return;
        }
        const slicers = this.preferences?.configs?.slicers ?? [];
        if (slicers.length) {
            const selectedSlicer = getDefault(slicers);
            this.vizSlicer.next({ label: selectedSlicer?.label, value: selectedSlicer?.value });
        }
    }

    onFilterChanged(slicer: ILabelValue): void {
        if (this.vizInfo) {
            this.vizInfo.slicer = slicer;
            this.vizInfo.values = cloneArray(this.preferences?.configs?.values ?? []);
            this.chartSeries[0].xField[0] = this.vizInfo.slicer.value ?? '';
            this.updateVizModelOnSlicerChange();
        }
    }

    override onVizInfoChanged(slicer: Partial<ConfigItem>): void {
        if (this.vizInfo && this.vizInfo.slicer.value !== slicer.value) {
            this.vizInfo.slicer = slicer;
        }
    }

    resetHighlightData(): void {
        if (this.visualizationModel?.highlight) {
            this.visualizationModel.highlight.data = undefined;
        }
    }

    updateVizModelOnSlicerChange(): void {
        if (this.visualizationModel && this.preferences && this.vizInfo) {
            let sortOrderSlicer: ConfigItem | undefined;
            if (this.preferences.configs?.slicers) {
                const colorRange = this.chartsSharedService.getColorRange(
                    this.preferences,
                    this.colorMetadataService,
                    this.vizInfo.slicer.value ?? '',
                    this.theme);
                if (colorRange?.length) {
                    this.visualizationModel.colorRange = colorRange;
                }
                this.visualizationModel.attributeCustomColors = this.chartsSharedService.getAttributeCustomColors(
                    this.preferences,
                    this.vizInfo.slicer.value ?? '',
                    this.theme);
                if (this.visualizationModel.colorRange && this.visualizationModel.colorRange?.length > 3 &&
                    this.visualizationModel.attributeCustomColors?.length) {
                    this.visualizationModel.colorRange = this.chartsSharedService.getFilteredColorRange(this.visualizationModel);
                }
                sortOrderSlicer = super.getCurrentSortOrderSlicer(this.vizInfo?.slicer.value);
            }
            this.visualizationModel.series = this.chartSeries;
            this.visualizationModel.drilldown = {
                keys: this.chartsSharedService.getDrillKeys(this.preferences.configs?.slicers ?? [], this.vizInfo.slicer),
                text: {
                    x: 12,
                    y: 20,
                    size: 12,
                },
                delimImg: {
                    x: 10,
                    y: 9,
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                aggregateDataOnDrill: (dataSource: any[], slicer): object[] => {
                    if (dataSource[0].values) {
                        // Already been aggregated
                        return dataSource;
                    }
                    return this.chartsSharedService.getCircleDataSource(
                        dataSource,
                        slicer,
                        this.preferences?.configs?.values ?? [],
                        this.preferences?.sortTableBy,
                        this.preferences?.tableSortDirection,
                        sortOrderSlicer);
                },
            };
            this.createChartData(this.preferences.sortTableBy, this.preferences.tableSortDirection);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, _: any): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.INIT_WIDGET:
            case WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE:
                this.vizWrapperComponent?.resizeVisualization();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE:
                this.vizWrapperComponent?.onMaximize();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE:
                this.vizWrapperComponent?.onRestore();
        }
    }

    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                this.updateDataSource(data as Required<DataUpdateBody>);
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = false;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.LOADING_DATA:
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = true;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.VISUALIZATION_SELECTED:
                this.updateChartSelection(data);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    updateChartSelection(data: any): void {
        if (this.isChartInitialized) {
            this.visualizationModel = clone(this.visualizationModel, {
                highlight: data.selectedItem ? { data: data.selectedItem } : {},
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateDataSource(componentData: { data: any[], filters: DashboardClientQueryParam, widgetPrefs: AppWidgetState }): void {
        const activeDate = this.isStackedQuery ? undefined : componentData.filters.activeDate;
        this.vizData = this.clientDatasetFilterService.filterData(componentData.data, { activeDate });
        if (this.isChartInitialized) {
            let sortTableBy = this.preferences?.sortTableBy;
            let tableSortDirection = this.preferences?.tableSortDirection;
            if (componentData.widgetPrefs && !this.isManagingWidget) {
                sortTableBy = componentData.widgetPrefs.sortTableBy ?? sortTableBy;
                tableSortDirection = componentData.widgetPrefs.tableSortDirection;
                if (this.visualizationModel) {
                    this.visualizationModel.formatter = clone(this.visualizationModel.formatter, {
                        sortDirection: componentData.widgetPrefs.tableSortDirection,
                    });
                }
            }
            this.createChartData(sortTableBy, tableSortDirection);
        }
    }

    createChartData(sortTableBy: TableSortType | undefined, tableSortDirection: string | undefined): void {
        if (this.vizData) {
            const sortOrderSlicer = super.getCurrentSortOrderSlicer(this.vizInfo?.slicer.value);
            const dataSource = this.chartsSharedService.getCircleDataSource(
                this.vizData,
                this.visualizationModel?.series[0].xField[0] ?? '',
                this.preferences?.configs?.values ?? [],
                sortTableBy,
                tableSortDirection,
                sortOrderSlicer);
            this.visualizationModel = clone(this.visualizationModel,{ dataSource });
        }
    }

    getExportFilteredData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFilteredData();
    }

    getExportFullData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFullData();
    }

    prepareChartModel(): void {
        if (!this.preferences) {
            return console.error('cannot prepareChartModel without preferences');
        }

        this.visualizationModel = this.chartsSharedService.getBaseChartModel(this.chartSeries, [this.xAxis, this.yAxis], undefined);
        this.createChartData(this.preferences.sortTableBy, this.preferences.tableSortDirection);
        this.visualizationModel.legend = this.legendConfigurationService.getConfigFromUserPreference(this.preferences);
        this.visualizationModel.showTooltip = this.preferences.enableTooltip;
        if (this.preferences.visualizationType === 'DONUT_CHART') {
            this.visualizationModel.displayTotal = this.preferences.displayTotal;
            this.visualizationModel.displayTotalLabel = this.preferences.configs?.values[0].showCustomName ?
                this.preferences.configs.values[0].customName :
                this.preferences.configs?.values[0].label;
        }
        if (this.visualizationModel.showTooltip) {
            this.visualizationModel.tooltip = this.preferences.configs?.tooltips.map((item) => ({ key: item.value, name: item.label }));
        }
        this.visualizationModel.enableDrilldown = !this.preferences.highlightSlice;
        this.visualizationModel.highlightSlice = this.preferences.highlightSlice;
        const { enableSliceManagement, sliceManagementType, groupByPercent, groupByMaxCount } = this.preferences;
        if (enableSliceManagement) {
            this.visualizationModel.enableSliceManagement = enableSliceManagement;
            this.visualizationModel.groupByType = sliceManagementType;
            this.visualizationModel.groupByValue = sliceManagementType === SliceManagement.PERCENTAGE ? groupByPercent : groupByMaxCount;
        }
        this.visualizationModel.highlight = { text: { size: 12, x: 12, y: 20 } };
        if (this.preferences.enableLabels) {
            this.visualizationModel.showLabels = true;
            this.visualizationModel.label = {
                inline: this.preferences.labelStyle === 'inline',
                projectedLines: this.preferences.labelStyle === 'showLines',
                minLabelValue: 0.05,
            };
        }
        this.visualizationModel.margin = this.chartsSharedService.computePieDonutMargin(this.visualizationModel);
        if (this.preferences.numberFormat || (this.preferences.decimalPlaces ?? 0) >= 0) {
            this.visualizationModel.formatter = {
                isNumberFormatted: true,
                numberUnits: this.preferences.numberUnits,
                numberFormat: this.preferences.numberFormat,
                decimalPlaces: this.preferences.decimalPlaces,
            };
        }

        this.visualizationModel.formatter = clone(this.visualizationModel.formatter, {
            sortDirection: this.preferences.tableSortDirection,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    getToolTip(tooltipData: any): string {
        let html = '<div class="tooltip-data"><table class="disp-table">';
        if (tooltipData) {
            if (tooltipData.data) {
                const name = tooltipData.data.key == null ?
                    'Null' :
                    (tooltipData.data.key.toString().trim() ? safeStringFormatter(tooltipData.data.key) : 'Blanks');
                html += `<tr><td colspan="2"><label class="base-circle-chart-header"><b>${name.toUpperCase()}</b> </label></td></tr>`;
            }
            this.preferences?.configs?.tooltips.forEach((tooltip) => {
                const aggFunName = tooltip.aggregationType;
                let aggValue = this.valueAggregationService.aggregateValue(
                    tooltipData.data.values,
                    tooltip.value!,
                    aggFunName);
                aggValue = this.valueFormatterService.applyFormatter(aggValue, tooltip);
                aggValue = aggValue.toString().trim() ? aggValue : 'Blanks';
                html += `<tr><td><label>${aggFunName?.split(/(?=[A-Z])/).join(' ')} of ${tooltip.showCustomName ? tooltip.customName : tooltip.label}`;
                html += `</label></td><td style="text-align:right;"><span>${aggValue}</span></td></tr>`;
            });
        }
        return `${html}</table></div>`;
    }
}
