import { Injectable } from '@angular/core';
import { BarChartData, ValueAggregationService } from '@ddv/charts';
import { DatedPublicApiResponseRow } from '@ddv/datasets';
import { AggType, ValueFilterOption, SliceManagement, SliceManagementOptions, TableSortType } from '@ddv/models';
import { getValidString } from '@ddv/utils';

import { hashFromKeys } from '../../../base/visualization-wrapper/charts-shared.service';

@Injectable()
export class HorizontalBarChartVisualizationDataService {
    constructor(private readonly valueAggregationService: ValueAggregationService<DatedPublicApiResponseRow>) {}

    getData(
        source: DatedPublicApiResponseRow[],
        slicerFieldName: string | undefined,
        valueFieldName: string,
        aggregationType: AggType | null | undefined,
        sortBy: TableSortType | undefined,
        sortDirection: string | undefined,
        customSortOrderWhenSortedOnSlicer?: { configCustomSortOrder?: string | ValueFilterOption[] | null },
        sliceManagementOptions?: SliceManagementOptions,
    ): BarChartData[] {
        const data = this.convertToBarChartData(
            source,
            slicerFieldName as keyof DatedPublicApiResponseRow,
            valueFieldName,
            aggregationType);
        const sortedData = sortBarChartData(data, sortBy, sortDirection, customSortOrderWhenSortedOnSlicer?.configCustomSortOrder);

        if (!sliceManagementOptions) {
            return sortedData;
        }

        return aggregateSmallSlices(
            sortedData,
            sliceManagementOptions.groupByType,
            sliceManagementOptions.groupByValue,
            sliceManagementOptions.showOthers);
    }

    private convertToBarChartData(
        source: DatedPublicApiResponseRow[],
        slicerFieldName: keyof DatedPublicApiResponseRow,
        valueFieldName: keyof DatedPublicApiResponseRow,
        aggregationType: AggType | null | undefined,
    ): BarChartData[] {
        const groupedBySlicerValue = getDataGroupedBySlicer(source, slicerFieldName);
        return Object.entries(groupedBySlicerValue).map(([slicerValue, data]) => {
            return {
                key: {
                    fieldName: String(slicerFieldName),
                    value: slicerValue,
                },
                value: {
                    fieldName: String(valueFieldName),
                    value: this.valueAggregationService.aggregateValue(data, valueFieldName, aggregationType),
                },
                sourceRows: data,
            };
        });
    }
}

function getDataGroupedBySlicer(
    source: DatedPublicApiResponseRow[],
    slicerFieldName: keyof DatedPublicApiResponseRow,
): Record<string, DatedPublicApiResponseRow[]> {
    const aggregated: Record<string, DatedPublicApiResponseRow[]> = {};
    source.forEach((item) => {
        const hash = hashFromKeys(item, [slicerFieldName]);
        if (!aggregated[hash]) {
            aggregated[hash] = [];
        }
        aggregated[hash].push(item);
    });
    return aggregated;
}

function sortBarChartData(
    data: BarChartData[],
    sortBy: TableSortType | undefined,
    sortDirection: string | undefined,
    customSortOrderWhenSortedOnSlicer?: string | ValueFilterOption[] | null,
): BarChartData[] {
    if (sortDirection === 'CUSTOM' && customSortOrderWhenSortedOnSlicer) {
        const orderedValues = typeof customSortOrderWhenSortedOnSlicer === 'string' ?
            JSON.parse(getValidString(customSortOrderWhenSortedOnSlicer)) :
            customSortOrderWhenSortedOnSlicer;
        return sortDataByCustomSortOrder(data, orderedValues);
    } else {
        const isSortedAscending = sortDirection === 'ASC' || false;
        const sortField = sortBy === 'SLICER' ? 'key' : 'value';
        return data.sort((a, b) => {
            const aSortValue = a[sortField].value;
            const bSortValue = b[sortField].value;

            if (aSortValue !== undefined && bSortValue !== undefined) {
                return aSortValue < bSortValue !== isSortedAscending ? 1 : -1;
            }

            if (aSortValue === undefined && bSortValue !== undefined) {
                return isSortedAscending ? 1 : -1;
            }

            if (bSortValue === undefined && aSortValue !== undefined) {
                return isSortedAscending ? -1 : 1;
            }

            return 0;
        });
    }
}

function sortDataByCustomSortOrder(data: BarChartData[], orderedSortValues: ValueFilterOption[]): BarChartData[] {
    const unsortedData = data.filter((d) => !orderedSortValues.some((item) => item.value === d.key.value));
    const sortedData = data.filter((d) => orderedSortValues.some((item) => item.value === d.key.value));
    unsortedData.sort((a, b) => a.key.value < b.key.value ? -1 : a.key.value > b.key.value ? 1 : 0);
    sortedData.sort((a, b) =>
        orderedSortValues.findIndex((item) => item.value === a.key.value) -
        orderedSortValues.findIndex((item) => item.value === b.key.value));
    sortedData.push(...unsortedData);
    return sortedData;
}

function aggregateSmallSlices(
    source: BarChartData[],
    type: string,
    threshold: number,
    showOthers: boolean,
): BarChartData[] {
    if (!source.length) {
        return [];
    }

    const postAggregationData: BarChartData[] = [];
    const total = source.reduce((sum, d) => sum + Number(d.value.value), 0);
    const aggregate: BarChartData = {
        key: { fieldName: source[0].key.fieldName, value: 'Others' },
        value: { fieldName: source[0].value.fieldName, value: 0 },
        isAggregate: true,
        aggregatedFrom: [],
        sourceRows: [],
    };

    if (type === SliceManagement.PERCENTAGE) { // keep the bars exceeding a certain size
        source.forEach((d) => {
            const percentage = Number(d.value.value) / total;
            if (Math.abs(percentage) > threshold / 100) {
                postAggregationData.push(d);
            } else {
                addDataToAggregate(aggregate, d);
            }
        });
    } else { // keep the top N bars
        if (source.length <= threshold) {
            return source;
        }

        const sorted = [...source].sort((a, b) => Math.abs(Number(b.value.value)) - Math.abs(Number(a.value.value)));
        // remember `.splice()` removes items from the original array, so after this call `sorted` retains the number of bars
        const discarded = sorted.splice(threshold - 1, sorted.length);
        const remainder = source.filter((s) => !discarded.includes(s));
        postAggregationData.push(...remainder);
        addDataToAggregate(aggregate, ...source.filter((s) => discarded.includes(s)));
    }

    if (showOthers && aggregate.aggregatedFrom?.length) {
        postAggregationData.push(aggregate);
    }

    return postAggregationData;
}

function addDataToAggregate(aggregate: BarChartData, ...source: BarChartData[]): void {
    source.forEach((d) => {
        aggregate.aggregatedFrom?.push(d);
        aggregate.sourceRows.push(...d.sourceRows);
        aggregate.value.value = Number(aggregate.value.value) + Number(d.value.value);
    });
}
