import { Injectable } from '@angular/core';
import { ApmService } from '@elastic/apm-rum-angular';

import { ErrorLogger } from './error-logger';

@Injectable()
export class ApmErrorLogger implements ErrorLogger {
    constructor(private readonly apmService: ApmService) { }

    logError(_: string, error: Error | unknown): void {
        if (Object.prototype.hasOwnProperty.call(error, 'message')) {
            this.apmService.apm.captureError(error as Error);
        }
    }
}
