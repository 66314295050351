import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EXCLUDE, INCLUDE } from '@ddv/models';

import { DropdownOption } from '../dropdown/dropdown-option';
import { ListBrowserFilter } from './list-browser.pipe';
import { ValueOption } from './value-option';

export interface SelectedValues {
    criteria?: string;
    values?: string[];
}

@Component({
    selector: 'app-list-browser',
    templateUrl: 'list-browser.component.html',
    styleUrls: ['list-browser.component.scss'],
})
export class ListBrowserComponent implements OnInit, OnChanges {
    readonly itemSize = 30;
    private readonly visibleItems = 6;

    @Input() buttonLabel = 'Add';
    @Input() criteria: string | undefined;
    @Input() criteriaOptions: DropdownOption[] = [
        { text: 'Include', value: INCLUDE, key: INCLUDE.toLocaleLowerCase() },
        { text: 'Exclude', value: EXCLUDE, key: EXCLUDE.toLocaleLowerCase() },
    ];
    @Input() criteriaPosition: 'top' | 'side' | undefined;
    @Input() noFiltersAvailable = false;
    @Input() options: ValueOption[] = [];
    @Input() placeholder: string | undefined;
    @Input() showCriteria = true;
    @Input() showHeader = true;
    @Input() showSelected = false;
    @Input() headers: string[] | undefined;
    @Output() addListValues = new EventEmitter<SelectedValues | undefined>();

    private valueOptions: ValueOption[] | undefined;
    selectedCount = 0;
    selectedItems: SelectedValues = {};
    searchValue = '';
    showOnlySelected = false;
    headerLayout: 'none' | 'no-criteria' | 'top-criteria' | 'show-selected' | undefined;
    selectedCriteria: DropdownOption | undefined;
    virtualScrollViewportHeight: number | undefined;

    getValueOptions(showOnlySelected: boolean): ValueOption[] {
        if (!this.valueOptions?.length) {
            return [];
        }

        let options = this.valueOptions;

        if (this.searchValue?.length) {
            options = this.getFilteredValueOptions(options);
        }

        if (showOnlySelected) {
            options = this.getCheckedItems(options);
        }

        return options;
    }

    ngOnInit(): void {
        if (!this.showHeader) {
            this.headerLayout = 'none';
        } else if (this.showSelected) {
            this.headerLayout = 'show-selected';
        } else if (!this.criteria || this.criteriaPosition !== 'top') {
            this.headerLayout = 'no-criteria';
        } else {
            this.headerLayout = 'top-criteria';
        }

        this.criteria = this.criteria ?? this.selectedItems.criteria ?? this.criteriaOptions[0].value;
        this.selectedCriteria = {
            text: this.criteriaOptions.filter((criteria) => criteria.value === this.criteria)[0]?.text ?? this.criteria ?? '',
            value: this.criteria?.toLocaleUpperCase(),
            key: this.criteria?.toLocaleLowerCase(),
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            this.valueOptions = this.options || [];
            this.updateSelectedCount();
            this.setVirtualScrollViewportHeight();
        }
    }

    toggleValues(isSelect: boolean): void {
        // We're sending false because, if this.showOnlySelected is true it will return
        // the wrong number of elements that needs to be checked or not
        const options = this.getValueOptions(false);
        options.map((o) => o.checked = isSelect);

        this.updateSelectedCount();
        this.setVirtualScrollViewportHeight();
    }

    onShowOnlySelected(): void {
        this.setVirtualScrollViewportHeight();
    }

    addValues(): void {
        if (this.noFiltersAvailable) {
            this.addListValues.emit(undefined);
            return;
        }

        this.selectedItems.criteria = this.criteria;
        this.selectedItems.values = this.valueOptions
            ?.filter((value) => value.checked)
            .map((value) => `${value.value}`);
        this.addListValues.emit(this.selectedItems);
    }

    updateSelectedCriteria(e: DropdownOption): void {
        this.criteria = e.value;
        this.selectedCriteria = e;
    }

    onCheckboxChanged(): void {
        this.updateSelectedCount();
        this.setVirtualScrollViewportHeight();
    }

    updateSelectedCount(): void {
        this.selectedCount = this.valueOptions?.reduce((count, value) => value.checked ? count + 1 : count, 0) ?? 0;
    }

    setVirtualScrollViewportHeight(): void {
        const itemsCount = this.getValueOptions(this.showOnlySelected)?.length || 0;
        this.virtualScrollViewportHeight = this.itemSize * (itemsCount <= this.visibleItems ? itemsCount : this.visibleItems);
    }

    onSearchValueChanged(): void {
        this.setVirtualScrollViewportHeight();
    }

    private getCheckedItems(options?: ValueOption[]): ValueOption[] {
        const items = options ?? this.getValueOptions(this.showOnlySelected);
        return items.filter((o) => o.checked);
    }

    private getFilteredValueOptions(items?: ValueOption[]): ValueOption[] {
        const textFilter = new ListBrowserFilter();
        return textFilter.transform(items ?? this.valueOptions, this.searchValue);
    }
}
