import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[appMousedownOutside]',
})
export class MousedownOutsideDirective {
    @Output() public mousedownOutside = new EventEmitter();

    constructor(private readonly elementRef: ElementRef) {}

    @HostListener('document:mousedown', ['$event'])
    public onClick(targetElement: MouseEvent): void {
        const mousedownInside = this.elementRef.nativeElement.contains(targetElement.target);
        const targetElementPath = targetElement.composedPath?.();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ccModalOpened = !!targetElementPath?.some((p) => (p as any).tagName?.toLowerCase() === 'cc-modal-layer');

        if (!mousedownInside && !ccModalOpened) {
            this.mousedownOutside.emit(null);
        }
    }
}
