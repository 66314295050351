export function getDefault<T extends { isDefault?: boolean }>(items: T[], defaultItem?: T): T | undefined {
    return items.find((item) => item.isDefault) ?? items[0] ?? defaultItem;
}

export function getUniqueBy<T extends { value: string }>(collection: T[]): T[] {
    const uniqueItemsMap: Map<string, T> = new Map();
    for (const item of collection) {
        uniqueItemsMap.set(item.value, item);
    }
    const uniqueItems: T[] = [];
    uniqueItemsMap.forEach((value) => uniqueItems.push(value));
    return uniqueItems;
}
