import {
    OnInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    ViewChild,
} from '@angular/core';
import { ThemeService } from '@hs/ui-core-presentation';

import { DefaultChartSettings } from '../defaults';
import { ChartData } from '../models/chart-data';
import { ChartSettings } from '../models/chart-settings';
import { DrillInfo } from '../models/drill-info';
import { BarChartService } from '../services/bar-chart.service';
import { BaseChartService } from '../services/base-chart.service';
import { ChartDataRelayService } from '../services/chart-data-relay.service';
import { LineChartService } from '../services/line-chart.service';
import { MultiSeriesChartService } from '../services/multi-series-chart.service';
import { PieChartService } from '../services/pie-chart.service';
import { StackedAreaChartService } from '../services/stacked-area-chart.service';
import { BigWTablePair } from './bigw-table-pair';

@Component({
    selector: 'app-chart',
    template: '<div class="chart-div" #vizComponent></div>',
    styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, BigWTablePair {
    @Input() chartConfiguration: ChartSettings | undefined;
    @Input() isMaster = false;
    @Input() isMaximized = false;
    change: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('vizComponent', { static: true }) private readonly chartContainer: ElementRef | undefined;
    private vizModel: ChartSettings | undefined;
    private readonly lineChartService: LineChartService;
    private readonly barChartService: BarChartService;
    private readonly pieChartService: PieChartService;
    private readonly multiSeriesChartService: MultiSeriesChartService;
    private readonly stackedAreaChartService: StackedAreaChartService;
    private currentChartService: BaseChartService | undefined;

    constructor(
        private readonly chartDataRelay: ChartDataRelayService,
        themeService: ThemeService,
    ) {
        this.lineChartService = new LineChartService();
        this.barChartService = new BarChartService();
        this.pieChartService = new PieChartService(themeService);
        this.multiSeriesChartService = new MultiSeriesChartService();
        this.stackedAreaChartService = new StackedAreaChartService();
    }

    ngOnInit(): void {
        if (this.chartConfiguration) {
            this.initChart(this.chartConfiguration);

            if (this.isMaster) {
                this.chartDataRelay.emitChartData(this.getChartData());
            }
        }
    }

    initChart(model: ChartSettings, legendVisibility?: 'show' | 'hide'): void {
        this.vizModel = new DefaultChartSettings(model);
        this.vizModel.selector = this.chartContainer?.nativeElement;

        switch (this.vizModel.series[0].type) {
            case 'line':
                if (this.vizModel.series.length > 1) {
                    this.currentChartService = this.multiSeriesChartService;
                    this.multiSeriesChartService.drawMultiSeriesChart(this.vizModel);
                } else {
                    this.currentChartService = this.lineChartService;
                    this.lineChartService.drawLineChart(this.vizModel, legendVisibility || 'show');
                }
                break;
            case 'pie':
            case 'donut':
                this.currentChartService = this.pieChartService;
                this.pieChartService.initGraph(this.vizModel);
                break;
            case 'bar':
                this.currentChartService = this.barChartService;
                this.barChartService.drawBarChart(this.vizModel, this.isMaximized, legendVisibility || 'show');
                break;
            case 'stacked-area':
                this.currentChartService = this.stackedAreaChartService;
                this.stackedAreaChartService.drawStackedAreaChart(this.vizModel, legendVisibility || 'show');
                break;
            default:
                throw new Error(`${this.vizModel.series[0].type} is not a supported type.`);
        }
    }

    getChartData(): ChartData[] {
        return this.currentChartService?.getChartData() ?? [];
    }

    resizeChart(legendVisibility?: 'show' | 'hide'): void {
        const chartType = this.vizModel?.series[0].type;
        if (chartType === 'pie' || chartType === 'donut') {
            this.pieChartService.reDrawGraph();
        } else {
            if (!this.vizModel) {
                throw new Error('cannot resizeChart without a visModel');
            }
            this.initChart(this.vizModel, legendVisibility);
        }
        this.setLegendsVisibility(legendVisibility || 'show');
    }

    setLegendsVisibility(action: 'show' | 'hide'): void {
        this.currentChartService?.setLegendsVisibility(action);
    }

    resizeBrush(timeline: { notation: string, scale?: number }): void {
        const chartType = this.vizModel?.series[0].type;
        if (chartType === 'line') {
            this.lineChartService.resizeBrush(timeline);
        } else if (chartType === 'stacked-area') {
            this.stackedAreaChartService.resizeBrush(timeline);
        }
    }

    resetChartDataSource(): void {
        this.currentChartService?.resetChartDataSource();
    }

    getState(): ChartSettings | undefined {
        return this.vizModel;
    }

    setState(state: ChartSettings): void {
        if (!this.vizModel) {
            return;
        }

        this.vizModel.hiddenLegends = state.hiddenLegends;
        this.vizModel.highlight = state.highlight;
        this.vizModel.brushRange = state.brushRange;
        this.resizeChart();
    }

    getDrillInfo(): DrillInfo {
        return this.pieChartService.getDrillInfo();
    }

    setDrillInfo(info: DrillInfo): void {
        this.pieChartService.setDrillLevelData(info.list);
    }

    getMirrorInfo(): { mirrorY1Axis: boolean, mirrorY2Axis: boolean } | undefined {
        if (!this.currentChartService) {
            return undefined;
        }

        return {
            mirrorY1Axis: !!(this.currentChartService as BarChartService).mirrorY1Axis,
            mirrorY2Axis: !!(this.currentChartService as BarChartService).mirrorY2Axis,
        };
    }

    getCurrentChartService(): BaseChartService | undefined {
        return this.currentChartService;
    }

    restoreMasterWidgetHighlight(
        data: { key: string | number | boolean | undefined },
        type: string | undefined,
        isStacked: boolean,
        filterType: string,
    ): void {
        if (typeof data.key === 'string') {
            if (type === 'bar' && isStacked && filterType === 'slicer') {
                this.barChartService.highlightStackedBar({ key: data.key });
            } else if (type === 'bar') {
                this.barChartService.highlightBar({ key: data.key });
            } else if (type === 'pie' || type === 'donut') {
                this.pieChartService.highlightSlice({
                    data: { key: data.key },
                });
            }
        }
    }
}

/**
 * Quick and dirty shallow extend
 */
export function extend<A>(a: A): A;
export function extend<A, B>(a: A, b: B): A & B;
export function extend<A, B, C>(a: A, b: B, c: C): A & B & C;
export function extend<A, B, C, D>(a: A, b: B, c: C, d: D): A & B & C & D;
export function extend(...args: any[]): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    const newObj: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
    for (const obj of args) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                // copy all the fields
                newObj[key] = obj[key];
            }
        }
    }
    return newObj;
}
