import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';

import { BarChartData } from '../../models/chart-data';
import { Margin } from '../../models/margin';
import { ChartPosition } from './horizontal-bar-chart-position.service';

@Injectable()
export class HorizontalBarChartSvgInitializerService {
    public readonly chartHovered$: Observable<[number, number] | undefined>;

    private readonly chartHoveredSubject: Subject<[number, number] | undefined> = new Subject();
    private svgSelection: d3.Selection<SVGElement, BarChartData, null, undefined> | undefined;

    constructor() {
        this.chartHovered$ = this.chartHoveredSubject.asObservable();
    }

    initialize(
        root: SVGElement,
        chartPosition: ChartPosition,  // this is the charts position inside the root SVG element
        clipPathId: string,
    ): d3.Selection<SVGGElement, BarChartData, null, undefined> {
        const chartRootGSelection = this.clearAndCreateChartRoot(root, chartPosition.margin);

        this.appendRectArea(chartRootGSelection, chartPosition.widthForBars, chartPosition.heightForBars);

        // this SHOULD be unnecessary as the clearAndCreateChartRoot should have taken care of it
        this.removeClipPath(clipPathId);
        this.appendClipPath(chartRootGSelection, clipPathId, chartPosition.widthForBars, chartPosition.heightForBars);

        return chartRootGSelection;
    }

    private clearAndCreateChartRoot(root: SVGElement, margin: Margin): d3.Selection<SVGGElement, BarChartData, null, undefined> {
        this.svgSelection = d3.select(root);

        this.svgSelection.selectAll('*').remove();
        return this.svgSelection
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .classed('chart', true);
    }

    // this rect is invisible and is only there to get cover events
    private appendRectArea(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        widthForBars: number,
        heightForBars: number,
    ): void {
        const rect = chartRootGSelection.append('svg:rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', Math.max(widthForBars, 0))
            .attr('height', Math.max(heightForBars, 0))
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .attr('class', 'rectdrawarea');

        rect?.on('mousemove', (event: MouseEvent) => {
            this.chartHoveredSubject.next(d3.pointer(event));
        }).on('mouseout', () => {
            this.chartHoveredSubject.next(undefined);
        });
    }

    private removeClipPath(clipPathId: string): void {
        const clipPathNode = d3.select(`#${clipPathId}`);
        if (clipPathNode.node()) {
            clipPathNode.remove();
        }
    }

    private appendClipPath(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        clipPathId: string,
        widthForBars: number,
        heightForBars: number,
    ): void {
        chartRootGSelection.append('clipPath')
            .attr('id', `${clipPathId}`)
            .attr('transform', 'translate(0,0)')
            .append('rect')
            .attr('width', widthForBars)
            .attr('height', heightForBars);
    }
}
