import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({ selector: '[ddvOnResize]' })
export class OnResizeDirective implements OnInit, OnDestroy {
    @Output() resized: EventEmitter<DOMRectReadOnly> = new EventEmitter();

    private readonly observer: ResizeObserver;
    private readonly element: HTMLElement;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {
        this.element = this.elementRef.nativeElement;
        this.observer = new ResizeObserver((entries) => this.handle(entries));
    }

    ngOnInit(): void {
        this.observer.observe(this.element);
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    private handle(entries: ResizeObserverEntry[]): void {
        this.resized.emit(entries[0].contentRect);
    }
}
