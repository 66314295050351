import { ViewContainerRef } from '@angular/core';

import { AppWidgetState } from './app-widget-state';
import { WidgetConfiguration } from './widget-configuration';
import { WidgetState } from './widget-state';

let widgetId = 0;
function generateId(): number {
    widgetId += 1;
    return widgetId;
}

interface TempState {
    height: number;
    width: number;
    left: number;
    top: number;
}

export class Widget {
    static hideContentWhileDrag = true;

    title = 'Test';
    selector: string;
    id: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    containerRef?: ViewContainerRef;
    height = 200;
    width = 200;
    display = 'block';
    minHeight = 200;
    maxHeight?: number;
    minWidth = 200;
    maxWidth?: number;
    top = 0;
    left = 0;
    zIndex = 101;
    position = 'absolute';
    maximized = false;
    cascade = true;
    menuOptions: MenuOptionConfig[];
    lifeCycleCallBack?: WidgetLifeCycleCallBack;
    isResizable = true;
    isDraggable = true;
    public readonly isSelectable: boolean;
    allowHeaderDoubleClick = true;
    allowCascade = true;
    selectedClassName = '';
    styleClasses = '';
    selectOnLoad = false;
    extraParameters: {
        preferences?: AppWidgetState;
        reload?: boolean;
    } | undefined;
    dragImagePath?: string;
    resizeDimensions?: { top: number, left: number, width: number, height: number };
    hideLoaderAfterFirstDataLoad?: boolean;
    isDetailWidget?: boolean;
    widgetType: string;

    private tempState: TempState = { height: 0, width: 0, left: 0, top: 0 };

    static setContentVisibilityWhileDrag(visibility: boolean): void {
        Widget.hideContentWhileDrag = visibility;
    }

    constructor(widgetConfig: WidgetConfiguration) {
        this.widgetType = widgetConfig.selector;
        this.title = widgetConfig.title;
        this.selector = widgetConfig.selector;
        this.isSelectable = widgetConfig.isSelectable ?? false;
        this.component = widgetConfig.component;
        const viewPort = this.getViewPort();
        if (widgetConfig.left) {
            this.left = widgetConfig.left * viewPort.width / 100;
        }
        if (widgetConfig.top) {
            this.top = widgetConfig.top * viewPort.height / 100;
        }
        if (widgetConfig.height) {
            this.height = widgetConfig.height * viewPort.height / 100;
        }
        if (widgetConfig.width) {
            this.width = widgetConfig.width * viewPort.width / 100;
        }
        if (widgetConfig.minHeight) {
            this.minHeight = widgetConfig.minHeight;
        }
        if (widgetConfig.minWidth) {
            this.minWidth = widgetConfig.minWidth;
        }
        if (this.height < this.minHeight) {
            this.height = this.minHeight;
        }
        if (this.width < this.minWidth) {
            this.width = this.minWidth;
        }
        if (widgetConfig.dragImagePath) {
            this.dragImagePath = widgetConfig.dragImagePath;
        }
        this.menuOptions = this.getDefaultMenuOptions(widgetConfig.defaultMenuIconClasses ?? []);
        this.id = generateId();
    }

    copyWidgetStateToTemp(state?: WidgetState): void {
        const sourceState = state ?? this;
        this.tempState = {
            height: sourceState.height ?? 0,
            width: sourceState.width ?? 0,
            top: sourceState.top ?? 0,
            left: sourceState.left ?? 0,
        };
    }

    copyTempStateToWidget(): void {
        this.height = this.tempState.height;
        this.width = this.tempState.width;
        this.top = this.tempState.top;
        this.left = this.tempState.left;
    }

    resize(width: number, height: number): void {
        this.width = width;
        this.height = height;
    }

    move(left: number, top: number): void {
        this.left = left;
        this.top = top;
    }

    updateState(state: WidgetState): void {
        this.maximized = state.maximized ?? this.maximized;
        const viewPort = this.getViewPort();
        if (state.left) {
            this.left = state.left * viewPort.width / 100;
        }
        if (state.top) {
            this.top = state.top * viewPort.height / 100;
        }

        if (state.height) {
            this.height = state.height * viewPort.height / 100;
        }
        if (state.width) {
            this.width = state.width * viewPort.width / 100;
        }
    }

    updateTitle(title: string): void {
        this.title = title;
    }

    getState(): WidgetState {
        const state = new WidgetState();
        const viewPort = this.getViewPort();
        state.id = this.id;
        state.selector = this.selector;
        state.height = this.roundTo2DecimalFloor(this.height * 100 / viewPort.height);
        state.width = this.roundTo2DecimalFloor(this.width * 100 / viewPort.width);
        state.left = this.roundTo2DecimalFloor(this.left * 100 / viewPort.width);
        state.top = this.roundTo2DecimalFloor(this.top * 100 / viewPort.height);
        state.maximized = this.maximized;
        return state;
    }

    getDefaultMenuOptions(defaultMenuIconClasses: { selector: string, styleClass: string }[]): MenuOptionConfig[] {
        const menuOptions: MenuOptionConfig[] = [
            { iconName: 'trashcan', selector: 'closeBtn', title: 'Remove' },
            { iconName: 'expand', selector: 'maxBtn', title: 'Maximize' },
            { iconName: 'contract', selector: 'cascadeBtn', title: 'Cascade' },
        ];
        if (defaultMenuIconClasses) {
            defaultMenuIconClasses.forEach((defaultMenuIcon) => {
                const menuOption = menuOptions.find((option) => option.selector === defaultMenuIcon.selector);
                if (menuOption) {
                    menuOption.styleClasses += ` ${defaultMenuIcon.styleClass}`;
                }
            });
        }
        return menuOptions;
    }

    addMenuOptions(menuOptions: MenuOptionConfig[]): void {
        this.menuOptions.push(...menuOptions);
    }

    getMenuOptions(): MenuOptionConfig[] {
        return this.menuOptions;
    }

    hideMenuIcons(menuOptionSelectors: string[]): void {
        this.setMenuVisibility(menuOptionSelectors, true);
    }

    showMenuIcons(menuOptionSelectors: string[]): void {
        this.setMenuVisibility(menuOptionSelectors, false);
    }

    enableAllMenuIcons(): void {
        const menuOptions = this.getMenuOptions();
        if (menuOptions) {
            menuOptions.forEach((menuOption) => {
                menuOption.disabled = false;
            });
        }
    }

    enableMenuIcons(menuOptionSelectors: string[]): void {
        const menuOptions = this.getMenuOptions();
        if (menuOptions && menuOptionSelectors) {
            menuOptionSelectors.forEach((selector) => {
                const menuOption = menuOptions.find((option) => option.selector === selector);
                if (menuOption) {
                    menuOption.disabled = false;
                }
            });
        }
    }

    disableAllMenuIcons(): void {
        const menuOptions = this.getMenuOptions();
        if (menuOptions) {
            menuOptions.forEach((menuOption) => {
                menuOption.disabled = true;
            });
        }
    }

    disableMenuIcons(menuOptionSelectors: string[]): void {
        const menuOptions = this.getMenuOptions();
        if (menuOptions && menuOptionSelectors) {
            menuOptionSelectors.forEach((selector) => {
                const menuOption = menuOptions.find((option) => option.selector === selector);
                if (menuOption) {
                    menuOption.disabled = true;
                }
            });
        }
    }

    updateMenuOptionStyleClass(menuOptionSelector: string, styleClass: string): void {
        const menuOptions = this.getMenuOptions();
        if (menuOptions) {
            const menuOption = menuOptions.find((option) => option.selector === menuOptionSelector);
            if (menuOption) {
                menuOption.styleClasses = styleClass;
            }
        }
    }

    bringInForeground(): void {
        this.zIndex = 102;
        this.selectedClassName = 'widget-selected';
    }

    bringInBackground(): void {
        if (!this.maximized) {
            this.zIndex = 101;
        }
        this.selectedClassName = '';
    }

    forceFitWidgetInArea(width: number, height: number): void {
        if (width > 0 && height > 0) {
            let newWidth = 0;
            let newHeight = 0;
            let widgetSizeChanged = false;

            if (this.height > height) {
                newHeight = height;
                widgetSizeChanged = true;
            }
            if (this.width > width) {
                newWidth = width;
                widgetSizeChanged = true;
            }
            if (widgetSizeChanged) {
                this.resize(newWidth, newHeight);
            }
        }
    }

    getContentSize(): WidgetLength {
        const contentHeight = this.height - 30 - 2; // title bar height = 30, paddings = 2
        const contentWidth = this.width;
        return { height: contentHeight, width: contentWidth };
    }

    getExtraPreferences(): AppWidgetState | null | undefined {
        return this.extraParameters ? this.extraParameters.preferences : null;
    }

    getExtraReload(): boolean | null | undefined {
        return this.extraParameters ? this.extraParameters.reload : null;
    }

    setExtraPreferences(preferences: AppWidgetState): void {
        if (!this.extraParameters) {
            this.extraParameters = { preferences: undefined, reload: undefined };
        }
        this.extraParameters.preferences = preferences;
    }

    setExtraReload(reload: boolean): void {
        if (!this.extraParameters) {
            this.extraParameters = { preferences: undefined, reload: undefined };
        }
        this.extraParameters.reload = reload;
    }

    private getViewPort(): WidgetLength {
        return { width: window.innerWidth, height: window.innerHeight };
    }

    private roundTo2DecimalFloor(num: number): number {
        const rd = Math.floor(num * 100) / 100;
        return rd === 0 ? 0.01 : rd;
    }

    private setMenuVisibility(menuOptionSelectors: string[], hide: boolean): void {
        const menuOptions = this.getMenuOptions();
        menuOptionSelectors.forEach((selector) => {
            const menuOption = menuOptions.find((option) => option.selector === selector);
            if (menuOption) {
                menuOption.hidden = hide;
            }
        });
    }
}

export interface MenuOptionConfig {
    iconName?: string;
    selector: string;
    styleClasses?: string;
    title: string;
    hidden?: boolean;
    disabled?: boolean;
    callBack?(selector: string): void;
}

export type WidgetLifeCycleCallBack = (eventName: string, data?: unknown) => void;

export enum WidgetBehaviour {
    DRAG,
    RESIZE,
    CASCADE,
    HEADER_DOUBLE_CLICK
}

export enum WidgetAction {
    MAXIMIZE,
    RESTORE,
    CLOSE,
    RESIZE,
    MOVE,
    SHOW_COMPARE_DATEPICKER
}

export interface WidgetActionDetail {
    widgetId: number;
    action: WidgetAction;
    extraParams?: WidgetActionDetailExtraParameters;
}

export interface WidgetActionDetailExtraParameters {
    top?: number;
    left?: number;
    height?: number;
    width?: number;
}

export interface WidgetLength {
    width: number;
    height: number;
}

export interface InterWidgetCommunicationModel {
    widget: Widget;
    initiatorWidgetId: number;
    data: unknown;
}
