<div class="sidenav" [ngClass]="{ 'collapsed': !dashboardNavComponent?.isSideNavExpanded }">
    <app-dashboard-nav #navComponent (revertChanges)="onRevertChanges()"></app-dashboard-nav>
</div>

<div
    id="layout-wrapper"
    class="layout-wrapper"
    [ngClass]="{ 'collapsed': !dashboardNavComponent?.isSideNavExpanded, 'marginleft': dashboardNavComponent?.isSideNavExpanded }"
>
    <app-hs-dashboard
        #hsDashboard
        [currentDashboard]="currentDashboard"
        [inPresentationMode]="inPresentationMode"
        [dashboardGroups]="dashboardGroups"
        [dashboardSnapshots]="dashboardSnapshots"
        [currentActiveDate]="currentActiveDate"
        (cancelEdit)="onCancelEdit()"
        (revertChanges)="onRevertChanges()"
        (saveDashboardWidgets)="onSaveWidgetChangesToServer($event)"
        (dashboardSwitch)="onDashboardSelectedInLeftNav($event)"
    >
    </app-hs-dashboard>

    <app-filters-bar
        [isDashboardMode]="true"
        [dashboardId]="currentDashboardId"
        [inPresentationMode]="inPresentationMode"
    >
    </app-filters-bar>

    <div #dummyDashboardDiv id="dashboardDummyDiv" class="dummy-dashboard-margin">
        <div class="margin-container"></div>
    </div>

    <div class="dashboard-container" #dashboardContainer>
        <div class="margin-container">
            <canvas id="grid-canvas" #gridcanvas></canvas>
            <app-dashboard #dashboard [isGlobal]="isGlobal" [isManagingWidget]="false"></app-dashboard>
        </div>

        <div class="loader-parent" *ngIf="isDashboardLoading">
            <span class="loader"></span>
        </div>
    </div>

    <app-socket data-html2canvas-ignore="true"></app-socket>
</div>

<ng-template #saveGlobalTemplate>
    <div class="save-global-message">{{ saveGlobalMessage }}</div>
    <div>{{ saveGlobalNote }}</div>
</ng-template>
