import { Injectable } from '@angular/core';
import { DashboardPreference, WidgetFilterParams, FilterPreference } from '@ddv/models';
import { deepClone } from '@ddv/utils';
import { BehaviorSubject, Observable } from 'rxjs';

const defaultWidgetQueryParams: WidgetFilterParams = {
    widgetFilters: new Map(),
};

@Injectable()
export class QueryParamsService {
    public readonly dashboardQueryParams: Observable<DashboardPreference>;
    public readonly widgetQueryParams: Observable<WidgetFilterParams | undefined>;

    private readonly dashboardQueryParamsSubject: BehaviorSubject<DashboardPreference> = new BehaviorSubject<DashboardPreference>({
        funds: [],
        clients: [],
        filters: [],
    });
    private readonly widgetQueryParamsSubject: BehaviorSubject<WidgetFilterParams | undefined> =
        new BehaviorSubject<WidgetFilterParams | undefined>(defaultWidgetQueryParams);

    constructor() {
        this.dashboardQueryParams = this.dashboardQueryParamsSubject.asObservable();
        this.widgetQueryParams = this.widgetQueryParamsSubject.asObservable();
    }

    dispatchNewQueryParams(queryParams: DashboardPreference): void {
        const valueToEmit: DashboardPreference = deepClone({ ...queryParams });
        this.dashboardQueryParamsSubject.next(valueToEmit);
    }

    dispatchUpdatedQueryParams(deltas: DashboardPreference): void {
        const currentValue: DashboardPreference = this.dashboardQueryParamsSubject.getValue();
        const newValue: DashboardPreference = deepClone({ ...currentValue, ...deltas });
        this.dashboardQueryParamsSubject.next(newValue);
    }

    removeFilters(): void {
        this.dispatchUpdatedQueryParams({ filters: [] });
    }

    addWidgetQueryParam(widgetId: number, widgetFilters?: FilterPreference): void {
        if (!widgetFilters) {
            return;
        }

        const currentValue: WidgetFilterParams = this.widgetQueryParamsSubject.getValue() ?? defaultWidgetQueryParams;
        currentValue.lastChangedWidgetId = widgetId;
        currentValue.widgetFilters.set(widgetId, widgetFilters);

        this.widgetQueryParamsSubject.next({ ...currentValue });
    }

    removeWidgetQueryParam(widgetId: number): void {
        const currentValue: WidgetFilterParams | undefined = this.widgetQueryParamsSubject.getValue();
        if (!currentValue) {
            return;
        }

        currentValue.widgetFilters.delete(widgetId);
        this.widgetQueryParamsSubject.next(undefined);
    }

    removeAllMasterWidgetFilters(): void {
        const filters = this.dashboardQueryParamsSubject
            .getValue().filters?.filter((dashboardFilter) => !dashboardFilter.isMasterWidgetFilter);

        this.dispatchUpdatedQueryParams({ filters });
    }

    toggleMasterFilter(columnId: string, columnDisplayName: string, value: string, selectedValues: string[]): void {
        const queryParams: DashboardPreference = this.dashboardQueryParamsSubject.getValue();

        const currentFilter = queryParams.filters?.find((filter) => filter.name === columnId);
        if (!currentFilter) {
            if (selectedValues.includes(value)) {
                queryParams.filters = queryParams.filters ?? [];
                queryParams.filters.push({
                    name: columnId,
                    displayName: columnDisplayName,
                    criteria: 'INCLUDE',
                    isMasterWidgetFilter: true,
                    values: [value],
                });
            }
        } else {
            currentFilter.values = selectedValues;
            if (!currentFilter.values.length) {
                queryParams.filters = queryParams.filters?.filter((filter) => filter !== currentFilter);
            }
        }

        this.dispatchUpdatedQueryParams({
            filters: queryParams.filters,
            highlight: value,
        });
    }
}
