import { ColorChartComponent } from './color-chart/color-chart.component';
import { ConfigureChartsComponent } from './configure-charts/configure-charts.component';
import { ConfigureGridComponent } from './configure-grid/configure-grid.component';
import { ConfigureSummaryComponent } from './configure-summary/configure-summary.component';
import { FormatBarComponent } from './format-bar/format-bar.component';
import { FormatStackedAreaChartComponent } from './format-stacked-area-chart/format-stacked-area-chart.component';
import { FormatSummaryComponent } from './format-summary/format-summary.component';
import { FormatVizComponent } from './format-viz/format-viz.component';

type ConfigureTabComponentType =
    typeof ConfigureGridComponent |
    typeof ConfigureSummaryComponent |
    typeof ConfigureChartsComponent;

type FormatTabComponentType =
    typeof FormatVizComponent |
    typeof FormatSummaryComponent |
    typeof FormatBarComponent |
    typeof FormatStackedAreaChartComponent;

/* eslint-disable @typescript-eslint/no-extraneous-class */
export class VizTabsConfiguration {
    static getConfigureTabComponent(vizType: string): ConfigureTabComponentType {
        switch (vizType) {
            case 'SIMPLE_GRID':
            case 'ADVANCED_GRID':
                return ConfigureGridComponent;
            case 'SUMMARY':
                return ConfigureSummaryComponent;
            default:
                return ConfigureChartsComponent;
        }
    }

    static getFormatTabComponent(vizType: string): FormatTabComponentType {
        switch (vizType) {
            case 'VERTICAL_BAR_CHART':
            case 'HORIZONTAL_BAR_CHART':
            case 'VERTICAL_STACKED_BAR_CHART':
            case 'HORIZONTAL_STACKED_BAR_CHART':
            case 'VERTICAL_MIRRORED_BAR_CHART':
                return FormatBarComponent;
            case 'STACKED_AREA_CHART':
                return FormatStackedAreaChartComponent;
            case 'SUMMARY':
                return FormatSummaryComponent;
            default:
                return FormatVizComponent;
        }
    }

    static getColorTabComponent(): typeof ColorChartComponent {
        return ColorChartComponent;
    }
}
