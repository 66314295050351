import { Formatters } from './formatters';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DefaultWidgetPreferences {
    static getSummaryPreferences(): Formatters[] {
        return [
            {
                label: 'Line Separator',
                type: 'toggle',
                name: 'summary-enable-separator',
                id: 'isLineSeparatorEnabled',
                selectedValue: true,
            },
            {
                label: 'Text Size',
                type: 'select',
                id: 'textSize',
                cssClass: 'text-size',
                name: 'summary-select-textsize',
                values: [
                    {
                        label: 'Small',
                        value: 16,
                    },
                    {
                        label: 'Medium',
                        value: 28,
                    },
                    {
                        label: 'Large',
                        value: 36,
                    },
                ],
                selectedValue: 28,
            },
            {
                label: 'Display',
                type: 'select',
                id: 'display',
                cssClass: 'display',
                name: 'summary-select-display',
                values: [
                    {
                        label: 'Horizontal',
                        value: 'horizontal',
                    },
                    {
                        label: 'Vertical',
                        value: 'vertical',
                    },
                ],
                selectedValue: 'horizontal',
            },
            {
                label: 'Alignment',
                type: 'select',
                id: 'textAlignment',
                cssClass: 'alignment',
                name: 'summary-select-alignment',
                values: [
                    {
                        label: 'Left',
                        value: 'left',
                    },
                    {
                        label: 'Right',
                        value: 'right',
                    },
                    {
                        label: 'Center',
                        value: 'center',
                    },
                ],
                selectedValue: 'left',
            },
        ];
    }
}
