<div
    id="workspace-container"
    class="workspace"
    [ngStyle]="styleAttributes"
    appWidgetDragTarget
    (dragOver)="onDragOver($event)"
>
    @for (widget of widgets; track $index) {
        <app-widget-on-board
            [widgetConfig]="widget"
            [mode]="mode"
            [dashboardId]="dashboardId"
            [isWorkspaceGlobal]="isGlobal"
            [isManagingWidget]="isManagingWidget"
            [useNewLegend]="useNewLegend"
            (unselectAllWidgets)="unselectAllWidgets()"
            (workspaceStateChange)="onWorkspaceStateChange($event)"
        >
        </app-widget-on-board>
    }
</div>

@if (showSlowDataApproval) {
    <ddv-slow-data-approval
        [clientCode]="clientCode"
        [selectedDate]="dateToCheckSlowness"
        [fuzzyDates]="fuzzyDates"
        (approved)="onSlowDataResponse()"
    >
    </ddv-slow-data-approval>
}
