import { DashboardSnapshot } from '@ddv/models';

export class DashboardGroup {
    id = 0;
    name = '';
    sortOrder = -1;
    dashboards: DashboardSnapshot[] = [];
    lastOpened?: string;
    isDefault?: boolean;

    public constructor(init?: Partial<DashboardGroup>) {
        Object.assign(this, init);

        this.dashboards = (this.dashboards || []).map((dashboard) => new DashboardSnapshot(dashboard));
    }
}
