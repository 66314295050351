<div
        *ngIf="(hiDates | async) ?? [] | filterDates:selectedDate; let dates"
        class="hidates-wrapper"
        [ngClass]="isDateRangePicker ? 'hidates-wrapper-range-fixed-width' : 'hidates-wrapper-single-fixed-width'">
    <div *ngIf="dates && dates.length" class="hidates-list">
        <div
                *ngFor="let d of dates"
                [ngClass]="{'selected': isSelected(d.date)}"
                (click)="selectHiDate(d)">

            <div class="hidate">{{ d.date.toDate() | date:"MMM d y" }}</div>

            <div
                    class="hidate-status"
                    [ngClass]="{'all-posted': d.status === hiDateStatus.ALL,
                    'partial-posted': d.status === hiDateStatus.PARTIAL,
                    'none-posted': d.status === hiDateStatus.NONE}">

                <span class="icon-selected" *ngIf="d.status === hiDateStatus.ALL">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 20 20"
                         xml:space="preserve">
                        <circle cx="7.901" cy="8" r="6.302"/>
                        <polyline stroke-width="1.5" stroke-miterlimit="10" points="4.429,8.682 7.317,11.165 10.491,3.432 "/>
                    </svg>
                </span>

                {{ d.status === hiDateStatus.ALL ? "ALL POSTED" :
                   d.status === hiDateStatus.PARTIAL ? "PARTIAL POSTED (" + d.numPosted + " of " + d.numTotal + ")" :
                   d.status === hiDateStatus.NONE ? "NONE POSTED" : ""   }}
            </div>
        </div>
    </div>

    <div *ngIf="!dates.length" class="no-hidates">
        No Hedge Investor Dates Available
    </div>
</div>
