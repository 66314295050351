import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { ExportableDataWithCrosstalk, ExportableData } from '../models/exportable-data-with-crosstalk';

@Injectable()
export class FilteredDataService {
    public readonly filteredData$: Observable<Record<string, ExportableDataWithCrosstalk>>;
    public readonly fullData$: Observable<Record<string, ExportableData>>;
    private readonly filteredData: Subject<Record<string, ExportableDataWithCrosstalk>> = new ReplaySubject(1);
    private readonly fullData: Subject<Record<string, ExportableData>> = new ReplaySubject(1);

    constructor() {
        this.filteredData$ = this.filteredData.asObservable();
        this.fullData$ = this.fullData.asObservable();
        this.filteredData.next({});
        this.fullData.next({});
    }

    updateFilteredData(newData: Record<string, ExportableDataWithCrosstalk>): void {
        this.filteredData
            .pipe(first())
            .subscribe({
                next: (existingData) => this.filteredData.next({ ...existingData, ...newData }),
            });
    }

    updateFullData(newData: Record<string, ExportableData>): void {
        this.fullData
            .pipe(first())
            .subscribe({
                next: (existingData) => this.fullData.next({ ...existingData, ...newData }),
            });
    }

    clearFilteredData(newData: Record<string, ExportableDataWithCrosstalk> = {}): void {
        this.filteredData.next({ ...newData });
    }

    clearFullData(newData: Record<string, ExportableDataWithCrosstalk> = {}): void {
        this.fullData.next({ ...newData });
    }
}
