import { WidgetSnapshot } from './widget-snapshot';

export interface GroupedWidgets {
    groupName: string;
    widgets: WidgetSnapshot[];
    totalWidgets: number;
    collapsed?: boolean;
}

export function groupWidgetsByCriteria(widgets: WidgetSnapshot[], criteria: string): { [key: string]: WidgetSnapshot[] } {
    const widgetsMap: { [key: string]: WidgetSnapshot[] } = {};
    let key: string;

    for (const widget of widgets) {
        switch (criteria) {
            case 'createdDate':
                key = new Date(widget.createdDate ?? '').toLocaleString('default', { month: 'short', year: 'numeric' });
                break;
            case 'visualizations':
                key = widget.visualizations[0];
                break;
            default:
                // this cast is dangerous but maybe this is only called with properties that actually are strings
                key = widget[criteria as keyof WidgetSnapshot] as string;
        }

        if (Object.prototype.hasOwnProperty.call(widgetsMap, key)) {
            widgetsMap[key] = [...widgetsMap[key], widget];
        } else {
            widgetsMap[key] = [widget];
        }
    }

    return widgetsMap;
}
