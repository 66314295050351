import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DropdownOption } from '@ddv/common-components';
import { FeatureFlagService } from '@ddv/entitlements';
import { getNQSApiHost } from '@ddv/http';
import { DatasetDefinition, NamedQuery } from '@ddv/models';
import { isNamedQuery, NamedQueriesService } from '@ddv/named-queries';
import { combineLatest, of, Subscription, switchMap } from 'rxjs';

import { DatasetDefinitionsService } from '../../services/dataset-definitions.service';

function sortByName(a: { name: string }, b: { name: string }): number {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

@Component({
    selector: 'app-dsd-selector',
    templateUrl: './dsd-selector.component.html',
    styleUrls: ['./dsd-selector.component.scss'],
})
export class DatasetDefinitionSelectorComponent implements OnInit, OnChanges, OnDestroy {
    @Input() datasetDefinition: DatasetDefinition | NamedQuery | undefined;
    @Input() showJumpLink = false;
    @Input() jumpedFrom: string | undefined;
    @Input() isReadOnly = false;
    @Input() enabled = true;

    @Output() datasetDefinitionSelected = new EventEmitter<DatasetDefinition | NamedQuery>();
    @Output() namedQuerySelected = new EventEmitter<NamedQuery>();

    queryTypeList: DropdownOption[] = [{ text: 'ALL', key: 0, value: 0 }];
    queryType: DropdownOption = { text: 'ALL', key: 0, value: 0 };
    dataSource: (DatasetDefinition | NamedQuery)[] = [];
    filteredDataSource: (DatasetDefinition | NamedQuery)[] = [];
    routerLink: string | string[] = ['../../../dataset-definitions/edit/none'];

    protected isNamedQuerySelected = false;
    protected currentClient = '';
    protected preSelectedItem: DatasetDefinition | NamedQuery | undefined;

    private clientCodeSubscription: Subscription | undefined;
    private priorDatasetDefinition: DatasetDefinition | NamedQuery | undefined;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly featureFlagsService: FeatureFlagService,
        private readonly namedQueriesService: NamedQueriesService,
        private readonly currentStateService: CurrentStateService,
    ) {}

    ngOnInit(): void {
        if (this.enabled) {
            this.fetchDatasets();
        }

        this.clientCodeSubscription = this.currentStateService.clientCode$.subscribe((currentClient) => this.currentClient = currentClient);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.datasetDefinition) {
            if (typeof this.datasetDefinition?.id === 'string') {
                this.isNamedQuerySelected = true;
                this.populateDataFromNQS(this.datasetDefinition.id);
            }

            this.priorDatasetDefinition = this.datasetDefinition;
            this.preSelectedItem = changes.datasetDefinition.currentValue;

            const queryType = changes.datasetDefinition.currentValue?.type ?? changes.datasetDefinition.currentValue?.queryType?.name;
            this.selectQueryTypeFromDefinition(queryType ?? 'ALL');

            if (!changes.datasetDefinition.currentValue && changes.datasetDefinition.previousValue) {
                this.fetchDatasets();
            }
        }
    }

    resetSelector(): void {
        this.isNamedQuerySelected = false;
        this.selectQueryTypeFromDefinition('ALL');
        this.filteredDataSource = [...this.dataSource];
    }

    restorePriorSelection(): void {
        this.selectQueryTypeFromDefinition(getQueryTypeName(this.priorDatasetDefinition));
        this.preSelectedItem = undefined;
        this.cdr.detectChanges();
        this.preSelectedItem = this.priorDatasetDefinition;
    }

    onQueryTypeChanged(): void {
        if (this.queryType.text === 'ALL') {
            this.filteredDataSource = [...this.dataSource];
        } else {
            this.filteredDataSource = this.dataSource.filter((dsd) =>
                (isNamedQuery(dsd) ? dsd.type.name === this.queryType.text : dsd.queryType!.name === this.queryType.text),
            );
        }
    }

    onDatasetDefinitionSelected(item: DatasetDefinition | NamedQuery): void {
        this.selectQueryTypeFromDefinition(getQueryTypeName(item));
        this.isNamedQuerySelected = isNamedQuery(item);
        this.datasetDefinitionSelected.emit(item);
        this.buildRouterLink(item.id ?? 'none');
    }

    ngOnDestroy(): void {
        this.clientCodeSubscription?.unsubscribe();
    }

    private fetchDatasets(): void {
        this.featureFlagsService.isFlagEnabled('ddv-use-nqs')
            .pipe(
                switchMap((enabled) => {
                    if (enabled) {
                        return combineLatest([
                            this.datasetDefinitionsService.fetchDatasets('ALL', ''),
                            this.namedQueriesService.fetchNamedQueries(),
                        ]);
                    }

                    return combineLatest([this.datasetDefinitionsService.fetchDatasets('ALL', ''), of([])]);
                }),
            )
            .subscribe(([data, namedQueries]) => {
                namedQueries.forEach((namedQuery) => {
                    if (namedQuery.datasetDefinitionId) {
                        namedQuery.name = `${namedQuery.name} [${namedQuery.datasetDefinitionId}]`;
                    }
                });
                const datasets = [...data, ...namedQueries];
                this.dataSource = datasets.sort(sortByName);
                this.initializeQueryTypeList();
            });
    }

    private initializeQueryTypeList(): void {
        const queryTypeNames = new Set<string>();
        this.dataSource.forEach((dsd) => queryTypeNames.add(isNamedQuery(dsd) ? dsd.type.name : dsd.queryType!.name));
        this.filteredDataSource = this.dataSource;
        this.queryTypeList = [
            ...this.queryTypeList,
            ...([...queryTypeNames].sort()).map((name, index) => ({ text: name, key: index + 1, value: index + 1 })),
        ];

        if (this.datasetDefinition) {
            this.selectQueryTypeFromDefinition(getQueryTypeName(this.priorDatasetDefinition));
        }
    }

    private selectQueryTypeFromDefinition(queryType: string): void {
        this.queryType = this.queryTypeList.find((qt) => qt.text === queryType) ?? { text: 'ALL', key: 0, value: 0 };
    }

    private buildRouterLink(id: string | number): void {
        if (!this.isNamedQuerySelected) {
            this.routerLink = [`../../../dataset-definitions/edit/${id}`];
        } else {
            const app = `https://${getNQSApiHost()}/#`;
            this.routerLink = `${app}/${this.currentClient}/queries/${id}`;
        }
    }

    private populateDataFromNQS(id: string): void {
        this.namedQueriesService.fetchNamedQuery(id)
            .subscribe((namedQuery) => {
                this.datasetDefinition!.name = namedQuery.name;
                this.selectQueryTypeFromDefinition(namedQuery.type.name);
                this.buildRouterLink(namedQuery.id ?? 'new');
            });
    }
}

function getQueryTypeName(datasetDefinition: NamedQuery | DatasetDefinition | undefined): string {
    const queryName = isNamedQuery(datasetDefinition) ?
        datasetDefinition.type.name :
        datasetDefinition?.queryType?.name;

    return queryName ?? 'ALL';
}
