import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, Injector, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DashboardFiltersService } from '@ddv/dashboards';
import { Datasource, WidgetDataSourceService } from '@ddv/datasets';
import { UserEntitlementService } from '@ddv/entitlements';
import {
    DataRange,
    MANAGE_WIDGET_ID,
    AgGridSortState,
    ConfigItem,
    AUTO_GROUP_COLUMN_ID,
    ValueFilterOption,
    VizFormat,
    VisualizationPreferences,
    VizConfigs,
    WidgetData,
    VisualizationType,
} from '@ddv/models';
import { deepCompare, getValidString } from '@ddv/utils';
import { SelectedWidgetRelayService, getDefaultVisualizationPreferences } from '@ddv/visualizations';
import { Subscription } from 'rxjs';

import {
    BarAxisID,
    BarXAxisSettings,
    BarYAxisSettings,
    LineAndStackedAreaAxisID,
    LineRightYAxisSettings,
    LineBottomXAxisSettings,
    StackedAreaBottomXAxisSettings,
    LineLeftYAxisSettings,
    StackedAreaLeftYAxisSettings,
    AxisSettings,
} from '../../../models/chart.constants';
import { ConfigValue } from '../../../models/config.value';
import { FormatComponent } from '../format.component';

@Component({
    selector: 'app-mw-format-viz',
    templateUrl: './format-viz.component.html',
    styleUrls: ['./format-viz.component.scss'],
})
export class FormatVizComponent extends FormatComponent implements OnInit, AfterViewInit {
    @ViewChild('formatParentContainer', { static: false, read: ElementRef }) private readonly formatParentContainer: ElementRef | undefined;

    vizType: string | undefined;
    valuesCount: number | undefined;
    axisIds: string[] | undefined;
    dataConfig: VizConfigs | undefined;
    widgetDataSourceObserver: Subscription | undefined;

    customSortAllowedVisualizations = [
        'PIE_CHART',
        'DONUT_CHART',
        'VERTICAL_BAR_CHART',
        'HORIZONTAL_BAR_CHART',
        'VERTICAL_STACKED_BAR_CHART',
        'HORIZONTAL_STACKED_BAR_CHART',
    ];

    private resizeObserver!: ResizeObserver;

    constructor(
        private readonly injector: Injector,
        private readonly dashboardFiltersService: DashboardFiltersService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef,
        userEntitlementService: UserEntitlementService,
        selectedWidgetRelayService: SelectedWidgetRelayService,
    ) {
        super(userEntitlementService, selectedWidgetRelayService);
        this.customSortOption = '';
        this.initialize();
    }

    ngOnInit(): void {
        this.resizeObserver = new ResizeObserver((entries) => {
            if (entries[0].contentRect.width) {
                this.setTogglePosition();
            }
        });
    }

    ngAfterViewInit(): void {
        this.resizeObserver.observe(this.formatParentContainer?.nativeElement);
    }

    initialize(): void {
        const appliedFormatters = this.injector.get('appliedFormatters');
        this.vizType = appliedFormatters.visualizationType;
        if (!this.vizType) {
            return console.error('cannot initialize without a vizType');
        }

        this.dataConfig = appliedFormatters.configs;

        if (appliedFormatters.id) {
            this.formatters = this.getFormattedVizConfigs(appliedFormatters);
        } else {
            this.formatters = getDefaultVisualizationPreferences(this.vizType);
        }

        if (this.formatters.find((formatter) => formatter.id === 'barYAxis')?.selectedValue && this.vizType === 'HORIZONTAL_BAR_CHART') {
            this.setLegendConfig(this.formatters, true);
        }

        if (this.visualizationAllowsCustomSort() && this.dataConfig) {
            if (this.dataConfig.slicers?.length) {
                this.dataConfig.slicers.forEach((slicer) => {
                    if (slicer.configCustomSortOrder) {
                        if (typeof slicer.configCustomSortOrder === 'string') {
                            slicer.configCustomSortOrder = JSON.parse(getValidString(slicer.configCustomSortOrder));
                        }
                        this.onFilterAttributeListChanged(slicer.value, slicer.configCustomSortOrder as ValueFilterOption[]);
                    }
                });
                this.selectedSlicerValue = this.getSelectedSlicerValue(this.dataConfig);
            }
        }
    }

    override addCustomSortOption(): void {
        this.customSortOption = this.customSortOption?.trim();

        if (this.customSortOption) {
            const customSortOrder = this.filterAttributeList.get(this.selectedSlicerValue ?? '');
            if (!customSortOrder?.find((listItem) => listItem.value === this.customSortOption)) {
                const customSortItem: ValueFilterOption = {
                    text: this.customSortOption,
                    value: this.customSortOption,
                    isCustom: true,
                };
                customSortOrder?.push(customSortItem);
                this.onFilterAttributeListChanged(this.selectedSlicerValue, customSortOrder);
                this.customSortOption = '';
            }
        }
    }

    override dropItem(event: CdkDragDrop<string[]>, itemsList?: ValueFilterOption[]): void {
        moveItemInArray(itemsList ?? [], event.previousIndex, event.currentIndex);
        this.renderPreview();
    }

    visualizationAllowsCustomSort(): boolean {
        return this.customSortAllowedVisualizations.includes(this.vizType ?? '');
    }

    setupCustomSort(dataConfig: VizConfigs): void {
        this.toggleCustomSortOption();
        if (dataConfig?.slicers?.length) {
            if ((this.selectedSlicerValue && !this.getSelectedSlicer(dataConfig.slicers)) || !this.selectedSlicerValue) {
                this.selectedSlicerValue = this.getSelectedSlicerValue(dataConfig);
            }
            const slicerConfig = this.findConfigById(this.formatters as VisualizationPreferences[], 'sortOrderSlicer');
            const tableSortDirection = this.findConfigById(this.formatters as VisualizationPreferences[], 'tableSortDirection')?.selectedValue;
            if (slicerConfig) {
                if (tableSortDirection === 'CUSTOM') {
                    this.addSlicers(slicerConfig, dataConfig);
                    this.loadFilterAttributeList(dataConfig);
                }
                slicerConfig.hidden = tableSortDirection !== 'CUSTOM' || !slicerConfig.values?.length;
            }
        } else {
            this.selectedSlicerValue = undefined;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override getSelectedConfigs(dataConfig: VizConfigs): any {
        if (this.visualizationAllowsCustomSort()) {
            this.setupCustomSort(dataConfig);
        }
        const sliceManagementFormatter = this.formatters.find((f) => f.id === 'enableSliceManagement');
        if (sliceManagementFormatter) {
            super.setSliceManagement(sliceManagementFormatter);
        }
        this.valuesCount = dataConfig.values.length;
        const appliedFormatters = super.getSelectedConfigs(dataConfig);
        /** Line chart needs extra formatting as BE takes
         * all axis configuration as a single array - All these will be moved to line chart format component */
        if (this.vizType === 'LINE_CHART') {
            this.formatLineConfigs(appliedFormatters);
        }
        return appliedFormatters;
    }

    updateGridPivotMode(isPivotMode: boolean): void {
        const f = this.formatters.find((formatter) => formatter.id === 'isPivotMode');
        if (f) {
            f.selectedValue = isPivotMode;
        }
    }

    updateGroupSort(sortState: AgGridSortState[]): void {
        const groupSort = sortState.find((sort) => sort.colId === AUTO_GROUP_COLUMN_ID);
        const groupSortFormatter = this.formatters.find((formatter) => formatter.id === 'groupSort');
        if (groupSort && groupSortFormatter) {
            groupSortFormatter.selectedValue = groupSort.sort;
        } else if (groupSortFormatter) {
            groupSortFormatter.selectedValue = null;
        }
        const groupSortFormatterIndex = this.formatters.find((formatter) => formatter.id === 'groupSortIndex');
        if (groupSort && groupSortFormatterIndex) {
            groupSortFormatterIndex.selectedValue = groupSort.sortIndex;
        } else if (groupSortFormatterIndex) {
            groupSortFormatterIndex.selectedValue = null;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    override onMultiSelectChanged(list: any[], config: any): void {
        if (this.vizType === 'LINE_CHART' || this.vizType === 'STACKED_AREA_CHART') {
            config.selectedValue = list.map((data) => ({ label: data.label, value: data.value }));
        }
        this.renderPreview();
    }

    override checkCustomValue(id: string): void {
        const dataRangeFormatter = this.getDataRangeFormatter(id);
        if (dataRangeFormatter) {
            dataRangeFormatter.hasError = this.getDataRangeError(dataRangeFormatter);
        }
        this.renderPreview();
    }

    override onFocusOut(context: { selectedValue: string }): void {
        if (context.selectedValue === '') {
            context.selectedValue = '0';
            this.renderPreview();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override renderPreview(event?: { target: any }, configId?: string): void {
        if (configId === 'sortOrderSlicer' && event) {
            this.selectedSlicerValue = event.target.value;
        }
        this.formatters.forEach((config) => this.getMergedConfigs(config as VisualizationPreferences, {}));
        if (this.hasValidFormatters()) {
            super.renderPreview();
        }
    }

    protected setLegendConfig(formatters: VizFormat[], axisState: boolean): void {
        const legendFormatter = formatters.find((formatter) => formatter.id === 'enableLegend');
        if (!legendFormatter) {
            return;
        }

        const config = legendFormatter.configs![0];
        if (axisState) {
            if (legendFormatter.selectedValue && config.selectedValue === 'right') {
                legendFormatter.selectedValue = false;
                config.selectedValue = 'top';
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const topIndex = config.values?.findIndex((value: any) => value.value === 'right') ?? -1;
            if (topIndex !== -1) {
                config.values?.splice(topIndex, 1);
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!config.values?.find((value: any) => value.value === 'right')) {
                config.values?.unshift({ label: 'Right', value: 'right' });
            }
        }
        this.formatters = formatters;
    }

    private findConfigById(formatters: VisualizationPreferences[], id: string): VisualizationPreferences | undefined {
        const f = formatters.find((formatter) => formatter.label === 'Chart Sorting');
        return f?.configs?.find((config) => config.id === id);
    }

    private toggleCustomSortOption(): void {
        const sortBySelectedValue = this.findConfigById(this.formatters as VisualizationPreferences[], 'sortTableBy')?.selectedValue;
        const chartSortingConfig = this.findConfigById(this.formatters as VisualizationPreferences[], 'tableSortDirection');
        if (!chartSortingConfig?.values) {
            return console.error('cannot toggleCustomSortOption without a chartSortingConfig');
        }

        const chartSortingConfigValues = chartSortingConfig?.values;
        const customOption = { label: 'Custom', value: 'CUSTOM' };
        const customOptionIndex = chartSortingConfigValues?.findIndex((value) => value.label === 'Custom');
        if (sortBySelectedValue === 'SLICER' && customOptionIndex === -1) {
            chartSortingConfigValues?.push(customOption);
            this.dropdownOptions[chartSortingConfig?.id ?? ''].push({
                text: customOption.label,
                key: customOption.value,
                value: customOption.value,
            });

            if (chartSortingConfig?.selectedValue) {
                this.selectedSlicerValue = chartSortingConfigValues?.find((v) => v.value === chartSortingConfig.selectedValue)?.toString();
                this.dropdownSelectedValues[chartSortingConfig.id] =
                    this.dropdownOptions[chartSortingConfig.id].find((v) => v.value === chartSortingConfig.selectedValue);
            }
        } else if (sortBySelectedValue !== 'SLICER' && customOptionIndex !== -1) {
            chartSortingConfigValues?.splice(customOptionIndex, 1);
            chartSortingConfig.selectedValue = this.dropdownOptions[chartSortingConfig.id][0].value;
            this.dropdownOptions[chartSortingConfig.id].splice(customOptionIndex, 1);
            this.selectedSlicerValue = this.dropdownOptions[chartSortingConfig.id][0].value;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.dropdownOptions[chartSortingConfig.id] as any).selectedValue = this.dropdownOptions[chartSortingConfig.id][0].value;
            this.dropdownSelectedValues[chartSortingConfig.id] = this.dropdownOptions[chartSortingConfig.id][0];
        }
    }

    private addSlicers(config: VisualizationPreferences, dataConfig: VizConfigs): void {
        config.values = dataConfig.slicers.map((slicer) => ({
            label: slicer.label ?? '',
            value: slicer.value!,
        }));
        this.dropdownOptions[config.id] = dataConfig.slicers.map((slicer) => ({
            text: slicer.label ?? '',
            key: slicer.value,
            value: slicer.value,
        }));
        config.selectedValue = this.selectedSlicerValue ?? config.values[0].value;
        this.dropdownSelectedValues[config.id] =
            this.dropdownOptions[config.id].find((o) => o.key === this.selectedSlicerValue) ?? config.values[0];
    }

    private changeSlicerValues(
        selectedSlicer: ConfigItem,
        fetchedSlicerValues: ValueFilterOption[],
        selectedSlicerCustomSortOrder: ValueFilterOption[],
    ): void {
        let newCustomSortOrder: ValueFilterOption[] = [];

        if (fetchedSlicerValues.length === 0) {
            newCustomSortOrder = selectedSlicerCustomSortOrder;
        } else {
            selectedSlicerCustomSortOrder.forEach((currentListItem) => {
                const index = fetchedSlicerValues.findIndex((item) => item.value === currentListItem.value);
                if (index !== -1) {
                    newCustomSortOrder.push(currentListItem);
                    fetchedSlicerValues.splice(index, 1);
                } else if (currentListItem.isCustom) {
                    newCustomSortOrder.push(currentListItem);
                }
            });

            newCustomSortOrder = [...newCustomSortOrder, ...fetchedSlicerValues];
        }

        if (!deepCompare(selectedSlicerCustomSortOrder, newCustomSortOrder)) {
            this.onFilterAttributeListChanged(this.selectedSlicerValue, newCustomSortOrder);
        }

        selectedSlicer.configCustomSortOrder = newCustomSortOrder;
    }

    private getSelectedSlicerValue(dataConfig: VizConfigs): string | undefined {
        return dataConfig?.slicers?.length ? dataConfig.slicers[0].value : undefined;
    }

    private getSelectedSlicer(slicers: ConfigItem[]): ConfigItem | undefined {
        return slicers.find((slicer) => slicer.value === this.selectedSlicerValue);
    }

    private onFilterAttributeListChanged(slicer: string | undefined, list: string | ValueFilterOption[] | undefined): void {
        if (!slicer || !list) {
            return;
        }

        let updatedList = list;
        if (typeof updatedList === 'string') {
            updatedList = JSON.parse(getValidString(updatedList));
        }
        this.filterAttributeList.set(slicer, updatedList as ValueFilterOption[]);
        this.renderPreview();
    }

    private loadFilterAttributeList(dataConfig: VizConfigs): void {
        if (this.widgetDataSourceObserver) {
            this.widgetDataSourceObserver.unsubscribe();
        }

        this.widgetDataSourceObserver = this.widgetDataSourceService.dataSource$.subscribe({
            next: (response: Datasource) => {
                const slicerValues = response.datasources.reduce((acc, datasource) => {
                    return datasource.uniqueKey.sourceType === 'widget' && datasource.uniqueKey.sourceId === MANAGE_WIDGET_ID ? acc.concat(datasource.data ?? []) : acc;
                }, [] as WidgetData[]);
                const fetchedSlicerValues = this.dashboardFiltersService.getValueOptionsByAttribute(this.selectedSlicerValue, slicerValues);
                const selectedSlicerCustomSortOrder = this.filterAttributeList.get(this.selectedSlicerValue ?? '');
                const selectedSlicer = this.getSelectedSlicer(dataConfig.slicers);
                if (selectedSlicer) {
                    if (!selectedSlicerCustomSortOrder) {
                        this.onFilterAttributeListChanged(this.selectedSlicerValue, fetchedSlicerValues);
                        selectedSlicer.configCustomSortOrder = fetchedSlicerValues;
                    } else {
                        this.changeSlicerValues(selectedSlicer, fetchedSlicerValues, selectedSlicerCustomSortOrder);
                    }
                }
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private formatLineConfigs(configs: any): void {
        configs.rightYAxisDataRange = this.getDataRangeMode(configs.rightYAxisMinRangeToggle, configs.rightYAxisMaxRangeToggle);
        configs.leftYAxisDataRange = this.getDataRangeMode(configs.leftYAxisMinRangeToggle, configs.leftYAxisMaxRangeToggle);

        configs.axisConfigurations = [
            {
                axis: LineAndStackedAreaAxisID.X_AXIS,
                enableAxis: configs.bottomXAxis,
                displayAxisLabels: configs.bottomXAxisLabels,
                position: 'bottom',
                orientation: configs.bottomXAxisOrientation,
                dataRangeMode: DataRange.AUTO,
            },
            {
                axis: LineAndStackedAreaAxisID.Y_LEFT_AXIS,
                enableAxis: configs.leftYAxis,
                displayAxisLabels: configs.leftYAxisLabels,
                position: 'left',
                dataRangeMode: configs.leftYAxisDataRange,
                maxDataRange: this.getAxisRangeConfig(configs.leftYAxisDataRange, configs.leftYAxisRangeMax),
                minDataRange: this.getAxisRangeConfig(configs.leftYAxisDataRange, configs.leftYAxisRangeMin),
            },
            {
                axis: LineAndStackedAreaAxisID.Y_RIGHT_AXIS,
                enableAxis: configs.rightYAxis,
                displayAxisLabels: configs.rightYAxisLabels,
                position: 'right',
                dataRangeMode: this.valuesCount === 1 ? configs.leftYAxisDataRange : configs.rightYAxisDataRange,
                maxDataRange: this.valuesCount === 1 ?
                    this.getAxisRangeConfig(configs.leftYAxisDataRange, configs.leftYAxisRangeMax) :
                    this.getAxisRangeConfig(configs.rightYAxisDataRange, configs.rightYAxisRangeMax),
                minDataRange: this.valuesCount === 1 ?
                    this.getAxisRangeConfig(configs.leftYAxisDataRange, configs.leftYAxisRangeMin) :
                    this.getAxisRangeConfig(configs.rightYAxisDataRange, configs.rightYAxisRangeMin),
            },
        ];
    }

    private getDataRangeMode(minMode: boolean, maxMode: boolean): DataRange {
        if (minMode || maxMode) {
            return DataRange.MANUAL;
        }
        return DataRange.AUTO;
    }

    private getAxisRangeConfig(rangeMode: DataRange, value: string): string | undefined {
        if (rangeMode === DataRange.MANUAL) {
            return value === 'AUTO' ? undefined : value;
        }
        return undefined;
    }

    protected override getMergedConfigs(
        vizConfig: VisualizationPreferences,
        accumulator: { [key: string]: ConfigValue } = {},
        isAutoRange?: boolean,
    ): { [key: string]: ConfigValue } {
        if (vizConfig.id) {
            if (vizConfig.type === 'range') {
                this.handleRangeConfig(vizConfig, !!isAutoRange);
            }
            accumulator[vizConfig.id] = this.getParsedConfigValue(vizConfig.valueType!, vizConfig.selectedValue);
        }
        if (vizConfig.configs) {
            const isRangeAuto = this.isAutoRange(vizConfig.dataType!, vizConfig.selectedValue);
            vizConfig.configs.forEach((option) => this.getMergedConfigs(option, accumulator, isRangeAuto));
        }

        return accumulator;
    }

    private handleRangeConfig(vizConfig: VisualizationPreferences, isAutoRange: boolean): void {
        vizConfig.disabled = isAutoRange;

        if (isAutoRange) {
            vizConfig.selectedValue = vizConfig.selectedValue != null ? 'AUTO' : vizConfig.selectedValue;
        } else {
            vizConfig.selectedValue = vizConfig.selectedValue === 'AUTO' ? '0' : vizConfig.selectedValue;
        }

        const dataRangeFormatter = this.getDataRangeFormatter(vizConfig.id);
        if (dataRangeFormatter) {
            dataRangeFormatter.hasError = this.getDataRangeError(dataRangeFormatter);
        }
    }

    private getDataRangeFormatter(id: string): VizFormat | undefined {
        return this.formatters
            .find((formatter) => formatter.id.startsWith(id.slice(0, 9)))?.configs
            ?.find((config) => config.label === 'Data Range');
    }

    private getDataRangeError(dataRangeFormatter: VizFormat): boolean {
        const minRange = dataRangeFormatter.configs?.[0].configs?.[0].selectedValue;
        const maxRange = dataRangeFormatter.configs?.[1].configs?.[0].selectedValue;

        return (minRange !== 'AUTO' && maxRange !== 'AUTO') && Number(minRange) >= Number(maxRange);
    }

    private getParsedConfigValue(valueType: string, selectedValue: ConfigValue): ConfigValue {
        if (valueType === 'number') {
            return parseInt(selectedValue as string, 10);
        }
        return selectedValue;
    }

    private isAutoRange(dataType: string, selectedValue: string | boolean): boolean {
        if (dataType === 'data-range') {
            return !selectedValue;
        }
        return selectedValue === DataRange.AUTO;
    }

    private getFormattedVizConfigs(data: { [key: string]: string | number | boolean }): VisualizationPreferences[] {
        this.axisIds = [
            BarAxisID.X_AXIS,
            BarAxisID.Y_AXIS,
            LineAndStackedAreaAxisID.X_AXIS,
            LineAndStackedAreaAxisID.Y_LEFT_AXIS,
            LineAndStackedAreaAxisID.Y_RIGHT_AXIS,
        ];
        return getDefaultVisualizationPreferences(data.visualizationType as VisualizationType).map((item: VisualizationPreferences) => {
            return this.updateSelectedValue(item, data);
        });
    }

    private updateSelectedValue(
        config: VisualizationPreferences,
        data: { [key: string]: string | number | boolean },
    ): VisualizationPreferences {
        if (this.axisIds?.some((axisId) => axisId === config.id)) {
            const savedAxisConfig = this.getMatchedAxisConfig(config.id, data);
            if (savedAxisConfig) {
                const mapperObject = this.getAxisMapper(data.visualizationType as VisualizationType, config.id);
                if (mapperObject) {
                    this.updateAxisSelectedValue(config, savedAxisConfig, mapperObject);
                }
                if (data.visualizationType === 'LINE_CHART') {
                    const dataRangeConfig = config.configs?.find((conf) => conf.label === 'Data Range');
                    if (dataRangeConfig) {
                        this.updateAxisDataRangeToggleValues(dataRangeConfig, savedAxisConfig);
                    }
                }
            }
        } else {
            this.updateCommonSelectedValue(config, data);
        }
        return config;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getMatchedAxisConfig(axisId: string, data: any): any {
        if (data.axisConfigurations?.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return data.axisConfigurations.find((axisConfig: any) => axisConfig.axis === axisId);
        }
    }

    private getAxisMapper(visualizationType: VisualizationType, configId: string): AxisSettings | undefined {
        if (visualizationType === 'LINE_CHART') {
            return this.getLineChartAxisMapper(configId);
        }
        if (visualizationType === 'STACKED_AREA_CHART') {
            return this.getStackedAreaChartAxisMapper(configId);
        }
        return this.getBarChartAxisMapper(configId);
    }

    private getLineChartAxisMapper(configId: string): LineBottomXAxisSettings | LineLeftYAxisSettings | LineRightYAxisSettings | undefined {
        if (configId === LineAndStackedAreaAxisID.X_AXIS) {
            return {
                bottomXAxis: 'enableAxis',
                bottomXAxisLabels: 'displayAxisLabels',
                bottomXAxisOrientation: 'orientation',
            };
        }
        if (configId === LineAndStackedAreaAxisID.Y_LEFT_AXIS) {
            return {
                leftYAxis: 'enableAxis',
                leftYAxisLabels: 'displayAxisLabels',
                leftYAxisDataRange: 'dataRangeMode',
                leftYAxisRangeMin: 'minDataRange',
                leftYAxisRangeMax: 'maxDataRange',
            };
        }
        if (configId === LineAndStackedAreaAxisID.Y_RIGHT_AXIS) {
            return {
                rightYAxis: 'enableAxis',
                rightYAxisLabels: 'displayAxisLabels',
                rightYAxisDataRange: 'dataRangeMode',
                leftYAxisDataRange: 'dataRangeMode',
                rightYAxisRangeMin: 'minDataRange',
                rightYAxisRangeMax: 'maxDataRange',
            };
        }

        return;
    }

    private getStackedAreaChartAxisMapper(configId: string): StackedAreaBottomXAxisSettings | StackedAreaLeftYAxisSettings | undefined {
        if (configId === LineAndStackedAreaAxisID.X_AXIS) {
            return {
                bottomXAxis: 'enableAxis',
                bottomXAxisLabels: 'displayAxisLabels',
                bottomXAxisOrientation: 'orientation',
            };
        }
        if (configId === LineAndStackedAreaAxisID.Y_LEFT_AXIS) {
            return {
                leftYAxis: 'enableAxis',
                leftYAxisLabels: 'displayAxisLabels',
                leftYAxisDataRange: 'dataRangeMode',
                leftYAxisRangeMinMax: 'minMax',
            };
        }

        return;
    }

    private getBarChartAxisMapper(configId: string): BarXAxisSettings | BarYAxisSettings | undefined {
        if (configId === BarAxisID.X_AXIS) {
            return {
                barXAxis: 'enableAxis',
                barXAxisLabels: 'displayAxisLabels',
                barXAxisLine: 'enableAxisLine',
                barXAxisRangeMinMax: 'minMax',
                barXAxisDataRange: 'dataRangeMode',
                barXAxisPosition: 'position',
            };
        }
        if (configId === BarAxisID.Y_AXIS) {
            return {
                barYAxis: 'enableAxis',
                barYAxisLabels: 'displayAxisLabels',
                barYAxisLine: 'enableAxisLine',
                barYAxisRangeMinMax: 'minMax',
                barYAxisDataRange: 'dataRangeMode',
                barYAxisPosition: 'position',
            };
        }

        return undefined;
    }

    private updateCommonSelectedValue(config: VisualizationPreferences, data: { [key: string]: string | number | boolean }): void {
        if (Object.prototype.hasOwnProperty.call(data, config.id)) {
            config.selectedValue = data[config.id];
        }
        if (config.configs) {
            config.configs.forEach((conf) => this.updateSelectedValue(conf, data));
        }
    }

    private updateAxisSelectedValue(
        config: VisualizationPreferences,
        data: { [key: string]: string | number | boolean },
        mapperObject: AxisSettings,
    ): VisualizationPreferences {
        if (Object.prototype.hasOwnProperty.call(mapperObject, config.id) && data) {
            this.setAxisSelectedValue(config, data, mapperObject);
        }
        if (config.configs) {
            config.configs.forEach((conf) => this.updateAxisSelectedValue(conf, data, mapperObject));
        }

        return config;
    }

    private setAxisSelectedValue(
        config: VisualizationPreferences,
        data: { [key: string]: string | number | boolean },
        mapperObject: AxisSettings,
    ): void {
        if (config.type === 'range') {
            config.selectedValue = config.id.includes('Min') ? (data.minDataRange ?? 'AUTO') : (data.maxDataRange ?? 'AUTO');
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const key = (mapperObject as any)[config.id];
            config.selectedValue = key === 'position' ? data[key] === 'left' : data[key];
        }
    }

    private updateAxisDataRangeToggleValues(config: VisualizationPreferences, data: { [key: string]: string | number | boolean }): void {
        config.configs?.forEach((c) => c.selectedValue = c.id.includes('Min') ? data.minDataRange != null : data.maxDataRange != null);
    }

    private setTogglePosition(): void {
        const toggleContainers = this.elementRef.nativeElement.querySelectorAll('.toggle-container');
        let point = 0;
        for (const toggleContainer of toggleContainers) {
            const togglePosition = toggleContainer.querySelector('.slider').getBoundingClientRect().x;
            if (!point) {
                point = togglePosition;
            }
            const margin = togglePosition - point;
            if (margin < 0) {
                return;
            }
            this.renderer.setStyle(toggleContainer, 'margin-right', `${margin}px`);
        }
    }
}
