<div class="field-picker-tree-style-wrapper" *ngIf="tree">
    <label class="label-title">Available Fields</label>

    <div class="search-box" [ngClass]="{'readonly': readonly}">
        <span class="icon-search1"></span>

        <input
            type="search"
            placeholder="Search"
            autocomplete="off"
            [(ngModel)]="searchValue"
            (ngModelChange)="searchMetadata()"/>
    </div>

    <div class="property-list">
        <ng-container *ngFor="let data of tree">
            <div *ngIf="!data.hidden" class="parent-field">
                <ng-container *ngIf="!data.children.length && !data.displayData[0].hierarchy; else hierarchyBlock">
                    <div
                        class="list-item"
                        [title]="data.displayName"
                        cdkDrag
                        [cdkDragData]="data"
                        (cdkDragStarted)="dragStateChanged.emit({state: 'start', data: data})"
                        (cdkDragEnded)="dragStateChanged.emit({state: 'stop', data: data})"
                        [innerHtml]="data.displayName | highlight: searchValue">
                    </div>
                </ng-container>

                <ng-template #hierarchyBlock>
                    <ng-container>
                        <span
                            [ngClass]="data.isCollapsed ? 'icon-arrow-right' : 'icon-expand'"
                            title="{{ data.isCollapsed ? 'icon-arrow-right' : 'icon-expand' }}"
                            (click)="data.isCollapsed = !data.isCollapsed">
                        </span>

                        <div class="list-item grid-list-item" [title]="data.displayName">{{ data.displayName }}</div>
                    </ng-container>

                    <div *ngIf="!data.isCollapsed" class="grid-list-children">
                        <ng-container *ngIf="data.children.length; else elseBlock">
                            <ng-container *ngTemplateOutlet="childElems; context: {ctx: data}"></ng-container>
                            <ng-container *ngIf="data.displayData.length">
                                <ng-container *ngTemplateOutlet="lastChild;context: {dataList: data.displayData}"></ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-template #elseBlock>
                            <ng-container *ngTemplateOutlet="lastChild; context: {dataList: data.displayData}"></ng-container>
                        </ng-template>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>

<!-- Templates defined -->
<ng-template #childElems let-ctx="ctx">
    <ng-container *ngFor="let child of ctx.children">
        <div class="child-field" *ngIf="!child.hidden">
            <span
                [ngClass]="child.isCollapsed ? 'icon-arrow-right' : 'icon-expand'"
                title="{{ child.isCollapsed ? 'icon-arrow-right' : 'icon-expand' }}"
                (click)="child.isCollapsed = !child.isCollapsed">
            </span>

            <div class="list-item grid-list-item" [title]="child.displayName">{{ child.displayName }}</div>
        </div>

        <div *ngIf="!child.isCollapsed" class="grid-list-children">
            <ng-container *ngIf="child.children.length; else elseBlock">
                <ng-container *ngTemplateOutlet="childElems;context: {ctx: child}"></ng-container>
                <ng-container *ngIf="child.displayData.length">
                    <ng-container *ngTemplateOutlet="lastChild;context: {dataList: child.displayData}"></ng-container>
                </ng-container>
            </ng-container>

            <ng-template #elseBlock>
                <ng-container *ngTemplateOutlet="lastChild;context: {dataList: child.displayData}"></ng-container>
            </ng-template>
        </div>
    </ng-container>
</ng-template>

<ng-template #lastChild let-dataList="dataList">
    <div *ngFor="let data of dataList" cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate()" [ngClass]="{'readonly': readonly}">
        <div
            *ngIf="!data.hidden"
            class="list-item list-child"
            [title]="data.displayName"
            cdkDrag
            [cdkDragData]="data"
            (cdkDragStarted)="cloneElement($event, { state: 'start', data: data })"
            (cdkDragEnded)="dragStateChanged.emit({state: 'stop', data: data})"
            [innerHtml]="data.displayName | highlight: searchValue"
            [cdkDragDisabled]="readonly">
        </div>
    </div>
</ng-template>
<!-- Templates defined -->
