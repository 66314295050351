import { Inject, Injectable, ErrorHandler } from '@angular/core';

import { ErrorLogger } from './error-logger';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
    constructor(@Inject(ErrorLogger) private readonly errorLoggers: ErrorLogger[]) {}

    isLoggerRegistered(errorLogger: ErrorLogger): boolean {
        return this.errorLoggers.includes(errorLogger);
    }

    handleError(error: Error | unknown): void {
        this.errorLoggers.forEach((errorLogger) => errorLogger.logError('GlobalError', error));
    }
}
