import { Inject, NgModule, Optional } from '@angular/core';
import {
    ApiConfiguration,
    ApiLocation,
    apiRootForService,
    defaultApiConfiguration,
    hostAndPortForService,
    HttpModule,
} from '@ddv/http';

import { CellEditorsModule } from './components/cell-editors/cell-editors.module';
import { CellRenderersModule } from './components/cell-renderers/cell-renderers.module';
import { CommentsService } from './services/comments.service';
import {
    CrosstalkModalService,
    portfolioDetailsHostAndPortToken,
    pscLocationToken,
    xtlkLocationToken,
} from './services/crosstalk-modal.service';
import { CrosstalkRealtimeConversationService, xtlkWsConnectionUrlToken } from './services/crosstalk-realtime-conversation.service';
import { CrosstalkService } from './services/crosstalk.service';
import { createWebSocket, webSocketFactoryToken } from './services/socket.factory';

@NgModule({
    imports: [
        CellEditorsModule,
        CellRenderersModule,
        HttpModule,
    ],
    providers: [
        CrosstalkRealtimeConversationService,
        CommentsService,
        CrosstalkModalService,
        CrosstalkService,
        { provide: webSocketFactoryToken, useValue: createWebSocket },
        {
            provide: xtlkWsConnectionUrlToken,
            useFactory: (c: ApiConfiguration | null): string => {
                const httpUrl = apiRootForService('xtlkApiLocation', c ?? defaultApiConfiguration);
                return `${httpUrl.replace('https://', 'wss://')}ws`;
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
        {
            provide: xtlkLocationToken,
            useFactory: (c: ApiConfiguration | null): string => {
                const config = c ?? defaultApiConfiguration;
                const location: ApiLocation = config.xtlkApiLocation;
                return `${window.location.protocol}//${hostAndPortForService(location.hostAndPort, config.onPremApiHost)}`;
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
        // this does not belong here.  there should be a non-crosstalk service for launching other modals
        {
            provide: pscLocationToken,
            useFactory: (c: ApiConfiguration | null): string => {
                const config = c ?? defaultApiConfiguration;
                return `${window.location.protocol}//${hostAndPortForService(config.pscLocation.hostAndPort, config.onPremApiHost)}`;
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
        // this does not belong here.  there should be a non-crosstalk service for launching other modals
        {
            provide: portfolioDetailsHostAndPortToken,
            useFactory: (c: ApiConfiguration | null): string => {
                const config = c ?? defaultApiConfiguration;
                return `${window.location.protocol}//${hostAndPortForService(config.portfolioDetailsHostAndPort, config.onPremApiHost)}`;
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
    ],
})
export class CrosstalkModule {}
