import { WIDGET_LIFECYCLE_EVENT, WorkspaceState, WidgetState } from '@ddv/models';

export class AppLayoutState {
    workspace?: WorkspaceState;
    widgets?: WidgetState[];

    constructor(workspaceState?: WorkspaceState, widgetState?: WidgetState[]) {
        this.workspace = workspaceState;
        this.widgets = widgetState;
    }
}

export type WorkspaceStateChangeHandler = (
    appLayoutState: AppLayoutState,
    isWorkspaceRendered: boolean,
    eventName: WIDGET_LIFECYCLE_EVENT) => void;
