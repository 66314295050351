import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService, DashboardModeAndId } from '@ddv/behaviors';
import { ConfirmationPopupService, ModalDialogService, MultiSubscriptionComponent, ValueOption } from '@ddv/common-components';
import { ADD_NEW_CROSSTALK_SCHEMA, CommentsService, CrosstalkModalService, Schema } from '@ddv/crosstalk';
import { DatasetDefinitionSelectorComponent, DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { Entitlements, UserEntitlementService } from '@ddv/entitlements';
import { ErrorLoggerService } from '@ddv/error-handling';
import {
    CONFIRM_DELETE,
    DatasetDefinition,
    DatasetDefinitionDetails,
    DataType,
    GLOBAL_SAVE_MESSAGE,
    NamedQuery,
    QueryPeriodType,
    QueryType,
    QueryTypeName,
} from '@ddv/models';
import { convertJsonToCsv, exportToCSV } from '@ddv/utils';
import { visualizationDescriptorMap } from '@ddv/visualizations';
import { WidgetsService } from '@ddv/widgets';
import { combineLatest } from 'rxjs';

import { Visualization } from '../../models/visualization';
import { PrettyPrintService } from '../../services/pretty-print.service';
import { PublicApiService } from '../../services/public-api.service';
import { SavedViewSelectorComponent } from '../saved-view-selector/saved-view-selector.component';

class CheckableVisualization extends Visualization {
    selected = false;
}

const NO_CONVERSABLE_TYPE = 'None';
const CROSSTALK_TYPE_MODAL_SIZE = { width: 560, height: 520 };

@Component({
    templateUrl: './manage-dataset-definitions.component.html',
    styleUrls: ['./manage-dataset-definitions.component.scss'],
})
export class ManageDatasetDefinitionsComponent extends MultiSubscriptionComponent implements OnInit, OnDestroy {
    commentOptions: ValueOption[] = [];
    crossTalkSchemas: Schema[] | undefined;
    conversableType: string | undefined;
    dataTypes: DataType[] = [];
    queryTypes: QueryType[] = [];
    queryPeriodTypes: QueryPeriodType[] = [];
    visualizations: CheckableVisualization[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    usingWidgetNamesAndVisualizations: { name: string, visualizations: string[] }[] | undefined;
    datasetDefinition: DatasetDefinitionDetails | undefined;
    isReadOnly = false;
    @ViewChild(DatasetDefinitionSelectorComponent, { static: true }) dsdSelector: DatasetDefinitionSelectorComponent | undefined;
    returnTo: string | undefined;
    canManageHSSupportedDataset = false;
    canAddCrosstalkType = false;

    private canDeleteHSSupportedDataset = false;
    private canEditGlobal = false;
    private currentClientCode: string | undefined;

    constructor(
        private readonly modalService: ModalDialogService,
        private readonly widgetsService: WidgetsService,
        private readonly publicApiService: PublicApiService,
        private readonly router: Router,
        private readonly prettyPrintService: PrettyPrintService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly errorLogger: ErrorLoggerService,
        private readonly currentStateService: CurrentStateService,
        private readonly userEntitlementsService: UserEntitlementService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly commentsService: CommentsService,
        private readonly crosstalkModalService: CrosstalkModalService,
        private readonly confirmationService: ConfirmationPopupService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(this.activatedRoute.queryParamMap, (paramsMap) => {
            if (paramsMap.has('from')) {
                this.returnTo = paramsMap.get('from') ?? undefined;
            }
        });

        this.subscribeTo(this.currentStateService.currentDatasetDefinitionId$, async (datasetDefinitionId) => {
            if (datasetDefinitionId == null) {
                return this.clear();
            }

            return this.populateForDatasetDefinition(datasetDefinitionId);
        });

        this.subscribeTo(this.currentStateService.clientCode$, (clientCode) => {
            this.currentClientCode = clientCode;
        });

        this.subscribeTo(this.datasetDefinitionsService.fetchDataTypes(true), (types) => {
            this.dataTypes = types;
        });

        this.subscribeTo(this.datasetDefinitionsService.fetchQueryTypes(), (types) => {
            this.queryTypes = types;
        });

        this.subscribeTo(this.datasetDefinitionsService.fetchQueryPeriodTypes(), (types) => {
            this.queryPeriodTypes = types;
        });

        this.subscribeTo(this.datasetDefinitionsService.fetchAllVisualizations(), (visualizations) => {
            this.visualizations = visualizations.map((v) => new CheckableVisualization(v.id, v.name, v.widgetType));
            const stackedAreaChart = this.visualizations.splice(11, 1)[0];
            this.visualizations.splice(3, 0, stackedAreaChart);
            if (this.datasetDefinition) {
                this.selectVisualizationsFromDefinition();
            }
        });

        this.subscribeTo(this.userEntitlementsService.entitlementsForClientCode$, (e) => {
            this.canManageHSSupportedDataset = e.hasPermission(Entitlements.DATASET_DEFINITION_MANAGE_HS_SUPPORTED);
            this.canDeleteHSSupportedDataset = e.hasPermission(Entitlements.DATASET_DEFINITION_DELETE_HS_SUPPORTED);
            // I think the following might be a mistake
            // as DDV MW requires 'DDV View and Edit All' EDB Component
            // in order to edit a global dataset
            this.canEditGlobal = e.hasPermission(Entitlements.ADMIN_EDIT);
            this.canAddCrosstalkType = e.hasPermission(Entitlements.MANAGE_CONVERSABLE_TYPE);
        });

        this.subscribeTo(combineLatest([this.commentsService.fetchSchemas(), this.crosstalkModalService.crosstalkSchema$]),
            ([schemas, newSchema]) => {
                if ((newSchema as Schema)?.type && !schemas.find((schema) => schema.id === (newSchema as Schema).id)) {
                    schemas.push(newSchema as Schema);
                }
                this.crossTalkSchemas = schemas;
                schemas.sort((a, b) => a.type.localeCompare(b.type));
                this.commentOptions = [
                    { texts: [NO_CONVERSABLE_TYPE, '', '', '', ''], value: NO_CONVERSABLE_TYPE },
                    ...schemas.map((schema) => {
                        const schemaFields = schema.fields.join(', ');
                        const schemaDisplayFields = schema.displayFields.join(', ');
                        return {
                            texts: [schema.type, schema.displayName, schemaFields, schemaDisplayFields, schema.createdBy],
                            value: schema.type,
                        };
                    }),
                ];
            });
    }

    get canDelete(): boolean {
        return !!(this.datasetDefinition?.id &&
            !this.datasetDefinition?.isGlobal &&
            (!this.datasetDefinition?.isHSSupported || this.canDeleteHSSupportedDataset));
    }

    get canSave(): boolean {
        return !!(this.datasetDefinition &&
            (!this.datasetDefinition.isHSSupported || this.canManageHSSupportedDataset) &&
            (!this.datasetDefinition.isGlobal || this.canEditGlobal));
    }

    get returnToLabel(): string {
        if (this.returnTo === 'widgets') {
            return 'Widget';
        }

        return 'View';
    }

    async onDatasetDefinitionSelected(selectedDsd: DatasetDefinition | NamedQuery): Promise<boolean> {
        return this.navigateToDatasetDefinition(selectedDsd.id);
    }

    populateForDatasetDefinition(id: number): void {
        this.widgetsService.fetchWidgetsUsingDatasetDefinition(id)
            .subscribe((widgets) => {
                this.usingWidgetNamesAndVisualizations = widgets
                    .map((widget) => {
                        return {
                            name: widget.name,
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            visualizations: (widget as any).visualizationConfigs.map((config: any) => {
                                return visualizationDescriptorMap[config.visualizationType].cssClass;
                            }),
                        };
                    })
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
            });

        this.datasetDefinitionsService.fetchDatasetDefinitionDetails(id)
            .subscribe((dsd) => {
                if (this.dsdSelector) {
                    this.dsdSelector.datasetDefinition = dsd;
                }
                this.loadDatasetDefinition(dsd);
            });
    }

    loadDatasetDefinition(dsd: DatasetDefinitionDetails): void {
        if (dsd.queryType && dsd.queryType.name === QueryTypeName.GQL) {
            dsd.queryTemplate = this.prettyPrintService.gqlFormat(dsd.queryTemplate, { indent: 4 });
        } else {
            dsd.queryTemplate = this.prettyPrintService.jsonFormat(dsd.queryTemplate, { minimal: true });
        }

        this.conversableType = dsd.conversableType || NO_CONVERSABLE_TYPE;

        this.datasetDefinition = dsd;
        this.selectVisualizationsFromDefinition();
    }

    selectVisualizationsFromDefinition(): void {
        this.visualizations.forEach((vis) => {
            vis.selected = this.datasetDefinition?.datasetDefinitionVisualizations?.some((v) => {
                return v.visualizationMaster && v.visualizationMaster.id === vis.id;
            }) ?? false;
        });
    }

    onLoadQueryFromSavedView(): void {
        const savedViewSelectorDialog = this.modalService.open(SavedViewSelectorComponent);
        savedViewSelectorDialog.result.then(
            (savedView) => this.populateFromSavedView(savedView),
            () => {});
    }

    populateFromSavedView(savedView: object): void {
        if (this.datasetDefinition && !this.datasetDefinition?.name) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.datasetDefinition.name = (savedView as any).display_name;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.publicApiService.getSavedViewAsQuery((savedView as any).id)
            .subscribe({
                next: (queryTemplate) => {
                    if (this.datasetDefinition) {
                        this.datasetDefinition.queryTemplate = queryTemplate;
                    }
                },
                error: (error) => {
                    const newError = { message: JSON.parse(error._body).error } as Error;
                    this.errorLogger.logError('', newError);
                },
            });
    }

    onCreateDataSetDefinition(): void {
        this.datasetDefinition = new DatasetDefinitionDetails({
            queryType: this.queryTypes.find((type) => type.name === QueryTypeName.POSITIONS),
            name: '',
            isHSSupported: false,
            isGlobal: false,
            showDuplicateRowsWarning: true,
        });
        this.visualizations.forEach((v) => v.selected = false);
    }

    onSaveDataSetDefinition(): void {
        if (this.datasetDefinition?.isGlobal) {
            const msg = GLOBAL_SAVE_MESSAGE;

            this.showConfirmPopup(msg, 'saveDatasetDefinition');
        } else {
            this.saveDatasetDefinition();
        }
    }

    saveDatasetDefinition(): void {
        if (!this.datasetDefinition) {
            return;
        }

        this.datasetDefinition.conversableType = (this.conversableType !== NO_CONVERSABLE_TYPE ? this.conversableType : '') ?? '';

        const datasetDefinitionUpdateRequest = {
            datasetDefinition: this.datasetDefinition,
            supportedVisualizationNames: this.visualizations.filter((v) => v.selected).map((v) => v.name),
        };

        this.datasetDefinitionsService.saveDatasetDefinition(datasetDefinitionUpdateRequest).subscribe(async (dsd) => {
            this.datasetDefinition!.id = Number(dsd.id);
            await this.navigateToDatasetDefinition(dsd.id);
        });
    }

    onDeleteDataSetDefinition(): void {
        this.showConfirmPopup(CONFIRM_DELETE, 'deleteDataSetDefinition');
    }

    deleteDataSetDefinition(): void {
        if (typeof this.datasetDefinition?.id !== 'number') {
            return;
        }

        this.datasetDefinitionsService.deleteDatasetDefinition(this.datasetDefinition.id)
            .subscribe(async (result) => {
                if (result.success) {
                    this.datasetDefinition = undefined;
                    await this.navigateToDatasetDefinition(undefined);
                }
            });
    }

    onNewDataType(newDataTypeName: string): void {
        this.datasetDefinitionsService.createDataType({ name: newDataTypeName }).subscribe((newDataType) => {
            this.dataTypes = [...this.dataTypes, newDataType];
            if (this.datasetDefinition) {
                // which one is it?  string or DataType
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.datasetDefinition.dataType = newDataType as any;
            }
        });
    }

    launchCrosstalkTypeModal(): void {
        const config = {
            label: ADD_NEW_CROSSTALK_SCHEMA,
            uri: `/two-way/#/${this.currentClientCode}/schema/new`,
            size: CROSSTALK_TYPE_MODAL_SIZE,
            launchType: 'modal',
        };

        const { screenX, screenY } = this.crosstalkModalService
            .getModalPosition(CROSSTALK_TYPE_MODAL_SIZE.width, CROSSTALK_TYPE_MODAL_SIZE.height);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.crosstalkModalService.openModal(config, ({ screenX, screenY } as any));
    }

    confirmCancel(): void {
        const msg = 'Are you sure you want to cancel?';
        this.showConfirmPopup(msg);
    }

    onReturnToLaunchPointClick(): void {
        if (this.canSave) {
            const msg = `Are you sure you want to go back to the ${this.returnToLabel}?`;
            return this.showConfirmPopup(msg, 'returnToLaunchPoint');
        }

        this.returnToLaunchPoint();
    }

    showConfirmPopup(message: string, method?: string): void {
        const confirmDialogOptions = {
            message,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        };
        this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
            next: async (action) => {
                if (action === 'confirm') {
                    switch (method) {
                        case 'returnToLaunchPoint':
                            this.returnToLaunchPoint();
                            break;
                        case 'deleteDataSetDefinition':
                            this.deleteDataSetDefinition();
                            break;
                        case 'saveDatasetDefinition':
                            this.saveDatasetDefinition();
                            break;
                        default:
                            await this.clear();
                    }
                }
            },
        });
    }

    onConversableTypeSelectedChange(event: string | ValueOption): void {
        this.conversableType = typeof event === 'string' ? event : event.value;
    }

    exportCrosstalkTypes(): void {
        const csv = convertJsonToCsv(this.crossTalkSchemas ?? []);
        exportToCSV(csv, 'Crosstalk Type Details');
    }

    private returnToLaunchPoint(): void {
        if (this.returnTo === 'widgets') {
            return this.returnToWidget();
        }

        this.returnToDashboard();
    }

    private returnToDashboard(): void {
        this.subscribeTo(this.currentStateService.dashboardModeAndId$, async (dashboardModeandId: DashboardModeAndId) => {
            if (dashboardModeandId) {
                const { mode, id } = dashboardModeandId;
                await this.router.navigate([`../../../views/${mode}/${id ?? 'none'}`], { relativeTo: this.activatedRoute });
            } else {
                await this.router.navigate(['../../../views/view/none'], { relativeTo: this.activatedRoute });
            }
        });
    }

    private returnToWidget(): void {
        this.subscribeTo(this.currentStateService.currentWidgetId$, async (widgetId) => {
            await this.router.navigate(['../../../widgets/edit/', widgetId ?? 'none'], { relativeTo: this.activatedRoute });
        });
    }

    private async clear(): Promise<boolean> {
        this.datasetDefinition = undefined;

        if (this.dsdSelector) {
            this.dsdSelector.restorePriorSelection();
        }

        this.usingWidgetNamesAndVisualizations = undefined;

        return this.navigateToDatasetDefinition(undefined);
    }

    private async navigateToDatasetDefinition(id?: number | string): Promise<boolean> {
        return this.router.navigate(['..', id ?? 'none'], { relativeTo: this.activatedRoute });
    }
}
