<div class="datepicker__wrapper">

    <div class="datepicker datepicker__from">
        <label>From date</label>
        <app-datepicker
            [calendarOptions]="{allowPrev: true, allowNext: false, allowRange: true}"
            [fuzzyDates]="fuzzyDates.from"
            [date]="dateRange"
            [year]="yearFrom"
            [month]="monthFrom"
            [nextRangeDate]="nextRangeDate"
            [dateRangeValue]="'dateFrom'"
            [showCalendar]="!isInListViewMode"
            [showListView]="isInListViewMode"
            [isHIDataAvailable]="isHIDataAvailable"
            (dateSelected)="onDateSelected($event)"
            (errorMessageChange)="handleError($event)"
            (yearMonthChange)="handleYearMonthChange($event, 'dateFrom')"
            (nextRangeDateChange)="setNextRangeDate($event)">
        </app-datepicker>
    </div>

    <div class="datepicker datepicker__to">
        <label>To date</label>
        <app-datepicker
            [calendarOptions]="{allowPrev: false, allowNext: true, allowRange: true}"
            [fuzzyDates]="fuzzyDates.to"
            [date]="dateRange"
            [year]="yearTo"
            [month]="monthTo"
            [nextRangeDate]="nextRangeDate"
            [dateRangeValue]="'dateTo'"
            [showCalendar]="!isInListViewMode"
            [isHIDataAvailable]="isHIDataAvailable"
            (dateSelected)="onDateSelected($event)"
            (errorMessageChange)="handleError($event)"
            (yearMonthChange)="handleYearMonthChange($event, 'dateTo')"
            (nextRangeDateChange)="setNextRangeDate($event)">
        </app-datepicker>
    </div>

</div>

<div *ngIf="isHIDataAvailable && !isInListViewMode" class="key-wrapper" appMousedownOutside (mousedownOutside)="keyModalOpen = false">
    <span class="key" (click)="keyModalOpen = !keyModalOpen">
        Key <span class="icon-arrow-right"></span>
    </span>
    <div *ngIf="keyModalOpen" class="key-modal">
        <span *ngFor="let postedState of postedStates" class="key-text">
            <span class="{{postedState.class}}">21</span> {{ postedState.text }}
        </span>
    </div>
</div>
