export const outdatedFontColors = [
    { label: 'Dark Red Text', value: '#D00000', mapsTo: 'Red' },
    { label: 'Dark Blue Text', value: '#004F6E', mapsTo: 'Dark Blue' },
    { label: 'Light Yellow Text', value: '#FFDB75', mapsTo: 'Yellow' },
    { label: 'Medium Grey Text', value: '#A7A8AB', mapsTo: 'Gray' },
    { label: 'White Text', value: '#FFFFFF', mapsTo: 'White' },
    { label: 'Black text', value: '#000000', mapsTo: 'Black' },
];

export const lightThemeFontColors = [
    { label: 'Red', value: '#C30B1E' },
    { label: 'Blue', value: '#00AEEF' },
    { label: 'Dark Blue', value: '#07506D' },
    { label: 'Green', value: '#72A13B' },
    { label: 'Yellow', value: '#F5AD1C' },
    { label: 'Gray', value: '#C1C0C2' },
    { label: 'Dark Gray', value: '#58595B' },
    { label: 'White', value: '#FFFFFF' },
    { label: 'Black', value: '#000000' },
];

export const darkThemeFontColors = [
    { label: 'Red', value: '#EA3A39' },
    { label: 'Blue', value: '#13AEE5' },
    { label: 'Dark Blue', value: '#07506D' },
    { label: 'Green', value: '#72A13B' },
    { label: 'Yellow', value: '#F9BE14' },
    { label: 'Gray', value: 'rgba(250, 251, 255, 0.65)' },
    { label: 'Dark Gray', value: 'rgba(250, 251, 255, 0.25)' },
    { label: 'White', value: '#FFFFFF' },
    { label: 'Black', value: '#000000' },
];
