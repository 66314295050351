import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DateRangeString, DdvDate, FuzzyDate } from '@ddv/models';

@Component({
    selector: 'app-active-date-picker-dialog',
    templateUrl: './active-date-picker-dialog.component.html',
    styleUrls: ['./active-date-picker-dialog.component.scss'],
})
export class ActiveDatePickerDialogComponent implements OnChanges {
    @Input() isActiveDate: boolean | undefined;
    @Input() date: string | undefined;
    @Input() fuzzyDates: FuzzyDate[] = [];

    @Output() dateSelected = new EventEmitter();
    @Output() isActiveDateChanged = new EventEmitter();

    year?: number;
    month?: number;

    errorMessage: string | undefined;

    ngOnChanges(): void {
        if (this.date) {
            const date = DdvDate.fromUSFormat(this.date);
            this.year = date.year;
            this.month = date.month;
        }
    }

    applyRadioFilter(isActiveDate: boolean): void {
        this.isActiveDateChanged.emit(isActiveDate);
    }

    onDateSelected(date?: string | DateRangeString): void {
        this.date = date as string;
        if (date) {
            const fuzzyDate = this.getFuzzyDate(date as string);
            this.setupYearMonth(fuzzyDate ? DdvDate.fromISOFormat(fuzzyDate.value) : DdvDate.fromUSFormat(date as string));
        }
    }

    private getFuzzyDate(value: string): FuzzyDate | undefined {
        return this.fuzzyDates?.find((fuzzyDate) => fuzzyDate.name.toLocaleUpperCase() === value.toLocaleUpperCase());
    }

    private setupYearMonth(date: DdvDate): void {
        this.year = date.year;
        this.month = date.month;
    }

    emitDate(): void {
        this.dateSelected.emit(this.date);
    }

    showError(errorMessage?: string): void {
        this.errorMessage = errorMessage;
    }
}
