<div class="loader-parent-in-left-nav" *ngIf="isDataLoading && !isWidgetLoader">
    <div class="spinner loader">
        <div class="blue-spinner"></div>
        <div class="gray-spinner"></div>
    </div>
</div>

<span id="delay-spinner" class="delay-spinner" *ngIf="isDataLoading && isWidgetLoader">
    <div class="progressbar" role="progressbar">
        <div [ngStyle]="{'animation': opt.animation}" *ngFor="let opt of options">
            <div [style.transform]="opt.transform"></div>
        </div>
    </div>
</span>
