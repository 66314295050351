<svg version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="16px"
        height="16px"
        viewBox="0 0 20 20"
        enable-background="new 0 0 20 20"
        xml:space="preserve">
    <title>Master Widget</title>
    <g>
        <path fill="none" stroke="#939598" stroke-width="0.96" stroke-miterlimit="10" d="M18.16,16.093c0,1.057-0.864,1.92-1.92,1.92H3.759c-1.056,0-1.92-0.863-1.92-1.92V3.613c0-1.056,0.864-1.92,1.92-1.92H16.24c1.056,0,1.92,0.864,1.92,1.92V16.093z"/>
    </g>
    <text transform="matrix(1 0 0 1 5.4858 13.6504)" font-size="10.56">M</text>
</svg>
