<div *ngIf="showAlert" class="alert short {{cssClass(alert).message}}">
    <span class="{{cssClass(alert).cssClass}}"></span>

    <div class="message-list">
        <h3>{{cssClass(alert).message}}</h3>

        <p>{{alert?.message}}</p>

        <a
            class="details-link"
            *ngIf="hasDetails"
            (click)="showDetails = !showDetails">
                {{showDetails ? "Hide Details" : "Show Details"}}
        </a>
    </div>

    <a class="icon-close1" (click)="removeAlert()"></a>
</div>

<div *ngIf="showDetails" class="alert details {{cssClass(alert).message}}">
    <a class="icon-close1" (click)="showDetails = false"></a>

    <h3>Details</h3>

    <div class="error-label">RequestID:</div>
    <div class="error-text">{{alert?.requestId}}</div>

    <div class="error-label">Root Cause:</div>
    <div class="error-text">{{alert?.rootCauseMessage}}</div>

    <div class="error-label">Stacktrace:</div>
    <div class="error-text">
        <div class="stack-container">
            <span *ngFor="let item of alert?.rootCauseStacktrace">{{item}}</span>
        </div>
    </div>
</div>
