<div class="column-search-wrap" appMousedownOutside (mousedownOutside)="showColumnSearchList = false">
    <div class="search-box" [ngClass]="{'readonly': isReadOnly}">
        <span class="icon-search1"></span>

        <input
            type="search"
            class="form-control"
            placeholder="Column Search"
            autocomplete="off"
            (focus)="showColumnSearchList = true"
            (input)="onColumnFilterChanged($event)"/>
    </div>

    <ul class="adv-search-dropdown" *ngIf="showColumnSearchList && searchColumns.length > 0">
        <li *ngFor="let item of searchColumns">
            <div class="checkbox">
                <input
                    type="checkbox"
                    [attr.checked]="item.visible ? 'checked' : null"
                    id="columSearch_{{item.colId}}"
                    class="checkbox-custom"
                    [(ngModel)]="item.visible"
                    (ngModelChange)="setColumnVisible(item)"/>

                <label for="columSearch_{{item.colId}}" class="checkbox-custom-label">
                    <span>{{ item.colName }}</span>
                </label>
            </div>
        </li>
    </ul>
</div>
