import { CommentCellEditorComponent } from './comment-cell-editor/comment-cell-editor.component';
import {
    UserDefinedFieldCellEditorComponent,
} from './user-defined-field-cell-editor/user-defined-field-cell-editor.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const crosstalkCellEditors: Record<string, new (...args: any[]) => any> = {
    commentCellEditorComponent: CommentCellEditorComponent,
    userDefinedFieldCellEditorComponent: UserDefinedFieldCellEditorComponent,
};
