import { WIDGET_LIFECYCLE_EVENT } from '../constants/widget-lifecycle-event';
import { DataUpdateBody } from './data-update.body';

export interface WidgetLifecycleAction {
    action: WIDGET_LIFECYCLE_EVENT;
}

export interface DataUpdateStackedArea {
    action?: WIDGET_LIFECYCLE_EVENT;
    dataSource?: StackedAreaKey[];
    selectedItem?: { data: StackedAreaKey };
    field?: string;
}

export interface StackedAreaKey {
    key: string;
}

export interface WidgetLifeCyleErrorAction extends WidgetLifecycleAction {
    action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED;
    exception?: { message: unknown };
    clientFacingMessage?: { message: unknown };
}

export type WidgetLifecycleEvent = WIDGET_LIFECYCLE_EVENT | WidgetLifecycleAction | WidgetLifeCyleErrorAction;
export type WidgetLifeCycleData = DataUpdateBody | WidgetLifecycleAction | MouseEvent | DataUpdateStackedArea;

export function isLifecycleAction(event: unknown): event is WidgetLifecycleAction {
    return Object.prototype.hasOwnProperty.call(event, 'action');
}

export function isLifecycleDataUpdateBody(data: unknown): data is DataUpdateBody {
    return !!data && Object.prototype.hasOwnProperty.call(data, 'data');
}

export function isLifecycleErrorAction(event: WidgetLifecycleEvent): event is WidgetLifeCyleErrorAction {
    return Object.prototype.hasOwnProperty.call(event, 'action') &&
        (event as WidgetLifecycleAction).action === WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED;
}
