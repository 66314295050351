import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserEntitlementService } from '@ddv/entitlements';
import { SelectedWidgetRelayService } from '@ddv/visualizations';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
    selectListItems: TextValueCheck[] = [];
    showSelectListItems = false;
    selectAll = false;
    selectedLists: TextValueCheck[] = [];
    isReadOnly = false;
    @Input() placeholder = 'Select Zoom Level';
    @Output() selectedListChange = new EventEmitter<TextValueCheck[]>();
    @Input() selectedItems: TextValueCheck[] | undefined;
    @Input() listItems: TextValueCheck[] | undefined;
    @Input() isDisabled = false;
    @Input() isAllrequired = false;

    constructor(
        private readonly userEntitlementService: UserEntitlementService,
        private readonly selectedWidgetRelayService: SelectedWidgetRelayService,
    ) {
        combineLatest([this.userEntitlementService.entitlementsForClientCode$, this.selectedWidgetRelayService.isWidgetGlobal])
            .subscribe(([entitlements, isWidgetGlobal]) => {
                this.isReadOnly = entitlements.haveGlobalEditPartial && isWidgetGlobal;
            });
    }

    ngOnInit(): void {
        this.selectedLists = this.selectedItems ?? [];
        this.selectListItems = this.listItems ? this.getselectedListItems() : [];
        this.selectAll = this.selectListItems.length ? this.checkSelected() : false;
    }

    getselectedListItems(): TextValueCheck[] {
        if (!this.selectedLists.length) {
            this.listItems?.forEach((item) => item.checked = false);
            return this.listItems ?? [];
        }

        const lists = this.listItems ?? [];
        lists?.map((item: TextValueCheck) => {
            return item.checked = this.selectedLists.some((list: TextValueCheck) => list.value === item.value);
        });

        return lists;
    }

    checkSelected(): boolean {
        return !this.selectListItems.some((item: TextValueCheck) => !item.checked);
    }

    toggleSelectList(): void {
        this.showSelectListItems = !this.showSelectListItems;
    }

    updateSelectedList(index?: string): void {
        if (index === 'all') {
            this.selectListItems.map((item: TextValueCheck) => item.checked = this.selectAll);
        }
        this.selectedLists = this.selectListItems.filter((item: TextValueCheck) => item.checked);
        this.selectAll = this.selectListItems.length ? this.checkSelected() : false;
    }

    onSelectClose(): void {
        if (this.showSelectListItems) {
            this.selectedItems = this.selectedLists;
            this.showSelectListItems = false;
            this.selectedListChange.emit(this.selectedLists);
        }
    }
}

interface TextValueCheck {
    label?: string;
    value?: string;
    checked?: boolean;
}
