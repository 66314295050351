export const AUTO_GROUP_COLUMN_ID = 'ag-Grid-AutoColumn';
export const TFL_DETAILS_AUTO_GROUP_COLUMN_ID = 'Allocation Group ID';
export const HEADER_COLUMN_FONT = '10pt "NewsGothicBTRoman", sans-serif';
export const COMPARE_GROUP_ID_SUFFIX = '__compare-group';
export const EDITABLE_CHECKBOX_FIELD = 'editable-field-gp56FSHEeTVl3pfe2isq';
export const TRANSACTION_TYPE_FIELD = 'Transaction Type';
export const HS_TRADE_ID_FIELD = 'HS Trade ID';
export const ROOT_NODE_ID = 'ROOT_NODE_ID';

export const GRID_ICONS: Record<string, string> = {
    menu: 'icon-advancedgrid-menu',
    filter: 'icon-filteroutline',
    columns: 'icon-advancedgrid-list',
    sortAscending: 'sort-ascending',
    sortDescending: 'sort-descending',
    groupExpanded: 'icon-arrow-down',
    groupContracted: 'icon-right-expand ',
    columnMovePin: 'column-move-pin',
    columnMoveAdd: 'column-move-add',
    columnMoveHide: 'column-move-hide',
    columnMoveMove: 'column-move-move',
    columnMoveLeft: 'column-move-left',
    columnMoveRight: 'column-move-right',
    columnMoveGroup: 'column-move-group',
    rowGroupPanel: 'row-group-panel',
    pivotPanel: 'pivot-panel',
    valuePanel: 'value-panel',
    menuPin: 'menu-pin',
    menuValue: 'menu-value',
    menuAddRowGroup: 'menu-add-row-group',
    menuRemoveRowGroup: 'menu-remove-row-group',
    clipboardCopy: 'clipboard-copy',
    clipboardPaste: 'clipboard-paste',
};

export const NUMBER_FILTER_OPERATIONS = [
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'inRange',
];

export const NUMBER_FILTER_OPERATIONS_FOR_RANKED_COLUMN = ['lessThan', 'lessThanOrEqual'];
