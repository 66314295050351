<div
    #bigWGridContainer
    class="simple-data-grid"
    [ngClass]="{'header-series': (isMultiSeries || isStacked || isStackedArea), 'mirrored-bar-chart': isMirrored}"
    appMousedownOutside
    (mousedownOutside)="deselectAllSelected()"
>
    <app-data-grid
        #dataGridComponent
        *ngIf="showGrid"
        [gridConfiguration]="gridConfiguration"
        (gridReadyEvent)="onGridReady()"
        (rowClickedEvent)="onRowClicked($event)"
        (virtualColumnsChanged)="onVirtualColumnsChanged()"
    >
    </app-data-grid>
</div>
