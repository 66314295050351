<div class="app-content">
    <div class="application-header">
        <div class="hs-dashboard-header">
            <div *ngIf="widgetInfo" class="dashboard-name">
                <h1 [class.highlight]="widgetInfo.name">{{ widgetInfo.name ? widgetInfo.name : 'Enter Widget Name' }}</h1>

                <cc-icon *ngIf="widgetInfo.id" [icon]="'dots-vertical'" [size]="'xlarge'" title="Widget Properties" (iconClicked)="showSaveDialog()"></cc-icon>

                <div>
                    <button class="ghost-btn" [disabled]="!enableSave" (click)="saveWidget()">Save</button>

                    <button class="ghost-btn" *ngIf="widgetInfo.id" (click)="showSaveDialog(true)">Save As</button>

                    <button class="ghost-btn" [disabled]="!enableSave" (click)="revertChanges.emit(widgetInfo.id)">Revert</button>
                </div>
            </div>
        </div>

        <div class="dashboard-filter">
            <cc-icon [icon]="'filter'" class="icon-filter-tablet" [size]="'xsmall'" title="Manage Widget Filter" (iconClicked)="openDashboardFilter()"></cc-icon>

            <div class="filter">
                <app-dashboard-filters
                    #dashboardFilters
                    [showActiveDateToggle]="true"
                    [showActiveDateDialogToggle]="true"
                    [isActiveDate]="!!widgetInfo?.isActiveDate"
                    [reducer]="reducer"
                    [isQueryTypeRecon]="isQueryTypeRecon()"
                    [clientCode]="clientCode"
                    [isMultiClient]="isMultiClient"
                    [userSelectedDate]="userSelectedDate"
                    [filterType]="filterType"
                    [widgetId]="manageWidgetId"
                    [datasetDefinition]="datasetDefinition"
                    (fundsApply)="onApplySelectedFunds($event)"
                    (clientsApply)="onApplySelectedClients($event)"
                    (dateFilterApply)="onApplyDateChanges($event)"
                    (filterApply)="onApplyAdditionalFilters($event)">
                </app-dashboard-filters>
            </div>
         </div>
    </div>
</div>
