import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';

import { Axis } from '../../models/axis';
import { BarChartData } from '../../models/chart-data';
import { safeStringFormatter } from '../../safe-string-formatter';
import { stringToNumber } from '../../services/base-chart.service';
import { ChartPosition } from './horizontal-bar-chart-position.service';
import { HorizontalBarChartScaleService } from './horizontal-bar-chart-scale.service';
import { HorizontalBarChartTextFormatterService } from './horizontal-bar-chart-text-formatter.service';
import { deriveNumberOfTicksToPutOnTheAxis, substr } from './utils';

@Injectable()
export class HorizontalBarChartAxisService {
    public readonly verticalAxisValueClick$: Observable<AxisClickEvent>;

    private readonly verticalAxisValueClickSubject: Subject<AxisClickEvent> = new Subject();
    private horizontalAxis: d3.Selection<SVGGElement, BarChartData, null, undefined> | undefined;
    private selectedSlicer: string | undefined;

    constructor(
        private readonly scales: HorizontalBarChartScaleService,
        private readonly textFormatter: HorizontalBarChartTextFormatterService,
    ) {
        this.verticalAxisValueClick$ = this.verticalAxisValueClickSubject.asObservable();
    }

    draw(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        chartPosition: ChartPosition,
        data: BarChartData[],
        slicerAxis: Axis,
        valueAxis: Axis,
        shouldNotTruncateValues = false,
    ): void {
        if (!valueAxis.hide) {
            this.horizontalAxis = this.drawHorizontalAxis(
                chartRootGSelection,
                valueAxis,
                data,
                chartPosition.widthForBars,
                chartPosition.heightForBars);
        } else {
            this.horizontalAxis = undefined;
        }

        if (!slicerAxis.hide) {
            this.drawVerticalAxis(
                chartRootGSelection,
                slicerAxis,
                data,
                chartPosition.widthForBars,
                chartPosition.heightForBars,
                shouldNotTruncateValues,
                chartPosition.slicerMaxCharacterCount);
        }
    }

    // this is done when the chart is hovered and a hover-line should be shown
    blurHorizontal(): void {
        if (!this.horizontalAxis) {
            return;
        }

        this.horizontalAxis.style('opacity', 0.3);
    }

    unblurHorizontal(): void {
        if (!this.horizontalAxis) {
            return;
        }

        this.horizontalAxis.style('opacity', 1);
    }

    private drawHorizontalAxis(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        config: Axis,
        data: BarChartData[],
        width: number,
        height: number,
    ): d3.Selection<SVGGElement, BarChartData, null, undefined> {
        const axisDrawer = configureD3AxisDrawer(
            data,
            'value',
            config,
            this.scales.toHorizontalAxis(),
            width,
            height);

        const axis = chartRootGSelection?.append('g')
            .attr('class', axisWrapperClassList('x', config.customClass))
            .attr('transform', `translate(0,${height})`)
            .call(axisDrawer);

        axis.selectAll<SVGTextElement, BarChartData>('text')
            .style('text-anchor', 'middle')
            .call(this.adjustHorizontalAxisTextValues.bind(this), config);

        return axis;
    }

    private drawVerticalAxis(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        config: Axis,
        data: BarChartData[],
        width: number,
        height: number,
        shouldNotTruncateValues = false,
        maximumAllowedCharCount: number,
    ): void {
        const axisDrawer = configureD3AxisDrawer(
            data,
            'key',
            config,
            this.scales.toLeftAxis(),
            width,
            height);

        const axis = chartRootGSelection.append('g')
            .attr('class', axisWrapperClassList('y', config.customClass))
            .call(axisDrawer);

        axis.selectAll<SVGTextElement, string>('text')
            .style('cursor', 'pointer')
            .on('click', (_, slicerValue) => {
                const bar = data.find((d) => d.key.value === slicerValue);

                if (bar) {
                    if (this.selectedSlicer === bar.key.value) {
                        this.selectedSlicer = undefined;
                        this.verticalAxisValueClickSubject.next({ data: bar, enabled: true });
                    } else {
                        this.selectedSlicer = bar?.key.value;
                        this.verticalAxisValueClickSubject.next({ data: bar, enabled: false });
                    }
                } else {
                    this.selectedSlicer = undefined;
                }
            })
            .call(this.adjustVerticalAxisTickValues.bind(this), config, shouldNotTruncateValues, maximumAllowedCharCount);
    }

    // since d3 is drawing our axis, it requires our real/source data.  so if we try to handle the case
    // where we do want an axis but do not want labels (see the comment on configureD3AxisDrawer) or
    // where our values are too long and need to be truncated by passing d3 data that is already adjusted
    // it cannot draw the axis correctly.  therefore, we need this function, to come back and undo some of
    // what d3 did for us
    private adjustHorizontalAxisTextValues(
        texts: d3.Selection<SVGTextElement, BarChartData, SVGGElement, BarChartData>,
        config: Axis,
    ): void {
        for (const textElement of texts) {
            const text = d3.select(textElement);
            const textStr: string = text.text();

            if (config.nTicks === 0) {
                text.text('');
            } else {
                text.text(this.textFormatter.formatValue(stringToNumber(textStr)));
            }
        }
    }

    private adjustVerticalAxisTickValues(
        texts: d3.Selection<SVGTextElement, string, SVGGElement, BarChartData>,
        config: Axis,
        shouldNotTruncateValues: boolean,
        maximumAllowedCharCount: number,
    ): void {
        for (const textElement of texts) {
            const text = d3.select(textElement);
            let textStr = text.text();
            const axisType = config.type;

            if (config.nTicks === 0) {
                text.text('');
            } else {
                if (axisType === 'numeric') {
                    text.text(this.textFormatter.formatValue(stringToNumber(textStr)));
                } else if (axisType === 'string') {
                    const numTicks = config.nTicks ?? 0;
                    if (textStr === '' && numTicks > 0) {
                        text.text('Blanks');
                        textStr = text.text();
                    }

                    let finalResultString = safeStringFormatter(textStr);
                    if (!shouldNotTruncateValues && finalResultString.length > maximumAllowedCharCount) {
                        // this really hard to read conditional is to figure out how much of the string to cut off
                        // such that when the ellipsis is added, the final length is equal to the maximumAllowedCharCount
                        const charCountLength = finalResultString.length - maximumAllowedCharCount > 4 ?
                            maximumAllowedCharCount - 4 :
                            maximumAllowedCharCount - (finalResultString.length - maximumAllowedCharCount);
                        finalResultString = `${substr(finalResultString, 0, charCountLength)}...`;
                    }

                    text.text(finalResultString);
                }
            }
        }
    }
}

// this function is a little odd.  in DDV we offer users the option to have
// an axis (so the line) but with no labels or ticks (nTicks === 0).
// we could just draw that line ourselves, but for some reason we are letting d3 do it
//
// this object is what is actually going to render and position the axis labels
// they end up as <g><line/><text/></g>
// where the <line/> is the tick mark and the <text/> is the value
function configureD3AxisDrawer<T>(
    data: BarChartData[],
    valueOrKey: 'value' | 'key',
    config: Axis,
    axis: d3.Axis<T>,
    width: number,
    height: number,
): d3.Axis<T> {
    // this is the cause were we have an axis but no labels.  that does not have its own flag
    // that is done in our config by setting the number of ticks to 0
    // nTicks CAN be undefined, so we are specifically looking for 0 here
    if (config.nTicks === 0) {
        axis.tickFormat(null);
        axis.tickSize(0);

        return axis;
    }

    // this is a cheap way to check if it's the vertical axis
    // the vertical axis is a discrete axis, so it needs to be configured differently for our visualizations to layout correctly
    if (valueOrKey === 'key') {
        axis.ticks(config.nTicks ?? 0);
    } else {
        axis.ticks(deriveNumberOfTicksToPutOnTheAxis(data, valueOrKey, config.position, width, height));
        axis.tickSize(6);
    }

    return axis;
}

function axisWrapperClassList(direction: 'x' | 'y', customClass: string | undefined): string {
    const classList: string[] = ['axis', `axis--${direction}`, `axis--${direction === 'x' ? 'horizontal' : 'vertical'}`];
    if (customClass) {
        classList.push(customClass);
    }

    return classList.join(' ');
}

export interface AxisClickEvent {
    data: BarChartData;
    enabled: boolean;
}
