import { trimTrailingZeros } from '@ddv/formatters';
import * as d3 from 'd3';
/* eslint-disable import/no-extraneous-dependencies */
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
/* eslint-enable import/no-extraneous-dependencies */

import { Axis } from '../models/axis';
import { ChartData } from '../models/chart-data';
import { ChartSettings } from '../models/chart-settings';
import { Element } from '../models/element';
import { EnabledAxis } from '../models/enabled-axis';
import { Margin } from '../models/margin';
import { ParentChartProperties } from '../models/parent-chart-properties';
import { PieChartDataModel } from '../models/pie-chart-data-model';
import { Series } from '../models/series';
import { safeStringFormatter } from '../safe-string-formatter';
import { DataSourceService } from './data-source.service';
import { LegendsService } from './legends.service';
import { MultiSeriesChartService } from './multi-series-chart.service';

export function stringToNumber(s: string): number {
    // remove anything that isn't a -, +, ., or digit
    return Number(s.replace(/[^-+\d.]/g, ''));
}

export abstract class BaseChartService {
    color: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    config: ChartSettings | undefined;
    /* HOLDS THE FORMATTED DATA FOR RESPECTIVE CHARTS */
    dataSource: ChartData[] | undefined;
    dataCompareSource: ChartData[] | undefined;
    protected margin: Margin = getDefaultMargin();
    protected width = 0;
    protected height = 0;
    protected x: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    protected y: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    protected yAxisRight: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number> | undefined;
    protected svg: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    protected ordinalScale: d3.ScaleBand<string> | undefined;
    protected lines: {
        key: string;
        path: d3Shape.Line<[number, number]>;
    }[] | undefined;
    protected area: d3.Area<[number, number]> | undefined;
    protected radius = 0;
    protected arc: d3.Arc<any, d3.DefaultArcObject> | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    protected labelArc: d3.Arc<any, d3.DefaultArcObject> | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    // pie is coming from d3 so it's of type any
    protected pie: any | undefined;  // eslint-disable-line @typescript-eslint/no-explicit-any
    protected stack: d3.Stack<any, { [key: string]: number }, string> | undefined;  // eslint-disable-line @typescript-eslint/no-explicit-any
    protected stackData: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    protected gBrush: any | undefined;  // eslint-disable-line @typescript-eslint/no-explicit-any
    protected brush: d3.BrushBehavior<unknown> | undefined;
    protected x2: d3.ScaleTime<number, number> | undefined;
    protected tickOffset = 75;
    protected legendsService: LegendsService;
    protected dataSourceService: DataSourceService;
    protected defaultColors = ['#6b486b', '#ff8c00', '#98abc5', '#8a89a6', '#7b6888', '#a05d56', '#d0743c'];
    protected calculatedWidth = 0;
    protected legendVisibility: 'show' | 'hide' = 'show';

    constructor() {
        this.legendsService = new LegendsService();
        this.dataSourceService = new DataSourceService();
        // Added this because in v6 of D3 the hyphen-minus was replaced with minus
        // that broke the negative values for our charts (which were previously using the hyphen-minus).
        // So now we are replacing the minus with a hyphen-minus again.
        setD3FormatDefaultLocale();
    }

    getChartData(): ChartData[] {
        return this.dataSource ?? [];
    }

    setLegendsVisibility(action: 'show' | 'hide'): void {
        this.legendsService.setLegendsVisibility(action);
    }

    resetChartDataSource(): void {
        this.dataSource = undefined;
    }

    updateColorRange(data: any[] | string[], isUserDefined?: boolean, hasColorSorting = false): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        const colorRange = this.config?.colorRange;
        this.setColorRange(data, colorRange, hasColorSorting);
        if (isUserDefined) {
            this.color = d3Scale.scaleOrdinal().range(colorRange ?? this.defaultColors);
        }
    }

    setColorRange(data: any[] | string[], colorRange: string[] | undefined, hasColorSorting = false): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if ((colorRange?.length ?? 4) <= 3 && !hasColorSorting) {
            let colorDomain: number[];
            switch (colorRange?.length) {
                case 1:
                    colorDomain = [data.length];
                    break;
                case 2:
                    colorDomain = [0, data.length];
                    break;
                case 3:
                    colorDomain = [0, data.length / 2, data.length];
                    break;
                default: colorDomain = [];
            }
            this.color = d3Scale.scaleLinear().domain(colorDomain)
                .interpolate((d3 as any).interpolateHcl) // eslint-disable-line @typescript-eslint/no-explicit-any
                .range(colorRange as any); // eslint-disable-line @typescript-eslint/no-explicit-any
        } else {
            let colors: string[] = [];

            if (hasColorSorting) {
                colors =
                    (data as any[]).filter((d: any) => this.config?.attributeCustomColors?.findIndex((c) => c[d.key] === d.color) === -1) // eslint-disable-line @typescript-eslint/no-explicit-any
                        .map((d: any) => d.color); // eslint-disable-line @typescript-eslint/no-explicit-any
            }

            const colorsToUse = hasColorSorting && colors.length >= 2 ? colors : (colorRange?.length ? colorRange : this.defaultColors);
            this.color = d3Scale.scaleOrdinal().range(colorsToUse);
        }
    }

    getColor(key: string, index: number, attributeCustomColors?: { [key: string]: string }[] | undefined): string {
        if (attributeCustomColors ?? this.config?.attributeCustomColors) {
            const customColor = attributeCustomColors ?
                attributeCustomColors.find((colorConfig) => !!colorConfig[key]) :
                this.config?.attributeCustomColors?.find((colorConfig) => !!colorConfig[key]);
            return customColor ? customColor[key] : this.color(index);
        }

        return this.color(index);
    }

    updateChartProperties(chartProperties: MultiSeriesChartService): void {
        this.config = chartProperties.config;
        this.svg = chartProperties.svg;
        this.x = chartProperties.x;
        this.y = chartProperties.y;
        this.yAxisRight = chartProperties.yAxisRight;
        this.margin = chartProperties.margin;
        this.ordinalScale = chartProperties.ordinalScale;
        this.height = chartProperties.height;
        this.color = d3Scale.scaleOrdinal().range(this.defaultColors);
    }

    protected initSvg(isMaximized = false): void {
        if (!this.config) {
            throw new Error('cannot initSvg without a config');
        }

        this.config.height = (this.config.selector as any).clientHeight || this.config.minHeight; // eslint-disable-line @typescript-eslint/no-explicit-any
        this.config.width = (this.config.selector as any).clientWidth || this.config.minWidth; // eslint-disable-line @typescript-eslint/no-explicit-any
        this.width = this.config.width ?? 0;
        this.height = (this.config.height ?? 0) - 10;
        if (this.svg) {
            d3.select(this.config.selector).select('svg').remove();
        }
        if (this.config.enableDynamicAxisLabelWidth) {
            this.updateMarginDynamic(isMaximized);
        }
        this.margin = this.computeMargin();
        this.svg = d3.select(this.config.selector).append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('transform', 'translate(0,10)')
            .append('g')
            .attr('transform', `translate(${this.margin.left},${this.margin.top})`);

        this.color = d3Scale.scaleOrdinal().range(this.defaultColors);
        this.calculatedWidth = this.getCalculatedWidth();
    }

    private updateMarginDynamic(isMaximized: boolean): void {
        if (this.config?.axis && this.config.axis.length > 1) {
            this.config.axis[1].forEach((axis) => {
                const field = axis.field;
                let longestTickValue: string | number | undefined;
                const tempDataSource = [...(this.config?.dataSource ?? []), ...(this.config?.dataCompareSource ?? [])];
                if (axis.type === 'numeric') {
                    const yDomain = d3Array.extent(tempDataSource, (d) => {
                        if (field.length <= 1) {
                            return d[field[0]];
                        }
                        if (d.total) {
                            return d.total;
                        }
                        return field.reduce((sum, column) => sum + ((d[column] as number) || 0), 0);
                    });
                    if (yDomain[0] === yDomain[1]) {
                        yDomain[0] = 0;
                    }
                    const domainScale = d3Scale.scaleLinear().domain(axis.domain.length ? axis.domain : yDomain);
                    let ticks = axis.domain ? domainScale.ticks() : domainScale.nice().ticks();
                    if (this.config?.formatter?.numberUnits) {
                        ticks = ticks.map((tick) => this.applyFormatter(tick));
                    }
                    longestTickValue = getStringValueWithLongestCharacterCount(ticks);
                } else if (axis.type === 'string') {
                    const yDomain = d3Scale.scaleBand().domain(tempDataSource.map((d) => d[field[0]]));
                    longestTickValue = getStringValueWithLongestCharacterCount(yDomain.domain()).toString() || 'Blanks';
                    let minimumDisplayCharCount = 16;
                    if (longestTickValue && longestTickValue.length > minimumDisplayCharCount) {
                        minimumDisplayCharCount = this.getDisplayCharCount(minimumDisplayCharCount);
                        longestTickValue = isMaximized ? longestTickValue : longestTickValue.substr(0, minimumDisplayCharCount);
                    }
                }
                if (longestTickValue) {
                    const longestTextLabel = d3.select(this.config?.selector ?? '').append('text')
                        .attr('class', 'tick text tickLabel')
                        .classed('hidden', true)
                        .text(longestTickValue);
                    const longestTextLabelWidth = (d3.select(this.config?.selector ?? '').select('.tickLabel').node() as SVGGraphicsElement)
                        .getBoundingClientRect().width + 1;
                    if (longestTextLabelWidth) {
                        this.setMarginDynamic(longestTextLabelWidth, axis);
                    }
                    longestTextLabel.remove();
                }
            });
        }
    }

    protected getCalculatedWidth(): number {
        const excludeValue = this.getExcludeValue(this.config?.series[0].type ?? '');
        const widthCheckFlag = this.config?.legend?.showCustom && this.config.legend.docked === 'right' && this.legendVisibility === 'show';
        return (widthCheckFlag ? this.width * 0.8 : this.width) - excludeValue;
    }

    protected initSvgPie(): void {
        const chartSettings = this.getChartSettings();
        const series: Series = chartSettings.series[0];
        this.initializeDimensions();
        const { outerRadius, innerRadius, labelRadius } = this.calculateRadii(chartSettings);
        this.initializeSvgElements(chartSettings);
        this.initializeArcsAndPie(series, outerRadius, innerRadius, labelRadius);
    }

    protected initAxis(): void {
        if (!this.config) {
            throw new Error('cannot initAxis with config');
        }

        const xConfig = this.config.axis[0][0];
        this.x = this.createAxis(xConfig);
        const tempDataSource = [...this.config.dataSource, ...(this.config.dataCompareSource ?? [])];
        if (xConfig.domain.length) {
            this.x.domain(xConfig.domain);
        } else {
            this.x.domain(d3Array.extent(tempDataSource, (d) => xConfig.field[0] === 'Date' || xConfig.field[0] === 'date' ?
                new Date(d[xConfig.field[0]]) :
                d[xConfig.field[0]]));
        }
        (this.config.axis[1]).forEach((yConfig) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let yAxis: any;
            if (yConfig.position === 'right') {
                this.yAxisRight = this.createAxis(yConfig);
                yAxis = this.yAxisRight;
            } else {
                this.y = this.createAxis(yConfig);
                yAxis = this.y;
            }

            if (this.config?.showMultipleSeries) {
                this.drawMultiSeries(yConfig, yAxis);
            } else {
                if (yConfig.domain.length) {
                    yAxis.domain(yConfig.domain);
                } else {
                    yAxis.domain(d3Array.extent(this.config?.dataSource ?? [], (d) => yConfig.field.length > 1 ?
                        d.total :
                        d[yConfig.field[0]]),
                    ).nice();
                }
            }
        });
    }

    protected prepareStackedChart(): void {
        if (!this.config) {
            throw new Error('cannot prepareStackedChart without config');
        }

        const series = this.config.series[0];
        this.prepareDataForStackChart(series);
        const domain = [
            this.config.negativeValue ? d3.min(this.stackData, (serie: number[]) => d3.min(serie, (d: any) => d[0])) : 0, // eslint-disable-line @typescript-eslint/no-explicit-any
            d3.max(this.stackData, (d: number[]) => d[0] + d[1]),
        ];
        if (series?.horizontal) {
            this.x.domain(domain).nice();
        } else {
            (this.y! || this.yAxisRight).domain(domain).nice();
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected drawMultiSeries(yConfig: Axis, yAxis: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (yConfig.domain.length) {
            yAxis.domain(yConfig.domain);
        } else {
            const tempDataSource = [...(this.dataSource ?? []), ...(this.dataCompareSource ?? [])];
            yAxis.domain([
                d3.min(tempDataSource, (c) => d3.min(c.values, (d) => d[yConfig.field[0]])),
                d3.max(tempDataSource, (c) => d3.max(c.values, (d) => d[yConfig.field[0]])),
            ]);
        }
    }

    protected createOrdinal(): void {
        if (!this.config) {
            throw new Error('cannot createOrdinal without config');
        }

        const offset = this.getChartOffsets();
        const tempDataSource = [...this.config.dataSource, ...(this.config.dataCompareSource ?? [])];
        if (this.config.series[0].horizontal) {
            const yConfig = this.config.axis[1][0];
            this.ordinalScale = this.y = d3Scale.scaleBand()
                // Domain is in reverse order because d3 views bottommost item as "first" and users
                // think of topmost item as "first"
                .domain(tempDataSource.reduceRight((domain: string[], d) => {
                    domain.push(d[yConfig.field[0]]);
                    return domain;
                }, []))
                .range([(this.height - offset.y), 0])
                .padding(0.3)
                .align(0.3);
        } else {
            const xConfig = this.config.axis[0][0];
            this.ordinalScale = this.x = d3Scale.scaleBand()
                .domain(tempDataSource.map((d) => d[xConfig.field[0]]))
                .range([0, this.calculatedWidth])
                .padding(0.3)
                .align(0.3);
        }
    }

    protected createAxis(axis: Axis): d3.ScaleTime<number, number> | d3.ScaleLinear<number, number> {
        let newAxis: d3.ScaleTime<number, number> | d3.ScaleLinear<number, number>;
        switch (axis.type) {
            case 'date':
                newAxis = d3Scale.scaleTime().range(this.getAxisPositions(axis));
                break;
            default:
                newAxis = d3Scale.scaleLinear().range(this.getAxisPositions(axis));
        }
        return newAxis;
    }

    protected getAxisPositions(axis: Axis): number[] {
        const offset = this.getChartOffsets();
        const rightMargin = this.getRightMargin();
        switch (axis.position) {
            case 'bottom':
            case 'top':
                return [0, this.calculatedWidth - rightMargin];
            default:
                return [this.height - offset.y, 0];
        }
    }

    protected drawAxis(isMaximized = false): void {
        if (!this.config) {
            throw new Error('cannot drawAxis without config');
        }

        let customClass = '';
        const offset = this.getChartOffsets();
        const xConfig = this.config.axis[0][0];
        if (!xConfig.hide) {
            customClass = xConfig.customClass ? (`axis axis--x ${xConfig.customClass}`) : 'axis axis--x';
            const axisPos = this.positionAxis(xConfig, this.x);
            const tickSize = this.x.domain().filter((d: any) => d); // eslint-disable-line @typescript-eslint/no-explicit-any
            const topAlign = xConfig.position === 'top' ? 0 : this.height - offset.y;
            const hasVerticalLabel = (tickSize.length > this.calculatedWidth / this.tickOffset) || xConfig.rotate;
            const labelPosition = xConfig.position === 'top' ? 'start' : 'end';
            const translatePos = xConfig.position === 'bottom' && xConfig.rotate === 270 ? -10 : 0;
            this.svg.append('g')
                .attr('class', customClass)
                .attr('transform', `translate(0,${topAlign})`)
                .call(axisPos)
                .selectAll('text')
                .style('text-anchor', hasVerticalLabel ? labelPosition : 'middle')
                .attr('transform', `rotate(${xConfig.rotate}) translate(${translatePos},${translatePos})`)
                .selectAll('.tick text')
                .call(this.wrapXAxisTickLabels, this.x, this); // eslint-disable-line @typescript-eslint/unbound-method
        }
        this.config.axis[1].forEach((yConfig) => {
            if (!yConfig.hide) {
                customClass = yConfig.customClass ? (`axis axis--y ${yConfig.customClass}`) : 'axis axis--y';
                const yAxis = this.svg.append('g').attr('class', customClass);
                if (yConfig.position === 'right') {
                    yAxis.call(this.positionAxis(yConfig, this.yAxisRight));
                    yAxis.attr('transform', `translate(${this.calculatedWidth},0)`)
                        .selectAll('text')
                        .selectAll('.tick text')
                        .call(this.wrapYAxisTickLabels, yConfig, isMaximized, this); // eslint-disable-line @typescript-eslint/unbound-method
                } else {
                    yAxis.call(this.positionAxis(yConfig, this.y))
                        .selectAll('text')
                        .selectAll('.tick text')
                        .call(this.wrapYAxisTickLabels, yConfig, isMaximized, this); // eslint-disable-line @typescript-eslint/unbound-method
                }
            }
        });
        if (this.config.showGridLines) {
            this.addGridLines();
        }
    }

    private getChartSettings(): ChartSettings {
        if (!this.config) {
            throw new Error('cannot initSvgPie without config');
        }
        return this.config;
    }

    private initializeDimensions(): void {
        if (this.config) {
            const series: Series = this.config.series[0];
            d3.select(this.config.selector).selectAll('*').remove();
            // selector is defined as string but it is an DOM element here
            this.config.height = (this.config.selector as any).clientHeight || this.config.minHeight; // eslint-disable-line @typescript-eslint/no-explicit-any
            this.config.width = (this.config.selector as any).clientWidth || this.config.minWidth; // eslint-disable-line @typescript-eslint/no-explicit-any
            this.width = this.config.width ?? 0;
            this.height = (this.config.height ?? 0) - 10;
            this.margin = this.computeMargin();
            this.color = d3Scale.scaleOrdinal().range(series.discreteColors?.length ? series.discreteColors : this.defaultColors);
        }
    }

    private calculateRadii(chartSettings: ChartSettings): { outerRadius: number, innerRadius: number, labelRadius: number } {
        const series: Series = chartSettings.series[0];
        const offset = this.getChartOffsets();
        const { width, height } = this.calculateDimensions(chartSettings);
        const outerRadius = this.calculateOuterRadius(series, width, height, offset, chartSettings.showLabels ?? false);
        const innerRadius = this.calculateInnerRadius(outerRadius, series, chartSettings.donutThreshold);
        const labelRadius = series.radius?.labelRadius ?? 0;

        return { outerRadius, innerRadius, labelRadius };
    }

    private calculateDimensions(chartSettings: ChartSettings): { width: number, height: number } {
        const width = chartSettings.legend?.showCustom && chartSettings.legend.docked === 'right' && this.width > 240
            && !document.getElementById('vizDataArea') ? this.width * 0.8 : this.width;
        const isLegendTopOrBottom = chartSettings.legend?.showCustom
            && (chartSettings.legend.docked === 'top' || chartSettings.legend.docked === 'bottom');
        const height = isLegendTopOrBottom ? this.height - 60 : this.height;

        return { width, height };
    }

    private calculateOuterRadius(
        series: Series,
        width: number,
        height: number,
        offset: { x: number, y: number },
        showLabels: boolean,
    ): number {
        if (series.radius?.plotWithRadius) {
            return series.radius.outer ?? 0;
        }

        let r: number;
        let buffer: number;
        const minRadiusWithLabels = 30;

        if (width < height) {
            r = width / 2;
            buffer = offset.y;
        } else {
            r = height / 2;
            buffer = offset.x;
        }

        if (!showLabels) {
            buffer = 10;
        }

        return r <= minRadiusWithLabels ? r : Math.max(minRadiusWithLabels, r - buffer);
    }

    private calculateInnerRadius(outerRadius: number, series: Series, donutThreshold?: { min?: number, max?: number }): number {
        let innerRadius = outerRadius * (series.radius?.inner ?? 0) / 100;
        const arcThickness = Math.floor(outerRadius - innerRadius);

        if (arcThickness < (donutThreshold?.min ?? 0)) {
            innerRadius = outerRadius - (donutThreshold?.min ?? 0);
        } else if (arcThickness > (donutThreshold?.max ?? 0)) {
            innerRadius = outerRadius - (donutThreshold?.max ?? 0);
        }

        return innerRadius;
    }

    private initializeSvgElements(chartSettings: ChartSettings): void {
        this.svg = d3.select(chartSettings.selector).append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('transform', 'translate(0,10)')
            .append('g')
            .attr('transform', `translate(${this.width / 2},${this.height / 2})`);
    }

    private initializeArcsAndPie(series: Series, outerRadius: number, innerRadius: number, labelRadius: number): void {
        if (series.type === 'donut') {
            this.arc = d3Shape.arc()
                .outerRadius(outerRadius)
                .innerRadius(innerRadius);
        } else {
            this.arc = d3Shape.arc()
                .outerRadius(outerRadius)
                .innerRadius(0);
        }
        this.radius = outerRadius;
        this.labelArc = d3Shape.arc()
            .outerRadius((outerRadius) + labelRadius)
            .innerRadius(innerRadius);
        this.pie = d3Shape.pie()
            .sort(null)
            .value((d: any) => d[series.yField[0]]); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private wrapXAxisTickLabels(texts: any, axis: any, chartService: BaseChartService): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        for (let text of texts._parents) {
            text = d3.select(text);
            const axisType = chartService.config?.axis[0][0].type;
            let textStr: string = text.text();

            if (!axis.bandwidth && axisType === 'numeric' && chartService.config?.formatter) {
                text.text(chartService.applyFormatter(stringToNumber(textStr)));
            } else if (axis.bandwidth && axisType === 'string') {
                if (textStr === '' && chartService.config?.axis[0][0].nTicks && chartService.config.axis[0][0].nTicks > 0) {
                    text.text('Blanks');
                    textStr = text.text();
                }

                textStr = safeStringFormatter(textStr);
                text.text(textStr);

                while (text.node().getComputedTextLength() > axis.bandwidth()) {
                    textStr = textStr.substr(0, (textStr.length - 1));
                    text.text(textStr);
                }
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected wrapYAxisTickLabels(texts: any, yConfig: Axis, isMaximized: boolean, chartService: BaseChartService): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        for (let text of texts._parents) {
            text = d3.select(text);
            let textStr = text.text();
            const axisType = yConfig.type;

            if (axisType === 'numeric' && !chartService.config?.series[0].mirror && chartService.config?.formatter) {
                text.text(chartService.applyFormatter(stringToNumber(textStr)));
            } else if (axisType === 'string') {
                const maximumAllowedCharCount = 16 + (chartService.width > 400 ? Math.floor((chartService.width - 400) / 9) : 0);
                if (textStr === '' && yConfig.nTicks && yConfig.nTicks > 0) {
                    text.text('Blanks');
                    textStr = text.text();
                }

                let finalResultString = safeStringFormatter(textStr);

                if (finalResultString.length > maximumAllowedCharCount) {
                    const charCountLength = finalResultString.length - maximumAllowedCharCount > 4 ?
                        maximumAllowedCharCount - 4 :
                        maximumAllowedCharCount - (finalResultString.length - maximumAllowedCharCount);
                    finalResultString = isMaximized ? finalResultString : `${finalResultString.substr(0, charCountLength)}...`;
                }

                text.text(finalResultString);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected positionAxis(axisConfig: Axis, axis: any): any { // eslint-disable-line @typescript-eslint/no-explicit-any
        let newAxis: any; // eslint-disable-line @typescript-eslint/no-explicit-any
        switch (axisConfig.position) {
            case 'bottom':
                newAxis = d3Axis.axisBottom(axis);
                this.createAxisLabel(axisConfig, (-1 * (this.calculatedWidth / 2)), (-1 * (this.height - 40)), 0);
                break;
            case 'top':
                newAxis = d3Axis.axisTop(axis);
                this.createAxisLabel(axisConfig, (this.calculatedWidth / 2), this.margin.top, 0);
                break;
            case 'left':
                newAxis = d3Axis.axisLeft(axis);
                this.createAxisLabel(axisConfig, (this.height / 2), this.margin.left, -90);
                break;
            case 'right':
                newAxis = d3Axis.axisRight(axis);
                this.createAxisLabel(axisConfig, (this.height / 2), this.calculatedWidth, -90);
        }
        if ((axisConfig.nTicks ?? 0) >= 0) {
            if (axisConfig.nTicks === 0) {
                newAxis.tickFormat('');
                newAxis.tickSize(0);
            }
            newAxis.ticks(axisConfig.nTicks);
        }
        if (axisConfig.autoTicks) {
            newAxis.ticks(this.getTickSize(axisConfig));
        }
        if (axisConfig.nTicks === 0) {
            newAxis.ticks(axisConfig.nTicks);
        }
        return newAxis;
    }

    getTickSize(axisConfig: Axis): number {
        const dataDependentDimension = this.getDataDependentDimension(axisConfig.field[0]);

        const dimension = axisConfig.position === 'bottom' || axisConfig.position === 'top' ?
            this.calculatedWidth :
            this.height - this.margin.top - this.margin.bottom;
        const tickSize = (dimension / this.tickOffset) - dataDependentDimension > 1 ?
            dataDependentDimension :
            dimension / this.tickOffset;
        return Math.max(tickSize, this.config!.parentSelector?.includes('line-chart') ? 3 : 2);
    }

    private getDataDependentDimension(field: string): number {
        let dataDependentDimension = 2;

        this.config?.dataSource.forEach((data) => {
            dataDependentDimension = dataDependentDimension < data.total ?
                data.total :
                (dataDependentDimension < data[field] ?
                    data[field] :
                    dataDependentDimension);
        });

        return dataDependentDimension;
    }

    private createAxisLabel(axisConfig: Axis, x: number, y: number, rotate: number): void {
        this.svg.append('text')
            .attr('transform', `rotate(${rotate})`)
            .attr('y', 0 - y)
            .attr('x', 0 - x)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .text(axisConfig.label);
    }

    private prepareDataForStackChart(series: Series): void {
        this.stack = d3.stack()
            .keys(series.horizontal ? series.xField : series.yField)
            .offset(d3.stackOffsetDiverging);
        if (this.config?.dataSource) {
            this.stackData = this.stack(this.config.dataSource);
        } else {
            this.stackData = [];
        }
        const columns = series.horizontal ? series.xField : series.yField;
        this.config?.dataSource.forEach((d) => {
            d.total = columns.reduce((sum, column) => sum + ((d[column] as number) || 0), 0);
            d.posTotal = columns.reduce((sum, column) => sum + ((d[column] as number) > 0 ? (d[column] as number) : 0), 0);
            d.negTotal = columns.reduce((sum, column) => sum + ((d[column] as number) < 0 ? (d[column] as number) : 0), 0);
        });
    }

    prepareData(params: ChartSettings | PieChartDataModel): void {
        const chartData = this.dataSourceService.prepareChartData(params, this);
        const series = params.series[0];
        const mirrored = series?.mirror;
        const multiSeriesLine = series && series.type === 'line' && params.showMultipleSeries;
        const hasColorSorting = !!(params.selectedSlicer?.colorSortBy && params.selectedSlicer?.colorSortDirection);
        this.updateColorRange(chartData, (mirrored || multiSeriesLine) as any, hasColorSorting); // eslint-disable-line @typescript-eslint/no-explicit-any
        this.dataSource = chartData;

        if ('dataCompareSource' in params && params.dataCompareSource) {
            this.dataCompareSource = this.dataSourceService.prepareChartData(params, this, true);
        }
    }

    protected resize(): void {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.svg.attr('width', this.width).attr('height', this.height);
    }

    protected applyConfigs(): void {
        if (this.config?.legend?.showCustom) {
            this.legendsService.setDefaults({
                svg: this.svg,
                config: this.config,
                dataSource: this.dataSource ?? this.config.dataSource,
            });
            this.legendsService.createCustomLegends();
        }

        if (this.config?.showCurveValues) {
            this.addInlineValues();
        }
    }

    getParentChartProperties(): ParentChartProperties {
        return {
            config: this.config as any, // eslint-disable-line @typescript-eslint/no-explicit-any
            svg: this.svg,
            x: this.x,
            y: this.y,
            yAxisRight: this.yAxisRight,
            margin: this.margin,
            legendVisibility: this.legendVisibility,
        };
    }

    addInlineValues(): void {
        if (!this.config) {
            throw new Error('cannot addInlineValues without config');
        }

        const series = this.config.series[0];
        const hiddenLines = this.svg.selectAll('.line.hidden').data();
        this.svg.selectAll('g.labels-group').remove();
        (series.yField).forEach((f, index: number) => {
            let yAxis = this.y || this.yAxisRight;
            if ((this.config?.axis[1][index] && this.config.axis[1][index].position === 'right')) {
                yAxis = this.yAxisRight;
            }
            let data;
            if (series.yField.length > 1) {
                data = this.dataSource?.filter((d) => d.key === series.yField[index]);
            }
            const xMax = this.x.domain()[1];
            let gp;
            if (this.config?.showMultipleSeries) {
                gp = this.svg.selectAll('.labels-group')
                    .data(data ? data : this.dataSource)
                    .enter()
                    .append('g')
                    .attr('class', () => 'labels-group')
                    .attr('data-legend', (d: any) => d.key) // eslint-disable-line @typescript-eslint/no-explicit-any
                    .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`);
            } else {
                gp = this.svg.selectAll(`.labels-group.yAxis${index}`)
                    .data(data ? data : this.dataSource)
                    .enter()
                    .append('g')
                    .attr('class', () => `labels-group yAxis${index}`)
                    .attr('data-legend', (d: any) => d.key) // eslint-disable-line @typescript-eslint/no-explicit-any
                    .attr('clip-path', () => `url(#${this.config?.parentSelector}-clip)`);
            }
            gp.selectAll('text')
                .data((d: any) => d.values) // eslint-disable-line @typescript-eslint/no-explicit-any
                .enter()
                .append('text')
                .attr('dy', '.35em')
                .text((d: any) => this.applyFormatter(d[f])) // eslint-disable-line @typescript-eslint/no-explicit-any
                .classed('label', true)
                .attr('transform', (d: any, i: number, textList: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                    const textBox = textList[i].getBBox();
                    const pos = this.getLabelsPosition(d[series.xField[0]], d[f], yAxis, textBox);
                    return `translate(${pos[0]},${pos[1]})`;
                })
                .text((d: any, i: number, textList: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                    const label = this.applyFormatter(d[f]);
                    if (this.config?.showInflectionPoints) {
                        const data1 = textList.map((d1: any) => d1.__data__[f]); // eslint-disable-line @typescript-eslint/no-explicit-any
                        const minIndex = data1.reduce((iMin: any, x: any, j: any, arr: any) => x < arr[iMin] ? j : iMin, 0); // eslint-disable-line @typescript-eslint/no-explicit-any
                        const maxIndex = data1.reduce((iMax: any, x: any, j: any, arr: any) => x > arr[iMax] ? j : iMax, 0); // eslint-disable-line @typescript-eslint/no-explicit-any
                        const showLabel = this.isInflectionPoint(i, data1, minIndex, maxIndex, yAxis);
                        return showLabel ? label : '';
                    } else {
                        const textBox = textList[i].getBBox();
                        const pos = this.getLabelsPosition(d[series.xField[0]], d[f], yAxis, textBox);
                        const isEllipsis = pos[0] + (textBox.width as number) > this.x(xMax);
                        return isEllipsis ? `${label.toString().substring(0, 3)}...` : label;
                    }
                })
                .attr('class', 'inchart-label')
                .append('svg:title')
                .text((d: any) => this.applyFormatter(d[f])); // eslint-disable-line @typescript-eslint/no-explicit-any
            if (series.type === 'line') {
                hiddenLines.forEach((line: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                    this.svg.selectAll('.labels-group').filter((el: any) => el.key === line.key) // eslint-disable-line @typescript-eslint/no-explicit-any
                        .classed('hidden', true);
                });
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    isInflectionPoint(index: number, textList: any, minIndex: number, maxIndex: number, yAxis: any): boolean { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (index === textList.length - 1) {
            return false;
        }
        if (index === 0 || index === minIndex || index === maxIndex) {
            return true;
        }
        const nextText = textList[index + 1];
        const currentText = textList[index];
        const yAxisDomain = yAxis.domain();
        const inflectedPointPercent = (Math.abs(nextText - currentText) / Math.abs(yAxisDomain[1] - yAxisDomain[0])) * 100;
        return inflectedPointPercent >= (this.config?.inflectionPointPercent ?? 0);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getLabelsPosition(xField: any, yField: any, yAxis: any, text: any): number[] { // eslint-disable-line @typescript-eslint/no-explicit-any
        const textHeight = text.height;
        const textWidth = text.width;
        const xMax = this.x.domain()[1];
        const yMax = yAxis.domain()[1];
        const xPos = this.x(xField);
        const xMaxPos = this.x(xMax);
        const xPadding = (xPos as number) + (textWidth as number) < xMaxPos ? 5 : -30;
        const yPadding = yAxis(yField) - textHeight >= yAxis(yMax) ? -10 : 10;
        const x = (xPos as number) + xPadding;
        const y = (yAxis(yField) as number) + yPadding;
        return [x, y];
    }

    createNegativeAxis(): void {
        const offset = this.getChartOffsets();
        const series = this.config?.series[0];
        const customClass = series?.mirror ? 'negative-axis' : null;
        const yAxis = this.y || this.yAxisRight;
        if (this.config?.negativeValue) {
            if (series?.horizontal) {
                this.svg.append('g')
                    .attr('class', `x axis ${customClass}`)
                    .append('line')
                    .attr('y2', this.height - offset.y)
                    .attr('x1', this.x(0))
                    .attr('x2', this.x(0));
            } else {
                this.svg.append('g')
                    .attr('class', `x axis ${customClass}`)
                    .append('line')
                    .attr('y1', yAxis(0))
                    .attr('y2', yAxis(0))
                    .attr('x2', this.calculatedWidth);
            }
        }
    }

    addGridLines(): void {
        const offset = this.getChartOffsets();

        if (!(this.config?.gridLines?.x as any).hide) { // eslint-disable-line @typescript-eslint/no-explicit-any
            const height = this.height - offset.y;
            const xGrid = this.svg.append('g')
                .attr('class', 'grid')
                .attr('transform', `translate(0,${height})`)
                .call(this.makeXGridLines(height));
            const customClass = (this.config?.gridLines?.x as any).customClass; // eslint-disable-line @typescript-eslint/no-explicit-any
            this.applyGridLineProps(xGrid, 'x-gridlines', customClass);
        }

        if (!(this.config?.gridLines?.y as any).hide) { // eslint-disable-line @typescript-eslint/no-explicit-any
            const yGrid = this.svg.append('g')
                .attr('class', 'grid')
                .call(this.makeYGridLines(this.calculatedWidth));
            const customClass = (this.config?.gridLines?.y as any).customClass; // eslint-disable-line @typescript-eslint/no-explicit-any
            this.applyGridLineProps(yGrid, 'y-gridlines', customClass);
        }
    }

    makeXGridLines(height: number): d3.Axis<d3.AxisDomain> {
        const axisConfig = this.config?.axis[0][0];
        if ((this.config?.gridLines?.x as any).autoTicks) { // eslint-disable-line @typescript-eslint/no-explicit-any
            (this.config?.gridLines?.x as any).nTicks = this.getTickSize(axisConfig as any); // eslint-disable-line @typescript-eslint/no-explicit-any
        }
        const gridLinesCount = axisConfig?.autoTicks ? this.getTickSize(axisConfig) : (this.config?.gridLines?.x as any).nTicks; // eslint-disable-line @typescript-eslint/no-explicit-any
        const grid = d3.axisBottom(this.x)
            .ticks(gridLinesCount)
            .tickSize(-height);
        if (!(this.config?.gridLines?.x as any).showLabels) { // eslint-disable-line @typescript-eslint/no-explicit-any
            grid.tickFormat(() => '');
        }
        return grid;
    }

    makeYGridLines(width: number): d3.Axis<d3.AxisDomain> {
        const y = this.y || this.yAxisRight;
        const axisConfig = this.config!.axis[1][0];
        if ((this.config!.gridLines!.y as any).autoTicks) { // eslint-disable-line @typescript-eslint/no-explicit-any
            (this.config!.gridLines!.y as any).nTicks = this.getTickSize(axisConfig); // eslint-disable-line @typescript-eslint/no-explicit-any
        }
        const gridLinesCount = axisConfig.autoTicks ? this.getTickSize(axisConfig) : (this.config!.gridLines!.y as any).nTicks; // eslint-disable-line @typescript-eslint/no-explicit-any
        const grid = d3.axisLeft(y)
            .ticks(gridLinesCount)
            .tickSize(-width);
        if (!(this.config!.gridLines!.y as any).showLabels) { // eslint-disable-line @typescript-eslint/no-explicit-any
            grid.tickFormat(() => '');
        }
        return grid;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    applyGridLineProps(grid: any, gridLineClass: any, customClass: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        grid.selectAll('line')
            .attr('class', gridLineClass)
            .classed(customClass, true);
        grid.select('path')
            .attr('stroke', 'none');
    }

    protected getTooltipDiv(styleClass?: string): d3.Selection<HTMLDivElement, unknown, HTMLElement, any> { // eslint-disable-line @typescript-eslint/no-explicit-any
        d3.select('body').selectAll('.toolTip').remove();
        return d3.select('body').append('div').attr('class', `toolTip ${styleClass ?? ''}`);
    }

    protected getChartOffsets(): { x: number, y: number } {
        return {
            x: this.margin.left + this.margin.right,
            y: this.margin.top + this.margin.bottom + (this.config?.showBrush ? (this.config.brushSize ?? 0) : 0),
        };
    }

    protected computeMargin(): { top: number, bottom: number, left: number, right: number } {
        const computedMgn: any = { top: 0, bottom: 0, left: 0, right: 0 }; // eslint-disable-line @typescript-eslint/no-explicit-any
        for (const key in computedMgn) {
            if (Object.prototype.hasOwnProperty.call(computedMgn, key)) {
                const multiplier = (key === 'top' || key === 'bottom') ? this.height : this.width;
                const margin = this.config?.margin as any; // eslint-disable-line @typescript-eslint/no-explicit-any
                computedMgn[key] = margin[key].min ?
                    Math.max(margin[key].min, (margin[key].max / 100 * multiplier)) :
                    margin[key];
            }
        }
        return computedMgn;
    }

    onChartClicked(elt: Element, chartUpdated: boolean): void {
        onChartClicked(elt, chartUpdated, this.config, this.dataSource);
    }

    protected dateToString(val: Date | string, format?: string): string {
        const parseDate = d3.timeFormat(format ?? '%x');
        return (val instanceof Date) ? parseDate(val) : val;
    }

    protected appendRectArea(): void {
        const height = this.height - this.margin.top - this.margin.bottom;
        const width = this.calculatedWidth;
        this.svg.append('svg:rect')
            .attr('x', 0).attr('y', 0)
            .attr('width', width < 0 ? this.config?.minWidth : width)
            .attr('height', height > 0 ? height : this.config?.minHeight)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .attr('class', 'rectdrawarea');
    }

    protected appendLine(): void {
        const xAxisFocus = this.svg.append('g').attr('class', 'focus--x');
        const yAxisFocus = this.svg.append('g').attr('class', 'focus--y');
        xAxisFocus.append('line').attr('pointer-events', 'none');
        yAxisFocus.append('line').attr('pointer-events', 'none');
    }

    protected appendClipPath(): void {
        const height = this.height - this.margin.top - this.margin.bottom;

        this.svg.append('clipPath')
            .attr('id', `${this.config?.parentSelector}-clip`)
            .attr('transform', 'translate(0,0)')
            .append('rect')
            .attr('width', this.getCalculatedWidth())
            .attr('height', this.config?.showBrush ? height - (this.config.brushSize ?? 0) : height);
    }

    protected removeClipPath(): void {
        const clipPathNode = d3.select(`#${this.config?.parentSelector}-clip`);
        if (clipPathNode.node()) {
            clipPathNode.remove();
        }
    }

    protected changeLinePosition(
        lineParallelToHorizontalAxis: boolean,
        lineParallelToVerticalAxis: boolean,
        mousePoint: [number, number],
        enableAxis: EnabledAxis,
        mirrored?: boolean,
        mirrorY1Axis?: boolean,
        mirrorY2Axis?: boolean,
    ): void {
        const xAxis = this.x;
        const yAxis = this.y || this.yAxisRight;
        const xAxisClass = this.svg.select('g.axis--x');
        const yAxisClass = this.svg.selectAll('g.axis--y');
        let yAxisClassWidth = yAxisClass.node().getBBox().width;
        yAxisClassWidth = (yAxisClassWidth > 0 && this.config?.axis[1][0].nTicks !== 0) ? yAxisClassWidth : this.config?.margin?.left;
        this.svg.selectAll('.focusvalue').remove();
        const focusValue = this.svg.append('g').attr('class', 'focusvalue');
        const y2Position = this.height - this.margin.bottom - 25;
        const xAxisValue = lineParallelToVerticalAxis ? xAxis.invert(mousePoint[0]) : '';
        const yAxisValue = lineParallelToHorizontalAxis ? yAxis.invert(mousePoint[1]) : '';
        const isDate: boolean = xAxisValue instanceof Date;
        let verticalTranslatePos: string;
        let horizontalTranslatePos: string;
        if (lineParallelToVerticalAxis) {
            verticalTranslatePos = this.config?.axis[0][0].position === 'top' ?
                `translate(${xAxis(xAxisValue) - 10},0)` : `translate(${xAxis(xAxisValue) - 25},${y2Position + 10})`;
            this.svg.select('.focus--y line')
                .attr('x1', xAxis(xAxisValue))
                .attr('x2', xAxis(xAxisValue))
                .attr('y1', 0)
                .attr('y2', y2Position)
                .attr('opacity', 1)
                .attr('stroke', 'block')
                .attr('class', 'tooltip-line');
            focusValue.selectAll('.y-value').remove();
            focusValue.append('g')
                .attr('class', 'y-value')
                .attr('transform', verticalTranslatePos)
                .append('text')
                .text(isDate ? this.dateToString(xAxisValue, '%m/%d') : this.applyFormatter(xAxisValue));
            xAxisClass.style('opacity', 0.3);
        }
        if (lineParallelToHorizontalAxis) {
            this.svg.select('.focus--x line')
                .attr('x1', 0)
                .attr('x2', this.calculatedWidth)
                .attr('y1', yAxis(yAxisValue))
                .attr('y2', yAxis(yAxisValue))
                .attr('opacity', 1)
                .attr('stroke', 'block')
                .attr('class', 'tooltip-line');
            focusValue.selectAll('.x-value').remove();
            focusValue.selectAll('.v-axishiddentext').remove();
            focusValue.append('text')
                .attr('class', 'v-axishiddentext')
                .style('visibility', 'hidden')
                .text(this.applyFormatter(yAxisValue));
            const textWidth = focusValue.selectAll('.v-axishiddentext').node().getComputedTextLength();
            const textWidthDiff = yAxisClassWidth - textWidth;
            const xposition = yAxisClassWidth - textWidthDiff + 5;
            horizontalTranslatePos = this.config?.axis[1][0].position === 'right' ?
                `translate(${this.calculatedWidth + 10},${yAxis(yAxisValue)})` : `translate(${-xposition},${yAxis(yAxisValue)})`;
            if (enableAxis.left) {
                focusValue.append('g')
                    .attr('class', 'x-value')
                    .attr('transform', horizontalTranslatePos)
                    .append('text')
                    .text(this.yAxisMousePoint(yAxisValue, mirrored, mirrorY1Axis, mirrorY2Axis));
                yAxisClass.style('opacity', 0.3);
            }
            if (enableAxis.right) {
                const yAxisRightValue = this.yAxisRight!.invert(mousePoint[1]) as number;
                focusValue.append('g')
                    .attr('class', 'x-value')
                    .attr('transform', `translate(${this.calculatedWidth + 10},${yAxis(yAxisValue)})`)
                    .append('text')
                    .text(this.yAxisMousePoint(yAxisRightValue, mirrored, mirrorY1Axis, mirrorY2Axis));
                yAxisClass.style('opacity', 0.3);
            }
        }
    }

    protected revertLinePosition(lineParallelToHorizontalAxis: boolean, lineParallelToVerticalAxis: boolean): void {
        const xAxisClass = this.svg.select('g.axis--x');
        const yAxisClass = this.svg.selectAll('g.axis--y');
        this.svg.selectAll('.focusvalue').remove();
        if (lineParallelToHorizontalAxis) {
            this.svg.selectAll('.focus--x line').attr('opacity', 0);
            yAxisClass.style('opacity', 1);
        }
        if (lineParallelToVerticalAxis) {
            this.svg.select('.focus--y line').attr('opacity', 0);
            xAxisClass.style('opacity', 1);
        }
    }

    protected convertNameToId(name: string): string {
        return name ? name.replace(/[^a-zA-Z\d]/g, '') : name;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected applyFormatter(value: any): any { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.config?.formatter) {
            if (typeof value === 'number') {
                return this.formatNumber(value);
            }
        }
        return value;
    }

    private yAxisMousePoint(yAxisValue: number, mirrored?: boolean, mirrorY1Axis?: boolean, mirrorY2Axis?: boolean): number {
        let newYAxisValue = yAxisValue;
        if (mirrored) {
            newYAxisValue = newYAxisValue * (((newYAxisValue > 0 && mirrorY1Axis) || (newYAxisValue < 0 && mirrorY2Axis)) ? -1 : 1);
        }
        return this.applyFormatter(newYAxisValue);
    }

    private formatNumber(numberToFormat: number): string {
        const defaultDecimalPos = this.config?.formatter?.numberUnits ? 1 : 2;
        let decimalPlaces = this.config?.formatter?.decimalPlaces ?? defaultDecimalPos;
        let prefix = '';
        let suffix = '';
        let formattedNumber = numberToFormat;

        switch (this.config?.formatter?.numberFormat) {
            case 'XX':
                decimalPlaces = 0;
                break;
            case '$':
                prefix = `$${prefix}`;
                break;
            case '%':
                formattedNumber = formattedNumber * 100;
                suffix += '%';
        }

        switch (this.config?.formatter?.numberUnits) {
            case 'billions':
                formattedNumber = formattedNumber / 1e9;
                suffix = `B ${suffix}`;
                break;
            case 'millions':
                formattedNumber = formattedNumber / 1e6;
                suffix = `M ${suffix}`;
                break;
            case 'thousands':
                formattedNumber = formattedNumber / 1e3;
                suffix = `K ${suffix}`;
        }

        const formatter = d3.format(`,.${decimalPlaces}f`);
        let formatted = formatter(formattedNumber);

        if ((this.config?.formatter?.numberFormat === 'XX.00 Trim')) {
            formatted = trimTrailingZeros(formatted);
        }

        return `${prefix}${formatted}${suffix.trim()}`;
    }

    private getDisplayCharCount(charCount: number): number {
        return charCount + (this.width > 400 ? Math.floor((this.width - 400) / 9) : 0);
    }

    private setMarginDynamic(longestTextLabelWidth: number, axis: Axis): void {
        if (!this.config?.margin) {
            return;
        }

        (this.config.margin.left as any) += axis.position === 'left' && axis.customClass !== 'hide' && // eslint-disable-line @typescript-eslint/no-explicit-any
            (axis.nTicks ?? 0) > 0 ? longestTextLabelWidth : 0;
        (this.config.margin.right as any) += axis.position === 'right' && axis.customClass !== 'hide' && // eslint-disable-line @typescript-eslint/no-explicit-any
            (axis.nTicks ?? 0) > 0 ? longestTextLabelWidth : 0;
    }

    private getRightMargin(): number {
        if (this.config?.series[0].type === 'line' || this.config?.series[0].type === 'stacked-area') {
            return 0;
        }
        return this.calculatedWidth > 350 ? 35 : (this.calculatedWidth > 250 ? 25 : 15);
    }

    private getExcludeValue(type: string): number {
        let excludeValue = 0;
        this.config?.axis[1].forEach((axis) => {
            if (axis.position === 'left') {
                excludeValue += this.margin.left;
                return;
            }
            if (axis.position === 'right') {
                excludeValue += this.margin.right;
                return;
            }
        });

        if (type === 'stacked-area') {
            return excludeValue + this.margin.right;
        }
        return excludeValue;
    }
}

export function getDefaultMargin(): Margin {
    return {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    };
}

export function setD3FormatDefaultLocale(): void {
    d3.formatDefaultLocale({
        decimal: '.',
        grouping: [3],
        currency: ['$', ''],
        minus: '-',
        thousands: ',',
    });
}

export function getStringValueWithLongestCharacterCount(inputArray: (string | number)[]): string | number {
    return inputArray.reduce((longest, item) => {
        // According to Yuri, the +1 is to handle the fact that some characters are rendered larger than others
        // so character length does not necessarily translate directly on screen width
        // the theory is that if its TWO characters longer then its definitely longer on screen
        // after that, the regex below is meant adjust for "special characters" in some way
        if (item?.toString().length > longest.toString().length + 1) {
            return item.toString();
        }

        if (item?.toString().length >= longest.toString().length) {
            if (item.toString().replace(/[^A-Z]/g, '').length > longest.toString().replace(/[^A-Z]/g, '').length) {
                return item.toString();
            }
        }

        return longest;
    }, '');
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function onChartClicked(elt: Element, chartUpdated: boolean, config: any, dataSource: any): void { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (typeof config?.onChartClicked === 'function') {
        config.onChartClicked(dataSource ?? [], elt, chartUpdated);
    }
}
