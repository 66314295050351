<div
    [id]="widgetConfig!.id"
    class="ngPopup widget"
    [ngClass]="(widgetConfig!.styleClasses + ' ' + widgetConfig!.selectedClassName).trim()"
    [class.view-mode]="mode === 'view'"
    [style.display]="widgetConfig!.display"
    [style.width]="widgetConfig!.width+'px'"
    [style.height]="widgetConfig!.height+'px'"
    [style.top]="widgetConfig!.top+'px'"
    [style.left]="widgetConfig!.left+'px'"
    [style.position]="widgetConfig!.position"
    [style.z-index]="widgetConfig!.zIndex"
    mwlResizable
    [enableGhostResize]="canResize()"
    (resizeStart)="onResizeStart()"
    (resizing)="onResizing($event)"
    (resizeEnd)="onResizeEnd($event)"
    (mousedown)="onSelectWidget($event)"
>
    <div class="container widget-wrapper" #widget>
        <div
            class="titleBar widget-header"
            (dblclick)="onHeaderDoubleClick()"
            appWidgetDrag
            [isDraggable]="(isWidgetDraggable())"
            [dragImagePath]="widgetConfig!.dragImagePath ?? ''"
            (dragStart)="onDragStart()"
            (dragEnd)="onDragEnd($event)"
        >
            <span class="title widget-title">
                {{ widgetConfig!.title }}
                @if (isMasterWidget()) {
                    <app-master-widget-icon></app-master-widget-icon>
                }
                @if (isUnsubscribedWidget()) {
                    <app-unsubscribed-widget-icon></app-unsubscribed-widget-icon>
                }
            </span>

            <app-loader [isDataLoading]="showLoader" [isWidgetLoader]="true"></app-loader>

            <div class="widget-actions">
                @for (menuOption of widgetConfig!.menuOptions; track $index) {
                    <div
                        class="menu-options {{'iconGroup '+menuOption.selector+((menuOption.disabled && !menuOption.iconName) ? ' disabled' : '')}}"
                        title="{{menuOption.title}}"
                    >
                        @if (!menuOption.hidden && (!menuOption.styleClasses || menuOption.styleClasses.indexOf('stacked-area') === -1)) {
                            @if (!menuOption.iconName) {
                                <i
                                    id="{{menuOption.selector}}"
                                    class="{{menuOption.styleClasses}}"
                                    [ngClass]="{'readonly': menuOption.selector === 'closeBtn' && isWorkspaceReadonly || menuOption.selector === 'settingsOverridesBtn' && isWorkspaceReadonly && mode === 'edit'}"
                                    (dblclick)="onMenuItemDoubleClick($event)"
                                    (click)="getMenuItemClickHandler(menuOption, $event)"
                                >
                                </i>
                            } @else {
                                <cc-icon
                                    id="{{menuOption.selector}}"
                                    class="cc-widget-header-icon"
                                    [icon]="menuOption.iconName!"
                                    [size]="menuOption.iconName === 'chevron-down2' ? 'medium' : 'small'"
                                    [disabled]="menuOption.disabled || (menuOption.selector === 'closeBtn' && isWorkspaceReadonly || menuOption.selector === 'settingsOverridesBtn' && isWorkspaceReadonly && mode === 'edit')"
                                    (dblclick)="onMenuItemDoubleClick($event)"
                                    (click)="getMenuItemClickHandler(menuOption, $event)"
                                >
                                </cc-icon>
                            }
                        }

                        @if (!menuOption.hidden && menuOption.styleClasses && menuOption.styleClasses.indexOf('stacked-area') !== -1) {
                            <ddv-stacked-area-chart-icon
                                id="{{menuOption.selector}}"
                                class="{{menuOption.styleClasses}}"
                                [ngClass]="{'readonly': menuOption.selector === 'closeBtn' && isWorkspaceReadonly || menuOption.selector === 'settingsOverridesBtn' && isWorkspaceReadonly && mode === 'edit'}"
                                (dblclick)="onMenuItemDoubleClick($event)"
                                (click)="getMenuItemClickHandler(menuOption, $event)"
                            >
                            </ddv-stacked-area-chart-icon>
                        }
                    </div>
                }

                @if (showExportOption) {
                    <div class="iconGroup menu-options">
                        <cc-icon
                            class='cc-widget-header-icon'
                            [icon]="'logout'"
                            [size]="'small'"
                            title="CSV Export"
                            [disabled]="showLoader"
                            (iconClicked)="exportWidget()"
                        >
                        </cc-icon>
                    </div>
                }

                @if (isGrid && areFiltersApplied) {
                    <div class="iconGroup">
                        <cc-icon
                            class="cc-widget-header-icon"
                            [icon]="'clear-filter'"
                            [size]="'small'"
                            title="Clear All Filters"
                            (iconClicked)="clearAllFilters()"
                        >
                        </cc-icon>
                    </div>
                }
            </div>
        </div>

        <div #widgetContentWrapper class="content" [style.display]="widgetContentDisplay">
            <div #widgetContent></div>
            </div>
    </div>

    @if (canResize()) {
        <div class="resize-icon-right" mwlResizeHandle [resizeEdges]="{right: true}">
            <span class="icon-resize-arrow"></span>
        </div>

        <div class="resize-icon-corner" mwlResizeHandle [resizeEdges]="{right: true, bottom: true}">
            <span class="icon-resize-arrow"></span>
        </div>

        <div class="resize-icon-bottom" mwlResizeHandle [resizeEdges]="{bottom: true}">
            <span class="icon-resize-arrow"></span>
        </div>
    }
</div>

<ng-template #removeWidgetTemplate>
    <div class="remove-message">{{ widgetRemovalMessage }}</div>
    <div class="remove-widget">{{ widgetConfig!.title }}</div>
</ng-template>
