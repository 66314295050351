import { Injectable } from '@angular/core';
import { ConfirmDialogOptions } from '@ddv/models';
import { ConfirmationService, ModalAction } from '@hs/ui-core-notifications';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmationPopupService {
    constructor(private readonly confirmationService: ConfirmationService) { }

    showConfirmationPopup(confirmDialogOptions: ConfirmDialogOptions, backdrop = false): Observable<ModalAction | undefined> {
        const message = confirmDialogOptions.message ?? 'You are working on an older version.';
        const confirmButtonText = confirmDialogOptions?.confirmButtonText ?? 'Refresh';
        const denyButtonText = confirmDialogOptions?.denyButtonText ?? 'Cancel';

        return this.confirmationService.launch(
            message,
            {
                confirmButtonText,
                denyButtonText,
                canCloseCallback: (action) => !backdrop || (backdrop && action !== 'dismiss'),
            });
    }
}
