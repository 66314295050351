import { NgModule, ModuleWithProviders } from '@angular/core';

import { ModalDialogOverlayComponent } from './modal-dialog-overlay.component';
import { ModalDialogStack } from './modal-dialog-stack.service';
import { ModalDialogWindowComponent } from './modal-dialog-window.component';
import { ModalDialogService } from './modal-dialog.service';

@NgModule({
    declarations: [
        ModalDialogOverlayComponent,
        ModalDialogWindowComponent,
    ],
    providers: [
        ModalDialogService,
    ],
})
export class ModalDialogModule {
    static forRoot(): ModuleWithProviders<ModalDialogModule> {
        return {
            ngModule: ModalDialogModule,
            providers: [
                ModalDialogService,
                ModalDialogStack,
            ],
        };
    }
}
