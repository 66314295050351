import { Injectable } from '@angular/core';
import { DashboardDetails } from '@ddv/models';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class DashboardDetailsRelayService {
    public readonly currentDashboardDetails: Observable<DashboardDetails>;
    private readonly currentDashboardDetailsSubject: Subject<DashboardDetails> = new ReplaySubject(1);

    constructor() {
        this.currentDashboardDetails = this.currentDashboardDetailsSubject.asObservable();
    }

    relay(dashboardDetails: DashboardDetails): void {
        this.currentDashboardDetailsSubject.next(dashboardDetails);
    }
}
