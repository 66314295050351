import { Injectable } from '@angular/core';

import { ErrorLogger } from './error-logger';

@Injectable()
export class BasicErrorLogger implements ErrorLogger {
    logError(_: string, error: Error | unknown): void {
        if (Object.prototype.hasOwnProperty.call(error, 'message')) {
            console.error(error);
        }
    }
}
