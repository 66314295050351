export const enum CrosstalkFields {
    CommentCounter = 'crosstalkCommentCounter',
    ClientComment = 'lastClientComment',
    HSComment = 'lastHedgeservComment',
    Attachments = 'attachments',
}

export const enum TrebekConversationFields {
    ClientComment = 'conversationLastClientComment',
    HSComment = 'conversationLastHedgeservComment',
}

export const crosstalkCommentFields: [CrosstalkFields.HSComment, CrosstalkFields.ClientComment] =
[CrosstalkFields.HSComment, CrosstalkFields.ClientComment];

export const trebekRealTimeCommentFields = [TrebekConversationFields.HSComment, TrebekConversationFields.ClientComment] as string[];

export const crosstalkCheckboxFieldId = 'checkbox-field-gp56FSHEeTVl3pfe2isq';

export const crosstalkColumns = [
    { columnId: TrebekConversationFields.HSComment },
    { columnId: `${TrebekConversationFields.HSComment}Author` },
    { columnId: `${TrebekConversationFields.HSComment}Created` },
    { columnId: TrebekConversationFields.ClientComment },
    { columnId: `${TrebekConversationFields.ClientComment}Author` },
    { columnId: `${TrebekConversationFields.ClientComment}Created` },
];
