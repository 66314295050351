import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BehaviorsModule } from '@ddv/behaviors';
import { HttpModule } from '@ddv/http';
import { CheckboxComponent } from '@hs/ui-core-inputs';

import { ActiveDatePickerDialogComponent } from './active-date-picker-dialog/active-date-picker-dialog.component';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './alert/alert.service';
import { CalendarListViewComponent } from './calendar-list-view/calendar-list-view.component';
import { FilterDatesPipe } from './calendar-list-view/filter-dates.pipe';
import { CalendarComponent } from './calendar/calendar.component';
import { ConfirmationPopupService } from './confirmation-dialog/confirmation-popup.service';
import { CustomAutoCompleterComponent } from './custom-autocompleter/custom-autocompleter.component';
import { DateRangePickerDialogComponent } from './date-range-picker-dialog/date-range-picker-dialog.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { HiDatesService } from './hi-dates.service';
import { ListBrowserComponent } from './list-browser/list-browser.component';
import { ListBrowserFilter } from './list-browser/list-browser.pipe';
import { LoaderComponent } from './loader/loader.component';
import { ModalDialogModule } from './modal-dialog/modal-dialog.module';
import { SingleDatePickerDialogComponent } from './single-date-picker-dialog/single-date-picker-dialog.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { WidgetDeleteAlertComponent } from './widget-delete-alert/widget-delete-alert.component';

@NgModule({
    imports: [
        BehaviorsModule,
        CommonModule,
        FormsModule,
        HttpModule,
        ModalDialogModule.forRoot(),
        RouterModule,
        ScrollingModule,
        CheckboxComponent,
    ],
    declarations: [
        ActiveDatePickerDialogComponent,
        AlertComponent,
        CalendarComponent,
        CalendarListViewComponent,
        CustomAutoCompleterComponent,
        DatepickerComponent,
        DateRangePickerComponent,
        DateRangePickerDialogComponent,
        DropdownComponent,
        ErrorModalComponent,
        FilterDatesPipe,
        ListBrowserComponent,
        ListBrowserFilter,
        LoaderComponent,
        SingleDatePickerDialogComponent,
        TypeaheadComponent,
        WidgetDeleteAlertComponent,
    ],
    exports: [
        ActiveDatePickerDialogComponent,
        AlertComponent,
        CalendarComponent,
        CalendarListViewComponent,
        CustomAutoCompleterComponent,
        DatepickerComponent,
        DateRangePickerComponent,
        DateRangePickerDialogComponent,
        DropdownComponent,
        ErrorModalComponent,
        ListBrowserComponent,
        ListBrowserFilter,
        LoaderComponent,
        SingleDatePickerDialogComponent,
        TypeaheadComponent,
        WidgetDeleteAlertComponent,
    ],
    providers: [
        AlertService,
        ConfirmationPopupService,
        HiDatesService,
    ],
})
export class CommonComponentsModule {}
