import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    BarChartService,
    ChartComponent,
    ChartData,
    ChartDataRelayService,
    ChartSettings,
    DrillInfo,
    Element,
    FixedColumn,
    Series,
    SeriesChartColumn,
    SlicerInfo,
    VizTableTotal,
} from '@ddv/charts';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { DataGridComponent, GridConfiguration } from '@ddv/data-grid';
import { MetadataService } from '@ddv/datasets';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import { DashboardFilter, DashboardPreference, DataRange, DdvDate, ExportFilteredData, FieldMetadataMap } from '@ddv/models';
import { deepClone } from '@ddv/utils';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { allFrameworkComponents } from '../../grids/all-framework-components';
import { BigWTableComponent } from './big-w-table/big-w-table.component';
import { ChartsSharedService } from './charts-shared.service';
import { VizInfo } from './visualization-wrapper.interface';
import { VisualizationWrapperService } from './visualization-wrapper.service';

@Component({
    selector: 'app-viz-wrapper',
    templateUrl: 'visualization-wrapper.component.html',
    styleUrls: ['visualization-wrapper.component.scss'],
})
export class VisualizationWrapperComponent extends MultiSubscriptionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() widgetId = 0;
    @Input() vizInfo: VizInfo | undefined;
    @Input() vizConfig: ChartSettings | undefined;

    @Output() gridReady = new EventEmitter<Event>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allFrameworkComponents: Record<string, new (...args: any[]) => any> = allFrameworkComponents;
    @ViewChild('chartViz', { static: false }) chartComponent: ChartComponent | undefined;
    @ViewChild('dataGrid', { static: false }) gridComponent: DataGridComponent | undefined;
    @ViewChild('bigWTable', { static: false }) bigWTableComponent: BigWTableComponent | undefined;

    slicerInfo: SlicerInfo | undefined;
    chartConfig: ChartSettings | undefined;
    gridConfig: GridConfiguration | undefined;
    vizChartData: ChartData[] = [];
    vizTableTotals: VizTableTotal[] = [];
    maximized = false;
    restore = false;
    showVizTable = false;
    noDataAvailable = false;
    filterRemovedByMaster = false;
    isPITChart = false;
    isStacked = false;
    isMirrored = false;
    isGridViz = false;
    isMaster = false;
    fixedColumn: FixedColumn | undefined;
    seriesChartColumnDetails: SeriesChartColumn[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableData: any[] = [];
    queryParams: DashboardPreference | undefined;
    isDataLoading = true;
    mirrorY1AxisMultiplier = 1;
    mirrorY2AxisMultiplier = 1;
    enableCompareMode = false;
    isManualToggle = false;

    private fieldsByWidgetId: Map<number, FieldMetadataMap> | undefined;

    private subscription: Subscription | undefined;
    private readonly unsubscribeNotifier$ = new Subject<void>();

    constructor(
        private readonly managerService: ManagerService,
        private readonly cdr: ChangeDetectorRef,
        private readonly vizWrapperService: VisualizationWrapperService,
        private readonly chartsSharedService: ChartsSharedService,
        private readonly metadataService: MetadataService,
        private readonly queryParamsService: QueryParamsService,
        private readonly chartDataRelay: ChartDataRelayService,
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.widgetId) {
            return console.warn('Widget id is missing.');
        }

        if (!this.vizConfig) {
            return console.warn('Visualization config is missing.');
        }

        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (dashboardPref: DashboardPreference) => {
            this.queryParams = dashboardPref;

            const widgetState = this.managerService.getWidgetPreferences(this.widgetId);
            this.isMaster = !!widgetState?.isMaster;
            this.enableCompareMode = widgetState?.enableCompareMode ?? false;

            const masterAppliedFilter = this.queryParams.filters?.find((filter) => filter.isMasterWidgetFilter);

            if (this.isMaster && (!this.queryParams.areFiltersAppliedByMaster || this.filterRemovedByMaster)) {
                this.isGridViz = false;
                this.initVisualization();
                this.drawBigWChart(true);
                this.filterRemovedByMaster = false;
                this.restoreMasterWidgetHighlight();
            }

            if (this.isMaster && !this.queryParams.highlight && masterAppliedFilter) {
                this.subscribeToMasterWidgetChartDataOnLoad(masterAppliedFilter);
            }
        });

        this.subscribeTo(this.metadataService.metadataState, (datasetMetadata) => {
            this.fieldsByWidgetId = datasetMetadata.metadata;
        });

        this.initVisualization();
    }

    // This is the magic.  This relies on the outer BaseVisualizationComponent to set properties
    // of the object that is bound to this.vizConfig INCLUDING the dataSource.
    ngOnChanges(changes: SimpleChanges): void {
        // First change is undefined to a value.
        if (!changes.vizConfig || changes.vizConfig.isFirstChange()) {
            return;
        }

        const isMaster = this.managerService.getWidgetPreferences(this.widgetId)?.isMaster;
        if (isMaster && this.queryParams?.areFiltersAppliedByMaster && (this.queryParams.filters?.length ?? 0) > 0) {
            return;
        }

        this.isGridViz = false;
        this.initVisualization();
        this.drawBigWChart(true);

        if (isMaster) {
            this.restoreMasterWidgetHighlight();
        }
    }

    override ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.unsubscribeNotifier$.next();
            this.unsubscribeNotifier$.complete();
        }

        super.ngOnDestroy();
    }

    initVisualization(): void {
        if (!this.vizInfo || !this.vizConfig) {
            return console.error('cannot initVisualization without a vizInfo and a vizConfig');
        }

        if (this.isGrid(this.vizConfig)) {
            this.isGridViz = true;
            this.showVizTable = false;
            this.gridConfig = this.vizConfig;
        } else {
            this.isGridViz = false;
            this.slicerInfo = {
                slicer: { ...this.vizInfo.slicer },
                values: [...this.vizInfo.values],
            };
            this.noDataAvailable = !this.vizConfig.dataSource || this.vizConfig.dataSource.length === 0;
            if (this.vizConfig.enableDrilldown && this.vizConfig.drilldown) {
                this.vizConfig.drilldown.onLevelListClicked = this.onLevelListClicked.bind(this);
            }
            this.vizConfig.onChartClicked = this.onChartClicked.bind(this);
            if (this.managerService.getCurrentDashboardFilters().length && this.filterRemovedByMaster) {
                const filteredRows = this.getFilteredRows();
                if (filteredRows.length) {
                    this.vizConfig.dataSource = filteredRows;
                }
            }
            this.chartConfig = this.vizConfig;
            if (this.vizInfo.slicer?.colorSortBy && this.vizInfo.slicer.colorSortDirection) {
                this.chartConfig.selectedSlicer = this.vizInfo.slicer;
            }
            this.afterChartInit();
        }
    }

    resizeVisualization(): void {
        if (this.chartComponent) {
            // reaching into private properties of the CDR seems really smart
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!(this.cdr as any).destroyed) {
                this.cdr.detectChanges();
            }
            this.reAssignMargin(this.chartComponent.getState());
            this.chartComponent.resizeChart((this.maximized && this.showVizTable) ? 'hide' : 'show');
        }
    }

    onMaximize(): void {
        const widgetState = this.managerService.getWidgetPreferences(this.widgetId);
        this.enableCompareMode = !!widgetState?.enableCompareMode;
        this.maximized = true;
        this.restore = false;
        if (this.isGridViz) {
            this.resizeVisualization();
        } else {
            if (!this.chartComponent) {
                return;
            }
            this.showVizTable = !this.isManualToggle;
            this.updateVizDataTable();
            this.drawBigWChart(false);
        }
    }

    onRestore(): void {
        this.maximized = false;
        this.restore = true;
        this.showVizTable = false;
        this.isManualToggle = false;
        if (this.isGridViz) {
            this.resizeVisualization();
        } else {
            this.drawBigWChart(false);
        }
    }

    onGridPrepared(): void {
        this.gridReady.emit();
    }

    resizeBrush(timeline: { notation: string, scale?: number }): void {
        if (this.chartComponent) {
            this.chartComponent.resizeBrush(timeline);
        }
    }

    onChartClicked(dataSource: ChartData[], selectedItem: Element, chartUpdated: boolean): void {
        if (this.maximized && chartUpdated) {
            this.updateVizDataTable();
            this.bigWTableComponent?.redrawBigWTable();
        }

        const widgetState = this.managerService.getWidgetPreferences(this.widgetId);

        if (!selectedItem.data && !selectedItem.key) {
            this.updateActiveDateOnMasterWidget(selectedItem.date, !!widgetState?.isMaster);
            return;
        }

        const highlightValue: string = selectedItem.key ?? selectedItem.data.key;
        const isAttNameAdd = (this.queryParams?.highlight && this.queryParams.highlight === highlightValue);

        if (widgetState?.isMaster) {
            let activeDate = selectedItem.date || selectedItem.data.date;
            activeDate = activeDate && DdvDate.isStringValidDashFormatDate(activeDate) ?
                DdvDate.fromDashFormat(activeDate.toString()).toUSPaddedFormat() :
                this.queryParams?.activeDate;
            this.toggleSelectedSlicerToFilter(selectedItem, chartUpdated, activeDate, highlightValue != null ? highlightValue : undefined);
        } else {
            const masterFilters = this.queryParams?.filters?.findIndex((filter) => filter.isMasterWidgetFilter);

            this.queryParamsService.dispatchUpdatedQueryParams({
                highlight: isAttNameAdd ? '' : highlightValue,
                areFiltersAppliedByMaster: masterFilters !== -1,
            });
            this.managerService.sendMessageToAllWidgetsOnWorkspace(
                this.widgetId,
                {
                    action: 'VISUALIZATION_SELECTED',
                    dataSource,
                    selectedItem: isAttNameAdd ? null : selectedItem,
                    field: this.vizConfig?.multiSeries ? this.vizConfig.multiSeries.field : '',
                    chartUpdated: isAttNameAdd,
                });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onLevelListClicked(dataSource: any[], chartUpdated: boolean): void {
        if (this.maximized && chartUpdated) {
            this.updateVizDataTable();
            this.bigWTableComponent?.redrawBigWTable();
        }

        const filters = this.queryParams?.filters;
        const drillInfo = this.chartComponent?.getDrillInfo();
        let masterFilters = filters?.filter((filter) => {
            const resultFilter = (drillInfo?.level ?? 0) > 0 ? drillInfo?.list.filter((key) => {
                return (filter.values as string[]).includes(key.label);
            }) : [];
            return !filter.isMasterWidgetFilter || (resultFilter?.length ?? 0) > 0;
        });

        if (this.isMaster && masterFilters?.length === 0) {
            masterFilters = deepClone(this.managerService.getCurrentDashboardFilters());
            this.filterRemovedByMaster = true;
        }

        this.queryParamsService.dispatchUpdatedQueryParams({ filters: this.isMaster ? masterFilters : filters });
    }

    getState(): ChartSettings | undefined {
        return this.chartComponent ? this.chartComponent.getState() : this.vizConfig;
    }

    setState(state: ChartSettings): void {
        this.chartComponent?.setState(state);
    }

    getDrillInfo(): DrillInfo | undefined{
        return this.chartComponent?.getDrillInfo();
    }

    setDrillInfo(info: DrillInfo): void {
        if (this.chartComponent) {
            this.chartComponent.setDrillInfo(info);
        }
    }

    toggleVizDataArea(): void {
        this.showVizTable = !this.showVizTable;
        this.isManualToggle = !this.isManualToggle;
        this.drawBigWChart(false);
        this.updateVizDataTable();
    }

    getExportFilteredData(): ExportFilteredData {
        return this.getExportData();
    }

    getExportFullData(): ExportFilteredData {
        return this.getExportData();
    }

    prepareLineOrStackedAreaExportData(chartType?: string): ExportFilteredData {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rows: any[] = [];
        this.fixedColumn?.values.forEach((fixedColData) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const rowData: any = {};
            rowData[this.fixedColumn?.key ?? ''] = chartType === 'line' || chartType === 'stacked-area' ?
                DdvDate.fromDate(new Date(fixedColData)).toUSPaddedFormat() :
                fixedColData;
            this.seriesChartColumnDetails.forEach((columnDetail) => {
                columnDetail.key = columnDetail.key == null ? 'Null' : columnDetail.key.toString().trim() ? columnDetail.key : 'Blanks';
                rowData[columnDetail.key] = columnDetail.values[fixedColData];
            });
            rows.push(rowData);
        });
        return { data: rows, summary: this.getRowFooter() };
    }

    prepareStackedExportData(): ExportFilteredData {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rows: any[] = [];
        this.vizChartData.forEach((chartData) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const rowData: any = {};
            rowData[this.fixedColumn?.key ?? ''] = chartData.key;
            this.seriesChartColumnDetails.forEach((columnDetail) => {
                columnDetail.key = columnDetail.key == null ? 'Null' : columnDetail.key.toString().trim() ? columnDetail.key : 'Blanks';
                rowData[columnDetail.key] = columnDetail.values[chartData.key];
            });
            rows.push(rowData);
        });
        return { data: rows, summary: this.getRowFooter() };
    }

    preparePieAndDonutExportData(): ExportFilteredData {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rows: any[] = [];
        this.vizChartData.forEach((chartData) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const rowData: any = {};
            rowData[this.slicerInfo?.slicer.value ?? ''] = chartData.key == null ?
                'Null' :
                chartData.key.toString().trim() ? chartData.key : 'Blanks';
            this.slicerInfo?.values.forEach((value) => {
                rowData[value.value ?? ''] = chartData[value.value ?? ''];
            });
            rows.push(rowData);
        });
        return { data: rows, summary: this.getRowFooter() };
    }

    // eslint-disable-next-line complexity
    private toggleSelectedSlicerToFilter(elt: Element, chartUpdated: boolean, activeDate: string, highlight = ''): void {
        if (this.vizConfig?.series?.[0].type === 'line' && this.vizConfig.series[0].yField.length > 1) {
            this.queryParamsService.dispatchUpdatedQueryParams({ activeDate });
            return;
        }

        let filterValue = elt.key ?? elt.data.key;
        if (filterValue === 'others') {
            filterValue = this.getFilterValueForOthers(elt);
        }

        let filterName = this.vizConfig?.multiSeries ? this.vizConfig.multiSeries.field : this.slicerInfo?.slicer.value;
        if (this.vizConfig?.enableDrilldown) {
            filterName = this.getFilterNameForDrillDown(elt);
        }
        if (this.checkForStackedBarSlicerMaster(elt.slicer)) {
            filterName = elt.slicer;
        }

        let toggle = false;

        const index = this.queryParams?.filters?.findIndex((filter) => filter.isMasterWidgetFilter && filter.name === filterName) ?? -1;

        let resetDrillDown =
            !!(this.vizConfig?.enableDrilldown &&
            index > -1 &&
            (this.queryParams?.filters?.[index]?.values as string[]).indexOf(highlight) > -1);

        if (index !== -1) {
            toggle = this.setToggle(filterValue, index);
            if (this.vizConfig?.enableDrilldown && chartUpdated && toggle && index !== (this.vizConfig.drilldown?.keys.length ?? 0) - 1) {
                toggle = false;
            }

            const filterToRemove = this.queryParams?.filters?.[index];
            this.queryParamsService.dispatchUpdatedQueryParams({
                filters: this.getFilters(filterToRemove),
            });
            this.filterRemovedByMaster = true;
            resetDrillDown = false;
        }

        if (highlight !== undefined && filterValue !== 'others' && !toggle) {
            if (this.queryParams?.filters?.some((f) => f.name === filterName)) {
                this.queryParams?.filters?.splice(this.queryParams?.filters.findIndex((f) => f.name === filterName), 1);
            }

            this.updateFilters(filterName, filterValue);

            this.filterRemovedByMaster = false;
        }

        this.dispatchQueryParams(resetDrillDown, activeDate, highlight);
    }

    private checkForStackedBarSlicerMaster(slicer: string | null | undefined): boolean {
        return !!slicer && !this.isMirroredChart(this.chartConfig?.series[0]);
    }

    private getFilterDisplayName(key?: string): string | undefined {
        const widgetInfo = this.managerService.getWidgetPreferences(this.widgetId);
        return this.fieldsByWidgetId?.get(widgetInfo?.id ?? 0)?.[key ?? ''].displayName;
    }

    private setToggle(filterValue: string | string[], index: number): boolean {
        return Array.isArray(filterValue) ?
            filterValue.every((value) => this.queryParams?.filters?.[index].values?.indexOf(value) !== -1) :
            this.queryParams?.filters?.[index].values?.indexOf(filterValue) !== -1;
    }

    private redrawChart(): void {
        if (this.isGridViz || !this.chartComponent || !this.chartConfig) {
            return;
        }
        this.chartComponent.resetChartDataSource();
        const series = this.chartConfig.series[0];
        if (this.isStackedChart(series)) {
            if (series.horizontal) {
                this.chartConfig.axis[0][0].field = this.chartConfig.series[0].xField = [this.vizInfo?.values[0].value ?? ''];
            } else {
                this.chartConfig.axis[1][0].field = this.chartConfig.series[0].yField = [this.vizInfo?.values[0].value ?? ''];
            }
        }
        if (this.isLineChart(series)) {
            const axisConfigs = this.managerService.getWidgetPreferences(this.widgetId)?.visualizationConfigs[0].axisConfigurations;
            if (axisConfigs) {
                axisConfigs.slice(1).forEach((axisConfig, index) => {
                    if (axisConfig.dataRangeMode === DataRange.MANUAL) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        this.chartConfig!.axis[1][index].domain = [axisConfig.minDataRange, axisConfig.maxDataRange];
                    }
                });
            }
        }
        this.reAssignMargin(this.chartConfig);
        this.chartComponent.initChart(this.chartConfig);
        if (this.maximized && this.showVizTable) {
            this.updateVizDataTable();
        }
    }

    private reAssignMargin(vizModel?: ChartSettings): void {
        const yAxis = vizModel?.axis[1][0];
        if (yAxis && vizModel?.enableDynamicAxisLabelWidth) {
            const marginCondition = this.chartsSharedService.getMarginCondition(
                vizModel.legend?.showCustom ? vizModel.legend?.docked : '',
                yAxis.position ?? '',
                !yAxis.hide,
                (yAxis.nTicks ?? 0) > 0);
            if (vizModel.margin) {
                vizModel.margin.left = marginCondition.leftSideAxisFlag ? 5 : 25;
            }

            if (vizModel.margin) {
                vizModel.margin.right = marginCondition.rightSideAxisFlag ? 15 : 35;
            }
        }
    }

    private prepareVizData(): void {
        if (!this.chartConfig) {
            return console.error('cannot prepareVizData without chartConfig');
        }

        const chartSeries = this.chartConfig.series[0];
        const isHorizontal = this.isHorizontalChart(chartSeries);
        const horizontalField = isHorizontal ? this.chartConfig.series[0].yField[0] : this.chartConfig.series[0].xField[0];
        this.isPITChart = this.isPIT(chartSeries);
        this.isStacked = this.isStackedChart(chartSeries);
        this.isMirrored = this.isMirroredChart(chartSeries);
        this.vizChartData = this.chartComponent ? this.chartComponent.getChartData() : [];
        if (this.isMirrored && this.chartComponent) {
            const currentChartService = this.chartComponent.getCurrentChartService();
            const mirrorY1Axis = (currentChartService as BarChartService).mirrorY1Axis;
            const mirrorY2Axis = (currentChartService as BarChartService).mirrorY2Axis;
            this.mirrorY1AxisMultiplier = mirrorY1Axis ? -1 : 1;
            this.mirrorY2AxisMultiplier = mirrorY2Axis ? -1 : 1;
        }
        this.tableData = this.vizChartData;
        let label = horizontalField;
        let drillDown;
        let drillDownInfo;
        if (this.isPITChart) {
            label = this.slicerInfo?.slicer.label ?? '';
            if (this.chartConfig.enableDrilldown) {
                drillDown = this.chartConfig.drilldown;
                drillDownInfo = this.getDrillInfo();
            }
        }
        this.fixedColumn = this.vizWrapperService.getFixedColumn(
            this.isPITChart,
            this.chartConfig.dataSource,
            this.vizChartData,
            label,
            drillDown,
            drillDownInfo);
        if (!this.isPITChart && !this.isStacked) {
            this.seriesChartColumnDetails = this.vizWrapperService.getSeriesChartTableColumnDetails(
                this.vizChartData,
                this.slicerInfo,
                this.fixedColumn.key,
                !!this.chartConfig.showMultipleSeries);
            this.tableData = this.seriesChartColumnDetails;
        }

        if (chartSeries.type === 'stacked-area') {
            this.setTotalColumnForStackedAreaChartExportData();
        }

        this.vizTableTotals = this.vizWrapperService.getVizTableTotals(
            this.isPITChart,
            this.slicerInfo,
            this.isPITChart ? this.vizChartData : this.seriesChartColumnDetails,
            this.isStacked);
        if (!this.isPITChart && this.isStacked) {
            const stackedField = this.chartConfig.multiSeries?.field ?? '';
            this.seriesChartColumnDetails = this.vizWrapperService.getStackedChartTableColumnDetails(
                this.slicerInfo,
                stackedField,
                this.vizChartData);
            const seriesTotalColumnDetails = this.vizWrapperService.getTotalStackDetails(this.vizChartData);
            this.seriesChartColumnDetails.push(seriesTotalColumnDetails);
            this.tableData = this.seriesChartColumnDetails;
            this.vizTableTotals = this.vizWrapperService.getVizTableTotals(
                this.isPITChart,
                this.slicerInfo,
                this.seriesChartColumnDetails,
                this.isStacked);
        }
    }

    private setTotalColumnForStackedAreaChartExportData(): void {
        const total: Record<string, number> = {};

        this.fixedColumn?.values.forEach((column) => {
            total[column] = this.tableData.reduce((sum: number, data) => sum + ((data.values[column] as number) || 0), 0);
        });

        this.tableData.push({
            key: 'Total',
            values: total,
            color: undefined,
            label: null,
        });
    }

    private updateVizDataTable(): void {
        if (this.isGridViz || !this.showVizTable) {
            return;
        }

        this.chartComponent?.setLegendsVisibility('hide');
        this.vizChartData = this.chartComponent?.getChartData() ?? [];
    }

    private drawBigWChart(redraw: boolean): void {
        if (this.noDataAvailable) {
            return;
        }
        if (redraw) {
            this.redrawChart();
        }
        this.resizeVisualization();
    }

    private isGrid(vizModel?: VizModel): vizModel is GridConfiguration {
        return (vizModel as GridConfiguration).columnDefinitions !== undefined;
    }

    private afterChartInit(): void {
        const widgetState = this.managerService.getWidgetState(this.widgetId);
        if (widgetState?.maximized) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (!(this.cdr as any).destroyed) {
                this.cdr.detectChanges();
            }
            if (!this.chartComponent && !this.gridComponent) {
                return;
            }
            this.onMaximize();
            this.resizeVisualization();
        }
    }

    private getRowFooter(): object {
        const rowFooter: Record<string, string | number> = {};
        rowFooter[this.slicerInfo?.slicer.value ?? ''] = '';
        this.vizTableTotals.forEach((total) => {
            if (total) {
                total.key = total.key == null ? 'Null' : total.key.toString().trim() ? total.key : 'Blanks';
                rowFooter[total.key] = total.totalValue;
            }
        });
        return rowFooter;
    }

    private getExportData(): ExportFilteredData {
        this.prepareVizData();
        if (!this.tableData.length) {
            return { data: this.tableData, summary: {} };
        }
        const chartType = this.chartConfig?.series[0].type;
        if (this.isPITChart) {
            return this.preparePieAndDonutExportData();
        }
        if (chartType === 'line' || this.isMirrored || chartType === 'stacked-area') {
            return this.prepareLineOrStackedAreaExportData(chartType);
        }
        if (this.isStacked) {
            return this.prepareStackedExportData();
        }
        return { data: this.tableData, summary: {} };
    }

    private updateActiveDateOnMasterWidget(date: Date | undefined, isMaster: boolean): void {
        if (isMaster && date) {
            this.queryParamsService.dispatchUpdatedQueryParams({
                activeDate: DdvDate.fromDate(date).toUSPaddedFormat(),
            });
        }
    }

    private subscribeToMasterWidgetChartDataOnLoad(masterAppliedFilter: DashboardFilter): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = this.chartDataRelay.chartData
            .pipe(takeUntil(this.unsubscribeNotifier$))
            .subscribe((chartData) => {
                const othersDataField = chartData?.find((data) => data.key === 'others');
                const filterLength = masterAppliedFilter.values?.length ?? 0;
                if (othersDataField) {
                    if (filterLength > 1) {
                        this.updateMasterAppliedFiltersOnLoad(masterAppliedFilter, othersDataField);
                    } else if (filterLength === 1 && !chartData.find((data) => data.key === masterAppliedFilter.values?.[0])) {
                        this.updateMasterAppliedFiltersOnLoad(masterAppliedFilter, othersDataField);
                    } else {
                        this.unsubscribeNotifier$.next();
                        this.unsubscribeNotifier$.complete();
                    }
                }
            });
    }

    private updateMasterAppliedFiltersOnLoad(masterAppliedFilter: DashboardFilter, othersDataField: ChartData): void {
        if (othersDataField.children && masterAppliedFilter.values?.length !== othersDataField.children.length) {
            const key = this.findKey(othersDataField);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            masterAppliedFilter.values = othersDataField.children.map((child: any) => child[key]);
            this.queryParamsService.dispatchUpdatedQueryParams({ filters: this.queryParams?.filters });
        }

        this.unsubscribeNotifier$.next();
        this.unsubscribeNotifier$.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private findKey(dataField: any): string {
        return Object.keys(dataField).find((key) => key !== 'key' && dataField[key] === 'others') ??
            Object.keys(dataField.values[0]).find((key) => dataField.values[0][key] === 'others') ?? '';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFilterNameForDrillDown(elt: any): string | undefined {
        return this.vizConfig?.drilldown?.keys.find((key) => {
            return Object.prototype.hasOwnProperty.call(elt.data, key.value ?? '') && elt.data[key.value ?? ''] === elt.data.key;
        })?.value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFilterValueForOthers(elt: any): any {
        const key = this.findKey(elt.data);
        const children = elt.data.children || elt.data.values[0].children;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return children.map((child: any) => child[key]);
    }

    private isPIT(chartSeries: Series): boolean {
        return chartSeries.type === 'pie' || chartSeries.type === 'donut' || (chartSeries.type === 'bar' && !chartSeries.stacked);
    }

    private isHorizontalChart(chartSeries: Series): boolean {
        return !!(chartSeries.type === 'bar' && chartSeries.stacked && chartSeries.horizontal);
    }

    private isStackedChart(chartSeries?: Series): boolean {
        return !!(chartSeries?.type === 'bar' && chartSeries.stacked && !chartSeries.mirror);
    }

    private isMirroredChart(chartSeries?: Series): boolean {
        return !!(chartSeries?.type === 'bar' && chartSeries.mirror);
    }

    private isLineChart(chartSeries: Series): boolean {
        return chartSeries.type === 'line';
    }

    private dispatchQueryParams(resetDrillDown: boolean, activeDate: string, highlight: string): void {
        this.queryParamsService.dispatchUpdatedQueryParams({
            filters: resetDrillDown ? [] : this.queryParams?.filters,
            activeDate,
            highlight: highlight || '',
            areFiltersAppliedByMaster: true,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateFilters(filterName: string | undefined, filterValue: any): void {
        this.queryParams?.filters?.push({
            name: filterName,
            displayName: this.vizConfig?.multiSeries || this.vizConfig?.enableDrilldown ?
                this.getFilterDisplayName(filterName) :
                this.slicerInfo?.slicer.label,
            criteria: 'INCLUDE',
            isMasterWidgetFilter: true,
            values: typeof filterValue === 'string' || typeof filterValue === 'boolean' ? [filterValue] : [...filterValue],
        });
    }

    private restoreMasterWidgetHighlight(): void {
        const appliedMasterFilter = this.queryParams?.filters?.find((filter) => filter.isMasterWidgetFilter);
        if (appliedMasterFilter) {
            const highlightedMasterData = this.vizConfig?.dataSource.find((datum) => {
                if (datum[appliedMasterFilter.name ?? ''] === appliedMasterFilter.values?.[0]) {
                    return datum;
                }
            });

            if (highlightedMasterData) {
                const isStacked = this.isStackedChart(this.chartConfig?.series[0]);
                let filterType = 'slicer';

                if (isStacked) {
                    highlightedMasterData.key = (appliedMasterFilter.values?.length ?? 0) > 1 ? 'others' : appliedMasterFilter.values?.[0];
                    const stackedBarSlicer = this.chartConfig?.series[0].horizontal ?
                        this.chartConfig.series[0].yField[0] :
                        this.chartConfig?.series[0].xField[0];

                    if (stackedBarSlicer?.toLowerCase() !== appliedMasterFilter.name?.toLowerCase()) {
                        filterType = 'detail';
                    }
                }

                if (this.chartComponent) {
                    this.chartComponent.restoreMasterWidgetHighlight(
                        highlightedMasterData,
                        this.chartConfig?.series[0].type,
                        isStacked,
                        filterType);
                }
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFilteredRows(): any[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let filteredRows: any[] = [];
        this.managerService.getCurrentDashboardFilters().forEach((filter) => {
            if (filteredRows.length) {
                filteredRows = this.getFilteredRowsByDashboardFilter(filteredRows, filter);
            } else {
                filteredRows = this.getFilteredRowsByDashboardFilter(this.vizConfig?.dataSource ?? [], filter);
            }
        });
        return filteredRows;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFilteredRowsByDashboardFilter(dataToFilter: any[], filter: DashboardFilter): any[] {
        const filteredRows = [];
        const chartDetailsSort = dataToFilter[0]?.chart_details_sort;
        for (const row of dataToFilter) {
            if (filter.criteria === 'EXCLUDE') {
                if (!filter.values?.includes(row[filter.name ?? '']?.toString())) {
                    filteredRows.push(row);
                }
            } else {
                if (filter.values?.includes(row[filter.name ?? '']?.toString())) {
                    filteredRows.push(row);
                }
            }
        }
        if (filteredRows.length && chartDetailsSort) {
            filteredRows[0].chart_details_sort = this.getFilteredChartDetailsSortData(filter.name ?? '', chartDetailsSort, filteredRows);
        }
        return filteredRows.length ? filteredRows : dataToFilter;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFilteredChartDetailsSortData(filterName: string, chartDetailsSort: any[], filteredRows: any[]): any[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filteredChartDetailsSort: any[] = [];
        const details = this.chartConfig?.multiSeries?.field ?? '';
        chartDetailsSort.forEach((el) => {
            if (el[filterName] != null) {
                const item = filteredRows.find((row) => row[filterName] === el[filterName]);
                if (item) {
                    filteredChartDetailsSort.push(el);
                }
            } else if (el[details] != null) {
                const item = filteredRows.find((row) => row[details] === el[details]);
                if (item) {
                    filteredChartDetailsSort.push(el);
                }
            }
        });

        const latestChartDetailsSort = this.chartConfig?.dataSource[0]?.chart_details_sort;
        return filteredChartDetailsSort.length ? filteredChartDetailsSort : latestChartDetailsSort;
    }

    private getFilters(filterToRemove?: DashboardFilter): DashboardFilter[] {
        const masterFilters = this.queryParams?.filters?.filter((el) => el.isMasterWidgetFilter && filterToRemove?.name !== el.name);
        const updatedFilters = this.queryParams?.filters?.filter((el) => el.name !== filterToRemove?.name);
        if (this.doesMasterFilterEqualDashboardFilter(filterToRemove)) {
            return masterFilters?.length ? masterFilters : deepClone(this.managerService.getCurrentDashboardFilters());
        }

        return updatedFilters ?? [];
    }

    private doesMasterFilterEqualDashboardFilter(filterToRemove?: DashboardFilter): boolean {
        return !!this.managerService.getCurrentDashboardFilters().find((el) => {
            return el.name === filterToRemove?.name && filterToRemove?.isMasterWidgetFilter;
        });
    }
}

export type VizModel = ChartSettings | GridConfiguration;
