import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { Observable } from 'rxjs';

@Injectable()
export class SavedViewService {
    private clientCode = '';

    constructor(
        @Inject(ApiServices.reportRunner) private readonly rrApiExecutor: ApiExecutorService,
        private readonly currentStateService: CurrentStateService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchSupportedMasterReports(): Observable<any> {
        return this.rrApiExecutor.invokeServiceWithParams(
            this.clientCode,
            '/templates',
            { applications: ['GL Tool', 'Portfolio Reporting'] });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchSavedViewsForMaster(id: string): Observable<any> {
        return this.rrApiExecutor.invokeServiceWithParams(this.clientCode, '/saved_choices', { templateId: id });
    }
}
