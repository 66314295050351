import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-group-tooltip',
    templateUrl: './group-tooltip.component.html',
    styleUrls: ['./group-tooltip.component.scss'],
})
export class GroupTooltipComponent implements ITooltipAngularComp {
    tooltipTitle: SafeHtml | undefined;

    constructor(private readonly sanitizer: DomSanitizer) {}

    agInit(params: ITooltipParams): void {
        this.tooltipTitle = this.sanitizer.bypassSecurityTrustHtml(params.colDef?.headerName ?? '');
    }
}
