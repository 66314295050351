import { ColDef, Column, ColumnResizedEvent, ColumnRowGroupChangedEvent, ColumnState, IAggFunc } from '@ag-grid-community/core';
import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DataGridComponent, OverridesRelayService } from '@ddv/data-grid';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import {
    AgGridFilterModel,
    AgGridSortDirection,
    AUTO_GROUP_COLUMN_ID,
    ConfigItem,
    crosstalkCheckboxFieldId,
    CustomColumnState,
    DashboardDetails,
    EDITABLE_CHECKBOX_FIELD,
    getCurrentVisualizationId,
    UserGridColumnOverrides,
    UserGridColumnOverridesMap,
    WidgetOnBoard,
} from '@ddv/models';
import { deepCompare } from '@ddv/utils';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, first, map, share, switchMap } from 'rxjs/operators';

import { DashboardDetailsRelayService } from './dashboard-details-relay.service';

interface UserGridColumnOverridesObservable {
    url: string;
    method: string;
    body: UserGridColumnOverridesMap | null;
}

@Injectable({ providedIn: 'root' })
export class UserGridColumnOverridesService {
    public updateGridColumnOverrides$: Observable<UserGridColumnOverridesObservable | unknown> | undefined;

    private readonly updateGridColumnOverridesSubject = new ReplaySubject<UserGridColumnOverridesObservable>(1);
    private checkboxColumnDefinition: ColDef | undefined;
    private readonly excludedColumns: string[] = [
        EDITABLE_CHECKBOX_FIELD,
        AUTO_GROUP_COLUMN_ID,
    ];
    private clientCode = '';
    private currentDashboardDetails: DashboardDetails | undefined;

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
        private readonly dashboardDetailsRelay: DashboardDetailsRelayService,
        private readonly manager: ManagerService,
        private readonly relayService: OverridesRelayService,
    ) {
        this.relayService.link(this);

        this.currentStateService.clientCode$.subscribe((clientCode) => {
            this.clientCode = clientCode;

            this.updateGridColumnOverrides$ = this.updateGridColumnOverridesSubject.pipe(
                debounceTime(150),
                switchMap(({ url, method, body }) => this.ddvApiService.invokeServiceWithBody(this.clientCode, url, method, body)),
                share());
        });

        this.dashboardDetailsRelay.currentDashboardDetails.subscribe((details) => {
            this.currentDashboardDetails = details;
        });
    }

    getCurrentGridColumnOverrides(widgetOnBoardId: number, visualizationId: number): UserGridColumnOverrides[] {
        const overrides = this.getGridColumnOverrides(widgetOnBoardId);
        return overrides[visualizationId] || [];
    }

    setCheckboxColumnDefinition(checkboxColumnDefinition: ColDef): void {
        this.checkboxColumnDefinition = checkboxColumnDefinition;
    }

    getColumnsOrder(
        userGridColumnOverrides: UserGridColumnOverrides[],
        isInViewMode = false,
        columns: ConfigItem[],
        initialColumnState: CustomColumnState[],
    ): ConfigItem[] {
        const columnsOrder: UserGridColumnOverrides[] = isInViewMode ?
            userGridColumnOverrides.sort((a, b) => a.columnPosition! > b.columnPosition! ? 1 : -1) :
            initialColumnState.map((c, index) => ({ columnId: c.colId, columnPosition: index }));

        const updatedColumns = columns;
        columnsOrder.forEach((override) => {
            if (override?.columnPosition != null) {
                const columnIndex = updatedColumns.findIndex((column) => column.colId === override.columnId);
                if (columnIndex !== -1) {
                    updatedColumns.splice(override.columnPosition, 0, columns.splice(columnIndex, 1)[0]);
                }
            }
        });
        return updatedColumns;
    }

    setColumnsSort(
        userGridColumnOverrides: UserGridColumnOverrides[],
        isInViewMode = false,
        columns: ConfigItem[],
        initialColumnState: CustomColumnState[],
        dataGridComponent: DataGridComponent,
    ): void {
        const sortOrder: UserGridColumnOverrides[] = isInViewMode ?
            userGridColumnOverrides :
            initialColumnState.map((c) => ({
                columnId: c.colId,
                agSort: c.sort ?? null,
                agSortIndex: (c.sortIndex !== undefined ? c.sortIndex : undefined) ?? undefined,
            }));

        const sortState: ColumnState[] = [];
        columns.forEach((column) => {
            const { agSort, agSortIndex } = this.getColumnSort(sortOrder, initialColumnState, column);

            sortState.push({
                colId: column.colId,
                sort: agSort.sort,
                sortIndex: agSortIndex,
            });
        });

        dataGridComponent.applyColumnState({ state: sortState, applyOrder: false });
    }

    setColumnsFilterModel(
        overrides: UserGridColumnOverrides[],
        isInViewMode = false,
        initialColumnState: CustomColumnState[],
        dataGridComponent: DataGridComponent,
    ): void {
        const filterModel = this.getColumnsFilterModel(overrides, isInViewMode, initialColumnState);

        if (dataGridComponent && !deepCompare(dataGridComponent.getFilterModel(), filterModel)) {
            // Calling setFilterModel with no arguments helps us set the correct filters
            // in case we deselect all options from a set (string) filter in view mode and then:
            // - switch to edit mode
            // - hit 'Restore Default View' button

            setTimeout(() => {
                dataGridComponent.setFilterModel();
                dataGridComponent.setFilterModel(filterModel);
            }, 100);
        }
    }

    updateColumnsOrder(widgetOnBoardId: number, columnIds: string[]): void {
        const widgetPrefs = this.manager.getWidgetPreferences(widgetOnBoardId);
        if (widgetPrefs?.isDetailWidget) {
            return;
        }

        if (columnIds.length) {
            const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
            const userGridColumnOverrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
            columnIds.forEach((columnId: string, index: number) => {
                const column = userGridColumnOverrides.find((override) => override.columnId === columnId);
                if (column) {
                    column.columnPosition = index;
                } else {
                    const newItem: UserGridColumnOverrides = { columnId, columnPosition: index };
                    userGridColumnOverrides.push(newItem);
                }
            });

            const newColumnOrder = userGridColumnOverrides
                .filter((column) => this.excludedColumns.indexOf(column.columnId) === -1)
                .map((column) => ({ columnId: column.columnId, columnPosition: column.columnPosition }));

            this.updateWidgetStateGridColumnOverrides(widgetOnBoardId, { [visualizationId]: userGridColumnOverrides });

            if (newColumnOrder.length) {
                const updatedOverrides = { [visualizationId]: newColumnOrder };
                this.updateGridColumnOverrides(widgetOnBoardId, 'columnOrder', updatedOverrides).pipe(first()).subscribe();
            }
        }
    }

    updateColumnVisibility(widgetOnBoardId: number, columns: { [colId: string]: boolean }): void {
        const widgetPrefs = this.manager.getWidgetPreferences(widgetOnBoardId);
        if (widgetPrefs?.isDetailWidget) {
            return;
        }

        const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
        const userGridColumnOverrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
        Object.keys(columns).forEach((key) => {
            const column = userGridColumnOverrides.find((c) => c.columnId === key);
            if (column) {
                column.isHidden = !columns[key];
            } else {
                const newItem: UserGridColumnOverrides = { columnId: key, isHidden: !columns[key] };
                userGridColumnOverrides.push(newItem);
            }
        });
        const newColumnVisibility = userGridColumnOverrides.map((c) => ({ columnId: c.columnId, isHidden: c.isHidden }));

        this.updateWidgetStateGridColumnOverrides(widgetOnBoardId, { [visualizationId]: userGridColumnOverrides });

        const updatedOverrides = { [visualizationId]: newColumnVisibility };
        this.updateGridColumnOverrides(widgetOnBoardId, 'columnVisibility', updatedOverrides).pipe(first()).subscribe();
    }

    updateAggFuncInPivotMode(widgetOnBoardId: number, columns: ConfigItem[], visibleColumns: Column[]): void {
        const widgetPrefs = this.manager.getWidgetPreferences(widgetOnBoardId);
        if (widgetPrefs?.isDetailWidget) {
            return;
        }

        const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
        const userGridColumnOverrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
        const overrides: UserGridColumnOverrides[] = columns
            .filter((column) => !column.canPivotOn && column.canAggregate)
            .map((column) => {
                const visibleColumn = visibleColumns.find((visualColumn) => visualColumn.getColId() === column.colId);
                let overrideColumn = userGridColumnOverrides.find((override) => override.columnId === column.colId);

                if (overrideColumn) {
                    overrideColumn.aggFuncInPivot = visibleColumn ?
                        overrideColumn.aggFuncInPivot === 'default' ?
                            column.aggregationType !== 'none' ? 'default' : visibleColumn.getAggFunc() :
                            visibleColumn.getAggFunc() :
                        overrideColumn.aggFuncInPivot === 'default' ?
                            column.aggregationType === 'none' ? 'default' : undefined :
                            undefined;
                } else {
                    overrideColumn = {
                        columnId: column.colId,
                        aggFuncInPivot: visibleColumn ?
                            column.aggregationType === 'none' ? visibleColumn.getAggFunc() : 'default' :
                            column.aggregationType === 'none' ? 'default' : undefined,
                    };
                    userGridColumnOverrides.push(overrideColumn);
                }

                return {
                    columnId: overrideColumn.columnId,
                    aggFuncInPivot: overrideColumn.aggFuncInPivot,
                };
            });

        this.updateWidgetStateGridColumnOverrides(widgetOnBoardId, { [visualizationId]: userGridColumnOverrides });

        const updatedOverrides = { [visualizationId]: overrides };
        this.updateGridColumnOverrides(widgetOnBoardId, 'aggFuncInPivotMode', updatedOverrides).pipe(first()).subscribe();
    }

    updateColumnSort(widgetOnBoardId: number, columnState: ColumnState[]): void {
        const widgetPrefs = this.manager.getWidgetPreferences(widgetOnBoardId);
        if (widgetPrefs?.isDetailWidget) {
            return;
        }

        const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
        const userGridColumnOverrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
        const columnSortState = columnState.map((column) => ({
            columnId: column.colId,
            sort: column.sort,
            sortIndex: column.sortIndex,
        }));

        columnSortState.forEach((column) => {
            const override = userGridColumnOverrides.find((col) => col.columnId === column.columnId);

            if (column.columnId !== crosstalkCheckboxFieldId) {
                if (override) {
                    override.agSort = column.sort ?? 'none';
                    override.agSortIndex = column.sortIndex ?? undefined;
                } else {
                    userGridColumnOverrides.push({
                        columnId: column.columnId,
                        agSort: column.sort ?? 'none',
                        agSortIndex: column.sortIndex ?? undefined,
                    });
                }
            }
        });

        const newColumnSort = userGridColumnOverrides.map((column) => ({
            columnId: column.columnId,
            agSort: Object.is(column.agSort, null) ? 'none' : column.agSort,
            agSortIndex: column.agSortIndex,
        }));

        this.updateWidgetStateGridColumnOverrides(widgetOnBoardId, { [visualizationId]: userGridColumnOverrides });

        if (newColumnSort.length) {
            const updatedOverrides = { [visualizationId]: newColumnSort };
            this.updateGridColumnOverrides(widgetOnBoardId, 'columnSort', updatedOverrides).pipe(first()).subscribe();
        }
    }

    updateColumnFilter(
        widgetOnBoardId: number,
        appliedFilters: AgGridFilterModel,
        columns: Column[],
        initialColumnState: CustomColumnState[],
        isClearAll = false,
    ): void {
        const widgetPrefs = this.manager.getWidgetPreferences(widgetOnBoardId);
        if (widgetPrefs?.isDetailWidget) {
            return;
        }

        const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
        const userGridColumnOverrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
        const appliedFiltersKeys = Object.keys(appliedFilters);

        if (!appliedFiltersKeys.length) {
            if (userGridColumnOverrides.length) {
                userGridColumnOverrides.forEach((column) => {
                    const isChanged = initialColumnState.some((c) => c.colId === column.columnId && c.agFilter);
                    column.agFilter = column.agFilter === 'default' && !isClearAll && !isChanged ? 'default' : null;
                    column.agFilterIndex = null;
                });
            } else {
                columns.forEach((column) => {
                    userGridColumnOverrides.push({
                        columnId: column.getColId(),
                        agFilter: null,
                        agFilterIndex: null,
                    });
                });
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const currentColumnsFilters: { columnId: string, filtersData?: string }[] = [];
            appliedFiltersKeys.forEach((key) => {
                const columnFilter = currentColumnsFilters.find((c) => c.columnId === key);
                if (columnFilter) {
                    columnFilter.filtersData = appliedFilters[key];
                } else {
                    currentColumnsFilters.push({
                        columnId: key,
                        filtersData: appliedFilters[key],
                    });
                }
            });

            initialColumnState.forEach((column) => {
                if (column.agFilter && !currentColumnsFilters.find((col) => col.columnId === column.colId)) {
                    currentColumnsFilters.push({
                        columnId: column.colId,
                        filtersData: undefined,
                    });
                }
            });

            currentColumnsFilters.forEach((filter, index) => {
                const appliedFilterIndex = appliedFiltersKeys.findIndex((key) => filter.columnId === key);

                const override = userGridColumnOverrides.find((c) => c.columnId === filter.columnId);
                const filterToApply: UserGridColumnOverrides = {
                    columnId: override ? override.columnId : filter.columnId,
                    agFilter: appliedFilterIndex !== -1 ?
                        filter.filtersData === 'default' || filter.filtersData === undefined ?
                            'default' :
                            JSON.stringify(filter.filtersData) :
                        null,
                    agFilterIndex: appliedFilterIndex !== -1 ? index : null,
                };

                if (!override) {
                    userGridColumnOverrides.push(filterToApply);
                } else {
                    Object.assign(override, filterToApply);
                }
            });

            userGridColumnOverrides.forEach((override) => {
                const isFilterApplied = appliedFiltersKeys.indexOf(override.columnId) !== -1;
                if (!isFilterApplied) {
                    const isChanged = initialColumnState.some((c) => c.colId === override.columnId && c.agFilter);
                    override.agFilter = (override.agFilter === 'default' || override.agFilter === undefined) && !isChanged ?
                        'default' :
                        null;
                    override.agFilterIndex = null;
                }
            });
        }
        const newColumnFilter = userGridColumnOverrides.map((column) => ({
            columnId: column.columnId,
            agFilter: column.agFilter,
            agFilterIndex: column.agFilterIndex,
        }));

        this.updateWidgetStateGridColumnOverrides(widgetOnBoardId, { [visualizationId]: userGridColumnOverrides });

        if (newColumnFilter.length) {
            const updatedOverrides = { [visualizationId]: newColumnFilter };
            this.updateGridColumnOverrides(widgetOnBoardId, 'columnFilter', updatedOverrides).pipe(first()).subscribe();
        }
    }

    updateColumnWidth(widgetOnBoardId: number, event: ColumnResizedEvent): void {
        const widgetPrefs = this.manager.getWidgetPreferences(widgetOnBoardId);
        const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
        const overrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
        const columnId = event.column?.getColId() ?? '';
        const width = event.column?.getActualWidth();

        const displayedColumns = event.api.getAllDisplayedColumns();
        const widthOverrides = displayedColumns.map((c) => {
            const colId = c.getColId();
            const columnOverride = overrides.find((o) => o.columnId === c.getColId());
            const widthOverride = columnOverride?.columnWidth != null ? columnOverride.columnWidth : undefined;
            const newColumnWidth = colId === columnId ? width : widthOverride;
            const groupColumnsOrderForGroupWidth = colId === AUTO_GROUP_COLUMN_ID ?
                event.api.getRowGroupColumns().map((gc) => gc.getColId()).join() :
                undefined;
            const newWidthOverride = { columnId: colId, columnWidth: newColumnWidth, groupColumnsOrderForGroupWidth };

            if (!columnOverride) {
                overrides.push(newWidthOverride);
            } else {
                columnOverride.columnWidth = newColumnWidth;
                columnOverride.groupColumnsOrderForGroupWidth = groupColumnsOrderForGroupWidth;
            }

            return newWidthOverride;
        });

        this.updateWidgetStateGridColumnOverrides(widgetOnBoardId, { [visualizationId]: overrides });

        const updatedOverrides = { [visualizationId]: widthOverrides };
        this.updateGridColumnOverrides(widgetOnBoardId, 'columnWidth', updatedOverrides).pipe(first()).subscribe();
    }

    updateGroupColumn(widgetOnBoardId: number, event: ColumnRowGroupChangedEvent): void {
        const widgetPrefs = this.manager.getWidgetPreferences(widgetOnBoardId);
        const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
        const overrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
        const groupColumnsOrder = event.api.getRowGroupColumns().map((c) => c.getColId()).join() ?? '';

        // once we fix how the checkbox column position is set
        // we will no longer need to filter it out when sending the other columns to be overrided
        const displayedColumns = event.api.getAllDisplayedColumns().filter((c) => c.getColId() !== crosstalkCheckboxFieldId);

        const groupColumnsOrderOverrides = displayedColumns.map((c) => {
            const colId = c.getColId();
            const newGroupColumnsOrder = colId === AUTO_GROUP_COLUMN_ID ? groupColumnsOrder : undefined;
            const override = overrides.find((o) => o.columnId === colId);
            const newGroupColumnsOrderOverride = { columnId: colId, groupColumnsOrder: newGroupColumnsOrder };

            if (!override) {
                overrides.push(newGroupColumnsOrderOverride);
            } else {
                override.groupColumnsOrder = newGroupColumnsOrder;
            }

            return newGroupColumnsOrderOverride;
        });

        // When the user removes all the groups we don't have the group column in the displayedColumns
        // so we have to explicitly add the override for it
        if (!displayedColumns.find((c) => c.getColId() === AUTO_GROUP_COLUMN_ID) && groupColumnsOrder === '') {
            const groupOverride = overrides.find((o) => o.columnId === AUTO_GROUP_COLUMN_ID);
            const newGroupColumnsOrderOverride = { columnId: AUTO_GROUP_COLUMN_ID, groupColumnsOrder };
            if (!groupOverride) {
                overrides.push(newGroupColumnsOrderOverride);
            } else {
                groupOverride.groupColumnsOrder = groupColumnsOrder;
            }

            groupColumnsOrderOverrides.push(newGroupColumnsOrderOverride);
        }

        this.updateWidgetStateGridColumnOverrides(widgetOnBoardId, { [visualizationId]: overrides });

        const updatedOverrides = { [visualizationId]: groupColumnsOrderOverrides };
        this.updateGridColumnOverrides(widgetOnBoardId, 'groupColumnsOrder', updatedOverrides).pipe(first()).subscribe();
    }

    deleteGridColumnOverrides(widgetOnBoardId: number, columns: CustomColumnState[] = []): Observable<void> {
        return this.updateGridColumnOverrides(widgetOnBoardId)
            .pipe(
                first(),
                map(() => {
                    if (columns.length) {
                        this.restoreCheckboxColumn(columns, 'CustomColumnState');
                    }
                    return this.resetGridColumnOverrides(widgetOnBoardId);
                }),
            );
    }

    setCheckboxColumnPosition(
        widgetOnBoardId: number,
        visualizationId: number,
        columns: ColDef[] | ConfigItem[],
        columnType: string,
        isInViewMode: boolean,
    ): void {
        const userGridColumnOverrides = this.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
        // ConfigItem does have a field property so this line of code is bad
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const emptyColumnIndex = columns.findIndex((col: any) => !col?.colId && !col?.field);
        if (emptyColumnIndex >= 0) {
            columns.splice(emptyColumnIndex, 1);
        }
        const checkboxColumnIndex = columns.findIndex((col) => col.colId === crosstalkCheckboxFieldId);
        if (checkboxColumnIndex < 0) {
            this.addCheckboxColumn(userGridColumnOverrides, columns, columnType, isInViewMode);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const undefinedColumnIndex = columns.findIndex((col: any) => !col?.colId && !col?.field);
            if (undefinedColumnIndex >= 0) {
                columns.splice(undefinedColumnIndex, 1);
            }
        } else {
            if (isInViewMode) {
                const checkboxColumnOverridePosition = userGridColumnOverrides
                    .find((col) => col.columnId === crosstalkCheckboxFieldId)?.columnPosition;
                if (this.checkboxColumnDefinition) {
                    this.checkboxColumnDefinition.pinned = checkboxColumnOverridePosition ? undefined : 'left';
                }
                const checkboxColumn = this.getCheckboxColumnType(columnType);
                if (!checkboxColumnOverridePosition) {
                    columns.splice(checkboxColumnIndex, 1);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    columns.unshift(checkboxColumn as any);
                } else {
                    columns.splice(checkboxColumnIndex, 1);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    columns.splice(checkboxColumnOverridePosition, 0, checkboxColumn as any);
                }
            } else {
                this.restoreCheckboxColumn(columns, columnType);
            }
        }
    }

    moveAutoGroupColumnAfterCheckboxColumn(dataGridComponent: DataGridComponent | undefined, isInViewMode: boolean): void {
        const checkboxColumn = dataGridComponent?.getAllDisplayedColumns()?.find((col) => col.getColId() === crosstalkCheckboxFieldId);
        if (checkboxColumn && (checkboxColumn.getPinned() === 'left' || !isInViewMode)) {
            dataGridComponent?.moveColumn(crosstalkCheckboxFieldId, 0);
            dataGridComponent?.moveColumn(AUTO_GROUP_COLUMN_ID, 1);
        }
    }

    getColumnVisibility(
        overrides: UserGridColumnOverrides[],
        initialColumnState: CustomColumnState[],
        colId: string,
        isInViewMode: boolean,
    ): boolean {
        const override = overrides.find((c) => c.columnId === colId);
        const initialVisibility = !!initialColumnState.find((col) => col.colId === colId)?.hide;
        const isHidden = override?.isHidden != null && isInViewMode ? override.isHidden : initialVisibility;
        return !isHidden;
    }

    setColumnAggFuncInPivotMode(
        overrides: UserGridColumnOverrides[],
        initialColumnsState: CustomColumnState[],
        column: ConfigItem,
        columns: ConfigItem[],
        isInViewMode: boolean,
        dataGridComponent: DataGridComponent,
    ): void {
        const override = overrides.find((o) => o.columnId === column.colId);
        const columnPosition = (override ? override.columnPosition : columns.length) ?? 0;
        const initialState = initialColumnsState.find((c) => c.colId === column.colId);
        const initialAggFuncInPivot = initialState?.aggFunc === 'none' ? null : initialState?.aggFunc;
        const aggFunc: string | IAggFunc | null | undefined = isInViewMode ?
            override?.aggFuncInPivot !== 'default' && override?.aggFuncInPivot !== undefined ?
                override.aggFuncInPivot :
                initialAggFuncInPivot :
            initialAggFuncInPivot;

        dataGridComponent.applyColumnState({ state: [{ colId: column.colId, aggFunc }], applyOrder: false });
        if (aggFunc) {
            dataGridComponent.moveColumn(column.colId, columnPosition);
        }
    }

    getColumnWidth(
        overrides: UserGridColumnOverrides[],
        colId: string,
        isInViewMode: boolean,
        initialColumnState: CustomColumnState[],
        autoSizeColumns: boolean,
    ): number | null | undefined {
        const override = overrides.find((o) => o.columnId === colId);
        const initialColumn = initialColumnState.find((c) => c.colId === colId);
        return isInViewMode && override?.columnWidth != null ?
            override.columnWidth :
            autoSizeColumns ? null : initialColumn?.width;
    }

    getGroupColumnWidth(
        overrides: UserGridColumnOverrides[],
        isInViewMode: boolean,
        initialColumnState: CustomColumnState[],
        groupColumns: Column[],
        autoSizeColumns: boolean,
    ): number | null | undefined {
        const groupOverride = overrides.find((o) => o.columnId === AUTO_GROUP_COLUMN_ID);
        const initialGroupColumnsOrder = groupColumns.map((c) => c.getColId()).join();
        const groupColumnsOrderSameAsInitial = groupOverride?.groupColumnsOrderForGroupWidth === initialGroupColumnsOrder;
        return isInViewMode && groupOverride?.columnWidth != null && groupColumnsOrderSameAsInitial ?
            groupOverride.columnWidth :
            autoSizeColumns ? null : initialColumnState.find((c) => c.colId === AUTO_GROUP_COLUMN_ID)?.width;
    }

    getGroupColumnsOrder(
        overrides: UserGridColumnOverrides[],
        isInViewMode: boolean,
        columns: ConfigItem[],
        initialColumnState: CustomColumnState[],
    ): ConfigItem[] {
        const updatedColumns = columns;
        const groupOverride = overrides.find((o) => o.columnId === AUTO_GROUP_COLUMN_ID);
        let groupColumnsOrder: { colId: string, rowGroupIndex: number | undefined | null }[];
        if (isInViewMode && groupOverride?.groupColumnsOrder != null) {
            if (groupOverride.groupColumnsOrder === '') {
                groupColumnsOrder = [];
            } else {
                groupColumnsOrder = groupOverride.groupColumnsOrder.split(',').map((colId, index) => ({
                    colId,
                    rowGroupIndex: index,
                }));
            }
        } else {
            groupColumnsOrder = initialColumnState
                .filter((c) => c.rowGroupIndex != null)
                .map((c) => ({ colId: c.colId, rowGroupIndex: c.rowGroupIndex }));
        }

        updatedColumns.forEach((c) => {
            const rowGroupIndex = groupColumnsOrder.find((o) => o.colId === c.colId)?.rowGroupIndex;
            c.rowGroupIndex = rowGroupIndex ?? undefined;
        });

        return updatedColumns;
    }

    // called only by the public method getCurrentGridColumnOverrides
    private getGridColumnOverrides(widgetOnBoardId: number): UserGridColumnOverridesMap {
        const widgetsOnBoard = this.currentDashboardDetails?.widgetOnBoards;
        const overrides = widgetsOnBoard?.find((widget) => widget.id === widgetOnBoardId)?.userGridColumnOverrides;
        return overrides ?? {};
    }

    private restoreCheckboxColumn(columns: ColDef[] | CustomColumnState[] | ConfigItem[], columnType: string): void {
        const checkboxColumnIndex = columns.findIndex((col) => col.colId === crosstalkCheckboxFieldId);
        if (checkboxColumnIndex > -1) {
            if (this.checkboxColumnDefinition) {
                this.checkboxColumnDefinition.pinned = 'left';
            }

            columns.splice(checkboxColumnIndex, 1);
            const checkboxColumn = this.getCheckboxColumnType(columnType);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columns.unshift(checkboxColumn as any);
        }
    }

    private addCheckboxColumn(
        userGridColumnOverrides: UserGridColumnOverrides[],
        columns: ColDef[] | ConfigItem[],
        columnType: string,
        isInViewMode: boolean,
    ): void {
        const checkboxColumnPosition = userGridColumnOverrides.filter((col) => {
            return col.columnId === crosstalkCheckboxFieldId;
        })[0]?.columnPosition ?? -1;
        if (checkboxColumnPosition >= 0) {
            if (isInViewMode) {
                if (this.checkboxColumnDefinition) {
                    this.checkboxColumnDefinition.pinned = null;
                }
            } else {
                if (this.checkboxColumnDefinition) {
                    this.checkboxColumnDefinition.pinned = 'left';
                }
            }

            const checkboxColumn = this.getCheckboxColumnType(columnType);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columns.splice(checkboxColumnPosition, 0, checkboxColumn as any);
        } else {
            if (this.checkboxColumnDefinition) {
                this.checkboxColumnDefinition.pinned = 'left';
            }
            const checkboxColumn = this.getCheckboxColumnType(columnType);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columns.unshift(checkboxColumn as any);
        }
    }

    private updateGridColumnOverrides(
        widgetOnBoardId: number,
        propertyToUpdate = '',
        overrides?: UserGridColumnOverridesMap,
    ): Observable<UserGridColumnOverridesObservable | unknown> {
        const dashboardId = this.manager.getCurrentDashboardId();
        const url = `dashboards/${dashboardId}/widgets/${widgetOnBoardId}/userOverrides/${propertyToUpdate}`;
        const method = !propertyToUpdate ? 'DELETE' : 'POST';
        const body = (!propertyToUpdate ? undefined : overrides) ?? null;

        if (this.updateGridColumnOverrides$ && propertyToUpdate) {
            this.updateGridColumnOverridesSubject.next({ url, method, body });
            return this.updateGridColumnOverrides$;
        }

        return this.ddvApiService.invokeServiceWithBody(this.clientCode, url, method, body);
    }

    private getCurrentWidget(widgetOnBoardId: number): WidgetOnBoard | undefined {
        let widgetOnBoard: WidgetOnBoard | undefined;
        if (this.currentDashboardDetails?.widgetOnBoards?.length) {
            widgetOnBoard = this.currentDashboardDetails.widgetOnBoards.find((widget: WidgetOnBoard) => widget.id === widgetOnBoardId);
        }

        return widgetOnBoard;
    }

    private resetGridColumnOverrides(widgetOnBoardId: number): void {
        const widgetOnBoard = this.getCurrentWidget(widgetOnBoardId);
        if (widgetOnBoard) {
            widgetOnBoard.userGridColumnOverrides = {};
        }
    }

    private updateWidgetStateGridColumnOverrides(widgetOnBoardId: number, overrides: UserGridColumnOverridesMap): void {
        const widgetOnBoard = this.getCurrentWidget(widgetOnBoardId);
        if (widgetOnBoard) {
            Object.keys(overrides).forEach((key) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (widgetOnBoard.userGridColumnOverrides as any)[key] = (overrides as any)[key];
            });
        }
    }

    private getConfigItemFromColDef(column: ColDef): ConfigItem {
        return {
            colId: column.colId,
            resizable: false,
            width: column.width,
            pinned: column.pinned,
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
            columnCondition: [],
            agFilter: null,
        } as unknown as ConfigItem;
    }

    private getCustomColumnStateFromColDef(column: ColDef): CustomColumnState {
        return {
            colId: column.colId ?? '',
            pinned: column.pinned,
            width: column.width,
            agFilter: null,
        };
    }

    private getCheckboxColumnType(columnType: string): ColDef | ConfigItem | CustomColumnState | undefined {
        let checkboxColumn: ColDef | ConfigItem | CustomColumnState | undefined;
        if (this.checkboxColumnDefinition) {
            if (columnType === 'ColDef') {
                checkboxColumn = this.checkboxColumnDefinition;
            } else if (columnType === 'ConfigItem') {
                checkboxColumn = this.getConfigItemFromColDef(this.checkboxColumnDefinition);
            } else if (columnType === 'CustomColumnState') {
                checkboxColumn = this.getCustomColumnStateFromColDef(this.checkboxColumnDefinition);
            }
        }

        return checkboxColumn;
    }

    private getColumnSort(
        sortOrder: UserGridColumnOverrides[],
        initialColumnState: CustomColumnState[],
        column: ConfigItem,
    ): { agSort: { sort: AgGridSortDirection | undefined | null }, agSortIndex: number | undefined | null } {
        const override = sortOrder.find((c) => c.columnId === column.colId);
        const initialColumnSort = initialColumnState.find((c) => c.colId === column.colId);
        const agSort = override?.agSort != null ?
            { sort: override.agSort === 'none' ? null : override.agSort as AgGridSortDirection } :
            { sort: (initialColumnSort?.sort ?? null) as AgGridSortDirection };
        const agSortIndex = override?.agSort != null && override?.agSortIndex !== undefined ?
            override.agSortIndex :
            (initialColumnSort?.sortIndex !== undefined ? initialColumnSort.sortIndex : undefined);
        return { agSort, agSortIndex };
    }

    private getColumnFilters(
        isInViewMode: boolean,
        overrides: UserGridColumnOverrides[],
        initialColumnState: CustomColumnState[],
    ): AgGridFilterModel[] {
        const initialFilterState = initialColumnState.map((c) => {
            const filter = isInViewMode || !c.agFilterOnViewEditMode ?
                c.agFilter :
                Object.keys(c.agFilterOnViewEditMode).length ? c.agFilterOnViewEditMode : null;
            return { [c.colId]: filter || null };
        });

        return isInViewMode && overrides.length ?
            overrides
                .sort((a, b) => a.agFilterIndex! > b.agFilterIndex! ? 1 : -1)
                .map((column) => {
                    const initialFilter = initialFilterState.find((c) => c[column.columnId])?.[column.columnId] || null;
                    const overrideFilter = column.agFilter === 'default' || column.agFilter === '{}' || column.agFilter === undefined ?
                        initialFilter :
                        // eslint-disable-next-line eqeqeq
                        column.agFilter === null ? null : JSON.parse(column.agFilter);
                    return { [column.columnId]: overrideFilter };
                }) :
            initialFilterState;
    }

    private getColumnsFilterModel(
        overrides: UserGridColumnOverrides[],
        isInViewMode: boolean,
        initialColumnState: CustomColumnState[],
    ): AgGridFilterModel {
        const columnFilters = this.getColumnFilters(isInViewMode, overrides, initialColumnState);
        return this.getOverridesFilterModel(columnFilters);
    }

    private getOverridesFilterModel(columnFilters: AgGridFilterModel[]): AgGridFilterModel {
        const overrideFilterToApply: AgGridFilterModel = {};

        columnFilters.forEach((filter) => {
            const key = Object.keys(filter)[0];
            if (filter[key]) {
                if (filter[key].includedStrings) {
                    setTextFilterModel(filter, key);
                } else if (filter[key].filterType === 'number') {
                    setNumberFilterModel(filter, key);
                } else if (filter[key].filterType === 'date') {
                    setDateFilterModel(filter, key);
                }

                Object.assign(overrideFilterToApply, filter);
            }
        });

        return overrideFilterToApply;
    }
}

export function setTextFilterModel(filter: AgGridFilterModel, key: string): void {
    filter[key].filterType = 'set';
    if (!filter[key].values) {
        filter[key].values = JSON.parse(filter[key].includedStrings);
    }
}

export function setNumberFilterModel(filter: AgGridFilterModel, key: string): void {
    let value: number | undefined;

    if (filter[key].filterFrom) {
        value = Number(filter[key].filterFrom);
    } else if (filter[key].filter) {
        value = Number(filter[key].filter);
    }

    if (!isNaN(value as number)) {
        filter[key].filter = value?.toString();
        filter[key].filterFrom = value?.toString();
    }

    if (typeof filter[key].condition1 === 'string' && typeof filter[key].condition2 === 'string') {
        filter[key].conditions = [JSON.parse(filter[key].condition1), JSON.parse(filter[key].condition2)];
    }
}

export function setDateFilterModel(filter: AgGridFilterModel, key: string): void {
    filter[key].dateFrom = filter[key].dateFrom ?? filter[key].filterFrom;
    filter[key].dateTo = filter[key].dateTo ?? filter[key].filterTo;
    delete filter[key].filterFrom;
    delete filter[key].filterTo;
}
