import { Pipe, PipeTransform } from '@angular/core';

import { ValueOption } from './value-option';

@Pipe({ name: 'listBrowserFilter' })
export class ListBrowserFilter implements PipeTransform {
    // have to use any because of where we use this filter in templates we try to index ValueOption with strings which is a no-no
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(options?: ValueOption[], txt?: string): any[] {
        return (!txt ?
            options :
            options?.filter((o) => {
                return o.texts.some((t) => t.toLocaleLowerCase().indexOf(txt.toLocaleLowerCase()) !== -1);
            })) ?? [];
    }
}
