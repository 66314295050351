import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CellStyle } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ColorMetadataService } from '@ddv/charts';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { CustomCellRendererParams } from '@ddv/data-grid';
import { AUTO_GROUP_COLUMN_ID, CellFormat } from '@ddv/models';
import { linkify } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

/*
    This renderer does not render URLs at all.
    It renders simple spans with a class on them that mimics a hyperlink
    BaseGridVisualizationComponent picks up on the cell click and goes from there
 */
@Component({
    selector: 'app-url-cell-renderer',
    templateUrl: './url-cell-renderer.component.html',
    styleUrls: ['./url-cell-renderer.component.scss'],
})
export class URLCellRendererComponent extends MultiSubscriptionComponent implements ICellRendererAngularComp {
    cellInnerHTML: SafeHtml | undefined;
    private params: CustomCellRendererParams | undefined;
    private theme: Theme = Theme.light;

    constructor(private readonly sanitizer: DomSanitizer, private readonly themeService: ThemeService) {
        super();
    }

    agInit(params: CustomCellRendererParams): void {
        this.params = params;

        this.subscribeTo(this.themeService.currentTheme$, (theme: Theme) => {
            this.theme = theme;
            this.setup();
        });
    }

    refresh(): boolean {
        this.setup();
        return true;
    }

    private setup(): void {
        if (!this.params) {
            return;
        }

        if (this.params.columnCondition) {
            this.params.columnCondition.forEach((colCondition: CellFormat) => {
                ColorMetadataService.mapOutdatedColors(this.theme, colCondition);
                ColorMetadataService.mapThemeColors(this.theme, colCondition);
            });

            const cellStyle: CellStyle | undefined = this.params.getCellStyle!(
                this.params.value,
                this.params.displayType,
                this.params.columnCondition,
            );
            const agGroupCell = this.params.eParentOfValue?.parentElement?.classList.contains('ag-row-group');
            this.params.eGridCell.style.color = agGroupCell && !this.params.valueFormatted ? '' : cellStyle?.color as string;
            this.params.eGridCell.style.backgroundColor = agGroupCell && !this.params.valueFormatted ? '' : cellStyle?.backgroundColor as string;
        }

        const valueToSanitize = this.params.colDef?.colId === AUTO_GROUP_COLUMN_ID ?
            this.params.value :
            this.params.valueFormatted;

        if (valueToSanitize && isNaN(valueToSanitize) && !valueToSanitize.toString().includes('external-app-link')) {
            this.cellInnerHTML = this.sanitizer.bypassSecurityTrustHtml(linkify(valueToSanitize, '_self'));
        } else {
            this.cellInnerHTML = this.sanitizer.bypassSecurityTrustHtml(valueToSanitize);
        }
    }
}
