import { Inject, Injectable, Optional } from '@angular/core';
import { trimTrailingZeros } from '@ddv/formatters';
import * as d3 from 'd3';

import { ChartFormatSettings } from '../../models/chart-settings';
import { setD3FormatDefaultLocale } from '../../services/base-chart.service';

export type FormatterFunction = (n: number | { valueOf(): number }) => string;
export type FormatterFactory = (formatString: string) => FormatterFunction;
export const FormatterFactoryInjectorToken = 'd3-formatter-factory';

@Injectable()
export class HorizontalBarChartTextFormatterService {
    private readonly formatterFactory: FormatterFactory;
    private format: ChartFormatSettings | undefined;

    constructor(@Inject(FormatterFactoryInjectorToken) @Optional() injectedFormatterFactory: FormatterFactory | null) {
        setD3FormatDefaultLocale();
        this.formatterFactory = injectedFormatterFactory ?? d3.format;
    }

    setFormat(format: ChartFormatSettings | undefined): void {
        this.format = format;
    }

    formatValue(value: number): string | number {
        // this typeof check clearly indicates that somewhere we don't trust our types
        if (this.format && typeof value === 'number') {
            return this.formatNumber(this.format, value);
        }

        return value;
    }

    private formatNumber(format: ChartFormatSettings, numberToFormat: number): string {
        let decimalPlaces = format.decimalPlaces ?? (format.numberUnits ? 1 : 2);
        let prefix = '';
        let suffix = '';
        let formattedNumber = numberToFormat;

        switch (format.numberFormat) {
            case 'XX':
                decimalPlaces = 0;
                break;
            case '$':
                prefix = `$${prefix}`;
                break;
            case '%':
                formattedNumber = formattedNumber * 100;
                suffix += '%';
        }

        switch (format.numberUnits) {
            case 'billions':
                formattedNumber = formattedNumber / 1e9;
                suffix = `B ${suffix}`;
                break;
            case 'millions':
                formattedNumber = formattedNumber / 1e6;
                suffix = `M ${suffix}`;
                break;
            case 'thousands':
                formattedNumber = formattedNumber / 1e3;
                suffix = `K ${suffix}`;
        }

        const formatter = this.formatterFactory(`,.${decimalPlaces}f`);
        let formatted = formatter(formattedNumber);

        if (format.numberFormat === 'XX.00 Trim') {
            formatted = trimTrailingZeros(formatted);
        }

        return `${prefix}${formatted}${suffix.trim()}`;
    }
}
