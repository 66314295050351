import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { MetadataService } from '@ddv/datasets';
import {
    MANAGE_WIDGET_ID,
    WIDGET_LIFECYCLE_EVENT,
    ExportFilteredData,
    MetadataFormatUtils,
    ConfigItem,
    VisualizationConfigs,
    ActionState,
    DataUpdateBody,
    DatasetMetadata,
    MetadataLookup,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    Action,
} from '@ddv/models';
import { deepCompare, clone, deepClone, uid } from '@ddv/utils';
import { Theme } from '@hs/ui-core-presentation';
import { Subject } from 'rxjs';

import { VizInfo } from './visualization-wrapper/visualization-wrapper.interface';

export interface PropertyNameValue {
    property: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    selectedValues: string[];
}

@Component({ template: '' })
export abstract class BaseVisualizationComponent extends MultiSubscriptionComponent implements OnInit {
    @Input() isManagingWidget = false;
    @Input() useNewLegend = false;
    @Input() fieldMetadata: MetadataLookup = {};
    datasetId: number | string = 0;
    isMaster = false;
    isStackedQuery: boolean | undefined;
    hideLoaderAfterFirstDataLoad = false;
    preferences: VisualizationConfigs | undefined;
    initialValues: ConfigItem[] = [];
    widgetId = 0;
    isChartInitialized = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    vizData: any[] = [];
    vizInfo: VizInfo | undefined;
    vizSlicer: Subject<Partial<ConfigItem>> = new Subject();
    @Output() valueClickedInMaster: EventEmitter<PropertyNameValue> = new EventEmitter<PropertyNameValue>();
    protected actionState: ActionState | undefined;
    protected metadata: MetadataLookup | undefined;
    protected theme: Theme = Theme.light;

    protected constructor(
        private readonly metadataService: MetadataService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initialValues = this.preferences?.configs?.values ?? [];

        this.subscribeTo(this.metadataService.metadataState, (param: DatasetMetadata) => {
            if ((!this.metadata && param.metadata.get(this.widgetId)) || param.lastChangedWidgetId === this.widgetId) {
                this.metadata = param.metadata.get(this.widgetId);
                if (this.dynamicColumnsShouldBeDisplayed()) {
                    this.setDynamicColumns();
                }
                this.preferences?.configs?.getFlatList?.().forEach((config) => this.populateConfigWithMetadata(config));
                this.onMetadataUpdate();
            }
        });

        this.subscribeTo(this.metadataService.actionsState, (actionState: ActionState) => {
            this.actionState = actionState;
            const actionsForDataset = actionState[this.datasetId];
            if (actionsForDataset) {
                this.onActionsChanged(actionsForDataset);
            }
        });
    }

    public getMetadata(): MetadataLookup | undefined {
        return this.metadata;
    }

    abstract onMetadataUpdate(): void;

    protected onActionsChanged(_actions: Action[]): void {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    abstract onFilterChanged(eventOrSlicer: any): void;
    abstract widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    abstract widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    abstract widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    abstract widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, data: unknown): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    abstract updateDataSource(componentData: { data: any[] }): void;
    abstract getExportFilteredData(): ExportFilteredData | undefined;
    abstract getExportFullData(): ExportFilteredData | undefined;

    // this is only necessary because of dynamic components
    // this is currently only used by HBar, but because our types are a bit of a mess, we need it defined here or it wont compile
    updateFieldMetadata(_: MetadataLookup): void {}

    // This is semi-abstract.  all the chart based visualizations override it
    // the grids have no such concept, so this is a no-op for them
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onVizInfoChanged(_: Partial<ConfigItem>): void {}

    protected populateConfigWithMetadata(config: ConfigItem): void {
        // These are fields in the metadata that are specifically overwritable by the user
        const alignment = config.alignment;
        const displayType = config.displayType;
        const isDynamic = config.isDynamic ?? false;
        Object.assign(config, this.metadata?.[config.value!]);
        config.alignment = alignment;
        config.displayType = displayType;
        config.isDynamic = isDynamic;
    }

    protected getCurrentSortOrderSlicer(value?: string): ConfigItem | undefined {
        const slicers = this.preferences?.configs?.slicers ?? [];
        if (this.isManageWidgetMode() && !value) {
            return slicers.find((slicer) => slicer.value === this.preferences?.sortOrderSlicer);
        }

        const defaultSlicer = this.getDefaultSlicer(value, slicers);
        if (defaultSlicer) {
            return defaultSlicer;
        }

        return slicers[0];
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected onDynamicColumnsUpdate(): void {}

    protected isManageWidgetMode(): boolean {
        return this.widgetId === MANAGE_WIDGET_ID;
    }

    // This doesn't belong here as its only relevant to grids
    private setDynamicColumns(): void {
        const currentValues = deepClone(this.preferences?.configs?.values ?? []);
        const newValues = deepClone(this.initialValues);

        if (this.metadata) {
            const md = this.metadata;
            Object.keys(this.metadata).forEach((key) => {
                const currentColumn = newValues.find((value) => value.label === md[key].displayName);
                if (md[key].isDynamic && !currentColumn) {
                    newValues.push(this.getNewColumn(key));
                } else if (currentColumn) {
                    currentColumn.editable = md[key].editable;
                }
            });
        }

        if (this.preferences?.configs) {
            this.preferences.configs.values = deepClone(newValues);
        }

        if (!deepCompare(currentValues, this.preferences?.configs?.values)) {
            this.onDynamicColumnsUpdate();
        }
    }

    private getNewColumn(key: string): ConfigItem {
        if (!this.metadata) {
            throw new Error('Cannot getNewColumn without metadata');
        }

        const { name, displayName, displayType } = this.metadata[key];
        return clone(
            this.metadata[key],
            MetadataFormatUtils.getDefaultColFormatters(),
            {
                label: displayName,
                value: this.isUserDefinedFieldColumn(name) ? name : displayName,
                colId: uid(),
                aggregationType: displayType === 'value' ? 'sum' : null,
            });
    }

    private getDefaultSlicer(value?: string, slicers?: ConfigItem[]): ConfigItem | undefined {
        if (value) {
            return slicers?.length ? slicers.find((slicer) => slicer.value === value) : undefined;
        }
        return slicers?.length ? slicers.find((slicer) => slicer.isDefault) : undefined;
    }

    // This doesn't belong here as its only relevant to grids
    private dynamicColumnsShouldBeDisplayed(): boolean {
        return !!(!this.isManageWidgetMode() &&
            (this.preferences?.visualizationType === 'ADVANCED_GRID' || this.preferences?.visualizationType === 'SIMPLE_GRID') &&
            this.preferences.autoAddDynamicColumns);
    }

    private isUserDefinedFieldColumn(name: string | undefined): boolean {
        return !!(name?.startsWith('udf_'));
    }
}
