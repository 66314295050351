import { AttachmentsCellRendererComponent } from './attachments-cell-renderer/attachments-cell-renderer.component';
import { AuthorCellRendererComponent } from './author-cell-renderer/author-cell-renderer.component';
import { CommentCellRendererComponent } from './comment-cell-renderer/comment-cell-renderer.component';
import {
    UserDefinedFieldCellRendererComponent,
} from './user-defined-field-cell-renderer/user-defined-field-cell-renderer.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const crosstalkCellRenderers: Record<string, new (...args: any[]) => any> = {
    commentCellRendererComponent: CommentCellRendererComponent,
    authorCellRendererComponent: AuthorCellRendererComponent,
    attachmentsCellRendererComponent: AttachmentsCellRendererComponent,
    userDefinedFieldCellRendererComponent: UserDefinedFieldCellRendererComponent,
};
