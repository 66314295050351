<div id="manageWidgetNav" #manageWidgetNav class="manage-widget" >
    <div class="menu">
        <div
            class="icon-left-right-expand"
            (click)="toggleNav()"
            [ngClass]="{'icon-left-expand': isNavExpanded, 'icon-right-expand': !isNavExpanded}"
            [title]="isNavExpanded ? 'Collapse' : 'Expand'">
        </div>

        <button class="ghost-btn dashboard-btn" [hidden]="!isNavExpanded" (click)="returnToDashboard()" (transitionend)="onButtonTransitionEnd($event)">
            <span class="icon-left-expand"></span>
            View
        </button>
    </div>

    <div class="create-find-area" *ngIf="isNavExpanded">
        <div class="block" (click)="createNewWidget()">
            <span class="icon-add-widgets2"></span>
            Create New
        </div>

        <div class="block" (click)="findWidget($event)">
            <span class="icon-find-widgets-2 icon-widget-finder"></span>
            Find A Widget
        </div>
    </div>

    <div class="manage-content-area" [hidden]="!isNavExpanded">
        <app-dsd-selector
            #dsdSelector
            [datasetDefinition]="datasetDefinition"
            (datasetDefinitionSelected)="onDatasetDefinitionSelected($event)"
            [showJumpLink]="true"
            [jumpedFrom]="'widgets'"
            [isReadOnly]="isReadOnly">
        </app-dsd-selector>

        <div class="select-visz">
            <p class="text-label">Select A Visualization</p>

            <div class="dropdown-wrapper viz-wrapper" appMousedownOutside (mousedownOutside)="showWidgetList=false">
                <div class="text-right">
                    <cc-icon
                        class="add-visualization-icon"
                        [icon]="'plus-circle'"
                        [disabled]="isReadOnly"
                        [size]="'small'"
                        title="Add Visualization"
                        (iconClicked)="showWidgets()">
                    </cc-icon>
                </div>

                <hr *ngIf="!selectedWidgets.length">

                <div class="dropdown viz-dropdown" *ngIf="showWidgetList && supportedWidgets.length">
                    <div *ngFor="let widget of supportedWidgets">
                        <ul class="vizforms" *ngIf="widget.isViz">
                            <li *ngFor="let config of widget.vizConfigs" (click)="toggleViz(config)">
                                <span [ngClass]="config.cssClass">
                                    <ddv-stacked-area-chart-icon
                                        *ngIf="isStackedAreaChartVizType(config.visualizationType)">
                                    </ddv-stacked-area-chart-icon>
                                </span>

                                <label>{{ config.name }}</label>

                                <cc-icon
                                    [ngClass]="!config.selected ? 'select-visualization' : 'clear-visualization'"
                                    [icon]="!config.selected ? 'plus-circle' : 'close'"
                                    [size]="'xsmall'"
                                    [hoverColor]="'var(--mw-viztype-active)'"
                                    (iconClicked)="toggleViz(config)">
                                </cc-icon>
                            </li>
                        </ul>

                        <div *ngIf="!widget.isViz && widget.isConfigurable" class="summary-widget" (click)="toggleWidget(widget)">
                            <span [ngClass]="widget.cssClass"></span>

                            <label>{{ widget.title }}</label>

                            <cc-icon
                                [ngClass]="!widget.selected ? 'select-visualization' : 'clear-visualization'"
                                [icon]="!widget.selected ? 'plus-circle' : 'close'"
                                [size]="'xsmall'"
                                [hoverColor]="'var(--mw-viztype-active)'"
                                (iconClicked)="toggleWidget(widget)">
                            </cc-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-details">
                <div class="tabtype" *ngIf="selectedWidgets.length">
                    <app-visualization-nav-header
                        #visualizationNavTabHeader
                        [vizTabs]="selectedWidgets"
                        (navTabFocus)="onNavTabFocus($event)"
                        (removeTab)="removeSelectedWidget($event)"
                        (dragDropEnd)="onDragDropSucceeded()"
                        [isReadOnly]="isReadOnly">
                    </app-visualization-nav-header>

                    <div class="inner-tabs">
                        <div class="tabset">
                            <div class="tabset-nav-container">
                                <ul class="nav nav-tabs">
                                    <li
                                            [ngClass]="{'active': selectedTab === 'configure'}"
                                            (click)="switchTab('configure')">
                                        Configure
                                    </li>

                                    <li
                                            [ngClass]="{'active': selectedTab === 'format'}"
                                            (click)="switchTab('format')">
                                        Format
                                    </li>

                                    <li
                                            *ngIf="showColorTab"
                                            [ngClass]="{'active': selectedTab === 'color'}"
                                            (click)="switchTab('color')">
                                        Color
                                    </li>
                                </ul>

                                <div class="option-container" *ngIf="showAutoAddDynamicColumnsOption">
                                    <cc-checkbox
                                        label="Auto Add Dynamic Columns"
                                        [(value)]="autoAddDynamicColumns"
                                        (valueChange)="onAutoAddDynamicColumnsChanged($event)">
                                    </cc-checkbox>
                                </div>
                            </div>

                            <div class="tabset-content">
                                <div class="tab-container" [ngClass]="{'active-tab' : selectedTab === 'configure', 'readonly': isReadOnly}">
                                    <div #configureTabContainer></div>
                                </div>

                                <div class="tab-container" [ngClass]="{'active-tab' : selectedTab === 'format', 'readonly': isReadOnly}">
                                    <div #formatTabContainer></div>
                                </div>

                                <div class="tab-container" [ngClass]="{'active-tab' : selectedTab === 'color', 'readonly': isReadOnly}">
                                    <div #colorTabContainer></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
