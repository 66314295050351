export class DdvDateTime {
    private constructor(private readonly date: Date) {}

    static get now(): DdvDateTime {
        return new DdvDateTime(new Date());
    }

    toString(): string {
        const year = this.date.getFullYear().toString();
        const month = (this.date.getMonth() + 1).toString();
        const day = this.date.getDate().toString();
        let hour = this.date.getHours().toString();
        hour = (hour.length === 1 ? '0' : '') + hour;
        let minute = this.date.getMinutes().toString();
        minute = (minute.length === 1 ? '0' : '') + minute;

        // M/D/Y HH:mm
        return `${month}/${day}/${year} ${hour}:${minute}`;
    }
}

