@if (headerText) {
    <div class="ag-cell-label-container" role="presentation">
        @if (params?.enableMenu) {
            <span #menuButton data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" (click)="onMenuClicked()">
                <i class="icon-advancedgrid-menu ag-icon-menu hamburger-menu"></i>
            </span>
        }

        <div data-ref="eLabel" class="ag-header-cell-label" role="presentation" (click)="onSortRequested($event)">
            <span data-ref="eText" class="ag-header-cell-text" [innerHTML]="headerText"></span>

            <span data-ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" [ngClass]="{'ag-hidden': !filterActive}">
                <i class="icon-filteroutline ag-icon-filter"></i>
            </span>

            @if (params?.enableSorting) {
                <span class="ag-sort-indicator-container" data-ref="eSortIndicator">
                    <span data-ref="eSortOrder" class="ag-sort-indicator-icon ag-sort-order" [ngClass]="{'ag-hidden': sortIndex === null}">{{ sortIndex }}</span>

                    <span data-ref="eSortAsc" class="ag-sort-indicator-icon ag-sort-ascending-icon" [ngClass]="{'ag-hidden': sortDirection !== 'asc'}">
                        <i class="sort-ascending"></i>
                    </span>

                    <span data-ref="eSortDesc" class="ag-sort-indicator-icon ag-sort-descending-icon" [ngClass]="{'ag-hidden': sortDirection !== 'desc'}">
                        <i class="sort-descending"></i>
                    </span>

                    <span data-ref="eSortMixed" class="ag-sort-indicator-icon ag-sort-mixed-icon ag-hidden">
                        <span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>
                    </span>

                    <span data-ref="eSortNone" class="ag-sort-indicator-icon ag-sort-none-icon" [ngClass]="{'ag-hidden': sortDirection}">
                        <span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>
                    </span>
                </span>
            }
        </div>
    </div>
}
