import { AgEditorComponent } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from '@ag-grid-community/core';
import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { convertStringToAscii } from '@ddv/utils';

@Component({
    selector: 'app-comment-cell-renderer',
    styleUrls: ['./comment-cell-editor.component.scss'],
    template: `<div class="editor-wrapper">
        <input #input [(ngModel)]="inputValue" (keydown)="onKeyDown($event)" (paste)="onPaste()">
        <span class="length-notice end" *ngIf="showMaxLengthWarning">The comment exceeds {{settings.maxCommentLength}} characters</span>
        <span class="length-notice start" *ngIf="showNoCommentWarning">You cannot send an empty comment.</span>
    </div>`,
})
export class CommentCellEditorComponent implements AgEditorComponent, AfterViewInit {
    inputValue: string | undefined;
    originalValue: string | undefined;
    showMaxLengthWarning = false;
    showNoCommentWarning = false;
    maxLengthWarningTimeout: number | undefined;
    noCommentWarningTimeout: number | undefined;
    timeout = 2000;
    settings: { asciiOnly?: boolean, maxCommentLength?: number } = { asciiOnly: false, maxCommentLength: undefined };

    @ViewChild('input', { read: ViewContainerRef, static: true }) private readonly input: ViewContainerRef | undefined;

    afterGuiAttached(_?: IAfterGuiAttachedParams): void {}

    agInit(params: Partial<ICellEditorParams>): void {
        this.originalValue = params.value || '';
        this.inputValue = this.originalValue;

        if (params.data && params.colDef && params.data[`${params.colDef.field}_settings`]) {
            this.settings = { ...this.settings, ...params.data[`${params.colDef.field}_settings`] };
        }
    }

    onPaste(): void {
        // this timeout ensures that the inputValue has been replaced before we validate it.
        window.setTimeout(() => {
            if (this.inputValue?.trim().length === 0) {
                this.showEmptyCommentAlert();
            }

            if (this.settings.asciiOnly) {
                this.inputValue = convertStringToAscii(this.inputValue ?? '');
            }

            if (this.settings.maxCommentLength && this.settings.maxCommentLength <= (this.inputValue?.length ?? 0)) {
                this.inputValue = this.inputValue?.substr(0, this.settings.maxCommentLength);
                this.showMaxLengthAlert();
            }
        });
    }

    onKeyDown(event: KeyboardEvent): void {
        // don't take any action on meta-keys or if not ascii only and not limited to a max length
        if (event.metaKey || (!this.settings.maxCommentLength && !this.settings.asciiOnly)) {
            return;
        }

        const charCode = event.which || event.keyCode;
        // if which and keyCode are not available, test to see if the key name is only one character
        const isSpecial = (charCode && charCode < 48 && charCode !== 32) || !/^.$/.test(event.key);
        let exceedsMaxLength = false;

        if (this.settings.maxCommentLength && this.settings.maxCommentLength <= (this.inputValue?.length ?? 0) && !isSpecial) {
            exceedsMaxLength = true;
            this.showMaxLengthAlert();
        } else {
            this.showMaxLengthWarning = false;
            window.clearTimeout(this.maxLengthWarningTimeout);
        }

        if (this.inputValue?.trim().length === 0 && isSpecial) {
            this.showEmptyCommentAlert();
        } else {
            this.showNoCommentWarning = false;
            window.clearTimeout(this.noCommentWarningTimeout);
        }

        if (this.settings.asciiOnly) {
            this.inputValue = convertStringToAscii(this.inputValue ?? '');
        }

        if (exceedsMaxLength && event.preventDefault) {
            event.preventDefault();
        }
    }

    private showMaxLengthAlert(): void {
        this.showMaxLengthWarning = true;

        window.clearTimeout(this.maxLengthWarningTimeout);

        this.maxLengthWarningTimeout = window.setTimeout(() => {
            this.showMaxLengthWarning = false;
        }, this.timeout);
    }

    private showEmptyCommentAlert(): void {
        this.showNoCommentWarning = true;

        window.clearTimeout(this.noCommentWarningTimeout);

        this.noCommentWarningTimeout = window.setTimeout(() => {
            this.showNoCommentWarning = false;
        }, this.timeout);
    }

    getFrameworkComponentInstance(): void {}

    getValue(): string | undefined {
        return this.inputValue;
    }

    isCancelAfterEnd(): boolean {
        this.inputValue = this.inputValue?.trim();

        if (!this.inputValue?.length) {
            this.showEmptyCommentAlert();
        }

        return this.inputValue?.length === 0 || this.inputValue === this.originalValue;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    ngAfterViewInit(): void {
        // this is straight from the ag-grid documentation and it doesn't work without the setTimeout()
        window.setTimeout(() => {
            this.input?.element.nativeElement.focus();
            this.input?.element.nativeElement.setSelectionRange(0, this.inputValue?.length);
        });
    }
}
