import { VisualizationPreferences } from '@ddv/models';
import { deepClone } from '@ddv/utils';

import { BaseVisualizationComponent } from './base/base-visualization.component';
import {
    DonutChartVisualizationComponent,
} from './charts/components/donut-chart-visualization/donut-chart-visualization.component';
import {
    HorizontalBarChartVisualizationComponent,
} from './charts/components/horizontal-bar-chart-visualization/horizontal-bar-chart-visualization.component';
import {
    HorizontalStackedBarChartVisualizationComponent,
} from './charts/components/horizontal-stacked-bar-chart-visualization/horizontal-stacked-bar-chart-visualization.component';
import {
    LineChartVisualizationComponent,
} from './charts/components/line-chart-visualization/line-chart-visualization.component';
import {
    PieChartVisualizationComponent,
} from './charts/components/pie-chart-visualization/pie-chart-visualization.component';
import {
    StackedAreaChartVisualizationComponent,
} from './charts/components/stacked-area-chart-visualization/stacked-area-chart-visualization.component';
import {
    VerticalBarChartVisualizationComponent,
} from './charts/components/vertical-bar-chart-visualization/vertical-bar-chart-visualization.component';
import {
    VerticalMirrorBarChartVisualizationComponent,
} from './charts/components/vertical-mirror-bar-chart-visualization/vertical-mirror-bar-chart-visualization.component';
import {
    VerticalStackedBarChartVisualizationComponent,
} from './charts/components/vertical-stacked-bar-chart-visualization/vertical-stacked-bar-chart-visualization.component';
import { defaultVisualizationPreferencesMap } from './default-visualization-preferences';
import { AdvancedGridVisualizationComponent } from './grids/components/advanced-grid-visualization/advanced-grid-visualization.component';
import { SimpleGridVisualizationComponent } from './grids/components/simple-grid-visualization/simple-grid-visualization.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type VisualizationConstructor = new (...args: any[]) => BaseVisualizationComponent;
export interface VisualizationDescriptor {
    id: string;
    name: string;
    selector: string;
    component: VisualizationConstructor;
    cssClass: string;
}

export function getDefaultVisualizationPreferences(visualizationType: string): VisualizationPreferences[] {
    // we have to cast to any because the JSON has no type information
    return deepClone(defaultVisualizationPreferencesMap[visualizationType]);
}

export const visualizationDescriptorMap: Record<string, VisualizationDescriptor> = {
    PIE_CHART: {
        id: 'pieChart',
        name: 'Pie Chart',
        selector: 'app-pie-chart-visualization',
        component: PieChartVisualizationComponent,
        cssClass: 'icon-pie-chart',
    },
    DONUT_CHART: {
        id: 'donutChart',
        name: 'Donut Chart',
        selector: 'app-donut-chart-visualization',
        component: DonutChartVisualizationComponent,
        cssClass: 'icon-donut',
    },
    LINE_CHART: {
        id: 'lineChart',
        name: 'Line Chart',
        selector: 'app-line-chart-visualization',
        component: LineChartVisualizationComponent,
        cssClass: 'icon-line-chart',
    },
    STACKED_AREA_CHART: {
        id: 'stackedAreaChart',
        name: 'Stacked Area Chart',
        selector: 'ddv-stacked-area-chart-visualization',
        component: StackedAreaChartVisualizationComponent,
        cssClass: 'icon-stacked-area-chart',
    },
    SIMPLE_GRID: {
        id: 'simpleGrid',
        name: 'Simple Grid',
        selector: 'app-simple-grid-visualization',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component: SimpleGridVisualizationComponent as any,
        cssClass: 'icon-simple-grid',
    },
    ADVANCED_GRID: {
        id: 'advancedGrid',
        name: 'Advanced Grid',
        selector: 'app-advanced-grid-visualization',
        component: AdvancedGridVisualizationComponent,
        cssClass: 'icon-advancedgrid',
    },
    HORIZONTAL_BAR_CHART: {
        id: 'hBarChart',
        name: 'Horizontal Bar Chart',
        selector: 'app-hbar-chart-visualization',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component: HorizontalBarChartVisualizationComponent as any,
        cssClass: 'icon-horizontal-bar',
    },
    VERTICAL_BAR_CHART: {
        id: 'vBarChart',
        name: 'Vertical Bar Chart',
        selector: 'app-vbar-chart-visualization',
        component: VerticalBarChartVisualizationComponent,
        cssClass: 'icon-vertical-bar',
    },
    HORIZONTAL_STACKED_BAR_CHART: {
        id: 'hsBarChart',
        name: 'Horizontal Stacked Bar',
        selector: 'app-hsbar-chart-visualization',
        component: HorizontalStackedBarChartVisualizationComponent,
        cssClass: 'icon-horizontal-stacked',
    },
    VERTICAL_STACKED_BAR_CHART: {
        id: 'vsBarChart',
        name: 'Vertical Stacked Bar',
        selector: 'app-vsbar-chart',
        component: VerticalStackedBarChartVisualizationComponent,
        cssClass: 'icon-vertical-stacked',
    },
    VERTICAL_MIRRORED_BAR_CHART: {
        id: 'vmBarChart',
        name: 'Mirrored Bar',
        selector: 'app-vmbar-chart-visualization',
        component: VerticalMirrorBarChartVisualizationComponent,
        cssClass: 'icon-vertical-mirrored',
    },
};
