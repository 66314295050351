import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SelectedValues, ValueOption } from '@ddv/common-components';
import { Client } from '@ddv/models';
import { ClientsService } from '@ddv/reference-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-clients-form',
    templateUrl: './clients-dashboard-form.component.html',
    styleUrls: ['./clients-dashboard-form.component.scss'],
})
export class ClientsDashboardFormComponent implements OnInit, OnChanges {
    @Input() selectedClients: Client[] = [];

    @Output() clientsApply = new EventEmitter<Client[]>();

    clients: string[] | undefined;
    clientsOptions: Observable<ValueOption[]> | undefined;

    constructor(private readonly clientsService: ClientsService) { }

    ngOnChanges(): void {
        this.selectedClients = this.selectedClients || [];
    }

    ngOnInit(): void {
        this.clientsService.clients().subscribe((clients) => this.clients = clients);
        this.clientsOptions = this.clientsService.clients()
            .pipe(
                map((clients) => clients.map((client) => {
                    return {
                        texts: [client],
                        value: client,
                        checked: this.selectedClients.map((el) => el.clientId).includes(client),
                    };
                })));
    }

    onAddingClients(selectedClients?: SelectedValues): void {
        this.clientsApply.emit(selectedClients?.values?.map((el) => ({ clientId: el, clientName: el })));
    }
}
