import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
    Component,
    OnInit,
    OnChanges,
    EventEmitter,
    ViewChild,
    ViewChildren,
    QueryList,
    ElementRef,
    Input,
    Output,
    AfterViewInit,
    SimpleChanges,
} from '@angular/core';
import { VisualizationType } from '@ddv/models';

@Component({
    selector: 'app-visualization-nav-header',
    templateUrl: './visualization-nav-header.component.html',
    styleUrls: ['./visualization-nav-header.component.scss'],
})
export class VisualizationNavHeaderComponent implements OnInit, OnChanges, AfterViewInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() vizTabs: any[] = [];
    @Input() isReadOnly = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() navTabFocus = new EventEmitter<any>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() removeTab = new EventEmitter<any>();
    @Output() dragDropEnd = new EventEmitter<boolean>();

    protected nextNavPermits = false;
    protected prevNavPermits = false;
    protected navArrowsVisible = false;

    private activeTabIndex = -1;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private activeTabGuid: any | undefined;

    @ViewChild('scrollContainer', { static: true }) private readonly scrollContainer: ElementRef | undefined;
    @ViewChildren('headers', { read: ElementRef }) private readonly headers: QueryList<ElementRef> | undefined;

    ngAfterViewInit(): void {
        this.updateNavigationFlags(this.scrollContainer?.nativeElement);
    }

    onScroll(event: Event): void {
        this.updateNavigationFlags(event.target as HTMLElement);
    }

    private updateNavigationFlags(container: HTMLElement): void {
        this.nextNavPermits = !(Math.ceil(container.scrollLeft + container.offsetWidth) >= container.scrollWidth);
        this.prevNavPermits = container.scrollLeft > 0;
        this.navArrowsVisible = (container.scrollWidth - container.offsetWidth) > 5;
    }

    ngOnInit(): void {
        this.initializeTab();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.vizTabs.previousValue &&
            changes.vizTabs.previousValue[0].uid !== changes.vizTabs.currentValue[0].uid) {
            this.initializeTab();
        }

        if (changes.vizTabs.currentValue?.[0].vizTabs?.length) {
            this.navArrowsVisible = this.nextNavPermits = changes.vizTabs.currentValue[0].vizTabs.length > 6;
        }
    }

    initializeTab(direct = true): void {
        this.activeTabIndex = 0;
        this.navTabSelection(this.getTabOnParam({ onIndex: true, onActive: false }), direct);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private vizTabHasChildTabs(vizTab: any): boolean {
        return !!(vizTab.isViz && vizTab.vizTabs);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getTabFromVizTabs(param: { onIndex: boolean, onActive: boolean }, tabCounter: number, vizTab: any): any {
        if (param.onIndex && this.activeTabIndex === tabCounter) {
            return vizTab;
        }
        if (param.onActive && vizTab.active) {
            this.activeTabIndex = tabCounter;
            return vizTab;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getTabOnParam(param: { onIndex: boolean, onActive: boolean }): any {
        let tabCounter = 0;
        for (const vizTab of this.vizTabs) {
            if (this.vizTabHasChildTabs(vizTab)) {
                for (const childVizTab of vizTab.vizTabs) {
                    const activeTab = this.getTabFromVizTabs(param, tabCounter, childVizTab);
                    if (activeTab) {
                        return activeTab;
                    }
                    tabCounter += 1;
                }
            } else {
                const activeTab = this.getTabFromVizTabs(param, tabCounter, vizTab);
                if (activeTab) {
                    return activeTab;
                }
                tabCounter += 1;
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getParentTabOnGuid(vizTabGuid: any): any {
        return this.vizTabs.find((vizTab) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.vizTabHasChildTabs(vizTab) && vizTab.vizTabs.some((tab: any) => tab.guid === vizTabGuid),
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private setVizTabActive(vizTabGuid: any): void {
        this.vizTabs.forEach((viztab) => {
            if (this.vizTabHasChildTabs(viztab)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                viztab.vizTabs.forEach((tab: any) => { tab.active = tab.guid === vizTabGuid; });
            } else {
                viztab.active = viztab.guid === vizTabGuid;
            }
        });
    }

    private getTabsCount(): number {
        return this.vizTabs.reduce((tabCounter: number, vizTab) =>
            this.vizTabHasChildTabs(vizTab) ? tabCounter + (vizTab.vizTabs.length as number) : tabCounter + 1, 0);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navTabSelection(vizTab: any | undefined, direct = true): void {
        if (!vizTab || this.activeTabGuid === vizTab.guid) {
            return;
        }

        this.activeTabGuid = vizTab.guid;

        if (this.headers && direct) {
            const headers = this.headers.toArray();
            this.activeTabIndex = headers.length ?
                headers.findIndex((header) => header.nativeElement.id === vizTab.guid) :
                0;
        }
        this.setVizTabActive(vizTab.guid);

        this.navTabFocus.emit({ vizTab, tabIndex: this.activeTabIndex });
    }

    private setNavTabScrollPosition(moveDirection?: string): void {
        if (!this.scrollContainer) {
            return;
        }

        const scrollDefaultWidth = 40;
        if (!moveDirection) {
            const vizTabWidth = Math.floor(this.scrollContainer.nativeElement.scrollWidth / this.getTabsCount());
            this.scrollContainer.nativeElement.scrollLeft = (this.activeTabIndex * vizTabWidth) - scrollDefaultWidth;
        } else {
            this.scrollContainer.nativeElement.scrollLeft = moveDirection === 'next' ?
                (this.scrollContainer.nativeElement.scrollLeft as number) + scrollDefaultWidth :
                this.scrollContainer.nativeElement.scrollLeft - scrollDefaultWidth;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    removeSelectedTab(vizTab: any, index: number, hierarchy: string): void {
        if (hierarchy === 'root') {
            this.vizTabs.splice(index, 1);
        } else {
            const vizConfig = this.getParentTabOnGuid(vizTab.guid);
            vizConfig.vizTabs.splice(index, 1);
        }

        this.removeTab.emit({ tab: vizTab, hierarchy, index });
    }

    onNavigation(moveDirection: string): void {
        this.setNavTabScrollPosition(moveDirection);
    }

    onNavTabDropEnd(): void {
        this.dragDropEnd.emit(true);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDragEnd(event: CdkDragDrop<any[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
        this.dragDropEnd.emit(true);
    }

    loadActiveNavTab(): void {
        const activeVizTab = this.getTabOnParam({ onIndex: false, onActive: true });
        if (activeVizTab && activeVizTab.guid !== this.activeTabGuid) {
            this.navTabSelection(activeVizTab, false);
            this.setNavTabScrollPosition();
        }
    }

    isStackedAreaChartVizType(visualizationType: VisualizationType): boolean {
        return visualizationType === 'STACKED_AREA_CHART';
    }
}

