import { ViewRef, ComponentRef } from '@angular/core';

export class ModalDialogContentRef {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(public nodes: any[], public viewRef?: ViewRef, public componentRef?: ComponentRef<any>) {}
}

export function isString(value: unknown): value is string {
    return typeof value === 'string';
}

export function isDefined(value: unknown): boolean {
    return value != null;
}
