export class ErrorResponseDetail {
    statusCode: number;
    statusText: string;
    url: string | null;
    body: unknown;
    requestParameters?: Record<string, unknown>;

    constructor(statusCode: number, statusText: string, url: string | null, body: unknown) {
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.url = url;

        if (typeof body === 'string') {
            try {
                this.body = JSON.parse(body);
            } catch (e) {
                this.body = body;
            }
        } else {
            this.body = body;
        }
    }
}
