import {
    ColDef,
    ColumnPinnedEvent,
    FilterChangedEvent,
    PostSortRowsParams,
    RowGroupingDisplayType,
    RowSelectionOptions,
    SortChangedEvent,
} from '@ag-grid-community/core';
import { NO_ROWS_TO_SHOW_MESSAGE } from '@ddv/models';

import { GetContextMenuItems, GetMainMenuItems, AutoGroupColDef, CellRendererFunc } from './interfaces';

export interface DataGridOptions {
    rowSelection?: RowSelectionOptions;
    headerHeight?: number;
    rowHeight?: number;
    suppressDragLeaveHidesColumns?: boolean;
    suppressRowGroupHidesColumns?: boolean;
    navigateToNextCell?: unknown;
    getRowStyle?: unknown;
    getRowClass?: unknown;
    rowClass?: string;
    pagination?: boolean;
    suppressScrollOnNewData?: boolean;
    suppressPaginationPanel?: boolean;
    paginationAutoPageSize?: boolean;
    paginationStartPage?: number;
    getNodeChildDetails?: unknown;
    rowModelType?: string;
    paginationPageSize?: number;
    cacheOverflowSize?: number;
    maxConcurrentDatasourceRequests?: number;
    infiniteInitialRowCount?: number;
    maxPagesInCache?: number;
    suppressContextMenu?: boolean;
    getContextMenuItems?: GetContextMenuItems;
    getMainMenuItems?: GetMainMenuItems;
    allowContextMenuWithControlKey?: boolean;
    suppressMenuHide?: boolean;
    groupUseEntireRow?: boolean;
    groupTotalRow?: boolean;
    groupDefaultExpanded?: number | null;
    groupRemoveSingleChildren?: boolean;
    groupDisplayType?: RowGroupingDisplayType;
    suppressAggFuncInHeader?: boolean;
    suppressHorizontalScroll?: boolean;
    suppressGroupRowsSticky?: boolean;
    icons?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: ((...args: any[]) => any) | string;
    };
    onFilterChanged?(event?: FilterChangedEvent): void;
    onSortChanged?(event?: SortChangedEvent): void;
    defaultColDef?: ColDef;
    rowGroupPanelShow?: string;
    rowGroupPanelSuppressSort?: boolean;
    showToolPanel?: boolean;
    pivotMode?: boolean | null;
    autoGroupColumnDef?: AutoGroupColDef;
    autoSizePadding?: number;
    onColumnResized?(event?: unknown): void;
    quickFilterText?: string;
    onColumnPinned?(event?: ColumnPinnedEvent): void;
    onCellClicked?(event?: unknown): void;
    groupRowRenderer?: CellRendererFunc | string;
    sideBar?: boolean;
    scrollbarWidth?: number;
    singleClickEdit?: boolean;
    stopEditingWhenCellsLoseFocus?: boolean;
    cellSelection?: boolean;
    functionsReadOnly?: boolean;
    postSortRows?(params: PostSortRowsParams): void;
    tooltipHideDelay?: number;
    localeText?: { [key: string]: string };
    columnMenu?: string;
}

export class DefaultDataGridOptions implements DataGridOptions {
    rowSelection = {
        mode: 'multiRow' as RowSelectionOptions['mode'],
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: true,
    };
    headerHeight = 30;
    rowHeight = 22;
    suppressDragLeaveHidesColumns = true;
    suppressRowGroupHidesColumns = true;
    pagination = false;
    suppressScrollOnNewData = false;
    suppressPaginationPanel = false;
    paginationPageSize = 100;
    cacheOverflowSize = 1;
    maxConcurrentDatasourceRequests = 1;
    infiniteInitialRowCount = 1;
    suppressContextMenu = true;
    allowContextMenuWithControlKey = true;
    suppressMenuHide = false;
    groupUseEntireRow = false;
    groupTotalRow = false;
    groupDefaultExpanded = 0;
    groupRemoveSingleChildren = false;
    suppressAggFuncInHeader = true;
    suppressHorizontalScroll = false;
    defaultColDef = { sortable: true, filter: true, resizable: true };
    autoGroupColumnDef = { rowGroupColumnHeader: true };
    sideBar = false;
    scrollbarWidth = 8;
    singleClickEdit = true;
    stopEditingWhenCellsLoseFocus = true;
    cellSelection = true;
    tooltipHideDelay = 2000;
    localeText = { noRowsToShow: NO_ROWS_TO_SHOW_MESSAGE };
    columnMenu = 'legacy';
}
