export class BoardWidgetDefinitionIds {
    constructor(
        public readonly dashboardId?: string | number,
        public readonly widgetId?: number,
        public readonly definitionId?: number | string,
    ) {}

    toRecord(): Record<string, unknown> {
        return {
            dashboardId: this.dashboardId,
            widgetId: this.widgetId,
            definitionId: this.definitionId,
        };
    }
}
