<div class="search-box">
    <input
        type="search"
        class="formControl"
        name="value"
        required
        maxlength="40"
        pattern="^[\w\/\s-]+$"
        autocomplete="off"
        [(ngModel)]="value"
        (keyup)="onFamilyValueValidation(value)"
        #input="ngModel"/>

    <span class="icon-search1 close-pos"></span>

    <button
        class="empty-btn"
        type="button"
        (click)="onDashboardFamilyCreation(value)"
        [disabled]="isDisabled || input.invalid">
        Add
    </button>
</div>

<div class="db-group">
    <ul class="db-group-list">
        <li
              class="db-group-name"
              [ngClass]="{'db-group-selected' : currentDashboardGroup!.name === dbGroup.name}"
              *ngFor="let dbGroup of dashboardGroupsList | dashboardFamilyFilter : value"
              (click)="onDashboardFamilyChange(dbGroup)">

            <span innerHTML="{{ dbGroup.name || 'None' | highlight : value }}"></span>
        </li>
    </ul>
</div>

