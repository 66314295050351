export const outdatedCustomColorMap: Map<string, string> = new Map<string, string>([
    ['#03506F', '#0E516E'],
    ['#840b73', '#0E516E'],
    ['#29AAE1', '#00AEEF'],
    ['#8ED8F8', '#00AEEF'],
    ['#BE202E', '#BE1E2D'],
    ['#6d124a', '#BE1E2D'],
    ['#8CC540', '#628D33'],
    ['#D0E4AA', '#8FC44A'],
    ['#cff19a', '#8FC44A'],
    ['#FFC940', '#F1BF36'],
    ['#FFE3A6', '#F1BF36'],
    ['#f2e99b', '#F1BF36'],
    ['#F7941D', '#F7941E'],
    ['#F9A980', '#EF4136'],
    ['#EF4123', '#EF4136'],
    ['#e5e0e5', '#D1D2D3'],
    ['#333333', '#000'],
    ['#232323', '#000'],
]);

export const lightCustomColorMap: Map<string, string> = new Map([
    ['#58595B', '#D1D2D3'],
    ['#808285', '#A7A8AB'],
    ['#BCBEC0', '#808184'],
    ['#FFF', '#000'],
]);

export const darkCustomColorMap: Map<string, string> = new Map([
    ['#D1D2D3', '#58595B'],
    ['#A7A8AB', '#808285'],
    ['#808184', '#BCBEC0'],
    ['#000', '#FFF'],
]);
