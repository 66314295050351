import { ColumnMovedEvent, ColumnState } from '@ag-grid-community/core';
import { Component, ElementRef } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { AlertService } from '@ddv/common-components';
import { CrosstalkModalService, CrosstalkService } from '@ddv/crosstalk';
import { DatasetManagerService, MetadataService } from '@ddv/datasets';
import { UserEntitlementService } from '@ddv/entitlements';
import { ClientDatasetFilterService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    WIDGET_LIFECYCLE_EVENT,
    ExportFilteredData,
    VisualizationConfigs,
    WidgetLifeCycleData,
    WidgetLifecycleEvent, DataUpdateBody,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { deepClone } from '@ddv/utils';

import { SelectedWidgetRelayService } from '../../../base/selected-widget-relay.service';
import { BaseGridVisualizationComponent } from '../../base-grid-visualization.component';
import { SimpleGridConfigService } from '../../services/simple-grid-config.service';
import { UserGridColumnOverridesService } from '../../services/user-grid-column-overrides.service';

@Component({
    selector: 'app-simple-grid-visualization',
    templateUrl: './simple-grid-visualization.component.html',
    styleUrls: ['../../grid.component.scss'],
})
export class SimpleGridVisualizationComponent extends BaseGridVisualizationComponent {
    quickSearchPlaceholder = 'Type to filter';

    constructor(
            alertService: AlertService,
            clientDatasetFilterService: ClientDatasetFilterService,
            elementRef: ElementRef,
            crosstalkModalService: CrosstalkModalService,
            gridConfigService: SimpleGridConfigService,
            manager: ManagerService,
            metadataService: MetadataService,
            crosstalkService: CrosstalkService,
            fuzzyDatesService: FuzzyDatesService,
            userEntitlementService: UserEntitlementService,
            userGridColumnOverridesService: UserGridColumnOverridesService,
            currentStateService: CurrentStateService,
            selectedWidgetRelayService: SelectedWidgetRelayService,
            datasetManagerService: DatasetManagerService) {
        super(alertService,
            clientDatasetFilterService,
            elementRef,
            crosstalkModalService,
            gridConfigService,
            metadataService,
            crosstalkService,
            fuzzyDatesService,
            userEntitlementService,
            userGridColumnOverridesService,
            currentStateService,
            manager,
            selectedWidgetRelayService,
            datasetManagerService);
    }

    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.MASTER_FILTERS_REMOVED:
                if (this.isMasterWidget()) {
                    this.deselectAllSelected();
                }
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                super.widgetLifeCycleCallBack(eventName as any, data as any);
        }
    }

    override getExportFilteredData(): ExportFilteredData {
        const prefs = this.setPreferences();
        return { data: this.gridConfigService.getExportFilteredData(this.dataGridComponent, prefs), summary: {} };
    }

    override getExportFullData(): ExportFilteredData {
        return { data: this.gridConfigService.getExportFullData(this.dataGridComponent), summary: {} };
    }

    override deselectAllSelected(clickOutside = false): void {
        if (clickOutside && this.isMasterWidget()) {
            return;
        }

        super.deselectAllSelected();
    }

    onColumnMoved(event: ColumnMovedEvent, columns: ColumnState[] = []): void {
        if (event.source === 'uiColumnMoved' && this.isInViewMode) {
            const nonDynamicColumns = this.filterOutDynamicColumns();
            const columnOrder = columns.filter((c) => nonDynamicColumns.includes(c.colId)).map((c) => c.colId);

            this.tempColumnOrder = [...columnOrder];
        }
    }

    private setPreferences(): VisualizationConfigs {
        if (!this.preferences) {
            throw new Error('cannot setPreferences without preferences');
        }

        const prefs = deepClone(this.preferences);
        prefs.showSubTotal = true;
        return prefs;
    }
}
