<div #formatParentContainer class="format">
    <div [ngClass]="{'format-parent' : !formatter.hidden }" *ngFor="let formatter of formatters">
        <div *ngIf="!formatter.hidden" class="format-details">
            <div class="header-title-block">
                <div class="left-flex">
                    <label class="text-label-format">
                        <span
                            class="icon"
                            *ngIf="formatter.configs"
                            [ngClass]="formatter.isClosed ? 'icon-arrow-right ' : ' icon-expand'"
                            title="{{ formatter.isClosed ? 'Expand' : 'Collapse' }}"
                            (click)="formatter.isClosed = !formatter.isClosed">
                        </span>

                        {{ formatter.label }}
                    </label>
                </div>

                <div class="right-flex">
                    <ng-container *ngIf="formatter.type === 'toggle'">
                        <ng-container *ngTemplateOutlet="toggleTemplate;context: {ctx: formatter, isDisabled: false}"></ng-container>
                    </ng-container>

                    <ng-container *ngIf="formatter.type && formatter.type !== 'toggle'">
                        <ng-container *ngTemplateOutlet="reuseHtml;context: {ctx: formatter, isDisabled: false}"></ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="configs-wrapper" *ngIf="!formatter.isClosed">
                <div class="config-item" *ngFor="let config of formatter.configs" [ngClass]="config.cssClass">
                    <ng-container *ngTemplateOutlet="reuseHtml;context: {ctx: config, isDisabled: !formatter.selectedValue}"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>


<!--- Templates Defined -->
<ng-template #toggleTemplate let-ctx="ctx" let-isDisabled="isDisabled">
    <div class="toggle-container {{ctx.cssClass}}">
        <cc-option-toggle
            class="toggle-checkbox"
            id="{{ctx.id}}"
            #display_as_custom
            name="displayNameType"
            [disabled]="isDisabled || isReadOnly"
            [ngClass]="{'disabled-toggle': isDisabled || isReadOnly}"
            [(value)]="ctx.selectedValue"
            [labels]="getOptionLabels(ctx)"
            (valueChange)="renderPreview({ target: $event }, ctx.id)">
        </cc-option-toggle>
    </div>
</ng-template>

<ng-template #reuseHtml let-ctx="ctx" let-isDisabled="isDisabled">
    <div class="config-item-child" [ngClass]="ctx.cssClass">
        <p class="text-label" *ngIf="!ctx.hidden">{{ ctx.label }}</p>

        <ng-container *ngIf="ctx.type === 'toggle'">
            <ng-container *ngTemplateOutlet="toggleTemplate; context: {ctx: ctx, isDisabled: isDisabled}"></ng-container>
        </ng-container>

        <ng-container *ngIf="ctx.type === 'range' && !ctx.hidden">
            <div class="input-range">
                <input
                    type="text"
                    class="formControl"
                    autocomplete="off"
                    [(ngModel)]="ctx.selectedValue"
                    [disabled]="ctx.disabled || isDisabled || isReadOnly"
                    (input)="checkCustomValue(ctx.id)"
                    (focusout)="onFocusOut(ctx)"
                    appInputRestrict
                    allowPattern="^-?\d*(?:\.\d*)?$" />
                <span class="range-delimiter">-</span>
            </div>
        </ng-container>

        <ng-container *ngIf="ctx.type === 'select' && !ctx.hidden">
            <div class="custom-parent {{ctx.id}}">
                <app-dropdown
                    class="{{ctx.name}}"
                    id="valuetype_{{ctx.id}}"
                    [disabled]="isDisabled"
                    [options]="dropdownOptions[ctx.id]"
                    [selected]="dropdownSelectedValues[ctx.id]"
                    (selectedChange)="ctx.selectedValue = $event.value; renderPreview({ target: $event }, ctx.id)"
                    [readonly]="isReadOnly">
                </app-dropdown>
            </div>

            <div class="drag-drop-parent" *ngIf="showConfigCustomSortOrder(ctx)">
                <p class="text-label">Custom sort order</p>

                <div class="custom-sort-wrap" [ngClass]="{'readonly': isReadOnly}">
                    <input type="text" maxlength="50" name="customSortOption" autocomplete="off" [(ngModel)]="customSortOption">

                    <button class="empty-btn" type="button" (click)="addCustomSortOption()">Add</button>
                </div>

                <div cdkDropList class="drag-wrap" (cdkDropListDropped)="dropItem($event, filterAttributeList.get(selectedSlicerValue ?? ''))">
                    <div class="drag-list" *ngFor="let attr of filterAttributeList.get(selectedSlicerValue ?? '')" cdkDragBoundary=".drag-wrap" cdkDrag>
                        <span class="icon-threedots"></span>

                        <label class="item">{{ attr.value }}</label>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="ctx.type === 'text' && !ctx.hidden">
            <div class="input-parent">
                <input
                    *ngIf="ctx.dataType === 'number'; else elseBlock"
                    [disabled]="isDisabled"
                    type="number"
                    class="formControl"
                    id="label_{{ctx.id}}"
                    min="{{ctx.minValue}}"
                    max="{{ctx.maxValue}}"
                    appInputRestrict
                    allowPattern="{{ctx.allowPattern}}"
                    [(ngModel)]="ctx.selectedValue"
                    (change)="renderPreview()"
                    [attr.disabled]="isReadOnly ? true : null"/>

                <ng-template #elseBlock>
                    <input
                        type="text"
                        class="formControl"
                        id="label_{{ctx.id}}"
                        autocomplete="off"
                        [disabled]="isDisabled || isReadOnly"
                        [(ngModel)]="ctx.selectedValue"
                        (change)="renderPreview()" />
                </ng-template>
            </div>
        </ng-container>

        <ng-container *ngIf="ctx.type === 'multi-select'">
            <app-multi-select
                [listItems]="ctx.values"
                [isDisabled]="isDisabled"
                [selectedItems]="ctx.selectedValue"
                (selectedListChange)="onMultiSelectChanged($event, ctx)">
            </app-multi-select>
        </ng-container>

        <ng-container *ngFor="let config of ctx.configs">
            <ng-container *ngTemplateOutlet="reuseHtml;context: {ctx: config, isDisabled: isDisabled}"></ng-container>
        </ng-container>

        <small class="errormessage" *ngIf="ctx.hasError && !(ctx.disabled || isDisabled)">
            Please enter a valid data range.
        </small>
    </div>
</ng-template>
<!--- Templates Defined -->
