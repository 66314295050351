import {
    CellClassParams,
    CellStyle,
    ColDef,
    Column,
    ColumnResizedEvent,
    IRowNode,
    NewValueParams,
    ValueFormatterParams,
    ValueGetterParams,
    ValueParserParams,
} from '@ag-grid-community/core';
import { ElementRef, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { CrosstalkModalService } from '@ddv/crosstalk';
import {
    ColumnMinMax,
    CustomCellRendererParams,
    CustomColDef,
    CustomColGroupDef,
    DataGridComponent,
    DataGridOptions,
    DefaultDataGridOptions,
    GridConfiguration,
    GridState,
} from '@ddv/data-grid';
import { ErrorLoggerService } from '@ddv/error-handling';
import { QueryParamsService } from '@ddv/filters';
import { booleanFormatter, dateFormatter, FormatterService, numberFormatter, percentageFormatter } from '@ddv/formatters';
import { ManagerService } from '@ddv/layout';
import {
    AGFilter,
    AgGridFilterState,
    AgGridSortDirection,
    AppWidgetState,
    AUTO_GROUP_COLUMN_ID,
    CompareColumnID,
    CompareColumnName,
    ConfigItem,
    crosstalkCheckboxFieldId,
    CrosstalkFields,
    CustomFilterParams,
    DashboardPreference,
    DatasetDefinition,
    EDITABLE_CHECKBOX_FIELD,
    ExportColumn,
    getCurrentVisualizationId,
    GRID_ICONS,
    CellFormat,
    isTradeFileDetails,
    LinkConfiguration,
    MANAGE_WIDGET_ID,
    MetadataFormatUtils,
    MetadataLookup,
    NUMBER_FILTER_OPERATIONS,
    NUMBER_FILTER_OPERATIONS_FOR_RANKED_COLUMN,
    NumberFormat,
    ColumnDisplayType,
    ColumnNegativeValues,
    TFL_DETAILS_AUTO_GROUP_COLUMN_ID,
    TRANSACTION_TYPE_FIELD,
    TrebekConversationFields,
    UserDefinedFieldType,
    VisualizationConfigs,
} from '@ddv/models';
import { camelCaseString, clone, deepClone, deepExtend, getMaximumValue, getMinimumValue } from '@ddv/utils';

import { SelectedWidgetRelayService } from '../../base/selected-widget-relay.service';
import { CheckboxCellRendererComponent } from '../cell-renderers/checkbox-cell-renderer/checkbox-cell-renderer.component';
import {
    ColumnConditionCellRendererComponent,
} from '../cell-renderers/column-condition-cell-renderer/column-condition-cell-renderer.component';
import { URLCellRendererComponent } from '../cell-renderers/url-cell-renderer/url-cell-renderer.component';
import { CheckboxHeaderComponent } from '../headers/checkbox-header/checkbox-header.component';
import { ColumnRankingService } from './column-ranking.service';
import { ColumnTransformService } from './column-transform.service';
import { getCellStyle } from './get-cell-style';
import {
    isAgGridDateFilterState,
    isAgGridDateMultiConditionFilterState,
    isAgGridNumberFilterState,
    isAgGridNumberMultiConditionFilterState,
    isAgGridSetFilterState,
    isGridDateFilter,
    isGridDateMultiConditionFilter,
    isGridNumberFilter,
    isGridNumberMultiConditionFilter,
    isGridSetFilter,
} from './grid-types';
import { UserGridColumnOverridesService } from './user-grid-column-overrides.service';

@Injectable()
export abstract class GridConfigService {
    protected customCheckboxColumnDefinition: ColDef = {
        field: EDITABLE_CHECKBOX_FIELD,
        headerComponent: CheckboxHeaderComponent,
        headerName: '',
        lockPosition: true,
        pinned: 'left',
        lockPinned: true,
        resizable: false,
        width: 34,
        minWidth: 34,
        maxWidth: 34,
        pivot: false,
        enablePivot: false,
        hide: false,
        lockVisible: true,
        suppressColumnsToolPanel: true,
        filter: false,
        cellRenderer: CheckboxCellRendererComponent,
    };
    protected isInViewMode = false;
    protected visualizationType = 'SIMPLE_GRID';

    private readonly rowHeights = MetadataFormatUtils.getRowHeights();
    private dashboardQueryParams: DashboardPreference | undefined;
    private datasetMinMax: { [datasetId: number | string]: { [columnId: string]: ColumnMinMax } } = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private editedCellsOriginalValue: Record<number, any> = {};
    private isMultiClient = false;

    constructor(
        protected formatter: FormatterService,
        protected errorLoggerService: ErrorLoggerService,
        protected columnRankingService: ColumnRankingService,
        protected columnTransformService: ColumnTransformService,
        protected manager: ManagerService,
        protected crosstalkModalService: CrosstalkModalService,
        protected currentStateService: CurrentStateService,
        protected queryParamsService: QueryParamsService,
        protected userGridColumnOverridesService: UserGridColumnOverridesService,
        protected selectedWidgetRelayService: SelectedWidgetRelayService,
    ) {
        this.currentStateService.isMultiClient$.subscribe((isMultiClient: boolean) => this.isMultiClient = isMultiClient);
        this.queryParamsService.dashboardQueryParams.subscribe((dashboardQueryParams) => this.dashboardQueryParams = dashboardQueryParams);
        this.currentStateService.dashboardModeAndId$.subscribe(({ mode }) => this.isInViewMode = mode === 'view');
    }

    getGridConfiguration(preferences: VisualizationConfigs, widgetId: number, datasetId: number | string): GridConfiguration {
        return {
            columnDefinitions: this.getGridColumns(preferences, widgetId, datasetId),
            uniqueId: `${widgetId}-grid`,
            autoFitColumns: false,
            autoSizeColumns: preferences.autoSizeColumns,
            gridOptions: this.getGridOptions(preferences, widgetId),
            showQuickSearch: preferences.showQuickSearch,
            distinctRowsOnly: preferences.distinctRowsOnly,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getGridOptions(gridFormat: VisualizationConfigs, widgetId?: number): DataGridOptions {
        const gridOptions: DataGridOptions = new DefaultDataGridOptions();
        gridOptions.icons = {};
        for (const icon in GRID_ICONS) {
            if (icon) {
                gridOptions.icons[icon] = `<i class="${GRID_ICONS[icon]}"/>`;
            }
        }
        gridOptions.icons.filter = `<i class="${GRID_ICONS.filter} ag-icon-filter"/>`;
        gridOptions.rowClass = gridFormat.alternateRowShading ? 'grid-alternateshade' : '';
        gridOptions.autoSizePadding = 8;
        gridOptions.headerHeight = gridFormat.hideGridHeaders ? 0 : 40;
        gridOptions.rowHeight = gridFormat.rowHeight ?? 25;
        gridOptions.defaultColDef = {};
        return gridOptions;
    }

    getRankedColumnIds(columnConfigs: ConfigItem[]): string[] {
        return this.columnRankingService.getRankedColumnIds(columnConfigs);
    }

    private getColumnConfigClass(
        params: CellClassParams,
        columnConfig: ConfigItem,
        gridFormat: VisualizationConfigs,
        widgetId: number,
    ): string[] {
        const columnConfigClass: string[] = [columnConfig.fontStyle ?? '', columnConfig.alignment ?? ''];
        const camelCasedLabel = camelCaseString(this.rowHeights.find((rowHeight) => rowHeight.value === gridFormat.rowHeight)?.label ?? '');
        columnConfigClass.push(camelCasedLabel);
        if (!gridFormat.alternateRowShading) {
            columnConfigClass.push('no-grid-alternateshade');
        }
        if (this.editedCellsOriginalValue?.[widgetId]?.[params.node.id!]?.[params.colDef.colId!]?.hasChanged) {
            columnConfigClass.push('ag-cell-edited');
        }
        return columnConfigClass;
    }

    getGridColumns(vizConfigs: VisualizationConfigs, widgetId: number, datasetId: number | string): ColDef[] {
        const columns: ColDef[] = vizConfigs.configs?.values.map((columnConfig) => {
            const columnDef = this.getGridColumn(vizConfigs, columnConfig, widgetId, datasetId);
            if (columnConfig.displayType === 'value' && (columnConfig.datatype === 'percentage' || columnConfig.datatype === 'decimal')) {
                this.addCustomNumberSort(columnDef);
            }
            if (columnConfig.displayType === 'string' && columnConfig.datatype === 'string') {
                this.addCustomStringSort(columnDef);
            }
            if (columnConfig.isRanked) {
                this.columnRankingService.addRankHandlersToColumnDef(columnDef, columnConfig);
            }
            if (columnConfig.columnCondition?.length) {
                this.addColumnConditionCellRenderer(columnDef, columnConfig, vizConfigs, datasetId);
            }
            if (this.columnShouldBeLinkified(columnConfig, columnDef)) {
                this.addURLCellRenderer(columnDef, columnConfig, vizConfigs, datasetId);
            }
            if (this.isUserDefinedFieldColumn((columnDef as CustomColDef).field)) {
                (columnDef as CustomColDef).userDefinedFieldType = columnConfig.datatype as UserDefinedFieldType;
            }
            return columnDef;
        }) ?? [];

        // The below definitely doesn't belong here.
        // We should move it in the Advanced Grid Config Service.
        const widgetPrefs = this.getWidgetPreferences(widgetId);
        const isAdvancedGrid = widgetPrefs?.currentVisualization === 'ADVANCED_GRID';
        const conversableType = !!widgetPrefs?.datasetDefinition?.conversableType;
        const isCrosstalkGrid = isAdvancedGrid && conversableType;
        if (isCrosstalkGrid && !isManageWidgetMode(widgetId)) {
            const visualizationId = getCurrentVisualizationId(widgetPrefs)!;
            this.userGridColumnOverridesService.setCheckboxColumnPosition(
                widgetId,
                visualizationId,
                columns,
                'ColDef',
                this.isInViewMode,
            );
        }

        return columns;
    }

    public getGridColumn(
        gridFormat: VisualizationConfigs,
        columnConfig: ConfigItem,
        widgetId: number,
        datasetId: number | string,
    ): CustomColDef | CustomColGroupDef {
        // Disable ability to group UDF columns
        // and disband already saved UDF groups
        // until we start fetching the data for them from Trebek
        if (this.isUserDefinedFieldColumn(columnConfig.name)) {
            columnConfig.canPivotOn = false;
            columnConfig.rowGroupIndex = undefined;
        }

        return {
            headerName: columnConfig.showCustomName ? columnConfig.customName : columnConfig.label,
            field: this.columnRankingService.getFieldName(columnConfig),
            colId: columnConfig.colId,
            groupId: columnConfig.agBand,
            enableRowGroup: columnConfig.canPivotOn,
            pivot: columnConfig.canPivotOn && columnConfig.pivotIndex != null,
            enablePivot: columnConfig.canPivotOn,
            pivotIndex: columnConfig.pivotIndex,
            enableValue: columnConfig.displayType === 'value',
            hide: columnConfig.isHidden ?? undefined,
            suppressColumnsToolPanel: columnConfig.isSuppressedFromPanel,
            suppressSpanHeaderHeight: true,
            pinned: columnConfig.pinned,
            rowGroup: !!columnConfig.rowGroupIndex || columnConfig.rowGroupIndex === 0,
            rowGroupIndex: columnConfig.rowGroupIndex,
            width: columnConfig.width ?? undefined,
            minWidth: 50,
            customWidthWhenGroup: columnConfig.rowGroupIndex != null ?
                (columnConfig.customWidthWhenGroup ?? undefined) :
                undefined,
            headerTooltip: gridFormat.showColumnHeaderToolTip ? columnConfig.description : undefined,
            cellClass: (params): string[] => this.getColumnConfigClass(params, columnConfig, gridFormat, widgetId),
            cellStyle: (params): CellStyle => {
                return columnConfig.colId === crosstalkCheckboxFieldId ?
                    {} :
                    this.getCellStyle(
                        params.value,
                        columnConfig.displayType,
                        columnConfig.columnCondition,
                        columnConfig.isBlank,
                        columnConfig.decimalPlaces);
            },
            cellRenderer: (params: CustomCellRendererParams): string | undefined => {
                return !columnConfig.columnCondition?.length ?
                    this.getFormattedValue(params, columnConfig, gridFormat, datasetId, widgetId) :
                    undefined;
            },
            aggFunc: columnConfig.aggregationType === 'none' ? null : columnConfig.aggregationType,
            filter: this.getColumnFilterType(columnConfig.displayType),
            filterParams: this.getColumnFilterParams(columnConfig, widgetId),
            filterValueGetter: (params: ValueGetterParams) => this.getColumnFilterValueGetter(params, columnConfig),
            unSortIcon: true,
            getQuickFilterText: (params): string => !params.column.isVisible() ? '' : params.value,
            editable: this.visualizationType === 'ADVANCED_GRID' ? columnConfig.editable : false,
            valueParser: (params: ValueParserParams): string | number => getParsedValue(params),
            onCellValueChanged: (params): void => this.onCellValueChanged(params, widgetId),
        };
    }

    getUpdatedColumnConfigs(preferences: VisualizationConfigs, metadata: MetadataLookup = {}): ConfigItem[] {
        const udfColumnNames = Object.keys(metadata).filter((metadataKey) => this.isUserDefinedFieldColumn(metadataKey));
        preferences.rowHeight = preferences.rowHeight ?? 20;

        const columnConfigs: ConfigItem[] = [];
        if (preferences.configs?.values) {
            preferences.configs.values.forEach((element) => {
                if (!this.isUserDefinedFieldColumn(element.value) || udfColumnNames.includes(element.value!)) {
                    const columnConfig = this.getUpdatedColumnConfig(element);
                    columnConfigs.push(columnConfig);
                }
            });
        }
        return columnConfigs;
    }

    getUpdatedColumnConfig(element: ConfigItem): ConfigItem {
        const columnConfig: ConfigItem = clone(element);
        columnConfig.customName = element.showCustomName ? element.customName : element.label;
        columnConfig.columnCondition = [];
        element.columnCondition?.forEach((columnCondition) => {
            const columnFormat: CellFormat = clone(columnCondition);
            if (columnCondition.operatorCondition === 'EQL' || columnCondition.operatorCondition === 'NEQL') {
                columnFormat.rangeFrom = columnFormat.value;
            } else {
                columnFormat.rangeFrom = columnFormat.value ?? columnFormat.rangeFrom;
            }
            columnConfig.columnCondition?.push(columnFormat);
        });
        return columnConfig;
    }

    columnResizeFormatter(ref: ElementRef, event: ColumnResizedEvent): void {
        if (!Object.prototype.hasOwnProperty.call(event, 'finished') || !event.column) {
            return;
        }

        const columns = ref.nativeElement.querySelectorAll(`div[col-id = '${event.column.getColId().replace('!', '\\!')}']`);
        for (const column of columns) {
            if (event.finished) {
                column.classList.remove('resize-border');
            } else {
                column.classList.add('resize-border');
            }
        }
    }

    getExportFilteredData(
        dataGridComponent: DataGridComponent | undefined,
        preferences: VisualizationConfigs | undefined,
        isCSV = false,
        onlySelectedRows = false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any[] {
        if (!dataGridComponent) {
            return [];
        }

        const visibleColumns = dataGridComponent.getAllDisplayedColumns() ?? [];
        const allColumns = dataGridComponent.getAllColumns();
        let exportColumns = this.getExportColumns(visibleColumns).filter((column) => {
            return column.columnId !== EDITABLE_CHECKBOX_FIELD && column.columnId !== crosstalkCheckboxFieldId;
        });
        const autoColumnGroupIndex = exportColumns.findIndex((column) => column.columnId === AUTO_GROUP_COLUMN_ID);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let rowData: any[] = [];

        const { columnState, pivotMode: inPivotMode } = dataGridComponent.getState();
        const autoColumnGroup = columnState.find((col) => col.colId === AUTO_GROUP_COLUMN_ID);
        if (autoColumnGroup && !isCSV && preferences?.showSubTotal) {
            const grandTotalData = dataGridComponent.getGrandTotalData();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const grandTotalRow: any = { _row_id: 0 };
            for (const key in grandTotalData) {
                if (typeof key !== 'undefined') {
                    const newKey = this.getColDefField(key, allColumns);
                    grandTotalRow[newKey ?? ''] = grandTotalData[key];
                }
            }
            rowData.push(grandTotalRow);
        }
        // We start from 1 instead of 0 because we either:
        // - set the grand total row _row_id = 0
        // - or set the empty 'Control Total' row _row_id = 0 in DDV MW
        let index = 1;
        dataGridComponent.grid.api?.forEachNodeAfterFilterAndSort((rowDatum: IRowNode) => {
            if (!rowDatum) {
                return;
            }

            if (!this.shouldBeExported(onlySelectedRows, rowDatum)) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let data: any;
            if (inPivotMode) {
                if (autoColumnGroup) {
                    if (rowDatum.group) {
                        data = isCSV ?
                            this.getCSVFilteredExportGroupRowInPivotMode(rowDatum, !!preferences?.showGrouperCount, allColumns) :
                            this.getExcelFilteredExportGroupRowInPivotMode(
                                rowDatum,
                                !!preferences?.showGrouperCount,
                                allColumns,
                                preferences?.showSubTotal);
                    }
                } else {
                    // This is needed because we still get all the rows although only two rows
                    // (one regular and one total row which are duplicates) are visible in the UI in pivot mode
                    // and we want to export only one of them
                    if (index === 1) {
                        const visibleRow = dataGridComponent.grid.api?.getRenderedNodes()[0];
                        data = this.getFilteredExportRowInPivotMode(visibleRow, allColumns);
                    }
                }
            } else {
                if (rowDatum.group) {
                    data = isCSV ?
                        this.getCSVFilteredExportGroupRow(rowDatum, !!preferences?.showGrouperCount) :
                        this.getExcelFilteredExportGroupRow(
                            rowDatum,
                            !!preferences?.showGrouperCount,
                            allColumns,
                            preferences?.showSubTotal);
                } else {
                    data = rowDatum.data;
                }
            }

            if (data) {
                if (preferences?.isAdvancedGrid?.()) {
                    rowData.push({
                        ...data,
                        _row_id: index,
                        id: rowDatum.id,
                        group: rowDatum.group,
                    });
                    index += 1;
                } else {
                    rowData.push(data);
                }
            }
        });

        if (preferences?.isAdvancedGrid?.() && !isCSV) {
            rowData.forEach((rowDatum) => {
                const parent = rowData.find((row) => row.childrenIds?.includes(rowDatum.id));
                if (rowDatum._row_id !== 0) {
                    rowDatum._parent_id = parent ? parent._row_id : 0;
                }
            });
        }

        const rowGroupColumns = dataGridComponent.getRowGroupColumns() ?? [];
        if (autoColumnGroupIndex !== -1) {
            if (isCSV) {
                exportColumns = this.getExportColumnsForGroupedGrid(exportColumns, rowGroupColumns, onlySelectedRows);
                rowData = rowData.filter((rowDatum) => {
                    return !Object.keys(rowDatum).includes(AUTO_GROUP_COLUMN_ID);
                });
            } else {
                exportColumns.splice(autoColumnGroupIndex, 1);
                const groupColumns = this.getExportColumns(rowGroupColumns);
                exportColumns.unshift(...groupColumns);
                return this.getExportData(exportColumns, rowData, allColumns, groupColumns);
            }
        }

        return this.getExportData(exportColumns, rowData, allColumns);
    }

    getExportFilteredDataGroupers(dataGridComponent?: DataGridComponent): string[] {
        if (!dataGridComponent) {
            return [];
        }

        const rowGroupColumns = dataGridComponent.getRowGroupColumns() ?? [];
        return this.getExportColumns(rowGroupColumns).map((column) => column.columnName);
    }

    getFilteredExportVisibleColumns(dataGridComponent?: DataGridComponent): string[] {
        const visibleColumns = dataGridComponent?.getAllDisplayedColumns() ?? [];
        return this.getExportColumns(visibleColumns)
            .filter((vc) =>
                vc.columnId !== AUTO_GROUP_COLUMN_ID &&
                vc.columnId !== EDITABLE_CHECKBOX_FIELD &&
                vc.columnId !== crosstalkCheckboxFieldId &&
                vc.columnId !== CrosstalkFields.CommentCounter)
            .map((c) => c.columnName);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getExportFullData(dataGridComponent?: DataGridComponent): any[] {
        if (!dataGridComponent) {
            return [];
        }

        const columns = dataGridComponent.getAllColumns().filter((col) => col.getColDef()?.field !== CrosstalkFields.CommentCounter);
        const exportColumns = this.getExportColumns(columns);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowData: any[] = [];

        dataGridComponent.grid.api?.forEachNode((node) => {
            if (!node.group) {
                rowData.push(node.data);
            }
        });

        return rowData.map((currRowData) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const row: any = {};
            exportColumns.forEach((value) => {
                row[value.columnId] = typeof currRowData[value.columnId] !== 'undefined' ? currRowData[value.columnId] : '';
            });
            return row;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateDataset(columnConfigs: ConfigItem[], datasetId: number | string, gridData: any[]): void {
        this.columnTransformService.transformAbsoluteValue(gridData, columnConfigs);
        this.columnRankingService.rankData(gridData, columnConfigs);
        this.updateDatasetMinMax(columnConfigs, datasetId, gridData);
        this.removeNullStrings(columnConfigs, gridData);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeNullStrings(columnConfigs: ConfigItem[], gridData: any[]): void {
        columnConfigs.forEach((config) => {
            gridData.forEach((dataRow) => {
                if (config.datatype === 'string' && config.value) {
                    dataRow[config.value] = dataRow[config.value] ?? '';
                }
            });
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateDatasetMinMax(columnConfigs: ConfigItem[], dataSetId: number | string, gridData: any[]): void {
        if (!this.datasetMinMax[dataSetId]) {
            this.datasetMinMax[dataSetId] = {};
        }
        columnConfigs.forEach((columnConfig) => {
            if (columnConfig.displayType === 'bar') {
                this.datasetMinMax[dataSetId][columnConfig.value!] = {
                    min: getMinimumValue(gridData, columnConfig.value!) ?? 0,
                    max: getMaximumValue(gridData, columnConfig.value!) ?? 0,
                };
            }
        });
    }

    initializeGridFiltersAndSorts(columnConfigs: ConfigItem[] | undefined, gridState: GridState | undefined): void {
        if (!gridState) {
            return console.error('Cannot initializeGridFiltersAndSorts without a gridState');
        }

        gridState.sortState = [];

        columnConfigs?.forEach((columnConfig) => {
            if (isGridSetFilter(columnConfig.agFilter)) {
                try {
                    gridState.filterState[columnConfig.colId] = {
                        filterType: 'set',
                        values: JSON.parse(columnConfig.agFilter.includedStrings),
                    };
                } catch (ex) {
                    console.error(`Invalid string filter: ${columnConfig.agFilter.includedStrings} for column ${columnConfig.colId}`);
                }
            } else if (isGridDateMultiConditionFilter(columnConfig.agFilter)) {
                gridState.filterState[columnConfig.colId] = {
                    conditions: [JSON.parse(columnConfig.agFilter.condition1), JSON.parse(columnConfig.agFilter.condition2)],
                    filterType: columnConfig.agFilter.filterType,
                    operator: columnConfig.agFilter.operator,
                };
            } else if (isGridDateFilter(columnConfig.agFilter)) {
                gridState.filterState[columnConfig.colId] = {
                    dateFrom: columnConfig.agFilter.filterFrom,
                    dateTo: columnConfig.agFilter.filterTo ?? '',
                    filterType: columnConfig.agFilter.filterType,
                    type: columnConfig.agFilter.type,
                };
            } else if (isGridNumberMultiConditionFilter(columnConfig.agFilter)) {
                gridState.filterState[columnConfig.colId] = {
                    conditions: [JSON.parse(columnConfig.agFilter.condition1), JSON.parse(columnConfig.agFilter.condition2)],
                    filterType: columnConfig.agFilter.filterType,
                    operator: columnConfig.agFilter.operator,
                };
            } else if (isGridNumberFilter(columnConfig.agFilter)) {
                gridState.filterState[columnConfig.colId] = {
                    filter: columnConfig.agFilter.filterFrom != null ? Number(columnConfig.agFilter.filterFrom) : 0,
                    filterTo: columnConfig.agFilter.filterTo != null ? Number(columnConfig.agFilter.filterTo) : 0,
                    filterType: columnConfig.agFilter.filterType,
                    type: columnConfig.agFilter.type,
                };
            }

            if (columnConfig.agSort) {
                gridState.sortState.push({
                    colId: columnConfig.colId,
                    sort: columnConfig.agSort.sort,
                    sortIndex: columnConfig.agSortIndex ?? gridState.sortState.length,
                });
            }
        });
    }

    initializeGroupSort(sort: AgGridSortDirection | undefined | null, sortIndex: number, gridState: GridState): void {
        for (let i = 0; i < gridState.sortState.length; i++) {
            if (!gridState.sortState[i]) {
                gridState.sortState[i] = {
                    colId: AUTO_GROUP_COLUMN_ID,
                    sort,
                    sortIndex,
                };
                return;
            }
        }
        gridState.sortState.push({
            colId: AUTO_GROUP_COLUMN_ID,
            sort,
            sortIndex,
        });
    }

    updateFiltersInManager(widgetId: number, vizConfigs?: VisualizationConfigs): void {
        const managerPrefs = this.manager.getWidgetById(widgetId)?.getExtraPreferences();
        if (managerPrefs?.visualizationConfigs) {
            const agIndex = managerPrefs.visualizationConfigs.findIndex((config) => config.visualizationType === this.visualizationType);
            managerPrefs.visualizationConfigs[agIndex].configs?.values.forEach((colConfig, i) => {
                colConfig.agFilter = vizConfigs?.configs?.values[i]?.agFilter;
            });
            this.manager.setWidgetExtraPreferences(widgetId, managerPrefs);
        }
    }

    updateSortsInManager(widgetId: number, vizConfigs?: VisualizationConfigs): void {
        const managerPrefs = this.manager.getWidgetById(widgetId)?.getExtraPreferences();
        if (managerPrefs?.visualizationConfigs) {
            const agIndex = managerPrefs.visualizationConfigs.findIndex((config) => config.visualizationType === this.visualizationType);
            managerPrefs.visualizationConfigs[agIndex].configs?.values.forEach((colConfig, i) => {
                colConfig.agSort = vizConfigs?.configs?.values[i]?.agSort;
                colConfig.agSortIndex = vizConfigs?.configs?.values[i]?.agSortIndex;
            });
            managerPrefs.visualizationConfigs[agIndex].groupSort = vizConfigs?.groupSort;
            managerPrefs.visualizationConfigs[agIndex].groupSortIndex = vizConfigs?.groupSortIndex;
            this.manager.setWidgetExtraPreferences(widgetId, managerPrefs);
        }
    }

    updateColumnFilterFromState(widgetId: number, gridState: GridState | undefined, vizConfigs?: VisualizationConfigs): void {
        vizConfigs?.configs?.values.forEach((columnConfig) => {
            columnConfig.agFilter = this.getFilterFromState(gridState?.filterState[columnConfig.colId]);
        });
        this.updateFiltersInManager(widgetId, vizConfigs);
    }

    updateColumnSortFromState(widgetId: number, gridState: GridState | undefined, vizConfigs: VisualizationConfigs | undefined): void {
        vizConfigs?.configs?.values.forEach((columnConfig) => {
            const column = gridState?.sortState.find((sort) => columnConfig.colId === sort.colId);
            columnConfig.agSort = column?.sort ? { sort: column.sort } : null;
            columnConfig.agSortIndex = (column?.sortIndex !== undefined ? column.sortIndex : undefined) ?? undefined;
        });

        const groupSort = gridState?.sortState.find((ss) => ss.colId === AUTO_GROUP_COLUMN_ID);
        if (isManageWidgetMode(widgetId) && groupSort && vizConfigs) {
            vizConfigs.groupSort = groupSort.sort;
            vizConfigs.groupSortIndex = groupSort.sortIndex ?? undefined;
        }

        this.updateSortsInManager(widgetId, vizConfigs);
    }

    getCurrentWidgetDataset(widgetId: number): DatasetDefinition | undefined {
        return this.manager.getWidgetById(widgetId)?.extraParameters?.preferences?.datasetDefinition;
    }

    clearEditedCellsOriginalValue(): void {
        this.editedCellsOriginalValue = {};
    }

    orderColumnsInColumnState(columnState: ConfigItem[], columns: ConfigItem[]): ConfigItem[] {
        const editableCheckboxColumn = columnState.find((state) => state.colId === EDITABLE_CHECKBOX_FIELD);
        const autoColumnGroup = columnState.find((state) => state.colId === AUTO_GROUP_COLUMN_ID);

        let newColumnState = columns.filter((column) => columnState.find((state) => state.colId === column.colId));

        if (autoColumnGroup) {
            const checkboxColumnIndex = newColumnState.findIndex((col) => col.colId === crosstalkCheckboxFieldId);
            if (checkboxColumnIndex === 0) {
                newColumnState.splice(1, 0, autoColumnGroup);
            } else {
                newColumnState = [autoColumnGroup, ...newColumnState];
            }
        }

        if (editableCheckboxColumn) {
            newColumnState = [editableCheckboxColumn, ...newColumnState];
        }

        return newColumnState;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    public columnIsExternallyLinked(columnId: string, rowData: any): boolean {
        return !!this.getExternalLinkConfiguration(columnId, rowData);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    public getExternalLinkConfiguration(columnId: string, rowData: any): LinkConfiguration {
        const config: LinkConfiguration = rowData?.links?.[columnId];
        if (config) {
            config.columnId = columnId;
        }

        return config;
    }

    protected getWidgetPreferences(widgetId: number): AppWidgetState | undefined | null {
        return this.manager.getWidgetPreferences(widgetId);
    }

    protected getCurrentDatasetDefinition(widgetId: number): DatasetDefinition | undefined {
        return isManageWidgetMode(widgetId) ?
            this.selectedWidgetRelayService.getCurrentDatasetDefinition() :
            this.getCurrentWidgetDataset(widgetId);
    }

    private getExportColumns(columns: Column[]): ExportColumn[] {
        return columns.map((column) => {
            const columnDefinition = column.getColDef();
            let headerName = columnDefinition.headerName;
            if (headerName === CompareColumnName.COMPARISON || headerName === CompareColumnName.DIFF) {
                headerName = `${(column.getParent()?.getChildren()?.[0] as Column).getColDef().headerName} ${headerName}`;
            }
            return {
                columnId: columnDefinition.field ?? columnDefinition.colId ?? '',
                columnName: columnDefinition.field === CrosstalkFields.Attachments ? 'Attachments' : headerName ?? '',
            };
        });
    }

    private getExportColumnsForGroupedGrid(columns: ExportColumn[], rowGroupColumns: Column[], onlySelectedRows: boolean): ExportColumn[] {
        columns.splice(columns.findIndex((column) => column.columnId === AUTO_GROUP_COLUMN_ID), 1);
        const columnNames = columns.map((column) => column.columnName);
        const groupedColumns = rowGroupColumns.map((column) => {
            const columnDefinition = column.getColDef();
            return {
                columnId: columnDefinition.field ?? columnDefinition.colId ?? '',
                columnName: columnDefinition.field === CrosstalkFields.Attachments ?
                    (!onlySelectedRows && columnNames.includes('Attachments') ? 'Attachments___Grouper___' : 'Attachments') :
                    (!onlySelectedRows && columnNames.includes(columnDefinition.headerName ?? '') ?
                        `${columnDefinition.headerName}___Grouper___` :
                        `${columnDefinition.headerName}`
                    ),
            };
        });
        return [...groupedColumns, ...columns];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getExportData(columns: ExportColumn[], rows: any[], allColumns: Column[], groupColumns?: ExportColumn[]): object[] {
        if (rows.length) {
            return rows.map((currRowData) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const row: any = {};
                columns.forEach((value) => {
                    const columnName =
                        value.columnId.includes(CompareColumnID.COMPARE) ?
                            this.getComparisonColumnName(value.columnId, CompareColumnID.COMPARE, allColumns) :
                            value.columnId.includes(CompareColumnID.DIFF) ?
                                this.getComparisonColumnName(value.columnId, CompareColumnID.DIFF, allColumns) :
                                value.columnName;
                    row[columnName] = currRowData[value.columnId] ?? '';
                });

                if (groupColumns) {
                    row._row_id = currRowData._row_id;
                    row._parent_id = currRowData._parent_id;
                    row._group = currRowData.group;
                }

                return row;
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const row: any = {};
            columns.forEach((value) => {
                const columnName =
                    value.columnId.includes(CompareColumnID.COMPARE) ?
                        this.getComparisonColumnName(value.columnId, CompareColumnID.COMPARE, allColumns) :
                        value.columnId.includes(CompareColumnID.DIFF) ?
                            this.getComparisonColumnName(value.columnId, CompareColumnID.DIFF, allColumns) :
                            value.columnName;
                row[columnName] = '';
            });
            return [row];
        }
    }

    private getComparisonColumnName(columnId: string, toBeReplaced: string, allColumns: Column[]): string {
        const originalColumnId = columnId.replace(toBeReplaced, '');
        const originalColumn = allColumns.find((c) => c.getColDef().colId === originalColumnId || c.getColDef().field === originalColumnId);
        const originalColumnName = originalColumn?.getColDef().headerName;
        const columnNameSuffix =
            toBeReplaced === CompareColumnID.COMPARE ? CompareColumnName.COMPARISON : CompareColumnName.DIFF;
        return `${originalColumnName} ${columnNameSuffix}`;
    }

    private getFilterFromState(stateFilter?: AgGridFilterState): AGFilter | undefined {
        if (isAgGridSetFilterState(stateFilter)) {
            return { includedStrings: JSON.stringify(stateFilter.values) };
        } else if (isAgGridDateMultiConditionFilterState(stateFilter)) {
            return {
                condition1: JSON.stringify(stateFilter.conditions[0]),
                condition2: JSON.stringify(stateFilter.conditions[1]),
                filterType: stateFilter.filterType,
                operator: stateFilter.operator,
            };
        } else if (isAgGridDateFilterState(stateFilter)) {
            return {
                filterFrom: stateFilter.dateFrom,
                filterTo: stateFilter.dateTo,
                filterType: stateFilter.filterType,
                type: stateFilter.type,
            };
        } else if (isAgGridNumberMultiConditionFilterState(stateFilter)) {
            return {
                condition1: JSON.stringify(stateFilter.conditions[0]),
                condition2: JSON.stringify(stateFilter.conditions[1]),
                filterType: stateFilter.filterType,
                operator: stateFilter.operator,
            };
        } else if (isAgGridNumberFilterState(stateFilter)) {
            return {
                filterFrom: stateFilter.filter != null ? String(stateFilter.filter) : '',
                filterTo: stateFilter.filterTo != null ? String(stateFilter.filterTo) : '',
                filterType: stateFilter.filterType,
                type: stateFilter.type,
            };
        }

        return;
    }

    private getCellStyle(
        value: string,
        displayType?: ColumnDisplayType,
        formatting?: CellFormat[],
        isBlank?: boolean,
        decimalPlaces?: number | string,
    ): CellStyle {
        try {
            return getCellStyle(value, displayType, formatting, isBlank, decimalPlaces);
        } catch (e) {
            this.errorLoggerService.logError('', e as Error);
            return {};
        }
    }

    private getFormattedValue(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: ValueFormatterParams | any,
        columnConfig: ConfigItem,
        vizConfigs: VisualizationConfigs,
        datasetId: number | string,
        widgetId?: number,
    ): string {
        const rowNode: IRowNode = params.node;
        if (typeof params.value === 'undefined' ||
            (rowNode.group && params.column.colId !== AUTO_GROUP_COLUMN_ID && !vizConfigs.showSubTotal)) {
            return '';
        }

        let formattedValue = this.getFormattedValueByDisplayType(columnConfig, params, rowNode, datasetId);

        const link: LinkConfiguration = params.data && this.getExternalLinkConfiguration(columnConfig.value!, params.data);
        if (this.shouldLinkifyColumn(columnConfig.name!, !!vizConfigs.showHyperlinks, !!link, widgetId ?? MANAGE_WIDGET_ID)) {
            const cellValue = {
                formatted: formattedValue,
                original: params.value,
            };

            const cellStyle = this.getCellStyle(params.value, columnConfig.displayType, columnConfig.columnCondition);
            formattedValue = this.crosstalkModalService.configureCellForLinking(link, cellValue, cellStyle);
        }

        if (columnConfig.hyperlink) {
            formattedValue = this.getHyperlinkFormattedValue(columnConfig, params, vizConfigs, widgetId as number, formattedValue);
        }

        if (columnConfig.linkBehavior === 'masterdetail' && columnConfig.ddvWidget) {
            formattedValue = this.getMasterDetailFormattedValue(columnConfig, params, formattedValue);
        }

        return formattedValue ?? '';
    }

    private shouldLinkifyColumn(columnName: string, showHyperlinks: boolean, isLink: boolean, widgetId: number): boolean {
        const widgetPrefs = this.getWidgetPreferences(widgetId);
        const isSimpleGrid = widgetPrefs?.currentVisualization === 'SIMPLE_GRID';

        const isCommentColumn = columnName === TrebekConversationFields.HSComment || columnName === TrebekConversationFields.ClientComment;

        return showHyperlinks && isLink && !(isSimpleGrid && isCommentColumn);
    }

    private renderProgressBar(value: number, barFormat: CellFormat | undefined, minMax: ColumnMinMax): string {
        if (!barFormat || isNaN(value) || isNaN(minMax.min) || isNaN(minMax.max) ||
            (barFormat.operatorCondition !== 'AUTO' && (isNaN(Number(barFormat.rangeFrom)) || isNaN(Number(barFormat.rangeTo))))) {
            return '';
        }
        let width: string;
        let newValue = value;
        let newMinMax = minMax;
        if (barFormat.operatorCondition === 'NUM') {
            newMinMax = {
                min: Number(barFormat.rangeFrom),
                max: Number(barFormat.rangeTo),
            };
        } else if (barFormat.operatorCondition === 'PRCT') {
            newValue = this.getScaledPercentageValue(value, minMax, false) as number;
            newMinMax = {
                min: Number(barFormat.rangeFrom),
                max: Number(barFormat.rangeTo),
            };
        }
        if (newValue >= newMinMax.max) {
            width = '100%';
        } else if (newValue <= newMinMax.min) {
            width = '0%';
        } else {
            width = this.getScaledPercentageValue(newValue, newMinMax, true) as string;
        }
        return `<div class="div-outer-div">
        <div class="div-percent-bar" style="width: ${width}; background-color: ${barFormat.barColor}"></div></div>`;
    }

    private getScaledPercentageValue(value: number, minMax: ColumnMinMax, isDisplay: boolean): string | number {
        // refer FXB-1118 for the formula
        const numerator = value - minMax.min;
        const denominator = minMax.max - minMax.min;
        return (isDisplay ? percentageFormatter.asDisplayValue(numerator / denominator, 2) :
            percentageFormatter.asFilterValue(numerator / denominator, 2)) ?? '';
    }

    private getFormattedNumber(
        value: number,
        decimalPlaces: number | string,
        numberFormat: NumberFormat | '',
        negativeFormat: ColumnNegativeValues,
    ): string {
        const formatted = this.formatter.summaryFormatting(
            value,
            decimalPlaces,
            numberFormat,
            'actual',
            negativeFormat.includes('arenthesis'),  // matches both "parenthesis" and "redParenthesis"
            negativeFormat.includes('red'),
        );
        if (formatted.isRed) {
            return `<span class="redcolor">${formatted.summaryColumnValue}</span>`;
        }
        return formatted.summaryColumnValue;
    }

    private getColumnFilterType(displayType?: ColumnDisplayType): string {
        switch (displayType) {
            case 'string':
            case 'boolean':
                return 'customColumnFilterComponent';
            case 'date':
                return 'agDateColumnFilter';
            default:
                return 'agNumberColumnFilter';
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getColumnFilterParams(columnConfig: ConfigItem, widgetId: number): any {
        const currentDatasetDefinition = this.getCurrentDatasetDefinition(widgetId);
        const filterParams: CustomFilterParams = {
            isTFLDetailsDataset: isTradeFileDetails(currentDatasetDefinition?.queryType?.name),
            inRangeInclusive: true,
            debounceMs: 500,
        };

        // We are limiting the filter options when the column is ranked due to a bug
        // After data rows are filtered, ranked columns are recomputed and they start again from 1,
        // no matter that their actual value is different in the whole dataset
        if (columnConfig.isRanked) {
            filterParams.filterOptions = NUMBER_FILTER_OPERATIONS_FOR_RANKED_COLUMN;
        } else if (columnConfig.displayType === 'value' || columnConfig.displayType === 'bar') {
            filterParams.filterOptions = NUMBER_FILTER_OPERATIONS;
        }

        if (columnConfig.displayType === 'string') {
            filterParams.textFormatter = (value: unknown): string => value ? value.toString().toLowerCase() : '';
            // These would prevent some edge cases but they don't work: https://www.ag-grid.com/archive/15.0.0/javascript-grid-filter-set/
            // filterParams.suppressRemoveEntries = true;
            // filterParams.newRowsAction = 'keep';
        }

        if (columnConfig.displayType === 'date') {
            filterParams.comparator = (filterLocalDateAtMidnight: Date, cellValue: string): number => {
                const cellDate = new Date(cellValue);

                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                }

                if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                }

                return 0;
            };
        }

        return filterParams;
    }

    private getColumnFilterValueGetter(params: ValueGetterParams, columnConfig: ConfigItem): string | number | Date | undefined {
        const data = params.data ? params.data[columnConfig.value!] : params.node?.aggData[columnConfig.colId];
        const isUserDefinedField = this.isUserDefinedFieldColumn(columnConfig.value);

        let userDefinedFieldColumnValue: string | undefined;
        if (isUserDefinedField) {
            const userDefinedFieldType = columnConfig.datatype;
            switch (userDefinedFieldType) {
                case 'choice':
                    userDefinedFieldColumnValue = data?.value;
                    break;
                case 'boolean':
                    userDefinedFieldColumnValue = data?.value;
                    break;
                case 'date':
                    userDefinedFieldColumnValue = data ? data.value : data;
                    break;
                case 'string':
                case 'number':
                    userDefinedFieldColumnValue = data?.value;
                    break;
                case 'decimal':
                    userDefinedFieldColumnValue = data?.value;
            }
        }

        const value = this.getColumnFilterValue(params, columnConfig, isUserDefinedField, userDefinedFieldColumnValue, data);
        const decimalPlaces = (columnConfig.numberFormat === 'XX' ? 0 : columnConfig.decimalPlaces) ?? 0;
        switch (columnConfig.displayType) {
            case 'boolean':
                return booleanFormatter.asFilterValue(value);
            case 'date':
                return dateFormatter.asFilterValue(value as string);
            case 'value':
                return columnConfig.numberFormat === '%' ?
                    percentageFormatter.asFilterValue(value, decimalPlaces) :
                    numberFormatter.asFilterValue(value, decimalPlaces);
            default:
                return value;
        }
    }

    private getColumnFilterValue(
        params: ValueGetterParams,
        columnConfig: ConfigItem,
        isUserDefinedField: boolean,
        userDefinedFieldColumnValue: string | undefined,
        data: string | number,
    ): string | number | undefined {
        if (columnConfig.useAbsVal) {
            return params.data ?
                params.data[this.columnTransformService.getAbsValFieldName(columnConfig)] :
                params.node?.aggData[columnConfig.colId];
        }

        if (isUserDefinedField) {
            return userDefinedFieldColumnValue;
        }

        return data;
    }

    private getDatasetMinMax(dataSetId: number | string, columnId: string | undefined): ColumnMinMax {
        return this.datasetMinMax[dataSetId] && columnId ? this.datasetMinMax[dataSetId][columnId] : { min: 0, max: 0 };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isZero(columnConfig: ConfigItem, params: any): boolean {
        if (params.value >= 1 || params.value <= -1) {
            return false;
        }

        const cellContent = this
            .getFormattedNumber(params.value, columnConfig.decimalPlaces ?? 0, columnConfig.numberFormat ?? '', columnConfig.negativeValue ?? 'minus')
            .toString()
            // eslint-disable-next-line no-useless-escape
            .replace(/[\($\-]+/, '')
            .replace('<span class="redcolor">', '')
            .substring(0, (columnConfig.decimalPlaces ? Number(columnConfig.decimalPlaces) : 0) + 2);
        return !cellContent.match(/[1-9]/);
    }

    private addColumnConditionCellRenderer(
        columnDef: ColDef,
        columnConfig: ConfigItem,
        vizConfigs: VisualizationConfigs,
        datasetId: number | string,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columnDef.valueFormatter = (params: ValueFormatterParams): any => columnConfig.isRanked ?
            this.columnRankingService.getFormattedValue(params, columnConfig) :
            params.node ?
                this.getFormattedValue(params, columnConfig, vizConfigs, datasetId) :
                null;
        columnDef.cellRenderer = ColumnConditionCellRendererComponent;
        columnDef.cellRendererParams = {
            columnCondition: columnConfig.columnCondition,
            displayType: columnConfig.displayType,
            getCellStyle: this.getCellStyle.bind(this),
            isRanked: columnConfig.isRanked,
            renderProgressBar: this.renderProgressBar.bind(this),
            datasetId,
            getDatasetMinMax: this.getDatasetMinMax.bind(this),
        };
    }

    private addCustomNumberSort(columnDef: ColDef): void {
        columnDef.comparator = (valueA, valueB): number => Number(valueA) - Number(valueB);
    }

    private addCustomStringSort(columnDef: ColDef): void {
        columnDef.comparator = (a: string, b: string): number => {
            const valueA: string = a == null ? '' : a.toString();
            const valueB: string = b == null ? '' : b.toString();
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };
    }

    private addURLCellRenderer(
        columnDef: ColDef,
        columnConfig: ConfigItem,
        vizConfigs: VisualizationConfigs,
        datasetId: number | string,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columnDef.valueFormatter = (params: ValueFormatterParams): any => columnConfig.isRanked ?
            this.columnRankingService.getFormattedValue(params, columnConfig) :
            params.node ?
                this.getFormattedValue(params, columnConfig, vizConfigs, datasetId) :
                null;
        columnDef.cellRenderer = URLCellRendererComponent;
        columnDef.cellRendererParams = {
            ...columnDef.cellRendererParams,
            isRanked: columnConfig.isRanked,
        };
    }

    private onCellValueChanged(params: NewValueParams, widgetId: number): void {
        const colId = params.column.getColId();
        const colDef = params.column.getColDef() as CustomColDef;
        const field = colDef.field ?? '';
        if (!isHSCommentOrClientCommentField(field) && !isAttachmentsOrCommentCounterField(field) && !colDef.isUserDefinedField) {
            if (params.oldValue !== params.newValue) {
                const rowIndex = params.node?.id ?? '';
                if (!this.editedCellsOriginalValue[widgetId]) {
                    this.editedCellsOriginalValue[widgetId] = {};
                }

                if (!this.editedCellsOriginalValue[widgetId][rowIndex]) {
                    this.editedCellsOriginalValue[widgetId][rowIndex] = {};
                }

                if (!this.editedCellsOriginalValue[widgetId][rowIndex][colId]) {
                    this.editedCellsOriginalValue[widgetId][rowIndex][colId] = {
                        originalValue: params.oldValue,
                    };
                }

                const originalValue = this.editedCellsOriginalValue[widgetId][rowIndex][colId].originalValue;
                this.editedCellsOriginalValue[widgetId][rowIndex][colId].hasChanged =
                    !(originalValue == null && params.newValue === '') && originalValue !== params.newValue;

                this.toggleCheckboxOnCellValueChange(widgetId, rowIndex, params);
            }
        }
    }

    private toggleCheckboxOnCellValueChange(widgetId: number, rowIndex: string, params: NewValueParams): void {
        const currentDatasetDefinition = this.getCurrentDatasetDefinition(widgetId);
        const { data, parent } = params.node!;
        let isAllocation = false;
        let isBlock = false;
        if (isTradeFileDetails(currentDatasetDefinition?.queryType?.name)) {
            const isGridGroupedByAllocationGroupId = this.isGridGroupedByAllocationGroupId(params);
            isAllocation = this.isAllocation(isGridGroupedByAllocationGroupId, data);
            isBlock = this.isBlock(isGridGroupedByAllocationGroupId, data);
        }

        let block: IRowNode | undefined;
        if (isAllocation) {
            block = parent?.childrenAfterFilter?.find((child: IRowNode) => child.data?.[TRANSACTION_TYPE_FIELD]);
        }

        let blockContainsEditedCells = false;
        if (isBlock) {
            const ids = parent?.childrenAfterFilter?.map((child: IRowNode) => child.id);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const editedRows: any[] = [];
            ids?.forEach((id?: string) => {
                if (id && this.editedCellsOriginalValue[widgetId][id]) {
                    editedRows.push(this.editedCellsOriginalValue[widgetId][id]);
                }
            });

            editedRows.forEach((row) => {
                if (!blockContainsEditedCells) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    blockContainsEditedCells = Object.values(row).some((col: any) => col.hasChanged);
                }
            });
        }

        const rowToCheck = !isAllocation ? params.node : block;
        const currentRow = this.editedCellsOriginalValue[widgetId][rowIndex];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowContainsEditedCells = Object.values(currentRow).some((col: any) => col.hasChanged);
        const checkboxValue = rowContainsEditedCells || blockContainsEditedCells;
        const updatedData = rowToCheck?.data;
        updatedData[EDITABLE_CHECKBOX_FIELD] = checkboxValue;
        rowToCheck?.setData(updatedData);
        rowToCheck?.setSelected(checkboxValue);

        const rowsToRedraw: IRowNode[] = [];
        if (!isAllocation && params.node) {
            rowsToRedraw.push(params.node);
        } else if (params.node && block) {
            rowsToRedraw.push(block);
            rowsToRedraw.push(params.node);
        }
        params.api.redrawRows({ rowNodes: rowsToRedraw });
        params.api.refreshHeader();
    }

    private getCSVFilteredExportGroupRow(rowDatum: IRowNode, showGrouperCount: boolean): object {
        const datum = deepClone(deepExtend({}, [rowDatum.groupData, rowDatum.aggData]));
        for (const key in datum) {
            if (typeof datum[key] !== 'undefined') {
                if (key === AUTO_GROUP_COLUMN_ID && showGrouperCount) {
                    datum[key] = datum[key] ? `${datum[key]}(${rowDatum.allChildrenCount})` : `(${rowDatum.allChildrenCount})`;
                } else if (typeof datum[key] === 'object' && datum[key] != null) {
                    datum[key] = datum[key].toNumber();
                }
            }
        }
        return datum;
    }

    private getCSVFilteredExportGroupRowInPivotMode(rowDatum: IRowNode, showGrouperCount: boolean, columns: Column[]): object {
        const groupData = rowDatum.groupData?.[AUTO_GROUP_COLUMN_ID];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowData: any = {};

        let newRowDatum = rowDatum;
        while (newRowDatum.group && newRowDatum.childrenAfterSort?.length) {
            const children = newRowDatum.childrenAfterSort;
            if (children[0].group) {
                rowData[children[0].field ?? ''] = children[0].groupData?.[AUTO_GROUP_COLUMN_ID];
            }
            newRowDatum = children[0];
        }

        newRowDatum = rowDatum;
        while (newRowDatum.parent?.groupData && !rowData[newRowDatum.parent.field ?? '']) {
            const parent = newRowDatum.parent;
            rowData[parent.field ?? ''] = parent.groupData?.[AUTO_GROUP_COLUMN_ID];
            newRowDatum = parent;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const aggData: any = {};
        for (const key in rowDatum.aggData) {
            if (typeof rowDatum.aggData[key] !== 'undefined') {
                const newKey = this.getColDefField(key, columns);
                aggData[newKey ?? ''] = rowDatum.aggData[key];
            }
        }

        return {
            [rowDatum.field ?? '']: showGrouperCount ? `${groupData}(${rowDatum.allChildrenCount})` : groupData,
            ...rowData,
            ...aggData,
        };
    }

    private getExcelFilteredExportGroupRow(
        rowDatum: IRowNode,
        showGrouperCount: boolean,
        columns: Column[],
        showSubtotal: boolean | null = false,
    ): object {
        const groupData = rowDatum.groupData?.[AUTO_GROUP_COLUMN_ID];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const aggData: any = {};
        for (const key in rowDatum.aggData) {
            if (typeof rowDatum.aggData[key] !== 'undefined' && showSubtotal) {
                const newKey = this.getColDefField(key, columns);
                aggData[newKey ?? ''] = rowDatum.aggData[key];
            }
        }
        return {
            [rowDatum.field ?? '']: showGrouperCount ? `${groupData}(${rowDatum.allChildrenCount})` : groupData,
            ...aggData,
            ...rowDatum.data,
            childrenIds: rowDatum.childrenAfterSort?.map((child) => child.id),
        };
    }

    private getExcelFilteredExportGroupRowInPivotMode(
        rowDatum: IRowNode,
        showGrouperCount: boolean,
        columns: Column[],
        showSubtotal: boolean | null = false,
    ): object {
        const groupData = rowDatum.groupData?.[AUTO_GROUP_COLUMN_ID];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rowData: any = {};
        for (const key in rowDatum.aggData) {
            if (typeof rowDatum.aggData[key] !== 'undefined' && showSubtotal) {
                const newKey = this.getColDefField(key, columns);
                rowData[newKey ?? ''] = rowDatum.aggData[key];
            }
        }
        return {
            [rowDatum.field ?? '']: showGrouperCount ? `${groupData}(${rowDatum.allChildrenCount})` : groupData,
            ...rowData,
            childrenIds: rowDatum.childrenAfterSort?.map((child) => child.id),
        };
    }

    // used both for CSV and Excel for now
    private getFilteredExportRowInPivotMode(rowDatum: IRowNode | undefined, columns: Column[]): object {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const aggData: any = {};
        for (const key in rowDatum?.aggData) {
            if (typeof rowDatum?.aggData[key] !== 'undefined') {
                const newKey = this.getColDefField(key, columns);
                aggData[newKey ?? ''] = rowDatum.aggData[key];
            }
        }
        return aggData;
    }

    private getColDefField(colId: string, columns: Column[]): string | undefined {
        return columns.find((column) => column.getColId() === colId)?.getColDef().field;
    }

    private columnShouldBeLinkified(columnConfig: ConfigItem, columnDef: ColDef | CustomColDef): boolean {
        const { editable, hyperlink } = columnConfig;
        const { field } = columnDef as CustomColDef;
        const isDatatypeDecimalOrPercentage = columnConfig.datatype === 'decimal' || columnConfig.datatype === 'percentage';
        const isCrosstalkColumn =
            isHSCommentOrClientCommentField(field) ||
            field === `${TrebekConversationFields.HSComment}Author` ||
            field === `${TrebekConversationFields.ClientComment}Author` ||
            field === `${TrebekConversationFields.HSComment}Created` ||
            field === `${TrebekConversationFields.ClientComment}Created` ||
            isAttachmentsOrCommentCounterField(field) ||
            field === crosstalkCheckboxFieldId ||
            this.isUserDefinedFieldColumn(field);
        return !isDatatypeDecimalOrPercentage && !editable && !hyperlink && !isCrosstalkColumn && field !== EDITABLE_CHECKBOX_FIELD;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFormattedValueByDisplayType(columnConfig: ConfigItem, params: any, rowNode: IRowNode, datasetId: number | string): string {
        let value = '';
        switch (columnConfig.displayType) {
            case 'boolean':
                value = booleanFormatter.asDisplayValue(params.value);
                break;
            case 'string':
                value = columnConfig.datatype === 'date' ?
                    dateFormatter.asDisplayValue(params.value, { input: 'YYYY-MM-DD', output: columnConfig.format ?? '' }) :
                    params.value as string;
                break;
            case 'date':
                value = dateFormatter.asDisplayValue(params.value ?? '', {
                    input: 'YYYY-MM-DD',
                    output: columnConfig.dateFormat ?? 'MM/DD/YYYY',
                });
                break;
            case 'value':
                value = this.getFormattedValueForDisplayTypeValue(columnConfig, params, rowNode);
                break;
            case 'bar':
                value = this.renderProgressBar(
                    params.value as number,
                    columnConfig.columnCondition?.[0],
                    this.getDatasetMinMax(datasetId, columnConfig.value));
        }
        return value;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFormattedValueForDisplayTypeValue(columnConfig: ConfigItem, params: any, rowNode: IRowNode): string {
        if (columnConfig.isBlank && (params.value === 0 || this.isZero(columnConfig, params))) {
            return '';
        }
        if (columnConfig.isRanked) {
            return params.value as string;
        }
        if (params.column.aggregationType === 'count' &&
            (rowNode.group || rowNode.rowPinned === 'top' || rowNode.rowPinned === 'bottom')) {
            return params.value as string;
        }
        const formattedValue = this.getFormattedNumber(
            params.value as number,
            columnConfig.decimalPlaces ?? 0,
            columnConfig.numberFormat ?? '',
            columnConfig.negativeValue ?? 'minus');
        return formattedValue;
    }

    private getHyperlinkFormattedValue(
        columnConfig: ConfigItem,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: any,
        vizConfigs: VisualizationConfigs,
        widgetId: number,
        formattedValue: string,
    ): string {
        let uri = `${columnConfig.hyperlink}`;
        if (this.isMultiClient) {
            const isClientCodeSupplied = vizConfigs.configs?.values.map((c) => c.name).indexOf('clientCode') !== -1;
            const queryPeriodType = encodeURIComponent(
                JSON.stringify(this.getCurrentWidgetDataset(widgetId)?.queryPeriodType?.name));
            const clients = encodeURIComponent(JSON.stringify((params.data.clientCode && isClientCodeSupplied ? [{
                clientId: params.data.clientCode,
                clientName: params.data.clientCode,
            }] : this.dashboardQueryParams?.clients) ?? []));
            const dates = encodeURIComponent(JSON.stringify({
                startDate: this.dashboardQueryParams?.startDate,
                endDate: this.dashboardQueryParams?.endDate,
                activeDate: this.dashboardQueryParams?.activeDate,
            } ?? []));
            const funds = encodeURIComponent(JSON.stringify(this.dashboardQueryParams?.funds?.map((f) => ({
                fundId: f.fundId,
                fundName: f.fundName,
            })) ?? []));
            const filters = encodeURIComponent(JSON.stringify(this.dashboardQueryParams?.filters?.map((f) => ({
                criteria: f.criteria,
                name: f.name,
                displayName: f.displayName,
                values: f.values,
            })) ?? []));

            uri += `?queryPeriodType=${queryPeriodType}&clients=${clients}&dates=${dates}&funds=${funds}&filters=${filters}`;
        }

        const cellValue = {
            formatted: formattedValue,
            original: params.value,
        };
        const cellStyle = this.getCellStyle(params.value, columnConfig.displayType, columnConfig.columnCondition);
        const hyperlink: LinkConfiguration = {
            label: params.value,
            uri,
            launchType: columnConfig.linkBehavior ?? '_self',
        };

        return this.crosstalkModalService.configureCellForLinking(hyperlink, cellValue, cellStyle, true);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getMasterDetailFormattedValue(columnConfig: ConfigItem, params: any, formattedValue: string): string {
        const cellValue = {
            formatted: formattedValue,
            original: params.value,
        };
        const cellStyle = this.getCellStyle(params.value, columnConfig.displayType, columnConfig.columnCondition);
        const hyperlink: LinkConfiguration = {
            label: params.value,
            uri: columnConfig.ddvWidget?.toString() ?? '',
            launchType: columnConfig.linkBehavior,
        };
        return this.crosstalkModalService.configureCellForLinking(hyperlink, cellValue, cellStyle, true);
    }

    private isGridGroupedByAllocationGroupId(params: NewValueParams): boolean {
        const groupColumns = params.api.getRowGroupColumns();
        return groupColumns.length === 1 && groupColumns[0].getColDef().field === TFL_DETAILS_AUTO_GROUP_COLUMN_ID;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isAllocation(isGridGrouped: boolean, data: any): boolean {
        return !!(isGridGrouped && data?.[TFL_DETAILS_AUTO_GROUP_COLUMN_ID] && !data[TRANSACTION_TYPE_FIELD]);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isBlock(isGridGrouped: boolean, data: any): boolean {
        return !!(isGridGrouped && data?.[TFL_DETAILS_AUTO_GROUP_COLUMN_ID] && data[TRANSACTION_TYPE_FIELD]);
    }

    private shouldBeExported(onlySelectedRows: boolean, node: IRowNode): boolean {
        return !onlySelectedRows ||
            (onlySelectedRows && node.isSelected()) ||
            node.data?.[EDITABLE_CHECKBOX_FIELD];
    }

    protected isUserDefinedFieldColumn(field: string | undefined): boolean {
        return !!field?.startsWith('udf_');
    }
}

export function isManageWidgetMode(widgetId: number): boolean {
    return widgetId === MANAGE_WIDGET_ID;
}

function getParsedValue(params: ValueParserParams): string | number {
    if (typeof params.oldValue === 'number') {
        return Number(params.newValue);
    }
    return params.newValue !== undefined ? params.newValue : '';
}

function isHSCommentOrClientCommentField(field?: string): boolean {
    return field === TrebekConversationFields.HSComment || field === TrebekConversationFields.ClientComment;
}

function isAttachmentsOrCommentCounterField(field?: string): boolean {
    return field === CrosstalkFields.Attachments || field === CrosstalkFields.CommentCounter;
}
