import { Injectable } from '@angular/core';
import { UsageTracker, UsageTrackingService } from '@ddv/usage-tracking';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const enum EventType {
    OPEN_DASHBOARD = 'Open',
    EDIT_RESOLUTION = 'Edit Resolution',
    EDIT_WINDOW_SIZE = 'Edit Window Size',
    EDIT_ZOOM_LEVEL = 'Edit Zoom Level'
}

@Injectable()
export class DdvScreenDataCaptureService {
    private resolution: string | undefined;
    private screenSize: string | undefined;
    private zoomLevel: number | undefined;
    private eventType: EventType | undefined;

    private resolutionWidth: number | undefined;
    private resolutionHeight: number | undefined;
    private browserWidth: number | undefined;
    private browserHeight: number | undefined;

    constructor(private readonly usageTracking: UsageTrackingService) {
        this.setInitialData();

        fromEvent(window, 'resize')
            .pipe(debounceTime(350))
            .subscribe((e: Event) => {
                this.addTrackData(e);
            });
    }

    private setResolution(win: Window): void {
        this.resolutionWidth = win.screen.width || 0;
        this.resolutionHeight = win.screen.height || 0;
        this.resolution = `${this.resolutionWidth}x${this.resolutionHeight}`;
    }

    private setBrowserSize(win: Window): void {
        this.browserWidth = win.innerWidth;
        this.browserHeight = win.innerHeight;
        this.screenSize = `${this.browserWidth}x${this.browserHeight}`;
    }

    private setZoomLevel(win: Window): void {
        this.zoomLevel = win.devicePixelRatio;
    }

    private setInitialData(): void {
        this.setResolution(window);
        this.setBrowserSize(window);
        this.setZoomLevel(window);
    }

    addTrackData(e?: Event, dashboardId?: string): void {
        if (e?.target) {
            const current = e.target as Window;

            this.eventType = EventType.EDIT_WINDOW_SIZE;

            if (this.zoomLevel !== current.devicePixelRatio) {
                this.setZoomLevel(current);
                this.eventType = EventType.EDIT_ZOOM_LEVEL;
            }

            if (this.resolutionWidth !== current.screen.width || this.resolutionHeight !== current.screen.height) {
                this.setResolution(current);
                this.eventType = EventType.EDIT_RESOLUTION;
            }

            this.setBrowserSize(current);
        }

        if (dashboardId) {
            this.eventType = EventType.OPEN_DASHBOARD;
        }

        if (this.resolution && this.screenSize && this.zoomLevel && this.eventType) {
            const inputData = {
                displayResolution: this.resolution,
                screenSize: this.screenSize,
                zoomLevel: this.zoomLevel,
                eventType: this.eventType,
            };
            const usage: UsageTracker = this.usageTracking.startTracking('DDV.Screendata.Capture', inputData);
            usage.succeeded();
        }
    }
}
