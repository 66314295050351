import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { MetadataAndQueryURLsConfig, NamedQuery, NamedQueryInit } from '@ddv/models';
import { delay, Observable, of, ReplaySubject, share, take } from 'rxjs';
import { map } from 'rxjs/operators';

export const replaceHostInMetadataAndQueryURLsToken: InjectionToken<boolean> = new InjectionToken('replaceHostInMetadataAndQueryURLsToken');
export const trebekApiLocationToken: InjectionToken<string> = new InjectionToken('trebekApiLocationToken');
export const nqsApiLocationToken: InjectionToken<string> = new InjectionToken('nqsApiLocationToken');

@Injectable()
export class NamedQueriesService {
    private clientCode = '';
    private readonly namedQueriesCache: Map<string, Observable<NamedQuery>> = new Map();
    private readonly config: MetadataAndQueryURLsConfig;

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.nqs) private readonly nqsApiExecutor: ApiExecutorService,
        @Inject(replaceHostInMetadataAndQueryURLsToken) private readonly replaceHostInURLs: boolean,
        @Inject(trebekApiLocationToken) private readonly trebekApiLocation: string,
        @Inject(nqsApiLocationToken) private readonly nqsApiLocation: string,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
        this.config = {
            replaceHostInURLs: this.replaceHostInURLs,
            trebekApiLocation: this.trebekApiLocation,
            nqsApiLocation: this.nqsApiLocation,
        };
    }

    fetchNamedQueries(): Observable<NamedQuery[]> {
        return this.nqsApiExecutor.invokeServiceWithParams<NamedQueryInit[]>(this.clientCode, '/queries')
            .pipe(map((queries) => queries.map((q) => new NamedQuery(q, this.config))));
    }

    fetchNamedQuery(id: string): Observable<NamedQuery> {
        let namedQuery: Observable<NamedQuery> | undefined = this.namedQueriesCache.get(id);
        if (namedQuery) {
            return namedQuery;
        }

        namedQuery = this.nqsApiExecutor.invokeServiceWithParams<NamedQueryInit>(this.clientCode, `/queries/${id}`)
            .pipe(
                share({
                    connector: () => new ReplaySubject(1),
                    resetOnError: false,
                    resetOnComplete: () => of(true).pipe(delay(30_000)), // caches the data for 30 seconds
                    resetOnRefCountZero: false,
                }),
                take(1),
                map((init) => new NamedQuery(init, this.config)),
            );
        this.namedQueriesCache.set(id, namedQuery);
        return namedQuery;
    }
}
