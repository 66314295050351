import { Injectable } from '@angular/core';
import { FormatterService } from '@ddv/formatters';
import { AggType, DdvDate, FormatterConfigItem, NumberFormat } from '@ddv/models';

@Injectable()
export class ValueFormatterService {
    constructor(private readonly formatter: FormatterService) {}

    applyFormatter(value: string | number | Date | undefined, config: FormatterConfigItem): string | number {
        if (config.datatype === 'date' &&
            config.aggregationType !== 'count' &&
            config.aggregationType !== 'countDistinct' &&
            config.aggregationType !== 'countExcludingZeros' &&
            config.aggregationType !== 'countDistinctExcludingZeros'
        ) {
            const d = value instanceof Date ? DdvDate.fromDate(value) : DdvDate.fromDashFormat(value as string);
            return d.toUSPaddedFormat();
        } else if (config.datatype === 'string') {
            return value as string | number || '';
        } else {
            return this.applyValueFormatter(
                value as number,
                config.datatype,
                config.aggregationType,
                config.decimalPlaces,
                config.numberFormat);
        }
    }

    private applyValueFormatter(
        value: number,
        datatype: string | undefined,
        aggregationType: AggType | null | undefined,
        decimalPlaces: number | string | undefined,
        numberFormat: NumberFormat | undefined,
    ): string {
        return this.formatter.displayFormat(
            value,
            decimalPlaces ?? 2,
            numberFormat ?? defaultNumberFormat(datatype, aggregationType),
            '',
            false);
    }
}

function defaultNumberFormat(datatype: string | undefined, aggregationType: AggType | null | undefined): NumberFormat {
    if (aggregationType === 'count' || aggregationType === 'countDistinct') {
        return 'XX';
    }

    if (datatype === 'decimal') {
        return 'XX.00';
    }

    if (datatype === 'percentage') {
        return '%';
    }

    // We may have a 'money' type in the future, in which case we'll need to check here
    return '$';
}
