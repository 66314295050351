export const outdatedBarColors = [
    { label: 'Dark Blue Fill', value: '#03506F', mapsTo: 'Dark Blue' },
    { label: 'Cyan Fill', value: '#29AAE1', mapsTo: 'Blue' },
    { label: 'Light Cyan Fill', value: '#8ED8F8', mapsTo: 'Light Blue' },
    { label: 'Green Fill', value: '#8CC540', mapsTo: 'Dark Green' },
    { label: 'Light Green Fill', value: '#D0E4AA', mapsTo: 'Light Green' },
    { label: 'Yellow Fill', value: '#FFC940', mapsTo: 'Yellow' },
    { label: 'Light Yellow Fill', value: '#FFE3A6', mapsTo: 'Yellow' },
    { label: 'Orange Fill', value: '#F7941D', mapsTo: 'Yellow' },
    { label: 'Light Orange Fill', value: '#F9A980', mapsTo: 'Yellow' },
    { label: 'Dark Red Fill', value: '#BE202E', mapsTo: 'Dark Red' },
    { label: 'Red Fill', value: '#EF4123', mapsTo: 'Red' },
    { label: 'Dark GrayFill', value: '#808285', mapsTo: 'Dark Gray' },
    { label: 'Black Fill', value: '#333333', mapsTo: 'Black' },
];
