<div class="multi-select-wrap" [ngClass]="{'readonly': isReadOnly}">
    <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="onSelectClose()">
        <div class="select-component" [ngClass]="{'disabled': isDisabled}" (click)="toggleSelectList()">
            <div class="disp-flex" *ngIf="selectedLists.length < 5">
                <div class="selected-list" *ngFor="let selectedList of selectedLists;">
                    <span>{{ selectedList.label }}</span>
                </div>
            </div>
            <div class="selected-list" *ngIf="selectedLists.length > 4">
                <span>{{ selectedLists.length }} of {{ selectListItems.length }} selected</span>
            </div>
            <div class="selected-list" *ngIf="selectedLists.length === 0">
                <p>{{ placeholder }}</p>
            </div>
            <div class="dropdown-arrow">
                <span class="icon-arrow-down"></span>
            </div>
        </div>

        <div class="dropdown" *ngIf="showSelectListItems && selectListItems.length > 0 && !isDisabled">
            <div class="dropdown-user-select">
                <div class="search-list">
                    <cc-checkbox
                        [label]="'All'"
                        *ngIf="isAllrequired"
                        [(value)]="selectAll"
                        (valueChange)="updateSelectedList('all')">
                    </cc-checkbox>

                    <cc-checkbox
                        *ngFor="let item of selectListItems; index as index;"
                        [label]="item.label ?? ''"
                        [(value)]="item.checked"
                        (valueChange)="updateSelectedList();">
                    </cc-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>
