<div class="dropdown-funds">
    <app-list-browser
        buttonLabel="Apply"
        placeholder="Quick Search..."
        [headers]="['Client Code']"
        [options]="(clientsOptions | async) ?? []"
        [showHeader]="true"
        [showCriteria]="false"
        (addListValues)="onAddingClients($event)">
    </app-list-browser>
</div>
