import { Component } from '@angular/core';
import { LegendConfigurationService } from '@ddv/models';

import { BaseBarChartVisualizationComponent } from '../base-bar-chart-visualization/base-bar-chart-visualization.component';

@Component({
    selector: 'app-vbar-chart-visualization',
    template: '<app-viz-wrapper [widgetId]="widgetId" [vizConfig]="visualizationModel" [vizInfo]="vizInfo"></app-viz-wrapper>',
    providers: [LegendConfigurationService],
})
export class VerticalBarChartVisualizationComponent extends BaseBarChartVisualizationComponent {
    configureChart(): void {
        if (!this.preferences) {
            return console.error('cannot configureChart without preferences');
        }

        this.currentSlicerDatatype = this.configs?.slicers.find((config) => config.label === this.vizInfo?.slicer.label)?.datatype ?? '';
        const xField = this.vizInfo?.slicer.value ?? '';
        const yField = this.configs?.values[0].value ?? '';
        this.yAxis = [
            {
                type: 'numeric',
                position: 'left',
                field: [yField],
                hide: false,
                nTicks: 10,
                customClass: '',
                domain: [],
            },
        ];
        this.xAxis = [
            {
                type: this.currentSlicerDatatype === 'date' ? 'date' : 'string',
                position: 'bottom',
                field: [xField],
                hide: false,
                domain: [],
            },
        ];

        this.chartSeries = [
            {
                type: 'bar',
                stacked: false,
                horizontal: false,
                tooltipHTML: this.getToolTip.bind(this),
                xField: [xField],
                yField: [yField],
            },
        ];
        this.margin = this.getBarMargin();
        const axisConfig = this.preferences.axisConfigurations;
        this.updateAxisConfig(this.xAxis[0], axisConfig[0]);
        this.updateAxisConfig(this.yAxis[0], axisConfig[1]);
        this.prepareChartModel();
    }

    onMetadataUpdate(): void {}
}
