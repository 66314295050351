<svg version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        width="18px" height="18px"
        viewBox="0 0 18 18" enable-background="new 0 0 18 18"
        xml:space="preserve">
    <g>
        <defs>
            <rect id="SVGID_1_" x="1.143" y="1.196" width="16" height="16" />
        </defs>
        <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" overflow="visible" />
        </clipPath>
        <path clip-path="url(#SVGID_2_)" fill="#A7A9AC" d="M13.033,8.807L12.326,8.1l-2.683,2.682V2.196h-1v8.585L5.959,8.1L5.252,8.807 l3.891,3.89L13.033,8.807z M14.143,12.196v2h-10v-2h-1v2c0,0.552,0.447,1,1,1h10c0.553,0,1-0.448,1-1v-2H14.143z" />
    </g>
</svg>
