export function getMinimumValue<T extends { [key: string]: number | string }>(
    source: T[],
    valueField: string,
): number | null {
    return source.length ? source.reduce((min, column) => {
        return Number(column[valueField]) < min ? Number(column[valueField]) : min;
    }, Number(source[0][valueField])) : null;
}

export function getMaximumValue<T extends { [key: string]: number | string }>(
    source: T[],
    valueField: string,
): number | null {
    return source.length ? source.reduce((max, column) => {
        return Number(column[valueField]) > max ? Number(column[valueField]) : max;
    }, Number(source[0][valueField])) : null;
}

export function getSumValue<T extends { [key: string]: number | string }>(source: T[], valueField: keyof T): number {
    return source.reduce((sum, column) => sum + Number(column[valueField]), 0);
}

export function getAvgValue<T extends { [key: string]: number | string }>(source: T[], valueField: string): number {
    return getSumValue(source, valueField) / getCountValue(source);
}

export function getCountValue<T>(source: T[]): number {
    return source.length;
}

export function getCountValueExcludingZeros<T>(source: T[], valueField: keyof T): number {
    return source.filter((column) => column[valueField] !== 'N/A' && column[valueField] !== 0).length;
}

export function getSourceFieldSort<T extends { [key: string]: string | number }>(
    source: T[],
    valueField: string,
): (string | number)[] {
    return source.map((item) => item[valueField]).sort();
}

export function getCountDistinctValue<T extends { [key: string]: number | string }>(source: T[], valueField: string): number {
    const tracker: Set<string | number> = new Set();
    source.forEach((item) => tracker.add(item[valueField]));
    return tracker.size;
}

export function getCountDistinctValueExcludingZeros<T extends { [key: string]: number | string }>(
    source: T[],
    valueField: string,
): number {
    const tracker: Set<string | number> = new Set();
    source.forEach((item) => {
        const value = item[valueField];
        if (value !== 'N/A' && value !== 0) {
            tracker.add(value);
        }
    });
    return tracker.size;
}
