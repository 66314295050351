import { Component, OnInit } from '@angular/core';
import { ModalDialogActive, TypeaheadConfig } from '@ddv/common-components';
import { SavedViewService } from '@ddv/dataset-definitions';

@Component({
    templateUrl: './saved-view-selector.component.html',
    styleUrls: ['./saved-view-selector.component.scss'],
})
export class SavedViewSelectorComponent implements OnInit {
    masterReportsLoaded = false;
    masterReportSelected = false;

    masterSelectorConfig: TypeaheadConfig | undefined;
    savedViewSelectorConfig: TypeaheadConfig | undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    masters: any[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    savedViews: any[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedSavedView: any | undefined;

    constructor(
        private readonly savedViewService: SavedViewService,
        public activeModal: ModalDialogActive,
    ) {}

    ngOnInit(): void {
        this.savedViewService.fetchSupportedMasterReports()
            .subscribe((masters) => {
                this.masters = masters;
                this.initMasterSelector();
                this.masterReportsLoaded = true;
            });
    }

    private initMasterSelector(): void {
        this.masterSelectorConfig = {
            placeholder: 'Master Report Name',
            displayProperty: 'display_name',
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onMasterSelected(master: any): void {
        this.masterReportSelected = true;
        this.savedViews = undefined;

        this.savedViewService.fetchSavedViewsForMaster(master.id)
            .subscribe((savedViews) => {
                this.savedViews = savedViews;
                this.initSavedViewSelector();
            });
    }

    private initSavedViewSelector(): void {
        this.savedViewSelectorConfig = {
            displayProperty: 'display_name',
            placeholder: 'Saved View Name',
        };
    }

    onLoadQueryClicked(): void {
        this.activeModal.close(this.selectedSavedView);
    }
}
