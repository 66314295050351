<svg version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        width="20px" height="20px"
        viewBox="0 0 20 20" enable-background="new 0 0 20 20"
        xml:space="preserve">
    <path opacity="0.5" fill="#78797C" enable-background="new    " d="M-23.9,14.339h-1.197V7.05h10.53v1.357l0.81,0.43V6.239h-12.149
                    v8.908h2.063L-23.9,14.339z"/>
    <path opacity="0.5" fill="#78797C" enable-background="new    " d="M-13.758,5.44l-17.01-0.014V2.78
                    c0-0.766,0.546-1.391,1.215-1.391h14.58c0.672,0,1.215,0.625,1.215,1.391V5.44z M-29.957,4.618l15.39,0.012V2.78
                    c0-0.314-0.185-0.58-0.405-0.58h-14.58c-0.219,0-0.404,0.266-0.404,0.58V4.618z"/>
    <path opacity="0.5" fill="#78797C" enable-background="new    " d="M-26.717,15.147h-2.654c-0.766,0-1.39-0.543-1.39-1.215
                    l-0.007-7.693h4.051V15.147z M-29.957,10.013l0.007,0.404v3.518c0,0.219,0.265,0.404,0.579,0.404h1.844V7.05h-2.43V10.013z"/>
    <g>
        <circle cx="-17.252" cy="14.151" r="4.541"/>
        <path fill="#78797C" d="M-13.949,11.005c-0.935-0.932-2.16-1.396-3.383-1.395c-1.225,0.002-2.447,0.471-3.38,1.404
                        c-1.864,1.871-1.86,4.9,0.011,6.764c0.935,0.932,2.159,1.398,3.383,1.396c1.225-0.004,2.447-0.473,3.38-1.406
                        C-12.074,15.897-12.079,12.87-13.949,11.005 M-14.461,17.249c-0.762,0.764-1.777,1.188-2.857,1.189
                        c-1.082,0.002-2.098-0.418-2.863-1.182c-1.582-1.574-1.584-4.143-0.01-5.723c0.764-0.764,1.778-1.188,2.86-1.189
                        c1.08,0,2.097,0.418,2.862,1.182c0.765,0.764,1.188,1.779,1.189,2.859S-13.697,16.483-14.461,17.249 M-16.962,12.185h-0.735
                        l0.003,1.84l-1.839,0.002l0.001,0.736l1.839-0.002l0.003,1.838h0.737l-0.004-1.84l1.839-0.002l-0.001-0.736l-1.839,0.004
                        L-16.962,12.185z"/>
    </g>
    <path opacity="0.5" fill="#78797C" enable-background="new    " d="M8.113,14.392l-5.982-0.053c-0.438,0-0.796-0.373-0.796-0.83
                    V3.028c0-0.457,0.358-0.828,0.796-0.828h13.801c0.438,0,0.793,0.371,0.793,0.828v4.889c0.256,0.051,0.535,0.178,0.811,0.229V3.028
                    c0-0.902-0.72-1.639-1.604-1.639H2.131c-0.886,0-1.605,0.736-1.605,1.639v10.48c0,0.904,0.72,1.637,1.605,1.637h6.096
                    C8.117,14.71,8.15,14.636,8.113,14.392z"/>
    <path fill="#78797C" d="M13.805,2.862c-0.539,0-0.977,0.438-0.977,0.977c0,0.201,0.076,0.377,0.18,0.531L9.479,7.974
                    c-0.17-0.145-0.385-0.242-0.626-0.242c-0.267,0-0.507,0.107-0.683,0.279L6.932,6.888c0.047-0.115,0.075-0.242,0.075-0.377
                    c0-0.541-0.438-0.979-0.978-0.979c-0.539,0-0.978,0.438-0.978,0.979c0,0.104,0.03,0.197,0.06,0.289L3.426,7.995
                    c-0.174-0.16-0.402-0.264-0.657-0.264c-0.54,0-0.978,0.438-0.978,0.979s0.438,0.977,0.978,0.977S3.746,9.251,3.746,8.71
                    c0-0.139-0.03-0.27-0.081-0.389L5.31,7.157c0.179,0.199,0.432,0.328,0.72,0.328c0.258,0,0.488-0.104,0.664-0.266l1.25,1.135
                    C7.9,8.466,7.873,8.585,7.873,8.71c0,0.541,0.438,0.977,0.977,0.977S9.828,9.251,9.828,8.71c0-0.15-0.04-0.289-0.1-0.414
                    l3.566-3.643c0.152,0.094,0.32,0.162,0.51,0.162c0.539,0,0.978-0.436,0.978-0.977C14.782,3.3,14.346,2.862,13.805,2.862z"/>
    <g>
        <circle cx="15.12" cy="14.151" r="4.54"/>
        <path fill="#78797C" d="M18.424,11.005c-0.936-0.932-2.16-1.396-3.383-1.395c-1.225,0.002-2.447,0.471-3.38,1.404
                        c-1.864,1.871-1.86,4.9,0.011,6.764c0.934,0.932,2.159,1.398,3.383,1.396c1.225-0.004,2.446-0.473,3.38-1.406
                        C20.299,15.897,20.295,12.87,18.424,11.005 M17.912,17.249c-0.762,0.764-1.777,1.188-2.857,1.189
                        c-1.082,0.002-2.098-0.418-2.863-1.182c-1.582-1.574-1.585-4.143-0.01-5.723c0.764-0.764,1.778-1.188,2.859-1.189
                        c1.08,0,2.098,0.418,2.863,1.182c0.765,0.764,1.188,1.779,1.189,2.859S18.676,16.483,17.912,17.249 M15.412,12.185h-0.736
                        l0.003,1.84l-1.839,0.002l0.001,0.736l1.839-0.002l0.003,1.838h0.735l-0.002-1.84l1.839-0.002l-0.001-0.736l-1.839,0.004
                        L15.412,12.185z"/>
    </g>
</svg>
