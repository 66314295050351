import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { SelectedValues, ValueOption } from '@ddv/common-components';
import { Fund } from '@ddv/models';
import { FundsService } from '@ddv/reference-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-funds-form',
    templateUrl: './funds-dashboard-form.component.html',
    styleUrls: ['./funds-dashboard-form.component.scss'],
})
export class FundsDashboardFormComponent implements OnInit, OnChanges {
    @Input() selectedFunds: Fund[] = [];

    @Output() fundsApply = new EventEmitter<Fund[]>();

    funds: Fund[] | undefined;
    fundsOptions: Observable<ValueOption[]> | undefined;

    constructor(private readonly fundService: FundsService) { }

    ngOnChanges(): void {
        this.selectedFunds = this.selectedFunds || [];
    }

    ngOnInit(): void {
        this.fundService.funds().subscribe((funds) => this.funds = funds);
        this.fundsOptions = this.fundService.funds().pipe(map((funds) => funds.map((fund) => ({
            texts: [fund.fundName, fund.fundId, fund.portfolioLevel],
            value: fund.fundId,
            // until funds come from Trebek
            // selectedFunds aren't an instance of Fund[]
            // but an array of objects with fundId and fundName
            checked: this.selectedFunds.some((selected) => selected instanceof Fund && selected.sameAs(fund)),
        }))));
    }

    onAddingFunds(selectedFunds?: SelectedValues): void {
        this.fundsApply.emit(this.funds?.filter((fund) => selectedFunds?.values?.includes(fund.fundId)));
    }
}
