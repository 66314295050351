import { Injectable } from '@angular/core';
import { Theme } from '@hs/ui-core-presentation';

import { PDFExportService } from './pdf-export.service';

@Injectable()
export class DashboardPDFExportService {
    constructor(private readonly pdfExportService: PDFExportService) {
        this.docModificationCallback = this.docModificationCallback.bind(this);
    }

    exportDashboardToPdf(fileName: string, theme: Theme = Theme.light): void {
        const dashboard = document.getElementById('layout-wrapper');
        if (!dashboard) {
            return console.error('cannot exportDashboardToPdf without a dashboard');
        }

        this.pdfExportService.generatePDF(
            dashboard,
            fileName,
            dashboard.clientWidth,
            dashboard.clientHeight,
            this.docModificationCallback.bind(this),
            theme);
    }

    private docModificationCallback(clonedDocument: Document): void {
        const clonedDashboard = clonedDocument.getElementById('layout-wrapper');
        if (!clonedDashboard) {
            return console.error('cannot docModificationCallback without a cloneDashboard');
        }

        this.updateWidgetZIndex(clonedDashboard);
        this.removeDashboardElements(clonedDashboard, ['app-dashboard-export']);
        this.updateGridIcons(clonedDashboard);
        this.modifySVG(clonedDashboard);
    }

    private updateWidgetZIndex(dashboard: HTMLElement): void {
        const widgets = dashboard.getElementsByClassName('ngPopup');
        for (let i = 0, j = widgets.length; i < j; i++) {
            (widgets[i] as HTMLElement).style.zIndex = '-1';
        }
    }

    private removeDashboardElements(dashboard: HTMLElement, elementsTag: string[]): void {
        elementsTag.forEach((elementTag) => {
            const elements = dashboard.getElementsByTagName(elementTag);
            if (elements.length) {
                const elementParent = elements[0].parentNode;
                elementParent?.removeChild(elements[0]);
            }
        });
    }

    private updateGridIcons(dashboard: Element): void {
        this.updateGridIconImage(dashboard, 'ag-icon-none', './assets/images/sorting.png');
        this.updateGridIconImage(dashboard, 'sort-ascending', './assets/images/sort-ascending.png');
        this.updateGridIconImage(dashboard, 'sort-descending', './assets/images/sort-descending.png');
        this.updateGridIconImage(dashboard, 'custom-checkbox-checked', './assets/images/checkboxChecked.png');
        this.updateGridIconImage(dashboard, 'custom-checkbox-unchecked', './assets/images/checkboxUnchecked.png');
        this.updateGridIconImage(dashboard, 'ag-column-drop-cell-button', './assets/images/close.png');
    }

    private updateGridIconImage(dashboard: Element, iconSelector: string, imagePath: string): void {
        const icons = dashboard.getElementsByClassName(iconSelector);
        for (let i = 0, j = icons.length; i < j; i++) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ((icons[i] as HTMLElement).style as any)['background-image'] = `url(${imagePath})`;
        }
    }

    private modifySVG(targetElem: Element): void {
        const nodesToRemove = [];
        const svgElems = targetElem.getElementsByTagName('svg');

        for (let i = 0, j = svgElems.length; i < j; i++) {
            this.updateFill(svgElems[i]);
            this.updateStyleForTextAndHiddenElements(svgElems[i]);
            const parentNode = svgElems[i].parentNode as SVGElement;
            const svg = parentNode.innerHTML;

            const canvas = this.pdfExportService.svgToCanvas(svg);

            nodesToRemove.push({
                parent: parentNode,
                child: svgElems[i],
            });

            parentNode.appendChild(canvas);
        }
        nodesToRemove.forEach((node) => {
            node.parent.removeChild(node.child);
        });
    }

    private updateFill(targetElem: Element): void {
        const lineElements = targetElem.getElementsByTagName('polyline');
        const pathElements = targetElem.getElementsByTagName('path');
        const zoomElements = targetElem.getElementsByClassName('zoom');

        for (let i = 0, j = lineElements.length; i < j; i++) {
            lineElements[i].setAttribute('fill', 'none');
            lineElements[i].setAttribute('stroke-width', '.5px');
            lineElements[i].setAttribute('stroke', '#a7aca9');
        }
        for (let i = 0, j = pathElements.length; i < j; i++) {
            pathElements[i].setAttribute('fill', 'none');
        }

        for (let i = 0, j = zoomElements.length; i < j; i++) {
            zoomElements[i].setAttribute('fill', 'none');
        }
    }

    private updateStyleForTextAndHiddenElements(targetElem: Element): void {
        const textElements = targetElem.getElementsByTagName('text');
        const hiddenElements = targetElem.getElementsByClassName('hide');
        const transformProperties = [
            'fill',
            'color',
            'font-size',
            'stroke',
            'font',
        ];
        for (let i = 0, j = textElements.length; i < j; i++) {
            this.applyInlineStyle(textElements[i], transformProperties);
        }
        for (let i = 0, j = hiddenElements.length; i < j; i++) {
            // Fix for y axis labels are showing on both left and right hand side in line chart in pdf
            this.applyInlineStyle(hiddenElements[i], ['display']);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private applyInlineStyle(node: any, transformProperties: string[]): void {
        if (!node.style) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const styles = getComputedStyle(node) as any;

        for (const transformProperty of transformProperties) {
            node.style[transformProperty] = styles[transformProperty];
        }

        for (const child of Array.from(node.childNodes)) {
            this.applyInlineStyle(child, transformProperties);
        }
    }
}

