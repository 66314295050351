import { Component } from '@angular/core';
import { LegendConfigurationService } from '@ddv/models';

import { BaseCircleChartVisualizationComponent } from '../base-circle-chart-visualization/base-circle-chart-visualization.component';

@Component({
    selector: 'app-pie-chart-visualization',
    templateUrl: './pie-chart-visualization.component.html',
    styleUrls: ['./pie-chart-visualization.component.scss'],
    providers: [LegendConfigurationService],
})
export class PieChartVisualizationComponent extends BaseCircleChartVisualizationComponent {
    onMetadataUpdate(): void {}

    configureChart(): void {
        this.xAxis = [];
        this.yAxis = [];
        this.chartSeries = [
            {
                type: 'pie',
                xField: [this.vizInfo?.slicer?.value ?? ''],
                yField: [this.preferences?.configs?.values[0].value ?? ''],
                tooltipHTML: (tooltipData): string => this.getToolTip(tooltipData),
                radius: {
                    inner: 0,
                },
            },
        ];
        this.prepareChartModel();
    }
}
