import { MODE, DashboardDetails, DashboardModel } from '@ddv/models';

import { Workspace } from './workspace';

export function setWidgetExtraParamsFromDashboardDetails(dashboardDetails: DashboardDetails, workspace: Workspace): void {
    // many of these ?? checks are against types that do not say they can be null/undefined
    // the implication is that either they are unnecessary or our types are lies
    const dashboardModel: DashboardModel = new DashboardModel({
        abbreviation: dashboardDetails.abbreviation ?? '',
        widgets: dashboardDetails.widgets ?? [],
        dashboardTags: [],
        dashboardPreferences: dashboardDetails.dashboardPreferences ?? [],
        dashboardPermissions: dashboardDetails.dashboardPermissions ?? [],
        description: dashboardDetails.description ?? '',
        isDefault: dashboardDetails.isDefault ?? false,
        isShared: dashboardDetails.isShared ?? false,
        mode: MODE.VIEW,
        name: dashboardDetails.name,
        privilege: dashboardDetails.privilege,
        deviceMode: dashboardDetails.deviceMode,
        version: dashboardDetails.version,
        clientId: dashboardDetails.clientId,
        isGlobal: dashboardDetails.isGlobal,
        updatedDate: dashboardDetails.updatedDate,
    });
    dashboardModel.dashboardTags = dashboardDetails.dashboardTags.map((dashboardTag) => ({
        id: dashboardTag.id, name: dashboardTag.name, type: dashboardTag.type,
    }));
    workspace.setExtraParameters(dashboardModel);
}
