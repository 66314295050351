import { Inject, Injectable } from '@angular/core';

import { WINDOW } from './injection-tokens';

type VizUIStateChangeReceiver = (
    clientCode: string,
    section: string,
    action: string,
    entityId: string,
) => void;

declare global {
    interface Window {
        onVizUIStateChange?: VizUIStateChangeReceiver;
    }
}

@Injectable({ providedIn: 'root' })
export class WrapperAppService {
    constructor(@Inject(WINDOW) private readonly window: Window) {}

    isWrapped(): boolean {
        return this.window.self !== this.window.top && !!this.window.parent.onVizUIStateChange;
    }

    syncStateToWrapper(
        clientCode: string,
        section: string,
        action: string,
        entityId: string,
    ): void {
        this.window.parent.onVizUIStateChange?.(clientCode, section, action, entityId);
    }
}
