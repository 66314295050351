<div class="typeahead-wrapper" appMousedownOutside (mousedownOutside)="showDropdown = false" [ngClass]="{'readonly': readonly}">
    <span class="icon-search1"></span>

    <input
        class="query"
        type="text"
        autocomplete="off"
        [(ngModel)]="queryFilter"
        [placeholder]="configuration?.placeholder"
        (focus)="showDropdown = true"
        (ngModelChange)="filterItems()"
        (keydown.enter)="updateSelectedItem($event)"
        (keydown.arrowDown)="changeSelectedItem(1)"
        (keydown.arrowUp)="changeSelectedItem(-1)"
    />

    <div class="items" *ngIf="showDropdown && configuration">
        <div class="items-wrap">
            <div class="item" *ngIf="!filteredItems">Loading</div>

            <ul *ngIf="filteredItems">
                <li
                    class="item"
                    *ngFor="let item of filteredItems;"
                    [ngClass]="{'selected': selectedItem === item}"
                    [innerHTML]="item[configuration.displayProperty ?? ''] | highlight: queryFilter"
                    (click)="emitSelection(item)"
                    (mouseover)="selectItem(item)">
                </li>

                <li class="item" *ngIf="!filteredItems.length">No Match Found</li>
            </ul>
        </div>
    </div>
</div>
