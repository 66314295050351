<div class="line-chart-parent">
    <ul class="zoomlist" *ngIf="chartConfiguration?.showBrush">
        <li
                *ngFor="let value of zoom"
                (click)="onRangeClicked(value)"
                [class.activezoom]="zoomValue === value.label">
            {{ value.label }}
        </li>
    </ul>
    <app-viz-wrapper [widgetId]="widgetId" [vizConfig]="chartConfiguration" [vizInfo]="vizInfo"></app-viz-wrapper>
</div>
