import { Injectable } from '@angular/core';
import { UserGridColumnOverrides } from '@ddv/models';

import { OverridesProvider } from './overrides-provider';

@Injectable()
export class OverridesRelayService implements OverridesProvider {
    private provider: OverridesProvider | undefined;

    getCurrentGridColumnOverrides(widgetOnBoardId?: number, visualizationId?: number): UserGridColumnOverrides[] {
        if (!this.provider) {
            throw new Error('cannot provide overrides without linking to another provider');
        }

        if (!widgetOnBoardId || !visualizationId) {
            return [];
        }

        return this.provider.getCurrentGridColumnOverrides(widgetOnBoardId, visualizationId);
    }

    link(provider: OverridesProvider): void {
        this.provider = provider;
    }
}
