<div *ngIf="currentDashboard && !isLastDashboardClosed" class="dashboard-name">
    <h1 [title]="currentDashboard.getExtraParameters().name" [class.viewmode]="inPresentationMode">
        {{currentDashboard.getExtraParameters().name}}
    </h1>
    <cc-icon
        [color]="inPresentationMode ? '' : 'var(--icon-hover)'"
        [hidden]="hideEditIcon()"
        [icon]="'edit-pencil'"
        [size]="'small'"
        [routerLink]="editDashboardPath"
        title="Edit Workspace">
    </cc-icon>

    <div *ngIf="!inPresentationMode" class="btn-group">
        <button
                (click)="saveClick.emit()"
                [disabled]="!dirtyFlagService.isDirty || currentDashboard.getExtraParameters().privilege === 'VIEW'"
                [class.saved]=""
                class="ghost-btn hs-db-header-save">
            Save
        </button>

        <button
                class="ghost-btn hs-db-header-cancel"
                (click)="cancelEdit.emit()">
            Cancel
        </button>

        <button
                class="ghost-btn hs-db-header-revert"
                [disabled]="!dirtyFlagService.isDirty"
                (click)="revertChanges.emit()">
            Revert
        </button>
    </div>

    <div class="view-group" *ngIf="views">
        {{views.name}}

        <span
            class="view-circle"
            *ngFor="let view of views.dashboards"
            [title] = "view.name"
            [ngClass]="{'selected': currentDashboard.id === view.id?.toString()}"
            (click)="switchDashboard(view.id?.toString())">
        </span>
    </div>
</div>
