import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@ddv/models';

@Pipe({ name: 'userListFilter' })
export class UserListFilter implements PipeTransform {
    transform(users: User[], tab: string, searchParam: string): User[] {
        let filteredUsers = users;
        if (tab !== 'ALL') {
            filteredUsers = filteredUsers.filter((user) => user.sharedType.indexOf(tab) !== -1);
        }
        if (searchParam) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name.toLocaleLowerCase().indexOf(searchParam.toLocaleLowerCase()) !== -1,
            );
        }
        return filteredUsers;
    }
}
