import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
    selector: 'app-find-view-icon',
    templateUrl: './find-view-icon.component.html',
    styleUrls: ['./find-view-icon.component.scss'],
})
export class FindViewIconComponent {
    @Output() pressed: EventEmitter<void> = new EventEmitter();

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        event.stopImmediatePropagation();
        event.preventDefault();

        this.pressed.emit();
    }
}
