import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CustomCellRendererParams } from '@ddv/data-grid';

@Component({
    selector: 'app-datetime-cell-renderer',
    styleUrls: ['./datetime-cell-renderer.component.scss'],
    template: '<div class="datetime-wrapper">{{ datetime | date: "MM/dd/yyyy hh:mm:ss a" }}</div>',
})
export class DatetimeCellRendererComponent implements ICellRendererAngularComp {
    datetime: string | undefined;

    agInit(params: CustomCellRendererParams): void {
        this.datetime = this.getValue(params);
    }

    refresh(params: CustomCellRendererParams): boolean {
        this.datetime = this.getValue(params);
        return true;
    }

    private getValue(params: CustomCellRendererParams): string {
        return params.colDef?.field?.startsWith('udf_') ? params.value?.value : params.value;
    }
}
