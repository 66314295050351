import { Component } from '@angular/core';
import { Margin } from '@ddv/charts';
import { LegendConfigurationService } from '@ddv/models';

import { BaseBarChartVisualizationComponent } from '../base-bar-chart-visualization/base-bar-chart-visualization.component';

@Component({
    selector: 'app-hsbar-chart-visualization',
    template: '<app-viz-wrapper [widgetId]="widgetId" [vizConfig]="visualizationModel" [vizInfo]="vizInfo"></app-viz-wrapper>',
    providers: [LegendConfigurationService],
})
export class HorizontalStackedBarChartVisualizationComponent extends BaseBarChartVisualizationComponent {
    configureChart(): void {
        const xField = this.configs?.values[0].value;
        const yField = this.vizInfo?.slicer.value;
        this.yAxis = [
            {
                type: 'string',
                position: 'left',
                field: [yField ?? ''],
                hide: false,
                nTicks: 10,
                customClass: '',
                domain: [],
            },
        ];
        this.xAxis = [
            {
                type: 'numeric',
                position: 'bottom',
                field: [xField ?? ''],
                hide: false,
                domain: [],
            },
        ];

        this.chartSeries = [
            {
                type: 'bar',
                stacked: true,
                horizontal: true,
                tooltipHTML: this.getToolTip.bind(this),
                xField: [xField ?? ''],
                yField: [yField ?? ''],
            },
        ];
        this.margin = this.getBarMargin();
        const axisConfig = this.preferences?.axisConfigurations;
        this.updateAxisConfig(this.xAxis[0], axisConfig?.[0]);
        this.updateAxisConfig(this.yAxis[0], axisConfig?.[1]);
        this.prepareChartModel();
    }

    onMetadataUpdate(): void {}

    protected getBarMargin(): Margin {
        const yAxis = this.preferences?.axisConfigurations?.[1];
        if (!yAxis) {
            throw new Error('Cannot getBarMargin without a yAxis');
        }

        const legendPosition = this.preferences?.enableLegend ? this.preferences.legendPosition : undefined;
        const { position, enableAxis, displayAxisLabels } = yAxis;
        const marginCondition = this.chartsSharedService.getMarginCondition(legendPosition, position, enableAxis, displayAxisLabels);

        const top = marginCondition.legendPosition ? 30 : 20;
        const right = marginCondition.rightSideAxisFlag ? 15 : 35;
        const bottom = 30;
        const left = marginCondition.leftSideAxisFlag ? 5 : 25;

        return { top, right, bottom, left };
    }
}
