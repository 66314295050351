export const outdatedCellColors = [
    { label: 'Dark Blue Fill', value: '#004F6E', mapsTo: 'Dark Blue' },
    { label: 'Light Blue Fill', value: '#DFE1E6', mapsTo: 'Light Blue' },
    { label: 'Cyan Fill', value: '#00ADEE', mapsTo: 'Blue' },
    { label: 'Light Cyan Fill', value: '#E4F5FD', mapsTo: 'Light Gray' },
    { label: 'Red Fill', value: '#EF4123', mapsTo: 'Dark Red' },
    { label: 'Dark Red Fill', value: '#C50314', mapsTo: 'Dark Red' },
    { label: 'Light Red Fill', value: '#E33E3E', mapsTo: 'Light Red' },
    { label: 'Medium Pink Fill', value: '#F67C7C', mapsTo: 'Light Red' },
    { label: 'Light Pink Fill', value: '#FAB3B3', mapsTo: 'Light Red' },
    { label: 'Green Fill', value: '#8BC53F', mapsTo: 'Dark Green' },
    { label: 'Light Green Fill', value: '#ECF4E0', mapsTo: 'Light Green' },
    { label: 'Orange Fill', value: '#F7941D', mapsTo: 'Yellow' },
    { label: 'Dark Orange Fill', value: '#F19631', mapsTo: 'Yellow' },
    { label: 'Light Orange Fill', value: '#FFC88B', mapsTo: 'Yellow' },
    { label: 'Dark Yellow Fill', value: '#E9BF30', mapsTo: 'Yellow' },
    { label: 'Yellow Fill', value: '#FFCA40', mapsTo: 'Yellow' },
    { label: 'Light Yellow Fill', value: '#FFDB75', mapsTo: 'Yellow' },
    { label: 'Light Grey Fill', value: '#CCCFD6', mapsTo: 'Gray' },
    { label: 'Medium Grey Fill', value: '#7D8C95', mapsTo: 'Medium Gray' },
    { label: 'Dark Grey Fill', value: '#5A676E', mapsTo: 'Dark Gray' },
    { label: 'Black Fill', value: '#000000', mapsTo: 'Black' },
    { label: 'No Background', value: '', mapsTo: 'N/A' },
];
