import { DropZoneConfig } from './configure/configure.component';

export const defaultConfig: DropZoneConfig[] = [
    {
        id: 'value',
        label: 'Value',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        configKey: 'values',
        maximumAllowed: 1,
        draggable: false,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'boolean',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
    },
    {
        id: 'slicer',
        label: 'Slicer',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: true,
        configKey: 'slicers',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'string',
            'date',
            'boolean',
            'choice',
        ],
    },
    {
        id: 'tooltip',
        label: 'Tooltip',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: false,
        allowMultiple: true,
        configKey: 'tooltips',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
        allowDuplicate: true,
    },
];

export const grid: DropZoneConfig[] = [
    {
        id: 'value',
        label: 'Enable fields for tool panel',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        draggable: true,
        configKey: 'values',
    },
];

export const line: DropZoneConfig[] = [
    {
        id: 'lvalue',
        label: 'Value',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: true,
        configKey: 'values',
        maximumAllowed: 2,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'boolean',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
    },
    {
        id: 'lslicer',
        label: 'Slicer or Additional value(s)',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: false,
        allowMultiple: true,
        configKey: 'slicers',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'string',
        ],
    },
    {
        id: 'axisTime',
        label: 'Axis Time',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        configKey: 'axisTimes',
        dataTypes: [
            'date',
        ],
        maximumAllowed: 1,
        draggable: false,
    },
    {
        id: 'tooltip',
        label: 'Tooltip',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: false,
        allowMultiple: true,
        configKey: 'tooltips',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
        allowDuplicate: true,
    },
];

export const stackedArea: DropZoneConfig[] = [
    {
        id: 'savalue',
        label: 'Value',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        configKey: 'values',
        maximumAllowed: 1,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'boolean',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
    },
    {
        id: 'saslicer',
        label: 'Slicer',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: true,
        configKey: 'slicers',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'string',
        ],
    },
    {
        id: 'axisTime',
        label: 'Axis Time',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        configKey: 'axisTimes',
        dataTypes: [
            'date',
        ],
        maximumAllowed: 1,
        draggable: false,
    },
    {
        id: 'tooltip',
        label: 'Tooltip',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: false,
        allowMultiple: true,
        configKey: 'tooltips',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
        allowDuplicate: true,
    },
];

export const mirroredBar: DropZoneConfig[] = [
    {
        id: 'value1',
        label: 'value1 (Top Axis)',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: true,
        configKey: 'values',
        maximumAllowed: 1,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'boolean',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
    },
    {
        id: 'value2',
        label: 'value2 (Bottom Axis)',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: true,
        configKey: 'mirroredValues',
        maximumAllowed: 1,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'boolean',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
    },
    {
        id: 'slicer',
        label: 'Slicer',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: true,
        configKey: 'slicers',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'string',
            'date',
            'boolean',
            'choice',
        ],
    },
    {
        id: 'tooltip',
        label: 'Tooltip',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: false,
        allowMultiple: true,
        configKey: 'tooltips',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
        allowDuplicate: true,
    },
];

export const stackedBar: DropZoneConfig[] = [
    {
        id: 'value',
        label: 'Value',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        configKey: 'values',
        maximumAllowed: 1,
        draggable: false,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'boolean',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
    },
    {
        id: 'slicer',
        label: 'Slicer',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: true,
        configKey: 'slicers',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'string',
            'date',
            'boolean',
            'choice',
        ],
    },
    {
        id: 'details',
        label: 'Details',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        configKey: 'details',
        maximumAllowed: 1,
        draggable: false,
        dataTypes: [
            'string',
            'date',
            'boolean',
            'choice',
        ],
    },
    {
        id: 'tooltip',
        label: 'Tooltip',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: false,
        allowMultiple: true,
        configKey: 'tooltips',
        maximumAllowed: 10,
        draggable: true,
        dataTypes: [
            'percentage',
            'decimal',
            'string',
            'date',
            'number',
            'choice',
        ],
        enableAggDropDown: true,
        allowDuplicate: true,
    },
];

export const summary: DropZoneConfig[] = [
    {
        id: 'value',
        label: 'Summary',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        draggable: false,
        configKey: 'summary',
        maximumAllowed: 1,
    },
    {
        id: 'filter',
        label: 'Filter',
        allowed: true,
        placeholder: 'Drag value fields here',
        mandatory: true,
        allowMultiple: false,
        draggable: false,
        configKey: 'filters',
        allowDuplicate: false,
        maximumAllowed: 1,
    },
];
