import {
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    Input,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService, DashboardModeAndId } from '@ddv/behaviors';
import { ConfirmationPopupService, ModalDialogService } from '@ddv/common-components';
import { DatasetDefinitionSelectorComponent, DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { UserEntitlementService } from '@ddv/entitlements';
import { ManagerService } from '@ddv/layout';
import {
    MANAGE_WIDGET_ID,
    WIDGET_LIFECYCLE_EVENT,
    DatasetDefinition,
    DatasetDefinitionDetails,
    ConfigItem,
    VisualizationConfigs,
    VisualizationType,
    VizConfigs,
    AppWidgetState,
    WidgetSnapshot,
    TWidgetTypes,
    NamedQuery,
} from '@ddv/models';
import { isNamedQuery, NamedQueriesService } from '@ddv/named-queries';
import { FuzzyDatesService } from '@ddv/reference-data';
import { getUniqueBy, deepClone, deepExtend } from '@ddv/utils';
import { SelectedWidgetRelayService, GridEvent, visualizationDescriptorMap } from '@ddv/visualizations';
import {
    WidgetFinderComponent,
    ApplicationTabConfiguration,
    ApplicationWidgetConfiguration,
    WidgetService,
    WidgetsStateRelayService,
    WidgetsService,
    VisualizationListItemConfig,
} from '@ddv/widgets';
import { ModalAction } from '@hs/ui-core-notifications';
import { Subscription, forkJoin, combineLatest, Observable } from 'rxjs';

import { DataService } from '../../../services/data.service';
import { ColorChartComponent } from '../color-chart/color-chart.component';
import { ConfigureGridComponent } from '../configure-grid/configure-grid.component';
import { ConfigureComponent } from '../configure/configure.component';
import { DynamicComponent } from '../dynamic.component';
import { FormatComponent } from '../format.component';
import { VizTabsConfiguration } from '../manage-widgets.configuration';
import {
    VisualizationNavHeaderComponent,
} from '../visualization-nav-header/visualization-nav-header.component';

export interface UserSelectedConfigs {
    coreWidgetType: TWidgetTypes;
    summaryConfig: VisualizationConfigs[];
    visualizationConfigs: VisualizationConfigs[];
    datasetDefinition: DatasetDefinition | NamedQuery;
}

@Component({
    selector: 'app-mw-left-nav',
    templateUrl: './manage-widgets-left-nav.component.html',
    styleUrls: ['./manage-widgets-left-nav.component.scss'],
})
export class MWLeftNavComponent implements OnInit, OnDestroy {
    @Input() currentWidgetId: number | undefined;
    @Input() datasetDefinition: NamedQuery | DatasetDefinition | undefined;

    @Output() navToggled = new EventEmitter<boolean>();
    @Output() transitionEnd = new EventEmitter<Event>();
    @Output() datasetDefinitionChanged = new EventEmitter<DatasetDefinition | NamedQuery>();
    @Output() autoAddDynamicColumnsChanged = new EventEmitter<Map<string, boolean>>();

    supportedWidgets: ApplicationWidgetConfiguration[] = [];
    showWidgetList = false;
    isNavExpanded = true;
    searchValue: string | undefined;
    selectedWidgets: ApplicationWidgetConfiguration[] = [];
    activeVizTab: ApplicationTabConfiguration | undefined;
    dashboardModeAndIdSubscription: Subscription | undefined;

    selectedTab = 'configure';
    showColorTab = false;
    isReadOnly = false;
    autoAddDynamicColumns = false;
    autoAddDynamicColumnsVisualizations: Map<string, boolean> = new Map();

    colorTabViz = [
        'PIE_CHART',
        'DONUT_CHART',
        'VERTICAL_STACKED_BAR_CHART',
        'HORIZONTAL_STACKED_BAR_CHART',
        'VERTICAL_BAR_CHART',
        'HORIZONTAL_BAR_CHART',
        'VERTICAL_MIRRORED_BAR_CHART',
        'LINE_CHART',
        'STACKED_AREA_CHART',
    ];

    @ViewChild('dsdSelector', { static: true }) private readonly datasetDefinitionSelector: DatasetDefinitionSelectorComponent | undefined;
    @ViewChild('configureTabContainer', { read: ViewContainerRef, static: false }) private readonly configureTabContainer: ViewContainerRef | undefined;
    @ViewChild('formatTabContainer', { read: ViewContainerRef, static: false }) private readonly formatTabContainer: ViewContainerRef | undefined;
    @ViewChild('colorTabContainer', { read: ViewContainerRef, static: false }) private readonly colorTabContainer: ViewContainerRef | undefined;
    @ViewChild('visualizationNavTabHeader', { static: false }) private readonly visualizationNavTabHeader: VisualizationNavHeaderComponent | undefined;
    @ViewChild('manageWidgetNav', { static: true }) private readonly manageWidgetNav: ElementRef | undefined;

    private configureTabs: { [guid: string]: ComponentRef<DynamicComponent> } = {};
    private formatTabs: { [guid: string]: ComponentRef<DynamicComponent> } = {};
    private colorTabs: { [guid: string]: ComponentRef<DynamicComponent> } = {};

    private isPreviewInitialized = false;
    private previewInitSubscription: Subscription | undefined;
    private inWidgetEditMode = false;
    private gridStateSubscription: Subscription | undefined;

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly currentStateService: CurrentStateService,
        private readonly dataService: DataService,
        private readonly widgetService: WidgetService,
        private readonly modalService: ModalDialogService,
        private readonly manageWidgetsCommService: WidgetsStateRelayService,
        private readonly managerService: ManagerService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly fuzzyDateService: FuzzyDatesService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly userEntitlementService: UserEntitlementService,
        private readonly widgetsService: WidgetsService,
        private readonly selectedWidgetRelayService: SelectedWidgetRelayService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly namedQueriesService: NamedQueriesService,
    ) { }

    ngOnInit(): void {
        if (!this.manageWidgetNav) {
            return console.error('Cannot init without manageWidgetNav');
        }

        const sideNav = this.manageWidgetNav.nativeElement;
        const transitionEvent = this.whichTransitionEvent(sideNav);
        if (transitionEvent) {
            sideNav.addEventListener(transitionEvent, () => {
                this.transitionEnd.emit();
            });
        }
        this.subscribeToPreviewInit();
        this.gridStateSubscription = this.widgetService.gridStateObs.subscribe((gridStateEvent) => {
            this.updateConfigurations(gridStateEvent);
        });

        combineLatest([this.userEntitlementService.entitlementsForClientCode$, this.selectedWidgetRelayService.isWidgetGlobal])
            .subscribe(([entitlements, isWidgetGlobal]) => {
                this.isReadOnly = entitlements.haveGlobalEditPartial && isWidgetGlobal;
            });
    }

    onDatasetDefinitionSelected(selected: DatasetDefinition | NamedQuery): void {
        if (!this.datasetDefinition) {
            this.applyNewDatasetDefinition(selected);
        } else {
            let originalDataset$: Observable<NamedQuery | DatasetDefinitionDetails>;
            let selectedDataset$: Observable<NamedQuery | DatasetDefinitionDetails>;

            if (typeof this.datasetDefinition.id === 'number') {
                originalDataset$ = this.datasetDefinitionsService.fetchDatasetDefinitionDetails(this.datasetDefinition.id);
            } else {
                originalDataset$ = this.namedQueriesService.fetchNamedQuery(String(this.datasetDefinition.id));
            }

            if (typeof selected.id === 'number') {
                selectedDataset$ = this.datasetDefinitionsService.fetchDatasetDefinitionDetails(selected.id);
            } else {
                selectedDataset$ = this.namedQueriesService.fetchNamedQuery(String(selected.id));
            }

            forkJoin([originalDataset$, selectedDataset$]).subscribe(([originalDetails, selectedDetails]) => {
                if (this.areDatasetsDifferent(originalDetails, selectedDetails)) {
                    this.confirmDatasetDefinitionChange(selected);
                } else {
                    this.updateDatasetDefinition(selected);
                }
            });
        }
    }

    areDatasetsDifferent(
        originalDataset: DatasetDefinitionDetails | NamedQuery,
        newDataset: DatasetDefinitionDetails | NamedQuery,
    ): boolean {
        let originalTemplate: string;
        let originalType: string;
        let newTemplate: string;
        let newType: string;

        if (originalDataset instanceof DatasetDefinitionDetails) {
            originalTemplate = originalDataset.queryTemplate?.replace(/(\s)/g, '');
            originalType = originalDataset.queryType.name;
        } else {
            originalTemplate = originalDataset.template?.replace(/(\s)/g, '');
            originalType = originalDataset.type.name;
        }

        if (newDataset instanceof DatasetDefinitionDetails) {
            newTemplate = newDataset.queryTemplate?.replace(/(\s)/g, '');
            newType = newDataset.queryType.name;
        } else {
            newTemplate = newDataset.template?.replace(/(\s)/g, '');
            newType = newDataset.type.name;
        }

        return newType !== originalType || originalTemplate !== newTemplate;
    }

    confirmDatasetDefinitionChange(selected: DatasetDefinition | NamedQuery): void {
        this.initializePopup().subscribe({
            next: (action) => {
                if (action === 'confirm') {
                    this.applyNewDatasetDefinition(selected);
                } else {
                    this.datasetDefinitionSelector?.restorePriorSelection();
                }
            },
            error: (error) => {
                console.error(`An error occurred: ${error}`);
                this.datasetDefinitionSelector?.restorePriorSelection();
            },
        });
    }

    applyNewDatasetDefinition(selected: DatasetDefinition | NamedQuery): void {
        this.datasetDefinition = selected;
        this.datasetDefinitionChanged.emit(this.datasetDefinition);

        this.clearModel();
        this.resetPreviewView();
        this.sendDatasetUpdateMessage();

        this.selectedWidgets = [];
        this.setWidgetsAndSendMessage();
    }

    updateDatasetDefinition(selected: DatasetDefinition | NamedQuery): void {
        this.datasetDefinition = selected;
        this.datasetDefinitionChanged.emit(this.datasetDefinition);
        this.sendDatasetUpdateMessage();
        this.manageWidgetsCommService.publishSaveStatus(true);
    }

    showWidgets(): void {
        this.showWidgetList = true;
    }

    toggleWidget(widget: ApplicationWidgetConfiguration): void {
        if (widget.selected) {
            widget.selected = !widget.selected;
            this.selectedWidgets = [];
            this.updateCoreWidget();
            return;
        }
        if (this.selectedWidgets.length !== 0) {
            this.initializePopup().subscribe({
                next: (action) => {
                    this.showWidgetList = false;

                    if (action === 'confirm') {
                        this.changeComponentAttachedState(this.activeVizTab, false);
                        this.resetPreviewView();
                        this.manageWidgetsCommService.publishClearInfo();
                        this.addNonVizWidget(widget);
                        this.onNavTabFocus({ tabIndex: 0, vizTab: this.selectedWidgets[0] as unknown as ApplicationTabConfiguration });
                    }
                },
            });
        } else {
            this.addNonVizWidget(widget);
        }
    }

    toggleNav(): void {
        this.isNavExpanded = !this.isNavExpanded;
        this.navToggled.emit(this.isNavExpanded);
    }

    removeSelectedWidget(widget: { tab: ApplicationTabConfiguration, hierarchy: string, index: number }): void {
        this.removeTabOnSupportedWidget(widget);
        this.destroyComponents(widget.tab);
        this.removeSelectedTabDeps(widget.tab);
        this.updateLiveWidgetView();
        this.validateVizTabsAvailability();

        this.visualizationNavTabHeader?.loadActiveNavTab();
    }

    onDragDropSucceeded(): void {
        this.manageWidgetsCommService.publishSaveStatus(true);
    }

    removeTabOnSupportedWidget(widget: { tab: ApplicationTabConfiguration, hierarchy: string, index: number }): void {
        if (widget.hierarchy === 'root') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const selectedWidget = this.supportedWidgets.find((item) => item.uid === (widget.tab as any).uid);
            if (selectedWidget) {
                selectedWidget.selected = false;
            }
            this.selectedWidgets.splice(widget.index, 1);
        } else {
            const supportedWidget = this.supportedWidgets.find((supWidget) => supWidget.isViz);
            const vizConfig = supportedWidget?.vizConfigs?.find((item) => {
                return item.visualizationType === widget.tab.visualizationType;
            });

            if (vizConfig) {
                vizConfig.selected = false;
            }
        }
    }

    removeSelectedTabDeps(vizTab: ApplicationTabConfiguration): void {
        if (this.configureTabs[vizTab.guid] && this.formatTabs[vizTab.guid]) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.configureTabs[vizTab.guid];
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete this.formatTabs[vizTab.guid];
            if (this.colorTabs[vizTab.guid]) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete this.colorTabs[vizTab.guid];
            }
        }
    }

    clearAllVizTabSections(): void {
        this.configureTabs = {};
        this.formatTabs = {};
        this.colorTabs = {};
    }

    validateVizTabsAvailability(): void {
        const validVizTabs = this.selectedWidgets.some((widget) =>
            (widget.isViz && widget.vizTabs?.length as number > 0) || (!widget.isViz),
        );
        if (!validVizTabs) {
            this.selectedWidgets = [];
            this.clearAllVizTabSections();
            this.resetPreviewView();
        } else {
            this.manageWidgetsCommService.publishSaveStatus(true);
        }
    }

    findWidget(event: Event): void {
        event.stopImmediatePropagation();
        const dialogRef = this.modalService.open(WidgetFinderComponent, { windowClass: 'finder-popup' });
        dialogRef.componentInstance.widgetAdded.subscribe(async (widget: WidgetSnapshot) => {
            if (this.currentWidgetId !== widget.id) {
                this.clearAll(true);
                await this.router.navigate(['..', widget.id], { relativeTo: this.activatedRoute });
            }
            dialogRef.close();
        });
    }

    async createNewWidget(): Promise<boolean> {
        this.clearAll();
        this.datasetDefinitionSelector?.resetSelector();
        this.isReadOnly = false;
        this.widgetsService.updateIsWidgetGlobal(false);
        return this.router.navigate(['..', 'none'], { relativeTo: this.activatedRoute });
    }

    getUserSelectedConfigs(): UserSelectedConfigs {
        return {
            // Expected to configure one widget at a time
            coreWidgetType: this.getSelectedWidget()?.uid,
            summaryConfig: this.getUserConfigsForWidget('SUMMARY')!,
            visualizationConfigs: this.getVizConfigs(),
            datasetDefinition: this.datasetDefinition!,
        };
    }

    onConfigureComponentAdded(component: ComponentRef<ConfigureComponent>): void {
        component.instance.updatePreview.subscribe(() => this.renderPreview());
        if (this.activeVizTab) {
            this.activeVizTab.configureInfo.componentRef = component;
            if (this.activeVizTab.visualizationType === 'SIMPLE_GRID' && this.selectedWidgets[0].vizTabs?.length as number > 1) {
                // Call only when there are other viz tabs apart from simple grid
                this.populateValueZoneForSimpleGrid(component.instance as ConfigureGridComponent);
            }
        }
        this.renderPreview();
    }

    onFormatComponentAdded(component: ComponentRef<FormatComponent>): void {
        component.instance.updatePreview.subscribe((res) => {
            if (this.activeVizTab?.colorInfo) {
                this.activeVizTab.colorInfo.componentRef.instance.isTableSortDirectionCustom = res.isTableSortDirectionCustom;
            }

            this.renderPreview();
        });
        if (this.activeVizTab) {
            this.activeVizTab.formatInfo.componentRef = component;
        }
        this.renderPreview();
    }

    onColorComponentAdded(component: ComponentRef<ColorChartComponent>): void {
        component.instance.updatePreview.subscribe(() => this.renderPreview());
        if (this.activeVizTab) {
            this.activeVizTab.colorInfo.componentRef = component;
        }
        this.renderPreview();
    }

    onEditBaseWidget(widgetInfo: AppWidgetState): void {
        this.manageWidgetsCommService.setCurrentQueryParams(widgetInfo.widgetFilters);
        // Update the live-demo-widget widgetPrefs
        this.resetPreviewWidget();
        if (this.isPreviewInitialized) {
            this.populateWidgetInfoOnEdit(widgetInfo);
        } else {
            this.subscribeToPreviewInit(() => {
                this.populateWidgetInfoOnEdit(widgetInfo);
            });
        }
    }

    toggleViz(viz: VisualizationListItemConfig): void {
        const vizWidget = this.getVizWidget()!;
        if (vizWidget) {
            this.toggleMultipleViz(viz, vizWidget);
            return;
        }
        if (this.selectedWidgets.length !== 0) {
            this.initializePopup().subscribe({
                next: (action) => {
                    this.showWidgetList = false;

                    if (action === 'confirm') {
                        this.resetPreviewView();
                        this.manageWidgetsCommService.publishClearInfo();
                        this.addToSelectedWidgets('VISUALIZATION');
                        this.toggleMultipleViz(viz, vizWidget);
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        this.onNavTabFocus({ tabIndex: 0, vizTab: this.selectedWidgets[0].vizTabs?.[0]! });
                    }
                },
            });
        } else {
            this.addToSelectedWidgets('VISUALIZATION');
            this.toggleMultipleViz(viz, vizWidget);
        }
    }

    onNavTabFocus(tabData: { tabIndex: number, vizTab: ApplicationTabConfiguration }): void {
        if (!tabData.vizTab || tabData.tabIndex < 0) {
            return;
        }
        const currentVizTab = this.activeVizTab;
        this.activeVizTab = tabData.vizTab;

        if (this.configureTabContainer && this.formatTabContainer) {
            this.changeComponentAttachedState(currentVizTab, false);
            this.configureTabContainer.detach();
            this.formatTabContainer.detach();
            if (this.colorTabContainer) {
                this.colorTabContainer.detach();
            }

            if (this.configureTabs[tabData.vizTab.guid]) {
                this.configureTabContainer.insert(this.configureTabs[tabData.vizTab.guid].hostView);
                this.changeComponentAttachedState(this.activeVizTab, true);
                if (this.formatTabs[tabData.vizTab.guid]) {
                    this.formatTabContainer.insert(this.formatTabs[tabData.vizTab.guid].hostView);
                } else {
                    this.createFormatComponent();
                }
                if (this.colorTabContainer && this.colorTabs[tabData.vizTab.guid]) {
                    this.showColorTab = true;
                    this.colorTabContainer.insert(this.colorTabs[tabData.vizTab.guid].hostView);
                } else {
                    this.createColorComponent();
                }
            } else {
                this.createNavTabConfigureFormatElements();
            }
        } else {
            setTimeout(this.createNavTabConfigureFormatElements.bind(this), 0);
        }

        const { visualizationType } = this.activeVizTab;
        if (this.isGrid(visualizationType)) {
            this.autoAddDynamicColumns = !!this.autoAddDynamicColumnsVisualizations.get(visualizationType);
        } else {
            this.autoAddDynamicColumns = false;
        }

        this.onTabSelected(tabData.tabIndex);
    }

    switchTab(tab: string): void {
        this.selectedTab = tab;
    }

    getNewGuid(): string {
        return Math.random()
            .toString()
            .split('')
            .reduce((result, n) => result + String.fromCharCode(65 + (n === '.' ? 0 : parseInt(n, 10))), 'tab')
            .toLowerCase();
    }

    addGuidOnToggleViz(): void {
        this.selectedWidgets.forEach((widget) => {
            if (!widget.guid) {
                widget.guid = this.getNewGuid();
            }
            if (widget.vizTabs) {
                widget.vizTabs.forEach((vizTab) => {
                    if (!vizTab.guid) {
                        vizTab.guid = this.getNewGuid();
                    }
                });
            }
        });
    }

    onVizSelected(tabIndex = 0): void {
        const vizTabs = this.getVizWidget()?.vizTabs ?? [];
        vizTabs.forEach((tab) => tab.active = false);
        vizTabs[tabIndex].active = true;
        this.renderPreview();
    }

    validateWidgetConfigs(): { name: string, isValid: boolean }[] {
        const selectedWidget = this.getSelectedWidget();
        if (selectedWidget.isViz) {
            this.silentRegisterVizTabs(selectedWidget.vizTabs);
            return selectedWidget.vizTabs?.map((viz) => {
                return {
                    name: viz.name,
                    isValid: this.hasRequiredFields(viz),
                };
            }) ?? [];
        }
        return [{
            name: selectedWidget.name ?? '',
            isValid: this.hasRequiredFields(selectedWidget),
        }];
    }

    getUserConfigsForWidget(uid: string): VisualizationConfigs[] | undefined {
        const selectedWidget = this.selectedWidgets.find((widget) => widget.uid === uid);
        if (!selectedWidget) {
            return;
        }
        switch (uid) {
            case 'VISUALIZATION':
                return this.getVizConfigs();
            default:
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return deepExtend({}, [this.getDataConfigs(selectedWidget), this.getFormatters(selectedWidget)]) as any;
        }
    }

    ngOnDestroy(): void {
        this.configureTabs = {};
        this.formatTabs = {};
        this.colorTabs = {};
        this.changeDetectorRef.detach?.();

        if (this.previewInitSubscription) {
            this.previewInitSubscription.unsubscribe();
        }

        if (this.gridStateSubscription) {
            this.gridStateSubscription.unsubscribe();
        }

        if (this.dashboardModeAndIdSubscription) {
            this.dashboardModeAndIdSubscription.unsubscribe();
        }
    }

    returnToDashboard(): void {
        this.dashboardModeAndIdSubscription = this.currentStateService.dashboardModeAndId$
            .subscribe(async (dashboardModeandId: DashboardModeAndId) => {
                if (dashboardModeandId) {
                    const { mode, id } = dashboardModeandId;
                    await this.router.navigate([`../../../views/${mode ?? 'view'}/${id ? id : 'none'}`], { relativeTo: this.activatedRoute });
                } else {
                    await this.router.navigate(['../../../views/view/none'], { relativeTo: this.activatedRoute });
                }
            });
    }

    onButtonTransitionEnd(event: Event): void {
        event.stopPropagation();
    }

    get showAutoAddDynamicColumnsOption(): boolean {
        return this.isGrid(this.activeVizTab?.visualizationType);
    }

    onAutoAddDynamicColumnsChanged(value: boolean): void {
        this.autoAddDynamicColumnsVisualizations.set(this.activeVizTab?.visualizationType ?? '', value);
        this.autoAddDynamicColumnsChanged.emit(this.autoAddDynamicColumnsVisualizations);
    }

    clearAutoAddDynamicColumnsVisualizations(): void {
        this.autoAddDynamicColumnsVisualizations.clear();
        this.autoAddDynamicColumnsChanged.emit(this.autoAddDynamicColumnsVisualizations);
    }

    isStackedAreaChartVizType(visualizationType: VisualizationType): boolean {
        return visualizationType === 'STACKED_AREA_CHART';
    }

    private addNonVizWidget(widget: ApplicationWidgetConfiguration): void {
        widget.selected = !widget.selected;
        this.addToSelectedWidgets(widget.uid);
        this.updateCoreWidget();
    }

    private onTabSelected(tabIndex = 0): void {
        if (this.getSelectedWidget().isViz) {
            this.onVizSelected(tabIndex);
        }
    }

    private toggleMultipleViz(viz: VisualizationListItemConfig, vizWidget: ApplicationWidgetConfiguration): void {
        viz.selected = !viz.selected;
        if (viz.selected) {
            this.addToSelectedVizs(viz);
        } else if (vizWidget) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const index = vizWidget.vizTabs!.findIndex((x: any) => x.cssId === viz.cssId);
            this.removeSelectedTabDeps(vizWidget.vizTabs![index]);
            vizWidget.vizTabs!.splice(index, 1);
        }
        this.selectedWidgets = [...this.selectedWidgets];
        this.updateCoreWidget();
    }

    private changeComponentAttachedState(selectedTab: ApplicationTabConfiguration | undefined, state: boolean): void {
        if (!selectedTab || !this.configureTabs[selectedTab.guid]?.instance.currentComponent) {
            return;
        }
        this.configureTabs[selectedTab.guid].instance.currentComponent!.instance.isAttached = state;
        this.configureTabs[selectedTab.guid].instance.currentComponent!.changeDetectorRef.detectChanges();
    }

    private populateValueZoneForSimpleGrid(simpleGridComponent: ConfigureGridComponent): void {
        // When widget gets loaded from widget finder then no need to apply the logic.
        // Logic is getting performed only when user adds a simple grid manually.
        if ((simpleGridComponent.dataConfigs as ConfigItem[]).length !== 0) {
            return;
        }
        const simpleGridDataConfigs = this.getUniqueFieldsFromAllZonesOfOtherTabs();
        simpleGridDataConfigs.forEach((dataConfig, index) => {
            if (typeof dataConfig !== 'function') {
                simpleGridComponent.updateConfigList({
                    displayName: dataConfig.label,
                    value: dataConfig.value,
                    datatype: dataConfig.datatype,
                    displayType: dataConfig.displayType,
                    name: '',
                }, index);
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getUniqueFieldsFromAllZonesOfOtherTabs(): any[] {
        const simpleGridVizTabIndex = this.selectedWidgets[0].vizTabs?.findIndex((tab) => tab.visualizationType === 'SIMPLE_GRID') ?? -1;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let simpleGridDataConfigs: any[] = [];
        this.selectedWidgets[0].vizTabs?.forEach((tab, index) => {
            // Loop through all the tabs prior to simple grid.
            if (index >= simpleGridVizTabIndex || tab.visualizationType === 'ADVANCED_GRID') {
                return;
            }
            const componentRef = tab.configureInfo.componentRef;
            const dataConfigs = componentRef ? componentRef.instance.dataConfigs : tab.configureInfo.componentData.inputs.configs;
            for (const key in dataConfigs) {
                // To not inherit the fields of tool tip zone.
                if (key !== 'tooltips') {
                    const values = dataConfigs[key];
                    simpleGridDataConfigs = simpleGridDataConfigs.concat(values);
                }
            }
        });
        return getUniqueBy(simpleGridDataConfigs);
    }

    private createNavTabConfigureFormatElements(): void {
        this.createConfigureComponent();
        this.createFormatComponent();
        this.createColorComponent();
    }

    private createConfigureComponent(): void {
        if (!this.configureTabContainer) {
            return console.error('cannot createConfigureComponent without a configureTabContainer');
        }

        const configureFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicComponent);
        const configureComponentRef = this.configureTabContainer.createComponent(configureFactory);
        configureComponentRef.instance.componentAdded.subscribe((component) => {
            // datasetId, configs and visualizationType should come off of this inputs hash now
            const inputs = this.activeVizTab?.configureInfo.componentData.inputs;
            configureComponentRef.instance.currentComponent!.instance.onInit(
                inputs.datasetId,
                MANAGE_WIDGET_ID,
                inputs.configs,
                inputs.visualizationType);

            return this.onConfigureComponentAdded(component as ComponentRef<ConfigureComponent>);
        });
        configureComponentRef.instance.isReadOnly = this.isReadOnly;
        configureComponentRef.instance.componentData = this.activeVizTab?.configureInfo.componentData;
        this.configureTabs[this.activeVizTab?.guid ?? ''] = configureComponentRef;
    }

    private createFormatComponent(): void {
        if (!this.formatTabContainer) {
            return console.error('cannot createFormatComponent without a formatTabContainer');
        }

        const formatFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicComponent);
        const formatComponentRef = this.formatTabContainer.createComponent(formatFactory);
        formatComponentRef.instance.componentAdded.subscribe((component) => {
            this.onFormatComponentAdded(component as ComponentRef<FormatComponent>);
        });
        formatComponentRef.instance.isReadOnly = this.isReadOnly;
        formatComponentRef.instance.componentData = this.activeVizTab?.formatInfo.componentData;
        this.formatTabs[this.activeVizTab?.guid ?? ''] = formatComponentRef;
    }

    private createColorComponent(): void {
        if (this.activeVizTab?.colorInfo) {
            if (!this.colorTabContainer) {
                return console.error('cannot createFormatComponent without a createColorComponent');
            }

            this.showColorTab = true;
            const colorFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicComponent);
            const colorComponentRef = this.colorTabContainer.createComponent(colorFactory);
            colorComponentRef.instance.componentAdded.subscribe((component) =>
                this.onColorComponentAdded(component as ComponentRef<ColorChartComponent>),
            );
            colorComponentRef.instance.isReadOnly = this.isReadOnly;

            colorComponentRef.instance.isTableSortDirectionCustom =
                this.activeVizTab?.formatInfo?.componentData?.inputs?.appliedFormatters?.tableSortDirection === 'CUSTOM';

            colorComponentRef.instance.componentData = this.activeVizTab.colorInfo.componentData;
            this.colorTabs[this.activeVizTab.guid] = colorComponentRef;
        } else {
            this.showColorTab = false;
            if (this.selectedTab !== 'format') {
                this.selectedTab = 'configure';
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private silentRegisterVizTabs(vizTabs?: any[]): void {
        if (!vizTabs) {
            return;
        }
        const activeTab = vizTabs.find((t) => t.active);

        vizTabs.filter((vizTab) => !this.configureTabs[vizTab.guid]).forEach((vizTab) => {
            this.onNavTabFocus({ tabIndex: vizTabs.indexOf(vizTab), vizTab });
        });

        this.onNavTabFocus({ tabIndex: vizTabs.indexOf(activeTab), vizTab: activeTab });
    }

    private removeAllVizSelections(): void {
        const supportedWidget = this.supportedWidgets.find((supWidget) => supWidget.isViz);
        if (supportedWidget?.vizConfigs) {
            supportedWidget.vizConfigs.forEach((viz) => {
                viz.selected = false;
            });
        }
    }

    private removeSelections(widgetUid: string): void {
        this.selectedWidgets = [];
        if (this.supportedWidgets.length === 0) {
            return;
        }
        if (widgetUid === 'VISUALIZATION') {
            this.supportedWidgets.forEach((supWidget) => {
                if (!supWidget.isViz) {
                    supWidget.selected = false;
                }
            });
        } else {
            this.removeAllVizSelections();
        }
    }

    private getVizWidget(): ApplicationWidgetConfiguration | undefined {
        return this.selectedWidgets.find((widget) => widget.isViz);
    }

    private subscribeToPreviewInit(callback?: () => void): void {
        if (this.previewInitSubscription) {
            this.previewInitSubscription.unsubscribe();
        }
        this.previewInitSubscription = this.manageWidgetsCommService.isPreviewInitializedObs.subscribe(() => {
            this.isPreviewInitialized = true;
            if (callback) {
                callback();
            }
        });
    }

    private populateWidgetInfoOnEdit(widgetInfo: AppWidgetState): void {
        // Called after widget object is loaded on the edit widget page
        this.updatePreviewOnEditWidget(widgetInfo);
        this.updateInfo(widgetInfo);
        this.addGuidOnToggleViz();
        this.manageWidgetsCommService.publishUpdateInfo(widgetInfo);
        this.inWidgetEditMode = true;
        widgetInfo.visualizationConfigs.forEach((config) => {
            if (this.isGrid(config.visualizationType)) {
                this.autoAddDynamicColumnsVisualizations.set(config.visualizationType, !!config.autoAddDynamicColumns);
            }
        });
        if (this.autoAddDynamicColumnsVisualizations.size) {
            this.autoAddDynamicColumnsChanged.emit(this.autoAddDynamicColumnsVisualizations);
        }
    }

    private updateInfo(widgetInfo: AppWidgetState): void {
        this.clearModel();
        const widgetDatasetDefinition = widgetInfo.datasetDefinition;
        if (!widgetInfo.namedQueryId) {
            this.datasetDefinition = widgetDatasetDefinition;
        }

        this.changeDetectorRef.detectChanges();
        this.datasetDefinitionChanged.emit(this.datasetDefinition);
        this.setSupportedWidgets();
        this.addToSelectedWidgets(widgetInfo.coreWidgetType ?? '', widgetInfo);
        widgetInfo.visualizationConfigs.forEach((item) => {
            this.addToSelectedVizs(item);
        });
        this.setWidgetsAndSendMessage();
        this.updateLiveWidgetView();

        // The named-query types should be extended when new investor types are added
        const investorQueryTypes = ['InvestorsEconomicDistribution', 'InvestorsActivity'];
        const isInvestor = isNamedQuery(this.datasetDefinition) ?
            investorQueryTypes.includes(this.datasetDefinition.type.name) :
            this.datasetDefinition?.dataType === 'Investor';

        if (isInvestor) {
            const funds = widgetInfo.widgetFilters?.funds?.map((fund) => {
                return { code: fund.investorFundCode ?? fund.fundId };
            }) ?? [];
            const dsdIds = [widgetInfo.datasetDefinition?.id ?? 0];
            this.fuzzyDateService.pushMostRecentFuzzyDatesForInvestorDataset(funds, dsdIds).subscribe();
        }
    }

    private clearAll(isWidgetFromFinder?: boolean): void {
        this.datasetDefinition = undefined;
        this.datasetDefinitionChanged.emit(this.datasetDefinition);
        this.clearModel();
        this.manageWidgetsCommService.publishClearInfo(isWidgetFromFinder);
        this.resetPreviewWidget();
        this.clearAutoAddDynamicColumnsVisualizations();
    }

    private clearModel(): void {
        this.supportedWidgets = [];
        this.selectedWidgets = [];
        this.clearAllVizTabSections();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(this.changeDetectorRef as any).destroyed) {
            this.changeDetectorRef.detectChanges();
        }
    }

    private renderPreview(): void {
        const selectedWidget: (ApplicationTabConfiguration | ApplicationWidgetConfiguration) = this.getSelectedWidget();
        if (!selectedWidget) {
            return;
        }

        let activeTab: (ApplicationTabConfiguration | ApplicationWidgetConfiguration) = selectedWidget;
        let configs: (VisualizationConfigs | VisualizationConfigs[]) = this.getUserConfigsForWidget(selectedWidget.uid) ?? [];
        if (selectedWidget.isViz) {
            const vizTabs = this.getVizWidget()?.vizTabs ?? [];
            const activeIndex = vizTabs.findIndex((tab) => tab.active);
            activeTab = vizTabs[activeIndex];
            configs = configs[activeIndex];
            this.manageWidgetsCommService.publishVizConfig(configs);
        } else if (selectedWidget.uid === 'SUMMARY') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.manageWidgetsCommService.publishVizConfig({ visualizationType: selectedWidget.uid } as any);
        }

        if (!activeTab.configureInfo.componentRef ||
            !activeTab.formatInfo.componentRef ||
            (activeTab.colorInfo && !activeTab.colorInfo.componentRef)
        ) {
            return;
        }

        if (this.hasRequiredFields(activeTab)) {
            if (!this.inWidgetEditMode) {
                this.manageWidgetsCommService.publishSaveStatus(true);
            }

            this.inWidgetEditMode = false;

            this.managerService.sendMessageToAllWidgetsOnWorkspace(0, {
                action: 'RENDER_VIEW',
                component: activeTab.component,
                preferences: [deepClone(configs)],
                coreWidgetType: selectedWidget.uid,
            });
        } else {
            this.managerService.sendMessageToAllWidgetsOnWorkspace(0, { action: 'RESET_VIEW' });
        }
    }

    private getSelectedWidget(): ApplicationWidgetConfiguration {
        /* For maintaining at a common place - right now only one widget
         * can be configured at a time; In future if it changes, logic to get active widget should be at one place
         */
        return this.selectedWidgets[0];
    }

    private addToSelectedWidgets(widgetUid: string, widgetInfo?: AppWidgetState): void {
        this.removeSelections(widgetUid);
        this.clearAllVizTabSections();
        if (!widgetInfo) {
            this.updateLiveWidgetView();
        }
        const widget = this.supportedWidgets.length ?
            this.supportedWidgets.find((item) => item.uid === widgetUid) : this.getWidgetDefaultConfig(widgetUid);
        let dynamicCompInput = {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            configure: { configs: undefined as any },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            format: { appliedFormatters: undefined as any },
            color: { id: widget?.uid },
        };
        if (widgetInfo) {
            if (widgetUid === 'SUMMARY') {
                dynamicCompInput = {
                    configure: { configs: widgetInfo.summaryConfig },
                    format: { appliedFormatters: widgetInfo.summaryConfig },
                    color: { id: widget?.uid },
                };
            }
        }
        this.selectedWidgets.push(
            deepExtend(
                {},
                [widget, { vizTabs: [] }, this.getDynamicComponentsInfo(widget?.uid ?? '', dynamicCompInput)],
            ) as ApplicationWidgetConfiguration,
        );
        if (this.selectedWidgets.length === 1) {
            this.manageWidgetsCommService.publishSaveStatus(true);
        }
    }

    private addToSelectedVizs(viz: VisualizationConfigs | VisualizationListItemConfig): void {
        const vizConfig = deepExtend({}, [{
            cssId: visualizationDescriptorMap[viz.visualizationType].id,
            component: visualizationDescriptorMap[viz.visualizationType].component,
            name: visualizationDescriptorMap[viz.visualizationType].name,
            cssClass: visualizationDescriptorMap[viz.visualizationType].cssClass,
            visualizationType: viz.visualizationType,
        }, this.getDynamicComponentsInfo(viz.visualizationType, {
            configure: {
                configs: viz instanceof VisualizationConfigs ? viz.configs : undefined,
                visualizationType: viz.visualizationType,
            },
            format: {
                appliedFormatters: viz,
            },
            color: {
                configs: viz instanceof VisualizationConfigs ? viz.configs : undefined,
                visualizationType: viz.visualizationType,
            },
        })]) as ApplicationTabConfiguration;
        const vizTabs = this.getVizWidget()?.vizTabs ?? [];
        vizTabs.push(vizConfig);
        if (vizTabs.length === 1) {
            this.manageWidgetsCommService.publishSaveStatus(true);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getDynamicComponentsInfo(id: string, inputs: { [key: string]: any }): any {
        if (this.colorTabViz.indexOf(id) !== -1) {
            return deepExtend(
                {},
                [
                    this.getDefaultDynamicComponentsInfo(id, inputs),
                    {
                        colorInfo: {
                            componentData: {
                                component: VizTabsConfiguration.getColorTabComponent(),
                                inputs: inputs.color,
                            },
                            componentRef: null,
                        },
                    },
                ]);
        }
        return deepExtend({}, [this.getDefaultDynamicComponentsInfo(id, inputs)]);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getDefaultDynamicComponentsInfo(id: string, inputs: { [key: string]: any }): { configureInfo: any, formatInfo: any } {
        return {
            configureInfo: {
                configs: inputs.configure.configs,
                datasetDefinitionId: this.datasetDefinition?.id,
                visualizationType: null,
                componentData: {
                    component: VizTabsConfiguration.getConfigureTabComponent(id),
                    inputs: deepExtend({}, [{ datasetId: this.datasetDefinition?.id }, inputs.configure]),
                },
                componentRef: null,
            },
            formatInfo: {
                componentData: {
                    component: VizTabsConfiguration.getFormatTabComponent(id),
                    inputs: inputs.format,
                },
                componentRef: null,
            },
        };
    }
    private getVizConfigs(): VisualizationConfigs[] {
        const vizWidget = this.getVizWidget();
        return vizWidget?.vizTabs?.map((viz) => {
            const vizConfigs = {
                id: null,
                configs: this.getDataConfigs(viz),
                visualizationType: viz.visualizationType,
            };
            return this.colorTabViz.indexOf(viz.visualizationType) !== -1 ?
                new VisualizationConfigs(Object.assign(vizConfigs, this.getFormatters(viz), this.getColorConfig(viz))) :
                new VisualizationConfigs(Object.assign(vizConfigs, this.getFormatters(viz)));
        }) ?? [];
    }

    private getFormatters(viz: ApplicationTabConfiguration | ApplicationWidgetConfiguration): VisualizationConfigs {
        return viz.formatInfo.componentRef ?
            viz.formatInfo.componentRef.instance.getSelectedConfigs(this.getDataConfigs(viz)) : {};
    }

    private getColorConfig(viz: ApplicationTabConfiguration | ApplicationWidgetConfiguration): VisualizationConfigs {
        return viz.colorInfo.componentRef ?
            viz.colorInfo.componentRef.instance.getSelectedConfigs(this.getDataConfigs(viz)) : {};
    }

    private getDataConfigs(viz: ApplicationTabConfiguration | ApplicationWidgetConfiguration): Partial<VizConfigs> {
        return viz.configureInfo.componentRef ?
            viz.configureInfo.componentRef.instance.getSelectedConfigs() : {};
    }

    private updateLiveWidgetView(): void {
        const selectedWidget = this.getSelectedWidget();
        if (!selectedWidget) {
            this.resetPreviewView();
            return;
        }
        if (selectedWidget.isViz) {
            const vizTabs = this.getVizWidget()?.vizTabs ?? [];
            if (!vizTabs.length) {
                this.resetPreviewView();
            } else if (!vizTabs.some((tab) => tab.active)) {
                this.onVizSelected();
            }
        } else {
            this.renderPreview();
        }
    }

    private updatePreviewOnEditWidget(widgetInfo: AppWidgetState): void {
        this.managerService.sendMessageToAllWidgetsOnWorkspace(0, { action: 'EDIT_WIDGET', widgetInfo });
    }

    private resetPreviewWidget(): void {
        this.managerService.sendMessageToAllWidgetsOnWorkspace(0, { action: WIDGET_LIFECYCLE_EVENT.RESET_WIDGET });
        this.manageWidgetsCommService.publishSaveStatus(false);
    }

    private resetPreviewView(): void {
        this.managerService.sendMessageToAllWidgetsOnWorkspace(0, { action: 'RESET_VIEW' });
        this.manageWidgetsCommService.publishSaveStatus(false);
    }

    private sendDatasetUpdateMessage(): void {
        this.managerService.sendMessageToAllWidgetsOnWorkspace(0,
            { action: 'UPDATE_DATASET', datasetDefinition: this.datasetDefinition });
    }

    private setWidgetsAndSendMessage(): void {
        this.setSupportedWidgets();
        this.sendDatasetUpdateMessage();
    }

    private setSupportedWidgets(): void {
        this.supportedWidgets = [];
        const widgetTypes: { [key: string]: VisualizationType[] } = this.getVisualizations();

        this.supportedWidgets[0] = this.getWidgetDefaultConfig('VISUALIZATION', widgetTypes.VISUALIZATION)!;
        this.supportedWidgets[1] = this.getWidgetDefaultConfig('SUMMARY', widgetTypes.SUMMARY)!;
    }

    private getWidgetDefaultConfig(widgetUid: string, vizTypes: VisualizationType[] = []): ApplicationWidgetConfiguration | undefined {
        const thisWidget = this.dataService.getWidgetsForDashboard().find((item) => item.uid === widgetUid);
        let widget = deepClone(thisWidget);
        const selectedWidget = this.getSelectedWidget();
        if (widget) {
            if (widget.isViz) {
                widget = deepExtend({}, [widget, {
                    vizConfigs: vizTypes.map((vizType) => ({
                        cssId: visualizationDescriptorMap[vizType].id,
                        name: visualizationDescriptorMap[vizType].name,
                        cssClass: visualizationDescriptorMap[vizType].cssClass,
                        visualizationType: vizType,
                        selected: selectedWidget ? selectedWidget.vizTabs?.some((chart) => chart.visualizationType === vizType) : false,
                    })),
                }]) as ApplicationWidgetConfiguration;
            } else {
                widget.selected = selectedWidget && selectedWidget.uid === widgetUid;
            }
            return widget;
        }

        return;
    }

    private getVisualizations(): { [key: string]: VisualizationType[] } {
        const visualizations: { [key: string]: VisualizationType[] } = {};
        visualizations.SUMMARY = ['SUMMARY'];
        visualizations.VISUALIZATION = [
            'SIMPLE_GRID',
            'ADVANCED_GRID',
            'LINE_CHART',
            'STACKED_AREA_CHART',
            'PIE_CHART',
            'DONUT_CHART',
            'VERTICAL_BAR_CHART',
            'HORIZONTAL_BAR_CHART',
            'VERTICAL_STACKED_BAR_CHART',
            'HORIZONTAL_STACKED_BAR_CHART',
            'VERTICAL_MIRRORED_BAR_CHART',
        ];
        return visualizations;
    }

    private hasRequiredFields(activeViz: ApplicationTabConfiguration | ApplicationWidgetConfiguration): boolean {
        try {
            return activeViz.configureInfo.componentRef.instance.hasRequiredFields() &&
                activeViz.formatInfo.componentRef.instance.hasValidFormatters();
        } catch (e) {
            return true;
        }
    }

    private whichTransitionEvent(leftnav: HTMLElement): string {
        const transitions: Record<string, string> = {
            transition: 'transitionend',
            /* eslint-disable @typescript-eslint/naming-convention */
            OTransition: 'oTransitionEnd',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
            /* eslint-enable @typescript-eslint/naming-convention */
        };
        for (const t in transitions) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (leftnav.style[t as any] !== undefined) {
                return transitions[t];
            }
        }

        return '';
    }

    private destroyComponents(viz: ApplicationTabConfiguration): void {
        if (viz.configureInfo.componentRef) {
            viz.configureInfo.componentRef.destroy();
        }
        if (viz.formatInfo.componentRef) {
            viz.formatInfo.componentRef.destroy();
        }
        if (viz.colorInfo?.componentRef) {
            viz.colorInfo.componentRef.destroy();
        }
    }

    private updateCoreWidget(): void {
        this.updateLiveWidgetView();
        this.addGuidOnToggleViz();
        this.validateVizTabsAvailability();
    }

    private initializePopup(): Observable<ModalAction | undefined> {
        const message = 'The configurations will be reset. Are you sure you want to proceed?';
        const confirmButtonText = 'Ok';
        const denyButtonText = 'Cancel';
        const confirmDialogOptions = { message, confirmButtonText, denyButtonText };
        return this.confirmationService.showConfirmationPopup(confirmDialogOptions);
    }

    private updateConfigurations(gridEvent: GridEvent): void {
        if (!this.activeVizTab) {
            return console.error('cannot updateConfigurations without a activeVizTab');
        }

        switch (gridEvent.event) {
            case 'COLUMNS':
                this.activeVizTab.configureInfo.componentRef.instance.updateGridColumnState(gridEvent.gridState);
                this.activeVizTab.formatInfo.componentRef.instance.updateGridPivotMode(gridEvent.gridState.pivotMode);
                break;
            case 'FILTER':
                this.activeVizTab.configureInfo.componentRef.instance.updateGridColumnsFilter(gridEvent.configs);
                break;
            case 'SORT':
                this.activeVizTab.configureInfo.componentRef.instance.updateGridColumnsSort(gridEvent.configs);
                this.activeVizTab.formatInfo.componentRef.instance.updateGroupSort(gridEvent.gridState.sortState);
                break;
            case 'PIVOT':
                this.activeVizTab.formatInfo.componentRef.instance.updateGridPivotMode(gridEvent.gridState.pivotMode);
                break;
            case 'PINNED':
                this.activeVizTab.configureInfo.componentRef.instance.updateGridColumnsPinned(gridEvent.configs);
        }
        if (gridEvent.triggeredByUser) {
            this.manageWidgetsCommService.publishSaveStatus(true);
        }
    }

    private isGrid(visualizationType?: VisualizationType): boolean {
        return visualizationType === 'ADVANCED_GRID' || visualizationType === 'SIMPLE_GRID';
    }
}
