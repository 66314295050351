import { Injectable } from '@angular/core';
import { CellColors, LabelValuePair } from '@ddv/models';
import { Theme } from '@hs/ui-core-presentation';

import { ColorInfo } from '../models/color-info';
import { outdatedBarColors } from './color-sets/bars';
import { darkBaseColors, lightBaseColors } from './color-sets/base';
import { darkCellAndFontColors, lightCellAndFontColors } from './color-sets/cell-and-fonts';
import { outdatedCellColors } from './color-sets/cells';
import {
    baseColorConfig,
    darkSolidColorConfig,
    lightSolidColorConfig,
    lineColorConfig,
    mirroredBarAndLineColorConfig,
    monochromeColorConfig,
    multiColorConfig,
} from './color-sets/configs';
import { darkThemeFontColors, lightThemeFontColors, outdatedFontColors } from './color-sets/fonts';
import { newLineChartColorValues } from './color-sets/lines';
import { darkCustomColorMap, lightCustomColorMap, outdatedCustomColorMap } from './color-sets/maps';
import { MultiColorNames, SolidColorNames } from './color-sets/names';

@Injectable()
export class ColorMetadataService {
    // cant use the proper type for columnCondition because its an enum and enums are not allowed
    // to be index by strings when using strict type checks
    static mapOutdatedColors(theme: Theme, columnCondition: any /* CellColors */): void { // eslint-disable-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
        const oldFontColors = ColorMetadataService.getOutdatedFontColors();
        const newFontColors = ColorMetadataService.getFontColors(theme);
        const oldCellColors = ColorMetadataService.getOutdatedCellColors();
        const newColors = ColorMetadataService.getColors(theme);
        const oldBarColors = ColorMetadataService.getOutdatedBarColors();

        const getOutdatedColor = (colors: LabelValuePair<string>[], term: string): LabelValuePair<string> | undefined => {
            return colors.find((c) => c.value === columnCondition[term]);
        };

        const getNewColorValue = (colors: LabelValuePair<string>[], mapsTo: string): string => {
            return colors.find((c) => c.label === mapsTo)?.value ?? '';
        };

        const oldFontColor = getOutdatedColor(oldFontColors, 'fontColor');
        if (oldFontColor?.mapsTo) {
            columnCondition.fontColor = getNewColorValue(newFontColors, oldFontColor.mapsTo);
        }

        const oldCellColor = getOutdatedColor(oldCellColors, 'cellColor');
        if (oldCellColor?.mapsTo) {
            columnCondition.cellColor = getNewColorValue(newColors, oldCellColor.mapsTo);
        }

        const oldBarColor = getOutdatedColor(oldBarColors, 'barColor');
        if (oldBarColor?.mapsTo) {
            columnCondition.barColor = getNewColorValue(newColors, oldBarColor.mapsTo);
        }
    }

    static mapThemeColors(currentTheme: Theme, columnCondition: CellColors): void {
        ColorMetadataService.mapColors(currentTheme, columnCondition);
        ColorMetadataService.mapColors(currentTheme, columnCondition, 'barColor');
    }

    static mapOutdatedLineChartColorType(): string {
        return 'SOLID';
    }

    static mapOutdatedLineChartColorName(): string {
        return 'Line Chart Color Fill';
    }

    static mapOutdatedLineChartValueColorName(colorName: string, index: number): string {
        return newLineChartColorValues.indexOf(colorName) === -1 ? (index === 0 ? '#26A9E0' : '#A4C439') : colorName;
    }

    static isLineChartConfigCustomStyleOutdated(colorName: string): boolean {
        return newLineChartColorValues.indexOf(colorName) === -1;
    }

    static mapOutdatedSolidColorName(colorName: string): string {
        switch (colorName) {
            case SolidColorNames.LIGHT_GREEN_FILL:
                return SolidColorNames.GREEN_FILL;
            case SolidColorNames.LIGHT_YELLOW_FILL:
                return SolidColorNames.YELLOW_FILL;
            case SolidColorNames.LIGHT_ORANGE_FILL:
                return SolidColorNames.ORANGE_FILL;
            case SolidColorNames.DARK_GRAY_FILL_WRONG:
                return SolidColorNames.DARK_GRAY_FILL;
            default:
                return colorName;
        }
    }

    static mapOutdatedMultiColorName(colorName: string): string {
        switch (colorName) {
            case MultiColorNames.GROUP_ONE:
            case MultiColorNames.GROUP_TWO:
            case MultiColorNames.GROUP_THREE:
                return MultiColorNames.DIVERSE_GROUP;
            default:
                return colorName;
        }
    }

    static mapOutdatedCustomColorValue(colorValue: string): string {
        return outdatedCustomColorMap.get(colorValue) ?? colorValue;
    }

    static mapThemeCustomColorValue(colorValue: string, theme: Theme): string {
        if (theme === Theme.light) {
            return lightCustomColorMap.get(colorValue) ?? colorValue;
        }

        return darkCustomColorMap.get(colorValue) ?? colorValue;
    }

    static mapColors(currentTheme: Theme, columnCondition: CellColors, term?: string): void {
        let colorsLightTheme: LabelValuePair<string>[] | CellColors[];
        let colorsDarkTheme: LabelValuePair<string>[] | CellColors[];
        if (term === 'barColor') {
            colorsLightTheme = ColorMetadataService.getColors(Theme.light);
            colorsDarkTheme = ColorMetadataService.getColors(Theme.dark);
            const getColor = (colors: LabelValuePair<string>[]): LabelValuePair<string> | undefined => {
                return colors.find((color) => color.value === columnCondition[term]);
            };
            const colorLightTheme = getColor(colorsLightTheme);
            const colorDarkTheme = getColor(colorsDarkTheme);
            if (currentTheme === Theme.light && !colorLightTheme && !!colorDarkTheme) {
                const correspondingColor = colorsLightTheme.find((color) => color.label === colorDarkTheme.label);
                columnCondition[term] = correspondingColor?.value;
            }
            if (currentTheme === Theme.dark && !colorDarkTheme && !!colorLightTheme) {
                const correspondingColor = colorsDarkTheme.find((color) => color.label === colorLightTheme.label);
                columnCondition[term] = correspondingColor?.value;
            }
        } else {
            colorsLightTheme = ColorMetadataService.getCellAndFontColors(Theme.light);
            colorsDarkTheme = ColorMetadataService.getCellAndFontColors(Theme.dark);
            const getCellAndFontColorIndex = (colors: CellColors[]): number =>
                colors.findIndex((color) => color.cellColor.toLowerCase() === columnCondition.cellColor.toLowerCase() &&
                    color.fontColor.toLowerCase() === columnCondition.fontColor.toLowerCase());
            const colorLightThemeIndex = getCellAndFontColorIndex(colorsLightTheme);
            const colorDarkThemeIndex = getCellAndFontColorIndex(colorsDarkTheme);
            if (currentTheme === Theme.light && colorDarkThemeIndex !== -1) {
                columnCondition.fontColor = colorsLightTheme[colorDarkThemeIndex].fontColor;
                columnCondition.cellColor = colorsLightTheme[colorDarkThemeIndex].cellColor;
            }
            if (currentTheme === Theme.dark && colorLightThemeIndex !== -1) {
                columnCondition.fontColor = colorsDarkTheme[colorLightThemeIndex].fontColor;
                columnCondition.cellColor = colorsDarkTheme[colorLightThemeIndex].cellColor;
            }
        }
    }

    static normalizeStackedAreaTransparentColorName(colorName: string): string {
        return `${colorName.substring(0, colorName.indexOf(' Transparent'))}`;
    }

    static makeStackedAreaColorsSemiTransparent(values: string[]): string[] {
        return values.map((value) => ColorMetadataService.makeColorSemiTransparent(value));
    }

    static makeColorSemiTransparent(value: string): string {
        const color = value.substring(1).split('');

        const red = parseInt(`${color[0]}${color[1]}`, 16);
        const green = parseInt(`${color[2]}${color[3]}`, 16);
        const blue = parseInt(`${color[4]}${color[5]}`, 16);
        const alpha = 0.6;

        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    }

    static getOutdatedFontColors(): LabelValuePair<string>[] {
        return [...outdatedFontColors];
    }

    static getOutdatedCellColors(): LabelValuePair<string>[] {
        return [...outdatedCellColors];
    }

    static getOutdatedBarColors(): LabelValuePair<string>[] {
        return [...outdatedBarColors];
    }

    static getFontColors(theme: Theme): LabelValuePair<string>[] {
        if (theme === Theme.light) {
            return [...lightThemeFontColors];
        }

        return [...darkThemeFontColors];
    }

    static getColors(theme: Theme, isBarColor = false): LabelValuePair<string>[] {
        const baseColors = theme === Theme.light ? [...lightBaseColors] : [...darkBaseColors];

        if (!isBarColor) {
            baseColors.push({ label: 'N/A', value: '' });
        }

        return baseColors;
    }

    static getCellAndFontColors(theme: Theme): CellColors[] {
        if (theme === Theme.light) {
            return [...lightCellAndFontColors];
        }

        return [...darkCellAndFontColors];
    }

    getColorConfig(): ColorInfo[] {
        return [...baseColorConfig];
    }

    getMonochromaticColorConfig(): ColorInfo[] {
        return [...monochromeColorConfig];
    }

    getSolidColorConfig(theme: Theme): ColorInfo[] {
        if (theme === Theme.light) {
            return [...lightSolidColorConfig];
        }

        return [...darkSolidColorConfig];
    }

    getMultiColorConfig(): ColorInfo[] {
        return [...multiColorConfig];
    }

    getMirroredBarAndLineChartColorConfig(): ColorInfo[] {
        return [...mirroredBarAndLineColorConfig];
    }

    getLineChartColorOptions(): ColorInfo[] {
        return [...lineColorConfig];
    }
}
