<div
    class="dropdown-wrapper {{wrapperCssClass}}"
    appMousedownOutside
    (mousedownOutside)="showDropdown = false"
    (click)="showDropdown=!showDropdown"
    [ngClass]="{'disabled': disabled, 'readonly': readonly}">

    <span class="title" [ngClass]="getClass()">{{selected?.text}}</span>

    <span class="icon-arrow-down"></span>

    <ul #list class="dropdown-options" *ngIf="showDropdown && !readonly">
        <li
                *ngFor="let option of options"
                [ngClass]="[option.css ? option.css : '', option.key === selected?.key ? 'dropdown-selected' : '']"
                (click)="chooseOption(option)">

            {{option?.text}}
        </li>
    </ul>
</div>
