import { DdvDate } from './ddv-date';
import { FuzzyDate } from './fuzzy-date';

export class FuzzyDates {
    to: FuzzyDate[];
    from: FuzzyDate[];

    constructor(to?: FuzzyDate[], from?: FuzzyDate[]) {
        this.to = to ?? [];
        this.from = from ?? [];
    }

    findToDate(name?: string): FuzzyDate | undefined {
        return this.to.find((fuzzyDate) => fuzzyDate.name.toLocaleUpperCase() === name?.toLocaleUpperCase());
    }

    findFromDate(name?: string): FuzzyDate | undefined {
        return this.from.find((fuzzyDate) => fuzzyDate.name.toLocaleUpperCase() === name?.toLocaleUpperCase());
    }
}

export function resolvePotentialFuzzyDate(
    fuzzyDates: FuzzyDates | undefined,
    dateList: 'to' | 'from',
    date: string | undefined,
): string {
    if (!date) {
        return '';
    }

    if (DdvDate.isStringValidDate(date)) {
        return date;
    }

    const fuzzyDate = fuzzyDates?.[dateList].find((fd) => fd.name.toLocaleUpperCase() === date?.toLocaleUpperCase());
    return fuzzyDate?.formattedString ?? '';
}
