import { ColDef, IRowNode, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { ConfigItem, ROOT_NODE_ID, RankOrder } from '@ddv/models';

import { ColumnTransformService } from './column-transform.service';

const rankSuffix = '__rank';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rankAlgorithms: Record<RankOrder, any> = {
    POSITIVE: { // Rank positive only
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        comparison: (a: any, b: any): number => b.data - a.data,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRankVal: (value: any, i: number): any => value > 0 ? i + 1 : null,
    },
    HIGH: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        comparison: (a: any, b: any): number => b.data - a.data,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRankVal: (value: any, i: number): any => i + 1,
    },
    NEGATIVE: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        comparison: (a: any, b: any): number => a.data - b.data,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRankVal: (value: any, i: number): any => value < 0 ? i + 1 : null,
    },
    LOW: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        comparison: (a: any, b: any): number => a.data - b.data,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getRankVal: (_: any, i: number): any => i + 1,
    },
};

@Injectable()
export class ColumnRankingService {
    constructor(private readonly transformService: ColumnTransformService) {}

    // External use utility to get the field name for processed data for any column
    getFieldName(columnConfig: ConfigItem): string {
        if (columnConfig.isRanked) {
            return this.getRankedFieldName(columnConfig);
        }
        return this.getSourceFieldName(columnConfig);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rankData(gridData: any[], columnConfigs: ConfigItem[]): void {
        columnConfigs.forEach((columnConfig: ConfigItem) => {
            if (columnConfig.isRanked) {
                // Calculate rank data for this config
                gridData.map((rowData, i) =>
                    ({ data: rowData[this.getSourceFieldName(columnConfig)], index: i }))
                    // Sort collected data values according to the selected comparison
                    .sort(rankAlgorithms[columnConfig.rankOrder!].comparison)
                    // Put the calculated ranks into the data under "<fieldName>__rank"
                    .forEach((ranked, i) => {
                        gridData[ranked.index][this.getRankedFieldName(columnConfig)] =
                            rankAlgorithms[columnConfig.rankOrder!].getRankVal(ranked.data, i);
                    });
            }
        });
    }

    getRankedColumnIds(columnConfigs: ConfigItem[]): string[] {
        return columnConfigs.filter((columnConfig) => columnConfig.isRanked).map((columnConfig) => columnConfig.colId);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    getFormattedValue(params: ValueFormatterParams, columnConfig: ConfigItem, parentRenderer?: any): string | number | HTMLElement {
        if (params.node?.group) {
            params.value = this.getRankedGroupValue(params.node, columnConfig);
        } else {
            params.value = params.data[this.getRankedFieldName(columnConfig)];
        }
        return parentRenderer ? parentRenderer(params) : params.value || '';
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    getFilterValue(params: any, columnConfig: ConfigItem): number | null {
        if (params.node?.parent && params.node.parent.id !== ROOT_NODE_ID) {
            if (!params.node.parent.aggData) {
                params.node.parent.aggData = {};
            }
            return this.getFilterValue(params.node.parent, columnConfig);
        }

        if (params.aggData) {
            return this.getRankedGroupValue(params, columnConfig);
        }

        // eslint-disable-next-line no-unsafe-optional-chaining
        let rankData = (params.data || params.node?.aggData)?.[this.getRankedFieldName(columnConfig)];

        // we don't always give a rank as a number some of them have null as their value
        if (rankData !== undefined) {
            return rankData;
        }

        if (params.node.id === ROOT_NODE_ID) {
            this.rankGroups(params.node, columnConfig);
        } else {
            // Fixes the issue from https://hedgeserv.atlassian.net/browse/DDV-6866
            this.rankGroups(params.node.parent, columnConfig);
            rankData = (params.data || params.node?.aggData)?.[this.getRankedFieldName(columnConfig)];
        }

        return rankData;
    }

    compareNodes(nodeA: IRowNode, nodeB: IRowNode, columnConfig: ConfigItem): number {
        if (nodeA.group) {
            const nodeAAggData = this.getRankedGroupValue(nodeA, columnConfig);
            const nodeBAggData = this.getRankedGroupValue(nodeB, columnConfig);

            if (nodeAAggData == null) {
                return 1;
            }
            if (nodeBAggData == null) {
                return -1;
            }
            return nodeAAggData - nodeBAggData;
        }

        const rankField = this.getRankedFieldName(columnConfig);
        if (nodeA.data[rankField] == null) {
            return 1;
        }
        if (nodeB.data[rankField] == null) {
            return -1;
        }
        return nodeA.data[rankField] - nodeB.data[rankField];
    }

    addRankHandlersToColumnDef(columnDef: ColDef, columnConfig: ConfigItem): void {
        columnDef.comparator = (a, b, nodeA, nodeB): number => this.compareNodes(nodeA, nodeB, columnConfig);
        columnDef.filterValueGetter = (params: ValueGetterParams): number | null => this.getFilterValue(params, columnConfig);
        if (!columnConfig.columnCondition?.length) {
            const parentRenderer = columnDef.cellRenderer;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columnDef.cellRenderer = (params: any): any => this.getFormattedValue(params, columnConfig, parentRenderer) as HTMLElement;
        }
    }

    // Gets source field name of raw unranked data, whether abs val or not
    private getSourceFieldName(columnConfig: ConfigItem): string {
        return columnConfig.useAbsVal ?
            this.transformService.getAbsValFieldName(columnConfig) :
            columnConfig.value!;
    }

    private rankGroups(parent: IRowNode, columnConfig: ConfigItem): void {
        const sourceFieldName = this.getSourceFieldName(columnConfig);
        const childNodes = parent.childrenAfterGroup;
        // Re-calculate all ranks at this level
        childNodes
            ?.map((rowNode, i) => {
                const allLeafChildren: IRowNode[] = rowNode.allLeafChildren ?? rowNode.parent?.allLeafChildren ?? [];
                return {
                    data: allLeafChildren.reduce((total, node) => total + (node.data[sourceFieldName] as number), 0),
                    index: i,
                };
            })
            // Sort collected aggregation values according to the selected comparison
            .sort(rankAlgorithms[columnConfig.rankOrder!].comparison)
            // Put the calculated ranks into the aggregation under "<columnId>__rank"
            .forEach((ranked, i) => {
                const aggData = childNodes[ranked.index].aggData || childNodes[ranked.index].parent?.aggData;
                if (aggData) {
                    const isKeySet = childNodes[ranked.index].key != null;
                    aggData[this.getRankedFieldName(columnConfig)] = isKeySet ?
                        rankAlgorithms[columnConfig.rankOrder!].getRankVal(ranked.data, i) :
                        1;
                }
            });
    }

    private isMissingRankedGroupData(node: IRowNode, rankedColId: string): boolean {
        return !!node.childrenAfterGroup?.some((rowNode) => !rowNode.aggData?.[rankedColId]);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getRankedGroupValue(node: IRowNode, columnConfig: ConfigItem): any {
        if (!node.aggData) {
            return;
        }
        const rankedColId = this.getRankedFieldName(columnConfig);
        const rowNode: IRowNode = node.parent ?? node;
        if (this.isMissingRankedGroupData(rowNode, rankedColId)) {
            this.rankGroups(rowNode, columnConfig);
        }
        return node.aggData[rankedColId];
    }

    // Gets the destination field name of ranked data, assumes the column is ranked
    private getRankedFieldName(columnConfig: ConfigItem): string {
        return columnConfig.useAbsVal ?
            this.transformService.getAbsValFieldName(columnConfig) + rankSuffix :
            columnConfig.colId + rankSuffix;
    }
}
