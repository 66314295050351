import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { UserService } from '@ddv/entitlements';
import { ManagerService } from '@ddv/layout';
import { AppWidgetState, Fund, QueryParams, QueryParamsBuilder, toPrioritizedFilterParams, UserPreferences } from '@ddv/models';
import { ClientsService, FundsService } from '@ddv/reference-data';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';

export const queryParamsBuilderInjectionToken: InjectionToken<QueryParamsBuilder> = new InjectionToken('queryParamsBuilderInjectionToken');

// have to fall back to a user's defaults is all over the place in DDV
// and as you can see from the below, it's not that trivial to get to the defaults
// so this service encapsulates that logic
@Injectable()
export class DefaultQueryParamsService {
    public readonly defaultQueryParams$: Observable<QueryParams>;

    private readonly defaultQueryParamsSubject: Subject<QueryParams> = new ReplaySubject(1);
    private readonly queryParamsBuilder: QueryParamsBuilder;

    constructor(
        managerService: ManagerService,
        fundsService: FundsService,
        clientsService: ClientsService,
        userService: UserService,
        @Inject(queryParamsBuilderInjectionToken) @Optional() queryParamsBuilder: QueryParamsBuilder | null,
    ) {
        this.queryParamsBuilder = queryParamsBuilder ?? toPrioritizedFilterParams;
        this.defaultQueryParams$ = this.defaultQueryParamsSubject.asObservable();

        combineLatest([
            fundsService.funds(),
            clientsService.clients(),
            userService.userPreferences$,
        ]).subscribe({
            next: ([funds, clientCodes, userPreferences]) => {
                this.buildAndEmitDefaultQueryParams(
                    funds,
                    clientCodes,
                    managerService.getExtraParametersForWorkspace(),
                    userPreferences);
            },
        });
    }

    private buildAndEmitDefaultQueryParams(
        funds: Fund[],
        clientCodes: string[],
        extraParametersForWorkspace: { widgets: AppWidgetState[] } | undefined,
        userPreferences: UserPreferences,
    ): void {
        this.defaultQueryParamsSubject.next(this.queryParamsBuilder(
            funds,
            clientCodes.map((clientCode) => {
                return {
                    clientName: clientCode,
                    clientId: clientCode,
                };
            }),
            extraParametersForWorkspace,
            userPreferences));
    }
}
