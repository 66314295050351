import { Inject, Injectable, InjectionToken, Optional, Renderer2, RendererFactory2 } from '@angular/core';
import { ApiExecutorService, ApiServices } from '@ddv/http';

export const fileDownloadQuietPeriod = new InjectionToken<number>('fileDownloadQuietPeriod');

@Injectable()
export class FileDownloaderService {
    private renderer: Renderer2 | undefined;
    private anchor: HTMLAnchorElement | undefined;
    private readonly downloadQueue: { name: string, filePath: string }[] = [];
    private isDownloading = false;

    // render cannot be injected directly into Services, only Components, so we have to use the Factory
    constructor(
        private readonly rendererFactory: RendererFactory2,
        @Inject(ApiServices.xtlk) private readonly xtlkApiService: ApiExecutorService,
        @Optional() @Inject(fileDownloadQuietPeriod) private readonly quietPeriod: number,
    ) {
        if (!quietPeriod) {
            if (window.location.search.includes('fileDownloadQuietPeriod')) {
                this.quietPeriod = Number(window.location.search
                    .substring(1)
                    .split(',')
                    .find((param) => param.includes('fileDownloadQuietPeriod'))
                    ?.split('=')[1]);
            } else {
                this.quietPeriod = 500;
            }
        }
    }

    downloadFile(name: string, filePath: string): void {
        if (!this.renderer) {
            this.initialize();
        }

        this.downloadQueue.push({ name, filePath });
        this.checkDownloadQueue();
    }

    private initialize(): void {
        this.renderer = this.rendererFactory?.createRenderer(null, null);

        this.anchor = this.renderer.createElement('a');
        this.renderer.setStyle(this.anchor, 'display', 'none');
        this.renderer.setAttribute(this.anchor, 'id', 'file-downloader');
        this.renderer.appendChild(document.body, this.anchor);
    }

    private checkDownloadQueue(): void {
        if (this.isDownloading) {
            return;
        }

        const file = this.downloadQueue.shift();
        if (!file) {
            return;
        }

        this.isDownloading = true;
        this.renderer?.setAttribute(this.anchor, 'download', file.name);
        this.renderer?.setAttribute(this.anchor, 'href', `${this.xtlkApiService.apiRoot}${file.filePath}`);
        this.anchor?.click();

        setTimeout(() => {
            this.isDownloading = false;
            this.checkDownloadQueue();
        }, this.quietPeriod);
    }
}
