import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { NgModel, NgControl } from '@angular/forms';

@Directive({
    selector: '[appInputRestrict]',
})
export class InputRestrictDirective {
    @Input() allowPattern: string | RegExp | undefined;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly model: NgModel,
        private readonly ngControl: NgControl,
    ) {}

    @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent): void {
        const value: string = (event.target as HTMLInputElement).value;
        if (value && this.allowPattern) {
            this.matchAndUpdateNativeValue(value);
            if (this.model.valueAccessor) {
                this.model.valueAccessor.writeValue(this.elementRef.nativeElement.value);
                this.model.update.emit(this.elementRef.nativeElement.value);
            }
            if (this.ngControl) {
                this.ngControl.control?.setValue(this.elementRef.nativeElement.value);
            }
        }
    }

    @HostListener('keydown', ['$event']) onkeydown(event: KeyboardEvent): void {
        const value: string = (event.target as HTMLInputElement).value;
        if (value && this.allowPattern) {
            this.matchAndUpdateNativeValue(value);
        }
    }

    private matchAndUpdateNativeValue(value: string): void {
        if (this.allowPattern && !value.match(this.allowPattern)) {
            this.elementRef.nativeElement.value = value.substring(0, value.length - 1);
        }
    }
}
