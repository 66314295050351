import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { ManagerService } from '@ddv/layout';
import { DateRangeString, DdvDate, FastnessResponse, FuzzyDates, resolvePotentialFuzzyDate, toDefaultQueryParams } from '@ddv/models';
import { ConfirmationService } from '@hs/ui-core-notifications';

import { DataFastnessService } from '../../services/data-fastness.service';
import { SlowDataApprovalService } from '../../services/slow-data-approval.service';

@Component({
    selector: 'ddv-slow-data-approval',
    templateUrl: './slow-data-approval.component.html',
    styleUrls: ['./slow-data-approval.component.scss'],
})
export class SlowDataApprovalComponent implements OnChanges, AfterViewInit {
    @Input() clientCode: string | undefined;
    @Input() selectedDate: string | DateRangeString | undefined;
    @Input() queryType = 'Recon';
    @Input() fuzzyDates: FuzzyDates | undefined;

    @Output() approved: EventEmitter<boolean> = new EventEmitter();

    private dateRange: { dateFrom: string, dateTo: string } | undefined;

    constructor(
        private readonly fastnessService: DataFastnessService,
        private readonly approvalService: SlowDataApprovalService,
        private readonly confirmationService: ConfirmationService,
        // this is only needed to get "default" dates
        // given the way pulling out this component has gone, this actually feels unnecessary
        // it feels like the parent component should be responsible for providing the dates even if they are the defaults
        private readonly managerService: ManagerService,
    ) {}

    @ViewChild('template', { static: true }) private readonly template: TemplateRef<unknown> | undefined;

    ngOnChanges(): void {
        this.dateRange = this.getDatesForSlowDataCheck(this.selectedDate);
    }

    ngAfterViewInit(): void {
        if (!this.dateRange || !this.clientCode || !this.template) {
            return;
        }

        // capture values in a closure to avoid rechecking undefined in the subscribe block
        const clientCode = this.clientCode;
        const template = this.template;
        const dateTo = this.dateRange.dateTo;
        const resolveToDate = resolvePotentialFuzzyDate(this.fuzzyDates, 'to', dateTo);
        const queryType = this.queryType === 'Recon' ? 'rc2' : this.queryType;

        this.fastnessService.checkFastness(clientCode, this.dateRange.dateFrom, dateTo, queryType, this.fuzzyDates)
            .subscribe({
                next: (result: FastnessResponse) => {
                    const toDateKey = DdvDate.fromUSFormat(resolveToDate).toReversePaddedDashFormat();
                    const resultForToDate = result[queryType][toDateKey];

                    if (resultForToDate && !resultForToDate.fast) {
                        this.getConfirmationToProceedWithSlowData(clientCode, resolveToDate, template);
                    } else {
                        this.approvalService.approve(clientCode, resolveToDate, this.queryType);
                        this.approved.emit(true);
                    }
                },
            });
    }

    private getDatesForSlowDataCheck(selectedDate: string | DateRangeString | undefined): { dateFrom: string, dateTo: string } {
        if (typeof selectedDate === 'string') {
            return {
                dateFrom: selectedDate,
                dateTo: selectedDate,
            };
        }

        if (selectedDate) {
            return {
                dateFrom: selectedDate.dateFrom ?? '',
                dateTo: selectedDate.dateTo ?? '',
            };
        }

        const hsDefaults = toDefaultQueryParams([], [], this.managerService.getExtraParametersForWorkspace());
        return {
            dateFrom: hsDefaults.startDate ?? '',
            dateTo: hsDefaults.endDate ?? '',
        };
    }

    private getConfirmationToProceedWithSlowData(
        clientCode: string,
        dateTo: string,
        template: TemplateRef<unknown>,
    ): void {
        this.confirmationService.launch(template, { confirmButtonText: 'OK', denyButtonText: 'Cancel' })
            .subscribe({
                next: (action) => {
                    if (action === 'confirm') {
                        this.approvalService.approve(clientCode, dateTo, this.queryType);
                        this.approved.emit(true);
                    } else {
                        this.approvalService.deny(clientCode, dateTo, this.queryType);
                        this.approved.emit(false);
                    }
                },
            });
    }
}
