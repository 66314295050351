import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { combineLatest, filter, Observable, ReplaySubject, Subject } from 'rxjs';

export interface ParsedRoute {
    clientCode: string;
    section: string;
    action: string;
    entityId: string;
    activatedRoute: ActivatedRoute;
}

function toStrings(urlSegments: UrlSegment[]): string[] {
    return urlSegments.map((s) => s.path);
}

@Injectable({ providedIn: 'root' })
export class ParsedRouteService {
    public readonly parsedRoute: Observable<ParsedRoute>;
    private readonly parsedRouteSubject: Subject<ParsedRoute> = new ReplaySubject(1);

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
        this.parsedRoute = this.parsedRouteSubject.asObservable();
        this.router.events
            .pipe(filter((event: Event) => event instanceof NavigationEnd))
            .subscribe(() => {
                const clientCodeRoute: ActivatedRoute | undefined = this.route.root.children[0];
                if (!clientCodeRoute) {
                    return;
                }

                const remainingRoute: ActivatedRoute | undefined = clientCodeRoute.children[0];
                if (!remainingRoute) {
                    return;
                }

                combineLatest([clientCodeRoute.url, remainingRoute.url])
                    .subscribe(([clientCodeParts, remainingParts]) => {
                        this.buildParsedRoute(toStrings(clientCodeParts), toStrings(remainingParts), remainingRoute);
                    })
                    .unsubscribe(); // this works because the Observable hanging off of `.url` is a behavior subject, so it fires straight away,
            });
    }

    // this assumes that:
    // - there is a parent route that has a single param that is the client code
    // - that each sub-route has exactly 3 parts to it
    // if AppRoutingModule changes, you will need to fix this
    private buildParsedRoute(clientCodeParts: string[], remainingParts: string[], activatedRoute: ActivatedRoute): void {
        if (clientCodeParts.length !== 1) {
            return console.warn(`The root route should have exactly one path.  Got: ${clientCodeParts.join('/')}`);
        }

        if (remainingParts.length !== 3) {
            return console.warn(`ignoring transient route: ${remainingParts.join('/')}`);
        }

        this.parsedRouteSubject.next({
            clientCode: clientCodeParts[0],
            section: remainingParts[0],
            action: remainingParts[1],
            entityId: remainingParts[2],
            activatedRoute,
        });
    }
}
