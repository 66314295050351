import { Directive, Input } from '@angular/core';
import { WIDGET_LIFECYCLE_EVENT, WidgetLifeCycleData, WidgetLifecycleEvent } from '@ddv/models';

@Directive()
export class BaseWidget {
    @Input() id = 0;
    @Input() selector?: string;

    widgetLifeCycleCallBack(_eventName: WidgetLifecycleEvent, _data: WidgetLifeCycleData): void {}

    widgetLifeCyclePostProcess(_eventName: WIDGET_LIFECYCLE_EVENT, _data: unknown): void {}

    getWidgetId(): number {
        return this.id;
    }

    destroy(_: string): void {}
}

