import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { LegendConfig } from '@ddv/models';
import { Subscription } from 'rxjs';

import { Axis } from '../../models/axis';
import { BarChartData } from '../../models/chart-data';
import { ChartFormatSettings } from '../../models/chart-settings';
import { ColorType } from '../../models/color-type';
import { GridLine } from '../../models/grid-lines';
import { Rect } from '../../models/legend';
import { Margin } from '../../models/margin';
import { BarHoverEvent, Tooltip } from '../../models/tooltip';
import { ColorMetadataService } from '../../services/color-metadata.service';
import { AxisClickEvent, HorizontalBarChartAxisService } from './horizontal-bar-chart-axis.service';
import { HorizontalBarChartBarLabelPositionService } from './horizontal-bar-chart-bar-label-position.service';
import { BarClickEvent, HorizontalBarChartBarsService } from './horizontal-bar-chart-bars.service';
import { HorizontalBarChartColorProviderService } from './horizontal-bar-chart-color-provider.service';
import { HorizontalBarChartGridlinesService } from './horizontal-bar-chart-gridlines.service';
import { HorizontalBarChartHoverLineService } from './horizontal-bar-chart-hover-line.service';
import { HorizontalBarChartLegendService, LegendClickEvent } from './horizontal-bar-chart-legends.service';
import { HorizontalBarChartPositionService } from './horizontal-bar-chart-position.service';
import { HorizontalBarChartScaleService } from './horizontal-bar-chart-scale.service';
import { HorizontalBarChartSvgInitializerService } from './horizontal-bar-chart-svg-initializer.service';
import { HorizontalBarChartTextFormatterService } from './horizontal-bar-chart-text-formatter.service';
import { HorizontalBarChartTooltipService } from './horizontal-bar-chart-tooltip.service';

export interface BarChartColorConfig {
    sortBy?: string | null;
    sortDirection?: string | null;
    explicitColorBySlicer?: { [key: string]: string }[];
    range?: string[];
    colorType?: ColorType;
    colorName?: string;
}

export interface ChartClickEvent {
    bar: BarChartData;
    alreadySelected: boolean;
}

@Component({
    selector: 'app-hbar-chart',
    templateUrl: './horizontal-bar-chart.component.html',
    styleUrls: ['./horizontal-bar-chart.component.scss'],
    providers: [
        ColorMetadataService,
        HorizontalBarChartAxisService,
        HorizontalBarChartBarLabelPositionService,
        HorizontalBarChartColorProviderService,
        HorizontalBarChartColorProviderService,
        HorizontalBarChartGridlinesService,
        HorizontalBarChartHoverLineService,
        HorizontalBarChartLegendService,
        HorizontalBarChartPositionService,
        HorizontalBarChartScaleService,
        HorizontalBarChartBarsService,
        HorizontalBarChartSvgInitializerService,
        HorizontalBarChartSvgInitializerService,
        HorizontalBarChartTextFormatterService,
        HorizontalBarChartTooltipService,
    ],
})
export class HorizontalBarChartComponent implements OnInit, OnDestroy, OnChanges {
    @Input() parentId: string | undefined;
    @Input() dataSource: BarChartData[] = [];
    @Input() compareDataSource: BarChartData[] | undefined;
    @Input() slicerAxisConfig: Axis | undefined;
    @Input() valueAxisConfig: Axis | undefined;
    @Input() margin: Margin | undefined;
    @Input() colorConfig: BarChartColorConfig | undefined;
    @Input() isMaximized = false;
    @Input() @HostBinding('class.new-legend') useNewLegend = false;
    @Input() legendConfig: LegendConfig | undefined;
    @Input() valueFormatSettings?: ChartFormatSettings;
    @Input() highlightValueOnHover = false;
    @Input() gridLineConfig: GridLine | undefined;
    @Input() labelPosition: 'none' | 'inside' | 'outside' = 'none';
    @Input() highlight: BarChartData | undefined;
    @Input() tooltipConfig: Tooltip[] | undefined;
    @Input() hasASelectedSlicer = false;

    @Output() rootElement: EventEmitter<HTMLElement | undefined> = new EventEmitter();
    @Output() chartClicked: EventEmitter<ChartClickEvent> = new EventEmitter();
    @Output() sizeChange: EventEmitter<{ width: number, height: number }> = new EventEmitter();

    protected svgRect: Rect = defaultRect();
    protected transform: string | undefined;

    @ViewChild('chartRootElement', { static: true }) private readonly chartRootElement: ElementRef | undefined;
    @ViewChild('rootSvg', { static: true }) private readonly svgRootElementRef: ElementRef<SVGElement> | undefined;
    private legendClickedSubscription: Subscription | undefined;
    private barClickedSubscription: Subscription | undefined;
    private barHoveredSubscription: Subscription | undefined;
    private chartHoveredSubscription: Subscription | undefined;
    private verticalAxisValueClickSubscription: Subscription | undefined;
    @HostBinding('class') private legendPosition: string | undefined;

    constructor(
        private readonly colorProviderService: HorizontalBarChartColorProviderService,
        private readonly legendService: HorizontalBarChartLegendService,
        private readonly barsService: HorizontalBarChartBarsService,
        private readonly tooltipService: HorizontalBarChartTooltipService,
        private readonly positionService: HorizontalBarChartPositionService,
        private readonly scalesService: HorizontalBarChartScaleService,
        private readonly textFormatter: HorizontalBarChartTextFormatterService,
        private readonly svgInitializer: HorizontalBarChartSvgInitializerService,
        private readonly axisService: HorizontalBarChartAxisService,
        private readonly gridLinesService: HorizontalBarChartGridlinesService,
        private readonly hoverLineService: HorizontalBarChartHoverLineService,
    ) {}

    ngOnInit(): void {
        this.initChart();

        this.legendClickedSubscription = this.legendService.legendClicked$.subscribe({
            next: (event) => this.relayLegendClickToChart(event),
        });

        this.barClickedSubscription = this.barsService.barClicked$.subscribe({
            next: (data) => this.relayBarClickToLegend(data),
        });

        this.chartHoveredSubscription = this.svgInitializer.chartHovered$.subscribe({
            next: (coordinates) => this.onChartHovered(coordinates),
        });

        this.barHoveredSubscription = this.barsService.barHovered$.subscribe({
            next: (event) => this.onBarHovered(event),
        });

        this.verticalAxisValueClickSubscription = this.axisService.verticalAxisValueClick$.subscribe({
            next: (data) => this.onVerticalAxisClick(data),
        });
    }

    ngOnChanges(): void {
        this.legendPosition = `legend-${this.legendConfig?.docked ?? 'top'}`;
        this.initChart();
    }

    ngOnDestroy(): void {
        this.legendClickedSubscription?.unsubscribe();
        this.barClickedSubscription?.unsubscribe();
        this.barHoveredSubscription?.unsubscribe();
        this.chartHoveredSubscription?.unsubscribe();
        this.verticalAxisValueClickSubscription?.unsubscribe();
    }

    private initChart(): void {
        this.rootElement.emit(this.chartRootElement?.nativeElement);

        this.colorProviderService.assignColorToBars(
            this.dataSource,
            this.compareDataSource,
            this.colorConfig?.sortBy,
            this.colorConfig?.sortDirection,
            this.colorConfig?.range,
            this.colorConfig?.explicitColorBySlicer);

        const hasNegativeValues = markConfigForNegativeValues(
            this.dataSource,
            this.compareDataSource ?? undefined);

        this.textFormatter.setFormat(this.valueFormatSettings);

        // the parent VisualizationComponent uses this component to "prepare the data" (yes this is backwards an stupid)
        // for a minimized widget, this happens AFTER the components/values below are initialized
        // for a maximised widget, this happens BEFORE.  you might think we can't start with a widget being maximized
        // well you'd be wrong.  if you are in manage widgets, and you maximize the widget and then change anything on the
        // format or color tabs THE WHOLE CHART IS THROWN AWAY AND RECREATED but now you're maximized so things are different
        if (!this.svgRootElementRef || !this.chartRootElement || !this.svgRect.width || !this.slicerAxisConfig || !this.valueAxisConfig) {
            return;
        }

        const clipPathId = `${this.parentId}-clip`;

        const chartPosition = this.positionService.build(
            this.svgRootElementRef.nativeElement,
            this.dataSource,
            this.compareDataSource,
            this.slicerAxisConfig.customClass !== 'hide' && (this.slicerAxisConfig.nTicks ?? 0) > 0,
            this.margin ? { ...this.margin } : undefined,
            // TODO: the chart shouldn't know if its maximized.  to this input into `shouldNotTruncateValues`
            this.isMaximized,
            this.svgRect.width,
            this.svgRect.height,
            this.legendConfig?.docked,
            this.slicerAxisConfig.position);

        const chartRootGSelection = this.svgInitializer.initialize(
            this.svgRootElementRef.nativeElement,
            chartPosition,
            clipPathId);

        this.scalesService.init(
            chartPosition,
            this.dataSource,
            this.compareDataSource,
            hasNegativeValues);

        if (this.highlightValueOnHover) {
            this.hoverLineService.initialize(chartRootGSelection, chartPosition.heightForBars);
        }

        this.axisService.draw(
            chartRootGSelection,
            chartPosition,
            this.dataSource,
            this.slicerAxisConfig,
            this.valueAxisConfig,
            // TODO: the chart shouldn't know if its maximized.  to this input into `shouldNotTruncateValues`
            this.isMaximized);

        if (this.gridLineConfig) {
            this.gridLinesService.draw(
                chartRootGSelection,
                this.dataSource,
                this.valueAxisConfig,
                this.gridLineConfig,
                chartPosition.widthForBars,
                chartPosition.heightForBars);
        }

        this.barsService.draw(
            chartRootGSelection,
            this.dataSource,
            this.compareDataSource,
            hasNegativeValues,
            chartPosition,
            clipPathId,
            this.highlight,
            this.labelPosition,
            this.hasASelectedSlicer);

        if (!this.useNewLegend && this.chartRootElement?.nativeElement && this.legendConfig) {
            this.legendService.createCustomLegends(
                this.svgRootElementRef.nativeElement,
                this.legendConfig,
                this.dataSource,
                this.svgRect,
                this.valueFormatSettings,
                this.highlight);
        }
    }

    private resizeChart(): void {
        this.initChart();
    }

    protected onWrapperResized(): void {
        this.svgRect = this.svgRootElementRef?.nativeElement.getBoundingClientRect() ?? defaultRect();

        this.sizeChange.emit({ width: this.svgRect.width, height: this.svgRect.height });
        this.resizeChart();
    }

    private relayLegendClickToChart(event: LegendClickEvent): void {
        if (event.enabled) {
            this.highlight = undefined;
            this.barsService.clearHighlight();
        } else {
            this.highlight = event.data;
            this.barsService.highlightBar(event.data);
        }

        this.chartClicked.emit({ bar: event.data, alreadySelected: event.enabled });
    }

    private relayBarClickToLegend(event: BarClickEvent): void {
        if (event.enabled) {
            this.highlight = undefined;
            this.legendService.clearHighlight();
        } else {
            this.highlight = event.data;
            this.legendService.highlightLegend(event.data);
        }

        this.chartClicked.emit({ bar: event.data, alreadySelected: event.enabled });
    }

    private onBarHovered(event: BarHoverEvent | undefined): void {
        if (this.tooltipConfig) {
            if (!event) {
                this.tooltipService.clearTooltip();
            } else {
                this.tooltipService.createOrMoveTooltip(
                    event.data,
                    event.mouseEvent,
                    this.tooltipConfig ?? []);
            }
        }

        this.onChartHovered(event?.coordinates);
    }

    private onChartHovered(coordinates: [number, number] | undefined): void {
        if (!this.highlightValueOnHover) {
            return;
        }

        if (coordinates) {
            this.hoverLineService.show(coordinates);
            this.axisService.blurHorizontal();
        } else {
            this.hoverLineService.hide();
            this.axisService.unblurHorizontal();
        }
    }

    private onVerticalAxisClick(event: AxisClickEvent): void {
        if (event.enabled) {
            this.highlight = undefined;
            this.barsService.clearHighlight();
            this.legendService.clearHighlight();
        } else {
            this.highlight = event.data;
            this.barsService.highlightBar(event.data);
            this.legendService.highlightLegend(event.data);
        }

        this.chartClicked.emit({ bar: event.data, alreadySelected: event.enabled });
    }
}

function defaultRect(): Rect {
    return { right: 0, height: 0, width: 0, top: 0, bottom: 0 };
}

export function markConfigForNegativeValues(data: BarChartData[], compareData: BarChartData[] = []): boolean {
    return [...data, ...compareData].some((d) => Number(d.value.value) < 0);
}
