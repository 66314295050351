import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgModel } from '@angular/forms';
import { DropdownOption, ModalDialogActive } from '@ddv/common-components';
import {
    ColumnDisplayType,
    FormatOperator,
    LabelValuePair,
    MetadataFormatUtils,
    AGG_TYPES_BY_SECTION_AND_DISPLAY,
    ConfigItem,
} from '@ddv/models';

@Component({
    selector: 'app-tooltip-column-formatting',
    templateUrl: './column-formatting-tooltip.component.html',
    styleUrls: ['../column-formatting-grid/column-formatting-grid.component.scss'],
    providers: [NgModel],
})
export class ColumnFormattingTooltipComponent implements OnInit {
    parentData: ConfigItem | undefined;
    configForm: UntypedFormGroup | undefined;
    valueOperators: { label: string, value: FormatOperator, showInputRange?: boolean }[] | undefined;
    displayTypes: LabelValuePair<ColumnDisplayType>[] | undefined;
    aggTypes: DropdownOption[] | undefined;
    numberFormats: DropdownOption[] | undefined;

    constructor(public modalDialogActive: ModalDialogActive, private readonly fb: UntypedFormBuilder) { }

    ngOnInit(): void {
        if (!this.parentData) {
            return console.error('Cannot init without parentData');
        }

        this.aggTypes = (this.parentData.aggregationDropDownList ?? AGG_TYPES_BY_SECTION_AND_DISPLAY.tooltip[this.parentData.displayType ?? ''])
            .map((t: LabelValuePair<string>) => {
                return { text: t.label, key: t.value, value: t.value };
            });
        this.numberFormats = MetadataFormatUtils.getNumberFormats().map((t) => {
            return { text: t.label, key: t.value, value: t.value };
        });
        this.initForm(this.parentData);
        this.updateValueOperators(this.parentData.displayType);
    }

    showInputRange(selectedOperator: FormatOperator): boolean {
        const operator = this.valueOperators?.find((op) => op.value === selectedOperator);
        return operator ? !!operator.showInputRange : true;
    }

    onDisplayTypeChanged(): void {
        this.updateValueOperators();
    }

    updateValueOperators(displayType?: string): void {
        this.valueOperators = MetadataFormatUtils.getOperators(displayType ?? this.configForm?.get('displayType')?.value);
    }

    onBarConditionChange(colCondn: UntypedFormGroup): void {
        if (colCondn.get('operatorCondition')?.value === 'AUTO') {
            colCondn.get('rangeFrom')?.disable();
            colCondn.get('rangeTo')?.disable();
        } else {
            colCondn.get('rangeFrom')?.enable();
            colCondn.get('rangeTo')?.enable();
        }
    }

    toggleCustomNameField(): void {
        if (this.configForm?.controls.showCustomName.value) {
            this.configForm.controls.customName.enable();
        } else {
            this.configForm?.controls.customName.disable();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private initForm(data: any): void {
        this.configForm = this.fb.group(
            {
                colId: [data.colId],
                value: [data.value],
                customName: [data.customName],
                showCustomName: [data.showCustomName],
                aggregationType: [data.aggregationType],
                displayType: [data.displayType],
                numberFormat: [data.numberFormat],
                decimalPlaces: [data.decimalPlaces],
            },
            {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                validator: (group: any) => {
                    if (group.get('showCustomName').value) {
                        group.controls.customName.setValidators(Validators.required);
                    } else {
                        group.controls.customName.clearValidators();
                    }
                },
            },
        );

        this.toggleCustomNameField();
    }
}
