import { Injectable } from '@angular/core';
import { DatasetDefinition, ClearAllFilters } from '@ddv/models';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class SelectedWidgetRelayService {
    public readonly isWidgetGlobal: Observable<boolean>;
    public readonly clearAllFilters$: Observable<ClearAllFilters>;

    private readonly isWidgetGlobalSubject: Subject<boolean> = new ReplaySubject<boolean>(1);
    private currentDatasetDefinition: DatasetDefinition | undefined;
    private readonly clearAllFiltersSubject: Subject<ClearAllFilters> = new ReplaySubject<ClearAllFilters>(1);

    constructor() {
        this.isWidgetGlobal = this.isWidgetGlobalSubject.asObservable();
        this.clearAllFilters$ = this.clearAllFiltersSubject.asObservable();
    }

    relayIsWidgetGlobal(isWidgetGlobal: boolean): void {
        this.isWidgetGlobalSubject.next(isWidgetGlobal);
    }

    // only called by live demo widget
    updateCurrentDatasetDefinition(datasetDefinition: DatasetDefinition): void {
        this.currentDatasetDefinition = datasetDefinition;
    }

    // only used by grid visualizations
    getCurrentDatasetDefinition(): DatasetDefinition | undefined {
        return this.currentDatasetDefinition;
    }

    clearAllFilters(widgetId: number): void {
        this.clearAllFiltersSubject.next({ id: widgetId, clearAllFilters: true });
    }
}
