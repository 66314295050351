<div appMousedownOutside (mousedownOutside)="selectedMenuIcon=''">
    <div class="vis-menu">
        <app-vis-menu
            #visMenu
            [selectedMenuIcon]="selectedMenuIcon"
            [widgetId]="widgetId"
            (selectVisualization)="onVisSelect($event)"
            (visualizationChanged)="onVisChanged($event)"
            [menuItems]="visMenuOptions">
        </app-vis-menu>
    </div>

    <app-dropdown
        *ngIf="selectedMenuIcon === 'custom-filter'"
        [options]="filterOptions"
        [(selected)]="selectedFilterOption"
        [wrapperCssClass]="'hide-title'"
        [showDropdown]="selectedMenuIcon === 'custom-filter'"
        (selectedChange)="onFilterChanged($event);">
    </app-dropdown>
</div>
