<div class="simple-data-grid data-grid" [ngClass]="{'ag-readonly': isWidgetReadOnly}">
    <div class="grid-wrapper widget-flex">
        <div *ngIf="showRowFilter" class="search-wrap">
            <div class="pull-left">
                <div class="search-box">
                    <ddv-search-box
                        [quickSearchPlaceholder]="quickSearchPlaceholder"
                        [rowSearchText]="rowSearchText"
                        (quickFilterChanged)="onQuickFilterChanged($event)">
                    </ddv-search-box>
                </div>
            </div>
        </div>

        <div class="widget-wrap" appMousedownOutside (mousedownOutside)="deselectAllSelected(true)" (mousedown)="isMousedownInside = true">
            <app-data-grid
                *ngIf="showGrid"
                [gridConfiguration]="gridConfiguration"
                [isManagingWidget]="isManagingWidget"
                [hideLoaderAfterFirstDataLoad]="hideLoaderAfterFirstDataLoad"
                [isOnMasterWidget]="isMaster"
                [isShowingTFLData]="isTFLDetails || isTFLIncompleteFiles"
                [datasetIncludesCrosstalk]="isCrosstalkGrid"
                [widgetId]="widgetId"
                [visualizationId]="visualizationId"
                [frameworkComponents]="allFrameworkComponents"
                (cellClickedEvent)="conditionallyFollowLink($event)"
                (columnEverythingChanged)="onColumnEverythingChanged($event)"
                (columnMovedEvent)="onColumnMoved($event.event, $event.columns)"
                (dragStoppedEvent)="onDragStopped($event.event, $event.columns)"
                (gridReadyEvent)="onGridReady()"
                (rowSelectionEvent)="onRowSelected($event)"
                (rowUnselectedEvent)="onRowUnselected($event)">
            </app-data-grid>
        </div>
    </div>
</div>
