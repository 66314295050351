import {
    ApplicationRef,
    Injectable,
    Injector,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    TemplateRef,
} from '@angular/core';

import { ModalDialogOverlayComponent } from './modal-dialog-overlay.component';
import { ModalDialogActive, ModalDialogRef } from './modal-dialog-ref.service';
import { isDefined, isString, ModalDialogContentRef } from './modal-dialog-utils';
import { ModalDialogWindowComponent } from './modal-dialog-window.component';
import { ModalDialogOptions } from './modal-dialog.service';

@Injectable()
export class ModalDialogStack {
    private readonly overlayFactory: ComponentFactory<ModalDialogOverlayComponent>;
    private readonly windowFactory: ComponentFactory<ModalDialogWindowComponent>;

    constructor(
        private readonly applicationRef: ApplicationRef,
        private readonly injector: Injector,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
    ) {
        this.overlayFactory = this.componentFactoryResolver.resolveComponentFactory(ModalDialogOverlayComponent);
        this.windowFactory = this.componentFactoryResolver.resolveComponentFactory(ModalDialogWindowComponent);
    }

    open(moduleCFR: ComponentFactoryResolver, contentInjector: Injector, content: unknown, options: ModalDialogOptions): ModalDialogRef {
        const containerSelector = options?.container || 'body';
        const containerEl = document.querySelector(containerSelector);

        if (!containerEl) {
            throw new Error(`The specified modal container "${containerSelector}" was not found in the DOM.`);
        }

        const activeDialog = new ModalDialogActive();
        const contentRef = this.getContentRef(moduleCFR, options.injector ?? contentInjector, content, activeDialog);

        if (options.data && contentRef.componentRef) {
            for (const name in options.data) {
                if (Object.prototype.hasOwnProperty.call(options.data, name)) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    contentRef.componentRef.instance[name] = (options.data as any)[name];
                }
            }
        }

        let overlayCompRef: ComponentRef<ModalDialogOverlayComponent> | undefined;

        if (options.backdrop !== false) {
            overlayCompRef = this.overlayFactory.create(this.injector);
            this.applicationRef.attachView(overlayCompRef.hostView);
            containerEl.appendChild(overlayCompRef.location.nativeElement);
        }

        const windowCompRef = this.windowFactory.create(this.injector, contentRef.nodes);
        this.applicationRef.attachView(windowCompRef.hostView);
        containerEl.appendChild(windowCompRef.location.nativeElement);

        const modalDialogRef = new ModalDialogRef(windowCompRef, contentRef, overlayCompRef);

        activeDialog.close = (result: unknown): void => { modalDialogRef.close(result); };
        activeDialog.dismiss = (reason: unknown): void => { modalDialogRef.dismiss(reason); };

        this.applyModalWindowOptions(windowCompRef.instance, options);

        return modalDialogRef;
    }

    private applyModalWindowOptions(windowInstance: ModalDialogWindowComponent, options: object): void {
        ['backdrop', 'keyboard', 'size', 'windowClass'].forEach((optionName: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = (options as any)[optionName];
            if (isDefined(value)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (windowInstance as any)[optionName] = value;
            }
        });
    }

    private getContentRef(
        moduleCFR: ComponentFactoryResolver,
        contentInjector: Injector,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        content: any,
        context: ModalDialogActive,
    ): ModalDialogContentRef {
        if (!content) {
            return new ModalDialogContentRef([]);
        } else if (content instanceof TemplateRef) {
            const viewRef = content.createEmbeddedView(context);
            this.applicationRef.attachView(viewRef);
            return new ModalDialogContentRef([viewRef.rootNodes], viewRef);
        } else if (isString(content)) {
            return new ModalDialogContentRef([[document.createTextNode(`${content}`)]]);
        } else {
            const contentCompFactory = moduleCFR.resolveComponentFactory(content);
            const modalContentInjector =
                Injector.create({ providers: [{ provide: ModalDialogActive, useValue: context }], parent: contentInjector });
            const componentRef = contentCompFactory.create(modalContentInjector);
            this.applicationRef.attachView(componentRef.hostView);
            return new ModalDialogContentRef([[componentRef.location.nativeElement]], componentRef.hostView, componentRef);
        }
    }
}
