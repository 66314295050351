import { AppWidgetState, Widget, WidgetConfiguration, WidgetPosition, WidgetState, TWidgetTypes } from '@ddv/models';

import { LiveDemoWidgetComponent } from './components/live-demo-widget/live-demo-widget.component';
import { SummaryWidgetComponent } from './components/summary-widget/summary-widget.component';
import { VisualizationWidgetComponent } from './components/visualization-widget/visualization-widget.component';
import { ApplicationWidgetConfiguration } from './models/application-widget-configuration';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class WidgetConfigurationManager {
    private static widgetConfigRegistry: { [selector: string]: Widget[] } = {};

    static getWidgetConfigForWorkspace(widgetInfo: AppWidgetState, widgetPosition?: WidgetPosition): Widget {
        const widget = WidgetConfigurationManager.createWidgetFromAppState(widgetInfo, widgetPosition);

        if (!widgetInfo.coreWidgetType) {
            console.error('cannot add widget to registry without a coreWidgetType');
            return widget;
        }

        this.addWidgetToRegistry(widget, widgetInfo.coreWidgetType);

        return widget;
    }

    private static createWidgetFromAppState(widgetInfo: AppWidgetState, widgetPosition?: WidgetPosition): Widget {
        const defaultConfig = this.getWidgetByUID(widgetInfo.coreWidgetType);
        if (!defaultConfig) {
            throw new Error(`No widget available for uid: ${widgetInfo.coreWidgetType}.`);
        }
        const defaultState = new WidgetState();
        defaultState.id = widgetInfo.id;

        const widgetConfiguration: WidgetConfiguration = {
            title: widgetInfo.name ?? defaultConfig.title,
            selector: defaultConfig.selector,
            component: defaultConfig.component,
            left: defaultState.left ?? defaultConfig.left,
            top: defaultState.top ?? defaultConfig.top,
            width: defaultState.width ?? defaultConfig.width,
            height: defaultState.height ?? defaultConfig.height,
            minHeight: defaultConfig.minHeight,
            minWidth: defaultConfig.minWidth,
            dragImagePath: defaultConfig.dragImagePath,
            isSelectable: widgetInfo.isSelectable,
            defaultMenuIconClasses: [
                { selector: 'maxBtn', styleClass: 'icon-bigw' },
                { selector: 'cascadeBtn', styleClass: 'icon-collapse' },
                { selector: 'closeBtn', styleClass: 'icon-config-close' },
            ],
        };
        const widget = new Widget(widgetConfiguration);
        // Replace the id created by the widget ID generator
        if (widgetInfo.id) {
            widget.id = widgetInfo.id;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        widgetInfo.widgetPosition = (defaultState as any).widgetPosition || widgetPosition;

        widget.setExtraPreferences(widgetInfo);

        return widget;
    }

    static addWidgetToRegistry(widget: Widget, widgetType: TWidgetTypes): void {
        if (!this.widgetConfigRegistry[widgetType]) {
            this.widgetConfigRegistry[widgetType] = [];
        }

        this.widgetConfigRegistry[widgetType].push(widget);
    }

    static getWidgetsForDashboard(): ApplicationWidgetConfiguration[] {
        const defaults = {
            top: 30,
            left: 30,
            width: 40,
            height: 40,
            dragImagePath: './assets/images/trans.png',
        };
        return [
            {
                uid: 'VISUALIZATION',
                title: 'Visualization Widget',
                selector: 'app-visualization-widget',
                component: VisualizationWidgetComponent,
                top: defaults.top,
                left: defaults.left,
                width: defaults.width,
                height: defaults.height,
                minWidth: 200,
                minHeight: 50,
                dragImagePath: defaults.dragImagePath,
                isViz: true,
                isConfigurable: true,
                isSelectable: true,
            },
            {
                uid: 'SUMMARY',
                title: 'Summary Widget',
                selector: 'app-summary-widget',
                component: SummaryWidgetComponent,
                top: defaults.top,
                left: defaults.left,
                width: defaults.width,
                height: defaults.height,
                minWidth: 150,
                minHeight: 25,
                dragImagePath: defaults.dragImagePath,
                isViz: false,
                isConfigurable: true,
                cssClass: 'icon-summary-widget',
                isSelectable: true,
            },
            {
                uid: 'LIVE_DEMO_WIDGET',
                title: 'Live Demo',
                selector: 'app-live-demo-widget',
                component: LiveDemoWidgetComponent,
                top: defaults.top,
                left: defaults.left,
                width: defaults.width,
                height: defaults.height,
                minWidth: 200,
                minHeight: 50,
                dragImagePath: defaults.dragImagePath,
                isConfigurable: false,
                isViz: true,
                isSelectable: true,
            },
        ];
    }

    static getWidgetBySelector(selector: string): ApplicationWidgetConfiguration | undefined {
        return this.getWidgetsForDashboard().find((item) => item.selector === selector);
    }

    static getWidgetByUID(uid?: TWidgetTypes | string): ApplicationWidgetConfiguration | undefined {
        return this.getWidgetsForDashboard().find((item) => item.uid === uid);
    }

    static getCoreWidgetType(selector: string): TWidgetTypes | undefined {
        return this.getWidgetsForDashboard().find((item) => item.selector === selector)?.uid;
    }
}
