<div class="attachments-renderer-wrapper" onclick="event.stopPropagation()">
    <ng-container *ngIf="conversationId">
        <span class="upload-attachment" title="Upload attachments" *ngIf="isUserEntitledToUploadFiles" onclick="event.stopPropagation()">
            <form class="attachments-form">
                <label class="browse-label" [for]="attachmentsInputId">
                    <app-upload-attachment-icon></app-upload-attachment-icon>
                </label>
                <input #attachment [id]="attachmentsInputId"
                    [name]="attachmentsInputId"
                    class="attachment"
                    type="file"
                    multiple
                    (input)="onFilesAttached($event)">
            </form>
        </span>

        <app-download-attachments-icon *ngIf="lastAttachments" title="Download attachments" (pressed)="downloadAttachments()"></app-download-attachments-icon>
        <app-conversation-history-icon title="Open history" (pressed)="openHistory($event)" [ngStyle]="{ 'margin-left': lastAttachments ? '0px' : '18px' }"></app-conversation-history-icon>
    </ng-container>
</div>

<ng-template #multiAttachmentTemplate>
    <span class="bold-text">You are about to upload {{ attachmentsCount }} {{ attachmentsCount > 1 ? 'attachments' : 'attachment' }} to {{ selectedNodesCount }} rows.</span>
</ng-template>
