import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { ModalDialogService } from '@ddv/common-components';
import { MetadataService } from '@ddv/datasets';
import {
    FieldMetadata,
    LabelValuePair,
    MetadataFormatUtils,
    AGG_TYPES_BY_SECTION_AND_DISPLAY,
    AggType,
    ConfigItem,
    VizConfigs,
} from '@ddv/models';
import { uid } from '@ddv/utils';

import { FieldMetadataService } from '../../../services/field-metadata.service';
import { ColumnFormattingTooltipComponent } from '../column-formatting-tooltip/column-formatting-tooltip.component';
import { ConfigureComponent, DropZoneConfig } from '../configure/configure.component';
import * as dropZoneConfig from '../drop-zone-config';

@Component({
    selector: 'app-mw-configure',
    templateUrl: '../configure/configure.component.html',
    styleUrls: ['../configure/configure.component.scss'],
})
export class ConfigureChartsComponent extends ConfigureComponent {
    override visualizationType: string | undefined;

    constructor(
        private readonly modalService: ModalDialogService,
        metadataService: MetadataService,
        fieldMetadataService: FieldMetadataService,
    ) {
        super(metadataService, fieldMetadataService);
    }

    getDropZones(): DropZoneConfig[] {
        switch (this.visualizationType) {
            case 'LINE_CHART':
                return dropZoneConfig.line;
            case 'STACKED_AREA_CHART':
                return dropZoneConfig.stackedArea;
            case 'VERTICAL_STACKED_BAR_CHART':
            case 'HORIZONTAL_STACKED_BAR_CHART':
                return dropZoneConfig.stackedBar;
            case 'VERTICAL_MIRRORED_BAR_CHART':
                return dropZoneConfig.mirroredBar;
            default:
                return dropZoneConfig.defaultConfig;
        }
    }

    dragStarted(data: FieldMetadata): void {
        this.dropZones?.forEach((item) => {
            const isNotFull = this.dataConfigs[item.configKey].length < item.maximumAllowed!;
            const allowDuplicate = item.allowDuplicate ??
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                !this.dataConfigs[item.configKey].some((config: any) => config.value === data.value);
            const isValidDatatype = item.dataTypes?.indexOf(data.datatype ?? '') !== -1;
            if (item.allowed) {
                item.allowed = isNotFull && allowDuplicate && isValidDatatype;
            }
        });
    }

    override dragEnded(): void {
        this.dropZones?.forEach((item) => {
            item.allowed = true;
        });
        this.checkValueSlicerAllowCondition();
    }

    showColumnFormatters(config: ConfigItem, zone = ''): void {
        config.hideGenFormatting = zone === 'value' || zone === 'value1' || zone === 'value2' || zone === 'lvalue' || zone === 'savalue';
        config.hideAggType = zone === 'slicer' || zone === 'lslicer' || zone === 'axisTime' || zone === 'details' || zone === 'saslicer';

        const dialogRef = this.modalService.open(ColumnFormattingTooltipComponent, { windowClass: 'column-formatter-popup' });
        dialogRef.componentInstance.parentData = config;

        if (['details', 'value'].indexOf(zone) !== -1) {
            const aggType = config.aggregationDropDownList?.find((type) => type.value === config.aggregationType);
            if (aggType) {
                config.aggregationType = aggType.value;
                config.displayName = `${config.showCustomName ? config.customName : config.label}`;
            }
        }

        dialogRef.result.then(
            (response: ConfigItem | 'cancel') => {
                if (response !== 'cancel') {
                    Object.assign(config, response);
                    const prefix = config.aggregationType ? `${config.aggregationType.split(/(?=[A-Z])/).join(' ')} of ` : '';
                    const name = config.showCustomName ? config.customName : config.label;
                    config.displayName = `${prefix}${name}`;
                    this.renderPreview();
                }
            },
            (dismissData) => dismissData,
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onCdkDropSuccess(data: CdkDragDrop<any[]>, zone: DropZoneConfig): void {
        if (data.previousContainer === data.container) {
            moveItemInArray(data.container.data, data.previousIndex, data.currentIndex);
        } else {
            if (zone.allowed) {
                this.updateConfigList(this.dataConfigs[zone.configKey], data.item.data, zone, data.currentIndex);
                if (zone.id !== 'tooltip') {
                    const ttpZone = this.dropZones?.find((item) => item.id === 'tooltip');
                    if (ttpZone?.allowed) {
                        this.updateConfigList(
                            this.dataConfigs[ttpZone.configKey],
                            data.item.data,
                            ttpZone,
                            this.dataConfigs[ttpZone.configKey].length);
                    }
                }
            }
        }
        this.activelyDragging = false;
        this.renderPreview();
    }

    removeConfigAtIndex(configKey: string, index: number): void {
        this.dataConfigs[configKey].splice(index, 1);
        this.checkValueSlicerAllowCondition();
        this.renderPreview();
    }

    setDataConfigs(configs: VizConfigs): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const configsAsAny = configs as any;
        for (const key in configsAsAny) {
            if (configsAsAny[key] && typeof configsAsAny[key] !== 'function') {
                const isAggEnabled = this.dropZones?.find((zone) => zone.configKey === key && zone.enableAggDropDown);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                configsAsAny[key].forEach((item: any) => {
                    const aggItems = this.getAggItems(item.displayType, isAggEnabled);
                    const selectedAgg = aggItems.some((agg) => agg.value === item.aggregationType);
                    const aggType = isAggEnabled && selectedAgg ?
                        `${aggItems.find((agg) => agg.value === item.aggregationType)?.label} of ` : '';
                    item.displayName = `${aggType}${item.showCustomName && item.customName || item.label}`;
                    item.aggregationDropDownList = aggItems;
                });
            }
        }
        this.dataConfigs = configs;
    }

    afterMetadataUpdate(): void {
        this.addDisplayTypeInDataConfig();
    }

    private addDisplayTypeInDataConfig(): void {
        Object.keys(this.dataConfigs).forEach((key) => {
            const columns = this.dataConfigs[key];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            columns.forEach((column: any) => {
                const columnMetaData = this.metadata.find((colMetadata) => colMetadata.value === column.value);
                column.displayType = columnMetaData?.displayType;
            });
        });
    }

    private checkValueSlicerAllowCondition(): void {
        if (this.visualizationType !== 'LINE_CHART') {
            return;
        }
        let slicerItem: DropZoneConfig | undefined;
        let valueItem: DropZoneConfig | undefined;
        const dzLength = this.dropZones?.length ?? 0;
        for (let i = 0; i < dzLength; i++) {
            if (!slicerItem && this.dropZones?.[i].id === 'lslicer') {
                slicerItem = this.dropZones[i];
            }
            if (!valueItem && this.dropZones?.[i].id === 'lvalue') {
                valueItem = this.dropZones[i];
            }
            if (slicerItem && valueItem) {
                break;
            }
        }
        const valuesLength = this.dataConfigs.values.length;
        slicerItem!.allowed = valuesLength < (valueItem!.maximumAllowed as number);
        valueItem!.allowed = this.dataConfigs.slicers.length === 0 || !valuesLength;
    }

    private updateConfigList(configList: ConfigItem[], metadata: FieldMetadata, zone: DropZoneConfig, index: number): void {
        const aggItems = this.getAggItems(metadata.displayType, zone);
        const defaultAggItem = aggItems.length ? aggItems[0] : undefined;

        const configItem: ConfigItem = {
            ...metadata,
            ...MetadataFormatUtils.getDefaultColFormatters(),
            label: metadata.displayName,
            value: metadata.value,
            displayName: zone.enableAggDropDown && defaultAggItem ?
                `${defaultAggItem.label} of ${metadata.displayName}` : metadata.displayName,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            aggregationType: zone.enableAggDropDown && defaultAggItem ? defaultAggItem.value : undefined as any,
            aggregationDropDownList: aggItems,
            colId: uid(),
        };
        this.dataConfigs[zone.configKey] = [...configList.slice(0, index), configItem, ...configList.slice(index)];
    }

    private getAggItems(displayType?: string, zone?: DropZoneConfig): LabelValuePair<AggType>[] {
        let chartType = ['PIE_CHART', 'DONUT_CHART'].includes(this.visualizationType ?? '') ? 'circle' : 'chart';
        chartType = zone && (zone.id === 'tooltip') ? 'tooltip' : chartType;
        return AGG_TYPES_BY_SECTION_AND_DISPLAY[chartType][displayType ?? ''];
    }
}
