import {
    CellPosition,
    ColDef,
    Column,
    ColumnEverythingChangedEvent,
    ColumnResizedEvent,
    ColumnState,
    DragStoppedEvent,
    FilterChangedEvent,
    IRowNode,
    SortChangedEvent,
} from '@ag-grid-community/core';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { AlertService } from '@ddv/common-components';
import { CrosstalkModalService, CrosstalkService } from '@ddv/crosstalk';
import {
    DataGridComponent,
    CellClickedEventModel,
    GridConfiguration,
    GridState,
    RowSelectionEventModel,
    CustomFilterChangedEvent,
} from '@ddv/data-grid';
import { DatasetManagerService, MetadataService } from '@ddv/datasets';
import { UserData, UserEntitlements, UserEntitlementService } from '@ddv/entitlements';
import { ClientDatasetFilterService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    LinkConfiguration,
    MODE,
    WIDGET_LIFECYCLE_EVENT,
    crosstalkCheckboxFieldId,
    CrosstalkFields,
    CompareColumnID,
    CompareMode,
    CustomColumnState,
    GridEventType,
    DdvDate,
    ExportFilteredData,
    FilterPreference,
    FuzzyDates,
    ConfigItem,
    AUTO_GROUP_COLUMN_ID,
    EDITABLE_CHECKBOX_FIELD,
    HS_TRADE_ID_FIELD,
    TFL_DETAILS_AUTO_GROUP_COLUMN_ID,
    TRANSACTION_TYPE_FIELD,
    AppWidgetState,
    getCurrentVisualizationId,
    DataUpdateBody,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    trebekRealTimeCommentFields,
    TrebekConversationFields,
    UserGridColumnOverrides,
    isTradeFileDetails,
    isTFLIncompleteFiles,
    CLEAR_FILTERS_TO_VIEW_ROWS_MESSAGE,
    NO_ROWS_TO_SHOW_MESSAGE,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { deepCompare, deepClone, hashValue } from '@ddv/utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { BaseVisualizationComponent } from '../base/base-visualization.component';
import { SelectedWidgetRelayService } from '../base/selected-widget-relay.service';
import { allFrameworkComponents } from './all-framework-components';
import { GridEvent } from './models/grid-event';
import { GridConfigService } from './services/grid-config.service';
import { UserGridColumnOverridesService } from './services/user-grid-column-overrides.service';

@Component({ template: '' })
export abstract class BaseGridVisualizationComponent extends BaseVisualizationComponent implements OnInit, OnDestroy {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allFrameworkComponents: Record<string, new (...args: any[]) => any> = allFrameworkComponents;
    isWidgetReadOnly = false;
    gridConfiguration: GridConfiguration | undefined;
    showGrid = false;
    showRowFilter = false;
    isMousedownInside = false;
    isGridReadyObs: Observable<boolean> | undefined;
    rowSearchText = '';
    visualizationId = 0;
    isTFLDetails = false;
    isTFLIncompleteFiles = false;
    isInViewMode = false;
    isCrosstalkGrid = false;

    @Output() gridStateUpdated = new EventEmitter<GridEvent>();
    @Output() cellClicked = new EventEmitter<CellClickedEventModel>();
    @Output() detailWidgetClicked = new EventEmitter<{ detailWidgetId: number, clientCode: string }>();

    protected gridState: GridState | undefined;
    protected isGridReady = false;
    protected distinctRowsOnly = false;
    protected widgetFilters: FilterPreference | undefined;
    protected conversableType: string | undefined;
    protected tempColumnOrder: string[] = [];
    protected localConfigName = '';
    protected widgetPrefs: AppWidgetState | undefined;
    protected initialColumnState: CustomColumnState[] = [];
    protected detailWidgetOpened = false;
    protected userEntitlements: UserEntitlements | undefined;
    @ViewChild(DataGridComponent, { static: false }) protected dataGridComponent: DataGridComponent | undefined;

    private isDataLoading = false;
    private readonly isGridReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private originalData: any[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private lastComparedData: any | undefined;
    private savedFiltersAndSortsApplied = false;
    private fuzzyDates: FuzzyDates | undefined;
    private udfColumnNames: string[] | undefined;
    private isInitialLoad = false;
    private isOverridingInProcess = false;

    constructor(
        protected readonly alertService: AlertService,
        protected clientDatasetFilterService: ClientDatasetFilterService,
        protected elementRef: ElementRef,
        protected crosstalkModalService: CrosstalkModalService, // This service launches ALL modals, not just crosstalk
        protected gridConfigService: GridConfigService,
        metadataService: MetadataService,
        protected crosstalkService: CrosstalkService,
        protected fuzzyDatesService: FuzzyDatesService,
        protected userEntitlementService: UserEntitlementService,
        protected userGridColumnOverridesService: UserGridColumnOverridesService,
        protected readonly currentStateService: CurrentStateService,
        protected readonly manager: ManagerService,
        private readonly selectedWidgetRelayService: SelectedWidgetRelayService,
        protected datasetManagerService: DatasetManagerService,
    ) {
        super(metadataService);

        this.isGridReadyObs = this.isGridReadySubject.asObservable();
        this.showRowFilter = true;
        this.distinctRowsOnly = false;
    }

    override ngOnInit(): void {
        this.isInitialLoad = true;
        super.ngOnInit();
        this.isChartInitialized = false;

        this.subscribeTo(this.fuzzyDatesService.fuzzyDates(), (fuzzyDates: FuzzyDates) => this.fuzzyDates = fuzzyDates);

        this.subscribeTo(
            this.userEntitlementService.entitlementsForClientCode$,
            (entitlements) => {
                this.userEntitlements = entitlements;
            });

        // yes the entitlements are also subscribe to above
        // however, there are now multiple things that need them, and
        // this.selectedWidgetRelayService.isWidgetGlobal does not emit in all the cases where the user entitlements are needed
        // subscribing twice is just easier/cleaner? than stashing isWidgetGlobal at the class level
        this.subscribeTo(
            combineLatest([
                this.userEntitlementService.entitlementsForClientCode$,
                this.selectedWidgetRelayService.isWidgetGlobal,
            ]),
            ([entitlements, isWidgetGlobal]) => {
                this.userEntitlements = entitlements;
                this.isWidgetReadOnly = this.isManageWidgetMode() && entitlements.haveGlobalEditPartial && isWidgetGlobal;
            });

        this.widgetPrefs = this.getWidgetPreferences() ?? undefined;
        const queryTypeName = this.getQueryTypeName();
        this.isTFLDetails = isTradeFileDetails(queryTypeName);
        this.isTFLIncompleteFiles = isTFLIncompleteFiles(queryTypeName);
        this.conversableType = this.getConversableType();

        this.subscribeTo(this.crosstalkService.userDefinedFields, (udfs) => {
            this.udfColumnNames = udfs.get(this.conversableType ?? '')?.map((udf) => `udf_${udf.name}`);
        });

        this.subscribeTo(this.selectedWidgetRelayService.clearAllFilters$, ({ id, clearAllFilters }) => {
            if (this.dataGridComponent && id === this.widgetId && clearAllFilters) {
                this.dataGridComponent.clearAllFilters();

                if (!this.isManageWidgetMode() && this.isInViewMode) {
                    const nonDynamicColumns = this.filterOutDynamicColumns();
                    const columns = this.dataGridComponent.getAllColumns().filter((c) => nonDynamicColumns.includes(c.getColId()));
                    this.userGridColumnOverridesService.updateColumnFilter(id, {}, columns, this.initialColumnState, true);
                }
            }
        });

        this.subscribeTo(this.userEntitlementService.userData$, (data: UserData) => {
            this.localConfigName = `config::${data.userName}::${this.manager.getCurrentDashboardId()}::${this.widgetId}`;
        });

        this.visualizationId = getCurrentVisualizationId(this.widgetPrefs) ?? 0;
        this.isCrosstalkGrid = this.isAdvancedGrid() && !!this.conversableType;

        this.subscribeTo(this.currentStateService.dashboardModeAndId$, ({ mode }) => {
            this.isInViewMode = !this.isManageWidgetMode() && mode === MODE.VIEW;

            if (this.isInViewMode) {
                this.initialColumnState.forEach((state) => state.agFilterOnViewEditMode = null);
            }

            this.setGridColumnOverrides();
        });

        this.subscribeTo(this.manager.isStackedQuery(this.datasetId), (isStackedQuery) => this.isStackedQuery = isStackedQuery);
    }

    onMetadataUpdate(): void {
        if (this.showGrid) {
            return;
        }
        this.setupGrid();
    }

    override onDynamicColumnsUpdate(): void {
        if (this.showGrid && this.preferences) {
            this.gridConfiguration = this.gridConfigService.getGridConfiguration(this.preferences, this.widgetId, this.datasetId);
            setTimeout(() => this.setGridData(), 0);
        }
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.isGridReady = false;
        this.isChartInitialized = false;
        this.showGrid = false;
    }

    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                if (this.dataGridComponent && this.isTFLDetails) {
                    this.dataGridComponent.horizontallyScrollToTheBeginning();
                }
                this.updateDataSource(data as DataUpdateBody);
                this.isDataLoading = false;
                break;
            case WIDGET_LIFECYCLE_EVENT.LOADING_DATA:
                this.isDataLoading = true;
                if (this.isGridReady && this.dataGridComponent) {
                    this.dataGridComponent.hideOverlay();
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.VIEW_RESTORED:
                if (this.isInViewMode) {
                    this.restoreInitialColumnState();
                }

                this.dataGridComponent?.refreshHeader();
                break;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    widgetLifeCyclePostProcess(_eventName: WIDGET_LIFECYCLE_EVENT, _data: unknown): void {}

    onGridReady(): void {
        this.isGridReady = true;
        this.initColumnState();
        this.initGridColumnOverrides();
        this.onReady();
        if (this.isDataLoading && this.gridConfiguration?.gridOptions) {
            this.dataGridComponent?.hideOverlay();
        }
    }

    onReady(): void {
        this.setGridData();
    }

    setStateFromColumnConfigs(): void {
        this.gridState = this.getGridState();
        this.gridConfigService.initializeGridFiltersAndSorts(this.preferences?.configs?.values, this.gridState);
        this.setGroupSortFromConfig();
        this.fixSortIndicesAfterSortUpdate();
        this.setGridState(this.gridState);
    }

    protected fixSortIndicesAfterSortUpdate(): void {
        if (!this.gridState?.sortState.length) {
            return;
        }

        const sortState = this.gridState.sortState;
        sortState
            .sort((a, b) => a.sortIndex! - b.sortIndex!)
            .forEach((s, index) => s.sortIndex = index);
    }

    setGroupSortFromConfig(): void {
        if (this.isAdvancedGrid() && this.preferences?.groupSortIndex != null && this.gridState) {
            this.gridConfigService.initializeGroupSort(this.preferences.groupSort, this.preferences.groupSortIndex, this.gridState);
        }
    }

    updateDataSource(componentData: DataUpdateBody): void {
        this.widgetFilters = componentData.widgetPrefs ? componentData.widgetPrefs.widgetFilters : undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let modifiedData: any[] = [];

        if (this.widgetFilters && !this.widgetFilters.isComparing || !this.originalData) {
            this.originalData = deepClone(componentData.data);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let hashOriginalData: any[] = [];
            let newDataHashes: string[] = [];
            let originalDataHashOrder: string[] = [];

            if (componentData.compareMode === CompareMode.COMPARED) {
                this.lastComparedData = deepClone(componentData.compareData);

                [originalDataHashOrder, hashOriginalData] = this.hashOriginalData(componentData.filters?.activeDate);

                [newDataHashes, hashOriginalData, originalDataHashOrder] = this.mergeCompareData(
                    componentData.compareData ?? [],
                    hashOriginalData,
                    originalDataHashOrder);

                hashOriginalData = this.normalizeEmptyFields(hashOriginalData, newDataHashes);
            } else if (componentData.compareMode === CompareMode.ORIGINAL ||
                !componentData.compareMode && componentData.filters?.comparing === CompareMode.ORIGINAL) {
                this.originalData = deepClone(componentData.data);

                [originalDataHashOrder, hashOriginalData] = this.hashOriginalData(componentData.filters?.activeDate);

                [newDataHashes, hashOriginalData, originalDataHashOrder] =
                    this.mergeCompareData(this.lastComparedData || [], hashOriginalData, originalDataHashOrder);

                hashOriginalData = this.normalizeEmptyFields(hashOriginalData, newDataHashes);
            }

            // this is nonsense.  we using a string to index an array, thus the casts to any
            modifiedData = originalDataHashOrder.length ?
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                originalDataHashOrder.map((hash) => (hashOriginalData as any)[hash]) :
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                newDataHashes.map((hash) => (hashOriginalData as any)[hash]);
        }

        this.vizData = modifiedData.length ? modifiedData.map((item) => ({ ...item })) : componentData.data;

        if (componentData.filters?.activeDate && this.widgetFilters && !this.widgetFilters.isComparing && !this.isStackedQuery) {
            this.vizData = this.clientDatasetFilterService.filterData(this.vizData, { activeDate: componentData.filters.activeDate });
        }

        if (this.distinctRowsOnly) {
            this.vizData = this.clientDatasetFilterService.removeDuplicateRows(
                this.vizData,
                this.gridConfiguration?.columnDefinitions.map((column: ColDef) => column.field!));
        }

        this.setLocaleTextMessage();

        if (this.isGridReady) {
            this.setGridData(componentData.widgetPrefs?.hideLoaderAfterFirstDataLoad);
        }
    }

    getExportFilteredData(isCsv = false, onlySelectedRows = false): ExportFilteredData {
        return {
            data: this.gridConfigService.getExportFilteredData(this.dataGridComponent, this.preferences, isCsv, onlySelectedRows),
            summary: {},
        };
    }

    getExportFilteredDataForCSV(): ExportFilteredData {
        const onlySelected = !!this.getSelectedNodes().length;

        return {
            data: this.gridConfigService.getExportFilteredData(this.dataGridComponent, this.preferences, true, onlySelected),
            summary: {},
        };
    }

    getExportFilteredDataGroupers(): string[] {
        return this.gridConfigService.getExportFilteredDataGroupers(this.dataGridComponent);
    }

    getFilteredExportVisibleColumns(): string[] {
        return this.gridConfigService.getFilteredExportVisibleColumns(this.dataGridComponent);
    }

    getExportFullData(): ExportFilteredData {
        return { data: this.gridConfigService.getExportFullData(this.dataGridComponent), summary: {} };
    }

    onFilterChanged(params: FilterChangedEvent): void {
        this.setGridAggregateRow();
        this.gridConfigService.updateColumnFilterFromState(this.widgetId, this.getGridState(), this.preferences);
        this.setColumnsWidthOnFilterChanged(params);

        if (this.shouldUpdateColumnFilter(params)) {
            const nonDynamicColumns = this.filterOutDynamicColumns();
            const columns = params.api.getColumns()?.filter((c) => nonDynamicColumns.includes(c.getColId())) ?? [];
            const filterModel = params.api.getFilterModel();
            this.userGridColumnOverridesService.updateColumnFilter(this.widgetId, filterModel, columns, this.initialColumnState);
        }

        if (this.isManageWidgetMode() || (!this.isOverridingInProcess && !this.isInViewMode)) {
            if (this.initialColumnState.length) {
                this.updateInitialColumnStateFiltersOnFilterChanged(params);
            }
            if (this.isManageWidgetMode() || params.source === 'columnFilter') {
                this.gridStateUpdated.emit(this.getGridStateEvent('FILTER'));
            }
        }

        const rowCount = this.dataGridComponent?.grid.api?.getDisplayedRowCount();

        if (rowCount === 0 && this.vizData.length !== 0 && this.dataGridComponent?.grid?.localeText) {
            this.dataGridComponent.grid.localeText.noRowsToShow = CLEAR_FILTERS_TO_VIEW_ROWS_MESSAGE;
            this.dataGridComponent?.showNoRowsOverlay();
        } else if (rowCount !== 0) {
            this.dataGridComponent?.hideOverlay();
        }
    }

    setupGrid(): void {
        if (!this.preferences) {
            return console.error('cannot setupGrid without a preferences');
        }

        if (this.preferences.configs) {
            this.preferences.configs.values = this.gridConfigService.getUpdatedColumnConfigs(this.preferences, this.metadata);
        }
        this.gridConfiguration = this.gridConfigService.getGridConfiguration(this.preferences, this.widgetId, this.datasetId);

        if (this.isAdvancedGrid()) {
            this.gridConfiguration.gridOptions.groupDefaultExpanded = this.preferences.groupDefaultExpanded;
        }

        this.showRowFilter = !!this.gridConfiguration.showQuickSearch;
        this.distinctRowsOnly = !!this.gridConfiguration.distinctRowsOnly;

        this.gridConfiguration.gridOptions.onFilterChanged = (event: FilterChangedEvent): void => {
            if (this.isGridReady && this.savedFiltersAndSortsApplied) {
                this.onFilterChanged(event);
            }
        };

        this.gridConfiguration.gridOptions.onSortChanged = (event: SortChangedEvent): void => {
            if (this.isGridReady && this.savedFiltersAndSortsApplied) {
                this.onSortChanged(event);
            }
        };

        this.gridConfiguration.gridOptions.onColumnResized = (event: ColumnResizedEvent): void => {
            this.gridConfigService.columnResizeFormatter(this.elementRef, event);
            if (Object.prototype.hasOwnProperty.call(event, 'finished')) {
                if (this.shouldUpdateColumnWidthOverride(event)) {
                    this.userGridColumnOverridesService.updateColumnWidth(this.widgetId, event);
                }

                this.gridStateUpdated.emit(this.getGridStateEvent('COLUMNS'));
            }
        };

        this.showGrid = true;
    }

    conditionallyFollowLink(event: CellClickedEventModel): void {
        if (!this.preferences?.showHyperlinks) {
            return;
        }

        const column = this.preferences.configs?.values.find((value) => value?.colId === event.column?.colId);
        if (column?.linkType === 'ddvwidget') {
            const detailWidgetId = column.ddvWidget ?? 0;
            const clientCode = event.rowData?.clientCode;
            this.detailWidgetClicked.next({ detailWidgetId, clientCode });
            this.detailWidgetOpened = true;
            return;
        }

        const columnId = event.column.colDef.field;
        if (trebekRealTimeCommentFields.some((c) => c === columnId)) {
            return;
        }

        if (!this.gridConfigService.columnIsExternallyLinked(columnId, event.rowData)) {
            return;
        }

        const linkConfiguration: LinkConfiguration = this.gridConfigService.getExternalLinkConfiguration(columnId, event.rowData);
        if (!this.crosstalkModalService.linkAsAModal(linkConfiguration)) {
            return;
        }

        const skipBuildUrl = linkConfiguration.uri.includes('api-support-tool') ||
            linkConfiguration.uri.startsWith('http') ||
            linkConfiguration.uri.startsWith('//');
        this.crosstalkModalService.openModal(linkConfiguration, event.event, skipBuildUrl);
    }

    onRowSelected(event: RowSelectionEventModel): void {
        const columnDef = event.columnDefs?.[0];
        if (columnDef?.field) {
            this.handleNonLinkCellClick(columnDef, event.selectedRowData[columnDef.field]);
        }
    }

    onRowUnselected(event: RowSelectionEventModel): void {
        const columnDef = event.columnDefs?.[0];
        if (columnDef?.field) {
            this.handleNonLinkCellClick(columnDef, event.selectedRowData[columnDef?.field]);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    handleNonLinkCellClick(colDef: ColDef, value: any): void {
        if (!this.isMaster || !this.isMousedownInside) {
            return;
        }

        const selectedValues: string[] = this.getSelectedNodes().map((row) => row.data[colDef.field!]);

        this.valueClickedInMaster.emit({
            property: colDef.field!,
            name: colDef.headerName!,
            value,
            selectedValues: [...new Set(selectedValues)],
        });
    }

    setGridState(state?: GridState): void {
        if (state) {
            this.dataGridComponent?.setState(state);
        }
    }

    getGridState(): GridState | undefined {
        return this.dataGridComponent?.getState();
    }

    getGridStateEvent(event: GridEventType, triggeredByUser?: boolean): GridEvent {
        this.gridState = this.getGridState();
        return {
            event,
            configs: this.preferences?.configs?.values ?? [],
            gridState: this.gridState!,
            triggeredByUser: triggeredByUser ?? (this.isGridReady && this.isChartInitialized),
        };
    }

    onQuickFilterChanged(searchText: string): void {
        this.rowSearchText = searchText;
        if (this.dataGridComponent) {
            this.dataGridComponent.setQuickFilter(this.rowSearchText);
        }
    }

    deselectAllSelected(): void {
        this.isMousedownInside = false;
        this.dataGridComponent?.deselectAllSelected();
    }

    areFiltersApplied(): boolean {
        const filters = this.dataGridComponent?.getFilterModel();
        return !!(filters && Object.keys(filters).length);
    }

    setReorderedColumns(columnIds: string[]): void {
        let reorderedColumns: ConfigItem[] = [];

        const groupIdx = columnIds.indexOf(AUTO_GROUP_COLUMN_ID);
        if (groupIdx !== -1) {
            columnIds.splice(groupIdx, 1);
        }

        this.preferences?.configs?.values.forEach((value) => {
            const idx = columnIds.indexOf(value.colId);
            if (idx !== -1) {
                reorderedColumns[idx] = value;
            }
        });

        reorderedColumns = reorderedColumns.filter((column) => column);

        if (this.preferences?.configs) {
            this.preferences.configs.values = reorderedColumns;
        }
    }

    onDragStopped(event: DragStoppedEvent, columns: ColumnState[] = []): void {
        const columnIds: string[] = columns.map((c) => c.colId);
        const tempColumnOrder = [...this.tempColumnOrder];
        if (this.tempColumnOrder.length) {
            if (tempColumnOrder.indexOf(EDITABLE_CHECKBOX_FIELD) !== -1) {
                tempColumnOrder.splice(0, 1);
            }

            if (tempColumnOrder.indexOf(crosstalkCheckboxFieldId) !== -1) {
                const columnDefs: ColumnState[] = event.api.getColumnState();
                const checkboxColumn = columnDefs.find((col) => col.colId === crosstalkCheckboxFieldId);
                const tempCheckboxColumnIndex = tempColumnOrder.findIndex((colId) => colId === crosstalkCheckboxFieldId);
                if (checkboxColumn?.pinned) {
                    tempColumnOrder.splice(tempCheckboxColumnIndex, 1);
                }
            }

            const groupColumnIndex = tempColumnOrder.findIndex((column) => column === AUTO_GROUP_COLUMN_ID);
            if (groupColumnIndex !== -1) {
                tempColumnOrder.splice(groupColumnIndex, 1);
            }

            this.userGridColumnOverridesService.updateColumnsOrder(this.widgetId, tempColumnOrder);
            this.tempColumnOrder = [];

            this.setReorderedColumns(columnIds);

            this.dataGridComponent?.refreshHeader();
        }
    }

    onColumnEverythingChanged(event: ColumnEverythingChangedEvent): void {
        if (event?.source === 'columnMenu') {
            this.restoreInitialColumnState();
        }
    }

    protected getConversableType(): string | undefined {
        return this.getWidgetPreferences()?.datasetDefinition?.conversableType;
    }

    protected isMasterWidget(): boolean {
        return !!this.getWidgetPreferences()?.isMaster;
    }

    protected getWidgetPreferences(): AppWidgetState | undefined | null {
        return this.manager.getWidgetPreferences(this.widgetId);
    }

    protected getEditingCells(): CellPosition[] {
        return this.dataGridComponent?.getEditingCells() ?? [];
    }

    protected getSelectedNodes(): IRowNode[] {
        return this.dataGridComponent?.getSelectedNodes() ?? [];
    }

    protected getColumnWidth(overrides: UserGridColumnOverrides[], colId: string): number | null | undefined {
        return this.userGridColumnOverridesService.getColumnWidth(
            overrides,
            colId,
            this.isInViewMode,
            this.initialColumnState,
            !!this.gridConfiguration?.autoSizeColumns);
    }

    protected getGroupColumnWidth(overrides: UserGridColumnOverrides[], groupColumns: Column[]): number | null | undefined {
        return this.userGridColumnOverridesService.getGroupColumnWidth(
            overrides,
            this.isInViewMode,
            this.initialColumnState,
            groupColumns,
            !!this.gridConfiguration?.autoSizeColumns);
    }

    protected onSortChanged(event: SortChangedEvent): void {
        this.gridConfigService.updateColumnSortFromState(this.widgetId, this.getGridState(), this.preferences);
        if (this.shouldUpdateColumnSort()) {
            const nonDynamicColumns = this.filterOutDynamicColumns();
            const columns = event.api.getColumnState()
                .filter((c) => nonDynamicColumns.includes(c.colId) || c.colId === AUTO_GROUP_COLUMN_ID);
            this.userGridColumnOverridesService.updateColumnSort(this.widgetId, columns);
        }
        if (this.isManageWidgetMode() || (!this.isOverridingInProcess && !this.isInViewMode)) {
            this.gridStateUpdated.emit(this.getGridStateEvent('SORT'));
        }
    }

    protected setGridData(isRealtimeUpdate = false): void {
        if (!this.vizData) {
            return;
        }

        this.isChartInitialized = false;
        this.gridConfigService.updateDataset(this.preferences?.configs?.values ?? [], this.datasetId, this.vizData);
        let data = deepClone(this.vizData);

        if (this.shouldSortGridDataForTFLDetails()) {
            data = this.sortGridDataForTFLDetails(data);
        }

        if (this.metadata && this.widgetPrefs?.currentVisualization === 'ADVANCED_GRID') {
            this.remapUDFsFromTrebekToData(data);
        }

        this.dataGridComponent?.setRowData(data);
        this.setGridAggregateRow();

        if (!this.savedFiltersAndSortsApplied) {
            this.setStateFromColumnConfigs();
            this.savedFiltersAndSortsApplied = true;
        }

        if (!this.isCrosstalkGrid && !this.isDetailWidget()) {
            this.setColumnFilterAndSort();
        }

        if (!isRealtimeUpdate) {
            this.dataGridComponent?.autoSizeColumnsBasedOnConfig();
            if ((this.isTFLDetails || this.isTFLIncompleteFiles) && this.isAdvancedGrid()) {
                this.deselectAllSelected();
            }
        }

        // Callbacks to update columns are still being called from ag-grid after data is
        // set, but we don't want this to trigger a dirty flag
        setTimeout(() => {
            this.isChartInitialized = true;
            this.isGridReadySubject.next(true);
            this.isInitialLoad = false;
        }, 1000);
    }

    protected setColumnFilterAndSort(): void {
        if (!this.dataGridComponent?.grid?.api || !this.widgetPrefs) {
            return;
        }

        const gridState = this.getGridState();

        if (gridState?.columnState) {
            if (this.isManageWidgetMode()) {
                this.setColumnFilterForManageWidgetMode();
            } else {
                const overrides = this.userGridColumnOverridesService.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
                this.isOverridingInProcess = true;

                this.userGridColumnOverridesService.setColumnsSort(
                    overrides,
                    this.isInViewMode,
                    gridState.columnState,
                    this.initialColumnState,
                    this.dataGridComponent);

                this.userGridColumnOverridesService.setColumnsFilterModel(
                    overrides,
                    this.isInViewMode,
                    this.initialColumnState,
                    this.dataGridComponent);

                setTimeout(() => this.isOverridingInProcess = false, 150);
            }
        }
    }

    protected setGridAggregateRow(): void {
        if (this.preferences?.showGrandTotal) {
            if (this.preferences?.showTotalAtTop) {
                this.dataGridComponent?.setHeaderGrandTotal();
            } else {
                this.dataGridComponent?.setFooterGrandTotal();
            }
        }
    }

    protected setCheckboxColumnPosition(columns: ConfigItem[] | ColDef[], columnType: string): void {
        this.userGridColumnOverridesService.setCheckboxColumnPosition(
            this.widgetId,
            this.visualizationId,
            columns,
            columnType,
            this.isInViewMode,
        );
    }

    protected isGroupedByAllocationGroupId(): boolean {
        const groupColumns = this.dataGridComponent?.getRowGroupColumns() ?? [];
        return groupColumns.length === 1 && groupColumns[0].getColDef().field === TFL_DETAILS_AUTO_GROUP_COLUMN_ID;
    }

    protected filterOutDynamicColumns(): string[] {
        return this.preferences?.configs?.values.filter((c) => !c.isDynamic).map((c) => c.colId) ?? [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private remapUDFsFromTrebekToData(data: { [key: string]: any }[]): void {
        data.forEach((datum) => {
            const udfKeys = Object.keys(datum).filter((key) => key.startsWith('udf_'));
            const udfMetadata = Object.values(this.metadata!).filter((metadataItem) => metadataItem.name.startsWith('udf_'));

            udfKeys.forEach((udfKey) => {
                const udfField = udfMetadata.find((udf) => udf.name === udfKey);

                if (udfField) {
                    datum[udfKey] = {
                        name: udfField.displayName,
                        type: udfField.datatype,
                        value: datum[udfKey],
                    };
                }
            });
        });
    }

    private shouldSortGridDataForTFLDetails(): boolean {
        const hsTradeIdColumn = this.dataGridComponent?.getAllColumns().find((c) => c.getColDef().field === HS_TRADE_ID_FIELD);
        return this.isAdvancedGrid() && this.isTFLDetails && !!hsTradeIdColumn && this.isGroupedByAllocationGroupId();
    }

    private setLocaleTextMessage(): void {
        if (!this.dataGridComponent?.grid?.localeText) {
            return;
        }

        if (this.vizData?.length === 0 && (this.datasetManagerService?.getSourceDataCopy()?.length ?? 0) > 0) {
            this.dataGridComponent.grid.localeText.noRowsToShow = CLEAR_FILTERS_TO_VIEW_ROWS_MESSAGE;
        } else {
            this.dataGridComponent.grid.localeText.noRowsToShow = NO_ROWS_TO_SHOW_MESSAGE;
        }
        this.dataGridComponent.hideOverlay();
        this.dataGridComponent.showNoRowsOverlay();
    }

    private setColumnFilterForManageWidgetMode(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const stringFiltersModel = this.initialColumnState.reduce((filter: { [key: string]: any }, column) => {
            const { agFilterOnViewEditMode, agFilter, colId } = column;

            if (!agFilterOnViewEditMode && agFilter?.includedStrings) {
                agFilter.filterType = 'set';
                agFilter.values = agFilter.values ?? JSON.parse(agFilter.includedStrings);
                filter[colId] = agFilter;
            }

            return filter;
        }, {});

        if (Object.keys(stringFiltersModel).length) {
            const filterModel = this.dataGridComponent!.getFilterModel();
            setTimeout(() => this.dataGridComponent!.setFilterModel({ ...filterModel, ...stringFiltersModel }), 50);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private sortGridDataForTFLDetails(data: any[]): any[] {
        const nonAllocations = data.filter((datum) => !datum[TFL_DETAILS_AUTO_GROUP_COLUMN_ID]).sort((a, b) => {
            return a[HS_TRADE_ID_FIELD] - b[HS_TRADE_ID_FIELD];
        });
        const allocations = data.filter((datum) => datum[TFL_DETAILS_AUTO_GROUP_COLUMN_ID]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const allocationGroupsMap: Map<string, any[]> = new Map();
        allocations.forEach((allocation) => {
            const allocationRows = allocationGroupsMap.get(allocation[TFL_DETAILS_AUTO_GROUP_COLUMN_ID]);
            if (!allocationRows) {
                allocationGroupsMap.set(allocation[TFL_DETAILS_AUTO_GROUP_COLUMN_ID], [allocation]);
            } else {
                allocationRows.push(allocation);
            }
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const sortedAllocations: any[] = [];
        allocationGroupsMap.forEach((group) => {
            const block = group.splice(group.findIndex((item) => item[TRANSACTION_TYPE_FIELD]), 1)[0];
            group.sort((a, b) => a[HS_TRADE_ID_FIELD] - b[HS_TRADE_ID_FIELD]);
            group.unshift(block);
            sortedAllocations.push(...group);
        });

        return [...sortedAllocations, ...nonAllocations];
    }

    private initColumnState(): void {
        if (!this.initialColumnState.length) {
            const columns = this.preferences?.configs?.values.map((c) => ({ ...c })) ?? [];
            this.initialColumnState = (this.getGridState()?.columnState as CustomColumnState[])
                ?.map((state) => {
                    const initialWidgetState = columns.find((widget) => widget.colId === state.colId);
                    state.sort = initialWidgetState?.agSort?.sort;
                    state.sortIndex = initialWidgetState?.agSortIndex;
                    state.agFilter = initialWidgetState?.agFilter;

                    if (state.colId === AUTO_GROUP_COLUMN_ID) {
                        state.sort = this.preferences?.groupSort;
                        state.sortIndex = this.preferences?.groupSortIndex;

                        // we don't really care which column from the group we'll take
                        // as in manage widgets all of the columns that are included in the group
                        // are saved with the same customWidthWhenGroup
                        const customWidthWhenGroup = this.preferences?.configs?.values
                            .find((v) => v.rowGroupIndex != null)?.customWidthWhenGroup;
                        state.width = this.preferences?.autoSizeColumns ?
                            undefined :
                            (customWidthWhenGroup == null ? undefined : customWidthWhenGroup);
                    }

                    return state;
                }) || [];
        }
    }

    private setOverridedGridState(columns: ConfigItem[], gridState: GridState): void {
        const columnIds = columns.map((c) => c.colId);
        const initialColumnIds = this.initialColumnState.map((c) => c.colId);

        if (!deepCompare(columnIds, initialColumnIds) || !this.isInViewMode || this.isAdvancedGrid()) {
            const updatedColumns = this.gridConfigService.orderColumnsInColumnState([...gridState.columnState], columns);
            gridState.columnState = [...updatedColumns];
            this.setGridState(gridState);
        }
    }

    // Some of the overrides need to be set before the grid is filled with data
    // that is why this method exists
    // tried to set them in getGridColumn() in grid config service
    // but that way we screw the initialColumnState
    private initGridColumnOverrides(): void {
        if (this.isManageWidgetMode() ||
            !this.dataGridComponent?.grid?.api ||
            !this.widgetPrefs) {
            return;
        }

        let columns = this.preferences?.configs?.values ?? [];
        const gridState = this.getGridState();

        if (gridState?.columnState) {
            const overrides = this.userGridColumnOverridesService.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
            this.isOverridingInProcess = true;
            const isInPivotMode = this.dataGridComponent.isPivotMode();

            columns = this.getColumnsOrder(overrides, columns);

            if (this.isAdvancedGrid()) {
                columns = this.getGroupColumnsOrder(overrides, columns);
            }

            columns.forEach((column) => {
                const visible = this.getColumnVisibility(overrides, column.colId);
                this.dataGridComponent?.setColumnVisible(column.colId, visible);
                column.isHidden = !visible;

                if (column.colId !== AUTO_GROUP_COLUMN_ID) {
                    this.setColumnWidth(column, overrides);
                }

                if (isInPivotMode && column.canAggregate && !column.canPivotOn) {
                    this.setColumnAggFuncInPivotMode(overrides, column, columns);
                }
            });

            if (this.isAdvancedGrid()) {
                this.manager.sendMessageToExistingWidget(this.widgetId, WIDGET_LIFECYCLE_EVENT.GROUP_COLUMNS_ORDER_CHANGED);
            }
            this.setOverridedGridState(columns, gridState);

            const isGrouped = this.dataGridComponent.isRowGroupingOn();
            if (isGrouped) {
                const groupColumns = this.dataGridComponent.getRowGroupColumns() ?? [];
                const groupWidth = this.getGroupColumnWidth(overrides, groupColumns)!;
                this.dataGridComponent.setColumnWidth(AUTO_GROUP_COLUMN_ID, groupWidth);
            }

            this.dataGridComponent.moveColumn(AUTO_GROUP_COLUMN_ID, 0);
        }
    }

    private restoreInitialColumnState(): void {
        this.userGridColumnOverridesService.deleteGridColumnOverrides(this.widgetId, this.initialColumnState).subscribe(() => {
            this.setColumnFilterAndSort();
            this.dataGridComponent?.applyColumnState({ state: this.initialColumnState, applyOrder: true });
        });
    }

    private setGridColumnOverrides(): void {
        if (this.isManageWidgetMode() ||
            !this.dataGridComponent?.grid?.api ||
            this.isInCompareMode() ||
            !this.vizData
        ) {
            return;
        }

        let columns = this.preferences?.configs?.values ?? [];
        const gridState = this.getGridState();

        if (gridState?.columnState) {
            const overrides = this.userGridColumnOverridesService.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
            this.isOverridingInProcess = true;
            const isInPivotMode = this.dataGridComponent.isPivotMode();

            columns = this.getColumnsOrder(overrides, columns);

            if (this.isAdvancedGrid()) {
                columns = this.getGroupColumnsOrder(overrides, columns);
            }

            if (this.isCrosstalkGrid) {
                this.setCheckboxColumnPosition(columns, 'ConfigItem');
            }

            columns.forEach((column) => {
                const visible = this.getColumnVisibility(overrides, column.colId);
                this.dataGridComponent?.setColumnVisible(column.colId, visible);
                column.isHidden = !visible;

                if (column.colId !== AUTO_GROUP_COLUMN_ID) {
                    this.setColumnWidth(column, overrides);
                }

                if (isInPivotMode && column.canAggregate && !column.canPivotOn) {
                    this.setColumnAggFuncInPivotMode(overrides, column, columns);
                }
            });

            if (this.isAdvancedGrid()) {
                this.manager.sendMessageToExistingWidget(this.widgetId, WIDGET_LIFECYCLE_EVENT.GROUP_COLUMNS_ORDER_CHANGED);
            }
            this.setOverridedGridState(columns, gridState);

            const isGrouped = this.dataGridComponent.isRowGroupingOn();
            if (isGrouped) {
                const groupColumns = this.dataGridComponent.getRowGroupColumns() ?? [];
                const groupWidth = this.getGroupColumnWidth(overrides, groupColumns)!;
                this.dataGridComponent.setColumnWidth(AUTO_GROUP_COLUMN_ID, groupWidth);
            }

            this.userGridColumnOverridesService.setColumnsSort(
                overrides,
                this.isInViewMode,
                gridState.columnState,
                this.initialColumnState,
                this.dataGridComponent);

            this.userGridColumnOverridesService.setColumnsFilterModel(
                overrides,
                this.isInViewMode,
                this.initialColumnState,
                this.dataGridComponent);

            this.dataGridComponent.moveColumn(AUTO_GROUP_COLUMN_ID, 0);

            setTimeout(() => this.isOverridingInProcess = false, 150);
        }
    }

    private setColumnWidth(column: ConfigItem, overrides: UserGridColumnOverrides[]): void {
        const width = this.getColumnWidth(overrides, column.colId);
        this.dataGridComponent?.setColumnWidth(column.colId, width!);
        column.width = width!;
    }

    private shouldUpdateColumnWidthOverride(event: ColumnResizedEvent): boolean {
        if (this.isManageWidgetMode() || !this.isInViewMode || !event.finished || event.source !== 'uiColumnResized') {
            return false;
        }

        return !this.isDetailWidget() && !this.isInCompareMode() && !this.isDynamicColumn(event.column?.getColDef().colId);
    }

    private shouldUpdateColumnSort(): boolean {
        return this.isInViewMode &&
            !this.isManageWidgetMode() &&
            !this.isInitialLoad &&
            !this.isOverridingInProcess &&
            !this.isInCompareMode();
    }

    private shouldUpdateColumnFilter(params: CustomFilterChangedEvent): boolean {
        if (!this.isInViewMode || this.isManageWidgetMode() || this.isInCompareMode()) {
            return false;
        }

        if (params.source === 'api') {
            return false;
        }

        if (params.source === 'columnFilter' && params.columns[0].getColDef().filter === 'customColumnFilterComponent' && !params.isCustomFilter) {
            return false;
        }

        return true;
    }

    private setColumnAggFuncInPivotMode(overrides: UserGridColumnOverrides[], column: ConfigItem, columns: ConfigItem[]): void {
        if (this.dataGridComponent) {
            this.userGridColumnOverridesService.setColumnAggFuncInPivotMode(
                overrides,
                this.initialColumnState,
                column,
                columns,
                this.isInViewMode,
                this.dataGridComponent);
        }
    }

    private setColumnsWidthOnFilterChanged(params: FilterChangedEvent): void {
        params.columns.forEach((column) => {
            const colId = column.getColId();
            const headerMinWidth = this.getColumnMinWidth(colId);
            if (column.getMinWidth() !== headerMinWidth) {
                const columnDefs = params.api.getColumnDefs() ?? [];
                const columnDef = columnDefs.find((c: ColDef) => c.colId === colId);
                if (columnDef) {
                    // minWidth is NOT a valid agGrid ColDef property
                    (columnDef as ColDef).minWidth = headerMinWidth;
                    params.api.setGridOption('columnDefs', columnDefs);
                }
            }
            if (headerMinWidth > column.getActualWidth()) {
                params.api.setColumnWidths([{ key: colId, newWidth: headerMinWidth }]);
            }
        });
    }

    private getColumnMinWidth(colId?: string): number {
        const items: number = this.elementRef.nativeElement.querySelectorAll(`[col-id="${colId}"] [data-ref="eLabel"]>:not(.ag-hidden)`).length;
        if (!colId || !items) {
            return 50;
        }
        // The logic for 19 - min icon width is 12px, and we have 7px left margin (sort, filter and hamburger menu icons)
        // +12 - because we have a padding of the header label from the left which is 12px (eLabel)
        const isCommentCounterColumn = this.elementRef.nativeElement.querySelector(`[col-id="${colId}"] [data-ref="eLabel"] .hs-icon-comment`);
        return (items * 19) + (isCommentCounterColumn ? 20 : 12);
    }

    private getColumnsOrder(overrides: UserGridColumnOverrides[], columns: ConfigItem[]): ConfigItem[] {
        return this.userGridColumnOverridesService.getColumnsOrder(
            overrides,
            this.isInViewMode,
            columns,
            this.initialColumnState);
    }

    private getGroupColumnsOrder(overrides: UserGridColumnOverrides[], columns: ConfigItem[]): ConfigItem[] {
        return this.userGridColumnOverridesService.getGroupColumnsOrder(
            overrides,
            this.isInViewMode,
            columns,
            this.initialColumnState);
    }

    private getColumnVisibility(overrides: UserGridColumnOverrides[], colId: string): boolean {
        return this.userGridColumnOverridesService.getColumnVisibility(
            overrides,
            this.initialColumnState,
            colId,
            this.isInViewMode);
    }

    private updateInitialColumnStateFiltersOnFilterChanged(filterModel: FilterChangedEvent): void {
        this.initialColumnState.forEach((column) => {
            if (this.isFilterChangedFromColumnMenu(filterModel, column.colId)) {
                column.agFilterOnViewEditMode = this.dataGridComponent?.getFilterModel()?.[column.colId] ?? {};
            }
        });
    }

    private isFilterChangedFromColumnMenu(filterModel: CustomFilterChangedEvent, columnId: string): boolean {
        if (filterModel.source === 'api') {
            return false;
        }

        if (filterModel.source === 'columnFilter' && filterModel.columns[0].getColId() !== columnId) {
            return false;
        }

        if (filterModel.source === 'columnFilter' && filterModel.columns[0].getColDef().filter === 'customColumnFilterComponent' && !filterModel.isCustomFilter) {
            return false;
        }

        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private normalizeEmptyFields(originalData: any[], newDataHashes: string[]): any[] {
        const originalDataHashes: string[] = Object.keys(originalData).filter((hash) => !newDataHashes.includes(hash));
        originalDataHashes.forEach((hash) => {
            this.gridConfiguration?.fieldsForComparing?.forEach((field) => {
                // again, we're indexing an array with strings
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const originalDataCasAsAny = originalData as any;
                originalDataCasAsAny[hash][field + CompareColumnID.COMPARE] = 0;
                originalDataCasAsAny[hash][field + CompareColumnID.DIFF] =
                    originalDataCasAsAny[hash][field.replace('__abs', '')] - originalDataCasAsAny[hash][field + CompareColumnID.COMPARE];
            });
        });

        return originalData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private hashOriginalData(activeDate?: string | null): any[][] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataHashOrder: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const originalData: any[] = [];
        this.originalData?.forEach((entry) => {
            const hash = this.generateHashFromEntry(entry);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const originalDataCastAsAny = originalData as any;

            if (!activeDate || this.isEntryDateInRange(activeDate, entry.date)) {
                if (originalDataCastAsAny[hash]) {
                    this.gridConfiguration?.fieldsForComparing?.forEach((field) => {
                        originalDataCastAsAny[hash][field] += entry[field];
                    });
                } else {
                    originalDataCastAsAny[hash] = { ...entry };
                }

                if (!dataHashOrder.includes(hash)) {
                    dataHashOrder.push(hash);
                }
            }
        });

        return [dataHashOrder, originalData];
    }

    // originalData is actually an any[] but we try to index it with strings which works in JS but wont compile in TS
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private mergeCompareData(compareData: any[], originalData: any, originalDataHashOrder: any[]): any[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newDataHash: any[] = [];
        compareData.forEach((dataEntry) => {
            const hash = this.generateHashFromEntry(dataEntry);
            if (!newDataHash.includes(hash)) {
                newDataHash.push(hash);
            }

            if (this.gridConfiguration?.fieldsForComparing?.length) {
                this.gridConfiguration.fieldsForComparing.forEach((field) => {
                    const cleanField = field.replace('__abs', '');
                    if (originalData[hash]) {
                        if (originalData[hash][field + CompareColumnID.COMPARE]) {
                            originalData[hash][field + CompareColumnID.COMPARE] += dataEntry[cleanField];
                        } else {
                            originalData[hash][field + CompareColumnID.COMPARE] = dataEntry[cleanField];
                        }

                        originalData[hash][field + CompareColumnID.DIFF] =
                            originalData[hash][cleanField] - originalData[hash][field + CompareColumnID.COMPARE];
                    } else {
                        dataEntry[field + CompareColumnID.COMPARE] = dataEntry[cleanField];
                        dataEntry[field + CompareColumnID.DIFF] = -dataEntry[cleanField];
                        dataEntry[field] = 0;
                    }
                });

                if (!originalData[hash]) {
                    originalData[hash] = { ...dataEntry };
                    if (!originalDataHashOrder.includes(hash)) {
                        originalDataHashOrder.push(hash);
                    }
                }
            }
        });

        return [newDataHash, originalData, originalDataHashOrder];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private generateHashFromEntry(entry: any): string {
        const excludeKeysFromHashCreation = [
            'Date',
            'date',
            'links',
            'reconPreparedBy',
            'reconReviewedBy',
            'conversationId',
            TrebekConversationFields.HSComment,
            `${TrebekConversationFields.HSComment}Author`,
            `${TrebekConversationFields.HSComment}Created`,
            `${TrebekConversationFields.HSComment}_settings`,
            `${TrebekConversationFields.HSComment}_conversation_id`,
            TrebekConversationFields.ClientComment,
            `${TrebekConversationFields.ClientComment}Author`,
            `${TrebekConversationFields.ClientComment}Created`,
            `${TrebekConversationFields.ClientComment}_settings`,
            `${TrebekConversationFields.ClientComment}_conversation_id`,
            CrosstalkFields.Attachments,
        ];
        if (this.udfColumnNames?.length) {
            excludeKeysFromHashCreation.push(...this.udfColumnNames);
        }
        const entryKeys: string[] = Object.keys(entry).filter((k) => !excludeKeysFromHashCreation.includes(k) && isNaN(entry[k]));
        let stringToHash = '';
        entryKeys.forEach((key) => {
            stringToHash += entry[key];
        });
        return hashValue(stringToHash);
    }

    protected getQueryTypeName(): string | undefined {
        return this.isManageWidgetMode() ?
            this.selectedWidgetRelayService.getCurrentDatasetDefinition()?.queryType?.name :
            this.widgetPrefs?.datasetDefinition?.queryType?.name;
    }

    private isEntryDateInRange(activeDateStr: string, entryDateStr: string): boolean {
        const fuzzyDate = this.fuzzyDates?.findFromDate(activeDateStr);
        const activeDate = fuzzyDate ? DdvDate.fromISOFormat(fuzzyDate.value) : DdvDate.fromUSFormat(activeDateStr);

        const entryDate = DdvDate.fromDashFormat(entryDateStr);

        return activeDate.sameAs(entryDate);
    }

    private isAdvancedGrid(): boolean {
        return !!this.preferences?.isAdvancedGrid?.();
    }

    private isDetailWidget(): boolean {
        return !!this.widgetPrefs?.isDetailWidget;
    }

    private isInCompareMode(): boolean {
        return !!this.widgetPrefs?.widgetFilters?.isComparing;
    }

    private isDynamicColumn(colId: string | undefined): boolean {
        return !!this.preferences?.configs?.values.find((value) => value.colId === colId)?.isDynamic;
    }
}
