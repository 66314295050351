<div class="button-menu">
    <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showExportOptions = false">
        <cc-icon
            *ngIf="inPresentationMode && userCanExport"
            class="export-icon"
            [icon]="'logout'"
            [size]="'medium'"
            title="Export"
            (iconClicked)="toggleExportOptions()">
        </cc-icon>

        <div class="dropdown right-position single padd0" [hidden]="!showExportOptions">
            <app-dashboard-export
                *ngIf="showExportOptions"
                (exportOptionsClose)="closeDashboardExport($event)">
            </app-dashboard-export>
        </div>
    </div>

    <span
        class="icon-desktop"
        [class.selected]="currentDashboard?.getExtraParameters().deviceMode === 'DESKTOP'"
        title="Desktop"
        *ngIf="!inPresentationMode"
        [class.disabled-icon]="isTabletMode"
        (click)="toggleMode('DESKTOP')">
    </span>

    <span
        class="icon-tablet"
        [class.selected]="currentDashboard?.getExtraParameters().deviceMode === 'TABLET'"
        title="Tablet"
        *ngIf="!inPresentationMode"
        (click)="toggleMode('TABLET')">
    </span>

    <cc-icon [icon]="'filter'" class="icon-filter-tablet" [size]="'medium'" title="Filter" (iconClicked)="openDashboardFilter()"></cc-icon>
</div>
