import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { NO_ROWS_TO_SHOW_MESSAGE } from '@ddv/models';

@Component({
    selector: 'app-custom-no-rows-to-show-overlay',
    templateUrl: './no-rows-to-show-overlay.component.html',
    styleUrls: ['./no-rows-to-show-overlay.component.scss'],
})
export class NoRowsToShowOverlayComponent implements INoRowsOverlayAngularComp {
    protected overlayText: string | undefined;
    private params: INoRowsOverlayParams | undefined;

    agInit(params: INoRowsOverlayParams): void {
        this.params = params;
        this.overlayText = this.params.context?.componentParent?.grid?.localeText?.noRowsToShow ?? NO_ROWS_TO_SHOW_MESSAGE;
    }

    refresh(params: INoRowsOverlayParams): void {
        this.params = params;
    }
}
