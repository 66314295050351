import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { UserDefinedFieldType } from '@ddv/models';

import { HeaderCellRendererComponent } from '../cell-renderers/header-cell-renderer/header-cell-renderer.component';

export interface CustomColDef extends ColDef {
    isUserDefinedField?: boolean;
    userDefinedFieldType?: UserDefinedFieldType;
}

export interface CustomColGroupDef extends ColGroupDef {
    customWidthWhenGroup?: number;
}

export class DefaultColumnDefinition implements ColDef {
    headerName = '';
    headerComponent = HeaderCellRendererComponent;
    field = '';
    width = 50;
    minWidth = 30;
    sortable = true;
    suppressHeaderMenuButton = false;
    hide = false;
    pinned = null;
    suppressSizeToFit = false;
    suppressMovable = false;
    resizable = true;
    suppressNavigable = false;
    suppressAutoSize = false;
    editable = false;
    unSortIcon = false;
    rowGroup = false;
    enableRowGroup = false;
    enablePivot = false;
    enableValue = false;
    enableCellChangeFlash = false;
    suppressColumnsToolPanel = false;
    cellDataType = false;
}
