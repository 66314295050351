import { VisualizationTypeReference } from '@ddv/models';
import { visualizationDescriptorMap } from '@ddv/visualizations';

export class Visualization implements VisualizationTypeReference {
    get caption(): string {
        const vizConfig = visualizationDescriptorMap[this.name];
        return vizConfig ? vizConfig.name : this.name;
    }

    constructor(public id: number, public name: string, public widgetType: string) { }
}
