<div class="renderer-wrapper">
    <span class="add-comment-sign" *ngIf="!showComment && editMode">&#43;</span>

    <span
        class="comment-cell-content"
        [innerHTML]="comment"
        [ngClass]="{'grouper-comment': isGrouperComment}">
    </span>

    <app-conversation-history-icon title="Open history" (pressed)="openHistory($event)"></app-conversation-history-icon>
</div>
