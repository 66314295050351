import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

// This class is only used by the AdvancedGridVisualizationComponent and CrosstalkCommentCounterComponent
// AdvGrid is subscribed to the CrosstalkRealtimeConversationService. It needs to make some API calls to AgGrid
// BEFORE relaying an event through this service. Because AdvGrid needs to that work BEFORE CrosstalkCommentCounterComponent
// can respond, CrosstalkCommentCounterComponent cannot simply subscribe to CrosstalkRealtimeConversationService
// This code was originally in ManagerService (where it had no business) so it was pulled here.
@Injectable()
export class PostRealtimeCommentUpdateNotificationService {
    public readonly realTimeCommentUpdate: Observable<boolean>;
    private readonly realTimeCommentUpdateSubject: Subject<boolean> = new Subject();

    constructor() {
        this.realTimeCommentUpdate = this.realTimeCommentUpdateSubject.asObservable();
    }

    emitWhenCommentIsUpdatedInRealTime(): void {
        this.realTimeCommentUpdateSubject.next(true);
    }
}
