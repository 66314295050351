import { Injectable } from '@angular/core';

import { ConfigItem } from './config-item';
import { LegendConfig } from './legend-config';
import { VisualizationConfigs } from './visualization-configs';

export const enum LegendPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
}

@Injectable()
export class LegendConfigurationService {
    private readonly inline = false;
    private readonly showCustom = true;

    getConfigFromUserPreference(preferences?: VisualizationConfigs): LegendConfig {
        const legend = this.getLegendConfig(preferences);

        if (preferences?.enableLegend) {
            legend.docked = preferences.legendPosition as LegendPosition || legend.docked;

            if (legend.nav && (legend.docked === LegendPosition.BOTTOM || legend.docked === LegendPosition.TOP)) {
                legend.nav.y = -5;
            }
        } else {
            legend.showCustom = false;
        }

        return legend;
    }

    private getLegendConfig(preferences?: VisualizationConfigs): LegendConfig {
        const mirroredValues: ConfigItem[] = isMirroredBarChart(preferences) ? preferences?.configs?.mirroredValues ?? [] : [];
        const values: ConfigItem[] = preferences?.configs?.values ?? [];

        return {
            inline: this.inline,
            docked: LegendPosition.RIGHT,
            showCustom: this.showCustom,
            type: 'circle',
            nav: { x: 12, y: 0 },
            rectSize: 7,
            values: preferences?.configs?.slicers?.length === 0 || isMirroredBarChart(preferences) ?
                [...values, ...mirroredValues]
                : undefined,
        };
    }
}

function isMirroredBarChart(preferences?: VisualizationConfigs): boolean {
    return preferences?.visualizationType === 'VERTICAL_MIRRORED_BAR_CHART';
}
