import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { Axis } from '../../models/axis';
import { BarChartData } from '../../models/chart-data';
import { GridLine } from '../../models/grid-lines';
import { HorizontalBarChartScaleService } from './horizontal-bar-chart-scale.service';
import { deriveNumberOfTicksToPutOnTheAxis } from './utils';

@Injectable()
export class HorizontalBarChartGridlinesService {
    constructor(private readonly scales: HorizontalBarChartScaleService) {}

    draw(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        horizontalAxisConfig: Axis,
        config: GridLine | undefined,
        width: number,
        height: number,
    ): void {
        if (!config?.hide) {
            this.drawVerticalLines(chartRootGSelection, data, width, height, horizontalAxisConfig, config?.customClass);
        }
    }

    private drawVerticalLines(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        width: number,
        height: number,
        axisConfig: Axis,
        gridLineClass: string | undefined,
    ): void {
        const wrapper = chartRootGSelection.append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(0,${height})`)
            .call(this.buildVerticalLineDrawer(data, axisConfig, width, height));

        styleGridLines(wrapper, 'x-gridlines', gridLineClass ?? '');
    }

    private buildVerticalLineDrawer(
        data: BarChartData[],
        axisConfig: Axis,
        width: number,
        height: number,
    ): d3.Axis<d3.NumberValue> {
        const numberOfTicks = deriveNumberOfTicksToPutOnTheAxis(data, 'value', axisConfig.position, width, height);
        return this.scales.toHorizontalAxis()
            .ticks(numberOfTicks)
            .tickSize(-height)
            .tickFormat(() => '');
    }
}

function styleGridLines(
    grid: d3.Selection<SVGGElement, BarChartData, null, undefined>,
    gridLineClass: string,
    customClass: string,
): void {
    grid.selectAll('line')
        .attr('class', gridLineClass)
        .classed(customClass, true);

    grid.select('path')
        .attr('stroke', 'none');
}
