<div class="custom-autocomplete">
    <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="showDropdown = false;">
        <ul class="select-component" [ngClass]="{'readonly': isReadOnly}" (click)="setSearchFocus()">
            <li class="input-search" [hidden]="isReadOnly">
                <input
                    appInputRestrict
                    #inputSearch
                    type="text"
                    maxlength="25"
                    autocomplete="off"
                    placeholder="{{ selectedList.length ? '' : config?.placeholderText }}"
                    name="tagSearch"
                    [disabled]="isDisabled"
                    (focus)="showDropdown = true"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="searchParam"
                    (ngModelChange)="setDropdownList()"
                    [allowPattern]="inputPattern"/>

            </li>

            <li class="selected-list1" *ngFor="let item of selectedList; let i=index">
                <span>
                    {{item.name}}

                    <label
                        class="icon-close1"
                        [class.disabled-icon]="isDisabled"
                        [hidden]="isReadOnly"
                        (click)="removeTag($event, i)">
                    </label>
                </span>
            </li>

            <li class="dropdown-arrow1" [hidden]="isReadOnly">
                <span class="icon-arrow-down" [class.disabled-icon]="isDisabled"></span>
            </li>
        </ul>

        <div class="dropdown sharedtag" body-append *ngIf="showDropdown">
            <div>
                <label *ngIf="searchParam" class="fromLabel">{{ config?.suggestionText }}</label>

                <ul class="tagList" *ngIf="searchParam">
                    <li *ngFor="let opt of dropdownList" (click)="selectItem($event, opt)">
                        <span innerHTML="{{ opt.name }}"></span>
                    </li>
                </ul>

                <div class="disp-flex tag-label" [ngClass]="{'empty' : !searchParam }">
                    <mark>{{searchParam}}</mark>

                    <i>New Tag</i>

                    <button
                            class="empty-btn"
                            type="button"
                            [disabled]="!searchParam"
                            (click)="selectItem($event, searchParam, true)">
                        Add
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
