import { Inject, Injectable } from '@angular/core';
import { ManagerService } from '@ddv/layout';
import { WorkspaceBoundingRectangle, WorkspaceSize } from '@ddv/models';
import { WINDOW } from '@hs/ui-core-presentation';

@Injectable()
export class WorkspaceSizerService {
    constructor(
        private readonly manager: ManagerService,
        @Inject(WINDOW) private readonly window: Window,
    ) {}

    getWorkspaceSize(containerElement: HTMLElement): WorkspaceSize {
        const layoutHandler = this.manager.getWorkspaceLayoutHandler();
        if (!layoutHandler) {
            return {
                width: this.window.innerWidth,
                height: this.window.innerHeight,
            };
        }

        const workspaceBoundingRectangle: WorkspaceBoundingRectangle = containerElement.getBoundingClientRect();
        return {
            width: workspaceBoundingRectangle.width,
            height: workspaceBoundingRectangle.height,
        };
    }
}
