import { Injectable } from '@angular/core';
import { Docked, LegendConfig } from '@ddv/models';
import * as d3 from 'd3';
import { Observable, Subject } from 'rxjs';

import { BarChartData } from '../../models/chart-data';
import { ChartFormatSettings } from '../../models/chart-settings';
import { Dimensions } from '../../models/dimensions';
import { Legend, Pagination, Rect, WrapperPos } from '../../models/legend';
import { WrapperDimensions } from '../../models/wrapper-dimensions';
import { safeStringFormatter } from '../../safe-string-formatter';

@Injectable()
export class HorizontalBarChartLegendService {
    public readonly legendClicked$: Observable<LegendClickEvent>;

    private dims: Dimensions | undefined;
    private legendWrapperDims: WrapperDimensions | undefined;
    private carousalDims: WrapperDimensions | undefined;
    private wrapperPos: WrapperPos | undefined;
    private pageNo = 0;
    private svgSelect: d3.Selection<SVGElement, BarChartData, null, undefined> | undefined;
    private paginationInfo: Pagination[] | undefined;
    private totalPages = 0;
    private legendRectWrapper: Legend | undefined;
    private legendInitialized = false;
    private readonly legendClickedSubject: Subject<LegendClickEvent> = new Subject();
    private selectedLegend: string | undefined;

    constructor() {
        this.legendClicked$ = this.legendClickedSubject.asObservable();
    }

    createCustomLegends(
        rootSvg: SVGElement,
        config: LegendConfig,
        data: BarChartData[],
        svgRect: Rect,
        formatter: ChartFormatSettings | undefined,
        highlightedBar?: BarChartData,
    ): void {
        const rootSelection = d3.select<SVGElement, BarChartData>(rootSvg);
        this.svgSelect = rootSelection;

        this.init(config, rootSelection, svgRect);

        this.drawLegends(
            config,
            rootSelection,
            data,
            formatter,
            true,
            svgRect);

        this.computeLegendDims(
            config,
            rootSelection,
            data,
            formatter,
            svgRect);

        if (highlightedBar && data.some((d) => d.key.value === highlightedBar.key.value)) {
            this.highlightLegend(highlightedBar);
        } else {
            this.clearHighlight();
        }
    }

    setLegendsVisibility(action: 'show' | 'hide'): void {
        this.svgSelect?.selectAll('.legend').classed('hide', action === 'hide');
    }

    clearHighlight(): void {
        this.selectedLegend = undefined;
        this.svgSelect?.selectAll('.legend-items')
            .classed('disabled', false)
            .classed('enabled', false);
    }

    highlightLegend(data: BarChartData): void {
        this.selectedLegend = this.getSafeName(data);
        this.applyHighlightClasses();
    }

    private init(
        config: LegendConfig,
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        svgRect: Rect,
    ): void {
        this.pageNo = 0;

        this.dims = {
            fontSize: 12,
            rect: config.rectSize ?? 10,
            offset: 10,
        };

        this.carousalDims = {
            width: 20,
            height: 20,
        };

        this.legendWrapperDims = {
            height: svgRect.height - 2 * (this.carousalDims.height + (config?.nav?.y ?? 0)),
            width: svgRect.width - 2 * (this.carousalDims.width + (config?.nav?.x ?? 0)),
        };

        this.wrapperPos = this.calculateWrapperPosition(config.docked, rootSelection, svgRect);

        this.legendRectWrapper = {
            lastLegendRight: 0,
            wrapperRectRight: 0,
        };
    }

    private initEvents(legendI: d3.Selection<SVGGElement, BarChartData, SVGGElement, BarChartData>): void {
        legendI.on('click', (_, d) => {
            const barKey = d.key.value;
            const enabled = this.selectedLegend === barKey;

            // must run this check before emitting the event
            // when the event is emitted, it triggers a series of things that result in the
            // the whole chart being redrawn which will then confuse the state of the selected legend item
            if (enabled) {
                this.clearHighlight();
            } else {
                this.highlightLegend(d);
            }

            this.legendClickedSubject.next({
                data: d,
                enabled,
            });
        });
    }

    private addLeftNav(
        config: LegendConfig,
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        formatter: ChartFormatSettings | undefined,
        svgRect: Rect,
    ): void {
        const lPos = config.docked;
        const pPos = {
            top: lPos !== 'right' ? -11 : 0,
            left: 2,
        };
        const legendG = rootSelection.select('.legend');
        const prevTriangle = legendG.append('g')
            .attr('class', 'prev')
            .attr('transform', `translate(${pPos.left}, ${pPos.top})`)
            .on('click', () => {
                this.pageNo -= 1;
                this.drawLegends(config, rootSelection, data, formatter, false, svgRect);
            })
            .style('cursor', 'pointer');

        this.addNav(prevTriangle, !!config.nav, lPos);
    }

    private addRightNav(
        config: LegendConfig,
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        formatter: ChartFormatSettings | undefined,
        textHeight: number,
        pos: { top: number, left: number },
        svgRect: Rect,
    ): void {
        const lPos = config.docked;
        const legendG = rootSelection.select('.legend');
        const nPos = { top: 0, left: 0 };
        if (lPos === 'right') {
            nPos.top = pos.top + textHeight - 5;
        } else {
            nPos.left = rootSelection.select<SVGRectElement>('.rectWrapper').node()?.getBoundingClientRect().width ?? 0;
            nPos.top = -10;
        }
        const nextTriangle = legendG.append('g')
            .attr('class', 'next')
            .attr('id', 'legendRightNav')
            .attr('transform', `translate(${nPos.left}, ${nPos.top})`)
            .on('click', () => {
                this.pageNo += 1;
                this.drawLegends(config, rootSelection, data, formatter, false, svgRect);
            })
            .style('cursor', 'pointer');

        this.addNav(nextTriangle, !!config.nav, lPos, true);
    }

    private addNav(
        parentElem: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        nav: boolean,
        lPos: string | undefined,
        isNext?: boolean,
    ): void {
        let dValue = null;
        if (nav) {
            if (lPos === 'right') {
                dValue = isNext ?
                    'M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z' :
                    'M7.41,15.41L12,10.83l4.59,4.58L18,14l-6-6-6,6z';
            } else {
                dValue = isNext ?
                    'M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z' :
                    'M15.41,16.59L10.83,12l4.58-4.59L14,6l-6,6l6,6L15.41,16.59z';
            }
            parentElem.append('path')
                .attr('class', 'domain')
                .attr('stroke', '#a4a3a2')
                .attr('fill', '#a4a3a2')
                .attr('d', dValue);
        }
    }

    private removeLegends(rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>): void {
        rootSelection.selectAll('g.legend').remove();
        rootSelection.select('.rectWrapper').remove();
        rootSelection.select('.prev').remove();
        rootSelection.select('.next').remove();
    }

    private calculateWrapperPosition(
        docked: Docked | undefined,
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        svgRect: Rect,
    ): WrapperPos {
        const position: WrapperPos = {
            top: 0,
            left: 0,
        };
        const offset = 30;
        const chartGElement = rootSelection.select<SVGGElement>('g.chart').node();
        const svgBx = chartGElement?.getBoundingClientRect();
        const topMargin = Math.max((svgBx?.top ?? 0) - svgRect.top, 0);
        const dims = this.dims ?? { rect: 0, offset: 0, fontSize: 0 };

        switch (docked) {
            case 'right':
                position.left = svgRect.width * 0.8 + 10;
                break;
            case 'top':
                position.top = Math.max((Math.max(dims.rect, dims.fontSize) + 2) / 2, topMargin - offset);
                break;
        }
        return position;
    }

    private computeLegendDims(
        config: LegendConfig,
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        formatter: ChartFormatSettings | undefined,
        svRect: Rect,
    ): void {
        const lPos = config.docked;
        const textNodes = rootSelection.selectAll<SVGTextElement, BarChartData>('.legend-items text').nodes();
        const svgLen = (lPos === 'right' ?
            this.legendWrapperDims?.height :
            (d3.select('.rectWrapper').node() as SVGGraphicsElement).getBBox().width - 30) ?? 0;
        this.paginationInfo = [];
        this.totalPages = 1;
        let computedDim = 0;
        let pageInfo = {
            start: 0, end: 0,
        };
        this.pageNo = 1;
        const nodeLength = textNodes.length;
        textNodes.forEach((node, index: number) => {
            let tempIndex = index;
            const dims = this.dims ?? { rect: 0, offset: 0, fontSize: 0 };
            let textDim = lPos === 'right' ?
                Math.max(dims.rect, dims.fontSize) + 2 :
                ((node.parentElement as unknown as SVGGElement)?.getBBox().width ?? 0) + 2;
            computedDim = computedDim + textDim;
            if (computedDim > svgLen) {
                const prevDim = computedDim - textDim;
                if (Math.ceil(svgLen - prevDim) >= 35) {
                    tempIndex += 1;
                    textDim = 0;
                }
                pageInfo.end = tempIndex;
                this.paginationInfo?.push(pageInfo);
                pageInfo = {
                    start: tempIndex,
                    end: 0,
                };
                if (tempIndex !== nodeLength) {
                    this.totalPages += 1;
                }
                computedDim = textDim;
            }
        });
        pageInfo.end = data?.length ?? 0;
        this.paginationInfo.push(pageInfo);
        this.drawLegends(config, rootSelection, data, formatter, false, svRect);
        if (!this.legendInitialized) {
            this.legendInitialized = true;
        }
    }

    private drawLegends(
        config: LegendConfig,
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        formatter: ChartFormatSettings | undefined,
        isInit: boolean,
        svgRect: Rect,
    ): void {
        if (!isInit) {
            this.removeLegends(rootSelection);
            this.initWrapperPos(config, svgRect);
        }

        const lPos = config.docked;
        const legendCircle = config.type === 'circle';
        const dims = this.dims ?? { rect: 0, offset: 0, fontSize: 0 };
        const textHeight = Math.max(dims.rect, dims.fontSize) + 2;
        const legendG = rootSelection
            .append('g')
            .attr('class', 'legend')
            .attr('transform', `translate(${this.wrapperPos?.left},${this.wrapperPos?.top})`);
        const pos = this.wrapperPos ? { ...this.wrapperPos } : { top: 0, left: 0 };

        // LEFT PAGING ADDED
        if (isInit || this.pageNo > 1) {
            this.addLeftNav(config, rootSelection, data, formatter, svgRect);
        }

        if (lPos !== 'right') {
            rootSelection.append('rect')
                .attr('width', svgRect.width - 10)
                .attr('height', dims.rect)
                .attr('fill', 'transparent')
                .attr('class', 'rectWrapper rectWrapperTop');
        }

        const dataset = isInit ? [...data] : [...this.getDataForPage(data, this.pageNo)];

        if (!formatter?.sortDirection || formatter.sortDirection !== 'CUSTOM') {
            dataset.sort((a, b) => a.key < b.key ? -1 : a.key > b.key ? 1 : 0);
        }

        const legendI = legendG.selectAll('.legend-items')
            .data(dataset)
            .enter()
            .append('g')
            .attr('class', 'legend-items')
            .attr('data-legend', (d) => d.key.value || 'Blanks');

        if (lPos === 'right') {
            legendI.append('rect')
                .attr('width', svgRect.width * (config.width ?? 1))
                .attr('height', dims.rect)
                .attr('fill', 'transparent');
        }

        legendI.append('svg:title').text((d) => d.key.value || 'Blanks'); // d.data ? (d.data.key || 'Blanks') : (d.displayName || d.key));

        if (legendCircle) {
            legendI.append('circle')
                .attr('r', dims.rect / 2)
                .attr('class', (d) => safeStringFormatter(d.key.value).replace(' ', ''))
                .attr('data-legend', (d) => d.key.value || 'Blanks')
                .attr('fill', (d) => d.color ?? '');
        } else {
            legendI.append('rect')
                .attr('width', dims.rect)
                .attr('height', dims.rect)
                .attr('class', (d) => safeStringFormatter(d.key.value).replace(' ', ''))
                .attr('data-legend', (d) => d.key.value || 'Blanks')
                .attr('fill', (d) => d.color ?? '');
        }

        const text = legendI.append('text')
            .text((d) => d.key == null ? 'Null' : (d.key.value.trim() ? this.getSafeName(d, 'Blanks') : 'Blanks'))
            .style('font-size', dims.fontSize)
            .attr('y', (legendCircle ? dims.rect / 2 : dims.rect))
            .attr('x', (legendCircle ? dims.rect : dims.rect + 10));

        let textOffset = 0;
        const textNodes = text.nodes();

        legendI.attr('transform', (_, i: number) => {
            if (lPos === 'right') {
                textOffset += (i === 0) ? (this.carousalDims?.height ?? 0) : textHeight;
                pos.top = textOffset;
                return `translate(0, ${pos.top})`;
            }
            textOffset += (i === 0) ?
                (this.carousalDims?.width ?? 0) + 8 :
                (textNodes[i - 1].parentNode as SVGGraphicsElement).getBoundingClientRect().width + 10;
            pos.left = textOffset;
            return `translate(${pos.left}, 0)`;
        });

        if (this.selectedLegend) {
            this.applyHighlightClasses();
        }

        if (!isInit) {
            this.initEvents(legendI);
        }

        // RIGHT PAGING ADDED
        if (isInit || this.pageNo < this.totalPages) {
            this.addRightNav(config, rootSelection, data, formatter, textHeight, pos, svgRect);
        }

        this.updateWrapperPos(config, rootSelection, isInit, svgRect);

        this.animateLegends(rootSelection, () => {
            if (!isInit) {
                this.truncateLongText(rootSelection, text, lPos, svgRect);
            }
        });
    }

    private applyHighlightClasses(): void {
        this.svgSelect?.selectAll<SVGGElement, BarChartData>('.legend-items')
            .classed('disabled', (n) => this.selectedLegend !== this.getSafeName(n))
            .classed('enabled', (n) => this.selectedLegend === this.getSafeName(n));
    }

    private getDataForPage(data: BarChartData[], pageNo: number): BarChartData[] {
        const pageInfo = this.paginationInfo?.length ? this.paginationInfo[pageNo - 1] : { start: 0, end: data.length };
        return [...data].slice(pageInfo.start, pageInfo.end);
    }

    private initWrapperPos(config: LegendConfig, svgRect: Rect): void {
        this.wrapperPos = this.wrapperPos ?? { top: 0, left: 0 };

        if (config.docked === 'right') {
            this.wrapperPos.top = svgRect.top;
        } else {
            this.wrapperPos.left = svgRect.right;
            this.wrapperPos.top = 10;
        }
    }

    private updateWrapperPos(
        config: LegendConfig,
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        isInit: boolean,
        svgRect: Rect,
    ): void {
        const lRect = rootSelection.selectAll<SVGGElement, BarChartData>('.legend').node()?.getBBox();

        this.wrapperPos = this.wrapperPos ?? { top: 0, left: 0 };
        this.legendRectWrapper = this.legendRectWrapper ?? { wrapperRectRight: 0, lastLegendRight: 0 };

        if (config.docked === 'right') {
            this.wrapperPos.top = (svgRect.height - (lRect?.height ?? 0)) / 2;
        } else {
            if (isInit) {
                const lastLegend = rootSelection.selectAll<SVGTextElement, BarChartData>('.legend-items text').nodes();
                this.legendRectWrapper.lastLegendRight = lastLegend[lastLegend.length - 1].getBoundingClientRect().right;
                this.legendRectWrapper.wrapperRectRight = rootSelection.selectAll<SVGRectElement, BarChartData>('.rectWrapper').node()?.getBoundingClientRect().right ?? 0;
            }
            if (this.legendRectWrapper.lastLegendRight > this.legendRectWrapper.wrapperRectRight) {
                this.wrapperPos.left = -10;
            } else {
                this.wrapperPos.left = Math.ceil(this.legendRectWrapper.wrapperRectRight - this.legendRectWrapper.lastLegendRight);
            }
        }
    }

    private animateLegends(rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>, cb: () => void): void {
        let legend: d3.Selection<SVGGElement, BarChartData, SVGElement, BarChartData> | d3.Transition<SVGGElement, BarChartData, SVGElement, BarChartData> = rootSelection.selectAll<SVGGElement, BarChartData>('.legend');
        if (!this.legendInitialized) {
            legend = legend.transition().duration(300).delay(0);
        }
        // TODO: something is wrong here.  only a Transition returns something with an "on" method
        const attr = legend.attr('transform', `translate(${this.wrapperPos?.left},${this.wrapperPos?.top})`);
        if (typeof attr !== 'string') {
            attr.on('end', cb);
        }
    }

    private truncateLongText(
        rootSelection: d3.Selection<SVGElement, BarChartData, null, undefined>,
        textGp: d3.Selection<SVGTextElement, BarChartData, SVGGElement, BarChartData>,
        lPos: string | undefined,
        svgRect: Rect,
    ): void {
        const effectiveSvgRect = (lPos === 'right') ? svgRect : rootSelection.select<SVGRectElement>('.rectWrapper')?.node()?.getBoundingClientRect();

        // this should never happen, but...
        // it is happening in tests, only in FF, only when running the whole batch
        // it is impossible to trace
        if (!effectiveSvgRect) {
            return;
        }

        let visChars: number | undefined;
        if (!rootSelection.select('#legendRightNav').node() && lPos !== 'right') {
            return;
        }
        textGp.each(function (this: SVGTextElement) {
            // eslint-disable-next-line no-invalid-this
            const txtNode = d3.select(this);
            const txtRect = txtNode.node()?.getBoundingClientRect() ?? { right: 0, width: 0 };
            const text = txtNode.text();
            if (txtRect.right > effectiveSvgRect.right) {
                if (!visChars) {
                    const ceiling = Math.ceil((txtRect.right - effectiveSvgRect.right) / (Math.floor(txtRect.width / text.length)));
                    visChars = text.length - ceiling;
                }
                txtNode.text(text.substring(0, visChars - 3));
                txtNode.append('tspan').attr('class', 'elip').text('...');
            } else if (text.length > 30) {
                txtNode.text(text.substring(0, 30));
                txtNode.append('tspan').attr('class', 'elip').text('...');
            }
        });

        if (visChars && visChars <= 3 && lPos === 'right') {
            this.setLegendsVisibility('hide');
        }
    }

    private getSafeName(data: BarChartData, defaultName = 'N/A'): string {
        if (safeStringFormatter(data.key.value)) {
            return safeStringFormatter(data.key.value);
        } else {
            return defaultName;
        }
    }
}

export interface LegendClickEvent {
    data: BarChartData;
    enabled: boolean;
}
