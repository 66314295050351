import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChildren,
    QueryList,
    AfterViewInit,
    AfterViewChecked,
    ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
    ConfirmationPopupService,
    CustomAutoCompleterComponent,
    CustomAutoCompleteConfig,
    CustomFormValidators,
    MultiSubscriptionComponent,
} from '@ddv/common-components';
import { QueryParamsService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    DEVICE_MODE,
    PERMISSION,
    TAG_TYPE,
    DashboardDetails,
    DashboardModel,
    DashboardPreference,
    UserPermission,
    combineUserAndSharedTags,
    getGlobalSaveDialogOptions,
} from '@ddv/models';
import { deviceModeIsTablet } from '@ddv/utils';
import { Observable } from 'rxjs';

import { DashboardService } from '../../services/dashboard.service';

export interface DashboardFormModel {
    abbreviation: string;
    description: string;
    isDefault: boolean;
    name: string;
    source: string;
    family?: string;
}

// only launched dynamically, so you'll see no reference to the selector
// only launched by DashboardNavComponent
@Component({
    selector: 'app-save-form',
    templateUrl: './save-dashboard-form.component.html',
    styleUrls: ['./save-dashboard-form.component.scss'],
})
export class SaveDashboardFormComponent extends MultiSubscriptionComponent implements OnInit, AfterViewInit, AfterViewChecked {
    selectedOption: 'new' | 'edit' = 'new';
    isReadOnly = false;
    dashboard: DashboardDetails | DashboardModel | undefined;
    dashboardGroupName: string | undefined;
    isDashboardCopy = false;
    isGlobalEditPartial = false;

    @Output() formSubmitted = new EventEmitter<DashboardDetails>();
    @Output() closeForm = new EventEmitter<Event>();

    dashboardForm: UntypedFormGroup | undefined;
    dashboardFormErrors = {
        name: '',
        family: '',
    };
    saveButtonText: string | undefined;
    dashboardPermissions: UserPermission[] = [];
    tagsConfiguration: CustomAutoCompleteConfig | undefined;
    isGlobal = false;

    private privilege: string | undefined;
    private isSaveDashboardInvoked = false;
    private isNameAvailable = true;
    private queryParam: DashboardPreference[] = [];
    @ViewChildren(CustomAutoCompleterComponent) private readonly autoCompleterRefs: QueryList<CustomAutoCompleterComponent> | undefined;
    private userTagCompleterRef: CustomAutoCompleterComponent | undefined;
    private sharedTagCompleterRef: CustomAutoCompleterComponent | undefined;

    constructor(
        private readonly dashboardService: DashboardService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly managerService: ManagerService,
        private readonly confirmationService: ConfirmationPopupService,
        private readonly queryParamsService: QueryParamsService,
        private readonly cdr: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(this.queryParamsService.dashboardQueryParams, (dashboardPref: DashboardPreference) => {
            this.queryParam = [{ ...dashboardPref }];
        });

        this.saveButtonText = 'Save';

        this.tagsConfiguration = {
            placeholderText: 'Enter Tag',
            suggestionText: 'Suggested Tags',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getSourceFn: (term): Observable<any[]> => this.dashboardService.getTagsAutocompleterData(term),
        };

        this.buildDashboardForm(this.buildDashboardFormModel());
    }

    ngAfterViewInit(): void {
        this.userTagCompleterRef = this.autoCompleterRefs?.first;

        this.sharedTagCompleterRef = this.autoCompleterRefs?.last;
        if (this.sharedTagCompleterRef) {
            this.sharedTagCompleterRef.isReadOnly = this.isReadOnly;
            this.sharedTagCompleterRef.isDisabled = this.privilege === PERMISSION.VIEW;
        }

        if (this.selectedOption === 'edit') {
            this.getCurrentDashboardTags();
        }
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    onSaveDashboard(formData: DashboardFormModel): void {
        if (this.isGlobal && !this.isDashboardCopy && !this.isGlobalEditPartial) {
            const confirmDialogOptions = getGlobalSaveDialogOptions();
            this.confirmationService.showConfirmationPopup(confirmDialogOptions).subscribe({
                next: (action) => {
                    if (action === 'confirm') {
                        this.onDashboardSave(formData);
                    }
                },
            });
        } else {
            this.onDashboardSave(formData);
        }
    }

    onDashboardSave(formData: DashboardFormModel): void {
        if (this.skipNameAvailabilityCheck(formData.name)) {
            this.emitFormDetails(formData);
        } else {
            this.dashboardService.isNameAvailable(formData.name)
                .subscribe((isNameAvailableResult) => {
                    if (!isNameAvailableResult) {
                        this.nameUnavailable();
                    } else {
                        this.emitFormDetails(formData);
                    }
                });
        }
    }

    checkNameAvailability(): void {
        const control = this.dashboardForm?.get('name');
        if (!control?.valid) {
            return;
        }

        const dashboardName = control.value.trim();
        if (!this.skipNameAvailabilityCheck(dashboardName)) {
            this.dashboardService.isNameAvailable(dashboardName)
                .subscribe((nameIsAvailable) => {
                    if (!nameIsAvailable) {
                        this.nameUnavailable();
                    }
                });
        }
    }

    disableSaveButton(): boolean {
        return !this.dashboardForm?.valid ||
            !this.isNameAvailable ||
            this.isSaveDashboardInvoked ||
            (!this.isReadOnly && this.privilege === PERMISSION.VIEW && this.selectedOption === 'edit') ||
            (this.isReadOnly && !!this.dashboard);
    }

    nameUnavailable(): void {
        this.isSaveDashboardInvoked = false;
        this.isNameAvailable = false;
        this.dashboardFormErrors.name = 'Name already exists. Please try a different name';
    }

    private emitFormDetails(formData: DashboardFormModel): void {
        const requestParam: DashboardDetails = this.convertFormDataToDashboardDetails(formData);
        this.isSaveDashboardInvoked = true;
        this.managerService.selectWidget(false, undefined);

        return this.formSubmitted.emit(requestParam);
    }

    private convertFormDataToDashboardDetails(formData: DashboardFormModel): DashboardDetails {
        const dashboardName = formData.name.trim();

        const dashboardDetails: DashboardDetails = new DashboardDetails({
            abbreviation: formData.abbreviation || dashboardName.slice(0, 4),
            allowInfiniteVScroll: true,
            allowInfiniteHScroll: false,
            widgets: [],
            dashboardTags: combineUserAndSharedTags(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.userTagCompleterRef?.selectedList ?? []) as any,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.sharedTagCompleterRef?.selectedList ?? []) as any),
            dashboardPreferences: [],
            dashboardPermissions: formData.source === 'shared' ? this.dashboardPermissions : [],
            description: formData.description,
            id: '',
            isDefault: formData.isDefault,
            isGlobal: this.isGlobal,
            isShared: formData.source === 'shared',
            layoutType: 'custom',
            name: dashboardName,
            deviceMode: deviceModeIsTablet() ? DEVICE_MODE.TABLET : DEVICE_MODE.DESKTOP,
        });

        if (this.selectedOption === 'new') {
            return dashboardDetails;
        }

        if (!this.dashboard) {
            const workspace = this.managerService.getWorkspace();
            dashboardDetails.widgets = this.managerService.getAppWidgetsState()
                .map((widget) => {
                    widget.maximized = false;
                    return widget;
                });

            dashboardDetails.dashboardPreferences = this.queryParam;
            dashboardDetails.dashboardPreferences.forEach((preference) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                workspace?.getExtraParameters().dashboardPreferences.forEach((p: any) => {
                    if (p.dashboardPreferenceMode === preference.dashboardPreferenceMode) {
                        preference.version = p.version;
                    }
                });
            });

            dashboardDetails.version = workspace?.getExtraParameters().version;
            dashboardDetails.privilege = workspace?.getExtraParameters().privilege;
            dashboardDetails.deviceMode = workspace?.getExtraParameters().deviceMode;

            if (this.selectedOption === 'edit') {
                dashboardDetails.id = workspace?.id;
            }
        } else {
            dashboardDetails.id = this.dashboard.id;
            dashboardDetails.widgets = this.dashboard.widgets;
            dashboardDetails.dashboardPreferences = this.dashboard.dashboardPreferences;
            dashboardDetails.version = this.dashboard.version;
            dashboardDetails.privilege = this.dashboard.privilege;
            dashboardDetails.deviceMode = this.dashboard.deviceMode;
        }

        return dashboardDetails;
    }

    private buildDashboardFormModel(): DashboardFormModel {
        const dashboardFormModel: DashboardFormModel = {
            name: '',
            description: '',
            source: 'private',
            isDefault: false,
            abbreviation: '',
            family: '',
        };

        if (this.selectedOption === 'new') {
            return dashboardFormModel;
        }

        const dashboard = this.dashboard ?? this.managerService.getWorkspace()?.getExtraParameters();

        this.isGlobal = !!dashboard?.isGlobal;

        dashboardFormModel.name = dashboard?.name ?? '';
        if (dashboard instanceof DashboardModel) {
            this.dashboardPermissions.push(...dashboard.dashboardPermissions);
        }
        dashboardFormModel.source = dashboard?.isShared ? 'shared' : dashboardFormModel.source;

        this.privilege = dashboard?.privilege;

        dashboardFormModel.description = dashboard?.description ?? dashboardFormModel.description;
        dashboardFormModel.isDefault = dashboard?.isDefault ?? dashboardFormModel.isDefault;
        dashboardFormModel.abbreviation = dashboard?.abbreviation ?? dashboardFormModel.abbreviation;
        dashboardFormModel.family = this.dashboardGroupName ?? 'None';

        return dashboardFormModel;
    }

    private getCurrentDashboardTags(): void {
        const currDashboard = this.managerService.getWorkspace();
        const currDashboardModel: DashboardModel = currDashboard?.getExtraParameters();
        const dashboardTags = !this.dashboard ? currDashboardModel.dashboardTags : this.dashboard.dashboardTags;

        dashboardTags.forEach((tag) => {
            if (tag.type === TAG_TYPE.SHARED) {
                this.sharedTagCompleterRef?.selectedList.push(tag);
            }
            if (tag.type === TAG_TYPE.USER) {
                this.userTagCompleterRef?.selectedList.push(tag);
            }
        });
    }

    private buildDashboardForm(details: DashboardFormModel): void {
        this.dashboardForm = this.formBuilder.group({
            name: [
                details.name,
                [
                    Validators.compose([
                        Validators.required,
                        CustomFormValidators.isInputSpaces,
                    ]),
                    Validators.pattern(/^[\w/\s\-%&^#@()<>]+$/),
                ],
            ],
            description: [details.description],
            source: [details.source],
            isDefault: [details.isDefault],
            abbreviation: [details.abbreviation],
            family: [details.family],
        });

        this.dashboardForm.get('name')?.valueChanges.subscribe(() => this.validateName('name'));
        this.dashboardForm.get('family')?.valueChanges.subscribe(() => this.validateName('family'));
    }

    private validateName(key: string): void {
        const control = this.dashboardForm?.get(key);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.dashboardFormErrors as any)[key] = '';
        this.isNameAvailable = true;

        if (control && !control.valid) {
            if (control.errors?.required) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.dashboardFormErrors as any)[key] = `Error: ${key} cannot be blank`;
            } else if (control.errors?.isSpaces) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.dashboardFormErrors as any)[key] = `Error: ${key} cannot be only spaces.`;
            } else if (control.errors?.pattern) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.dashboardFormErrors as any)[key] = `Error: ${key} contains an invalid character.`;
            }
        }
    }

    // don't check if in edit mode and the name is unchanged because im allowed to use my own name
    private skipNameAvailabilityCheck(dashboardName: string): boolean {
        return this.selectedOption === 'edit' &&
            dashboardName.toLowerCase() === this.managerService.getWorkspace()?.getExtraParameters().name.toLowerCase() &&
            !this.isDashboardCopy;
    }
}
