import { Component, Input } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';

import { ModalDialogActive } from '../modal-dialog/modal-dialog-ref.service';

@Component({
    selector: 'ddv-widget-delete-alert',
    templateUrl: './widget-delete-alert.component.html',
    styleUrls: ['./widget-delete-alert.component.scss'],
})
export class WidgetDeleteAlertComponent {
    @Input() message: string | undefined;
    @Input() dashboards: { id: number, name: string, url?: string }[] = [];

    clientCode: string | undefined;

    constructor(
        private readonly currentState: CurrentStateService,
        private readonly modalDialogActive: ModalDialogActive,
    ) {
        this.currentState.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    close(): void {
        this.modalDialogActive.close();
    }
}
