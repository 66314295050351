import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DateRangeString, DdvDate, FuzzyDate } from '@ddv/models';

@Component({
    selector: 'app-single-date-picker-dialog',
    templateUrl: './single-date-picker-dialog.component.html',
    styleUrls: ['./single-date-picker-dialog.component.scss'],
})
export class SingleDatePickerDialogComponent implements OnChanges {
    @Input() date: string | undefined;
    @Input() fuzzyDates: FuzzyDate[] = [];
    @Input() isHIDataAvailable = false;

    @Output() dateSelected = new EventEmitter();

    year: number | undefined;
    month: number | undefined;

    errorMessage: string | undefined;
    isDatePickerChecked = true;
    keyModalOpen = false;

    postedStates = [
        { class: 'all', text: 'All Posted' },
        { class: 'partial', text: 'Partial Posted' },
        { class: 'none', text: 'None Posted' },
    ];

    ngOnChanges(): void {
        const date = DdvDate.fromUSFormat(this.date);
        this.year = date.year;
        this.month = date.month;
    }

    onDateSelected(date?: string | DateRangeString): void {
        this.date = date as string;
        if (date) {
            const fuzzyDate = this.getFuzzyDate(date as string);
            this.setupYearMonth(fuzzyDate ? DdvDate.fromISOFormat(fuzzyDate.value) : DdvDate.fromUSFormat(date as string));
        }
    }

    private getFuzzyDate(value: string): FuzzyDate | undefined {
        return this.fuzzyDates?.find((fuzzyDate) => fuzzyDate.name.toLocaleUpperCase() === value.toLocaleUpperCase());
    }

    private setupYearMonth(date: DdvDate): void {
        this.year = date.year;
        this.month = date.month;
    }

    emitDate(): void {
        this.dateSelected.emit(this.date);
    }

    showError(errorMessage?: string): void {
        this.errorMessage = errorMessage;
    }
}
