import { DecimalPipe, PercentPipe } from '@angular/common';

export const LOCALE_ID = 'en-US';
const decimalPipe = new DecimalPipe(LOCALE_ID);
const percentagePipe = new PercentPipe(LOCALE_ID);

function formatNumber(value: string | number, decimalPlaces: number | string): string | null {
    return decimalPipe.transform(Number(value), `.${decimalPlaces}-${decimalPlaces}`);
}

function formatTrimmedNumber(value: string | number, decimalPlaces: number | string): string {
    return trimTrailingZeros(decimalPipe.transform(Number(value), `.${decimalPlaces}-${decimalPlaces}`));
}

export function trimTrailingZeros(value: string | null): string {
    let trimmedValue = value;
    if (trimmedValue == null) {
        return '0';
    }

    if (trimmedValue?.indexOf('.') === -1) {
        return trimmedValue;
    }

    while (trimmedValue?.slice(-1) === '0') {
        trimmedValue = trimmedValue.slice(0, -1);
    }

    return trimmedValue?.slice(-1) === '.' ? trimmedValue?.slice(0, -1) : trimmedValue;
}

export const numberFormatter = {
    asDisplayValue: formatNumber,
    asTrimmedValue: formatTrimmedNumber,
    asFilterValue: (value: unknown, decimalPlaces: number | string): number => parseFloat(parseFloat(`${value}`).toFixed(Number(decimalPlaces))),
};

export const percentageFormatter = {
    asDisplayValue: (value: unknown, decimalPlaces: number | string): string | null => {
        return percentagePipe.transform(`${value}`, `.${decimalPlaces}-${decimalPlaces}`);
    },
    asFilterValue: (value: unknown, decimalPlaces: number | string): number => {
        return numberFormatter.asFilterValue(Number(value) * 100, decimalPlaces);
    },
};
