export interface ViewLink {
    id: number;
    name: string;
    abbreviation: string;
    privilege?: string;
    isGlobal?: boolean;
}

export class ViewTile {
    id = 0;
    name = '';
    isGroup = false;
    selectedLink: ViewLink | undefined;
    links: ViewLink[] = [];

    constructor(init?: Partial<ViewTile>) {
        Object.assign(this, init);
    }
}
