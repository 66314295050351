<cc-tool-tip></cc-tool-tip>
<cc-modal-layer></cc-modal-layer>
<cc-nav-bar [clientCode]="clientCode" [appDisplayName]="appDisplayName"></cc-nav-bar>

<div>
    <router-outlet></router-outlet>
</div>

<div class="loader-parent" *ngIf="isLoading">
    <div class="spinner loader">
        <div class="blue-spinner"></div>
        <div class="gray-spinner"></div>
    </div>
</div>

<div class="loader-parent tabletP" *ngIf="isTabletInPortraitMode">
    <div class="content-area">
        <span class="loader-tabletP background-tablet"></span>
        <div class="text-block">
            <h4>Please rotate your device</h4>
            <p> We do not support portrait mode.</p>
            <p> Please use this app in landscape mode for best experience </p>
        </div>
    </div>
</div>

<app-alert></app-alert>
