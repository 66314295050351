import { Injectable } from '@angular/core';
import { ExecutorService } from '@ddv/http';
import { ActionHandler } from '@ddv/models';
import { Observable } from 'rxjs';

import { ActionHandlerBodyBuilderService } from './action-handler-body-builder.service';

@Injectable()
export class ActionHandlerService {
    constructor(
        private readonly bodyBuilder: ActionHandlerBodyBuilderService,
        private readonly executor: ExecutorService,
    ) {}

    handleAction(clientCode: string, config: ActionHandler, selectedRows: unknown[]): Observable<void> {
        const body = this.bodyBuilder.buildActionHandlerBody(config.body, selectedRows);
        return this.executor.invokeServiceWithBody(
            escapeURLPlaceHolders(config.url, clientCode),
            config.method,
            body);
    }
}

function escapeURLPlaceHolders(url: string, clientCode: string): string {
    return url.replace(':clientCode', clientCode);
}
