import { Injectable } from '@angular/core';
import { DateRangeString } from '@ddv/models';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class SlowDataApprovalService {
    private readonly decisions: Record<string, { response: boolean | undefined, subject: Subject<boolean> }> = {};

    isApproved(clientCode: string, date: string | DateRangeString, queryType = 'Recon'): boolean {
        if (queryType !== 'Recon') {
            return true;
        }

        return !!this.decisions[buildKey(clientCode, queryType, date)]?.response;
    }

    approve(clientCode: string, date: string | DateRangeString, queryType = 'Recon'): void {
        const approval = this.getDecision(clientCode, queryType, date);
        approval.response = true;
        approval.subject.next(true);
    }

    deny(clientCode: string, date: string | DateRangeString, queryType = 'Recon'): void {
        const approval = this.getDecision(clientCode, queryType, date);
        approval.response = false;
        approval.subject.next(false);
    }

    waitForDecision(clientCode: string, date: string | DateRangeString, queryType = 'Recon'): Observable<boolean> {
        if (queryType !== 'Recon') {
            return of(true);
        }

        return this.getDecision(clientCode, queryType, date).subject.asObservable();
    }

    private getDecision(
        clientCode: string,
        queryType: string,
        date: string | DateRangeString,
    ): { response: boolean | undefined, subject: Subject<boolean> } {
        const key = buildKey(clientCode, queryType, date);
        if (!this.decisions[key]) {
            this.decisions[key] = { response: undefined, subject: new ReplaySubject(1) };
        }

        return this.decisions[key];
    }
}

function buildKey(clientCode: string, queryType: string, date: string | DateRangeString): string {
    const dateString = typeof date === 'string' ? date : date.dateTo;
    return `${clientCode}:${queryType}:${dateString}`;
}
