import { Injectable } from '@angular/core';

import { Service } from './model/service';
import { ServiceLocator } from './service-locator.interface';

@Injectable()
export class DefaultServiceLocator implements ServiceLocator {
    // TODO: public state
    serviceRegistry: { [name: string]: Service } = {};

    registerService(service: Service): void {
        this.addService(service);
    }

    locateService(serviceName: string): Service {
        if (!this.serviceRegistry[serviceName]) {
            throw new Error(`Service ${serviceName} not found`);
        }
        return this.serviceRegistry[serviceName];
    }

    private addService(service: Service): void {
        const serviceName = service.getServiceName();
        if (!serviceName) {
            throw new Error('You must provide a valid name for this service.');
        }

        if (this.serviceRegistry[serviceName]) {
            throw new Error(`Service ${serviceName} already registered`);
        }

        this.serviceRegistry[serviceName] = service;
    }
}
