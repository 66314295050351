<div class="column-formatting-form">
    <form *ngIf="configForm" [formGroup]="configForm" (ngSubmit)="closeGrid(configForm.value)">
        <div class="section column-name">
            <div class="subsection">
                <div class="title">Column Name</div>

                <div class="column-label">{{ parentData?.label }}</div>
            </div>

            <div class="subsection">
                <div class="radio-btn">
                    <input
                        #display_as_configured
                        id="display_as_configured"
                        name="showCustomName"
                        formControlName="showCustomName"
                        type="radio"
                        [attr.checked]="display_as_configured.checked ? 'checked' : null" [value]=false
                        (change)="toggleCustomNameField()" />

                    <label for="display_as_configured">As Configured</label>
                </div>

                <div class="radio-btn">
                    <input
                        #display_as_custom
                        id="display_as_custom"
                        name="showCustomName"
                        formControlName="showCustomName"
                        type="radio"
                        [attr.checked]="display_as_custom.checked ? 'checked' : null"
                        [value]=true
                        (change)="toggleCustomNameField()" />

                    <label for="display_as_custom">Custom</label>
                </div>

                <div class="content">
                    <input
                        type="text"
                        name="displayname"
                        formControlName="customName"
                        placeholder="Display Name"
                        maxlength="40"
                        autocomplete="off"/>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="subsection">
                <p class="title">Display Type</p>

                <div class="custom-select">
                    <app-dropdown
                        name="displayType"
                        formControlName="displayType"
                        [options]="displayTypes"
                        (selectedChange)="onDisplayTypeChanged($event)">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection" *ngIf="currentDisplayType === 'date'">
                <p class="title">Date Format</p>

                <div class="custom-select">
                    <app-dropdown
                        name="dateFormat"
                        formControlName="dateFormat"
                        [options]="dateFormats">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection" *ngIf="configForm.get('displayType')?.value === 'value'">
                <div class="title">Aggregation Type</div>

                <div class="custom-select">
                    <app-dropdown
                        name="aggregationtype"
                        formControlName="aggregationType"
                        [options]="aggTypes">
                    </app-dropdown>
                </div>
            </div>

            <div class="section empty" *ngIf="configForm.get('displayType')?.value === 'value'">
                <div class="subsection use-abs-val">
                    <cc-checkbox
                        ngDefaultControl
                        formControlName="useAbsVal"
                        [label]="'Display as Absolute Value'"
                        [value]="!!configForm.get('useAbsVal')?.value"
                        (valueChange)="configForm.get('useAbsVal')?.setValue($event)">
                    </cc-checkbox>
                </div>

                <div class="subsection is-ranked">
                    <cc-checkbox
                        ngDefaultControl
                        formControlName="isRanked"
                        [label]="'Convert to Ranked Column'"
                        [value]="!!configForm.get('isRanked')?.value"
                        (valueChange)="configForm.get('isRanked')?.setValue($event)">
                    </cc-checkbox>
                </div>
            </div>

            <div class="section empty" *ngIf="configForm.get('isRanked')?.value && configForm.get('displayType')?.value === 'value'">
                <div class="subsection">&nbsp;</div>

                <div class="subsection">
                    <p class="title">Rank Order</p>

                    <div class="custom-select">
                        <app-dropdown
                            name="rankOrder"
                            formControlName="rankOrder"
                            [options]="rankOrders">
                        </app-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <div class="section general-formatting" *ngIf="configForm.get('displayType')?.value !== 'bar'">
            <p class="title">General Formatting</p>

            <div class="subsection">
                <p class="title">Font Style</p>

                <div class="custom-select">
                    <app-dropdown
                        name="fontstyle"
                        formControlName="fontStyle"
                        [options]="fontStyles">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection" *ngIf="configForm.get('displayType')?.value === 'value' && !configForm.get('isRanked')?.value">
                <p class="title">Format</p>

                <div class="custom-select">
                    <app-dropdown
                        name="numberFormat"
                        formControlName="numberFormat"
                        [options]="numberFormats">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection">
                <p class="title">Alignment</p>

                <div class="custom-select">
                    <app-dropdown
                        name="alignment"
                        formControlName="alignment"
                        [options]="alignmentList">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection" *ngIf="configForm.get('displayType')?.value === 'value' && !configForm.get('isRanked')?.value">
                <p class="title">Decimal Places</p>

                <input type="number" min="0" name="decimal" formControlName="decimalPlaces" />
            </div>

            <div class="section empty" *ngIf="configForm.get('displayType')?.value === 'value' && !configForm.get('isRanked')?.value">
                <div class="subsection">
                    <p class="title">Negative Values</p>

                    <div class="custom-select">
                        <app-dropdown
                            class="{{configForm.get('negativeValue')?.value}} {{configForm.get('negativeValue')?.value!.startsWith('red') ? 'redcolor' : ''}}"
                            name="negative"
                            formControlName="negativeValue"
                            [options]="negativeValues">
                        </app-dropdown>
                    </div>
                </div>

                <div class="subsection">
                    <p class="title">&nbsp;</p>

                    <cc-checkbox
                        ngDefaultControl
                        formControlName="isBlank"
                        [label]="'Format Zeros as Blank Cells'"
                        [value]="!!configForm.get('isBlank')?.value"
                        (valueChange)="configForm.get('isBlank')?.setValue($event)">
                    </cc-checkbox>
                </div>
            </div>
        </div>

        <div class="section url-widget">
            <div class="subsection">
                <p class="title">Link Behavior</p>

                <div class="custom-select">
                    <app-dropdown
                        name="linkBehavior"
                        formControlName="linkBehavior"
                        [options]="linkBehaviorFiltered"
                        [selected]="linkBehaviorSelected"
                        (selectedChange)="updateLinks($event)">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection">
                <p class="title">Link Type</p>

                <div class="custom-select">
                    <app-dropdown
                        name="linkType"
                        formControlName="linkType"
                        [options]="linkTypeFiltered"
                        [selected]="linkTypeSelected"
                        (selectedChange)="updateLinks($event)">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection full-length column-hyperlink" *ngIf="configForm.get('linkType')?.value === 'url' && parentData?.name !== 'instrumentId'">
                <p class="title">HYPERLINK</p>

                <div class="custom-select">
                    <input
                        type="url"
                        name="hyperlink"
                        formControlName="hyperlink"
                        placeholder="Add Hyperlink To Column"
                        autocomplete="off"
                        (focusout)="validateUrl()"
                        [ngClass]="{'has-error': configForm.get('hyperlink')?.invalid && touchedOrDirty('hyperlink')}"/>
                </div>

                <div class="error-message" *ngIf="configForm.get('hyperlink')?.hasError('urlIncorrect') && touchedOrDirty('hyperlink')">
                    Hyperlink is not valid!
                </div>
            </div>
            <div class="subsection full-length column-ddvwidget" *ngIf="configForm.get('linkType')?.value === 'ddvwidget'">
                <p class="title">DDV WIDGET</p>

                <div class="custom-select">
                    <app-dropdown
                        name="ddvWidgets"
                        formControlName="ddvWidget"
                        [options]="ddvWidgets">
                    </app-dropdown>
                </div>

                <div class="error-message" *ngIf="configForm.get('ddvWidget')?.hasError('ddvWidgetInvalid')">
                    Please choose a DDV widget or change the Link Type
                </div>
            </div>

        </div>

        <div class="section conditional-formatting" formArrayName="columnCondition">
            <p class="title">Conditional Formatting</p>

            <ng-container *ngIf="configForm.get('displayType')?.value !== 'bar'; else barBlock">
                <div class="tab-header">
                    <app-visualization-nav-header
                        #visualizationNavTabHeaderForCondition
                        *ngIf="columnConditionVizTab[0].vizTabs.length > 0"
                        [vizTabs]="columnConditionVizTab"
                        (navTabFocus)="onNavTabFocus($event)"
                        (removeTab)="removeCNFormattingAtIndex($event.tab)">
                    </app-visualization-nav-header>

                    <cc-icon
                        class="add-visualization-icon"
                        [icon]="'plus-circle'"
                        [size]="'xsmall'"
                        (iconClicked)="addConditionalFormatters()">
                    </cc-icon>
                </div>

                <div class="parent-tabtype" *ngIf="columnConditionVizTab[0].vizTabs.length > 0">
                    <div *ngFor="let formatter of getColumnConditionControls(); let i = index;">
                        <div class="tab-details" [formGroupName]="i" *ngIf="activeColumnConditionTabIndex === formatter.get('guid')?.value">
                            <div class="section empty">
                                <div class="subsection">
                                    <p class="title">If the value is</p>

                                    <div class="custom-select">
                                        <app-dropdown
                                            name="valuelabel"
                                            formControlName="operatorCondition"
                                            [options]="valueOperators"
                                            (selectedChange)="onOperatorConditionChanged(formatter)">
                                        </app-dropdown>
                                    </div>
                                </div>

                                <div class="subsection">
                                    <p class="title">&nbsp;</p>

                                    <ng-container *ngIf="configForm.get('displayType')?.value === 'value'; else stringBlock">
                                        <div class="range-type" *ngIf="showInputRange(formatter.get('operatorCondition')?.value); else elseBlock">
                                            <input
                                                appInputRestrict
                                                type="number"
                                                allowPattern="{{numberRegex}}"
                                                name="rangefrom"
                                                class="formControl"
                                                formControlName="rangeFrom" />

                                            <span>-</span>

                                            <input
                                                appInputRestrict
                                                type="number"
                                                allowPattern="{{numberRegex}}"
                                                name="rangeto"
                                                class="formControl" formControlName="rangeTo" />
                                        </div>

                                        <ng-template #elseBlock>
                                            <input
                                                appInputRestrict
                                                type="number"
                                                allowPattern="{{numberRegex}}"
                                                name="value"
                                                class="formControl"
                                                formControlName="value" />
                                        </ng-template>
                                    </ng-container>

                                    <ng-template #stringBlock>
                                        <div *ngIf="showInputRange(formatter.get('operatorCondition')?.value); else elseBlock">
                                            <div class="range-type">
                                                <input
                                                    appInputRestrict
                                                    type="text"
                                                    name="rangefrom"
                                                    class="formControl"
                                                    formControlName="rangeFrom"
                                                    autocomplete="off"
                                                    allowPattern="{{ configForm.get('displayType')?.value === 'date' ? dateRegex : null }}"
                                                    placeholder="{{ configForm.get('displayType')?.value === 'date' ? acceptedDateFormat : '' }}" />

                                                <span>-</span>

                                                <input
                                                    appInputRestrict
                                                    type="text"
                                                    name="rangeto"
                                                    class="formControl"
                                                    formControlName="rangeTo"
                                                    autocomplete="off"
                                                    allowPattern="{{ configForm.get('displayType')?.value === 'date' ? dateRegex : null }}"
                                                    placeholder="{{ configForm.get('displayType')?.value === 'date' ? acceptedDateFormat : '' }}" />
                                            </div>
                                        </div>

                                        <ng-template #elseBlock>
                                            <div
                                                    class="custom-select"
                                                    *ngIf="formatter.get('operatorCondition')?.value === 'ADO'; else notAdoBlock">
                                                <app-dropdown
                                                    name="datelabel"
                                                    formControlName="value"
                                                    [options]="fuzzyDates">
                                                </app-dropdown>
                                            </div>

                                            <ng-template #notAdoBlock>
                                                <input
                                                    appInputRestrict
                                                    type="text"
                                                    name="value"
                                                    class="formControl"
                                                    formControlName="value"
                                                    autocomplete="off"
                                                    allowPattern="{{ configForm.get('displayType')?.value === 'date' ? dateRegex : null }}"
                                                    placeholder="{{ configForm.get('displayType')?.value === 'date' ? acceptedDateFormat : '' }}" />
                                            </ng-template>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="section empty">
                                <div class="subsection font-cell">
                                    <p class="title">Set Cell and Font Color</p>

                                    <ng-container
                                        *ngTemplateOutlet="cellAndFontColorTemplate;context: {colors: cellAndFontColors, cellKey: 'cellColor', fontKey: 'fontColor', ctx: formatter}">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-template #barBlock>
                <ng-container *ngFor="let formatter of getColumnConditionControls(); let i = index;">
                    <div class="section empty progress-bar-type" [formGroupName]="i">
                        <div class="subsection">
                            <p class="title">Type</p>

                            <div class="custom-select">
                                <app-dropdown
                                    name="operatorCondition"
                                    formControlName="operatorCondition"
                                    [options]="valueOperators"
                                    [selected]="formatter.get('operatorCondition')?.value"
                                    (selectedChange)="onBarConditionChange(formatter, $event)">
                                </app-dropdown>
                            </div>
                        </div>

                        <div class="subsection value" [hidden]="formatter.get('operatorCondition')?.value === 'AUTO'">
                            <p class="title">Value</p>

                            <div class="range-type">
                                <input
                                    type="number"
                                    name="rangefrom"
                                    class="formControl"
                                    formControlName="rangeFrom"
                                    min="{{formatter.get('operatorCondition')?.value === 'PRCT' ? 0 : ''}}"
                                    max="{{formatter.get('operatorCondition')?.value === 'PRCT' ? 100 : ''}}" placeholder="MIN"
                                    appInputRestrict
                                    allowPattern="{{formatter.get('operatorCondition')?.value === 'NUM' ? numberRegex : barPercentRegex}}" />

                                <span>-</span>

                                <input
                                    type="number"
                                    name="rangeto"
                                    class="formControl"
                                    formControlName="rangeTo"
                                    min="{{formatter.get('operatorCondition')?.value === 'PRCT' ? 0 : ''}}"
                                    max="{{formatter.get('operatorCondition')?.value === 'PRCT' ? 100 : ''}}"
                                    placeholder="MAX"
                                    appInputRestrict
                                    allowPattern="{{formatter.get('operatorCondition')?.value === 'NUM' ? numberRegex : barPercentRegex}}" />
                            </div>
                        </div>

                        <div class="section empty">
                            <div class="subsection">
                                <p class="title">Bar Color</p>

                                <ng-container
                                    *ngTemplateOutlet="colorTemplate;context: {colors: barColors, key: 'barColor', ctx: formatter}">
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </div>

        <div class="save-cancel">
            <button class="empty-btn" type="button" (click)="modalDialogActive.close('cancel')">Cancel</button>

            <button class="fill-btn" type="submit" [disabled]="!configForm.valid">Apply</button>
        </div>
    </form>
</div>

<ng-template #colorTemplate let-colors="colors" let-key="key" let-ctx="ctx">
    <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="el.hidden = true" appScrollToChild>
        <div class="select-component" (click)="el.hidden = !el.hidden">
            <div class="selected-list">
                <span class="square" [style.background]="ctx.get(key)?.value"></span>

                <label>{{ allColorsMap?.get(key)?.[ctx.get(key)?.value ?? ''] }}</label>
            </div>

            <span class="icon-arrow-down"></span>
        </div>

        <div class="dropdown" #el [hidden]=true>
            <ul>
                <li *ngFor="let item of colors;" (click)="onColorSelected(ctx, key, item, el)">
                    <span class="square" [style.background]="item.value"></span>

                    {{ item.label }}
                </li>
            </ul>
        </div>
    </div>
</ng-template>

<ng-template #cellAndFontColorTemplate let-colors="colors" let-cellKey="cellKey" let-fontKey="fontKey" let-ctx="ctx">
    <div class="dropdown-wrapper" appMousedownOutside (mousedownOutside)="el.hidden = true" appScrollToChild>
        <div class="select-component" (click)="el.hidden = !el.hidden">
            <div class="selected-list">
                <span
                        class="font-cell-color"
                        [style.background]="ctx.get(cellKey)?.value"
                        [style.color]="ctx.get(fontKey)?.value">
                    $387,271.54
                </span>
            </div>

            <span class="icon-arrow-down"></span>
        </div>

        <div class="dropdown" #el [hidden]=true>
            <ul class="cell-font-colors">
                <li
                        *ngFor="let item of colors;"
                        (click)="onCellFontColorSelected(ctx, cellKey, fontKey, item, el)"
                        [style.background]="item.cellColor"
                        [style.color]="item.fontColor">
                    <span>$387,271.54</span>
                </li>
            </ul>
        </div>
    </div>
</ng-template>
