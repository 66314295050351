import { Injectable } from '@angular/core';
import { DataGridOptions } from '@ddv/data-grid';
import { GRID_ICONS, VisualizationConfigs } from '@ddv/models';

import { GridConfigService } from './grid-config.service';

@Injectable()
export class SimpleGridConfigService extends GridConfigService {
    override getGridOptions(vizConfigs: VisualizationConfigs): DataGridOptions {
        const gridOptions = super.getGridOptions(vizConfigs);
        if (gridOptions.icons) {
            gridOptions.icons.menu = `<i class="${GRID_ICONS.menu} ag-icon-menu only-filter"/>`;
        }

        if (gridOptions.defaultColDef) {
            gridOptions.defaultColDef.menuTabs = ['filterMenuTab'];
        }
        gridOptions.suppressContextMenu = true;
        return gridOptions;
    }
}
