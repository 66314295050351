import { Injectable } from '@angular/core';
import { StackedAreaKey } from '@ddv/models';
import * as d3 from 'd3';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as d3Shape from 'd3-shape';

import { Axis } from '../models/axis';
import { ChartSettings } from '../models/chart-settings';
import { Element } from '../models/element';
import { ParentChartProperties } from '../models/parent-chart-properties';
import { BaseChartService } from './base-chart.service';
import { StackedAreaChartBrushService } from './stacked-area-chart-brush.service';

const OFFSET_ZERO_VALUE = 0.0000000000000001;

@Injectable()
export class StackedAreaChartService extends BaseChartService {
    protected brushService: StackedAreaChartBrushService | undefined;
    // coming from d3 again
    private chartSvg: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    private stackedAreaDataSource: any[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any

    drawStackedAreaChart(config: ChartSettings, legendVisibility: 'show' | 'hide'): void {
        this.config = config;
        this.legendVisibility = legendVisibility;
        this.initSvg();
        this.prepareData(config);
        this.initAxis();
        this.drawAxis();
        this.drawStackedArea();
        this.applyStackedAreaChartConfigs();
        if (this.config.showBrush) {
            this.brushService = new StackedAreaChartBrushService();
            this.brushService.addBrush(this);
        }
    }

    resizeBrush(timeline: { notation: string, scale?: number }): void {
        if (this.brushService) {
            this.brushService.resizeBrush(timeline, this);
        }
    }

    override getParentChartProperties(): ParentChartProperties {
        return {
            ...super.getParentChartProperties(),
            area: this.area,
        };
    }

    private drawStackedArea(): void {
        if (!this.config) {
            throw new Error('cannot drawStackedArea without config');
        }

        this.appendRectArea();
        const series = this.config.series[0];
        const xField = series.xField[0];
        const yField = series.yField[0];
        const slicer = this.config.selectedSlicer?.value ?? '';

        const stack = this.getStack();
        this.stackedAreaDataSource = this.getStackedAreaDataSource(xField, yField, slicer);
        this.updateYAxisBasedOnStackedAreaDataSource(xField);
        this.updateYAxisTickLabelsBasedOnStackedAreaDataSource(this.config.axis[1][0]);
        if (this.config.showGridLines) {
            this.updateGridLinesBasedOnStackedAreaDataSource();
        }
        const stackedData = stack(this.stackedAreaDataSource);

        this.removeClipPath();
        this.appendClipPath();

        this.area = this.getChartArea(xField);
        this.chartSvg = this.getChartSvg(stackedData);

        if (this.config.highlightXValueOnHover && this.config.highlightYValueOnHover) {
            this.appendLine();
        }

        this.handleStackedAreaMouseEvents(xField);
    }

    private applyStackedAreaChartConfigs(): void {
        if (this.config?.hiddenLegends) {
            this.restoreStackedAreaHighlighting();
        }

        if (this.config?.legend?.showCustom) {
            this.createCustomLegends();
        }
    }

    private restoreStackedAreaHighlighting(): void {
        this.config?.hiddenLegends?.forEach((legend) => {
            this.svg.selectAll('.stacked-area').filter((elt: any) => elt.key === legend).classed('disabled', true); // eslint-disable-line @typescript-eslint/no-explicit-any
        });
    }

    private createCustomLegends(): void {
        this.legendsService.setDefaults({
            svg: this.svg,
            config: this.config!,
            dataSource: this.dataSource! || this.config?.dataSource,
            onChartClicked: (elt: Element, chartUpdated: boolean) => this.onChartClicked(elt, chartUpdated),
        });
        this.legendsService.createCustomLegends();
    }

    private getStack(): d3.Stack<any, {[key: string]: number }, string> { // eslint-disable-line @typescript-eslint/no-explicit-any
        const keys = d3.map(this.dataSource ?? [], (d) => d.key);

        return d3Shape
            .stack()
            .keys(keys)
            .value((d, key) => d[key])
            .order((series) => getStackedAreaOrder(series))
            .offset(d3.stackOffsetDiverging);
    }

    private getStackedAreaDataSource(xField: string, yField: string, slicer: string): { [key: string]: any }[] { // eslint-disable-line @typescript-eslint/no-explicit-any
        const stackedAreaDataSource: { [key: string]: any }[] = []; // eslint-disable-line @typescript-eslint/no-explicit-any
        this.config?.dataSource.forEach((dataSource) => {
            const datum = stackedAreaDataSource.find((d) => d[xField].getTime() === dataSource[xField].getTime());
            if (datum) {
                datum[dataSource[slicer]] = dataSource[yField] || OFFSET_ZERO_VALUE;
            } else {
                stackedAreaDataSource.push({ [xField]: dataSource[xField], [dataSource[slicer]]: dataSource[yField] || OFFSET_ZERO_VALUE });
            }
        });
        return stackedAreaDataSource;
    }

    private updateYAxisBasedOnStackedAreaDataSource(xField: string): void {
        this.y = d3.scaleLinear()
            .domain([this.getYDomainLowerBound(xField), this.getYDomainUpperBound(xField)])
            .range([this.height - this.getChartOffsets().y, 0]);
    }

    private updateYAxisTickLabelsBasedOnStackedAreaDataSource(yAxis: Axis): void {
        const isMaximized = false;
        this.svg.select('.axis--y')
            .call(this.positionAxis(yAxis, this.y))
            .selectAll('text')
            .selectAll('.tick text')
            .call((texts: any) => this.wrapYAxisTickLabels(texts, yAxis, isMaximized, this)); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private updateGridLinesBasedOnStackedAreaDataSource(): void {
        this.svg.selectAll('.grid').remove();
        this.addGridLines();
    }

    private getChartArea(xField: string): d3.Area<[number, number]> {
        return d3.area()
            .curve(d3.curveMonotoneX)
            .defined((d) => !isNaN(d[0]) && !isNaN(d[1]))
            .x((d) => this.x((d as any).data[xField])) // eslint-disable-line @typescript-eslint/no-explicit-any
            .y0((d) => this.y(d[0]))
            .y1((d) => this.y(d[1]));
    }

    private getChartSvg(stackedData: d3.Series<{ [key: string]: number }, string>[]): any { // eslint-disable-line @typescript-eslint/no-explicit-any
        const isChartTransparent = isColorTransparent(this.dataSource?.[0].color ?? '');

        return this.svg.selectAll('.areas')
            .data(stackedData)
            .enter()
            .append('g')
            .attr('class', 'areas')
            .append('path')
            .attr('class', 'stacked-area')
            .style('clip-path', `url(#${this.config?.parentSelector}-clip)`)
            .attr('data-legend', (d: any) => d.key) // eslint-disable-line @typescript-eslint/no-explicit-any
            .style('fill', (d: any) => this.dataSource?.find((datum) => datum.key === d.key)?.color) // eslint-disable-line @typescript-eslint/no-explicit-any
            .style('stroke', (d: any) => this.getStackedAreaBorderColor(d)) // eslint-disable-line @typescript-eslint/no-explicit-any
            .style('stroke-width', () => isChartTransparent ? '0.8px' : '0.5px')
            .attr('d', this.area);
    }

    private handleStackedAreaMouseEvents(xField: string): void {
        this.svg
            .selectAll('.rectdrawarea')
            .on('mousemove', (event: MouseEvent) => {
                this.handleMouseMove(event, xField);
            })
            .on('mouseout', () => {
                this.handleMouseOut();
            });

        this.svg
            .selectAll('.stacked-area')
            .on('mousemove', (event: MouseEvent) => {
                this.handleMouseMove(event, xField);
            })
            .on('mouseout', () => {
                this.handleMouseOut();
            })
            .on('click touchend', (event: MouseEvent, data: StackedAreaKey) => {
                this.highlightStackedArea(data);
                this.onChartClicked({ data }, !this.config?.highlight?.data);
            });
    }

    private highlightStackedArea(data: StackedAreaKey): void {
        const selectedStackedArea = this.svg.selectAll('.stacked-area').filter((area: any) => area.key === data.key); // eslint-disable-line @typescript-eslint/no-explicit-any
        const unselectedStackedArea = this.svg.selectAll('.stacked-area').filter((area: any) => area.key !== data.key); // eslint-disable-line @typescript-eslint/no-explicit-any

        if (selectedStackedArea.filter('.disabled').size()) {
            selectedStackedArea.classed('disabled', false);
            unselectedStackedArea.classed('disabled', true);
            this.highlightStackedAreaLegends(data);
            this.config!.highlight!.data = data;
        } else if (unselectedStackedArea.filter('.disabled').size()) {
            this.svg.selectAll('.stacked-area').classed('disabled', false).classed('enabled', false);
            d3.select(this.svg.node().parentNode).selectAll('.legend-items')
                .classed('disabled', false)
                .classed('enabled', false);
            this.config!.highlight!.data = undefined;
        } else {
            unselectedStackedArea.classed('disabled', true);
            this.highlightStackedAreaLegends(data);
            this.config!.highlight!.data = data;
        }

        this.config!.hiddenLegends = this.svg.selectAll('.stacked-area.disabled').data().map((datum: any) => datum.key); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private highlightStackedAreaLegends(data: StackedAreaKey): void {
        d3.select(this.svg.node().parentNode).selectAll('.legend-items')
            .classed('disabled', (n: any, index: number, elem: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                elem[index].enabled = false;
                return data.key !== n.key;
            })
            .classed('enabled', (n: any) => data.key === n.key); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private handleMouseMove(event: MouseEvent, xField: string): void {
        if (this.config?.showTooltip) {
            this.createTooltip(event, xField);
        }

        if (this.config?.highlightXValueOnHover && this.config.highlightYValueOnHover) {
            this.showHoverLines(event);
        }
    }

    private handleMouseOut(): void {
        if (this.config?.showTooltip) {
            this.removeTooltip();
        }

        if (this.config?.highlightXValueOnHover && this.config.highlightYValueOnHover) {
            this.revertLinePosition(this.config.highlightYValueOnHover, this.config.highlightXValueOnHover);
        }
    }

    private createTooltip(event: MouseEvent, xField: string): void {
        const mousePoint = d3.pointer(event);
        const xValue = this.x.invert(mousePoint[0]);
        const tooltipValue = { [xField]: xValue };
        const tooltipColors = this.getTooltipColors();
        const html = this.config?.series[0].tooltipHTML?.({ tooltipValue, tooltipColors }) ?? '';

        if (html.includes('tr')) {
            this.createTooltipDiv(event.pageX, event.pageY, html);
        } else {
            this.removeTooltip();
        }
    }

    private createTooltipDiv(pageX: number, pageY: number, html: string): void {
        this.getTooltipDiv()
            .style('display', 'block')
            .style('left', `${pageX + 15}px`)
            .style('top', `${pageY + 15}px`)
            .html(html);
    }

    private removeTooltip(): void {
        this.getTooltipDiv().classed('hidden', true);
    }

    private getTooltipColors(): Map<string, string> {
        return this.dataSource?.reduce((colors, datum) => colors.set(datum.key, datum.color), new Map()) ?? new Map();
    }

    private showHoverLines(event: MouseEvent): void {
        const enableAxis = { left: this.config?.axis[1][0].customClass !== 'hide', right: false };
        const mousePoint = d3.pointer(event);
        this.changeLinePosition(!!this.config?.highlightYValueOnHover, !!this.config?.highlightXValueOnHover, mousePoint, enableAxis);
    }

    private getYDomainLowerBound(xField: string): number {
        const lowerBoundMultiplier = 1.2;
        return Math.ceil(d3.min(this.stackedAreaDataSource, (d) => {
            let total = 0;
            Object.keys(d).forEach((key) => {
                if (key !== xField && d[key] < 0) {
                    total += d[key];
                }
            });
            return total;
        }) as any) * lowerBoundMultiplier; // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private getYDomainUpperBound(xField: string): number {
        const upperBoundMultiplier = 1.2;
        return Math.ceil(d3.max(this.stackedAreaDataSource, (d) => {
            let total = 0;
            Object.keys(d).forEach((key) => {
                if (key !== xField && d[key] > 0) {
                    total += d[key];
                }
            });
            return total;
        }) as any) * upperBoundMultiplier;  // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private getStackedAreaBorderColor(datum: { key: string }): string {
        const color = this.dataSource?.find((d) => d.key === datum.key)?.color ?? '';
        return isColorTransparent(color) ? color.replace('0.6', '1') : '#fff';
    }
}

export function getStackedAreaOrder(series: d3.Series<{ [key: string]: number }, string>): number[] {
    const seriesTotals: any = [];  // eslint-disable-line @typescript-eslint/no-explicit-any
    series.forEach((currentSeries) => {
        seriesTotals.push(currentSeries.reduce((total, s: any) => {  // eslint-disable-line @typescript-eslint/no-explicit-any
            if (!isNaN(s[1])) {
                return total + (s as unknown as number[])[1];
            }
            return total;
        }, 0));
    });

    return d3.stackOrderNone(series).sort((a, b) => {
        if ((series[a] as any).key === 'others') { // eslint-disable-line @typescript-eslint/no-explicit-any
            return 1;
        }
        if ((series[b] as any).key === 'others') { // eslint-disable-line @typescript-eslint/no-explicit-any
            return -1;
        }
        if (seriesTotals[a] < 0 && seriesTotals[b] < 0) {
            return seriesTotals[a] - seriesTotals[b];
        }
        return seriesTotals[b] - seriesTotals[a];
    });
}

export function isColorTransparent(color: string): boolean {
    return color.includes('rgba');
}
