import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Alert, AlertType } from './model';

@Injectable()
export class AlertService {
    private readonly isAlertSrc: Subject<Alert | undefined> = new Subject();

    getAlert(): Observable<Alert | undefined> {
        return this.isAlertSrc.asObservable();
    }

    success(message: string): void {
        this.alert(AlertType.Success, message);
    }

    error(message: string, rootCauseMessage?: string, rootCauseStacktrace?: string[], requestId?: string): void {
        this.alert(AlertType.Error, message, rootCauseMessage, rootCauseStacktrace, requestId);
    }

    info(message: string): void {
        this.alert(AlertType.Info, message);
    }

    warn(message: string): void {
        this.alert(AlertType.Warning, message);
    }

    message(message: string): void {
        this.alert(AlertType.Message, message);
    }

    alert(type: AlertType, message: string, rootCauseMessage?: string, rootCauseStacktrace?: string[], requestId?: string): void {
        this.isAlertSrc.next({
            type,
            message,
            rootCauseMessage,
            rootCauseStacktrace,
            requestId,
        });
    }

    clear(): void {
        this.isAlertSrc.next(undefined);
    }
}
