import { Inject, NgModule, Optional } from '@angular/core';
import { ApiConfiguration, apiRootForService, defaultApiConfiguration } from '@ddv/http';

import { ApmErrorLogger } from './apm-error-logger.service';
import { BasicErrorLogger } from './basic-error-logger.service';
import { ErrorLogger } from './error-logger';
import { errorHandlerConfigInjectionToken, ErrorLoggerService } from './error-logger.service';

@NgModule({
    providers: [
        ErrorLoggerService,
        { provide: ErrorLogger, useClass: ErrorLoggerService, multi: true },
        { provide: ErrorLogger, useClass: BasicErrorLogger, multi: true },
        { provide: ErrorLogger, useClass: ApmErrorLogger, multi: true },
        {
            provide: errorHandlerConfigInjectionToken,
            useFactory: (c: ApiConfiguration | null): { url: string, shouldRelay: boolean } => {
                const config = c || defaultApiConfiguration;
                return {
                    url: apiRootForService('errorLoggerLocation', config),
                    shouldRelay: config.production,
                };
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
    ],
})
export class ErrorHandlingModule {}
