import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ColorMetadataService, ColorType } from '@ddv/charts';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { AppWidgetState, ConfigItem, Fund, VisualizationConfigs, WidgetSnapshot } from '@ddv/models';
import { FundsService } from '@ddv/reference-data';
import { deepClone } from '@ddv/utils';
import { SelectedWidgetRelayService } from '@ddv/visualizations';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WidgetsService {
    private clientCode = '';
    private theme: Theme = Theme.light;

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
        private readonly fundsService: FundsService,
        private readonly relay: SelectedWidgetRelayService,
        private readonly themeService: ThemeService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode: string) => this.clientCode = clientCode);
        this.themeService.currentTheme$.subscribe((theme: Theme) => this.theme = theme);
    }

    fetchWidget(id: number): Observable<AppWidgetState> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, `widgets/${id}`);
    }

    fetchWidgetTags(searchTerm: string, clientCode: string): Observable<string[]> {
        return this.ddvApiService.invokeServiceWithParams(clientCode, `widgets/tags?tagSearchString=${searchTerm}`);
    }

    fetchWidgetInfo(id: number): Observable<AppWidgetState> {
        return combineLatest([this.fetchWidget(id), this.fundsService.funds()])
            .pipe(map(([widget, funds]) => {
                this.updateIsWidgetGlobal(!!widget.isGlobal);
                return this.mapWidgetState(widget, funds);
            }));
    }

    fetchAllWidgets(): Observable<WidgetSnapshot[]> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'widgets/all');
    }

    removeWidget(widgetId: number): Observable<void> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `widgets/${widgetId}`, 'DELETE', null);
    }

    fetchWidgetInfoForMultiClient(id: number): Observable<AppWidgetState> {
        return this.fetchWidget(id)
            .pipe(map((widget) => {
                this.updateIsWidgetGlobal(!!widget.isGlobal);
                return this.mapWidgetState(widget, []);
            }));
    }

    fetchWidgetsUsingDatasetDefinition(datasetId: number): Observable<{ id: number, name: string }[]> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'widgets', { 'dataset-definition': datasetId });
    }

    saveCoreWidget(config: AppWidgetState, isMultiClient: boolean): Observable<AppWidgetState> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const payload: any = deepClone(config);

        if (config.widgetFilters?.funds) {
            payload.widgetFilters.funds = config.widgetFilters.funds.map((f) => ({ fundId: f.fundId, fundName: f.fundName }));
        }

        const method = config.id ? 'PUT' : 'POST';

        if (isMultiClient) {
            return this.ddvApiService.invokeServiceWithBody<Partial<AppWidgetState>>(this.clientCode, 'widgets', method, payload)
                .pipe(map((widget) => new AppWidgetState(widget)));
        }

        return combineLatest([
            this.ddvApiService.invokeServiceWithBody<Partial<AppWidgetState>>(this.clientCode, 'widgets', method, payload),
            this.fundsService.funds(),
        ]).pipe(map(([widget, funds]) => new AppWidgetState(widget, funds)));
    }

    validateWidgetName(name: string): Observable<boolean> {
        return this.ddvApiService.invokeServiceWithParams(this.clientCode, 'widgets/isNameValid', { name: encodeURIComponent(name) });
    }

    private mapWidgetState(widget: Partial<AppWidgetState>, funds: Fund[]): AppWidgetState {
        this.mapWidgetVizConfigs(widget, this.theme);
        return new AppWidgetState(widget, funds);
    }

    private mapWidgetVizConfigs(widget: Partial<AppWidgetState>, theme: Theme): void {
        const advancedGridVizConfig = widget.visualizationConfigs?.find((vc: VisualizationConfigs) => vc.visualizationType === 'ADVANCED_GRID');
        if (advancedGridVizConfig) {
            this.mapGridConfigColumnConditionColors(advancedGridVizConfig.configs?.values ?? [], theme);
        }
        const simpleGridVizConfig = widget.visualizationConfigs?.find((vc: VisualizationConfigs) => vc.visualizationType === 'SIMPLE_GRID');
        if (simpleGridVizConfig) {
            this.mapGridConfigColumnConditionColors(simpleGridVizConfig.configs?.values ?? [], theme);
        }

        widget.visualizationConfigs?.forEach((vc: VisualizationConfigs) => {
            if (vc.visualizationType === 'LINE_CHART') {
                if (vc.configs?.slicers?.length) {
                    this.mapLineChartConfigCustomStyles(vc.configs.slicers);
                } else if (vc.configs?.values?.length) {
                    this.mapLineChartConfigValueColorNames(vc.configs.values);
                }
            } else {
                if (vc.configs?.slicers?.find((slicer) => slicer.colorType === ColorType[ColorType.SOLID])) {
                    this.mapSolidColors(vc.configs.slicers);
                }

                if (vc.configs?.slicers?.find((slicer) => slicer.colorType === ColorType[ColorType.MULTICOLORED])) {
                    this.mapMultiColors(vc.configs.slicers);
                }
            }
        });
    }

    private mapGridConfigColumnConditionColors(values: ConfigItem[], theme: Theme): void {
        values.forEach((v) => {
            if (v.columnCondition?.length) {
                v.columnCondition.forEach((colCondition) => {
                    ColorMetadataService.mapOutdatedColors(theme, colCondition);
                    ColorMetadataService.mapThemeColors(theme, colCondition);
                });
            }
        });
    }

    private mapLineChartConfigCustomStyles(values: ConfigItem[]): void {
        values.forEach((v) => {
            v.configCustomStyles = v.configCustomStyles?.filter((attribute) => {
                return !ColorMetadataService.isLineChartConfigCustomStyleOutdated(attribute.attributeColorName);
            });
        });
    }

    private mapLineChartConfigValueColorNames(values: ConfigItem[]): void {
        values.forEach((value, index) => {
            value.colorName = ColorMetadataService.mapOutdatedLineChartValueColorName(value.colorName ?? '', index);
        });
    }

    private mapSolidColors(values: ConfigItem[]): void {
        values.forEach((v) => {
            v.colorName = ColorMetadataService.mapOutdatedSolidColorName(v.colorName ?? '');
        });
    }

    private mapMultiColors(values: ConfigItem[]): void {
        values.forEach((v) => {
            v.colorName = ColorMetadataService.mapOutdatedMultiColorName(v.colorName ?? '');
        });
    }

    updateIsWidgetGlobal(isWidgetGlobal: boolean): void {
        this.relay.relayIsWidgetGlobal(isWidgetGlobal);
    }

    clearAllFilters(widgetId: number): void {
        this.relay.clearAllFilters(widgetId);
    }
}
