import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams, IRowNode } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { CheckboxValue, EDITABLE_CHECKBOX_FIELD } from '@ddv/models';

import { TradeFileDetailsService } from '../../services/trade-file-details.service';

@Component({
    selector: 'app-checkbox-header',
    templateUrl: './checkbox-header.component.html',
    styleUrls: ['./checkbox-header.component.scss'],
})
export class CheckboxHeaderComponent extends MultiSubscriptionComponent implements IHeaderAngularComp {
    value: CheckboxValue;
    private params: IHeaderParams | undefined;

    constructor(private readonly tradeFileDetailsService: TradeFileDetailsService) {
        super();
    }

    agInit(params: IHeaderParams): void {
        this.params = params;
        this.toggleHeaderCheckbox();

        this.subscribeTo(this.tradeFileDetailsService.gridFilterChanged, (gridFilterChanged) => {
            if (gridFilterChanged) {
                this.toggleHeaderCheckbox();
            }
        });
    }

    refresh(): boolean {
        this.toggleHeaderCheckbox();
        return true;
    }

    onClick(value: CheckboxValue): void {
        this.value = value;
        if (this.value) {
            this.params!.api.selectAllFiltered();
            this.params!.api.forEachNodeAfterFilter((node: IRowNode) => {
                if (node.data) {
                    node.data[EDITABLE_CHECKBOX_FIELD] = true;
                }
            });
        } else {
            this.params!.api.deselectAllFiltered();
            this.params!.api.forEachNodeAfterFilter((node: IRowNode) => {
                node.data[EDITABLE_CHECKBOX_FIELD] = false;
            });
        }
        this.params!.api.refreshCells({ force: true });
    }

    private toggleHeaderCheckbox(): void {
        const rows = { selected: 0, unselected: 0 };
        this.params!.api.forEachNodeAfterFilter((node: IRowNode) => {
            if (node.isSelected()) {
                rows.selected += 1;
            } else {
                rows.unselected += 1;
            }
        });

        this.value = rows.selected ?
            (rows.unselected ? undefined : true) :
            false;
        this.params!.api.refreshCells({ force: true });
    }
}
