<cc-icon
    [size]="'medium'"
    [icon]="iconName"
    [color]="'var(--grid-action-color)'"
    [hoverColor]="'var(--grid-action-color-hover)'"
    [highlight]="highlight"
    (iconClicked)="onClick()"
>
</cc-icon>
<span class="action-name">{{actionName}}</span>
