<div class="dialog-wrapper">
    <div class="list-view-toggle" *ngIf="isHIDataAvailable">
        <div class="toggle-slider">
            <label for="list-view-toggle" class="text-label" [ngClass]="{'grey-text': !isDatePickerChecked}">
                <svg  viewBox="0 0 64 64" width="16" height="16">
                    <path d="M52,10H44V8a2,2,0,0,0-4,0v2H24V8a2,2,0,0,0-4,0v2H12a4,4,0,0,0-4,4V52a4,4,0,0,0,4,4H52a4,4,0,0,0,4-4V14A4,4,0,0,0,52,10Zm0,42H12V26H52Zm0-30H12V14h8v2a2,2,0,0,0,4,0V14H40v2a2,2,0,0,0,4,0V14h8Z"/>
                </svg>
            </label>

            <label class="cb-toggle">
                <input
                    id="list-view-toggle"
                    name="list-view-toggle"
                    type="checkbox"
                    [ngModel]="isDatePickerChecked"
                    (change)="isDatePickerChecked = !isDatePickerChecked">
                <span class="tog-slider"></span>
            </label>

            <label for="list-view-toggle" class="text-label" [ngClass]="{'grey-text': isDatePickerChecked}">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
                    <line stroke-linecap="round" stroke-miterlimit="10" x1="1.842" y1="3.746" x2="14.502" y2="3.746"/>
                    <line stroke-linecap="round" stroke-miterlimit="10" x1="1.842" y1="11.835" x2="14.502" y2="11.835"/>
                    <line stroke-linecap="round" stroke-miterlimit="10" x1="1.842" y1="7.802" x2="14.502" y2="7.802"/>
                </svg>
            </label>
        </div>
    </div>

    <span class="errormessage" *ngIf="errorMessage">{{ errorMessage }}</span>

    <div>
        <label class="datepicker-label">Date</label>
        <div class="datepicker-wrapper">
            <app-datepicker
                [calendarOptions]="{allowPrev: true, allowNext: true, allowRange: false, single: true}"
                [fuzzyDates]="fuzzyDates"
                [year]="year"
                [month]="month"
                [date]="date"
                [isHIDataAvailable]="isHIDataAvailable"
                [showCalendar]="isDatePickerChecked"
                [showListView]="!isDatePickerChecked"
                (dateSelected)="onDateSelected($event)"
                (errorMessageChange)="showError($event)">
            </app-datepicker>

            <button class="fill-btn submit-btn" type="button" (click)="emitDate()">Apply</button>
        </div>
    </div>

    <div *ngIf="isHIDataAvailable && isDatePickerChecked" class="key-wrapper" appMousedownOutside (mousedownOutside)="keyModalOpen = false">
        <span class="key" (click)="keyModalOpen = !keyModalOpen">
            Key <span class="icon-arrow-right"></span>
        </span>
        <div *ngIf="keyModalOpen" class="key-modal">
            <span *ngFor="let postedState of postedStates" class="key-text">
                <span class="{{postedState.class}}">21</span> {{ postedState.text }}
            </span>
        </div>
    </div>
</div>
