export type FilterCriteria = 'INCLUDE' | 'EXCLUDE' | 'EQ' | 'NEQ' | 'GT' | 'GTE' | 'LT' | 'LTE' | 'BETWEEN';

export function getAvailableCriteriaOptions(): FilterCriteria[] {
    return ['INCLUDE', 'EXCLUDE', 'EQ', 'NEQ', 'GT', 'GTE', 'LT', 'LTE', 'BETWEEN'];
}

export function getAvailableOperators(): string[] {
    return [
        'Includes',
        'Excludes',
        'Equals',
        'Not equals',
        'Greater than',
        'Greater than or equal to',
        'Less than',
        'Less than or equal to',
        'Between',
    ];
}
