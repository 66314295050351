import { Inject, NgModule, Optional } from '@angular/core';
import { ApiConfiguration, apiRootForService, defaultApiConfiguration } from '@ddv/http';

import { ddvWsConnectionUrlToken, ServerSocketService } from './server-socket.service';
import { SocketUserComponent } from './socket.component';

@NgModule({
    declarations: [
        SocketUserComponent,
    ],
    exports: [
        SocketUserComponent,
    ],
    providers: [
        ServerSocketService,
        {
            provide: ddvWsConnectionUrlToken,
            useFactory: (c: ApiConfiguration | null): string => {
                const httpUrl = apiRootForService('ddvMWLocation', c || defaultApiConfiguration);
                return `${httpUrl.replace('https://', 'wss://')}ws`;
            },
            deps: [[new Inject('apiConfig'), new Optional()]],
        },
    ],
})

export class SocketModule { }
