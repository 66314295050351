import { Injectable } from '@angular/core';
import { NumberValue } from 'd3';
import * as d3 from 'd3';

import { BarChartData } from '../../models/chart-data';
import { ChartPosition } from './horizontal-bar-chart-position.service';
import { copyAndConcat } from './utils';

@Injectable()
export class HorizontalBarChartScaleService {
    // this scale is used to convert a bar's value to its left/right position
    private horizontalScale: d3.ScaleLinear<number, number> | undefined;
    // this scale is used for two things:
    //  - to provide the "thickness" for a bar based on the number of keys
    //  - to map a given key to the vertical position of the bar
    private verticalScale: d3.ScaleBand<string> | undefined;

    init(
        position: ChartPosition,
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        hasNegativeValues: boolean,
        padding: { padding: number, align: number } = { padding: .3, align: .3 },
    ): void {
        this.createHorizontalScale(position.widthForBars - position.margin.right, data, compareData, hasNegativeValues);
        this.createVerticalScale(position.heightForBars, data, compareData, padding);
    }

    // this is always going to be 1 or 2 depending on whether or not there are negative values
    horizontalDomainLength(): number {
        return this.horizontalScale?.domain().length ?? 0;
    }

    toHorizontalAxis(): d3.Axis<NumberValue> {
        if (!this.horizontalScale) {
            throw new Error('cannot convert undefined horizontal scale into an axis');
        }

        return d3.axisBottom(this.horizontalScale);
    }

    toLeftAxis(): d3.Axis<string> {
        if (!this.verticalScale) {
            throw new Error('cannot convert undefined vertical scale into an axis');
        }

        return d3.axisLeft(this.verticalScale);
    }

    // this is the left edge of the bar.  it will always be the x position for the "0" in the scale
    // the scale has no actual notion of negative though, so the x position for 0 could be fairly far to the right, so like 80
    // this is very similar to the bar width.  we should look at why/how they are really different
    horizontalValueForBar(bar: BarChartData): number {
        return this.horizontalScale?.(Math.min(0, Number(bar.value.value))) ?? 0;
    }

    horizontalPositionToValue(width: number): number {
        return this.horizontalScale?.invert(width) ?? 0;
    }

    valueToHorizontalPosition(value: number): number {
        return this.horizontalScale?.(value) ?? 0;
    }

    widthForBar(d: BarChartData, hasNegativeValues: boolean): number {
        const xValue = this.horizontalScale?.((d.value.value ?? 0) as d3.NumberValue) ?? 0;
        return hasNegativeValues ? Math.abs(xValue - (this.horizontalScale?.(0) ?? 0)) : xValue;
    }

    maxHorizontalPosition(): number {
        return this.horizontalScale?.range()[1] ?? 0;
    }

    // this gives the top position
    verticalPositionForBar(bar: BarChartData): number {
        const thickness = this.verticalScale?.bandwidth() ?? 0;
        const verticalCenterOfBar = this.keyToHeight(bar.key.value);
        return thickness > 100 ? verticalCenterOfBar + (thickness / 2) - 50 : verticalCenterOfBar;
    }

    barThickness(): number {
        return Math.min(this.verticalScale?.bandwidth() ?? 0, 100);
    }

    // this gives the height for the mid-point of the bar
    keyToHeight(key: string): number {
        return this.verticalScale?.(key) ?? 0;
    }

    private createHorizontalScale(
        maxWidth: number,
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        hasNegativeValues: boolean,
    ): void {
        this.horizontalScale = d3.scaleLinear().range([0, maxWidth]);

        const tempDataSource = copyAndConcat(data, compareData);
        const xFieldAccessorFn = (d: BarChartData): number => Number(d.value.value);

        this.horizontalScale
            ?.domain([
                hasNegativeValues ? d3.min(tempDataSource, xFieldAccessorFn) ?? 0 : 0,
                maxDomainValue(tempDataSource, xFieldAccessorFn),
            ])
            .nice();
    }

    private createVerticalScale(
        heightForBars: number,
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        padding: { padding: number, align: number },
    ): void {
        const tempDataSource = copyAndConcat(data, compareData);
        // Domain is in reverse order because d3 views bottommost item as "first" and users
        // think of topmost item as "first", thus the reduceRight
        const domain = tempDataSource.reduceRight((set, bar) => set.add(bar.key.value), new Set<string>());
        this.verticalScale = d3.scaleBand()
            .domain(domain)
            .range([heightForBars, 0])
            .padding(padding.padding)  // this controls how much space to leave between bars
            .align(padding.align);
    }
}

function maxDomainValue(data: BarChartData[], accessorFn: (datum: BarChartData) => number | undefined | null): number {
    const maxValue = d3.max(data, accessorFn) ?? 0;
    return maxValue < 0 ? 0 : maxValue;
}
