import { Injectable } from '@angular/core';
import { ColorConfig } from '@ddv/models';
import * as d3 from 'd3';

import { Drilldown } from '../models/drilldown';
import { Level } from '../models/level';

@Injectable()
export class DrilldownService {
    levelList: Level[] = [];
    drillConfig: Config | undefined;

    constructor() {
        this.init();
    }

    init(): void {
        this.drillConfig = {
            level: 0,
            selector: 'body',
            keys: [],
        };
        this.levelList = [];
    }

    setDefaults(config: Config, selector: string): void {
        this.drillConfig = config;
        this.drillConfig.selector = selector;
        if (this.drillConfig.defaultLevel) {
            this.drillConfig.level = this.drillConfig.defaultLevel;
        } else {
            this.drillConfig.level = 0;
            this.levelList = [];
        }
    }

    setData(data: any[], color: string, label?: string): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.levelList.filter((aLevel) => aLevel.label === 'others').length >= (this.drillConfig?.keys.length ?? 0) &&
            this.levelList.some((aLevel) => aLevel.label === label)) {
            return;
        }
        const level: Level = {
            id: this.drillConfig?.level ?? 0,
            label: label || this.getLabel(),
            dataSet: data,
            color,
        };
        this.levelList.push(level);
    }

    getData(all?: boolean): Level | any[] | undefined { // eslint-disable-line @typescript-eslint/no-explicit-any
        const index = this.getLevel();
        const source = this.levelList.find((d) => d.id === index);
        if (source) {
            return all ? source : source.dataSet;
        }

        return undefined;
    }

    addLevels(levelUpdateCB: (data: any) => void): void | boolean { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!this.drillConfig?.level) {
            return false;
        }
        let textOffset = 0;
        const textConfig = this.drillConfig.text ? this.drillConfig.text : {
            size: 12,
            x: 12,
            y: 12,
        };
        this.levelList.forEach((d: Level) => {
            if (d.id > (this.drillConfig?.level ?? 0)) {
                return;
            }

            let dg: any = d3.select(this.drillConfig?.selector ?? '').select('svg').selectAll('.drilldown'); // eslint-disable-line @typescript-eslint/no-explicit-any
            const drillLevelClass = d.id === this.drillConfig?.level ? ' active' : '';
            if (!dg.node()) {
                dg = d3.select(this.drillConfig?.selector ?? '').select('svg')
                    .append('g')
                    .attr('class', 'drilldown')
                    .attr('transform', 'translate( 0,0)');
            }
            const g = dg.append('g').attr('class', `drill-key${drillLevelClass}`);
            g.datum(d);
            const text = g.append('text')
                .text((l: { label?: string }) => l.label)
                .style('font-size', textConfig.size)
                .attr('x', textConfig.x)
                .attr('y', textConfig.y);
            g.on('click', (_: MouseEvent, l: Level) => {
                let chartUpdated = false;
                if (this.drillConfig?.level !== l.id) {
                    this.drillConfig!.level = l.id;
                    chartUpdated = true;
                    this.levelList.splice(this.drillConfig!.level + 1);
                    levelUpdateCB(d);
                    if (typeof this.drillConfig?.onLevelListClicked === 'function') {
                        this.drillConfig.onLevelListClicked(l.dataSet, chartUpdated);
                    }
                }
            });
            const currentSvg = d3.select(this.drillConfig?.selector ?? '').select('svg');
            const topRectWrapper = currentSvg.select('.rectWrapperTop').node();
            const topValue = topRectWrapper ?
                (currentSvg.select('.legend').node() as SVGGradientElement).getBoundingClientRect().height : 0;
            g.attr('transform', `translate(${textOffset},${topValue})`);
            const textNodes = text.nodes();
            const xValue = Math.ceil(textNodes[0].getBoundingClientRect().width) + 5;
            const yValue = 3;
            textOffset += xValue + 6;
            if (this.drillConfig?.delimImg && (d.id !== this.drillConfig.level)) {
                g.append('path')
                    .attr('class', 'domain')
                    .attr('stroke', '#a4a3a2')
                    .attr('fill', '#a4a3a2')
                    .attr('d', 'M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z')
                    .attr('transform', `translate(${xValue},${yValue})`);
            }
        });
    }

    shouldContinueDrilling(): boolean {
        const level = this.getLevel() - this.levelList.filter((level1) => level1.label === 'others').length;
        return level < ((this.drillConfig?.keys.length ?? 0) - 1);
    }

    isLastLevel(): boolean {
        const level = this.getLevel() - this.levelList.filter((level1) => level1.label === 'others').length;
        return level === ((this.drillConfig?.keys.length ?? 0) - 1);
    }

    updateLevel(): void {
        this.drillConfig!.level = (this.drillConfig?.level ?? 0) + 1;
    }

    getColorConfigForLevel(level: number): ColorConfig {
        return {
            colorType: this.drillConfig?.keys[level].colorType,
            colorName: this.drillConfig?.keys[level].colorName,
            configCustomStyles: this.drillConfig?.keys[level].configCustomStyles,
            colorSortBy: this.drillConfig?.keys[level].colorSortBy,
            colorSortDirection: this.drillConfig?.keys[level].colorSortDirection,
        };
    }

    getLevel(): number {
        return this.drillConfig?.level ?? 0;
    }

    getX(): string {
        const index = this.getLevel() - this.levelList.filter((level) => level.label === 'others').length;
        return this.drillConfig?.keys[index].value ?? '';
    }

    getLabel(): string {
        const index = this.getLevel() - this.levelList.filter((level) => level.label === 'others').length;
        return this.drillConfig?.keys[index].label ?? '';
    }

    getLevelData(): Level[] {
        return this.levelList;
    }

    setLevelData(list: Level[]): void {
        this.levelList = [...list];
    }
}

export interface Config extends Drilldown {
    level: number;
    selector: string;
}
