import { Injectable } from '@angular/core';
import { ChartData, DrillInfo, Drilldown, FixedColumn, SeriesChartColumn, SlicerInfo, VizTableTotal } from '@ddv/charts';

type VizChartData = ChartData | SeriesChartColumn;

@Injectable()
export class VisualizationWrapperService {
    getFixedColumn(
        isPITChart: boolean,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        chartDataSource: any[],
        vizChartData: ChartData[],
        fixedColKey: string,
        chartDrillDown?: Drilldown,
        drillInfo?: DrillInfo,
    ): FixedColumn {
        const fixedColumn: FixedColumn = { key: fixedColKey, values: [] };
        if (isPITChart) {
            fixedColumn.values = vizChartData.map((pitChartDatum) => pitChartDatum.color);
            fixedColumn.key = this.getPITChartFixedColKey(fixedColumn.key, chartDrillDown, drillInfo);
        } else {
            chartDataSource.forEach((value) => {
                if (!fixedColumn.values.some((val) => val.valueOf() === value[fixedColKey].valueOf())) {
                    fixedColumn.values.push(value[fixedColKey]);
                }
            });
        }
        if (fixedColKey === 'date') {
            fixedColumn.values.sort((a: string, b: string) => {
                const date1 = new Date(a);
                const date2 = new Date(b);
                if (date1 > date2) {
                    return 1;
                } else if (date1 < date2) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
        return fixedColumn;
    }

    getSeriesChartTableColumnDetails(
        vizChartData: ChartData[],
        slicerInfo: SlicerInfo | undefined,
        fixedColKey: string,
        isMultiSeries: boolean,
    ): SeriesChartColumn[] {
        return vizChartData.map((column) => ({
            key: column.key,
            values: this.getValuesByFixedColumn(column, isMultiSeries, slicerInfo, fixedColKey),
            color: column.color,
            label: this.getLabelBasedonValue(slicerInfo, column.key),
        }));
    }

    getLabelBasedonValue(slicerInfo: SlicerInfo | undefined, valueField: string): string | null | undefined {
        const matchedValue = slicerInfo?.values.find((slicerValue) => slicerValue.value === valueField);
        return matchedValue ? matchedValue.label : null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStackedChartTableColumnDetails(slicerInfo: SlicerInfo | undefined, stackedField: string, dataSource: any[]): SeriesChartColumn[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const stackedFieldsValues: any[] = dataSource[0]?.values || [];
        const slicerValue = slicerInfo?.slicer.value ?? '';
        return stackedFieldsValues.map((data) => ({
            key: data[slicerValue],
            values: stackedFieldsValues.find((innerData) => innerData[slicerValue] === data[slicerValue]),
            color: '',
        }));
    }

    getVizTableTotals(
        isPITChart: boolean,
        slicerInfo: SlicerInfo | undefined,
        vizChartData: VizChartData[],
        isStacked: boolean,
    ): VizTableTotal[] {
        if (isPITChart) {
            return slicerInfo?.values.map((val) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const totalValue = vizChartData.reduce((sum, curr: any) => sum + (curr[val.value ?? ''] as number), 0);
                return { key: val.value ?? '', totalValue };
            }) ?? [];
        }

        if (isStacked) {
            return vizChartData.map((vizTableColumn) => {
                const totalValue = vizTableColumn.values.total;
                return { key: vizTableColumn.key, totalValue };
            });
        }

        return vizChartData.map((vizTableColumn) => {
            const totalValue = Object.keys(vizTableColumn.values)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .reduce((sum, colKey) => sum + ((vizTableColumn as any).values[colKey] as number), 0);
            return { key: vizTableColumn.key, totalValue };
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getTotalStackDetails(vizChartData: any[]): SeriesChartColumn {
        return {
            key: 'total',
            values: this.getStackTotal(vizChartData),
            color: '',
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getStackTotal(vizChartData: any[]): any {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const totalData: any = {};
        let grandTotal = 0;
        vizChartData.forEach((column) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const totalValue = column.values.reduce((sum: number, value: any) => sum + ((value[column.key] as number) || 0), 0);
            grandTotal += totalValue;
            totalData[column.key] = totalValue || 0;
        });
        totalData.total = grandTotal;
        return totalData;
    }

    private getValuesByFixedColumn(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        column: { key: string, values: any[] },
        isMultiSeries: boolean,
        slicerInfo: SlicerInfo | undefined,
        fixedColKey: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any {
        if (!column.values) {
            return {};
        }
        const slicerKey = (isMultiSeries ? slicerInfo?.values[0].value : column.key) ?? '';
        return column.values.reduce((valByFixedCol, colData) => {
            valByFixedCol[colData[fixedColKey]] = colData[slicerKey];
            return valByFixedCol;
        }, {});
    }

    private getPITChartFixedColKey(currKey: string, chartDrillDown?: Drilldown, drillInfo?: DrillInfo): string {
        if (chartDrillDown?.keys && drillInfo?.list && typeof drillInfo.level !== 'undefined') {
            const othersLength = drillInfo.list.filter((item) => item.label === 'others').length;
            const index = othersLength ? drillInfo.level - othersLength : drillInfo.level;
            if (chartDrillDown.keys[index]) {
                return chartDrillDown.keys[index].label ?? '';
            }
        }
        return currKey;
    }
}

