import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive()
export class MultiSubscriptionComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }

        this.subscriptions = [];
    }

    subscribeTo<T>(observable: Observable<T>, callback?: (value: T) => void): void {
        this.subscriptions.push(observable.subscribe(callback));
    }
}
