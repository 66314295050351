import { Inject, Injectable } from '@angular/core';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { UserPermission } from '@ddv/models';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardSharingService {
    constructor(@Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService) {}

    updateSharing(clientCode: string, dashboardId: number, permissions: UserPermission[] | undefined): Observable<void> {
        // also call the new method that clears all user state/preferences for the unshared dashboards

        return this.ddvApiService.invokeServiceWithBody<void>(
            clientCode,
            `dashboard/${dashboardId}/sharing`,
            'POST',
            { dashboardPermissions: permissions });
    }
}
