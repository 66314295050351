import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { Column, ColumnState, FilterChangedEvent, IHeaderParams, SortChangedEvent, SortDirection } from '@ag-grid-community/core';
import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AUTO_GROUP_COLUMN_ID } from '@ddv/models';

@Component({
    selector: 'app-header-cell-renderer',
    templateUrl: './header-cell-renderer.component.html',
    styleUrls: ['./header-cell-renderer.component.scss'],
})
export class HeaderCellRendererComponent implements IHeaderAngularComp, OnDestroy {
    @ViewChild('menuButton', { read: ElementRef }) public menuButton!: ElementRef;

    params: IHeaderParams | undefined;

    protected headerText: SafeHtml | undefined;
    protected sortIndex: number | null = null;
    protected sortDirection: SortDirection = null;
    protected filterActive = false;

    private colId = '';

    constructor(private readonly sanitizer: DomSanitizer) {}

    agInit(params: IHeaderParams): void {
        if (!params) {
            return;
        }

        this.params = params;
        this.colId = this.params.column.getColId();

        this.setSortDirectionAndSortIndex(params);
        this.setFilterState(params);

        this.setHeaderText();

        this.params.api.addEventListener('sortChanged', (event: SortChangedEvent) => this.setSortDirectionAndSortIndex(event));

        this.params.api.addEventListener('filterChanged', (event: FilterChangedEvent) => this.setFilterState(event));
    }

    refresh(params: IHeaderParams): boolean {
        this.params = params;
        this.setHeaderText();
        return true;
    }

    ngOnDestroy(): void {
        this.params?.api.removeEventListener('sortChanged', this.setSortDirectionAndSortIndex.bind(this));
        this.params?.api.removeEventListener('filterChanged', this.setFilterState.bind(this));
    }

    protected onMenuClicked(): void {
        this.params?.showColumnMenu(this.menuButton.nativeElement);
    }

    protected onSortRequested(event: MouseEvent): void {
        if (!this.params?.enableSorting) {
            return;
        }

        this.params?.progressSort(event.shiftKey);
    }

    private setHeaderText(): void {
        this.headerText = this.params?.displayName ? this.sanitizer.bypassSecurityTrustHtml(this.params.displayName) : undefined;
    }

    private setSortDirectionAndSortIndex(event: IHeaderParams | SortChangedEvent): void {
        const columnsInSort = event.api.getColumnState().filter((state) => state.sort);
        if (!columnsInSort.length) {
            this.sortIndex = null;
            this.sortDirection = null;
            return;
        }

        const columnsInGroup = event.api.getRowGroupColumns();
        const column = columnsInSort.find((state) => state.colId === this.colId);

        if (!column) {
            this.sortIndex = null;
            this.sortDirection = null;
            return;
        }

        this.sortDirection = column.sort ?? null;

        if (this.colId === AUTO_GROUP_COLUMN_ID) {
            this.setGroupColumnSortAndDirection(column, columnsInGroup, columnsInSort);
        } else {
            this.setColumnSortAndDirection(column, columnsInGroup, columnsInSort);
        }
    }

    private getLowestGroupColumnSortIndex(columnsInGroup: Column[]): number | null {
        let lowestSortIndex: number | null = null;

        columnsInGroup.forEach((c) => {
            const sortIndex = c.getSortIndex();
            if (sortIndex != null && !isNaN(sortIndex)) {
                if (lowestSortIndex == null || lowestSortIndex > sortIndex) {
                    lowestSortIndex = sortIndex;
                    return;
                }
            }
        });

        return lowestSortIndex;
    }

    private setGroupColumnSortAndDirection(column: ColumnState, columnsInGroup: Column[], columnsInSort: ColumnState[]): void {
        const lowestGroupSortIndex = this.getLowestGroupColumnSortIndex(columnsInGroup);
        const isOnlyGroupedColumnsSorted = columnsInSort
            .filter((c) => c.colId !== AUTO_GROUP_COLUMN_ID)
            .every((c) => c.rowGroup);

        const allColumnsHaveSameSortDirection = columnsInGroup.every((c: Column) => c.getSort() === column?.sort);
        this.sortDirection = allColumnsHaveSameSortDirection ? column?.sort ?? null : null;

        const sortIndex = lowestGroupSortIndex;
        this.sortIndex = sortIndex != null && !isOnlyGroupedColumnsSorted ? sortIndex + 1 : null;
    }

    private setColumnSortAndDirection(column: ColumnState, columnsInGroup: Column[], columnsInSort: ColumnState[]): void {
        const lowestGroupSortIndex = this.getLowestGroupColumnSortIndex(columnsInGroup);
        const areOnlyGroupedColumnsSorted = columnsInSort
            .filter((c) => c.colId !== AUTO_GROUP_COLUMN_ID)
            .every((c) => c.rowGroup);
        const isColumnInGroup = this.params?.column.isRowGroupActive();
        const groupColumn = columnsInSort.find((c) => c.colId === AUTO_GROUP_COLUMN_ID);

        if (column?.sortIndex == null || columnsInSort.length < 2) {
            this.sortIndex = null;
            return;
        }

        if (isColumnInGroup && lowestGroupSortIndex != null) {
            this.sortIndex = !areOnlyGroupedColumnsSorted ? lowestGroupSortIndex + 1 : null;
            return;
        }

        if (!isColumnInGroup) {
            if (!groupColumn) {
                this.sortIndex = column?.sortIndex + 1;
                return;
            }

            if (groupColumn.sortIndex == null) {
                this.sortIndex = column?.sortIndex >= columnsInGroup.length ?
                    column?.sortIndex - (columnsInGroup.length - 1) + 1 :
                    column?.sortIndex + 1;
                return;
            }

            this.sortIndex = lowestGroupSortIndex != null && column?.sortIndex >= lowestGroupSortIndex ?
                column.sortIndex - (columnsInGroup.length - 1) + 1 :
                column?.sortIndex + 1;
        }
    }

    private setFilterState(event: IHeaderParams | FilterChangedEvent): void {
        this.filterActive = event.api.getFilterModel()?.[this.colId] ?? false;
    }
}
