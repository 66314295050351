import { Injectable } from '@angular/core';
import { ManagerService } from '@ddv/layout';
import { CompareMode, WidgetData, DateRangeString } from '@ddv/models';
import { CommonDate, DateRange, FuzzyDate } from '@hs/ui-core-date';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface CompareModeState {
    isInCompareMode: boolean;
    compareDates?: DateRange | CommonDate;
}
interface CompareModeStateUpdate extends CompareModeState {
    widgetId: number;
}

@Injectable()
export class CompareModeService {
    public readonly widgetsCompareModeStatus: Observable<Map<number, CompareModeState>>;
    public readonly currentWidgetInCompareMode: Observable<number | undefined>;

    private readonly widgetsCompareModeStatusSubject: BehaviorSubject<Map<number, CompareModeState>> = new BehaviorSubject(new Map());
    private readonly currentWidgetInCompareModeSubject: Subject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
    private readonly compareDataByWidgetId: Map<number, WidgetData[]> = new Map();

    constructor(private readonly managerService: ManagerService) {
        this.widgetsCompareModeStatus = this.widgetsCompareModeStatusSubject.asObservable();
        this.currentWidgetInCompareMode = this.currentWidgetInCompareModeSubject.asObservable();
    }

    updateWidgetsCompareModeStatus({ widgetId, isInCompareMode, compareDates }: CompareModeStateUpdate): void {
        const widgets = this.widgetsCompareModeStatusSubject.getValue();
        widgets.set(widgetId, { isInCompareMode, compareDates });
        this.widgetsCompareModeStatusSubject.next(widgets);
    }

    clearWidgetsCompareModeStatus(): void {
        const widgets = this.widgetsCompareModeStatusSubject.getValue();
        widgets.clear();
        this.widgetsCompareModeStatusSubject.next(widgets);
    }

    clearWidgetsCompareModeStatusById(widgetId: number): void {
        const widgets = this.widgetsCompareModeStatusSubject.getValue();
        widgets.delete(widgetId);
        this.widgetsCompareModeStatusSubject.next(widgets);
    }

    updateCurrentWidgetInCompareMode(widgetId: number): void {
        this.currentWidgetInCompareModeSubject.next(widgetId);
    }

    updateCompareDataByWidgetId(widgetId: number, compareData?: WidgetData[]): void {
        if (!compareData) {
            return;
        }

        this.compareDataByWidgetId.set(widgetId, compareData);
    }

    clearCompareData(): void {
        this.compareDataByWidgetId.clear();
    }

    clearCompareDataByWidgetId(widgetId: number): void {
        this.compareDataByWidgetId.delete(widgetId);
    }

    get compareDataMap(): Map<number, WidgetData[]> {
        return this.compareDataByWidgetId;
    }

    toDateRangeString(date: DateRange | CommonDate | FuzzyDate | undefined): DateRangeString | undefined {
        let formattedDate: DateRangeString | undefined;
        if (date instanceof DateRange) {
            formattedDate = {
                dateFrom: date?.fromDate?.toNamedFormat('us'),
                dateTo: date?.toDate?.toNamedFormat('us'),
            };
        } else if (date instanceof CommonDate) {
            formattedDate = {
                dateFrom: date?.toUSPaddedFormat(),
                dateTo: date?.toUSPaddedFormat(),
            };
        } else if (date) {
            formattedDate = {
                dateFrom: date.commonDate?.toUSPaddedFormat(),
                dateTo: date.commonDate?.toUSPaddedFormat(),
            };
        }

        return formattedDate;
    }

    disableCompareMode(): void {
        const widgetsInCompareMode = this.widgetsCompareModeStatusSubject.getValue();
        widgetsInCompareMode.forEach((_, widgetId) => {
            const widgetPrefs = this.managerService.getWidgetPreferences(widgetId);
            if (widgetPrefs?.widgetFilters?.isComparing) {
                widgetPrefs.widgetFilters.isComparing = false;
                widgetPrefs.widgetFilters.comparing = CompareMode.ORIGINAL;
                widgetPrefs.widgetFilters.compareDates = undefined;
                // FilterPreference doesn't have this property so somebody screwed something
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (!(widgetPrefs as any).widgetFilters.isSubscribedToDashboardFilters) {
                    this.managerService.emitWhenDateComparerIsToggled(false);
                }
            }

            this.updateWidgetsCompareModeStatus(
                { widgetId, isInCompareMode: false, compareDates: undefined },
            );
        });
    }
}
