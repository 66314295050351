import { NgModule } from '@angular/core';
import { HttpModule } from '@ddv/http';

import { UsageTrackingService } from './usage-tracking.service';

@NgModule({
    imports: [HttpModule],
    providers: [UsageTrackingService],
})
export class UsageTrackingModule {}
