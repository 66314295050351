import { Inject, Injectable } from '@angular/core';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { FastnessQuery, FastnessResponse, DdvDate, FuzzyDates } from '@ddv/models';
import { Observable } from 'rxjs';

@Injectable()
export class DataFastnessService {
    constructor(@Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService) {}

    checkFastness(
        clientCode: string,
        dateFrom: string | undefined,
        dateTo: string | undefined,
        queryType: string,
        fuzzyDates?: FuzzyDates,
    ): Observable<FastnessResponse> {
        const requestBody: FastnessQuery = constructFastnessCheckBody(dateFrom, dateTo, queryType, fuzzyDates);
        return this.trebekApiExecutor.invokeServiceWithBody(clientCode, '/fastness/', 'POST', requestBody);
    }
}

function constructFastnessCheckBody(
    dateFrom: string | undefined,
    dateTo: string | undefined,
    queryType: string,
    fuzzyDates?: FuzzyDates,
): FastnessQuery {
    const startDate = getValidDate('from', dateFrom, fuzzyDates)?.toReversePaddedDashFormat();
    const endDate = getValidDate('to', dateTo, fuzzyDates)?.toReversePaddedDashFormat();
    return {
        timeseriesRange: {
            granularity: 'none',
            startDate,
            endDate,
        },
        queryTypes: [queryType],
    };
}

function getValidDate(dateList: 'to' | 'from', date = '', fuzzyDates?: FuzzyDates): DdvDate {
    return DdvDate.isStringValidDate(date) ?
        DdvDate.fromUSFormat(date) :
        (getDateFromFuzzy(dateList, date, fuzzyDates) ?? DdvDate.empty);
}

function getDateFromFuzzy(dateList: 'to' | 'from', fuzzyDate: string, fuzzyDates?: FuzzyDates): DdvDate | undefined {
    const option = fuzzyDates?.[dateList].find((fd) => fd.name.toLocaleUpperCase() === fuzzyDate?.toLocaleUpperCase());
    return option ? DdvDate.fromISOFormat(option.value) : undefined;
}
