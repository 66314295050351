import { AgGridAngular } from '@ag-grid-community/angular';
import {
    ApplyColumnStateParams,
    CellClickedEvent,
    CellPosition,
    ColDef,
    ColGroupDef,
    Column,
    ColumnEverythingChangedEvent,
    ColumnGroup,
    ColumnMovedEvent,
    ColumnPinnedEvent,
    ColumnPinnedType,
    ColumnPivotModeChangedEvent,
    ColumnRowGroupChangedEvent,
    ColumnState,
    ColumnValueChangedEvent,
    ColumnVisibleEvent,
    DragStoppedEvent,
    FilterModifiedEvent,
    GridOptions,
    GridReadyEvent,
    IRowNode,
    NavigateToNextCellParams,
    ProcessCellForExportParams,
    RefreshCellsParams,
    RowClickedEvent,
    RowNode,
    RowSelectedEvent,
    RowSelectionOptions,
    SelectionChangedEvent,
    SideBarDef,
    ValueSetterParams,
} from '@ag-grid-community/core';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { CurrentStateService, RealtimeActiveService } from '@ddv/behaviors';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import {
    CompareColumnID,
    AgGridSortState,
    RowGroupOpenedEvent,
    AgGridFilterModel,
    UserGridColumnOverrides,
    ConfigItem,
    AUTO_GROUP_COLUMN_ID,
    COMPARE_GROUP_ID_SUFFIX,
    HEADER_COLUMN_FONT,
    TrebekConversationFields,
    AgGridSortDirection,
} from '@ddv/models';

import { NoRowsToShowOverlayComponent } from './custom-no-rows-to-show-overlay/no-rows-to-show-overlay.component';
import { CellNavigationKey } from './model/cell-navigation';
import { CustomColDef, CustomColGroupDef, DefaultColumnDefinition } from './model/column-definition';
import { DefaultDataGridConfiguration } from './model/default-data-grid-configuration.model';
import {
    CellClickedEventModel,
    ColumnHeaderChild,
    ColumnHeaderGroupState,
    CustomSelectionChangedEvent,
    FilterChangedEventModel,
    GridConfiguration,
    GridState,
    RowClickedEventModel,
    RowGroupOpenedEventModel,
    RowSelectionEventModel,
    StatusBarModel,
} from './model/interfaces';
import { DataGridOptions, DefaultDataGridOptions } from './model/options';
import { OverridesRelayService } from './services/overrides-relay.service';

@Component({
    selector: 'app-data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent extends MultiSubscriptionComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('grid') grid!: AgGridAngular;
    @Input() gridConfiguration: GridConfiguration | undefined;

    @Input() isManagingWidget = false;
    @Input() hideLoaderAfterFirstDataLoad = false;
    @Input() isOnMasterWidget = false;
    @Input() isShowingTFLData = false;
    @Input() datasetIncludesCrosstalk = false;

    @Input() suppressAggFuncInHeader: boolean | '' = '';
    @Input() frameworkComponents: Record<string, new (...args: unknown[]) => unknown> | undefined;
    @Input() enableGroupEdit = false;
    @Input() widgetId: number | undefined;
    @Input() visualizationId: number | undefined;

    @Output() rowSelectionEvent = new EventEmitter<RowSelectionEventModel>();
    @Output() rowUnselectedEvent = new EventEmitter<RowSelectionEventModel>();
    @Output() rowClickedEvent = new EventEmitter<RowClickedEventModel>();
    @Output() cellClickedEvent = new EventEmitter<CellClickedEventModel>();
    @Output() gridReadyEvent = new EventEmitter<GridReadyEvent>();
    @Output() rowGroupOpenedEvent = new EventEmitter<RowGroupOpenedEvent>();
    @Output() modelUpdatedEvent = new EventEmitter();
    @Output() columnVisibleEvent = new EventEmitter();
    @Output() columnPivotModeChangedEvent = new EventEmitter<ColumnPivotModeChangedEvent>();
    @Output() valueColumnChangedEvent = new EventEmitter<ColumnValueChangedEvent>();
    @Output() rowGroupColumnChangedEvent = new EventEmitter();
    @Output() pivotColumnChangedEvent = new EventEmitter();
    @Output() displayedColumnsChangedEvent = new EventEmitter();
    @Output() cellValueChanged = new EventEmitter<ValueSetterParams>();
    @Output() columnEverythingChanged = new EventEmitter<ColumnEverythingChangedEvent>();
    @Output() columnMovedEvent = new EventEmitter<{ event: ColumnMovedEvent, columns: ColumnState[] | undefined }>();
    @Output() virtualColumnsChanged = new EventEmitter();
    @Output() gridFilterChangedEvent = new EventEmitter();
    @Output() dragStoppedEvent = new EventEmitter<{ event: DragStoppedEvent, columns: ColumnState[] | undefined }>();
    @Output() selectionChangedEvent = new EventEmitter<CustomSelectionChangedEvent>();
    @Output() columnPinnedEvent = new EventEmitter<ColumnPinnedEvent>();

    gridOptions: GridOptions;
    sideBar: SideBarDef | string | boolean | undefined;
    columnDefinitions: ColDef[] | undefined;
    suppressScrollOnNewData = false;
    statusBar: StatusBarModel = {
        statusPanels: [
            {
                statusPanel: 'agAggregationComponent',
                statusPanelParams: {
                    aggFuncs: ['count', 'sum', 'min', 'max', 'avg'],
                },
                align: 'right',
            },
        ],
    };

    protected readonly noRowsToShowOverlayComponent = NoRowsToShowOverlayComponent;

    private dataGridOptions: DataGridOptions | undefined;
    private readonly defaultDataGridOptions: DataGridOptions = new DefaultDataGridOptions();
    private readonly defaultColumnDefinitions: ColDef = new DefaultColumnDefinition();
    private selectedRow: IRowNode | undefined;
    private selectedRows: IRowNode[] = [];
    private selectedCellRowNode: IRowNode | undefined;
    private currentClickedCellColDef: ColDef | undefined;
    private readonly selectedRowsCount = {
        previousValue: 0,
        currentValue: 0,
    };
    private readonly dashboardMode: { previous?: string, current?: string } = {};
    private readonly maxAllowedColumnWidth = 400;

    constructor(
        private readonly realtimeActiveService: RealtimeActiveService,
        private readonly currentStateService: CurrentStateService,
        private readonly overridesRelay: OverridesRelayService,
        private readonly rootElementRef: ElementRef,
    ) {
        super();
        this.gridOptions = {};
    }

    ngOnInit(): void {
        if (this.gridConfiguration != null) {
            this.initializeGridConfiguration(this.gridConfiguration);
        }

        this.subscribeTo(this.realtimeActiveService.realtimeIsActive, (realtimeUpdates) => this.suppressScrollOnNewData = realtimeUpdates);

        if (!this.isManagingWidget) {
            this.subscribeTo(this.currentStateService.dashboardModeAndId$, (modeAndId) => {
                const { mode } = modeAndId;
                this.dashboardMode.previous = this.dashboardMode.current;
                this.dashboardMode.current = mode ?? undefined;
                if (this.dashboardMode.previous === 'edit' && this.dashboardMode.current === 'view' && !this.isOnMasterWidget) {
                    this.deselectAllSelected();
                }
            });
        }

        if (this.datasetIncludesCrosstalk || this.isShowingTFLData) {
            this.statusBar.statusPanels.push({ statusPanel: 'agSelectedRowCountComponent', align: 'left' });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.gridConfiguration && !changes.gridConfiguration.firstChange) {
            this.initializeGridConfiguration(changes.gridConfiguration.currentValue);
        }
    }

    // this is to facilitate acceptance tests
    // it is a replacement for something they have been using for a long time that ag-grid removed
    ngAfterViewInit(): void {
        this.rootElementRef.nativeElement.__agGrid = this.grid;
    }

    enableSelectedRowCount(): void {
        // WAITING ON AG-GRID TO TELL US HOW TO DO THIS

        // if (this.statusBar.statusPanels.some((panel) => panel.statusPanel === 'agSelectedRowCountComponent')) {
        //     return;
        // }
        //
        // const statusBarConfig = { ...this.statusBar };
        // statusBarConfig.statusPanels.push({ statusPanel: 'agSelectedRowCountComponent', align: 'left' });
        // this.statusBar = statusBarConfig;
    }

    refreshCells(params: RefreshCellsParams): void {
        if (this.grid?.api) {
            this.grid.api.refreshCells(params);
        }
    }

    refreshHeader(): void {
        this.grid?.api?.refreshHeader();
    }

    onGridReady(event: GridReadyEvent): void {
        if (this.gridConfiguration?.rowData) {
            this.grid?.api?.setGridOption('rowData', this.gridConfiguration.rowData);
        }
        this.autoFitAndSizeColumnsBasedOnConfig();

        this.grid.localeText = {};

        this.gridReadyEvent.emit(event);
    }

    onGridColumnsChanged(): void {
        this.autoFitAndSizeColumnsBasedOnConfig();
    }

    onVirtualColumnsChanged(): void {
        this.virtualColumnsChanged.emit();
    }

    onColumnMoved(event: ColumnMovedEvent): void {
        this.columnMovedEvent.emit({ event, columns: this.getColumnState() });
    }

    onResize(): void {
        this.autoFitAndSizeColumnsBasedOnConfig();
    }

    getFilteredDataRows(): unknown[] {
        const filteredDataRows: unknown[] = [];
        this.grid?.api?.forEachNodeAfterFilterAndSort((rowNode: IRowNode) => {
            if (rowNode.data) {
                filteredDataRows.push(rowNode.data);
            }
        });
        return filteredDataRows;
    }

    getTotalRowCount(): number {
        return this.grid?.api?.getDisplayedRowCount() ?? 0; // arbitrary default
    }

    isRowGroupingOn(): boolean {
        return !!this.grid.api && !!this.getRowGroupColumns()?.length;
    }

    onSelectionChanged(event: SelectionChangedEvent): void {
        const selectedNodesCount = event.api.getSelectedNodes().length;
        if (this.selectedRowsCount.currentValue !== selectedNodesCount) {
            this.selectedRowsCount.previousValue = this.selectedRowsCount.currentValue;
            this.selectedRowsCount.currentValue = selectedNodesCount;
        }
        this.selectionChangedEvent.emit({ event, hasSelectedRows: !!selectedNodesCount });
    }

    onRowSelected(event: RowSelectedEvent): void {
        const rowSelectionModel: RowSelectionEventModel = {
            selectedRow: event.node,
            selectedRowData: event.node.data,
            selectedRowIndex: event.node.rowIndex,
            columnDefs: event.api.getColumnDefs(),
        };

        if (!event.node.group) {
            if (event.node.isSelected()) {
                this.rowSelectionEvent.emit(rowSelectionModel);
            } else {
                this.rowUnselectedEvent.emit(rowSelectionModel);
            }
        }
    }

    onRowClicked(event: RowClickedEvent): void {
        const rowClickedEventModel: RowClickedEventModel = {
            rowData: event.data,
            event: event.event as PointerEvent,
            rowIndex: event.rowIndex,
        };
        this.rowClickedEvent.emit(rowClickedEventModel);
        this.handleRowSelection(event);
    }

    cellClickedHandler(event: CellClickedEvent): void {
        this.currentClickedCellColDef = event.colDef;
        const cellClickedEventModel: CellClickedEventModel = {
            column: event.column,
            rowData: event.data,
            event: event.event,
            rowIndex: event.rowIndex,
            value: event.value,
        };
        this.cellClickedEvent.emit(cellClickedEventModel);
    }

    hideOverlay(): void {
        this.grid?.api?.hideOverlay();
    }

    showNoRowsOverlay(): void {
        this.grid?.api?.showNoRowsOverlay();
    }

    onRowGroupOpened(event: RowGroupOpenedEventModel): void {
        this.rowGroupOpenedEvent.emit({ rowGroupId: event.node.id, expanded: event.expanded });
        this.autoFitAndSizeColumnsBasedOnConfig();
    }

    onColumnEverythingChanged(event: ColumnEverythingChangedEvent): void {
        this.autoFitAndSizeColumnsBasedOnConfig();
        this.columnEverythingChanged.emit(event);
    }

    onColumnPivotModeChanged(event: ColumnPivotModeChangedEvent): void {
        this.columnPivotModeChangedEvent.emit(event);
    }

    onValueColumnChanged(event: ColumnValueChangedEvent): void {
        this.valueColumnChangedEvent.emit(event);
    }

    onRowGroupColumnChanged(event: ColumnRowGroupChangedEvent): void {
        this.rowGroupColumnChangedEvent.emit(event);
        this.refreshHeader();
    }

    onColumnPivotChanged(): void {
        this.pivotColumnChangedEvent.emit();
    }

    onDisplayedColumnsChanged(): void {
        this.displayedColumnsChangedEvent.emit();
    }

    setHeaderRow(config: unknown): void {
        this.grid?.api?.setGridOption('pinnedTopRowData', [config]);
    }

    setHeaderGrandTotal(): void {
        this.setHeaderRow({});
        const rootNode = this.grid?.api?.getDisplayedRowAtIndex(0)?.parent;
        if (rootNode) {
            (this.grid?.api?.getPinnedTopRow(0) as RowNode).setAggData(rootNode.aggData);
        }
    }

    setFooterRow(config: unknown): void {
        this.grid?.api?.setGridOption('pinnedBottomRowData', [config]);
    }

    setFooterGrandTotal(): void {
        this.setFooterRow({});
        const rootNode = this.grid?.api?.getDisplayedRowAtIndex(0)?.parent;
        if (rootNode) {
            (this.grid?.api?.getPinnedBottomRow(0) as RowNode).setAggData(rootNode.aggData);
        }
    }

    setRowData(data: unknown[]): void {
        // Setting the row data removes filters. On purpose.
        // https://stackoverflow.com/questions/50533098/keep-filter-after-ag-grid-update
        const filters = this.getFilterModel();
        this.grid?.api?.setGridOption('rowData', data);
        this.setFilterModel(filters);
    }

    setColumnVisible(colKey: string, visible: boolean): void {
        if (this.grid.api) {
            this.grid.api.setColumnsVisible([colKey], visible);
        }
    }

    setColumnVisibleInPivotMode(colKey: string, visible: boolean): void {
        const column = this.getColumn(colKey);
        if (!column) {
            return;
        }

        const isRowGroupColumn = column.getColDef().enableRowGroup;
        if (visible) {
            if (isRowGroupColumn) {
                this.grid?.api?.addRowGroupColumns([colKey]);
            } else {
                this.grid?.api?.addValueColumns([colKey]);
            }
        } else {
            if (isRowGroupColumn) {
                this.grid?.api?.removeRowGroupColumns([colKey]);
            } else {
                this.grid?.api?.removeValueColumns([colKey]);
            }
        }
    }

    setColumnsVisible(colKeys: string[], visible: boolean): void {
        this.grid?.api?.setColumnsVisible(colKeys, visible);
    }

    onModelUpdated(): void {
        this.modelUpdatedEvent.emit();
    }

    onColumnsVisibilityChanged(event: ColumnVisibleEvent): void {
        if (event.column) {
            this.columnVisibleEvent.emit({ source: event.source, columns: event.api.getColumns() });
        }
    }

    setGridConfiguration(gridConfig: GridConfiguration): void {
        this.gridConfiguration = gridConfig;
    }

    setQuickFilter(text: string): void {
        this.grid?.api?.setGridOption('quickFilterText', text);
    }

    searchByColumnHeader(text: string): void {
        const allCols = this.getAllColumns();
        const hiddenCols: string[] = [];
        const visibleCols: string[] = [];
        allCols.forEach((column) => {
            const hdrName = column.getColDef()?.headerName?.toLowerCase();
            const colId = column.getColId();
            if (hdrName && hdrName.indexOf(text.toLowerCase()) >= 0) {
                visibleCols.push(colId);
            } else {
                hiddenCols.push(colId);
            }
        });
        this.setColumnsVisible(visibleCols, true);
        this.setColumnsVisible(hiddenCols, false);
        this.autoFitAndSizeColumnsBasedOnConfig();
    }

    autoSizeColumns(): void {
        const allColumns = this.getAllColumns();

        let columnsForResizingIds = this.getNonGroupedColumnsIds(allColumns);

        const autoColumnGroup = this.getColumnState()?.find((c) => c.colId === AUTO_GROUP_COLUMN_ID);
        if (autoColumnGroup) {
            columnsForResizingIds.unshift(AUTO_GROUP_COLUMN_ID);
        }

        const overrides = this.overridesRelay.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
        if (this.isInDashboardViewMode() && overrides.some((o) => o.columnWidth != null)) {
            columnsForResizingIds = this.getColumnsForResizing(overrides, columnsForResizingIds);
        }

        this.grid?.api?.autoSizeColumns(columnsForResizingIds);

        const groupedCompareColumns = this.getGroupedColumns(allColumns);
        if (groupedCompareColumns.length) {
            this.resizeGroupColumns(groupedCompareColumns, overrides, !!autoColumnGroup);
        }

        this.setMaxAllowedWidthForAllColumns(columnsForResizingIds);
    }

    autoSizeColumn(columnId: string): void {
        this.grid?.api?.autoSizeColumns([columnId]);

        const overrides = this.overridesRelay.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
        const override = overrides.find((o) => o.columnId === columnId);
        if (this.isInDashboardViewMode() && override?.columnWidth != null) {
            this.setColumnWidth(columnId, override.columnWidth);
        } else {
            this.setMaxAllowedColumnWidth(columnId);
        }
    }

    autoFitColumnsBasedOnConfig(): void {
        if (this.gridConfiguration?.autoFitColumns) {
            this.sizeColumnsToFit();
        }
    }

    autoSizeColumnsBasedOnConfig(): void {
        if (this.gridConfiguration?.autoSizeColumns) {
            this.autoSizeColumns();
        }
    }

    isToolPanelShowing(): boolean {
        return !!this.sideBar;
    }

    showToolPanel(showPanel: boolean): void {
        this.sideBar = showPanel ? 'columns' : false;
    }

    getAllColumns(): Column[] {
        return this.grid?.api?.getColumns() ?? [];
    }

    getAllDisplayedColumns(): Column[] | undefined {
        return this.grid?.api?.getAllDisplayedColumns();
    }

    getRowGroupColumns(): Column[] | undefined {
        return this.grid?.api?.getRowGroupColumns();
    }

    onFilterChanged(): void {
        this.grid?.api?.onFilterChanged();
    }

    onFilterModified(e: FilterModifiedEvent): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const conditionOperators = [(e.filterInstance as any).eJoinOperatorsAnd[0], (e.filterInstance as any).eJoinOperatorsOr[0]];
        conditionOperators.forEach((conditionOperator) => {
            const checked = conditionOperator?.eWrapper.classList.contains('ag-checked');
            if (checked) {
                conditionOperator.eLabel.classList.add('ag-label-checked');
            } else {
                const labelChecked = conditionOperator?.eLabel.classList.contains('ag-label-checked');
                if (labelChecked) {
                    conditionOperator.eLabel.classList.remove('ag-label-checked');
                }
            }
        });
    }

    onGridFilterChanged(event: FilterChangedEventModel): void {
        const selectedNodes = event.api.getSelectedNodes();
        event.api.forEachNodeAfterFilter((node) => {
            const index = selectedNodes.findIndex((n) => n.id === node.id);
            if (index !== -1) {
                selectedNodes.splice(index, 1);
            }
        });
        selectedNodes.forEach((node) => node.setSelected(false));
        this.gridFilterChangedEvent.emit();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDataRows(): any[] {
        const dataRows: unknown[] = [];
        this.grid?.api?.forEachNode((node) => {
            if (!node.group) {
                dataRows.push(node.data);
            }
        });
        return dataRows;
    }

    getGrandTotalData(): { [key: string]: number } {
        return this.grid?.api?.getDisplayedRowAtIndex(0)?.parent?.aggData;
    }

    customNavigation(params: NavigateToNextCellParams): CellPosition | null {
        const previousCell = params.previousCellPosition;
        const suggestedNextCell = params.nextCellPosition;

        switch (params.key) {
            case CellNavigationKey.ARROW_DOWN:
                // set selected cell on current cell + 1
                this.grid?.api?.forEachNode((node) => {
                    if (previousCell?.rowIndex + 1 === node.rowIndex) {
                        node.setSelected(true);
                    }
                });
                return suggestedNextCell;
            case CellNavigationKey.ARROW_UP:
                // set selected cell on current cell - 1
                this.grid?.api?.forEachNode((node) => {
                    if (previousCell?.rowIndex - 1 === node.rowIndex) {
                        node.setSelected(true);
                    }
                });
                return suggestedNextCell;
            case CellNavigationKey.ARROW_LEFT:
            case CellNavigationKey.ARROW_RIGHT:
                return suggestedNextCell;
            default:
                throw new Error('this will never happen, navigation is always on of the 4 keys above');
        }
    }

    mergeColumnDefinitions(columnDefinitions: (ColDef | ColGroupDef)[]): (ColDef | ColGroupDef)[] {
        return columnDefinitions.map((columnDef) => {
            if (!(columnDef as ColGroupDef).children) {
                return { ...this.defaultColumnDefinitions, ...columnDef };
            }
            (columnDef as ColGroupDef).children = this.mergeColumnDefinitions((columnDef as ColGroupDef).children);
            return columnDef;
        });
    }

    applyColumnState({ state, applyOrder }: ApplyColumnStateParams): void {
        this.grid?.api?.applyColumnState({ state, applyOrder });
    }

    getState(): GridState {
        if (!this.grid?.api) {
            return { filterState: {}, columnState: [], sortState: [], pivotMode: false, columnHeaderGroupState: [] };
        }

        return {
            filterState: this.getFilterModel() ?? {},
            columnState: this.getColumnState() as ConfigItem[], // this is a lie - we have to fix our grid types
            sortState: this.getSortModel(),
            pivotMode: this.isPivotMode(),
            columnHeaderGroupState: this.getColumnGroupState(),
        };
    }

    setState(state: GridState): void {
        if (this.grid?.api) {
            this.setFilterModel(state.filterState);

            if (state.sortState?.length) {
                this.updateColumnsSortState(state);
            }

            // the column state has to be cast as any because we use our ConfigItem interface as a ColumnState
            // that can be passed to AgGrid.  When we create ConfigItems from API calls, they are created with nulls
            // presumably, somewhere before this line of code, they wont have nulls anymore but the types allow so the cast
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.applyColumnState({ state: state.columnState as any, applyOrder: true });

            this.grid?.api?.setGridOption('pivotMode', state.pivotMode);

            // this.gridConfiguration.autoFitColumns is always false for Advanced and Simple grids
            // as it is initially set to false and it is never overrided
            // I believe that when we added this check, we should have used the autoSizeColumns property instead of the autoFitColumns
            if (!this.gridConfiguration?.autoFitColumns && !this.keepGroupColumnOverrideWidth()) {
                this.gridConfiguration?.columnDefinitions.forEach((colDef: ColDef) => {
                    if (colDef.rowGroupIndex != null) {
                        this.setColumnWidth(AUTO_GROUP_COLUMN_ID, (colDef as CustomColGroupDef).customWidthWhenGroup ?? 0);
                    }
                });
            }
        }
    }

    onCellValueChanged(event: ValueSetterParams): void {
        this.cellValueChanged.emit(event);
    }

    setColumnDefs(defs: ColDef[]): void {
        if (this.grid?.api) {
            this.grid?.api?.setGridOption('columnDefs', defs);
            this.columnDefinitions = defs;
        }
    }

    resetSelectedRows(): void {
        this.getSelectedNodes()
            .forEach((row) => {
                row.setSelected(false);
            });
    }

    resetSelectedCells(): void {
        this.grid?.api?.clearCellSelection();
    }

    deselectAllSelected(): void {
        this.resetSelectedCells();
        this.resetSelectedRows();
        this.refreshHeader();
        this.selectedRow = undefined;
        this.selectedRows = [];
        this.selectedCellRowNode = undefined;
        this.currentClickedCellColDef = undefined;

        if (!this.grid?.api) {
            return;
        }

        // Even though in resetSelectedRows we are calling .setSelected() on every node
        // for some reason the real selectionChangedEvent isn't triggered in this case
        // so we are faking one in order to update the TFL Details CSV Export icon
        this.selectionChangedEvent.emit({
            event: {
                api: this.grid.api,
                type: 'selectionChanged',
                context: null,
                source: undefined,
            },
            hasSelectedRows: false,
        });
    }

    getSelectedNodes(): IRowNode[] {
        return this.grid?.api?.getSelectedNodes() ?? [];
    }

    getEditingCells(): CellPosition[] {
        return this.grid?.api?.getEditingCells() ?? [];
    }

    horizontallyScrollToTheBeginning(): void {
        const column = this.getAllDisplayedColumns()?.[1];
        if (column) {
            this.grid?.api?.ensureColumnVisible(column);
        }
    }

    getAllNodes(): IRowNode[] {
        const allNodes: IRowNode[] = [];
        this.grid?.api?.forEachNode((node) => allNodes.push(node));
        return allNodes;
    }

    setColumnWidth(column: string | Column, width: number): void {
        this.grid?.api?.setColumnWidths([{ key: column, newWidth: width }]);
    }

    isPivotMode(): boolean {
        return !!this.grid?.api?.isPivotMode();
    }

    isColumnVisible(colId: string): boolean {
        return !!this.getColumn(colId)?.isVisible();
    }

    getFilterModel(): AgGridFilterModel | undefined {
        return this.grid?.api?.getFilterModel();
    }

    clearAllFilters(): void {
        this.setFilterModel();
        this.onFilterChanged();
    }

    onDragStopped(event: DragStoppedEvent): void {
        this.dragStoppedEvent.emit({ event, columns: this.getColumnState() });
    }

    moveColumn(columnId: string | Column, position: number): void {
        this.grid?.api?.moveColumns([columnId], position);
    }

    setColumnPinned(key: string, pinned: ColumnPinnedType): void {
        this.grid?.api?.setColumnsPinned([key], pinned);
    }

    setFilterModel(filterModel: AgGridFilterModel | null = null): void {
        this.grid?.api?.setFilterModel(filterModel);
    }

    setRowGroupColumns(colKeys: (string | Column)[]): void {
        this.grid?.api?.setRowGroupColumns(colKeys);
    }

    onColumnPinned(event: ColumnPinnedEvent): void {
        this.columnPinnedEvent.emit(event);
    }

    enableClickSelection(enable: boolean): void {
        this.gridOptions.rowSelection = {
            ...this.gridOptions.rowSelection as RowSelectionOptions,
            enableClickSelection: enable,
        };
    }

    processCellForClipboard(params: ProcessCellForExportParams): string {
        return params.value;
    }

    private initializeGridConfiguration(gridConfiguration: GridConfiguration): void {
        this.gridConfiguration = new DefaultDataGridConfiguration(gridConfiguration);
        this.dataGridOptions = { ...this.defaultDataGridOptions, ...this.gridConfiguration.gridOptions };
        this.gridOptions = { ...this.gridOptions, ...this.dataGridOptions as GridOptions };
        this.columnDefinitions = this.mergeColumnDefinitions(this.gridConfiguration.columnDefinitions);
        this.gridOptions.navigateToNextCell = this.customNavigation.bind(this);

        this.gridOptions.aggFuncs = {
            zero: (): number => 0,
        };

        this.gridOptions.context = { componentParent: this };
    }

    private sizeColumnsToFit(): void {
        this.grid?.api?.sizeColumnsToFit();
    }

    private setMaxAllowedWidthForAllColumns(columnIds: string[]): void {
        const displayedColumns = this.getAllDisplayedColumns();
        columnIds.forEach((columnId: string) => {
            const column = displayedColumns?.find((c) => c.getColId() === columnId);
            if (column && column.getActualWidth() > this.maxAllowedColumnWidth) {
                this.setColumnWidth(column.getColId(), this.maxAllowedColumnWidth);
            }
        });
    }

    private setMaxAllowedColumnWidth(columnId: string): void {
        const displayedColumns = this.getAllDisplayedColumns();
        const column = displayedColumns?.find((col: Column) => col.getColId() === columnId);
        if (column && column.getActualWidth() > this.maxAllowedColumnWidth) {
            this.setColumnWidth(columnId, this.maxAllowedColumnWidth);
        }
    }

    private getGroupedColumns(columns: Column[]): Column[] {
        return columns.filter((c): boolean => {
            const colId = c.getColId().replace(CompareColumnID.COMPARE, '').replace(CompareColumnID.DIFF, '');
            const groupId = colId + COMPARE_GROUP_ID_SUFFIX;
            return !!c.getParent() && c.getParent()?.getGroupId() === groupId;
        });
    }

    private getNonGroupedColumns(columns: Column[]): Column[] {
        return columns.filter((c): boolean => {
            const colId = c.getColId().replace(CompareColumnID.COMPARE, '').replace(CompareColumnID.DIFF, '');
            const groupId = colId + COMPARE_GROUP_ID_SUFFIX;
            return !(c.getParent() && c.getParent()?.getGroupId() === groupId);
        });
    }

    private getNonGroupedColumnsIds(columns: Column[]): string[] {
        return this.getNonGroupedColumns(columns)
            .reduce((visibleColumns, currentColumn) => {
                if (currentColumn.isVisible()) {
                    visibleColumns.push(currentColumn.getColId());
                }
                return visibleColumns;
            }, [] as string[]);
    }

    private getColumnTextWidth(inputText = ''): number {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
            return 0;
        }

        context.font = HEADER_COLUMN_FONT;
        const width = context.measureText(inputText).width;
        // THESE MAGIC NUMBERS ARE:
        // * 1.333333 to turn points into pixels (since our current fonts are in pt)
        // + 50px ~ the current margins/paddings we have on column headers
        return (Math.ceil(width) * 1.333333) + 50;
    }

    private resizeGroupColumns(groupedColumns: Column[], overrides: UserGridColumnOverrides[], addExtraWidth: boolean): void {
        groupedColumns.forEach((c) => {
            const override = overrides.find((o) => o.columnId === c.getColId());
            // eslint-disable-next-line  @typescript-eslint/prefer-optional-chain
            if (!this.isInDashboardViewMode() || !override || override.columnWidth == null) {
                const columnWidth = c.getUserProvidedColDef()?.width ?? 0;
                const headerName = c.getUserProvidedColDef()?.headerName;
                const headerWidth = this.getColumnTextWidth(headerName);
                // add some extra pixels for case when rows are grouped
                // ex: column name becomes sum(headerName)
                const headerNameWidth = addExtraWidth ? headerWidth + 35 : headerWidth;
                const width = columnWidth > headerNameWidth ? columnWidth : headerNameWidth;
                this.setColumnWidth(c, width);
            }
        });
    }

    private getColumnGroupState(): ColumnHeaderGroupState[] {
        const columnHeaderGroupState: ColumnHeaderGroupState[] = [];
        if (this.gridConfiguration?.isColumnGroupEnabled || this.gridConfiguration?.isRowGroupEnabled) {
            const rowGroupState = this.getRowGroupColumns();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.getAllDisplayedColumnGroups()?.forEach((columnGroupState: any) => {
                if (!columnGroupState.groupId) {
                    return;
                }

                if (columnGroupState?.originalColumnGroup?.colGroupDef?.headerName === '') {
                    columnGroupState.originalColumnGroup.colGroupDef.headerName = 'Blanks';
                }

                const columnHeaderChildren: ColumnHeaderChild[] = [];
                if (columnGroupState.children?.length) {
                    if (columnGroupState.children[0].colDef?.rowGroupColumnHeader) {
                        rowGroupState?.forEach((childDef) => {
                            if (!this.getColumn(childDef.getColId())?.isVisible()) {
                                columnHeaderChildren.push({ colDef: childDef.getColDef() });
                            }
                        });
                    } else {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        columnHeaderChildren.push(...(columnGroupState.children.map((childDef: any) => ({ colDef: childDef.colDef }))));
                    }
                } else {
                    columnHeaderChildren.push(...(columnGroupState.colDef));
                }
                columnHeaderGroupState.push({
                    isGroupedColumn: !!this.gridConfiguration?.columnDefinitions
                        .some((colDef) => (colDef as ColGroupDef).groupId === columnGroupState.groupId),
                    groupId: columnGroupState.groupId,
                    children: columnHeaderChildren,
                    openByDefault: columnGroupState.isExpanded ? columnGroupState.isExpanded() : false,
                });
            });
        }
        return columnHeaderGroupState;
    }

    private autoFitAndSizeColumnsBasedOnConfig(): void {
        const { previousValue, currentValue } = this.selectedRowsCount;
        const headerCheckboxChanged = (previousValue === 0 && currentValue !== 0) || (previousValue !== 0 && currentValue === 0);
        if (this.isManagingWidget || (!headerCheckboxChanged && !this.hideLoaderAfterFirstDataLoad)) {
            this.autoFitColumnsBasedOnConfig();
            this.autoSizeColumnsBasedOnConfig();
        }
    }

    private isCrosstalkColDef(colDef: CustomColDef): boolean {
        return !!colDef.isUserDefinedField ||
            colDef.field === TrebekConversationFields.HSComment ||
            colDef.field === TrebekConversationFields.ClientComment;
    }

    private handleRowSelection(event: RowClickedEvent): void {
        // This whole thing happening below is very wrong
        // and is a workaround over ag-grid's api for selection which anyway is quite buggy
        const { node } = event;
        const { shiftKey, ctrlKey, metaKey } = event.event as PointerEvent;

        const isSameRowSelected = this.selectedRow?.rowIndex === event.node.rowIndex && !!this.selectedRow?.isSelected();
        const resetSelected = (): void => {
            this.resetSelectedRows();
            this.selectedRow = undefined;
        };

        const setSelected = (rowNode: IRowNode): void => {
            rowNode.setSelected(true);
            this.selectedRow = rowNode;
        };

        if (!node.group) {
            if (!shiftKey && !ctrlKey && !metaKey) {
                this.handleRowSelectionWithoutKey(node, isSameRowSelected, resetSelected);
            } else if (ctrlKey || metaKey) {
                this.handleRowSelectionWithCtrlKey(node);
            }
        } else {
            if (!shiftKey && !ctrlKey && !metaKey) {
                if (!this.selectedRow) {
                    setSelected(node);
                } else if (isSameRowSelected) {
                    resetSelected();
                    this.selectedCellRowNode = node;
                } else {
                    /*
                    * preserve selected rows when
                    * the row is a crosstalk data (not group) row
                    * or dataset is TFL Details
                    * so that we don't lose selected checkboxes when clicking on a group row
                    * */
                    if (!(this.datasetIncludesCrosstalk || this.isShowingTFLData)) {
                        this.resetSelectedRows();
                        setSelected(node);
                        this.selectedCellRowNode = undefined;
                    }
                }

                this.selectedRows.push(node);
            } else if (ctrlKey || metaKey) {
                if (node.isSelected()) {
                    node.setSelected(false);
                    // not really sure why this doesn't clear all the selected cells
                    // but it does the job in our case
                    this.resetSelectedCells();
                } else {
                    setSelected(node);
                    this.selectedRows.push(node);
                }
            }
        }

        // mostly works the same both for node.group and !node.group
        if (shiftKey) {
            this.handleRowSelectionWithShiftKey(node);
        }
    }

    private handleRowSelectionWithShiftKey(node: IRowNode): void {
        if (!this.selectedRow && !this.selectedCellRowNode) {
            this.selectedRow = node;
            if (node.group) {
                node.setSelected(true);
            }
        } else if (this.selectedCellRowNode) {
            const rowIndex = this.selectedCellRowNode?.rowIndex;
            const currentNode = rowIndex ? this.grid?.api?.getDisplayedRowAtIndex(rowIndex) : undefined;
            if (node.group) {
                currentNode?.setSelected(true);
            }
            this.selectedRow = currentNode;
        }
        this.resetSelectedCells();
        this.resetSelectedRows();

        if (this.selectedRow) {
            // this are arbitrary defaults
            const sorted = [this.selectedRow.rowIndex ?? 0, node.rowIndex ?? 0].sort((a, b) => a - b);
            this.selectedRows = [];
            this.grid?.api?.forEachNode((rowNode) => {
                const rowIndex = rowNode.rowIndex;
                if (rowIndex != null && rowIndex >= sorted[0] && rowIndex <= sorted[1]) {
                    rowNode.setSelected(true);
                    this.selectedRows.push(rowNode);
                }
            });
        }
    }

    private handleRowSelectionWithCtrlKey(node: IRowNode): void {
        const currentRowIsSelected = this.selectedRows.find((row) => row.rowIndex === node.rowIndex);
        if (this.selectedCellRowNode && this.selectedCellRowNode.rowIndex === node.rowIndex || currentRowIsSelected) {
            this.resetSelectedCells();
        }
        // This is very wrong, it removes the row from the DOM and redraws it
        // but couldn't find another way to clear the cells for non-group row
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.grid?.api?.redrawRows({ rowNodes: [(node as any).rowIndex] });
        if (node.isSelected()) {
            this.selectedRows.push(node);
        }
        this.selectedRow = node;
    }

    private handleRowSelectionWithoutKey(node: IRowNode, isSameRowSelected: boolean, resetSelected: () => void): void {
        if (isSameRowSelected) {
            if (this.currentClickedCellColDef && !this.isCrosstalkColDef(this.currentClickedCellColDef) && !this.isShowingTFLData) {
                resetSelected();
            }

            if (this.isOnMasterWidget) {
                this.resetSelectedCells();
                this.selectedCellRowNode = undefined;
            } else {
                this.selectedCellRowNode = node;
            }
        } else {
            this.selectedRow = node;
            this.selectedCellRowNode = undefined;
        }
        this.selectedRows.push(node);
    }

    private getColumn(colId: string): Column | undefined | null {
        return this.grid.api?.getColumn(colId);
    }

    private getAllDisplayedColumnGroups(): (Column | ColumnGroup)[] | undefined | null {
        return this.grid?.api?.getAllDisplayedColumnGroups();
    }

    private getSortModel(): AgGridSortState[] {
        const sortModel: AgGridSortState[] = [];
        this.getColumnState()?.forEach((column) => {
            sortModel.push({ colId: column.colId, sort: column.sort as AgGridSortDirection | undefined, sortIndex: column.sortIndex });
        });
        return sortModel;
    }

    private getColumnState(): ColumnState[] | undefined {
        return this.grid?.api?.getColumnState();
    }

    private getColumnsForResizing(overrides: UserGridColumnOverrides[], columnIds: string[]): string[] {
        const columnsForResizing = [];
        for (const columnId of columnIds) {
            const override = overrides.find((o) => o.columnId === columnId);
            const columnGroupOrderSameAsOverride =
                override?.groupColumnsOrderForGroupWidth === this.getRowGroupColumns()?.map((c) => c.getColId()).join();
            const columnShouldBeResized = !this.isInDashboardViewMode() ||
                // eslint-disable-next-line  @typescript-eslint/prefer-optional-chain
                !override ||
                override?.columnWidth == null ||
                (columnId === AUTO_GROUP_COLUMN_ID && !columnGroupOrderSameAsOverride);
            if (columnShouldBeResized) {
                columnsForResizing.push(columnId);
            }
        }
        return columnsForResizing;
    }

    private keepGroupColumnOverrideWidth(): boolean {
        if (!this.widgetId || !this.visualizationId) {
            return false;
        }

        const overrides = this.overridesRelay.getCurrentGridColumnOverrides(this.widgetId, this.visualizationId);
        const groupOverride = overrides.find((o) => o.columnId === AUTO_GROUP_COLUMN_ID);
        const groupColumnsOrder = groupOverride?.groupColumnsOrderForGroupWidth;
        const currentGroupColumnsOrder = this.getRowGroupColumns()?.map((c) => c.getColId());
        const isGroupColumnsOrderSameAsOverride = groupColumnsOrder === currentGroupColumnsOrder?.join();
        return this.isInDashboardViewMode() && groupOverride?.columnWidth != null && isGroupColumnsOrderSameAsOverride;
    }

    private isInDashboardViewMode(): boolean {
        return !this.isManagingWidget && this.dashboardMode.current === 'view';
    }

    private updateColumnsSortState(state: GridState): void {
        const { columnState, sortState } = state;
        columnState.forEach((column) => {
            const currentColumn = sortState.find((c) => c.colId === column.colId);
            if (currentColumn) {
                (column as ColDef).sort = currentColumn.sort;
                (column as ColDef).sortIndex = currentColumn.sortIndex;
            }
        });
    }
}
