import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Action } from '@ddv/models';

@Component({
    selector: 'ddv-actions-handler',
    templateUrl: './actions-handler.component.html',
    styleUrls: ['./actions-handler.component.scss'],
})
export class ActionsHandlerComponent implements OnChanges {
    @Input() actions: Action[] | undefined;
    @Input() filterName: string | undefined;
    @Input() filterActive = false;

    @Output() actionPressed: EventEmitter<string> = new EventEmitter();
    @Output() filterActiveChange: EventEmitter<boolean> = new EventEmitter();

    protected iconDescriptors: { iconName: string, actionName: string }[] = [];

    ngOnChanges(): void {
        this.iconDescriptors = this.actions?.map((action) => {
            return {
                iconName: actionNameToIcon(action.name),
                actionName: action.name,
            };
        }) ?? [];
    }
}

function actionNameToIcon(actionName: string): string {
    switch (actionName) {
        case 'release':
            return 'send';
        default:
            return 'error';
    }
}
