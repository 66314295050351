export const dateFormat = 'MM/DD/YYYY';

export function isStringValidISODate(date: string): boolean {
    const d = new Date(date);
    return !isNaN(+d) && d.toISOString() === date;
}

export function getUTCDate(dateString: string): Date {
    return getDate(dateString, isStringValidISODate(dateString));
}

export function getDate(dateString: string, isISOFormat: boolean): Date {
    return isISOFormat ? new Date(dateString) : parseUTCDate(dateString);
}

function parseUTCDate(dateString: string): Date {
    const date = new Date(dateString);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}
