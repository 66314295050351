<div class="error-modal">
    <div class="header">
        <h2 class="title-modal">Error Occurred -
            <span>{{widgetName}}</span>
        </h2>
        <a class="icon-close1" (click)="onClose()"></a>
    </div>
    <div class="error-modal-body">
        <p>{{exception.time}}</p>
        <h4>{{exception.message}}</h4>
    </div>
</div>
