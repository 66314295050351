import { Injectable } from '@angular/core';
import { anyWidgetIsRealtime, AppWidgetState } from '@ddv/models';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class RealtimeActiveService {
    public readonly realtimeIsActive: Observable<boolean>;
    private isActive = false;
    private isPaused = false;
    private readonly realtimeIsActiveSubject: Subject<boolean> = new ReplaySubject<boolean>(1);

    constructor() {
        this.realtimeIsActive = this.realtimeIsActiveSubject.asObservable();
    }

    updateRealtimeActive(widgetsOrState: AppWidgetState[] | boolean): void {
        this.isActive = Array.isArray(widgetsOrState) ? anyWidgetIsRealtime(widgetsOrState) : widgetsOrState;
        this.realtimeIsActiveSubject.next(this.isActive);
    }

    pauseRealtimeUpdates(): void {
        if (this.isActive && !this.isPaused) {
            this.isPaused = true;
            this.realtimeIsActiveSubject.next(false);
        }
    }

    resumeRealtimeUpdates(): void {
        if (this.isPaused && this.isActive) {
            this.isPaused = false;
            this.realtimeIsActiveSubject.next(true);
        }
    }
}
