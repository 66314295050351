import { NgModule } from '@angular/core';

import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { InputRestrictDirective } from './directives/input-restrict.directive';
import { MousedownOutsideDirective } from './directives/mousedown-outside.directive';
import { ScrollToChildDirective } from './directives/scroll-to-child.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { Highlight } from './pipes/highlight.pipe';
import { TextValueFilter } from './pipes/text-value.pipe';
import { CurrentStateService } from './services/current-state.service';
import { DirtyFlagService } from './services/dirty-flag-dialog.service';
import { LoaderService } from './services/loader.service';
import { RealtimeActiveService } from './services/realtime-active.service';

@NgModule({
    declarations: [
        AutoFocusDirective,
        ClickOutsideDirective,
        FilterPipe,
        Highlight,
        InputRestrictDirective,
        MousedownOutsideDirective,
        ScrollToChildDirective,
        TextValueFilter,
    ],
    exports: [
        AutoFocusDirective,
        ClickOutsideDirective,
        FilterPipe,
        Highlight,
        InputRestrictDirective,
        MousedownOutsideDirective,
        ScrollToChildDirective,
        TextValueFilter,
    ],
    providers: [
        CurrentStateService, // it really doesn't belong here, but it really doesn't belong anywhere else either
        DirtyFlagService,
        LoaderService,
        RealtimeActiveService,
    ],
})
export class BehaviorsModule {}
