import { Injectable, Injector, ComponentFactoryResolver } from '@angular/core';

import { ModalDialogRef } from './modal-dialog-ref.service';
import { ModalDialogStack } from './modal-dialog-stack.service';

export interface ModalDialogOptions {
    backdrop?: boolean | 'static';
    container?: string;
    injector?: Injector;
    keyboard?: boolean;
    size?: 'sm' | 'lg';
    windowClass?: string;
    data?: object;
}

@Injectable()
export class ModalDialogService {
    constructor(
        private readonly moduleCFR: ComponentFactoryResolver,
        private readonly injector: Injector,
        private readonly modalDialogStack: ModalDialogStack) {
    }

    open(content: unknown, options: ModalDialogOptions = {}): ModalDialogRef {
        return this.modalDialogStack.open(this.moduleCFR, this.injector, content, options);
    }
}
