<div class="widget-finder">
    <div class="search-bar">
        <div class="search-input">
            <span class="hs-icon-search-right"></span>

            <input
                type="search"
                appAutoFocus
                appInputRestrict
                allowPattern="^[\w\s\/\-%&^#@()<>]+$"
                placeholder="Find a Widget"
                autocomplete="off"
                [(ngModel)]="searchParam"
                (ngModelChange)="filterWidgets()"
                [focus]="!loaderInFinder"/>

            <div class="eg-text">E.g. PNL, Time Series, Risk, etc...</div>
        </div>

        <button type="button" class="ghost-btn" (click)="createWidget.emit()">Create New Widget</button>
    </div>

    <div class="filter-settings">
        <div class="only-global toggle">
            <cc-option-toggle
                id="only-global"
                [(value)]="onlyGlobals"
                [labels]="['GLOBAL', 'ALL']"
                (valueChange)="showOnlyGlobals($event)">
            </cc-option-toggle>
        </div>

        Group By:
        <div
            class="group-by"
            *ngFor="let group of widgetGroups | keyvalue"
            [class]="{'selected':groupByText === group.value.toString()}"
            (click)="groupWidgetsBy(group.value)"
            [innerHTML]="group.value">
        </div>

    </div>

    <ng-container *ngIf="filteredWidgets">
        <div class="search-content" [ngClass]="{'no-widgets': filteredWidgets.length === 0 && !loaderInFinder}">
            <app-loader [isDataLoading]="loaderInFinder"></app-loader>

            <ng-container *ngFor="let group of filteredWidgets">
                <div class="group" *ngIf="group.widgets.length">
                    <div class="title-section">
                        <div class="title">{{ group.groupName }}</div>

                        <div class="count">
                            {{ (group.widgets.length < group.totalWidgets ? group.widgets.length + ' of ' : null) }}{{ group.totalWidgets }}
                            widgets
                        </div>

                        <span
                                class="show-more"
                                (click)="group.collapsed = !group.collapsed">
                            Show {{ group.collapsed ? 'more' : 'less' }}
                        </span>
                    </div>

                    <div class="group-content" [ngClass]="{'collapsed': group.collapsed}">
                        <div class="card" *ngFor="let widget of group.widgets">
                            <div class="title-wrap">
                                <div
                                    class="title"
                                    (click)="openWidget(widget)"
                                    [ngClass]="{'global': widget.isGlobal}"
                                    [innerHTML]="(widget.displayNameType === 'CUSTOM' ? widget.customDisplayName : widget.name) | highlight : searchParam"></div>

                                <div class="global-icon" *ngIf="widget.isGlobal">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15" width="20px" height="15px" xml:space="preserve">
                                        <path d="M14.7,14.4H5.3c-1.2,0-2.2-1-2.2-2.2V2.8c0-1.2,1-2.2,2.2-2.2h9.3c1.2,0,2.2,1,2.2,2.2v9.3
                                                C16.9,13.4,15.9,14.4,14.7,14.4z M5.3,1.1c-1,0-1.8,0.8-1.8,1.8v9.3c0,1,0.8,1.8,1.8,1.8h9.3c1,0,1.8-0.8,1.8-1.8V2.8
                                                c0-1-0.8-1.8-1.8-1.8H5.3z"/>
                                        <path d="M11.5,10.3c-0.4,0.5-1,0.8-1.7,0.8c-1.5,0-2.5-1.3-2.5-3.4c0-2.1,1-3.3,2.7-3.3c1.2,0,2,0.6,2.3,1.7
                                                l-1.1,0.3C11,5.7,10.6,5.4,10,5.4c-0.9,0-1.4,0.8-1.4,2.3C8.6,9.2,9.1,10,10,10c0.7,0,1.2-0.5,1.3-1.3H10v-1h2.4V11h-0.9
                                                L11.5,10.3z"/>
                                    </svg>
                                </div>
                            </div>

                            <div class="viz-type viz-icons {{ visStyle[widget.coreWidgetType === 'VISUALIZATION' ? widget.visualizations[0] : widget.coreWidgetType ?? ''] }}-{{ theme }}"></div>

                            <div class="description" [innerHTML]="widget.description | highlight : searchParam"></div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="no-widgets-label" *ngIf="!filteredWidgets.length && !loaderInFinder">
                No widgets available - please refine your search
            </div>
        </div>
    </ng-container>
</div>
