import { AgFilterComponent } from '@ag-grid-community/angular';
import { IDoesFilterPassParams, IFilterParams, IRowNode, RowNode } from '@ag-grid-community/core';
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { CustomColDef } from '@ddv/data-grid';
import { TFL_DETAILS_AUTO_GROUP_COLUMN_ID, TRANSACTION_TYPE_FIELD, ValueFilterOption, DdvDate } from '@ddv/models';

import { GrouperCommentService } from '../../services/grouper-comment.service';

@Component({
    selector: 'app-custom-column-filter',
    templateUrl: './custom-column-filter.component.html',
    styleUrls: ['./custom-column-filter.component.scss'],
})
export class CustomColumnFilterComponent implements AgFilterComponent {
    params: IFilterParams | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueGetter: ((rowNode: IRowNode) => any) | undefined;
    cellData: ValueFilterOption[] = [];
    filteredCellData: ValueFilterOption[] = [];
    searchValue = '';
    selectAll: boolean | undefined = true;
    private filteredValues: Set<string> = new Set();

    constructor(
        private readonly grouperCommentService: GrouperCommentService,
        private readonly datePipe: DatePipe,
    ) { }

    agInit(params: IFilterParams): void {
        this.grouperCommentService.updateCustomFilters.subscribe((updateCustomFilters) => {
            if (updateCustomFilters) {
                this.initData(true, this.cellData);
                this.updateSelectAll();
            }
        });

        this.params = params;
        this.valueGetter = params.getValue;
        this.initData();
    }

    private initData(newRowsLoaded = false, previousCellData: ValueFilterOption[] = []): void {
        const filterOptions = new Set();
        this.cellData = [];
        this.filteredCellData = [];
        const { rowModel, colDef } = this.params!;
        const { userDefinedFieldType } = colDef as CustomColDef;
        rowModel.forEachNode((row: RowNode) => {
            const isAllocation = row.data?.[TFL_DETAILS_AUTO_GROUP_COLUMN_ID] &&
                row.parent!.field === TFL_DETAILS_AUTO_GROUP_COLUMN_ID &&
                !row.data[TRANSACTION_TYPE_FIELD];
            if (!isAllocation) {
                const isCrosstalkCreatedField =
                    colDef.field === 'conversationLastHedgeservCommentCreated' ||
                    colDef.field === 'conversationLastClientCommentCreated';
                const data = row.data?.[colDef.field!];
                const value = userDefinedFieldType === 'choice' || userDefinedFieldType === 'string' || userDefinedFieldType === 'boolean' ?
                    data?.value :
                    data;

                if (value !== undefined) {
                    let checked = true;
                    const formattedValue = this.getFormattedValue(value);

                    if (newRowsLoaded && previousCellData.length) {
                        const current = previousCellData.find((item) => item.value === formattedValue);
                        if (current) {
                            checked = !!current.checked;
                        }
                    }
                    const entryItem: ValueFilterOption = {
                        text: this.getTextFromValue(value, isCrosstalkCreatedField),
                        value: formattedValue,
                        checked,
                    };
                    if (!filterOptions.has(entryItem.text)) {
                        this.cellData.push(entryItem);
                        filterOptions.add(entryItem.text);
                    }
                }
            }
        });

        this.cellData.sort((a, b) => a.text.toString().localeCompare(b.text));

        const blanksIndex = this.cellData.findIndex((datum) => datum.text === 'Blanks');
        if (blanksIndex !== -1) {
            const blanksItem = this.cellData.splice(blanksIndex, 1)[0];
            this.cellData.unshift(blanksItem);
        }

        this.filterItems();
        this.updateFilteredValues();
    }

    private getFormattedValue(value: string | number | boolean | null): string {
        return value == null ?
            'Null' :
            value.toString();
    }

    private getTextFromValue(value: string | number | boolean | null, isCrosstalkCreatedField = false): string {
        if (value == null) {
            return 'Null';
        }
        if (value === '') {
            return 'Blanks';
        }
        if (isCrosstalkCreatedField && DdvDate.isStringValidDate(value as string)) {
            return this.datePipe.transform(new Date(value as string).toString(), 'MM/dd/yyyy hh:mm:ss a') ?? '';
        }

        return value.toString();
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        const nodeToCheck = this.getNodeToCheck(params.node);
        const value = this.valueGetter?.(nodeToCheck);
        const strValue = this.getFormattedValue(value);
        return this.filteredValues.has(strValue);
    }

    private getNodeToCheck(node: IRowNode): IRowNode {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!(this.params as any)?.isTFLDetailsDataset) {
            return node;
        }

        const isGroupedByAllocationGroupId = node.parent?.field === TFL_DETAILS_AUTO_GROUP_COLUMN_ID;
        if (!isGroupedByAllocationGroupId) {
            return node;
        }

        const hasAllocationGroupId = !!node.data[TFL_DETAILS_AUTO_GROUP_COLUMN_ID];
        if (!hasAllocationGroupId) {
            return node;
        }

        const block = node.parent?.allLeafChildren?.find((child) => child.data[TRANSACTION_TYPE_FIELD]);
        return block ? block : node;
    }

    isFilterActive(): boolean {
        const cellDataCheckedItems = !!this.cellData.filter((item) => item.checked).length;
        const filteredCellDataCheckedItems = !!this.filteredCellData.filter((item) => item.checked).length;

        return !(cellDataCheckedItems === filteredCellDataCheckedItems && this.selectAll && !this.searchValue);
    }

    getModel(): { filterType: string, values: string[] } | null {
        if (this.cellData.some((item) => !item.checked)) {
            return { filterType: 'set', values: [...this.filteredValues] };
        }

        return null;
    }

    setModel(model: { filterType: string, values: string[] } | null): void {
        if (model) {
            this.cellData.forEach((filter) => {
                if (!model.values.includes(filter.value)) {
                    filter.checked = false;
                }
            });
        } else {
            this.cellData.forEach((filter) => filter.checked = true);
            this.searchValue = '';
            this.filteredCellData = this.cellData;
        }
        this.updateFilteredValues();
        this.updateSelectAll();
        this.params?.filterChangedCallback();
    }

    onNewRowsLoaded(): void {
        // setTimeout() is intentional - otherwise it cannot get the updated rowModel
        // AG Grid has and issue with this method as stated here:
        // https://stackoverflow.com/questions/67422448/ag-grid-custom-filter-angular-how-to-get-row-data-inside-onnewrowsloaded
        setTimeout(() => {
            this.initData(true, this.cellData);
            this.updateSelectAll();
            this.params?.filterChangedCallback();
        }, 50);
    }

    private filterItems(): void {
        this.filteredCellData = this.searchValue ?
            this.cellData.filter((item) => item.text.toLocaleLowerCase().includes(this.searchValue?.toLocaleLowerCase() ?? '')) :
            this.cellData;
    }

    private updateSelectAll(): void {
        const checked = this.filteredCellData.filter((item) => item.checked).length;
        this.selectAll = checked > 0 && checked < this.filteredCellData.length ? undefined : !!checked;
    }

    onSearch(): void {
        this.filterItems();
        this.updateSelectAll();
    }

    onSelectAll(): void {
        this.filterItems();
        this.filteredCellData.forEach((item) => item.checked = !!this.selectAll);
        this.updateSelectAll();
        this.updateFilteredValues();
        this.params?.filterChangedCallback({ source: 'columnFilter', isCustomFilter: true });
    }

    onCheckChanged(): void {
        this.updateFilteredValues();
        this.filterItems();
        this.updateSelectAll();
        this.params?.filterChangedCallback({ source: 'columnFilter', isCustomFilter: true });
    }

    private updateFilteredValues(): void {
        this.filteredValues = this.cellData.reduce((items, currentItem) => {
            if (currentItem.checked) {
                items.add(currentItem.value);
            }
            return items;
        }, new Set<string>());
    }
}
