import { NgModule } from '@angular/core';

import { WidgetDragPubSubService } from './widget-drag-pub-sub.service';
import { WidgetDragTargetDirective } from './widget-drag-target.directive';
import { WidgetDragDirective } from './widget-drag.directive';

@NgModule({
    providers: [
        WidgetDragPubSubService,
    ],
    declarations: [
        WidgetDragDirective,
        WidgetDragTargetDirective,
    ],
    exports: [
        WidgetDragDirective,
        WidgetDragTargetDirective,
    ],
})
export class WidgetDragModule {}
