export type HostAndPort = 'on-prem' | string | undefined;

export interface ApiLocation {
    hostAndPort: HostAndPort;
    rootUri: string;
}

export interface ApiConfiguration {
    ddvMWLocation: ApiLocation;
    monitorMWLocation: ApiLocation;
    ddvApiLocation: ApiLocation;
    monitorApiLocation: ApiLocation;
    usageApiLocation: ApiLocation;
    flagsApiLocation: ApiLocation;
    trebekApiLocation: ApiLocation;
    wauthApiLocation: ApiLocation;
    xtlkApiLocation: ApiLocation;
    reportRunnerApiLocation: ApiLocation;
    errorLoggerLocation: ApiLocation;
    fmpLocation: ApiLocation;
    pscLocation: ApiLocation;
    portfolioDetailsHostAndPort: HostAndPort;
    nqsApiLocation: ApiLocation;
    production: boolean;
    onPremApiHost: string;
    replaceHostInMetadataAndQueryURLs: boolean;
    xtlkManagementLocation: ApiLocation;
}

export const defaultApiConfiguration: ApiConfiguration = {
    ddvMWLocation: {
        hostAndPort: undefined,
        rootUri: './api/',
    },
    monitorMWLocation: {
        hostAndPort: undefined,
        rootUri: './',
    },
    ddvApiLocation: {
        hostAndPort: undefined,
        rootUri: './v2/api/',
    },
    monitorApiLocation: {
        hostAndPort: undefined,
        rootUri: './v2/api/',
    },
    trebekApiLocation: {
        hostAndPort: undefined,
        rootUri: '/public-api/',
    },
    usageApiLocation: {
        hostAndPort: undefined,
        rootUri: '/client_usage/',
    },
    flagsApiLocation: {
        hostAndPort: undefined,
        rootUri: '/flags/api/',
    },
    wauthApiLocation: {
        hostAndPort: undefined,
        rootUri: '/wauth/api/',
    },
    xtlkApiLocation: {
        hostAndPort: undefined,
        rootUri: '/two-way/api/',
    },
    reportRunnerApiLocation: {
        hostAndPort: undefined,
        rootUri: '/data/',
    },
    errorLoggerLocation: {
        rootUri: '/errors',
        hostAndPort: undefined,
    },
    fmpLocation: {
        rootUri: '/fmp',
        hostAndPort: undefined,
    },
    pscLocation: {
        hostAndPort: undefined,
        rootUri: '/psc/',
    },
    portfolioDetailsHostAndPort: undefined,
    nqsApiLocation: {
        hostAndPort: undefined,
        rootUri: '/named-query/api/',
    },
    onPremApiHost: 'localhost:8100',
    production: false,
    replaceHostInMetadataAndQueryURLs: true,
    xtlkManagementLocation: {
        hostAndPort: undefined,
        rootUri: '/two-way/',
    },
};

export function apiRootForService(service: keyof ApiConfiguration, config: ApiConfiguration): string {
    const location: ApiLocation = config[service] as ApiLocation;
    const hostAndPort = hostAndPortForService(location.hostAndPort, config.onPremApiHost);

    // regex to remove // or /// from the URI
    const hostAndPortAndPath = `${hostAndPort}/${location.rootUri}`.replace(/\/{2,}/g, '/');
    return `${window.location.protocol}//${hostAndPortAndPath}`;
}

export function hostAndPortForService(hostAndPort: HostAndPort, onPremApiHost: string): string {
    return hostAndPort === 'on-prem' ? onPremApiHost : (hostAndPort ?? window.location.host);
}

export function getApmEnvironment(): string {
    const { hostname, pathname } = window.location;

    if (hostname.includes('hsuat')) {
        return pathname.startsWith('/ddv-edge') || hostname.includes('poc-rnd') ? 'edge' : 'uat';
    } else if (hostname.includes('access-dr')) {
        return 'dr';
    } else {
        return 'prod';
    }
}

export function getApmHost(): string {
    const hostname = window.location.hostname;

    if (hostname.includes('hsuat')) {
        return 'access.hsuat.com';
    } else if (hostname.includes('access-dr')) {
        return 'access-dr.hedgeserv.com';
    } else if (hostname.includes('hedgeserv.com')) {
        return 'access.hedgeserv.com';
    } else {
        return '';
    }
}

export function getOnPremApiHost(): string {
    const hostname = window.location.hostname;

    if (hostname.includes('hsuat')) {
        return 'access.hsuat.com';
    } else if (hostname.includes('access-dr')) {
        return 'access-dr.hedgeserv.com';
    } else {
        return 'access.hedgeserv.com';
    }
}

const awsProdRoot = 'app.hedgeserv.com';
const awsTstRoot = 'app-tst.hedgeserv.com';
const awsRndRoot = 'poc-rnd.hsuat.com';
const awsUatRoot = 'app.hsuat.com';

export function getNQSApiHost(): string {
    const app = 'named-query';
    const location = window.location;

    if (location.hostname.includes('hsuat')) {
        return (location.pathname.includes('/ddv-edge/') || location.hostname.includes('poc-rnd')) ?
            `${app}.${awsRndRoot}` :
            `${app}.${awsUatRoot}`;
    } else if (location.hostname.includes('hedgeserv.com')) {
        return location.hostname.includes('app-tst') ?
            `${app}.${awsTstRoot}` :
            `${app}.${awsProdRoot}`;
    }

    return 'on-prem';
}

export function getPSCHostAndPort(): string {
    const app = 'psc';
    const location = window.location;

    if (location.hostname.includes('hsuat')) {
        return (location.pathname.includes('/ddv-edge/') || location.hostname.includes('poc-rnd')) ?
            `${app}.${awsRndRoot}` :
            `${app}.${awsUatRoot}`;
    } else if (location.hostname.includes('hedgeserv.com')) {
        return location.hostname.includes('app-tst') ?
            `${app}.${awsTstRoot}` :
            `${app}.${awsProdRoot}`;
    }

    return 'on-prem';
}

export function getDDVApiHost(): string {
    const app = 'ddv';
    const location = window.location;

    if (location.hostname.includes('hsuat')) {
        return (location.pathname.includes('/ddv-edge/') || location.hostname.includes('poc-rnd')) ?
            `${app}.${awsRndRoot}` :
            `${app}.${awsUatRoot}`;
    } else if (location.hostname.includes('hedgeserv.com')) {
        return location.hostname.includes('app-tst') ?
            `${app}.${awsTstRoot}` :
            `${app}.${awsProdRoot}`;
    }

    return 'on-prem';
}
