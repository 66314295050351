import cloneDeep from 'lodash.clonedeep';
import defaultsDeep from 'lodash.defaultsdeep';

/**
 * Quick and dirty shallow extend
 */
export function clone<A>(a: A): A;
export function clone<A, B>(a: A, b: B): A & B;
export function clone<A, B, C>(a: A, b: B, c: C): A & B & C;
export function clone<A, B, C, D>(a: A, b: B, c: C, d: D): A & B & C & D;
export function clone(...args: unknown[]): unknown {
    return Object.assign({}, ...args);
}

export function deepExtend<T, U>(target: T, sources: U[]): T & U {
    return defaultsDeep(target, ...sources);
}

export function deepClone<T>(source: T): T {
    return cloneDeep(source);
}

export function cloneArray<T>(list: T[]): T[] {
    return !list ? list : [...list];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function completeAssign(target: any[], args: any[]): any {
    if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
    }
    const to = Object(target);
    if (Array.isArray(args)) { // args' type is a lie, this isn't always an array
        for (const arg of args) {
            const nextSource = arg;
            if (nextSource != null) { // Skip over if undefined or null
                if (nextSource instanceof Object) {
                    for (const nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            if (to[nextKey] instanceof Object || to[nextKey] instanceof Array) {
                                completeAssign(to[nextKey], nextSource[nextKey]);
                            } else {
                                to[nextKey] = nextSource[nextKey];
                            }
                        }
                    }
                } else {
                    for (let ind = 0; ind < nextSource.length; ++ind) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (to[ind] instanceof Object || to[ind] instanceof Array) {
                            completeAssign(to[ind], nextSource[ind]);
                        } else {
                            to[ind] = nextSource[ind];
                        }
                    }
                }
            }
        }
    }
    return to;
}
