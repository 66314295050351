<nav #leftNav id="leftNav" class="main-menu">
    <div class="menu-buttons-wrap" [ngClass]="{'no-finder': !showIconsWrapper}">
        <div class="icon-left-right-expand"
            (click)="toggleNavbar()"
            [ngClass]="{'icon-left-expand': isSideNavExpanded, 'icon-right-expand': !isSideNavExpanded}"
            [title]="isSideNavExpanded ? 'Collapse' : 'Expand'">
        </div>

        <div *ngIf="showIconsWrapper" class="finder-wrap">
            <app-find-widget-icon *ngIf="showFindWidgetIcon"
                [readonly]="isGlobalEditPartial && !inPresentationMode"
                (pressed)="findWidgetv2()">
            </app-find-widget-icon>

            <app-find-view-icon (pressed)="findDashboard()"></app-find-view-icon>
        </div>
    </div>

    <ul id="lists-of-dashboards"
            class="list-of-dashboards"
            *ngIf="tiles"
            #dashboardList
            cdkDropList
            [cdkDropListData]="tiles"
            (cdkDropListDropped)="onDragEnd($event)">
        <li id="db_{{tile.selectedLink?.id}}"
                *ngFor="let tile of tiles"
                class="view-{{tile.isGroup ? 'group' : 'single'}}"
                cdkDrag
                cdkDragBoundary=".list-of-dashboards"
                (click)="switchDashboard(tile.selectedLink?.id)">
            <div class="view" [ngClass]="{'active': isSameView(tile), 'unsaved': isSameView(tile) && dirtyFlagService.isDirty}">
                <div class="title" [ngClass]="{'single-view': !tile.isGroup}">
                    {{ isSideNavExpanded ? tile.name : tile.selectedLink?.abbreviation }}
                </div>

                <span class="icon-arrow-right"
                    [ngClass]="{'icon-open': showViewsWrapper === tile.id}"
                    *ngIf="isSideNavExpanded && tile.isGroup"
                    (click)="showGroupedViews($event, tile.id)">
                </span>

                <div class="view-title-selected" *ngIf="tile.isGroup">
                    {{ isSideNavExpanded ? tile.selectedLink?.name : tile.selectedLink?.abbreviation }}
                </div>

                <div class="view-wrapper"
                        *ngIf="showViewsWrapper === tile.id && tile.isGroup"
                        appMousedownOutside
                        (mousedownOutside)="showViewsWrapper = undefined">
                    <ul>
                        <li *ngFor="let link of tile.links; let i = index"
                            (click)="$event.stopPropagation(); switchDashboard(link.id);">
                            {{ link.name }}
                        </li>
                    </ul>
                </div>

                <span class="icon-copy-view"
                        (click)="copyDashboard($event, tile.selectedLink?.id)"
                        *ngIf="copyIsAllowed && isSideNavExpanded && tile.selectedLink?.id === currentView?.id"
                        title="Save a Copy">
                    <svg viewBox="0 0 64 64">
                        <path d="M12,12H42v2h4V12a4,4,0,0,0-4-4H12a4,4,0,0,0-4,4V42a4,4,0,0,0,4,4h2V42H12Zm40,6H22a4,4,0,0,0-4,4V52a4,4,0,0,0,4,4H52a4,4,0,0,0,4-4V22A4,4,0,0,0,52,18Zm0,34H22V22H52Z"/>
                    </svg>
                </span>

                <span class="icon-info-circle"
                        (click)="emitDashboardSnapshot($event, tile.selectedLink)"
                        *ngIf="isSideNavExpanded && tile.selectedLink?.id === currentView?.id"
                        title="View Properties">
                    <svg viewBox="0 0 64 64">
                        <path d="M32,6A26,26,0,1,0,58,32,26,26,0,0,0,32,6Zm0,48A22,22,0,1,1,54,32,22,22,0,0,1,32,54Zm0-23a2.5,2.5,0,0,0-2.5,2.5v11a2.5,2.5,0,0,0,5,0v-11A2.5,2.5,0,0,0,32,31Zm0-11.5A3.5,3.5,0,1,0,35.5,23,3.5,3.5,0,0,0,32,19.5Z"/>
                    </svg>
                </span>
            </div>
        </li>
    </ul>

    <ul class="menu-footer">
        <li (click)="createDashboard()" *ngIf="showCreateViewTab">
            <span class="icon-dashboard">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                    <polygon points="9.456,16.984 7,16.984 7,7.985 18.456,7.985 19.456,6.985 6,6.985 6,17.984
                        8.457,17.984 "/>
                    <polygon points="13.627,20.844 24.041,10.12 21.507,7.586 11.094,18.311"/>
                    <polygon points="10.469,19.102 8.974,22.922 12.85,21.482"/>
                    <path d="M21,6L0,5.984V2.715C0,1.769,0.673,1,1.5,1h18C20.327,1,21,1.769,21,2.715V6z M1,4.985L20,5
                        V2.715C20,2.327,19.771,2,19.5,2h-18C1.229,2,1,2.327,1,2.715V4.985z"/>
                    <path d="M5,17.984H1.723c-0.946,0-1.715-0.672-1.715-1.5L0,6.985h5V17.984z M1,11.643l0.008,0.5v4.342
                        c0,0.271,0.327,0.5,0.715,0.5H4V7.985H1V11.643z"/>
                </svg>
            </span>
            <span *ngIf="isSideNavExpanded">Create View</span>
        </li>

        <li (click)="findDashboard()" *ngIf="showManageViewsTab">
            <span class="icon-add-view">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                    <path fill="#77787C" d="M9.155,16.984H7.012V7.985h13v2.427c0.363,0.276,0.699,0.586,1,0.928V6.985h-15v10.999h3.385
                        C9.291,17.662,9.211,17.328,9.155,16.984z"></path>
                    <path fill="#77787C" d="M23.717,22.059l-3.68-3.68c0.451-0.738,0.727-1.598,0.727-2.525c0-2.688-2.188-4.875-4.877-4.875
                        c-2.688,0-4.874,2.188-4.874,4.875s2.186,4.875,4.874,4.875c1.037,0,1.996-0.33,2.787-0.885l3.629,3.629
                        c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293C24.107,23.082,24.107,22.449,23.717,22.059z M15.887,18.729
                        c-1.584,0-2.873-1.289-2.873-2.875s1.289-2.875,2.873-2.875c1.586,0,2.877,1.289,2.877,2.875S17.473,18.729,15.887,18.729z"></path>
                    <path fill="#77787C" d="M21.012,6l-21-0.017V2.715C0.012,1.769,0.685,1,1.512,1h18c0.828,0,1.5,0.769,1.5,1.715V6z
                            M1.012,4.985l19,0.015V2.715c0-0.388-0.229-0.715-0.5-0.715h-18c-0.271,0-0.5,0.327-0.5,0.715V4.985z"></path>
                    <path fill="#77787C" d="M5.012,17.984H1.735c-0.946,0-1.715-0.672-1.715-1.5L0.012,6.985h5V17.984z M1.012,11.643
                        l0.008,0.5v4.342c0,0.271,0.327,0.5,0.715,0.5h2.277V7.985h-3V11.643z"></path>
                </svg>
            </span>
            <span *ngIf="isSideNavExpanded">Manage Views</span>
        </li>

        <li [routerLink]="['../../../widgets/edit/none']" *ngIf="showManageWidgetTab && inPresentationMode">
            <span class="icon-manage-widget">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                    <polygon fill="#77787C" points="13.211,20.287 23.625,9.563 21.091,7.029 10.678,17.754"/>
                    <polygon fill="#77787C" points="10.053,18.545 8.559,22.365 12.434,20.926"/>
                    <path fill="#77787C" d="M8.68,16.984H1.982C1.441,16.984,1,16.525,1,15.961V3.021
                        C1,2.458,1.441,2,1.982,2H19.02C19.561,2,20,2.458,20,3.021v2.915c0.292-0.207,0.658-0.638,1-0.874V3.021C21,1.907,20.111,1,19.02,1
                        H1.982C0.889,1,0,1.907,0,3.021V15.96c0,1.116,0.889,2.022,1.982,2.022h6.009C8.253,17.46,8.558,17.271,8.68,16.984z"/>
                    <path fill="#77787C" d="M16.395,2.816c-0.666,0-1.205,0.541-1.205,1.207c0,0.248,0.092,0.464,0.22,0.655l-4.356,4.45
                        c-0.211-0.181-0.476-0.301-0.774-0.301c-0.328,0-0.625,0.133-0.842,0.347L7.908,7.787c0.06-0.144,0.094-0.301,0.094-0.467
                        c0-0.666-0.54-1.207-1.207-1.207c-0.666,0-1.206,0.541-1.206,1.207c0,0.128,0.036,0.244,0.072,0.359l-2.08,1.474
                        C3.366,8.956,3.084,8.828,2.77,8.828c-0.666,0-1.207,0.541-1.207,1.207c0,0.668,0.541,1.207,1.207,1.207s1.207-0.539,1.207-1.207
                        c0-0.17-0.037-0.331-0.101-0.478l2.03-1.438c0.221,0.246,0.533,0.406,0.889,0.406c0.319,0,0.604-0.128,0.82-0.329l1.544,1.402
                        C9.105,9.734,9.07,9.881,9.07,10.035c0,0.668,0.541,1.207,1.207,1.207s1.207-0.539,1.207-1.207c0-0.184-0.048-0.354-0.122-0.51
                        l4.404-4.498c0.187,0.116,0.394,0.201,0.629,0.201c0.666,0,1.207-0.537,1.207-1.205C17.602,3.357,17.062,2.816,16.395,2.816z"/>
                </svg>
            </span>
            <span *ngIf="isSideNavExpanded">Manage Widgets</span>
        </li>

        <li id="left-nav-find-widget"
            *ngIf="(!inPresentationMode && tiles.length !== 0)"
            [ngClass]="{'readonly': isGlobalEditPartial}"
            (click)="findWidget()">
            <span class="icon-find-widgets">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                    <path fill="#77787C" d="M8.847,16.967H2.01c-0.542,0-0.982-0.459-0.982-1.023V3.004c0-0.563,0.441-1.021,0.982-1.021
                        h17.037c0.541,0,0.98,0.458,0.98,1.021v6.082c0.354,0.189,0.686,0.411,1,0.655V3.004c0-1.114-0.889-2.021-1.98-2.021H2.01
                        c-1.093,0-1.982,0.907-1.982,2.021v12.939c0,1.116,0.889,2.023,1.982,2.023h7.148C9.031,17.645,8.927,17.311,8.847,16.967z"/>
                    <path fill="#77787C" d="M23.68,21.311L20,17.633c0.451-0.739,0.727-1.6,0.727-2.527c0-2.688-2.188-4.875-4.875-4.875
                        c-2.689,0-4.875,2.187-4.875,4.875s2.186,4.875,4.875,4.875c1.035,0,1.994-0.33,2.783-0.884l3.631,3.628
                        c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293C24.07,22.334,24.07,21.701,23.68,21.311z M15.852,17.98
                        c-1.586,0-2.875-1.29-2.875-2.875s1.29-2.875,2.875-2.875c1.584,0,2.875,1.29,2.875,2.875S17.436,17.98,15.852,17.98z"/>
                    <path fill="#77787C" d="M16.422,2.799c-0.666,0-1.205,0.541-1.205,1.207c0,0.248,0.092,0.464,0.219,0.655l-4.356,4.45
                        c-0.211-0.181-0.476-0.301-0.774-0.301c-0.328,0-0.625,0.133-0.842,0.347L7.936,7.77C7.996,7.626,8.03,7.469,8.03,7.303
                        c0-0.666-0.54-1.207-1.207-1.207c-0.666,0-1.206,0.541-1.206,1.207c0,0.128,0.036,0.244,0.072,0.359l-2.08,1.474
                        C3.394,8.938,3.112,8.811,2.797,8.811c-0.666,0-1.207,0.541-1.207,1.207c0,0.668,0.541,1.207,1.207,1.207s1.207-0.539,1.207-1.207
                        c0-0.17-0.037-0.331-0.101-0.478l2.03-1.438c0.221,0.246,0.533,0.406,0.889,0.406c0.319,0,0.604-0.128,0.82-0.329l1.544,1.402
                        c-0.053,0.136-0.088,0.282-0.088,0.437c0,0.668,0.541,1.207,1.207,1.207s1.207-0.539,1.207-1.207c0-0.184-0.048-0.354-0.122-0.51
                        l4.405-4.498c0.186,0.116,0.393,0.201,0.627,0.201c0.668,0,1.207-0.537,1.207-1.205C17.629,3.34,17.09,2.799,16.422,2.799z"/>
                </svg>
            </span>

            <span *ngIf="isSideNavExpanded">Find Widgets</span>
        </li>
    </ul>
</nav>
