// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sliceOthers<T>(datasource: any[], groupByValue: number): { newData: T[], others: T[] } {
    const sourceCopy = [...datasource];
    sourceCopy.sort((a, b) => Math.abs(b.percentage) - Math.abs(a.percentage)).splice(groupByValue - 1, datasource.length);
    const newData = datasource.filter((d) => sourceCopy.find((cd) => d.id === cd.id));
    const others = datasource.filter((d) => !sourceCopy.find((cd) => d.id === cd.id));
    return { newData, others };
}

export function hasOwnProperty(obj: unknown, property: string): boolean {
    return Object.prototype.hasOwnProperty.call(obj, property);
}
