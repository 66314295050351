import { Injectable } from '@angular/core';
import { DashboardPreference, DdvDate, DashboardClientQueryParam, FuzzyDates, AppWidgetState } from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { getDate, getUTCDate, isStringValidISODate, deepCompare, deepClone } from '@ddv/utils';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { QueryParamsService } from './query-params.service';

interface Item {
    [key: string]: string | number | boolean;
}

const predicateMapping: { [name: string]: (item: Item, property: string, values: (string | number | boolean)[]) => boolean } = {
    INCLUDE: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.some((value) => value.toString() === item[property]?.toString());
    },
    EXCLUDE: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.every((value) => value.toString() !== item[property]?.toString());
    },
    EQ: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.some((value) => value.toString() === item[property]?.toString());
    },
    NEQ: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.some((value) => value.toString() !== item[property]?.toString());
    },
    GT: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.some((value) => {
            if (isNaN(value as number) && isNaN(item[property] as number)) {
                return Number(getTimezoneAgnosticDate(value as string)) - Number(getTimezoneAgnosticDate(item[property] as string)) < 0;
            }
            return Number(value) - Number(item[property]) < 0;
        });
    },
    GTE: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.some((value) => {
            if (isNaN(value as number) && isNaN(item[property] as number)) {
                return Number(getTimezoneAgnosticDate(value as string)) - Number(getTimezoneAgnosticDate(item[property] as string)) <= 0;
            }
            return Number(value) - Number(item[property]) <= 0;
        });
    },
    LT: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.some((value) => {
            if (isNaN(value as number) && isNaN(item[property] as number)) {
                return Number(getTimezoneAgnosticDate(value as string)) - Number(getTimezoneAgnosticDate(item[property] as string)) > 0;
            }
            return Number(value) - Number(item[property]) > 0;
        });
    },
    LTE: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        return values.some((value) => {
            if (isNaN(value as number) && isNaN(item[property] as number)) {
                return Number(getTimezoneAgnosticDate(value as string)) - Number(getTimezoneAgnosticDate(item[property] as string)) >= 0;
            }
            return Number(value) - Number(item[property]) >= 0;
        });
    },
    BETWEEN: (item: Item, property: string, values: (string | number | boolean)[]): boolean => {
        const openingDate = getTimezoneAgnosticDate(values[0] as string);
        const closingDate = getTimezoneAgnosticDate(values[1] as string);
        const currentDate = getTimezoneAgnosticDate(item[property] as string);
        return Number(openingDate) - Number(currentDate) <= 0 && Number(closingDate) - Number(currentDate) >= 0;
    },
};

@Injectable()
export class ClientDatasetFilterService {
    private dashboardFilterParams: DashboardClientQueryParam = {};
    private readonly widgetFilterParams: Map<number, DashboardClientQueryParam> = new Map();
    private readonly clientFilterObs: BehaviorSubject<DashboardClientQueryParam | undefined> =
        new BehaviorSubject<DashboardClientQueryParam | undefined>(undefined);
    private readonly widgetsFilterObs: Map<number, BehaviorSubject<DashboardClientQueryParam>> = new Map();
    private dashboardFilterSubscription: Subscription | undefined;
    private widgetFilterSubscription: Subscription | undefined;
    private fuzzyDates: FuzzyDates | undefined;

    constructor(
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly queryParamsService: QueryParamsService,
    ) {
        this.fuzzyDatesService.fuzzyDates().subscribe((fuzzyDates: FuzzyDates) => {
            this.fuzzyDates = fuzzyDates;
        });
        this.clientFilterObs.next(this.dashboardFilterParams);
    }

    fetchFilterParams(widgetPrefs: AppWidgetState): Observable<DashboardClientQueryParam | undefined> {
        if (widgetPrefs.isSubscribedToDashboardFilters) {
            this.updateDashboardFilterParams();
            return this.clientFilterObs.asObservable();
        }

        let widgetFilterObs: BehaviorSubject<DashboardClientQueryParam> | undefined = this.widgetsFilterObs.get(widgetPrefs.id ?? 0);
        if (!widgetFilterObs) {
            widgetFilterObs = new BehaviorSubject({});
            this.widgetsFilterObs.set(widgetPrefs.id ?? 0, widgetFilterObs);
        }

        this.updateWidgetFilterParams();

        return widgetFilterObs;
    }

    updateDashboardFilterParams(): void {
        if (this.dashboardFilterSubscription) {
            this.dashboardFilterSubscription.unsubscribe();
        }

        this.dashboardFilterSubscription = this.queryParamsService.dashboardQueryParams.subscribe((dashboardPref: DashboardPreference) => {
            const currentFilterParams: DashboardClientQueryParam = {
                filters: deepClone(dashboardPref.filters),
                activeDate: dashboardPref.activeDate,
                startDate: dashboardPref.startDate,
                endDate: dashboardPref.endDate,
                isDateChanged: false,
                comparing: dashboardPref.comparing,
            };
            this.dashboardFilterParams.isDateChanged = false;
            if (!deepCompare(this.dashboardFilterParams, currentFilterParams)) {
                currentFilterParams.isDateChanged =
                    this.dashboardFilterParams.startDate !== dashboardPref.startDate ||
                    this.dashboardFilterParams.endDate !== dashboardPref.endDate;
                this.clientFilterObs.next(currentFilterParams);
                this.dashboardFilterParams = currentFilterParams;
            }
        });
    }

    updateWidgetFilterParams(): void {
        if (this.widgetFilterSubscription) {
            this.widgetFilterSubscription.unsubscribe();
        }
        this.widgetFilterSubscription = this.queryParamsService.widgetQueryParams.subscribe({
            next: (params) => {
                if (params?.widgetFilters.size) {
                    const widgetId = params.lastChangedWidgetId;
                    if (!widgetId) {
                        return;
                    }

                    const widgetFilterObs: BehaviorSubject<DashboardClientQueryParam> | undefined = this.widgetsFilterObs.get(widgetId);
                    if (!widgetFilterObs) {
                        return;
                    }
                    const widgetQueryParam = params.widgetFilters.get(widgetId);
                    const prevWidgetQueryParam = this.widgetFilterParams.get(widgetId);
                    let isDateChanged = true;
                    if (prevWidgetQueryParam) {
                        isDateChanged = prevWidgetQueryParam.startDate !== widgetQueryParam?.startDate
                            || prevWidgetQueryParam.endDate !== widgetQueryParam?.endDate;
                    }
                    const currentFilterParams: DashboardClientQueryParam = {
                        filters: deepClone(widgetQueryParam?.filters),
                        activeDate: widgetQueryParam?.activeDate,
                        startDate: widgetQueryParam?.startDate,
                        endDate: widgetQueryParam?.endDate,
                        isDateChanged,
                        isComparing: widgetQueryParam?.isComparing,
                        comparing: widgetQueryParam?.comparing,
                        compareDates: widgetQueryParam?.compareDates,
                    };
                    if (!deepCompare(prevWidgetQueryParam, currentFilterParams)) {
                        widgetFilterObs.next(currentFilterParams);
                        this.widgetFilterParams.set(widgetId, currentFilterParams);
                    }
                }
            },
        });
    }

    filterData<T extends { date?: string }>(data: T[] = [], filterParam: DashboardClientQueryParam): T[] {
        let filteredData = data ?? [];
        if (filterParam.activeDate) {
            let activeDate: Date | undefined;

            if (DdvDate.isStringValidDate(filterParam.activeDate)) {
                activeDate = getUTCDate(filterParam.activeDate);
            } else {
                const fuzzyDate = this.fuzzyDates?.findToDate(filterParam.activeDate);
                activeDate = fuzzyDate?.actualDate;
            }

            if (!activeDate) {
                return [];
            }

            if (data?.length) {
                const isISOFormat = isStringValidISODate(data[0].date ?? '');
                filteredData = data.filter((d) => {
                    const date = getDate(d.date?.split('T')?.[0] ?? '', isISOFormat);
                    return date.getFullYear() === activeDate?.getFullYear() &&
                        date.getDate() === activeDate.getDate() &&
                        date.getMonth() === activeDate.getMonth();
                });
            }
        }

        if (filteredData.length && filterParam.filters?.length) {
            const availableFilter = filterParam.filters.filter((filterItem) => filterItem.values?.length);
            filteredData = availableFilter.reduce((result, filter) => {
                return result.filter((item) => {
                    const predicate = predicateMapping[filter.criteria ?? 'INCLUDE'];
                    return predicate(item, filter.name ?? '', filter.values ?? []);
                });
            }, filteredData);
        }

        return filteredData;
    }

    removeDuplicateRows<T>(sourceData: T[], columnsToConsider: string[] = []): T[] {
        const filtered: T[] = [];
        const stringifieds: string[] = [];

        sourceData.forEach((row) => {
            const stringified = this.stringifyValuesFromRow(row, columnsToConsider);
            if (!stringifieds.includes(stringified)) {
                stringifieds.push(stringified);
                filtered.push(row);
            }
        });

        return filtered;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private stringifyValuesFromRow(row: any, columnsToConsider: string[]): string {
        return columnsToConsider.reduce((value, property) => {
            return `${value}${property}=${row[property]}`;
        }, '');
    }
}

function getTimezoneAgnosticDate(value: string): Date {
    const date = new Date(value);
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}
