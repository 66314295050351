<div *ngIf="selectedMenuIcon === 'visBtn' && menuItems.length > 1" class="visualization dropdown-wrapper">
    <div class="dropdown visBtnMenu">
        <ul>
            <li *ngFor="let item of menuItems" [class.hide]="selectedItem.cssClass === item.cssClass">
                <div (click)="onVisChanged(item, true)">
                    <span *ngIf="item.cssClass !== 'icon-stacked-area-chart'" [ngClass]="item.cssClass"></span>

                    <ddv-stacked-area-chart-icon
                        *ngIf="item.cssClass === 'icon-stacked-area-chart'"
                        [ngClass]="item.cssClass">
                    </ddv-stacked-area-chart-icon>

                    <label class="{{item.label}}">{{item.label}}</label>
                </div>
                <i
                    *ngIf="(item.label === 'Advanced Grid' || item.label === 'Simple Grid') && !isVizMaximized"
                    class="fa fa-expand icon-bigw"
                    (click)="onVisChangedBigW(item)">
                </i>
            </li>
        </ul>
    </div>
</div>
