import { Inject, Injectable } from '@angular/core';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { WidgetSettingsOverrides } from '@ddv/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WidgetSettingsOverridesService {
    constructor(@Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService) {}

    getWidgetSettingsOverrides(clientCode: string, id: number): Observable<WidgetSettingsOverrides> {
        return this.ddvApiService.invokeServiceWithParams<Partial<WidgetSettingsOverrides>>(clientCode, `dashboards/widgets/settings/${id}`)
            .pipe(map((jsonData) => new WidgetSettingsOverrides(jsonData)));
    }

    saveWidgetSettingsOverrides(clientCode: string, id: number, overrides: WidgetSettingsOverrides): Observable<void> {
        return this.ddvApiService.invokeServiceWithBody(clientCode, `dashboards/widgets/settings/${id}`, 'POST', overrides);
    }
}
