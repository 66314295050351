import {
    Component,
    ComponentRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ManagerService, WorkspaceSelection } from '@ddv/layout';
import { WIDGET_LIFECYCLE_EVENT, Widget } from '@ddv/models';
import { Subscription } from 'rxjs';

import { WorkspaceComponent } from '../workspace/workspace.component';

@Component({
    selector: 'app-dashboard',
    template: '<div class="workspace-container" #workspaceContainer></div>',
})
export class DashBoardComponent implements OnInit, OnChanges, OnDestroy {
    private static workspaceComponentRefs: { [selector: string]: ComponentRef<WorkspaceComponent> } = {};

    @Input() isManagingWidget = false;
    @Input() isGlobal = false;
    @Input() workspaceComponentClass: typeof WorkspaceComponent = WorkspaceComponent;

    private workspaceSelectionObserver: Subscription | undefined;
    private currentWorkspaceWidgetsSubscription: Subscription | undefined;
    private clientCode: string | undefined;
    @ViewChild('workspaceContainer', { read: ViewContainerRef, static: true }) private readonly container: ViewContainerRef | undefined;
    private currentWorkspaceComponent: WorkspaceComponent | undefined;
    private clientCodeSubscription: Subscription | undefined;

    constructor(
        private readonly manager: ManagerService,
        private readonly currentStateService: CurrentStateService,
    ) {}

    ngOnInit(): void {
        this.workspaceSelectionObserver = this.manager.workspaceSelection
            .subscribe({
                next: (workspaceSelection: WorkspaceSelection) => {
                    if (!workspaceSelection) {
                        return;
                    }

                    const fromWSId = workspaceSelection.previousWorkspace?.id;
                    if (workspaceSelection.currentWorkspace) {
                        const toWSId = workspaceSelection.currentWorkspace.id;
                        this.renderWorkspace(fromWSId, toWSId);
                    } else if (fromWSId) {
                        const fromWorkspaceComponentRef = DashBoardComponent.workspaceComponentRefs[fromWSId];
                        if (fromWorkspaceComponentRef) {
                            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                            delete DashBoardComponent.workspaceComponentRefs[fromWSId];
                            this.container?.remove();
                        }
                    }
                },
            });

        this.currentWorkspaceWidgetsSubscription = this.manager.currentWorkspaceWidgets
            .subscribe({
                next: (widgets: Widget[]) => this.updateWorkspaceWidgets(widgets),
            });

        this.clientCodeSubscription = this.currentStateService.clientCode$
            .subscribe({
                next: (clientCode) => this.clientCode = clientCode,
            });
    }

    ngOnChanges(): void {
        this.updateWorkspaceIsGlobalProperty();
    }

    ngOnDestroy(): void {
        this.workspaceSelectionObserver?.unsubscribe();
        this.currentWorkspaceWidgetsSubscription?.unsubscribe();
        this.clientCodeSubscription?.unsubscribe();
        DashBoardComponent.workspaceComponentRefs = {};
    }

    private renderWorkspace(fromWorkspaceId: string | undefined, toWorkspaceId: string): void {
        if (fromWorkspaceId) {
            const fromWorkspaceComponent = DashBoardComponent.workspaceComponentRefs[fromWorkspaceId]?.instance;
            if (fromWorkspaceComponent) {
                this.triggerLifeCycleEventForWidgets(WIDGET_LIFECYCLE_EVENT.WIDGET_PASSIVE_MODE, fromWorkspaceComponent.widgets);
                this.container?.detach();
            }
        }

        const toWorkspaceComponentRef = DashBoardComponent.workspaceComponentRefs[toWorkspaceId];
        if (!toWorkspaceComponentRef) {
            if (this.container) {
                this.container.detach();
                DashBoardComponent.workspaceComponentRefs[toWorkspaceId] = this.createWorkspace(this.container);
            }
        } else {
            this.container?.insert(toWorkspaceComponentRef.hostView);
            this.currentWorkspaceComponent = DashBoardComponent.workspaceComponentRefs[toWorkspaceId].instance;
            this.triggerLifeCycleEventForWidgets(WIDGET_LIFECYCLE_EVENT.WIDGET_ACTIVE_MODE, this.currentWorkspaceComponent?.widgets);
        }
    }

    private createWorkspace(container: ViewContainerRef, widgets: Widget[] = []): ComponentRef<WorkspaceComponent> {
        const componentRef = container.createComponent(this.workspaceComponentClass);
        const component = componentRef.instance;
        component.isManagingWidget = this.isManagingWidget;
        component.clientCode = this.clientCode;
        this.currentWorkspaceComponent = component;

        this.updateWorkspaceWidgets(widgets);
        this.updateWorkspaceIsGlobalProperty();

        return componentRef;
    }

    private updateWorkspaceWidgets(widgets: Widget[]): void {
        if (this.currentWorkspaceComponent) {
            this.currentWorkspaceComponent.widgets = widgets;
            // this is necessary because dynamic components do not get change detection
            this.currentWorkspaceComponent.onWidgetsUpdated();
        }
    }

    private updateWorkspaceIsGlobalProperty(): void {
        if (this.currentWorkspaceComponent) {
            this.currentWorkspaceComponent.isGlobal = this.isGlobal;
        }
    }

    resetCurrentWorkspace(): void {
        if (!this.currentWorkspaceComponent) {
            return;
        }

        this.currentWorkspaceComponent.prepareToReinitializeWidgets();
    }

    private triggerLifeCycleEventForWidgets(eventName: string, widgets: Widget[] | undefined): void {
        if (!widgets) {
            return;
        }

        widgets
            .filter((w) => !!w.lifeCycleCallBack)
            .forEach((w) => w.lifeCycleCallBack?.(eventName));
    }
}
