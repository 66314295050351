<div *ngIf="parentData && summaryConfig" class="column-formatting-form">
    <div class="section column-name">
        <div class="subsection">
            <div class="title">Column Name</div>

            <div class="column-label">{{ parentData.columnName }}</div>
        </div>

        <div class="subsection">
            <div class="title">Summary Label</div>

            <input
                type="text"
                class="formControl"
                autocomplete="off"
                name="label"
                maxlength="30"
                [(ngModel)]="summaryConfig.label" />
        </div>
    </div>


    <div class="section general-formatting">
        <p class="title">General Formatting</p>

        <div class="section empty general-formatting-child">
            <div class="subsection">
                <p class="title">Aggregation Type</p>

                <div class="custom-select">
                    <app-dropdown
                        name="aggregationtype"
                        [options]="aggTypes"
                        [(selected)]="selectedAggType"
                        (selectedChange)="onAggregationTypeChange($event.value)">
                    </app-dropdown>
                </div>
            </div>

            <div class="subsection" *ngIf="parentData.displayType === 'value' && showFormatter">
                <p class="title">Format</p>

                <div class="custom-select">
                    <app-dropdown
                        name="format"
                        [options]="formats"
                        [(selected)]="selectedFormat"
                        (selectedChange)="onSelectedFormatChange($event.value)">
                    </app-dropdown>
                </div>
            </div>

            <div class=" section empty general-formatting-child" *ngIf="parentData.displayType === 'value'">
                <div class="subsection">
                    <p class="title">Negative Values</p>

                    <div class="custom-select">
                        <app-dropdown
                            class="{{summaryConfig.negativeValue}}"
                            name="negative"
                            [options]="negativeValues"
                            [(selected)]="selectedNegativeValue"
                            (selectedChange)="onSelectedNegativeFormatChange($event.value)">
                        </app-dropdown>
                    </div>
                </div>

                <div class="subsection" *ngIf="parentData.displayType === 'value'">
                    <p class="title">Decimal Places</p>

                    <input
                        appInputRestrict
                        type="number"
                        min="0"
                        name="decimal"
                        [(ngModel)]="summaryConfig.decimalPlaces"
                        allowPattern="^(\d|1\d|20)$" />
                </div>
            </div>

            <div class="section empty general-formatting-child" *ngIf="parentData.displayType === 'value'">
                <div class="subsection">
                    <p class="title">Number Units</p>

                    <div class="custom-select">
                        <app-dropdown
                            name="numberUnits"
                            [options]="numberUnits"
                            [(selected)]="selectedNumberUnits"
                            (selectedChange)="onSelectedNumberUnitsChange($event.value)">
                        </app-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="save-cancel">
        <button
                class="empty-btn"
                type="button"
                (click)="modalDialogActive.close('cancel')">
            Cancel
        </button>

        <button
                class="fill-btn"
                type="button"
                [disabled]="summaryConfig.decimalPlaces === null"
                (click)="modalDialogActive.close(summaryConfig)">
            Apply
        </button>
    </div>
</div>
