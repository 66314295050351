import { Component, OnInit, ViewChild } from '@angular/core';
import { Axis, ChartSettings, ColorMetadataService, getDefaultMargin, Margin, Series } from '@ddv/charts';
import { MetadataService } from '@ddv/datasets';
import {
    DataUpdateBody,
    ExportFilteredData,
    FuzzyDate,
    FuzzyDates,
    IAxis,
    ConfigItem,
    IMarginCondition,
    LegendConfig,
    LegendConfigurationService,
    VizConfigs,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    WIDGET_LIFECYCLE_EVENT,
    SliceManagement,
    DataUpdateStackedArea,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { clone, deepClone, getDefault } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

import { BaseVisualizationComponent } from '../../../base/base-visualization.component';
import { ChartsSharedService } from '../../../base/visualization-wrapper/charts-shared.service';
import { VisualizationWrapperComponent } from '../../../base/visualization-wrapper/visualization-wrapper.component';

@Component({
    selector: 'ddv-stacked-area-chart-visualization',
    templateUrl: './stacked-area-chart-visualization.component.html',
    styleUrls: ['./stacked-area-chart-visualization.component.scss'],
    providers: [LegendConfigurationService],
})
export class StackedAreaChartVisualizationComponent extends BaseVisualizationComponent implements OnInit {
    chartConfiguration: ChartSettings | undefined;
    zoom: { label: string }[] = [];
    zoomValue = '';

    @ViewChild(VisualizationWrapperComponent, { static: true }) private readonly vizWrapperComponent?: VisualizationWrapperComponent;

    private configs: VizConfigs| undefined;
    private fuzzyDates: FuzzyDates| undefined;
    private legendConfig: LegendConfig| undefined;
    private margin: Margin = getDefaultMargin();
    private filterBy: Partial<ConfigItem>| undefined;
    private stackedAreaSeries: Series| undefined;
    private xDomain: (Date | undefined)[] = [];
    private xAxis: Axis[] = [];
    private yAxis: Axis[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private tooltipData: any[] = [];
    private hasSlicer = false;

    constructor(
        metadataService: MetadataService,
        private readonly chartsSharedService: ChartsSharedService,
        private readonly colorMetadataService: ColorMetadataService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        private readonly legendConfigurationService: LegendConfigurationService,
        private readonly themeService: ThemeService,
    ) {
        super(metadataService);
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.isChartInitialized = true;
        this.configs = this.preferences?.configs;
        this.vizInfo = this.vizInfo ?? { slicer: { label: '', value: '' }, values: [] };
        this.legendConfig = this.legendConfigurationService.getConfigFromUserPreference(this.preferences);
        if (this.preferences?.showBrush && this.preferences.zoomLevels?.length) {
            this.zoom.push(...this.preferences.zoomLevels.map((zoomLevel) => ({ label: zoomLevel.value })));
        }
        this.zoomValue = '';

        this.subscribeTo(this.fuzzyDatesService.fuzzyDates(), (fuzzyDates: FuzzyDates) => this.fuzzyDates = fuzzyDates);

        this.subscribeTo(this.themeService.currentTheme$, (theme: Theme) => {
            this.theme = theme;
            this.setVizSlicer();
            this.initializeStackedAreaChart();
        });
    }

    getExportFilteredData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFilteredData();
    }

    getExportFullData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFullData();
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMetadataUpdate(): void {}

    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                this.setXDomain((data as DataUpdateBody).filters?.startDate, (data as DataUpdateBody).filters?.endDate);
                this.updateDataSource(data as DataUpdateBody);
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = false;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.LOADING_DATA:
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = true;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.VISUALIZATION_SELECTED:
                this.tooltipData = [];
                this.updateChartSelection(data as DataUpdateStackedArea);
        }
    }

    widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, _: unknown): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.INIT_WIDGET:
            case WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE:
                this.vizWrapperComponent?.resizeVisualization();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE:
                this.vizWrapperComponent?.onMaximize();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE:
                this.vizWrapperComponent?.onRestore();
        }
    }

    updateDataSource(componentData: DataUpdateBody): void {
        const data = deepClone(componentData.data);
        this.tooltipData = [];

        if (data.length) {
            this.vizData = this.getVizData(data);
        } else {
            this.vizData = [];
        }

        if (this.isChartInitialized) {
            this.zoomValue = '';
            const dataSource = this.vizData.length ? this.getChartConfigurationDataSource() : [];
            if (this.xDomain.length && this.chartConfiguration) {
                this.chartConfiguration.axis[0][0].domain = this.xDomain;
            }
            this.chartConfiguration = clone(this.chartConfiguration, { dataSource });
        }

        this.adjustDomainForOutsideData();
    }

    onFilterChanged(item: Partial<ConfigItem>): void {
        this.zoomValue = '';
        this.tooltipData = [];
        this.filterBy = item;
        this.initializeStackedAreaChart();
    }

    override onVizInfoChanged(slicer: Partial<ConfigItem>): void {
        if (this.vizInfo && this.vizInfo.slicer.value !== slicer.value) {
            this.vizInfo.slicer = slicer;
        }
    }

    onRangeClicked(value: { label: string }): void {
        this.zoomValue = value.label;
        switch (this.zoomValue) {
            case '1D':
            case '1M':
            case '3M':
            case '6M':
                this.vizWrapperComponent?.resizeBrush({ notation: this.zoomValue[1], scale: Number(this.zoomValue[0]) });
                break;
            case '12M':
                this.vizWrapperComponent?.resizeBrush({ notation: this.zoomValue[2], scale: Number(`${this.zoomValue[0]}${this.zoomValue[1]}`) });
                break;
            case 'YTD':
            case 'ALL':
                this.vizWrapperComponent?.resizeBrush({ notation: this.zoomValue });
        }
    }

    private updateChartSelection(data: DataUpdateStackedArea): void {
        if (this.isChartInitialized) {
            const selected = data.selectedItem ? data.selectedItem.data.key : null;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const hiddenLegends: any[] = selected && data.dataSource ? data.dataSource.reduce((arr: any[], source) => {
                if (source.key !== selected) {
                    arr.push(source.key);
                }
                return arr;
            }, []) : [];

            this.chartConfiguration = clone(this.chartConfiguration, {
                highlight: { data: data.selectedItem },
                hiddenLegends,
            });
        }
    }

    private setXDomain(startDate: string | undefined, endDate: string | undefined): void {
        if (this.fuzzyDates && startDate && endDate) {
            this.xDomain = [this.getDate(startDate, this.fuzzyDates.from), this.getDate(endDate, this.fuzzyDates.to)];
        }
    }

    private setVizSlicer(): void {
        const slicer = this.vizInfo?.slicer;
        if (slicer?.label) {
            this.setSlicerAttributes(slicer);
            this.hasSlicer = true;
            this.filterBy = slicer;
            this.vizSlicer.next(slicer);
        } else {
            const slicers = this.configs?.slicers ?? [];
            this.hasSlicer = slicers.length !== 0;
            this.filterBy = getDefault(slicers, { colId: '', label: '', value: '' } as ConfigItem);
            this.vizSlicer.next({
                label: this.filterBy?.label,
                value: this.filterBy?.value,
                showCustomName: this.filterBy?.showCustomName,
                customName: this.filterBy?.customName,
                colorSortBy: this.filterBy?.colorSortBy,
                colorSortDirection: this.filterBy?.colorSortDirection,
            });
        }
    }

    private setSlicerAttributes(slicer: Partial<ConfigItem>): void {
        this.configs?.slicers.forEach((s) => {
            if (this.isDifferentSlicerName(slicer,s)) {
                slicer.showCustomName = s.showCustomName;
                slicer.customName = s.customName;
                slicer.colorSortBy = s.colorSortBy;
                slicer.colorSortDirection = s.colorSortDirection;
            }
        });
    }

    private isDifferentSlicerName(slicer: Partial<ConfigItem>, configSlicer: ConfigItem): boolean {
        return slicer.value === configSlicer.value &&
            (slicer.customName !== configSlicer.customName || slicer.showCustomName !== configSlicer.showCustomName);
    }

    private initializeStackedAreaChart(): void {
        if (!this.preferences) {
            return console.error('cannot initializeStackedAreaChart without preferences');
        }
        const yAxis = this.preferences.axisConfigurations[1];
        const marginCondition = this.getMarginCondition(yAxis.position, yAxis.displayAxisLabels);
        const bottomMargin = this.getBottomMargin(marginCondition.legendPosition);
        this.margin = this.getMargin(marginCondition, bottomMargin);
        this.updateStackedAreaChartConfig();
        this.configureStackedAreaChart();
    }

    private updateStackedAreaChartConfig(): void {
        if (!this.preferences || !this.configs) {
            return console.error('cannot initializeStackedAreaChart without preferences and configs');
        }
        const xField = this.configs.axisTimes?.[0].value ?? '';
        const yField = this.configs.values[0].value ?? '';
        const axisConfig = this.preferences.axisConfigurations;

        this.xAxis = [this.getXAxis(xField, axisConfig[0])];
        if (this.xDomain.length) {
            this.xAxis[0].domain = this.xDomain;
        }

        this.yAxis = [this.getYAxis(yField, axisConfig[1])];

        this.stackedAreaSeries = {
            type: 'stacked-area',
            stacked: true,
            xField: [xField],
            yField: [yField],
            tooltipHTML: ({ tooltipValue, tooltipColors }): string => this.getTooltip(tooltipValue, tooltipColors),
        };

        this.vizInfo = {
            slicer: { value: this.filterBy?.value, label: this.filterBy?.label },
            values: [{ value: this.yAxis[0]?.field[0] || '', label: '' }],
        };
    }

    private configureStackedAreaChart(): void {
        const baseChartConfigation = this.getBaseChartConfiguration();
        this.chartConfiguration = this.getChartConfiguration(baseChartConfigation);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getVizData(data: any[]): any[] {
        const usedTimeColumnName = this.preferences?.configs?.axisTimes?.[0].value ?? '';
        data.forEach((d) => d[usedTimeColumnName] = this.chartsSharedService.deserializeDate(d[usedTimeColumnName]));
        return this.chartsSharedService.sortDataOnDateValue(data, usedTimeColumnName);
    }

    private getMarginCondition(yAxisPosition: string, enableAxisLabel: boolean): IMarginCondition {
        const legendPosition = this.preferences?.enableLegend ? this.preferences.legendPosition : undefined;
        const enableYAxis = true;
        return this.chartsSharedService.getMarginCondition(legendPosition, yAxisPosition, enableYAxis, enableAxisLabel);
    }

    private getBottomMargin(legendPosition: string): number {
        const baseBottomMargin = legendPosition === 'bottom' ? 30 : 0;

        if (this.isBottomAxisOrientationVertical() && this.preferences?.showBrush) {
            return baseBottomMargin + 95;
        }
        if (this.isBottomAxisOrientationVertical()) {
            return baseBottomMargin + 65;
        }
        if (this.preferences?.showBrush) {
            return baseBottomMargin + 70;
        }
        if (legendPosition !== 'bottom') {
            return baseBottomMargin + 25;
        }
        return baseBottomMargin;
    }

    private getMargin(marginCondition: IMarginCondition, bottomMargin: number): Margin {
        const top = marginCondition.legendPosition !== 'top' ? 15 : 20;
        const right = 15;
        const bottom = bottomMargin;
        const left = marginCondition.leftSideAxisFlag ? 5 : 25;
        return { top, right, bottom, left };
    }

    private getXAxis(xField: string, axisConfig: IAxis): Axis {
        return {
            type: 'date',
            position: axisConfig.position,
            field: [xField],
            nTicks: 10,
            rotate: axisConfig.orientation === 'horizontal' ? 0 : 270,
            customClass: this.getCustomClass(axisConfig),
            domain: [],
        };
    }

    private getYAxis(yField: string, axisConfig: IAxis): Axis {
        return {
            type: 'numeric',
            position: axisConfig.position,
            field: [yField],
            hide: false,
            nTicks: 10,
            autoTicks: true,
            customClass: this.getCustomClass(axisConfig),
            domain: [],
        };
    }

    private getTooltip(tooltipValue: { [key: string]: Date }, tooltipColors: Map<string, string>): string {
        if (!this.tooltipData.length) {
            this.tooltipData = this.getTooltipDataSource();
        }
        return this.getStackedAreaTooltip(tooltipValue, tooltipColors);
    }

    private getCustomClass(axisConfig: IAxis): string {
        if (axisConfig.displayAxisLabels) {
            return axisConfig.enableAxis ? '' : 'show-only-tick-labels';
        }
        return 'hide';
    }

    private getBaseChartConfiguration(): ChartSettings {
        if (!this.stackedAreaSeries) {
            throw new Error('cannot getBaseChartConfiguration without a stackedAreaSeries');
        }
        return this.chartsSharedService.getBaseChartModel([this.stackedAreaSeries], [this.xAxis, this.yAxis], this.vizData);
    }

    private getChartConfiguration(baseChartConfiguration: ChartSettings): ChartSettings {
        if (!this.preferences) {
            throw new Error('cannot getChartConfiguration with a preferences');
        }

        baseChartConfiguration.parentSelector = `ddv-stacked-area-chart_${this.widgetId}`;
        baseChartConfiguration.margin = this.margin;
        baseChartConfiguration.minHeight = 200;

        baseChartConfiguration.legend = this.legendConfig;
        baseChartConfiguration.multiSeries = this.hasSlicer ? { field: this.filterBy?.value ?? '' } : undefined;
        baseChartConfiguration.showMultipleSeries = this.hasSlicer;

        baseChartConfiguration.showTooltip = this.preferences.enableTooltip;
        baseChartConfiguration.highlightXValueOnHover = this.preferences.showHoverLines;
        baseChartConfiguration.highlightYValueOnHover = this.preferences.showHoverLines;
        baseChartConfiguration.enableDynamicAxisLabelWidth = true;

        baseChartConfiguration.showCurveValues = this.preferences.inChartLabel;
        baseChartConfiguration.showBrush = this.preferences.showBrush;
        baseChartConfiguration.brushSize = 4;

        baseChartConfiguration.showInflectionPoints = true;
        baseChartConfiguration.inflectionPointPercent = 25;

        baseChartConfiguration.showGridLines = this.preferences.gridLineStyle !== 'none';
        baseChartConfiguration.gridLines = {
            x: { hide: true },
            y: { hide: false, showLabels: false, nTicks: 10, customClass: this.preferences.gridLineStyle },
        };

        baseChartConfiguration.selectedSlicer = this.filterBy;

        baseChartConfiguration.colorRange = this.getColorRange();
        baseChartConfiguration.attributeCustomColors = this.getAttributeCustomColors();

        baseChartConfiguration.dataSource = [];
        if (this.vizData?.length) {
            baseChartConfiguration.dataSource = this.getChartConfigurationDataSource();
        }

        if (this.preferences.numberFormat || (this.preferences.decimalPlaces ?? -1) >= 0) {
            const { numberFormat, numberUnits, decimalPlaces } = this.preferences;
            baseChartConfiguration.formatter = { isNumberFormatted: true, numberFormat, numberUnits, decimalPlaces };
        }

        if (this.preferences.enableSliceManagement) {
            const { enableSliceManagement, sliceManagementType, groupByPercent, groupByMaxCount } = this.preferences;
            baseChartConfiguration.enableSliceManagement = enableSliceManagement;
            baseChartConfiguration.groupByType = sliceManagementType;
            baseChartConfiguration.groupByValue = sliceManagementType === SliceManagement.PERCENTAGE ? groupByPercent : groupByMaxCount;
        }

        return baseChartConfiguration;
    }

    private getColorRange(): string[] {
        if (!this.preferences) {
            return [];
        }

        return this.chartsSharedService.getColorRange(this.preferences, this.colorMetadataService, this.filterBy?.value ?? '', this.theme);
    }

    private getAttributeCustomColors(): { [key: string]: string }[] {
        if (!this.preferences) {
            return [];
        }
        return this.chartsSharedService.getAttributeCustomColors(this.preferences, this.filterBy?.value ?? '', this.theme);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getChartConfigurationDataSource(): any[] {
        if (!this.stackedAreaSeries) {
            return [];
        }

        const value = this.filterBy?.value;
        const values = this.preferences?.configs?.values;
        const enableSliceManagement = this.preferences?.enableSliceManagement;

        const aggregatedData = this.chartsSharedService.getLineAndAreaDataSource(this.vizData, value ?? '', this.stackedAreaSeries, values ?? []);

        if (enableSliceManagement) {
            return this.getAreaManagementDataSource(aggregatedData, value ?? '');
        }
        return aggregatedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getTooltipDataSource(): any[] {
        if (!this.stackedAreaSeries) {
            return [];
        }

        const value = this.filterBy?.value ?? '';
        const tooltips = this.preferences?.configs?.tooltips ?? [];
        const enableSliceManagement = this.preferences?.enableSliceManagement;
        const areFieldsUnique = false;

        const aggregatedData = this.chartsSharedService.getLineAndAreaDataSource(
            this.vizData,
            value,
            this.stackedAreaSeries,
            tooltips,
            areFieldsUnique);

        if (enableSliceManagement) {
            return this.getAreaManagementTooltipDataSource(aggregatedData, value, tooltips.map((tooltip) => tooltip.colId));
        }
        return aggregatedData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getAreaManagementDataSource(aggregatedData: any[], value: string): any[] {
        if (!this.preferences || !this.stackedAreaSeries) {
            return [];
        }

        const { sliceManagementType, groupByPercent, groupByMaxCount } = this.preferences;
        const groupByValue = (sliceManagementType === SliceManagement.PERCENTAGE ? Number(groupByPercent) : Number(groupByMaxCount)) ?? 0;
        return this.chartsSharedService.getStackedAreaManagementData(
            aggregatedData,
            this.stackedAreaSeries,
            sliceManagementType,
            groupByValue,
            value);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getAreaManagementTooltipDataSource(aggregateData: any[], value: string, columns: string[]): any[] {
        const xField = this.stackedAreaSeries?.xField[0] ?? '';
        const nonOtherValues = this.getAreaManagementNonOtherValues(value);
        return this.chartsSharedService.getStackedAreaTooltipManagementData(aggregateData, xField, value, columns, nonOtherValues);
    }

    private getAreaManagementNonOtherValues(value: string): string[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const reduced = this.chartConfiguration?.dataSource.reduce((result: Set<string>, datum: any) => {
            if (datum[value] !== 'others') {
                result.add(datum[value]);
            }
            return result;
        }, new Set()) ?? new Set();
        return [...reduced];
    }

    private getStackedAreaTooltip(tooltipValue: { [key: string]: Date }, tooltipColors: Map<string, string>): string {
        const xField = this.stackedAreaSeries?.xField[0] ?? '';
        const targetDate = tooltipValue[xField].valueOf();
        const tooltipData = this.chartsSharedService.getLineAndStackedAreaTooltipData(this.tooltipData, xField, targetDate);
        tooltipColors.forEach((value, key) => this.addColorToTooltipDatum(key, value, tooltipData));
        tooltipData.reverse();
        return this.chartsSharedService.lineAndStackedAreaTooltipDataToHTML(
            tooltipData,
            this.configs?.tooltips ?? [],
            this.filterBy?.value ?? '');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private addColorToTooltipDatum(key: string, color: string, tooltipData: any[]): void {
        const tooltipDatum = tooltipData.find((datum) => datum[this.filterBy?.value ?? ''] === key);
        if (tooltipDatum) {
            tooltipDatum.color = color;
        }
    }

    private getDate(date: string, fuzzyDates: FuzzyDate[]): Date | undefined {
        return parseInt(date, 10) ?
            new Date(date) :
            fuzzyDates.find((fd) => fd.name.toLocaleUpperCase() === date?.toLocaleUpperCase())?.actualDate;
    }

    private isBottomAxisOrientationVertical(): boolean {
        if (!this.preferences) {
            return false;
        }

        const { bottomXAxisOrientation, axisConfigurations } = this.preferences;
        return bottomXAxisOrientation === 'Vertical' || axisConfigurations[0].orientation === 'Vertical';
    }

    private adjustDomainForOutsideData(): void {
        if (!this.chartConfiguration) {
            return;
        }

        const data = this.chartConfiguration.dataSource ?? [];
        const currentDomain = this.chartConfiguration.axis?.[0][0].domain ?? [];
        if (!data.length || !currentDomain.length) {
            return;
        }

        const timeColumn = this.preferences?.configs?.axisTimes?.[0].value ?? '';

        this.chartConfiguration.axis[0][0].domain[0] = Math.min(data[0][timeColumn], currentDomain[0]);
        this.chartConfiguration.axis[0][0].domain[1] = Math.max(data[data.length - 1][timeColumn], currentDomain[1]);
    }
}
