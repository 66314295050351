import { CommonDate, DateRange } from '@hs/ui-core-date';

import { DashboardPreference } from '../dashboards/dashboard-preference';
import { TableSortType } from '../visualizations/table-sort-type';
import { VisualizationType } from '../visualizations/visualization-type';
import { AppWidgetState } from './app-widget-state';
import { DisplayNameType } from './display-name-type';

export class WidgetSettingsOverrides {
    customName?: string | undefined | null;
    displayNameType?: DisplayNameType;
    masterWidget = false;
    subscribedToDashboardFilters = false;
    filterToActiveDateNotRange?: boolean;
    defaultVisualizationType?: VisualizationType | null;
    defaultSlicerForDefaultVisualization?: string | undefined | null;
    defaultSortOnForDefaultVisualization?: TableSortType | undefined | null;
    defaultSortDirectionForDefaultVisualization?: string | undefined | null;
    realtimeUpdates = false;
    compareMode?: boolean;
    compareDates?: DateRange | CommonDate | undefined;
    currentDashboardQueryParams?: DashboardPreference | undefined;

    public constructor(init?: Partial<WidgetSettingsOverrides>) {
        Object.assign(this, init);
    }

    public clone(): WidgetSettingsOverrides {
        return new WidgetSettingsOverrides(this);
    }

    public deriveEffectiveStateFrom(widgetSettings: AppWidgetState): WidgetSettingsOverrides {
        const effectiveState: WidgetSettingsOverrides = new WidgetSettingsOverrides();

        effectiveState.displayNameType = this.displayNameType ? this.displayNameType : widgetSettings.displayNameType;
        effectiveState.customName = this.customName ? this.customName : widgetSettings.customDisplayName;
        effectiveState.masterWidget = !!(this.masterWidget || widgetSettings.isMaster);

        effectiveState.subscribedToDashboardFilters = !!(this.subscribedToDashboardFilters != null ?
            this.subscribedToDashboardFilters :
            widgetSettings.isSubscribedToDashboardFilters);

        effectiveState.filterToActiveDateNotRange = this.filterToActiveDateNotRange != null ?
            this.filterToActiveDateNotRange : widgetSettings.widgetFilters?.activeDate != null;

        effectiveState.defaultVisualizationType = this.defaultVisualizationType ?
            this.defaultVisualizationType : widgetSettings.getDefaultVisualizationType();

        effectiveState.defaultSlicerForDefaultVisualization = this.defaultSlicerForDefaultVisualization ?
            this.defaultSlicerForDefaultVisualization :
            widgetSettings.getDefaultSlicerForVisualizationType(effectiveState.defaultVisualizationType);

        effectiveState.defaultSortOnForDefaultVisualization = this.defaultSortOnForDefaultVisualization ?
            this.defaultSortOnForDefaultVisualization : widgetSettings.sortTableBy;

        effectiveState.defaultSortDirectionForDefaultVisualization = this.defaultSortDirectionForDefaultVisualization ??
            widgetSettings.tableSortDirection;

        effectiveState.realtimeUpdates = !!(this.realtimeUpdates != null ? this.realtimeUpdates : widgetSettings.realtimeUpdates);

        return effectiveState;
    }

    public applyToWidget(widgetSettings: AppWidgetState): void {
        widgetSettings.displayNameType = this.displayNameType ? this.displayNameType : widgetSettings.displayNameType;
        widgetSettings.customDisplayName = this.customName ? this.customName : widgetSettings.customDisplayName;
        widgetSettings.isMaster = this.masterWidget;
        widgetSettings.isSubscribedToDashboardFilters = this.subscribedToDashboardFilters != null ?
            this.subscribedToDashboardFilters : widgetSettings.isSubscribedToDashboardFilters;
        widgetSettings.isActiveDate = this.filterToActiveDateNotRange != null ?
            this.filterToActiveDateNotRange : widgetSettings.isActiveDate;
        widgetSettings.setDefaultVisualizationType(this.defaultVisualizationType ?
            this.defaultVisualizationType : widgetSettings.getDefaultVisualizationType());
        widgetSettings.sortTableBy = this.defaultSortOnForDefaultVisualization ?
            this.defaultSortOnForDefaultVisualization : widgetSettings.sortTableBy;
        widgetSettings.tableSortDirection = this.defaultSortDirectionForDefaultVisualization ?? widgetSettings.tableSortDirection;

        widgetSettings.realtimeUpdates = this.realtimeUpdates != null ?
            this.realtimeUpdates : widgetSettings.realtimeUpdates;

        widgetSettings.hideLoaderAfterFirstDataLoad = false;

        if (widgetSettings.isActiveDate && widgetSettings.widgetFilters) {
            widgetSettings.widgetFilters.activeDate = widgetSettings.widgetFilters.activeDate ?? widgetSettings.widgetFilters.endDate;
        } else if (widgetSettings.widgetFilters) {
            widgetSettings.widgetFilters.activeDate = null;
        }
    }

    public applyDisplayNameType(value: DisplayNameType): void {
        this.displayNameType = value;

        if (value !== 'CUSTOM') {
            this.applyCustomName(null);
        }
    }

    public applyCustomName(value: string | null): void {
        this.customName = value;
    }

    public applyIsSubscribedToDashboardFilters(value: boolean): void {
        this.subscribedToDashboardFilters = value;
    }

    public applyMasterWidget(value: boolean): void {
        this.masterWidget = value;

        if (!value) {
            this.subscribedToDashboardFilters = true;
        }
    }

    public applyRealtimeUpdates(value: boolean): void {
        this.realtimeUpdates = value;
    }

    public applyCompareMode(value: boolean): void {
        this.compareMode = value;
    }

    public applyDefaultVisualizationType(value: VisualizationType | null): void {
        this.defaultVisualizationType = value;

        this.defaultSlicerForDefaultVisualization = null;
        this.defaultSortOnForDefaultVisualization = null;
        this.defaultSortDirectionForDefaultVisualization = null;
    }

    public applyDefaultSlicerForDefaultVisualization(value: string | null): void {
        this.defaultSlicerForDefaultVisualization = value;
    }

    public applyDefaultSortOnForDefaultVisualization(value: TableSortType | null): void {
        this.defaultSortOnForDefaultVisualization = value;
    }

    public applyDefaultSortDirectionForDefaultVisualization(value: string | null): void {
        this.defaultSortDirectionForDefaultVisualization = value;
    }

    public applyCompareDates(value: DateRange | CommonDate | undefined): void {
        this.compareDates = value;
    }

    public applyCurrentDashboardQueryParams(value: DashboardPreference | undefined): void {
        this.currentDashboardQueryParams = value;
    }

    public enumerateChanges(original: WidgetSettingsOverrides): WidgetSettingsOverrides {
        const changes: WidgetSettingsOverrides = new WidgetSettingsOverrides();

        if (this.displayNameType !== original.displayNameType) {
            changes.displayNameType = this.displayNameType;
        }

        if (this.customName !== original.customName) {
            changes.customName = this.customName;
        }

        if (this.masterWidget !== original.masterWidget) {
            changes.masterWidget = this.masterWidget;
        }

        if (this.subscribedToDashboardFilters !== original.subscribedToDashboardFilters) {
            changes.subscribedToDashboardFilters = this.subscribedToDashboardFilters;
        }

        if (this.filterToActiveDateNotRange !== original.filterToActiveDateNotRange) {
            changes.filterToActiveDateNotRange = this.filterToActiveDateNotRange;
        }

        if (this.defaultVisualizationType !== original.defaultVisualizationType) {
            changes.defaultVisualizationType = this.defaultVisualizationType;
        }

        if (this.defaultSlicerForDefaultVisualization !== original.defaultSlicerForDefaultVisualization) {
            changes.defaultSlicerForDefaultVisualization = this.defaultSlicerForDefaultVisualization;
        }

        if (this.defaultSortOnForDefaultVisualization !== original.defaultSortOnForDefaultVisualization) {
            changes.defaultSortOnForDefaultVisualization = this.defaultSortOnForDefaultVisualization;
        }

        if (this.defaultSortDirectionForDefaultVisualization !== original.defaultSortDirectionForDefaultVisualization) {
            changes.defaultSortDirectionForDefaultVisualization = this.defaultSortDirectionForDefaultVisualization;
        }

        if (this.realtimeUpdates !== original.realtimeUpdates) {
            changes.realtimeUpdates = this.realtimeUpdates;
        }

        return changes;
    }
}
