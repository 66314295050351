import { Component } from '@angular/core';
import { LegendConfigurationService } from '@ddv/models';

import {
    BaseCircleChartVisualizationComponent,
} from '../base-circle-chart-visualization/base-circle-chart-visualization.component';

@Component({
    selector: 'app-donut-chart-visualization',
    templateUrl: './donut-chart-visualization.component.html',
    styleUrls: ['./donut-chart-visualization.component.scss'],
    providers: [LegendConfigurationService],
})
export class DonutChartVisualizationComponent extends BaseCircleChartVisualizationComponent {
    configureChart(): void {
        this.xAxis = [];
        this.yAxis = [];
        this.chartSeries = [
            {
                type: 'donut',
                xField: [''],
                yField: [this.preferences?.configs?.values[0].value ?? ''],
                radius: {
                    inner: 80,
                },
                tooltipHTML: (tooltipData): string => this.getToolTip(tooltipData),
            },
        ];
        this.prepareChartModel();
    }

    onMetadataUpdate(): void {}
}
