import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NavigationService } from './services/navigation.service';
import { ParsedRouteService } from './services/parsed-route.service';

@NgModule({
    imports: [
        RouterModule,
    ],
    providers: [
        NavigationService,
        ParsedRouteService,
    ],
})
export class NavigationModule {}
