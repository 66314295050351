import { Component } from '@angular/core';
import { DataRange, VizConfigs } from '@ddv/models';

import { LineAndStackedAreaAxisID } from '../../../models/chart.constants';
import { FormatVizComponent } from '../format-viz/format-viz.component';

@Component({
    templateUrl: '../format-viz/format-viz.component.html',
    styleUrls: ['../format-viz/format-viz.component.scss'],
})
export class FormatStackedAreaChartComponent extends FormatVizComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override getSelectedConfigs(dataConfig: VizConfigs): any {
        const appliedFormatters = super.getSelectedConfigs(dataConfig);
        this.formatStackedAreaChartConfigs(appliedFormatters);
        return appliedFormatters;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private formatStackedAreaChartConfigs(configs: any): void {
        configs.axisConfigurations = [
            {
                axis: LineAndStackedAreaAxisID.X_AXIS,
                enableAxis: configs.bottomXAxis,
                displayAxisLabels: configs.bottomXAxisLabels,
                position: 'bottom',
                orientation: configs.bottomXAxisOrientation,
                dataRangeMode: DataRange.AUTO,
            },
            {
                axis: LineAndStackedAreaAxisID.Y_LEFT_AXIS,
                enableAxis: configs.leftYAxis,
                displayAxisLabels: configs.leftYAxisLabels,
                position: 'left',
                dataRangeMode: configs.leftYAxisDataRange,
            },
        ];
    }
}
