import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

const LOADER_OPTIONS = {
    animation: 'spinner-line-fade',
    lines: 13,
    radius: 6,
    speed: 1,
};

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    @Input() isDataLoading = false;
    @Input() isWidgetLoader = false;

    options: {animation: string, transform: SafeStyle}[] = [];

    constructor(private readonly sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        if (this.isWidgetLoader) {
            this.startWidgetLoader();
        }
    }

    startWidgetLoader(): void {
        for (let i = 0; i < LOADER_OPTIONS.lines; i++) {
            const delay = (i / LOADER_OPTIONS.lines) - (1 / LOADER_OPTIONS.speed);
            const degrees = Math.floor(360 / LOADER_OPTIONS.lines * i);

            this.options.push({
                animation: `1s linear ${delay}s infinite normal none running ${LOADER_OPTIONS.animation}`,
                transform: this.sanitizer.bypassSecurityTrustStyle(`rotate(${degrees}deg) translateX(${LOADER_OPTIONS.radius}px)`),
            });
        }
    }
}
