<svg version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" width="18px" height="18px"
        viewBox="0 0 18 18" enable-background="new 0 0 18 18"
        xml:space="preserve">
    <path d="M10.256,0.914h-6.74c-0.512,0-0.928,0.414-0.928,0.926v12.964c0,0.512,0.416,0.926,0.928,0.926H14.01c0.512,0,0.926-0.414,0.926-0.926V5.72c0-0.246-0.098-0.48-0.271-0.654l-3.971-3.973C10.576,0.978,10.42,0.914,10.256,0.914M9.998,5.851V2.144l3.707,3.707H9.998z M3.824,14.495V2.148h4.938v4.938h4.939v7.41H3.824z" />
    <circle stroke-miterlimit="10" cx="12.646" cy="13.503" r="3.883" />
    <line stroke-width="1.2706" stroke-miterlimit="10" x1="10.112" y1="13.417" x2="15.179" y2="13.417" />
    <line stroke-width="1.2706" stroke-miterlimit="10" x1="12.646" y1="10.884" x2="12.646" y2="15.95" />
</svg>
