import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ExportDatasetInfo, TExportFormat } from '@ddv/models';
import { Observable, Subject } from 'rxjs';
import { retry, share } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { ServerSocketMessage } from './socket.component';

export const ddvWsConnectionUrlToken: InjectionToken<string> = new InjectionToken('ddvWsConnectionUrlToken');

@Injectable()
export class ServerSocketService {
    public readonly isSending$: Observable<unknown>;

    private socketSubject: WebSocketSubject<ServerSocketMessage> | undefined;
    private readonly isSending: Subject<boolean> = new Subject();

    constructor(@Inject(ddvWsConnectionUrlToken) private readonly wsConnectionUrl: string) {
        this.isSending$ = this.isSending.asObservable();
    }

    connect(): Observable<ServerSocketMessage> {
        this.socketSubject = webSocket<ServerSocketMessage>(this.wsConnectionUrl);

        return this.socketSubject.pipe(retry(), share());
    }

    send(message: WebSocketMessage): void {
        this.isSending.next(true);
        // this is obviously wrong but it appears that the RXJS library assumes the same message
        // type/format goes both down and up the socket, which is ridiculous
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.socketSubject?.next(message as any as ServerSocketMessage);
    }

    disconnect(): void {
        this.socketSubject?.complete();
        this.socketSubject = undefined;
        this.isSending.next(false);
    }
}

export interface ExportPayload {
    clientCode: string;
    dashboardName: string;
    dashboardId: string;
    datasets: ExportDatasetInfo[];
    format: TExportFormat;
    exportType: string;
}

export interface WebSocketMessage {
    type: 'dashboard export';
    payload: ExportPayload;
}
