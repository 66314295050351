import { Pipe, PipeTransform } from '@angular/core';
import { TextValuePair, ValueFilterOption } from '@ddv/models';

@Pipe({ name: 'textValueFilter' })
export class TextValueFilter implements PipeTransform {
    transform(array: TextValuePair[] | ValueFilterOption[], args?: string): TextValuePair[] | ValueFilterOption[] {
        if (args) {
            return array.filter((pair) => pair.text.toLocaleLowerCase().indexOf(args.toLocaleLowerCase()) !== -1);
        }
        return array;
    }
}
