import { Component, OnInit, ViewChild } from '@angular/core';
import { Axis, ChartSettings, GridLines, Margin, Series, ColorMetadataService, getDefaultMargin } from '@ddv/charts';
import { MetadataService } from '@ddv/datasets';
import { ClientDatasetFilterService } from '@ddv/filters';
import { ManagerService } from '@ddv/layout';
import {
    SliceManagement,
    WIDGET_LIFECYCLE_EVENT,
    CompareMode,
    ExportFilteredData,
    ConfigItem,
    LegendConfig,
    LegendConfigurationService,
    TableSortType,
    VizConfigs,
    DataUpdateBody,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    SliceManagementOptions,
    IAxis,
    DataRange,
} from '@ddv/models';
import { getDefault, clone, cloneArray } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

import { BaseVisualizationComponent } from '../../../base/base-visualization.component';
import { ChartsSharedService } from '../../../base/visualization-wrapper/charts-shared.service';
import { VisualizationWrapperComponent } from '../../../base/visualization-wrapper/visualization-wrapper.component';
import { ILabelValue } from '../../../base/visualization-wrapper/visualization-wrapper.interface';

@Component({ template: '' })
export abstract class BaseBarChartVisualizationComponent extends BaseVisualizationComponent implements OnInit {
    @ViewChild(VisualizationWrapperComponent, { static: true }) vizWrapperComponent: VisualizationWrapperComponent | undefined;

    visualizationModel: ChartSettings | undefined;

    protected xAxis: Axis[] = [];
    protected yAxis: Axis[] = [];
    protected chartSeries: Series[] = [];
    protected filterBy: ILabelValue | undefined;
    protected margin: Margin = getDefaultMargin();
    protected configs: VizConfigs | undefined;
    protected legendConfig: LegendConfig | undefined;
    protected currentSlicerDatatype = '';

    abstract configureChart(): void;

    constructor(
        metadataService: MetadataService,
        protected legendConfigurationService: LegendConfigurationService,
        protected chartsSharedService: ChartsSharedService,
        protected clientDatasetFilterService: ClientDatasetFilterService,
        protected colorMetadataService: ColorMetadataService,
        private readonly manager: ManagerService,
        private readonly themeService: ThemeService,
    ) {
        super(metadataService);
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.legendConfig = this.legendConfigurationService.getConfigFromUserPreference(this.preferences);
        this.configs = this.preferences?.configs;

        this.isChartInitialized = true;

        this.vizInfo = this.vizInfo ?? {
            slicer: { label: '', value: '' },
            values: [],
        };

        this.themeService.currentTheme$.subscribe((theme: Theme) => {
            this.theme = theme;
            this.setVizSlicer();
        });

        this.subscribeTo(this.manager.isStackedQuery(this.datasetId), (isStackedQuery) => this.isStackedQuery = isStackedQuery);
    }

    onFilterChanged(item: ILabelValue): void {
        this.filterBy = item;
        this.populateChartData(item, this.preferences?.sortTableBy, this.preferences?.tableSortDirection);
    }

    override onVizInfoChanged(slicer: Partial<ConfigItem>): void {
        if (this.vizInfo && this.vizInfo.slicer.value !== slicer.value) {
            this.vizInfo.slicer = slicer;
        }
    }

    getExportFilteredData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFilteredData();
    }

    getExportFullData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFullData();
    }

    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.LOADING_DATA:
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = true;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                this.updateDataSource(data as Required<DataUpdateBody>);
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = false;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.VISUALIZATION_SELECTED:
                this.updateChartSelection(data);
        }
    }

    widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, _: unknown): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.INIT_WIDGET:
            case WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE:
                this.vizWrapperComponent?.resizeVisualization();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE:
                this.vizWrapperComponent?.onMaximize();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE:
                if (this.visualizationModel) {
                    this.visualizationModel.dataCompareSource = undefined;
                }
                this.vizWrapperComponent?.onRestore();
        }
    }

    updateDataSource(componentData: Required<DataUpdateBody>): void {
        const widgetFilters = componentData?.widgetPrefs?.widgetFilters ?? componentData.filters;
        this.vizData = this.getVizData(componentData);

        let sortTableBy = this.preferences?.sortTableBy;
        let tableSortDirection = this.preferences?.tableSortDirection;
        if (this.visualizationModel) {
            if (componentData.widgetPrefs && !this.isManagingWidget) {
                sortTableBy = componentData.widgetPrefs.sortTableBy ?? sortTableBy;
                tableSortDirection = componentData.widgetPrefs.tableSortDirection;
                if (this.isChartInitialized) {
                    this.visualizationModel.formatter = clone(this.visualizationModel.formatter, {
                        sortDirection: componentData.widgetPrefs.tableSortDirection,
                    });
                }
            }

            if (this.isChartInitialized && !this.chartSeries[0].stacked) {
                const sortOrderSlicer = super.getCurrentSortOrderSlicer(this.vizInfo?.slicer?.value);
                const sliceManagementOptions = this.getSliceManagementOptions();
                const getBarDataSource = this.chartsSharedService.getBarDataSource(
                    this.vizData,
                    this.filterBy?.value ?? '',
                    this.visualizationModel.series,
                    this.preferences?.configs?.values ?? [],
                    !!this.preferences?.enableSliceManagement,
                    sliceManagementOptions,
                    sortTableBy,
                    tableSortDirection,
                    sortOrderSlicer);

                if (!widgetFilters?.isComparing) {
                    this.visualizationModel = clone(this.visualizationModel, {
                        dataSource: getBarDataSource,
                        dataCompareSource: null,
                    });
                } else {
                    if (componentData.compareMode === CompareMode.ORIGINAL || !componentData.compareMode) {
                        this.visualizationModel = clone(this.visualizationModel, {
                            dataSource: getBarDataSource,
                        });
                    } else if (componentData.compareMode === CompareMode.COMPARED) {
                        this.visualizationModel = clone(this.visualizationModel, {
                            dataCompareSource: getBarDataSource,
                        });
                    }
                }
            }

            if (this.isChartInitialized && this.chartSeries[0].stacked) {
                this.populateChartData(this.filterBy, sortTableBy, tableSortDirection, widgetFilters?.comparing);
            }
        }
    }

    protected setVizSlicer(): void {
        const slicer = this.vizInfo?.slicer;
        if (slicer?.label) {
            this.configs?.slicers.forEach((s) => {
                if (slicer.value === s.value &&
                    (slicer.customName !== s.customName || slicer.showCustomName !== s.showCustomName)
                ) {
                    slicer.showCustomName = s.showCustomName;
                    slicer.customName = s.customName;
                    slicer.colorSortBy = s.colorSortBy;
                    slicer.colorSortDirection = s.colorSortDirection;
                }
            });

            this.vizSlicer.next(slicer);
            return;
        }

        const slicers = this.configs?.slicers ?? [];
        const selectedSlicer = getDefault(slicers, { colId: '', label: '', value: '' } as ConfigItem);
        this.vizSlicer.next({
            label: selectedSlicer?.label,
            value: selectedSlicer?.value,
            showCustomName: selectedSlicer?.showCustomName,
            customName: selectedSlicer?.customName,
            colorSortBy: selectedSlicer?.colorSortBy,
            colorSortDirection: selectedSlicer?.colorSortDirection,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    protected updateChartSelection(data: any): void {
        if (this.isChartInitialized) {
            this.visualizationModel = clone(this.visualizationModel, {
                highlight: data.selectedItem || {},
            });
        }
    }

    protected prepareChartModel(): void {
        this.initChartModel();

        if (this.chartSeries[0].stacked && !this.chartSeries[0].mirror) {
            this.setVisualizationModelMultiSeries();
        }

        const enableSliceManagement = this.preferences?.enableSliceManagement;

        if (enableSliceManagement) {
            this.setVisualizationModelSliceManagement(enableSliceManagement);
        }

        this.setVisualizationModelFormatter();

        if (this.preferences?.inChartLabel) {
            this.setVisualizationModelCurveValues();
        }

        if (!this.chartSeries[0].mirror && this.preferences?.configs?.slicers) {
            this.setVisualizationModelColorRange();
        }

        if (this.chartSeries[0].mirror && this.preferences?.configs?.values.length && this.preferences.configs?.mirroredValues?.length) {
            this.setVisualizationModelMirrorColorRange();
        }
    }

    protected populateChartData(
        slicer: ILabelValue | undefined,
        sortTableBy: TableSortType | undefined,
        tableSortDirection: string | undefined,
        comparingMethod: string = CompareMode.ORIGINAL,
    ): void {
        if (!this.vizInfo) {
            return console.error('cannot populateChartData without a vizInfo');
        }

        this.vizInfo.slicer.value = slicer?.value;
        this.vizInfo.slicer.label = slicer?.label;
        this.vizInfo.slicer.showCustomName = slicer?.showCustomName;
        this.vizInfo.slicer.customName = slicer?.customName;
        this.vizInfo.slicer.colorSortBy = slicer?.colorSortBy;
        this.vizInfo.slicer.colorSortDirection = slicer?.colorSortDirection;

        this.configureChart();
        this.vizInfo.values = cloneArray(this.preferences?.configs?.values ?? []);
        const sortOrderSlicer = super.getCurrentSortOrderSlicer(this.vizInfo?.slicer.value);

        if (!this.chartSeries[0].stacked) {
            this.populateBarChartData(slicer?.value, sortTableBy, tableSortDirection, comparingMethod, sortOrderSlicer);
        }

        if (this.chartSeries[0].stacked && !this.chartSeries[0].mirror) {
            this.populateStackedBarChartData(slicer?.value, sortTableBy, tableSortDirection, sortOrderSlicer);
        }

        if (this.chartSeries[0].mirror) {
            this.populateMirroredBarChartData(slicer?.value, tableSortDirection);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    protected getToolTip(toolTipValue: any, slicerLabel?: string, slicerField?: string): string {
        const chartSeries: Series = this.chartSeries[0];
        const tooltipdata = chartSeries.stacked ? toolTipValue.data : toolTipValue;
        const axisField = chartSeries.horizontal ? chartSeries.yField[0] : chartSeries.xField[0];
        const dataSource = chartSeries.stacked ? this.getStackedFilteredDataSource(tooltipdata, axisField) :
            this.getFilteredDataSource(tooltipdata, axisField);
        const isStacked = chartSeries.stacked && !chartSeries.mirror;
        return this.chartsSharedService.getBarToolTip(
            tooltipdata,
            axisField,
            this.configs?.tooltips ?? [],
            dataSource,
            isStacked,
            slicerLabel,
            slicerField);
    }

    protected updateAxisConfig(axis: Axis, axisConfig?: IAxis): void {
        if (!axisConfig) {
            return console.error('cannot updateAxisConfig without an axisConfig');
        }

        axis.rotate = 0;
        axis.position = axisConfig.position;
        if (axisConfig.enableAxis) {
            axis.customClass = axisConfig.enableAxisLine ? '' : 'show-only-tick-labels';
            if (!axisConfig.displayAxisLabels) {
                axis.nTicks = 0;
            }
        } else {
            axis.customClass = 'hide';
        }
        if (axisConfig.dataRangeMode === DataRange.MANUAL) {
            let minDataRange = Number(axisConfig.minDataRange) || 0;
            minDataRange = this.chartSeries[0].mirror && minDataRange > 0 ? minDataRange * -1 : minDataRange;
            let maxDataRange = Number(axisConfig.maxDataRange) || 0;
            maxDataRange = this.chartSeries[0].mirror ? Math.abs(maxDataRange) : maxDataRange;
            axis.domain = [minDataRange, maxDataRange];
        }
    }

    protected getBarMargin(): Margin {
        const yAxis = this.preferences?.axisConfigurations?.[1];
        if (!yAxis) {
            throw new Error('Cannot getBarMargin without a yAxis');
        }

        const legendPosition = this.preferences?.enableLegend ? this.preferences.legendPosition : undefined;
        const { position, enableAxis, displayAxisLabels } = yAxis;
        const marginCondition = this.chartsSharedService.getMarginCondition(legendPosition, position, enableAxis, displayAxisLabels);

        let marginBottom = marginCondition.legendPosition !== 'bottom' ? 30 : 60;
        if (this.currentSlicerDatatype === 'date') {
            marginBottom = marginCondition.legendPosition === 'bottom' ?
                marginBottom + 50 :
                marginBottom + 30;
        }
        return {
            top: marginCondition.legendPosition !== 'top' ? 20 : 30,
            right: marginCondition.rightSideAxisFlag ? 15 : 35,
            bottom: marginBottom,
            left: marginCondition.leftSideAxisFlag ? 5 : 25,
        };
    }

    private getGridLinesConfig(): GridLines {
        const chartSeries: Series = this.chartSeries[0];
        return {
            x: {
                hide: !chartSeries.horizontal,
                showLabels: false,
                nTicks: 10,
                customClass: this.preferences?.gridLineStyle,
            },
            y: {
                hide: chartSeries.horizontal,
                showLabels: false,
                nTicks: 10,
                customClass: this.preferences?.gridLineStyle,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getStackedFilteredDataSource(tooltipdata: any, axisField: string): any[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let dataSource: any[];
        if (tooltipdata[axisField] === 'others') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const othersDataAxisFields = tooltipdata.children.map((child: any) => child[axisField]);
            dataSource = this.vizData.filter((data) => othersDataAxisFields.indexOf(data[axisField]) !== -1);
        } else {
            dataSource = this.vizData.filter((d) => d[axisField] === tooltipdata[axisField]);
        }
        return dataSource;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getFilteredDataSource(tooltipdata: any, axisField: string): any[] {
        let dataSource = tooltipdata.values;
        if (tooltipdata[axisField] === 'others') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dataSource = tooltipdata.children.reduce((childrenValues: any, column: any) => {
                return childrenValues.concat(column.values);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }, [] as any[]);
        }
        return dataSource;
    }

    private initChartModel(): void {
        this.visualizationModel = this.chartsSharedService.getBaseChartModel(this.chartSeries, [this.xAxis, this.yAxis], this.vizData);
        this.visualizationModel.margin = this.margin;
        this.visualizationModel.parentSelector = `app-bar-chart_${this.widgetId}`;
        this.visualizationModel.legend = this.legendConfig;
        this.visualizationModel.showTooltip = this.preferences?.enableTooltip;
        this.visualizationModel.showGridLines = this.preferences?.gridLineStyle !== 'none';
        this.visualizationModel.showCurveValues = this.preferences?.inChartLabel;
        this.visualizationModel.highlightXValueOnHover = false;
        this.visualizationModel.enableDynamicAxisLabelWidth = true;
        this.visualizationModel.highlightYValueOnHover = this.preferences?.showHoverLines;
        this.visualizationModel.gridLines = this.getGridLinesConfig();
        this.visualizationModel.highlight = undefined;
        this.visualizationModel.showBrush = this.currentSlicerDatatype === 'date';
        if (this.visualizationModel.showBrush) {
            this.visualizationModel.brushSize = 4;
        }
    }

    private setVisualizationModelMultiSeries(): void {
        if (this.visualizationModel) {
            this.visualizationModel.multiSeries = {
                field: this.configs?.details?.[0].value ?? '',
                label: this.configs?.details?.[0].showCustomName ? this.configs.details[0].customName : this.configs?.details?.[0].label,
            };
        }
    }

    private populateBarChartData(
        filterByValue: string | undefined,
        sortTableBy: TableSortType | undefined,
        tableSortDirection: string | undefined,
        comparingMethod: string,
        sortOrderSlicer: ConfigItem | undefined,
    ): void {
        const sliceManagementOptions = this.getSliceManagementOptions();

        if (comparingMethod === CompareMode.BOTH) {
            const getBarDataSource = this.chartsSharedService.getBarDataSource(
                this.visualizationModel?.dataSource ?? [],
                filterByValue,
                this.chartSeries,
                this.preferences?.configs?.values ?? [],
                !!this.preferences?.enableSliceManagement,
                sliceManagementOptions,
                sortTableBy,
                tableSortDirection,
                sortOrderSlicer);
            const getBarDataCompareSource = this.chartsSharedService.getBarDataSource(
                this.visualizationModel?.dataCompareSource ?? [],
                filterByValue,
                this.chartSeries,
                this.preferences?.configs?.values ?? [],
                !!this.preferences?.enableSliceManagement,
                sliceManagementOptions,
                sortTableBy,
                tableSortDirection,
                sortOrderSlicer);

            if (this.visualizationModel) {
                this.visualizationModel.dataSource = getBarDataSource;
                this.visualizationModel.dataCompareSource = getBarDataCompareSource;
            }
        } else {
            const dataSource = comparingMethod === CompareMode.COMPARED ?
                this.visualizationModel?.dataCompareSource :
                this.visualizationModel?.dataSource;

            const getBarDataSource = this.chartsSharedService.getBarDataSource(
                dataSource ?? [],
                filterByValue,
                this.chartSeries,
                this.preferences?.configs?.values ?? [],
                !!this.preferences?.enableSliceManagement,
                sliceManagementOptions,
                sortTableBy,
                tableSortDirection,
                sortOrderSlicer);

            if (this.visualizationModel) {
                if (comparingMethod === CompareMode.COMPARED) {
                    this.visualizationModel.dataCompareSource = getBarDataSource;
                } else {
                    this.visualizationModel.dataSource = getBarDataSource;
                }
            }
        }
    }

    private populateStackedBarChartData(
        slicerField: string | undefined,
        sortTableBy: TableSortType | undefined,
        tableSortDirection: string | undefined,
        sortOrderSlicer: ConfigItem | undefined,
    ): void {
        const slicers = this.preferences?.configs?.slicers ?? [];
        const field = this.visualizationModel?.multiSeries?.field;
        const detailOrderSlicer = tableSortDirection === 'CUSTOM' ?
            slicers.find((s) => s.name === field && s.configCustomSortOrder?.length) :
            undefined;

        if (this.visualizationModel) {
            this.visualizationModel.dataSource = this.chartsSharedService.getStackedBarDataSource(
                this.visualizationModel.dataSource,
                slicerField ?? '',
                this.chartSeries,
                this.preferences?.configs?.values ?? [],
                field ?? '',
                sortTableBy,
                tableSortDirection,
                sortOrderSlicer,
                detailOrderSlicer);
        }
    }

    private populateMirroredBarChartData(filterByValue: string | undefined, tableSortDirection: string | undefined): void {
        const mirroredValue = this.preferences?.configs?.mirroredValues?.[0];
        if (mirroredValue) {
            this.vizInfo?.values.push(clone(mirroredValue));
        }

        if (this.visualizationModel) {
            this.visualizationModel.dataSource = this.chartsSharedService.getMirrorBarDataSource(
                this.visualizationModel.dataSource,
                filterByValue ?? '',
                this.preferences?.configs?.values ?? [],
                this.preferences?.configs?.mirroredValues ?? [],
                tableSortDirection);
        }
    }

    private setVisualizationModelSliceManagement(enableSliceManagement: boolean): void {
        if (this.visualizationModel) {
            const { groupByType, groupByValue, showOthers } = this.getSliceManagementOptions();
            this.visualizationModel.enableSliceManagement = enableSliceManagement;
            this.visualizationModel.groupByType = groupByType;
            this.visualizationModel.groupByValue = groupByValue;
            this.visualizationModel.showOthers = showOthers;
        }
    }

    private getSliceManagementOptions(): SliceManagementOptions {
        return {
            groupByType: this.preferences?.sliceManagementType ?? '',
            groupByValue: (this.preferences?.sliceManagementType === SliceManagement.PERCENTAGE ?
                this.preferences?.groupByPercent :
                this.preferences?.groupByMaxCount) ?? 0,
            showOthers: !!this.preferences?.showOthers,
        };
    }

    private setVisualizationModelFormatter(): void {
        if (this.visualizationModel && this.preferences) {
            this.visualizationModel.formatter = {
                isNumberFormatted: Boolean(this.preferences.numberFormat ?? this.preferences.decimalPlaces),
                numberUnits: this.preferences.numberUnits,
                numberFormat: this.preferences.numberFormat,
                decimalPlaces: this.preferences.decimalPlaces,
                sortDirection: this.preferences.tableSortDirection,
            };
        }
    }

    private setVisualizationModelCurveValues(): void {
        if (this.visualizationModel && this.preferences) {
            this.visualizationModel.curveValues = {
                enableInsideLabel: !!this.preferences.enableInsideLabel,
                labelOrientation: this.preferences.labelOrientation ?? 'horizontal',
                enableTotalLabel: this.chartSeries[0].stacked ? !!this.preferences.enableTotalLabel : true,
            };
        }
    }

    private setVisualizationModelColorRange(): void {
        if (this.visualizationModel && this.preferences) {
            const colorRange = this.chartsSharedService.getColorRange(
                this.preferences,
                this.colorMetadataService,
                this.filterBy?.value ?? '',
                this.theme);
            if (colorRange?.length) {
                this.visualizationModel.colorRange = colorRange;
            }
            this.visualizationModel.attributeCustomColors = this.chartsSharedService.getAttributeCustomColors(
                this.preferences,
                this.filterBy?.value ?? '',
                this.theme);

            if ((this.visualizationModel.colorRange?.length ?? 0) > 3 && this.visualizationModel.attributeCustomColors?.length) {
                this.visualizationModel.colorRange = this.chartsSharedService.getFilteredColorRange(this.visualizationModel);
            }
        }
    }

    private setVisualizationModelMirrorColorRange(): void {
        if (this.visualizationModel && this.preferences) {
            const valueColor = this.preferences.configs?.values[0].colorName;
            const mirroredColor = this.preferences.configs?.mirroredValues?.[0].colorName;
            if (valueColor && mirroredColor) {
                this.visualizationModel.colorRange = [valueColor, mirroredColor];
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getVizData(componentData: DataUpdateBody): any[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any[] = (componentData.compareMode === CompareMode.COMPARED && componentData.compareData ?
            componentData.compareData :
            componentData.data) ?? [];
        const activeDate = (this.isStackedQuery || componentData.compareMode === CompareMode.COMPARED) ?
            undefined :
            componentData.filters?.activeDate;
        const filters = { activeDate };
        return this.clientDatasetFilterService.filterData(data, filters);
    }
}
