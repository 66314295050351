import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BehaviorsModule } from '@ddv/behaviors';
import { CommonComponentsModule } from '@ddv/common-components';
import { DashboardsModule } from '@ddv/dashboards';
import { ErrorHandlerService, ErrorHandlingModule } from '@ddv/error-handling';
import { HttpModule, ApiConfiguration } from '@ddv/http';
import { ManageDatasetDefinitionsModule } from '@ddv/manage-dataset-definitions';
import { ManageWidgetsModule } from '@ddv/manage-widgets';
import { ReferenceDataModule } from '@ddv/reference-data';
import { SocketModule } from '@ddv/socket';
import { ViewsModule } from '@ddv/views';
import { VisualizationsModule } from '@ddv/visualizations';
import type { AgentConfigOptions } from '@elastic/apm-rum';
import { ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { PublicApiEnvironment } from '@hs/ui-core-common';
import { CcDatesModule } from '@hs/ui-core-date';
import { ModalLayerComponent } from '@hs/ui-core-layout';
import { CcNavbarModule, NavBarComponent } from '@hs/ui-core-navbar';
import { ToolTipComponent } from '@hs/ui-core-presentation';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationService } from './application.service';
import { MainComponent } from './main/main.component';
import { WrapperModule } from './wrapper/wrapper.module';

@NgModule({
    imports: [
        ApmModule,
        AppRoutingModule,
        BehaviorsModule,
        BrowserAnimationsModule,
        CcDatesModule.forRoot(
            getPublicApiEnvironment(window.location.hostname, environment.isEdge),
            environment.trebekApiLocation.hostAndPort,
        ),
        CommonComponentsModule,
        DashboardsModule,
        ErrorHandlingModule,
        HttpModule,
        ManageDatasetDefinitionsModule,
        ManageWidgetsModule,
        ReferenceDataModule,
        SocketModule,
        ViewsModule,
        VisualizationsModule,
        WrapperModule,
        CcNavbarModule,
        ModalLayerComponent,
        NavBarComponent,
        ToolTipComponent,
    ],
    declarations: [
        AppComponent,
        MainComponent,
    ],
    providers: [
        ApmService,
        ApplicationService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: 'apiConfig',
            useFactory: (): ApiConfiguration => environment,
        },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(apm: ApmService) {
        apm.init({
            serverUrl: `https://${environment.apm.host}/apm/`,
            environment: environment.apm.environment,
            centralConfig: false,
            serviceName: 'DDV UI',
            active: environment.apm.active,
            sendCredentials: true,
        } as AgentConfigOptions);
    }
}

export function getPublicApiEnvironment(hostname: string, isEdge: boolean): PublicApiEnvironment {
    if (hostname.includes('-dr')) {
        return 'dr';
    }

    if (hostname.includes('.hedgeserv.com')) {
        return 'prod';
    }

    return isEdge ? 'edge' : 'stable';
}
