<div class="active-date-toggle">
    <div class="radio-btn">
        <input
            id="radio_active_date"
            name="isActiveDateRadio"
            type="radio"
            [(ngModel)]="isActiveDate"
            [value]="true"
            (click)="applyRadioFilter(true)"/>

        <label for="radio_active_date">Active Date</label>
    </div>

    <div class="radio-btn">
        <input
            id="radio_range"
            name="isActiveDateRadio"
            type="radio"
            [(ngModel)]="isActiveDate"
            [value]="false"
            (click)="applyRadioFilter(false)"/>

        <label for="radio_range">Use Date Range</label>
    </div>
</div>

<div class="dialog-wrapper" [ngClass]="{'disabled':!isActiveDate}" *ngIf="isActiveDate || errorMessage">
    <span class="errormessage" *ngIf="errorMessage">{{errorMessage}}</span>

    <div *ngIf="isActiveDate">
        <label class="dialog__label">Active date</label>

        <div class="datepicker-wrapper">
            <app-datepicker
                class="datepicker__item"
                [calendarOptions]="{allowPrev: true, allowNext: true, allowRange: false}"
                [fuzzyDates]="fuzzyDates"
                [year]="year"
                [month]="month"
                [date]="date"
                (dateSelected)="onDateSelected($event)"
                (errorMessageChange)="showError($event)">
            </app-datepicker>

            <div class="range__inputs">
                <button class="fill-btn" type="button" (click)="emitDate()">Apply</button>
                <span></span>
            </div>
        </div>
    </div>
</div>
