import { InjectionToken } from '@angular/core';
import { DdvDate, FilterQueryParam, FuzzyDates } from '@ddv/models';
import { deepCompare, getUTCDate } from '@ddv/utils';

export const queryParamsDifferInjectionToken = new InjectionToken<QueryParamsDiffer>('QueryParamsDiffer');

export type QueryParamsDiffer = (
    oldParams: FilterQueryParam | undefined,
    newParams: FilterQueryParam | undefined,
    fuzzyDates: FuzzyDates,
    defaultDates: { startDate?: string, endDate?: string } | undefined,
) => boolean;

export function areQueryParamsEqual(
    oldParams: FilterQueryParam | undefined,
    newParams: FilterQueryParam | undefined,
    fuzzyDates: FuzzyDates,
    defaultDates?: { startDate?: string, endDate?: string },
): boolean {
    if (deepCompare(oldParams, newParams)) { // completely the same
        return true;
    }

    if (!deepCompare(copyParamsWithoutDates(oldParams), copyParamsWithoutDates(newParams))) { // there is a change in the non-date filters
        return false;
    }

    const oldStartDate = datePropertyAsDate('startDate', oldParams, fuzzyDates, defaultDates);
    const oldEndDate = datePropertyAsDate('endDate', oldParams, fuzzyDates, defaultDates);
    const newStartDate = datePropertyAsDate('startDate', newParams, fuzzyDates, defaultDates);
    const newEndDate = datePropertyAsDate('endDate', newParams, fuzzyDates, defaultDates);

    if (!oldStartDate || !oldEndDate || !newStartDate || !newEndDate) {
        return false;
    }

    return newStartDate.getTime() === oldStartDate.getTime() && newEndDate.getTime() === oldEndDate.getTime();
}

export function datePropertyAsDate(
    which: 'startDate' | 'endDate',
    params: FilterQueryParam | undefined,
    fuzzyDates: FuzzyDates,
    defaultDates?: { startDate?: string, endDate?: string },
): Date | undefined {
    const paramsStartDate = params?.[which] ?? defaultDates?.[which];
    if (!paramsStartDate) {
        return;
    }

    if (paramsStartDate && DdvDate.isStringValidDate(paramsStartDate)) {
        return getUTCDate(paramsStartDate);
    }

    const fuzzyDate = fuzzyDates[which === 'startDate' ? 'findFromDate' : 'findToDate'](paramsStartDate);
    return fuzzyDate?.actualDate;
}

function copyParamsWithoutDates(params: FilterQueryParam | undefined): FilterQueryParam {
    return {
        funds: params?.funds,
        isComparing: params?.isComparing ? params?.isComparing : undefined,
        comparing: params?.isComparing ? params?.comparing : undefined, // we don't care about that if we aren't comparing
        compareDates: params?.compareDates,
        isPreferenceChangedOnRefresh: params?.isPreferenceChangedOnRefresh,
        clients: params?.clients,
        acknowledged: params?.acknowledged,
        includeManuallyReleased: params?.includeManuallyReleased, // only relevant for net-settlement breaks
    };
}
