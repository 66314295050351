import { Component, OnInit, ViewChild } from '@angular/core';
import { Axis, ChartSettings, Margin, Series, ColorMetadataService, getDefaultMargin } from '@ddv/charts';
import { MetadataService } from '@ddv/datasets';
import {
    WIDGET_LIFECYCLE_EVENT,
    ExportFilteredData,
    DashboardClientQueryParam,
    FuzzyDate,
    FuzzyDates,
    ConfigItem,
    LegendConfig,
    LegendConfigurationService,
    VizConfigs,
    AppWidgetState,
    DataUpdateBody,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    IAxis,
    DataRange,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { getDefault, clone, deepClone, initialCapitalization } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

import { BaseVisualizationComponent } from '../../../base/base-visualization.component';
import { ChartsSharedService } from '../../../base/visualization-wrapper/charts-shared.service';
import { VisualizationWrapperComponent } from '../../../base/visualization-wrapper/visualization-wrapper.component';

interface TooltipParams {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltipValue: { [x: number]: any, hiddenValues: any };
    tooltipColors: Map<string, string>;
}

@Component({
    selector: 'app-line-chart-visualization',
    templateUrl: 'line-chart-visualization.component.html',
    styleUrls: ['line-chart-visualization.component.scss'],
    providers: [LegendConfigurationService],
})
export class LineChartVisualizationComponent extends BaseVisualizationComponent implements OnInit {
    @ViewChild(VisualizationWrapperComponent, { static: true }) private readonly vizWrapperComponent?: VisualizationWrapperComponent;
    chartConfiguration: ChartSettings | undefined;
    zoom: { label: string }[] = [];
    zoomValue = '';
    private xAxis: Axis[] = [];
    private yAxis: Axis[] = [];
    private lineSeries: Series | undefined;
    private filterBy: Partial<ConfigItem> | undefined;
    private margin: Margin = getDefaultMargin();
    private legendConfig: LegendConfig | undefined;
    private hasSlicer = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private tooltipData: any[] = [];
    private configs: VizConfigs | undefined;
    private xDomain: (Date | undefined)[] = [];
    private fuzzyDates: FuzzyDates | undefined;

    constructor(
        private readonly legendConfigurationService: LegendConfigurationService,
        private readonly chartsSharedService: ChartsSharedService,
        private readonly colorMetadataService: ColorMetadataService,
        private readonly fuzzyDatesService: FuzzyDatesService,
        metadataService: MetadataService,
        private readonly themeService: ThemeService,
    ) {
        super(metadataService);
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.subscribeTo(this.fuzzyDatesService.fuzzyDates(), (fuzzyDates: FuzzyDates) => {
            this.fuzzyDates = fuzzyDates;
        });

        this.preferences?.configs?.values.forEach((field) => {
            if (!field.displayName) {
                field.displayName = field.aggregationType ?
                    `${initialCapitalization(field.aggregationType)} of ${field.label}` :
                    field.label;
            }
        });

        this.legendConfig = this.legendConfigurationService.getConfigFromUserPreference(this.preferences);

        this.isChartInitialized = true;
        this.zoomValue = '';
        this.configs = this.preferences?.configs;

        this.subscribeTo(this.themeService.currentTheme$, (theme: Theme) => {
            this.theme = theme;

            this.setVizSlicer();
            if (!this.hasSlicer) {
                this.initLineChart();
            }
        });

        if (this.preferences?.showBrush && this.preferences?.zoomLevels?.length) {
            this.zoom.push(...this.preferences.zoomLevels.map((zoomLevel) => ({ label: zoomLevel.value })));
        }
    }

    configureChart(field: string): void {
        if (!this.lineSeries || !this.preferences) {
            return console.error('cannot configureChart without a lineSeries and a preferences');
        }

        this.chartConfiguration = this.chartsSharedService.getBaseChartModel([this.lineSeries], [this.xAxis, this.yAxis], this.vizData);

        this.chartConfiguration.parentSelector = `app-line-chart_${this.widgetId}`;
        this.chartConfiguration.margin = this.margin;
        this.chartConfiguration.minHeight = 200;

        this.chartConfiguration.legend = this.legendConfig;
        this.chartConfiguration.multiSeries = this.hasSlicer ? { field } : null;
        this.chartConfiguration.showMultipleSeries = this.hasSlicer;

        this.chartConfiguration.showTooltip = this.preferences.enableTooltip;
        this.chartConfiguration.highlightXValueOnHover = this.preferences.showHoverLines;
        this.chartConfiguration.highlightYValueOnHover = this.preferences.showHoverLines;
        this.chartConfiguration.enableDynamicAxisLabelWidth = true;

        this.chartConfiguration.showGridLines = this.preferences.gridLineStyle !== 'none';
        this.chartConfiguration.gridLines = {
            x: { hide: true },
            y: { hide: false, showLabels: false, nTicks: 10, customClass: this.preferences.gridLineStyle },
        };

        this.chartConfiguration.showCurveValues = this.preferences.inChartLabel;
        this.chartConfiguration.showBrush = this.preferences.showBrush;
        this.chartConfiguration.brushSize = 4;

        if (this.vizData?.length) {
            this.chartConfiguration.dataSource = this.getChartConfigurationDataSource();
            this.adjustDomainForOutsideData();
        }

        if (this.preferences.numberFormat || (this.preferences.decimalPlaces ?? 0) >= 0) {
            this.chartConfiguration.formatter = {
                isNumberFormatted: true,
                numberUnits: this.preferences.numberUnits,
                numberFormat: this.preferences.numberFormat,
                decimalPlaces: this.preferences.decimalPlaces,
            };
        }

        let colorRange: string[];
        if (this.preferences.configs?.slicers && this.preferences.configs.slicers.length !== 0) {
            colorRange = this.chartsSharedService.getColorRange(
                this.preferences,
                this.colorMetadataService,
                this.filterBy?.value ?? '',
                this.theme);
            this.chartConfiguration.attributeCustomColors = this.chartsSharedService.getAttributeCustomColors(
                this.preferences,
                this.filterBy?.value ?? '',
                this.theme);

            if (this.chartConfiguration.colorRange
                    && this.chartConfiguration.colorRange.length > 3
                    && this.chartConfiguration.attributeCustomColors?.length) {
                this.chartConfiguration.colorRange = this.chartsSharedService.getFilteredColorRange(this.chartConfiguration);
            }
        } else {
            colorRange = [];
            this.preferences.configs?.values.forEach((value, index) => {
                if (value.colorName) {
                    colorRange.push(this.chartsSharedService.getValueColorName(value.colorName, index));
                }
            });
        }
        if (colorRange && colorRange.length !== 0) {
            this.chartConfiguration.colorRange = colorRange;
        }

        this.chartConfiguration.showInflectionPoints = true;
        this.chartConfiguration.inflectionPointPercent = 25;
    }

    onFilterChanged(item: Partial<ConfigItem>): void {
        this.zoomValue = '';
        this.tooltipData = [];
        this.filterBy = item;
        this.initLineChart();
    }

    override onVizInfoChanged(slicer: Partial<ConfigItem>): void {
        if (this.vizInfo && this.vizInfo.slicer.value !== slicer.value) {
            this.vizInfo.slicer = slicer;
        }
    }

    initLineChart(): void {
        if (!this.preferences) {
            return console.error('cannot initLineChart with preferences');
        }

        const yAxis = this.preferences.axisConfigurations?.[1];
        const marginCondition = this.chartsSharedService.getMarginCondition(
            this.preferences.enableLegend ? this.preferences.legendPosition : undefined,
            yAxis?.position ?? '',
            true,
            !!yAxis?.displayAxisLabels);

        let bottomMargin = 30;
        if (this.preferences.bottomXAxisOrientation === 'Vertical' || this.preferences.axisConfigurations?.[0].orientation === 'Vertical') {
            bottomMargin = 65;
        } else if (this.preferences.showBrush) {
            bottomMargin = 40;
        } else if (marginCondition.legendPosition !== 'bottom') {
            bottomMargin = 25;
        }

        this.margin = {
            top: marginCondition.legendPosition !== 'top' ? 15 : 20,
            right: marginCondition.rightSideAxisFlag ? 15 * ((this.preferences.axisConfigurations?.length ?? 0) > 2 ? 2 : 1) : 35,
            bottom: bottomMargin,
            left: marginCondition.leftSideAxisFlag ? 5 : 25,
        };

        this.updateConfig();
        this.configureChart(this.filterBy?.value ?? '');
    }

    updateConfig(): void {
        if (!this.preferences || !this.configs) {
            return console.error('cannot updateConfig without a preferences and a configs');
        }

        const xField = this.configs.axisTimes?.[0].value ?? '';
        const xCustomName = this.configs.axisTimes?.[0].showCustomName ? this.configs.axisTimes[0].customName : undefined;
        const yField = this.configs.values[0].value ?? '';

        this.xAxis = [{
            type: 'date',
            position: 'bottom',
            field: [xField],
            customClass: '',
            nTicks: 10,
            customName: xCustomName,
            domain: [],
        }];

        if (this.xDomain.length) {
            this.xAxis[0].domain = this.xDomain;
        }

        this.yAxis = [
            {
                type: 'numeric',
                position: 'left',
                field: [yField],
                hide: false,
                nTicks: 10,
                customClass: '',
                autoTicks: true,
                domain: [],
            },
            {
                type: 'numeric',
                position: 'right',
                field: [yField],
                hide: false,
                nTicks: 10,
                customClass: '',
                autoTicks: true,
                domain: [],
            },
        ];

        this.lineSeries = {
            type: 'line',
            stacked: false,
            xField: [xField],
            yField: [yField],
            tooltipHTML: this.getToolTip.bind(this),
        };

        const axisConfig = this.preferences.axisConfigurations ?? [];
        this.getNewAxisConfig(this.xAxis[0], axisConfig[0]);
        this.getNewAxisConfig(this.yAxis[0], axisConfig[1]);

        if (!this.hasSlicer && this.configs.values.length > 1) {
            const yField2 = this.configs.values[1].value;
            this.lineSeries.yField.push(yField2!);
            this.xAxis[0].nTicks = 20;
            this.yAxis[1].field = [yField2!];
        }

        this.getNewAxisConfig(this.yAxis[1], axisConfig[2]);
        this.vizInfo = {
            slicer: { value: this.filterBy?.value, label: this.filterBy?.label },
            values: [{ value: this.hasSlicer ? this.yAxis[0].field[0] : '', label: '' }],
        };

        if (this.preferences.showBrush && typeof this.margin.bottom === 'number') {
            this.margin.bottom = this.margin.bottom + 30;
        }

        if (this.legendConfig?.docked === 'bottom' && typeof this.margin.bottom === 'number') {
            this.margin.bottom = this.margin.bottom + 30;
        }
    }

    getNewAxisConfig(axis: Axis, axisConfig: IAxis): void {
        axis.position = axisConfig.position;

        if (axisConfig.displayAxisLabels) {
            if (!axisConfig.enableAxis) {
                axis.customClass = 'show-only-tick-labels';
            } else {
                axis.customClass = '';
            }
        } else {
            axis.customClass = 'hide';
        }

        if (axisConfig.dataRangeMode === DataRange.MANUAL) {
            axis.domain = [axisConfig.minDataRange, axisConfig.maxDataRange];
        }

        if (axisConfig.orientation) {
            axis.rotate = axisConfig.orientation === 'horizontal' ? 0 : 270;
        }
    }

    getExportFilteredData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFilteredData();
    }

    getExportFullData(): ExportFilteredData | undefined {
        return this.vizWrapperComponent?.getExportFullData();
    }

    onMetadataUpdate(): void {}

    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data: WidgetLifecycleEvent): void;
    widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, data: WidgetLifeCycleData): void;
    widgetLifeCycleCallBack(
        eventName: WidgetLifecycleEvent | WIDGET_LIFECYCLE_EVENT.DATA_UPDATE | WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION,
        data: DataUpdateBody | WidgetLifecycleEvent | WidgetLifeCycleData,
    ): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                this.updateDataSource(data as Required<DataUpdateBody>);
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = false;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.LOADING_DATA:
                if (this.vizWrapperComponent) {
                    this.vizWrapperComponent.isDataLoading = true;
                }
                break;
            case WIDGET_LIFECYCLE_EVENT.VISUALIZATION_SELECTED:
                this.updateChartSelection(data);
        }
    }

    widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, _: unknown): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.INIT_WIDGET:
            case WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE:
                this.vizWrapperComponent?.resizeVisualization();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_MAXIMIZE:
                this.vizWrapperComponent?.onMaximize();
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_CASCADE:
                this.vizWrapperComponent?.onRestore();
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
    updateChartSelection(data: any): void {
        this.tooltipData = [];
        if (this.isChartInitialized) {
            const selected = data.selectedItem ? data.selectedItem.key || data.selectedItem.data.key : null;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const hiddenLegends = selected && data.dataSource ? data.dataSource.reduce((arr: any[], source: any) => {
                if (source.key !== selected) {
                    arr.push(source.key);
                }
                return arr;
            }, []) : [];

            this.chartConfiguration = clone(this.chartConfiguration, {
                highlight: { data: data.selectedItem },
                hiddenLegends,
            });
        }
    }

    onRangeClicked(value: { label: string }): void {
        this.zoomValue = value.label;
        switch (this.zoomValue) {
            case '1D':
            case '1M':
            case '3M':
            case '6M':
                this.vizWrapperComponent?.resizeBrush({ notation: this.zoomValue[1], scale: Number(this.zoomValue[0]) });
                break;
            case '12M':
                this.vizWrapperComponent?.resizeBrush({ notation: this.zoomValue[2], scale: Number(`${this.zoomValue[0]}${this.zoomValue[1]}`) });
                break;
            case 'YTD':
            case 'ALL':
                this.vizWrapperComponent?.resizeBrush({ notation: this.zoomValue });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateDataSource(componentData: { data: any[], filters: DashboardClientQueryParam, widgetPrefs?: AppWidgetState }): void {
        if (componentData.filters.startDate && componentData.filters.endDate && this.fuzzyDates) {
            const startDate = this.getDate(componentData.filters.startDate, this.fuzzyDates.from);
            const endDate = this.getDate(componentData.filters.endDate, this.fuzzyDates.to);
            this.xDomain = [startDate, endDate];
        }

        this.vizData = deepClone(componentData.data);
        this.tooltipData = [];
        if (this.vizData.length) {
            const usedTimeColumnName = this.preferences?.configs?.axisTimes?.[0].value ?? '';

            this.vizData.forEach((d) => {
                d[usedTimeColumnName] = this.chartsSharedService.deserializeDate(d[usedTimeColumnName]);
            });
            this.vizData = this.chartsSharedService.sortDataOnDateValue(this.vizData, usedTimeColumnName);
        }

        if (this.isChartInitialized) {
            this.zoomValue = '';
            const dataSource = this.vizData.length ? this.getChartConfigurationDataSource() : [];

            this.preferences?.axisConfigurations.slice(1).forEach((axisConfig, index) => {
                if (axisConfig.dataRangeMode === DataRange.MANUAL) {
                    this.yAxis[index].domain = [axisConfig.minDataRange, axisConfig.maxDataRange];
                }
            });

            if (this.xDomain.length && this.chartConfiguration) {
                this.chartConfiguration.axis[0][0].domain = this.xDomain;
            }

            this.chartConfiguration = clone(this.chartConfiguration, { dataSource });

            this.adjustDomainForOutsideData();
        }
    }

    protected getToolTip({ tooltipValue, tooltipColors }: TooltipParams): string {
        if (!this.lineSeries) {
            return 'cannot get tool tip without a line series';
        }

        if (!this.tooltipData.length) {
            this.tooltipData = this.chartsSharedService.getLineAndAreaDataSource(
                this.vizData,
                this.filterBy?.value ?? '',
                this.lineSeries,
                this.preferences?.configs?.tooltips ?? [],
                false);
        }
        return this.chartsSharedService.getLineTooltip(
            tooltipValue,
            tooltipColors,
            this.tooltipData,
            this.configs?.tooltips ?? [],
            this.lineSeries,
            this.filterBy?.value ?? '');
    }

    private setVizSlicer(): void {
        const vizInfoSlicer = this.vizInfo?.slicer;

        this.hasSlicer = !!vizInfoSlicer?.label || !!this.configs?.slicers.length;
        if (!this.hasSlicer) {
            return;
        }

        if (vizInfoSlicer?.label) {
            this.setSlicerAttributes(vizInfoSlicer);
            this.vizSlicer.next(vizInfoSlicer);
        } else {
            const slicers = this.configs?.slicers ?? [];
            const selectedSlicer = getDefault(slicers, { colId: '', label: '', value: '' } as ConfigItem);
            this.vizSlicer.next({
                label: selectedSlicer?.label,
                value: selectedSlicer?.value,
                showCustomName: selectedSlicer?.showCustomName,
                customName: selectedSlicer?.customName,
                colorSortBy: selectedSlicer?.colorSortBy,
                colorSortDirection: selectedSlicer?.colorSortDirection,
            });
        }
    }

    private setSlicerAttributes(slicer: Partial<ConfigItem>): void {
        this.configs?.slicers.forEach((s) => {
            if (this.isDifferentSlicerName(slicer, s)) {
                slicer.showCustomName = s.showCustomName;
                slicer.customName = s.customName;
                slicer.colorSortBy = s.colorSortBy;
                slicer.colorSortDirection = s.colorSortDirection;
            }
        });
    }

    private isDifferentSlicerName(slicer: Partial<ConfigItem>, configSlicer: ConfigItem): boolean {
        return slicer.value === configSlicer.value &&
            (slicer.customName !== configSlicer.customName || slicer.showCustomName !== configSlicer.showCustomName);
    }

    private adjustDomainForOutsideData(): void {
        if (!this.chartConfiguration) {
            return;
        }

        const data = this.chartConfiguration.dataSource ?? [];
        const currentDomain = this.chartConfiguration.axis?.[0][0].domain ?? [];
        if (!data.length || !currentDomain.length) {
            return;
        }

        const timeColumn = this.preferences?.configs?.axisTimes?.[0].value ?? '';

        this.chartConfiguration.axis[0][0].domain[0] = Math.min(data[0][timeColumn], currentDomain[0]);
        this.chartConfiguration.axis[0][0].domain[1] = Math.max(data[data.length - 1][timeColumn], currentDomain[1]);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getChartConfigurationDataSource(): any[] {
        if (!this.lineSeries) {
            return [];
        }

        const values = this.preferences?.configs?.values ?? [];
        return this.chartsSharedService.getLineAndAreaDataSource(this.vizData, this.filterBy?.value ?? '', this.lineSeries, values);
    }

    private getDate(date: string, fuzzyDates: FuzzyDate[]): Date | undefined {
        return parseInt(date, 10) ?
            new Date(date) :
            fuzzyDates.find((fd) => fd.name.toLocaleUpperCase() === date?.toLocaleUpperCase())?.actualDate;
    }
}
