import { AlertService } from '@ddv/common-components';
import { ErrorResponseDetail, ResponseInterceptor } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import { MANAGE_WIDGET_ID, SYSTEM_ERROR_MESSAGE, WIDGET_LIFECYCLE_EVENT } from '@ddv/models';

export class ErrorHandlingResponseInterceptor implements ResponseInterceptor {
    constructor(
        private readonly managerService: ManagerService,
        private readonly alertService: AlertService,
    ) {}

    handleException(response: ErrorResponseDetail): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const exception = response.body as any;
        switch (response.statusCode) {
            case 401:
                window.location.href = JSON.parse(exception).redirectURL;
                break;
            case 403:
                if (!Object.prototype.hasOwnProperty.call(exception ?? {},'dashboards')) {
                    this.alertService.error(SYSTEM_ERROR_MESSAGE, `Response Status Code: ${response.statusCode}`, undefined, undefined);
                }
                break;
            case 500:
                if (!exception) {
                    this.alertService.error(
                        'An error with no details has occurred.  Sorry',
                        '',
                        [],
                        'unknown request id');
                } else if (exception.module === 'WIDGET') {
                    if (this.managerService.isManagingWidget()) {
                        this.managerService.sendMessageToExistingWidget(
                            MANAGE_WIDGET_ID,
                            { action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED, exception });
                    } else if (exception.id) {
                        const widgetState = this.managerService.getWidgetState(exception.id);
                        if (widgetState) {
                            this.managerService.sendMessageToExistingWidget(
                                exception.id,
                                { action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED, exception });
                        }
                    }
                } else {
                    this.alertService.error(
                        exception.clientFacingMessage,
                        exception.rootCauseMessage,
                        exception.stackTrace ? exception.stackTrace.split('\n') : [],
                        exception.requestId);
                }
                break;
            default:
                this.alertService.error(SYSTEM_ERROR_MESSAGE, `Response Status Code: ${response.statusCode}`, undefined, undefined);
        }
    }
}
