export function compareSortOptions(a: string | number | null, b: string | number | null): number {
    if (a == null || a === '') {
        return -1;
    }

    if (b == null || b === '') {
        return 1;
    }

    if (!isNaN(a as number) && !isNaN(b as number)) {
        return (a as number) - (b as number);
    }

    return (a as string).localeCompare(b as string);
}
