import { DdvDate } from '@ddv/models';

export function safeStringFormatter(term: string | null | Date | undefined | number | boolean): string {
    if (term == null) {
        return '';
    } else if (term instanceof Date) {
        return new DdvDate(term.getFullYear(), term.getMonth(), term.getDate()).toUSFormat();
    } else if (/^\d{4}-[01]?\d-[0123]?\d$/.test(term as string)) {
        return DdvDate.fromDashFormat(term as string).toUSFormat();
    } else if (typeof term === 'string') {
        return term;
    } else {
        return term.toString();
    }
}
