import { ConfigItem } from './config-item';

export class VizConfigs {
    values: ConfigItem[] = [];
    slicers: ConfigItem[] = [];
    tooltips: ConfigItem[] = [];
    axisTimes?: ConfigItem[];
    details?: ConfigItem[];
    mirroredValues?: ConfigItem[];

    public constructor(init?: Partial<VizConfigs>) {
        Object.assign(this, init);

        this.populateNameValue?.(this.values);
        this.populateNameValue?.(this.slicers);
        this.populateNameValue?.(this.tooltips);
        this.populateNameValue?.(this.axisTimes);
        this.populateNameValue?.(this.details);
        this.populateNameValue?.(this.mirroredValues);
    }

    public getFlatList?(): ConfigItem[] {
        return this.values.concat(this.slicers, this.tooltips, this.axisTimes ?? [], this.details ?? [], this.mirroredValues ?? []);
    }

    public hasValueConfigForColumn?(columnId: string): boolean {
        return this.values.some((entry) => entry.name === columnId);
    }

    // Until we decide if we like "name" or "value" we need
    // to make sure the same thing is in both places
    private populateNameValue?(configs?: ConfigItem[]): void {
        configs?.forEach((config) => {
            config.name = config.name ?? config.value;
            config.value = config.name ?? config.value;
        });
    }
}
