import { DdvDate } from '@ddv/models';

export enum HiDateStatus { ALL, PARTIAL, NONE }

export class HiDate {
    date: DdvDate = DdvDate.empty;
    numPosted: number | undefined;
    numTotal: number | undefined;

    get status(): HiDateStatus {
        return this.numPosted === 0 ? HiDateStatus.NONE : this.numPosted === this.numTotal ? HiDateStatus.ALL : HiDateStatus.PARTIAL;
    }

    constructor(init?: Partial<HiDate>) {
        Object.assign(this, init);
    }
}
