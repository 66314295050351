import { NgModule } from '@angular/core';
import { EntitlementsModule } from '@ddv/entitlements';

import { LayoutService } from './service/layout.service';
import { ManagerService } from './service/manager.service';

@NgModule({
    imports: [
        EntitlementsModule,
    ],
    providers: [
        LayoutService,
        ManagerService,
    ],
})
export class LayoutModule {}
