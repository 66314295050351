import { Injectable } from '@angular/core';
import { ParsedRoute, ParsedRouteService } from '@ddv/navigation';

import { WrapperAppService } from './wrapper-app.service';

@Injectable({ providedIn: 'root' })
export class WrapperAppBindingService {
    constructor(
        private readonly parsedRouteService: ParsedRouteService,
        private readonly wrapperAppService: WrapperAppService,
    ) {}

    startup(): void {
        if (!this.wrapperAppService.isWrapped()) {
            return;
        }

        console.warn('DDV is running inside the wrapper app.  Binding route changes');
        this.parsedRouteService.parsedRoute.subscribe((route) => this.handleRouteChange(route));
    }

    private handleRouteChange(route: ParsedRoute): void {
        this.wrapperAppService.syncStateToWrapper(route.clientCode, route.section, route.action, route.entityId);
    }
}
