import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ChartData } from '../models/chart-data';

@Injectable({ providedIn: 'root' })
export class ChartDataRelayService {
    private readonly chartDataSubject: BehaviorSubject<ChartData[]> = new BehaviorSubject<ChartData[]>([]);
    public readonly chartData: Observable<ChartData[]>;

    constructor() {
        this.chartData = this.chartDataSubject.asObservable();
    }

    emitChartData(chartData: ChartData[]): void {
        this.chartDataSubject.next(chartData);
    }
}
