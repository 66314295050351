import { Injectable, ComponentRef } from '@angular/core';

import { ModalDialogOverlayComponent } from './modal-dialog-overlay.component';
import { ModalDialogContentRef } from './modal-dialog-utils';
import { ModalDialogWindowComponent } from './modal-dialog-window.component';

@Injectable()
export class ModalDialogActive {
    close(_?: unknown): void {}
    dismiss(_?: unknown): void {}
}

@Injectable()
export class ModalDialogRef {
    private resolve?: (_?: unknown) => void;
    private reject?: (_?: unknown) => void;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get componentInstance(): any | undefined {
        if (this.contentRef.componentRef) {
            return this.contentRef.componentRef.instance;
        }

        return;
    }

    // only needed to keep TS1.8 compatibility
    set componentInstance(instance: unknown) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: Promise<any>;

    constructor(
        private readonly windowCompRef: ComponentRef<ModalDialogWindowComponent>,
        private readonly contentRef: ModalDialogContentRef,
        private readonly overlayCompRef?: ComponentRef<ModalDialogOverlayComponent>,
    ) {
        windowCompRef.instance.dismissEvent.subscribe((reason: unknown) => {
            this.dismiss(reason);
        });

        this.result = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        this.result.then(null, () => {});
    }

    close(result?: unknown): void {
        if (this.windowCompRef) {
            this.resolve?.(result);
            this.removeDialogElements();
        }
    }

    dismiss(reason?: unknown): void {
        if (this.windowCompRef) {
            this.reject?.(reason);
            this.removeDialogElements();
        }
    }

    private removeDialogElements(): void {
        const windowEl = this.windowCompRef.location.nativeElement;
        windowEl.parentNode.removeChild(windowEl);
        this.windowCompRef.destroy();

        if (this.overlayCompRef) {
            const overlayEl = this.overlayCompRef.location.nativeElement;
            overlayEl.parentNode.removeChild(overlayEl);
            this.overlayCompRef.destroy();
        }

        if (this.contentRef?.viewRef) {
            this.contentRef.viewRef.destroy();
        }

        // this is dangerous and stupid.  those properties are injected as readonly by angular DI
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).windowCompRef = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).overlayCompRef = null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).contentRef = null;
    }
}
