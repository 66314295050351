import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorsModule } from '@ddv/behaviors';
import { CommonComponentsModule } from '@ddv/common-components';
import { DatasetDefinitionsModule } from '@ddv/dataset-definitions';
import { LibSvgIconsModule } from '@ddv/svg-icons';
import { CheckboxComponent, OptionToggleComponent } from '@hs/ui-core-inputs';
import { IconComponent } from '@hs/ui-core-presentation';

import { ColorChartComponent } from './color-chart/color-chart.component';
import { ColumnFormattingGridComponent } from './column-formatting-grid/column-formatting-grid.component';
import { ColumnFormattingSummaryComponent } from './column-formatting-summary/column-formatting-summary.component';
import { ColumnFormattingTooltipComponent } from './column-formatting-tooltip/column-formatting-tooltip.component';
import { ConfigureChartsComponent } from './configure-charts/configure-charts.component';
import { ConfigureGridComponent } from './configure-grid/configure-grid.component';
import { ConfigureSummaryComponent } from './configure-summary/configure-summary.component';
import { DynamicComponent } from './dynamic.component';
import { FieldPickerTreeComponent } from './field-picker-tree/field-picker-tree.component';
import { FormatBarComponent } from './format-bar/format-bar.component';
import { FormatStackedAreaChartComponent } from './format-stacked-area-chart/format-stacked-area-chart.component';
import { FormatSummaryComponent } from './format-summary/format-summary.component';
import { FormatVizComponent } from './format-viz/format-viz.component';
import { MWLeftNavComponent } from './manage-widgets-left-nav/manage-widgets-left-nav.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { VisualizationNavHeaderComponent } from './visualization-nav-header/visualization-nav-header.component';

@NgModule({
    imports: [
        BehaviorsModule,
        CommonComponentsModule,
        CommonModule,
        DatasetDefinitionsModule,
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        LibSvgIconsModule,
        CheckboxComponent,
        IconComponent,
        OptionToggleComponent,
    ],
    providers: [],
    declarations: [
        ColorChartComponent,
        ColumnFormattingGridComponent,
        ColumnFormattingSummaryComponent,
        ColumnFormattingTooltipComponent,
        ConfigureChartsComponent,
        ConfigureGridComponent,
        ConfigureSummaryComponent,
        DynamicComponent,
        FieldPickerTreeComponent,
        FormatBarComponent,
        FormatStackedAreaChartComponent,
        FormatSummaryComponent,
        FormatVizComponent,
        MWLeftNavComponent,
        MultiSelectComponent,
        VisualizationNavHeaderComponent,
    ],
    exports: [
        MWLeftNavComponent,
    ],
})
export class LeftNavModule {}
