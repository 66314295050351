import { Component, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-custom-autocompleter',
    templateUrl: './custom-autocompleter.component.html',
    styleUrls: ['./custom-autocompleter.component.scss'],
})
export class CustomAutoCompleterComponent {
    @Input() config: CustomAutoCompleteConfig | undefined;
    @Input() showDropdown = false;
    dropdownList: { id: string, name: string }[] = [];
    selectedList: { name: string }[] = [];
    searchParam: string | undefined;
    isDisabled = false;
    isReadOnly = false;
    inputPattern = /^[a-z0-9]+$/i;
    private searchTimer: ReturnType<typeof setTimeout> | undefined;

    @Output() itemSelected = new EventEmitter<boolean>();

    @ViewChild('inputSearch', { static: true }) elementRef: ElementRef | undefined;

    setDropdownList(): void {
        this.searchParam = this.searchParam ? this.searchParam.trim() : this.searchParam;
        this.dropdownList = [];
        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
            if (this.searchParam) {
                this.config?.getSourceFn(this.searchParam).subscribe((data) => {
                    this.populateDropdownData(data);
                });
            }
        }, 500);
    }

    selectItem(event: MouseEvent, item: unknown, isNew?: boolean): void {
        event.stopPropagation();
        const selectedItem = (isNew ? { name: item } : item) as { name: string };
        if (selectedItem.name && !this.isTagPresent(selectedItem.name)) {
            this.selectedList.push(selectedItem);
            this.itemSelected.emit();
        }
        this.searchParam = '';
        this.dropdownList = [];
        this.showDropdown = false;
    }

    removeTag(event: MouseEvent, index: number): void {
        event.stopPropagation();
        this.selectedList.splice(index, 1);
    }

    setSearchFocus(): void {
        this.elementRef?.nativeElement.focus();
    }

    private isTagPresent(name: string): boolean {
        return this.selectedList.some((item) => item.name.toLocaleLowerCase() === name.toLocaleLowerCase());
    }

    private populateDropdownData(tagLists: { id: string, name: string }[]): void {
        this.dropdownList = tagLists.map((item) => ({
            id: item.id,
            name: item.name,
        }));
        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
        }
    }
}

export interface CustomAutoCompleteConfig {
    placeholderText: string;
    suggestionText: string;
    getSourceFn(searchTerm: string): Observable<{ id: string, name: string }[]>;
}
