import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, NgModel, AbstractControl, ValidatorFn } from '@angular/forms';
import { ColorMetadataService } from '@ddv/charts';
import { CustomFormValidators, DropdownOption, ModalDialogActive } from '@ddv/common-components';
import {
    CellColors,
    CellFormat,
    FormatOperator,
    LabelValuePair,
    MetadataFormatUtils,
    AGG_TYPES_BY_SECTION_AND_DISPLAY,
    ConfigItem,
} from '@ddv/models';
import { dateFormat, isUrlValid } from '@ddv/utils';
import { WidgetsService } from '@ddv/widgets';
import { Theme, ThemeService } from '@hs/ui-core-presentation';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-grid-column-formatting',
    templateUrl: './column-formatting-grid.component.html',
    styleUrls: ['./column-formatting-grid.component.scss'],
    providers: [NgModel],
})
export class ColumnFormattingGridComponent implements OnInit, OnDestroy, AfterViewChecked {
    parentData: ConfigItem | undefined;
    configForm: UntypedFormGroup | undefined;
    valueOperators: DropdownOption[] | undefined;
    displayTypes: DropdownOption[] | undefined;
    dateFormats: DropdownOption[] = [];
    aggTypes: DropdownOption[] | undefined;
    fontStyles: DropdownOption[] | undefined;
    numberFormats: DropdownOption[] | undefined;
    alignmentList: DropdownOption[] | undefined;
    barColors: LabelValuePair<string>[] = [];
    cellAndFontColors: CellColors[] = [];
    rankOrders: DropdownOption[] = [];
    negativeValues: DropdownOption[] = [];
    fuzzyDates: DropdownOption[] = [];
    acceptedDateFormat = dateFormat;
    dateRegex = '^\\d{0,2}[\\/]{0,1}\\d{0,2}[\\/]{0,1}\\d{0,4}$';
    numberRegex = '^-?\\d*(\\.\\d*)?$';
    barPercentRegex = '^(\\d{1,2}(\\.\\d*)?|100)$';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columnConditionVizTab = [{ isViz: true, vizTabs: [] as any[], isColumnGrid: true }];
    activeColumnConditionTabIndex = -1;
    currentGuid = 0;
    currentDisplayType: string | undefined;
    allColorsMap: Map<string, { [key: string]: string }> | undefined;

    linkBehavior: DropdownOption[] = [
        {
            text: 'Select / Clear selection',
            key: null,
            value: null,
        },
        {
            text: 'Open in Same Window',
            key: '_self',
            value: '_self',
        },
        {
            text: 'Open in New Window',
            key: '_blank',
            value: '_blank',
        },
        {
            text: 'Master/Detail',
            key: 'masterdetail',
            value: 'masterdetail',
        },
    ];
    linkType: DropdownOption[] = [
        {
            text: 'Select / Clear selection',
            key: null,
            value: null,
        },
        {
            text: 'URL',
            key: 'url',
            value: 'url',
        },
        {
            text: 'DDV Widget',
            key: 'ddvwidget',
            value: 'ddvwidget',
        },
    ];
    linkCrossRef: Record<string, string[]> = {
        ddvwidget: ['masterdetail'],
        masterdetail: ['ddvwidget'],
        url: ['_self', '_blank'],
        _blank: ['url'],
        _self: ['url'],
    };
    linkBehaviorFiltered: DropdownOption[] | undefined;
    linkTypeFiltered: DropdownOption[] | undefined;
    ddvWidgets: DropdownOption[] = [];
    linkBehaviorSelected: DropdownOption | undefined;
    linkTypeSelected: DropdownOption | undefined;
    private themeSubscription: Subscription | undefined;

    constructor(
        public modalDialogActive: ModalDialogActive,
        private readonly fb: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly widgetsService: WidgetsService,
        private readonly themeService: ThemeService,
    ) { }

    ngOnInit(): void {
        const parentData = this.parentData;
        if (!parentData) {
            return console.error('cannot init without parentData');
        }

        this.linkBehaviorFiltered = this.linkBehavior;
        this.linkTypeFiltered = this.linkType;
        this.linkBehaviorSelected = this.linkBehavior[0];
        this.linkTypeSelected = this.linkType[0];

        this.themeSubscription = this.themeService.currentTheme$.subscribe((theme: Theme) => {
            this.currentDisplayType = parentData.displayType;
            this.displayTypes = MetadataFormatUtils.getDisplayTypes(parentData.displayType ?? '').map((t) => {
                return { text: t.label, key: t.value, value: t.value };
            });
            this.dateFormats = MetadataFormatUtils.getDateFormats().map((t) => {
                return { text: t, key: t, value: t };
            });
            this.cellAndFontColors = ColorMetadataService.getCellAndFontColors(theme);

            this.aggTypes = (AGG_TYPES_BY_SECTION_AND_DISPLAY.grid[parentData.displayType ?? 'value']).map((t: LabelValuePair<string>) => {
                return { text: t.label, key: t.value, value: t.value };
            });
            this.fontStyles = MetadataFormatUtils.getFonts().map((t) => {
                return { text: t.label, key: t.value, value: t.value };
            });
            this.numberFormats = MetadataFormatUtils.getNumberFormats().map((t) => {
                return { text: t.label, key: t.value, value: t.value };
            });
            this.alignmentList = MetadataFormatUtils.getAlignments().map((t) => {
                return { text: t.label, key: t.value, value: t.value };
            });
            this.negativeValues = MetadataFormatUtils.getNegativeValues().map((v) => {
                return { text: v.label, key: v.value, value: v.value, css: v.value.startsWith('red') ? 'redcolor' : 'blackcolor' };
            });
            this.barColors = ColorMetadataService.getColors(theme, true);
            this.rankOrders = MetadataFormatUtils.getRankOrders().map((t) => {
                return { text: t.label, key: t.value, value: t.value };
            });
            this.allColorsMap = this.createColorsMap();
            this.fuzzyDates = MetadataFormatUtils.getDateOccurringValues().map((d) => {
                return { text: d.label, key: d.value, value: d.value, css: d.value };
            });
            this.fetchWidgets();
            this.updateValueOperators(this.parentData?.displayType);
            this.initForm(parentData);
            if (this.columnConditionVizTab[0].vizTabs.length !== 0) {
                // eslint-disable-next-line prefer-spread
                this.currentGuid = Math.max.apply(Math, this.columnConditionVizTab[0].vizTabs.map((vizTab) => vizTab.guid));
            }
        });
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.themeSubscription?.unsubscribe();
    }

    closeGrid(configData: ConfigItem): void {
        if (!configData.linkBehavior || !configData.linkType) {
            configData.hyperlink = undefined;
        }

        configData.columnCondition?.forEach((item, index) => item.guid = index + 1);

        this.modalDialogActive.close(configData);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onCellFontColorSelected(ctx: UntypedFormGroup, cellKey: any, fontKey: any, item: any, el?: HTMLDivElement): void {
        ctx.get(cellKey)?.setValue(item.cellColor);
        ctx.get(fontKey)?.setValue(item.fontColor);
        if (el) {
            el.hidden = true;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onColorSelected(ctx: UntypedFormGroup, key: any, item: any, el: HTMLDivElement): void {
        ctx.get(key)?.setValue(item.value);
        el.hidden = true;
    }

    addConditionalFormatters(): void {
        if (!this.configForm) {
            return;
        }

        const ctrl = this.configForm.get('columnCondition') as UntypedFormArray;
        ctrl.controls.forEach((element) => element.get('active')?.setValue(false));
        this.columnConditionVizTab[0].vizTabs.forEach((tab) => tab.active = false);
        ctrl.push(this.initColumnCondition({
            active: true,
            operatorCondition: this.valueOperators?.[0].value,
            fontColor: this.cellAndFontColors[0].fontColor,
            cellColor: this.cellAndFontColors[0].cellColor,
            barColor: this.barColors[0].value,
            guid: this.currentGuid + 1,
        }, this.configForm.get('displayType')!.value));

        this.addColumnConditionVizTab(this.currentGuid + 1, true);
        this.activeColumnConditionTabIndex = this.currentGuid + 1;
        this.currentGuid += 1;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    removeCNFormattingAtIndex(tab: any): void {
        const ctrl = this.configForm?.get('columnCondition') as UntypedFormArray;
        const index = ctrl.controls.findIndex((element) => element.get('guid')?.value === tab.guid);
        ctrl.controls.splice(index, 1);
        // VisualizationNavHeaderComponent is removing the tab so not removing from this.columnConditionVizTab[0].vizTabs.
        if (ctrl.controls.length) {
            const activeColumnConditionIndex = ctrl.controls.find((formatter) => !!formatter.get('active')?.value) ?
                ctrl.controls.find((formatter) => !!formatter.get('active')?.value)?.get('guid')?.value :
                this.columnConditionVizTab[0].vizTabs[0].guid;

            ctrl.controls.find((element) => element.value.guid === activeColumnConditionIndex)?.get('active')?.setValue(true);
            this.columnConditionVizTab[0].vizTabs.forEach((vizTab) => vizTab.active = vizTab.guid === activeColumnConditionIndex);
            this.activeColumnConditionTabIndex = activeColumnConditionIndex;
        } else {
            ctrl.reset();
        }
    }

    onTabClicked(tabIndex: number): void {
        const ctrl = this.configForm?.get('columnCondition') as UntypedFormArray;
        ctrl.controls.forEach((element, index) => {
            element.get('active')?.setValue(false);
            if (!element.value.guid) {
                element.get('guid')?.setValue(index + 1);
            }
        });
        ctrl.controls.find((element) => element.value.guid === tabIndex)?.get('active')?.setValue(true);
    }

    showInputRange(selectedOperator: FormatOperator): boolean {
        const operator = this.valueOperators?.find((op) => op.value === selectedOperator);
        return operator ? !!operator.showInputRange : true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onDisplayTypeChanged(e: any): void {
        this.configForm?.get('displayType')?.setValue(e.value);
        const colCondns = this.configForm?.get('columnCondition') as UntypedFormArray;
        colCondns.controls.splice(0, colCondns.length);
        colCondns.reset();
        this.columnConditionVizTab[0].vizTabs.splice(0, this.columnConditionVizTab[0].vizTabs.length);
        this.updateValueOperators();
        if (this.configForm?.get('displayType')?.value === 'bar') {
            this.addConditionalFormatters();
        }
        this.currentDisplayType = this.configForm?.get('displayType')?.value;
    }

    updateValueOperators(displayType?: string): void {
        this.valueOperators = MetadataFormatUtils.getOperators(displayType ?? this.configForm?.get('displayType')?.value)
            .map((operator) => {
                const valueOperator: DropdownOption = { text: operator.label, key: operator.value, value: operator.value };
                if (operator.showInputRange) {
                    valueOperator.showInputRange = operator.showInputRange;
                }
                return valueOperator;
            });
    }

    onOperatorConditionChanged(colCondition: UntypedFormGroup): void {
        colCondition.get('value')?.setValue(colCondition.get('operatorCondition')?.value === 'ADO' ? this.fuzzyDates[0].value : null);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onBarConditionChange(colCondition: UntypedFormGroup, e: any): void {
        colCondition.get('operatorCondition')?.setValue(e.key);
        if (colCondition.get('operatorCondition')?.value === 'AUTO') {
            colCondition.get('rangeFrom')?.disable();
            colCondition.get('rangeTo')?.disable();
        } else {
            colCondition.get('rangeFrom')?.enable();
            colCondition.get('rangeTo')?.enable();
        }
    }

    toggleCustomNameField(): void {
        if (this.configForm?.controls.showCustomName.value) {
            this.configForm.controls.customName.enable();
        } else {
            this.configForm?.controls.customName.disable();
        }
    }

    onNavTabFocus(tabData: { vizTab: CellFormat, tabIndex: number }): void {
        this.activeColumnConditionTabIndex = tabData.vizTab.guid ?? -1;
        this.onTabClicked(this.activeColumnConditionTabIndex);
    }

    validateUrl(): void {
        const hyperlink = this.configForm?.get('hyperlink')?.value;
        if (hyperlink && !isUrlValid(hyperlink)) {
            this.configForm?.get('hyperlink')?.setErrors({ urlIncorrect: true });
        }
    }

    touchedOrDirty(field?: string): boolean | undefined {
        return !field ? false : !!this.configForm?.get(field)?.touched || !!this.configForm?.get(field)?.dirty;
    }

    updateLinks(selectedOption?: DropdownOption): void {
        if (!selectedOption) {
            return;
        }

        if (selectedOption.value == null) {
            this.linkBehaviorSelected = selectedOption;
            this.linkTypeSelected = selectedOption;
            this.linkBehaviorFiltered = this.linkBehavior;
            this.linkTypeFiltered = this.linkType;
            this.configForm?.get('linkBehavior')?.setValue(null);
            this.configForm?.get('linkType')?.setValue(null);
            return;
        }

        const crossRef = this.linkCrossRef[selectedOption.value as string];
        switch (selectedOption.value) {
            case 'url':
            case 'ddvwidget':
                this.linkTypeSelected = selectedOption;
                this.linkTypeFiltered = this.linkType;
                this.linkBehaviorFiltered = this.linkBehavior.filter((link) => crossRef.indexOf(link.value) !== -1 || link.value == null);
                if (this.linkBehaviorSelected?.value == null ||
                    this.linkBehaviorFiltered.map((item) => item.value).indexOf(this.linkBehaviorSelected.value) === -1) {
                    this.linkBehaviorSelected = this.linkBehaviorFiltered[1];
                }
                this.configForm?.get('linkBehavior')?.setValue(this.linkBehaviorSelected.value);
                this.configForm?.get('linkType')?.setValue(this.linkTypeSelected.value);
                break;
            case '_self':
            case '_blank':
            case 'masterdetail':
                this.linkBehaviorSelected = selectedOption;
                this.linkBehaviorFiltered = this.linkBehavior;
                this.linkTypeFiltered = this.linkType.filter((link) => crossRef.indexOf(link.value) !== -1 || link.value == null);
                if (this.linkTypeSelected?.value == null ||
                    this.linkTypeFiltered.map((item) => item.value).indexOf(this.linkTypeSelected.value) === -1) {
                    this.linkTypeSelected = this.linkTypeFiltered[1];
                }
                this.configForm?.get('linkType')?.setValue(this.linkTypeSelected.value);
                this.configForm?.get('linkBehavior')?.setValue(this.linkBehaviorSelected.value);

                // eslint-disable-next-line no-case-declarations
                const hyperlink = this.configForm?.get('hyperlink')?.value;
                if (hyperlink && !isUrlValid(hyperlink)) {
                    this.configForm?.get('hyperlink')?.setValue(null);
                }
        }
    }

    getColumnConditionControls(): UntypedFormGroup[] {
        // the real type of controls is AbstractControl[]
        // but other things start screaming down the road
        return (this.configForm?.get('columnCondition') as UntypedFormArray).controls as UntypedFormGroup[];
    }

    private addColumnConditionVizTab(guid: number, active: boolean): void {
        this.columnConditionVizTab[0].vizTabs.push(
            {
                guid,
                active,
                cssId: '',
                selector: '',
                label: `CN ${guid}`,
            },
        );
    }

    private initForm(data: ConfigItem): void {
        this.configForm = this.fb.group(
            {
                colId: [data.colId],
                value: [data.value],
                customName: [data.customName],
                showCustomName: [data.showCustomName],
                aggregationType: [data.aggregationType],
                displayType: [data.displayType],
                dateFormat: [data.dateFormat ?? 'MM/DD/YYYY'],
                fontStyle: [data.fontStyle],
                numberFormat: [data.numberFormat],
                alignment: [data.alignment],
                decimalPlaces: [data.decimalPlaces],
                negativeValue: [data.negativeValue],
                columnCondition: this.fb.array(data.columnCondition?.map((item) => this.initColumnCondition(item, data.displayType ?? '')) ?? []),
                isRanked: [data.isRanked],
                isBlank: [data.isBlank],
                useAbsVal: [data.useAbsVal],
                rankOrder: [data.rankOrder ?? 'POSITIVE'],
                hyperlink: [data.hyperlink],
                linkBehavior: [data.linkBehavior],
                linkType: [data.linkType],
                ddvWidget: [data.ddvWidget],
            },
            {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                validator: (group: any) => {
                    if (group.get('showCustomName').value) {
                        group.controls.customName.setValidators(Validators.required);
                    } else {
                        group.controls.customName.clearValidators();
                    }

                    if (group.get('linkType').value === 'ddvwidget' && !group.get('ddvWidget').value) {
                        group.get('ddvWidget').setErrors({ ddvWidgetInvalid: true });
                    }
                },
            },
        );

        if (data.linkBehavior) {
            this.linkBehaviorSelected = this.linkBehavior.find((item) => item.value === data.linkBehavior);
        }
        if (data.linkType) {
            this.linkTypeSelected = this.linkType.find((item) => item.value === data.linkType);
        }

        if (data.hyperlink && (!data.linkBehavior || !data.linkType)) {
            if (!data.linkBehavior && !data.linkType) {
                this.linkBehaviorSelected = this.linkBehavior[1];
                this.linkTypeSelected = this.linkType[1];
                this.configForm.get('linkBehavior')?.patchValue(this.linkBehaviorSelected.value);
                this.configForm.get('linkType')?.patchValue(this.linkTypeSelected.value);
            } else if (!data.linkBehavior) {
                this.updateLinks(this.linkTypeSelected);
            } else if (!data.linkType) {
                this.updateLinks(this.linkBehaviorSelected);
            }
        }

        // To load the column condition tabs on dialog open
        (this.configForm.get('columnCondition') as UntypedFormArray).controls.forEach((columnCondition, index) => {
            this.addColumnConditionVizTab(index + 1, columnCondition.get('active')?.value);
        });
        this.toggleCustomNameField();
    }

    private initColumnCondition(data: CellFormat, displayType: string): UntypedFormGroup {
        return this.fb.group(
            {
                operatorCondition: [data.operatorCondition],
                fontColor: [data.fontColor],
                cellColor: [data.cellColor],
                barColor: [data.barColor],
                type: [data.type],
                value: [data.value],
                rangeFrom: [data.rangeFrom],
                rangeTo: [data.rangeTo],
                active: [!!data.active],
                guid: [data.guid],
            },
            {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                validator: (group: any) => {
                    const rangeFromControl = group.get('rangeFrom');
                    const rangeToControl = group.get('rangeTo');
                    const valueControl = group.get('value');
                    rangeFromControl.clearValidators();
                    rangeToControl.clearValidators();
                    valueControl.clearValidators();
                    const operatorCondition = group.get('operatorCondition').value;
                    const isInputRange = this.showInputRange(operatorCondition);
                    const validators = this.getValidators(displayType, operatorCondition, isInputRange, rangeFromControl, rangeToControl);
                    if (isInputRange) {
                        rangeFromControl.setValidators(validators);
                        rangeToControl.setValidators(validators);
                        // valueControl validator is cleared in this case
                        // but there is a pending error on it from previous state
                        // that prevents the form from becoming valid
                        valueControl.setErrors(null);
                    } else if (displayType !== 'bar' && !isInputRange) {
                        if (this.operatorIsEqualOrNotEqual(operatorCondition)) {
                            valueControl.setErrors(null);
                        } else if (valueControl.value == null || valueControl.value === '') {
                            valueControl.setErrors({});
                        } else {
                            valueControl.setErrors(null);
                        }
                        valueControl.setValidators(validators);
                    }
                },
            });
    }

    private getValidators(
        displayType: string,
        operatorCondition: string,
        isInputRange: boolean,
        rangeFromControl: AbstractControl,
        rangeToControl: AbstractControl,
    ): ValidatorFn | null {
        const validators = this.operatorIsEqualOrNotEqual(operatorCondition) ? [] : [Validators.required];
        if (displayType === 'date' && operatorCondition !== 'ADO') {
            validators.push(CustomFormValidators.isInvalidDate);
        }
        if (isInputRange) {
            validators.push(CustomFormValidators.isInvalidRange(rangeFromControl, rangeToControl, displayType === 'date'));
        }
        return Validators.compose(validators);
    }

    private operatorIsEqualOrNotEqual(operatorCondition: string): boolean {
        return operatorCondition === 'EQL' || operatorCondition === 'NEQL';
    }

    private createColorsMap(): Map<string, { [key: string]: string }> {
        const colorsReducer = (colors: LabelValuePair<string>[]): Record<string, string> => colors.reduce((accum, color) => {
            accum[color.value] = color.label;
            return accum;
        }, {} as Record<string, string>);
        const colorsMap = new Map<string, { [key: string]: string }>();
        colorsMap.set('barColor', colorsReducer(this.barColors));
        return colorsMap;
    }

    private fetchWidgets(): void {
        this.widgetsService.fetchAllWidgets().subscribe((data) => {
            this.ddvWidgets = data.map((datum) => ({ key: datum.id, text: datum.name, value: datum.id }));
        });
    }
}
