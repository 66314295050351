import { CdkDrag, CdkDragStart, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FieldMetadataGroup } from '@ddv/models';

import { FieldMetadataService } from '../../../services/field-metadata.service';

@Component({
    selector: 'app-field-picker-tree',
    templateUrl: './field-picker-tree.component.html',
    styleUrls: ['./field-picker-tree.component.scss'],
})
export class FieldPickerTreeComponent implements OnChanges, OnDestroy {
    @Input() isAttached = true;
    @Input() tree: FieldMetadataGroup[] | undefined;
    @Input() readonly = false;
    @Output() dragStateChanged = new EventEmitter<DragStateEvent>();

    searchValue: string | undefined;

    constructor(private readonly fieldMetadataService: FieldMetadataService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isAttached) {
            this.resetSearch();
        }
    }

    searchMetadata(): void {
        this.fieldMetadataService.filterTree(this.tree, this.searchValue);
    }

    dropListEnterPredicate(): (drag: CdkDrag, drop: CdkDropList) => boolean {
        return function (_drag: CdkDrag, _drop: CdkDropList): boolean {
            return false;
        };
    }

    cloneElement(e: CdkDragStart, emitData: DragStateEvent): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const clone = (e as any).source.element.nativeElement;
        setTimeout(function () {
            const removeEl = document.querySelector('.grid-list-children .cdk-drag-placeholder');
            if (removeEl) {
                removeEl.remove();
                document.querySelector('.grid-list-children .cdk-drop-list-dragging')?.appendChild(clone);
            }
        }, 100);
        this.dragStateChanged.emit(emitData);
    }

    ngOnDestroy(): void {
        if (!this.tree) {
            return;
        }
        this.resetSearch();
    }

    private resetSearch(): void {
        this.searchValue = undefined;
        this.searchMetadata();
    }
}

export interface DragStateEvent {
    state: 'start' | 'stop';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}
