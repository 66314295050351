<form>
    <div class="upload-container">
        <div>
            <cc-single-select-dropdown
                label="Map Name"
                anchorType="simple"
                size="large"
                [disabled]="true"
                [options]="[]">
            </cc-single-select-dropdown>
        </div>
        <div>
            <label for="file-upload" [ngClass]="{'disabled': fileSelected}">Select File</label>
            <input id="file-upload" type="file" (change)="onFileSelected($event)" [disabled]="fileSelected">
        </div>
    </div>
    <div class="file-container">
        <div class="file-headers">
            <span>Type</span>
            <span>File Name</span>
            <span>Size</span>
        </div>
        <div class="file-content">
            <span *ngIf="fileType">{{ fileType }}</span>
            <span *ngIf="fileName">{{ fileName }}</span>
            <span *ngIf="fileSize">{{ fileSize }} KB</span>
        </div>
    </div>
    <div class="btn-container">
        <button class="empty-btn cancel-btn" (click)="closeUploadModal('Cancel')">Cancel</button>
        <button class="ghost-btn apply-btn" [disabled]="!fileSelected" (click)="uploadFile()">Upload</button>
    </div>
</form>
