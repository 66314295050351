import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService, RealtimeActiveService } from '@ddv/behaviors';
import { ModalDialogService } from '@ddv/common-components';
import { GridState } from '@ddv/data-grid';
import { DatasetManagerService, DatasetRefresherService, DefaultQueryParamsService, MetadataService, SlowDataApprovalService } from '@ddv/datasets';
import { UserService } from '@ddv/entitlements';
import { ClientDatasetFilterService, QueryParamsService } from '@ddv/filters';
import { LayoutService, ManagerService } from '@ddv/layout';
import {
    LOADER_CANCELLED_MESSAGE,
    MANAGE_WIDGET_DEFAULT_MESSAGE,
    MANAGE_WIDGET_ID,
    WIDGET_LIFECYCLE_EVENT,
    VisualizationConfigs,
    DataUpdateBody,
    WidgetAction,
    WidgetLifeCycleData,
    WidgetLifecycleEvent,
    WidgetPosition,
    TWidgetTypes,
    ConfigItem,
    MetadataLookup,
} from '@ddv/models';
import { FuzzyDatesService } from '@ddv/reference-data';
import { clone, deepExtend } from '@ddv/utils';
import {
    BaseVisualizationComponent,
    SelectedWidgetRelayService,
    BaseGridVisualizationComponent,
    AdvancedGridVisualizationComponent,
    SimpleGridVisualizationComponent,
    GridEvent,
    VisualizationConstructor,
} from '@ddv/visualizations';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { ApplicationBaseWidgetComponent } from '../../application-base-widget.component';
import { WidgetService } from '../../services/widget.service';
import { WidgetsStateRelayService } from '../../services/widgets-state-relay.service';
import { WidgetHeaderComponent } from '../widget-header/widget-header.component';

export interface CreateLiveDemoWidgetEvent {
    action: 'RENDER_VIEW';
    component: VisualizationConstructor;
    preferences: VisualizationConfigs | VisualizationConfigs[];
    coreWidgetType: TWidgetTypes;
}

@Component({
    selector: 'app-live-demo-widget',
    templateUrl: './live-demo-widget.component.html',
    styleUrls: ['../../application-base-widget.component.scss'],
})
export class LiveDemoWidgetComponent extends ApplicationBaseWidgetComponent implements OnInit, OnDestroy {
    componentRef: ComponentRef<BaseVisualizationComponent> | undefined;
    showMessage = false;
    private invokeRenderVisualization = false;
    @ViewChild(WidgetHeaderComponent, { static: true }) private readonly widgetHeader: WidgetHeaderComponent | undefined;
    @ViewChild('content', { read: ViewContainerRef, static: true }) private readonly container: ViewContainerRef | undefined;
    private isWidgetRendered = false;
    private componentData: CreateLiveDemoWidgetEvent | undefined;

    private subscriptions: Subscription[] = [];

    private readonly instanceLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public instanceLoadedObs: Observable<boolean>;

    @Output() detailWidgetSelectedEvent = new EventEmitter<boolean>();

    constructor(
        private readonly manageWidgetsCommService: WidgetsStateRelayService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly selectedWidgetRelay: SelectedWidgetRelayService,
        cdr: ChangeDetectorRef,
        clientDatasetFilterService: ClientDatasetFilterService,
        datasetManagerService: DatasetManagerService,
        layoutService: LayoutService,
        metadataService: MetadataService,
        modalService: ModalDialogService,
        widgetService: WidgetService,
        workspaceManager: ManagerService,
        queryParamsService: QueryParamsService,
        realtimeActiveService: RealtimeActiveService,
        currentStateService: CurrentStateService,
        slowDataApprovalService: SlowDataApprovalService,
        fuzzyDateService: FuzzyDatesService,
        userService: UserService,
        datasetRefresherService: DatasetRefresherService,
        defaultQueryParamsService: DefaultQueryParamsService,
    ) {
        super(cdr,
            clientDatasetFilterService,
            datasetManagerService,
            layoutService,
            metadataService,
            modalService,
            widgetService,
            workspaceManager,
            queryParamsService,
            slowDataApprovalService,
            realtimeActiveService,
            fuzzyDateService,
            currentStateService,
            userService,
            datasetRefresherService,
            defaultQueryParamsService);

        this.instanceLoadedObs = this.instanceLoadedSubject.asObservable();
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.headers = this.getHeaders({
            hasCustomFilter: true,
            hasViz: false,
            hideMenu: true,
        });
        this.initWidget();
    }

    // this moronicness is necessary because of all the dynamic components
    override toggleUseNewLegend(useNewLegend: boolean): void {
        super.toggleUseNewLegend(useNewLegend);

        if (this.componentRef) {
            this.componentRef.instance.useNewLegend = this.useNewLegend;
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];

        this.destroyComponentRef();

        this.removeDataset();
    }

    override updateFieldMetadata(fieldMetadata: MetadataLookup): void {
        super.updateFieldMetadata(fieldMetadata);

        if (this.componentRef) {
            this.componentRef.instance.fieldMetadata = fieldMetadata;
            this.cdr.detectChanges();
        }
    }

    private publishWidgetDimension(): void {
        if (this.id) {
            const widgetState = this.workspaceManager.getWidgetState(this.id);
            const widgetPosition = this.layoutService.getWidgetPositionById(this.id);
            if (widgetPosition) {
                this.manageWidgetsCommService.publishWidgetDimension({
                    widgetDimension: { width: widgetState?.width ?? 0, height: widgetState?.height ?? 0 },
                    widgetPosition,
                });
            }
        }
    }

    override widgetLifeCycleCallBack(eventName: WIDGET_LIFECYCLE_EVENT.DATA_UPDATE, data: DataUpdateBody): void;
    override widgetLifeCycleCallBack(eventName: WidgetLifecycleEvent, param: WidgetLifeCycleData): void {
        switch (eventName) {
            case WIDGET_LIFECYCLE_EVENT.INIT_WIDGET:
                this.workspaceManager.triggerWidgetAction(this.id, WidgetAction.MOVE,
                    this.layoutService.getWidgetScaledPosition(this.layoutService.getWidgetDefaultPosition(undefined)!));
                this.manageWidgetsCommService.publishPreviewWidgetInitStatus();
                this.manageWidgetsCommService.publishSaveStatus(false);
                this.isDataLoaded = true;
                this.cdr.detectChanges();
                break;
            case WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION:
                this.onInterWidgetCommunication(param);
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_PASSIVE_MODE:
                this.removeDataset();
                break;
            case WIDGET_LIFECYCLE_EVENT.WIDGET_ACTIVE_MODE:
                if (!this.widgetPrefs?.coreWidgetType) {
                    this.manageWidgetsCommService.publishSaveStatus(false);
                }
                this.fetchMetaData();
                this.fetchDataset();
                break;
            case WIDGET_LIFECYCLE_EVENT.DATA_UPDATE:
                (param as DataUpdateBody).data = this.clientDatasetFilterService.filterData(
                    (param as DataUpdateBody).data,
                    { filters: (param as DataUpdateBody).filters?.filters });
                if ((param as DataUpdateBody).compareData) {
                    (param as DataUpdateBody).compareData = this.clientDatasetFilterService.filterData(
                        (param as DataUpdateBody).compareData ?? [],
                        { filters: (param as DataUpdateBody).filters?.filters });
                }
                this.triggerCompLifeCycleCallBack(eventName, param);
                this.enableMenuIcons();
                this.isDataLoaded = true;
                break;
            case WIDGET_LIFECYCLE_EVENT.ON_CLOSE:
                this.removeDataset();
                break;
            case WIDGET_LIFECYCLE_EVENT.ON_MAXIMIZE:
                this.showMessage = false;
                break;
            case WIDGET_LIFECYCLE_EVENT.AFTER_RESIZE:
                this.publishWidgetDimension();
                if (this.widgetPrefs?.coreWidgetType) {
                    this.manageWidgetsCommService.publishSaveStatus(true);
                }
                this.triggerCompLifeCycleCallBack(eventName, param);
                break;
            case WIDGET_LIFECYCLE_EVENT.ON_CASCADE:
                if (this.activatedRoute.snapshot.queryParamMap.get('return_to')) {
                    this.router.navigate(['../../../widgets/edit/', this.activatedRoute.snapshot.queryParamMap.get('return_to')],
                        { relativeTo: this.activatedRoute }).catch((e) => console.error(e));
                }
                break;
            default:
                this.triggerCompLifeCycleCallBack(eventName, param);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    triggerCompLifeCycleCallBack(eventName: any, param: any): void {
        if (this.componentRef?.instance.widgetLifeCycleCallBack) {
            this.componentRef.instance.widgetLifeCycleCallBack(eventName, param);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    override widgetLifeCyclePostProcess(eventName: WIDGET_LIFECYCLE_EVENT, data: any): void {
        super.widgetLifeCyclePostProcess(eventName, data);

        if (this.componentRef?.instance.widgetLifeCyclePostProcess) {
            this.componentRef.instance.widgetLifeCyclePostProcess(eventName, data);
        }
    }

    onHeaderInitialized(): void {
        this.hideAllIcons();
        this.workspaceManager.showMenuOptionForWidget(this.id, [this.getMaximizeIcon()]);
    }

    override displayIcons(): void {
        /* To Override Base Widget */
    }

    toggleIcons(): void {
        /* To Override Base Widget */
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onFilterChanged(event: any): void {
        this.selectedSlicer = event;
        this.updateWidgetTitle();
        if (this.componentRef && this.selectedSlicer) {
            this.componentRef.instance.vizInfo = { slicer: this.selectedSlicer, values: [] };

            if (this.componentRef.instance.onFilterChanged) {
                this.componentRef.instance.onFilterChanged(event);
            }
        }
    }

    private initWidget(): void {
        this.widgetPrefs = clone(this.widgetService.getDefaultWidgetState(this.id), {
            id: this.id,
            name: 'Set Display Name',
            displayNameType: '',
            isSubscribedToDashboardFilters: false,
            widgetFilters: this.manageWidgetsCommService.getCurrentQueryParams(),
        });

        if (this.activatedRoute.snapshot.queryParamMap.get('clientCode') && this.widgetPrefs.widgetFilters) {
            const clientCode = this.activatedRoute.snapshot.queryParamMap.get('clientCode') ?? '';
            this.widgetPrefs.widgetFilters.clients = [{ clientId: clientCode, clientName: clientCode }];
        }
        this.widgetPrefs.enableCompareMode = this.workspaceManager.getWidgetPreferences(this.id)?.enableCompareMode;
        this.workspaceManager.setWidgetExtraPreferences(this.id, this.widgetPrefs);
        this.updateWidgetTitle(this.widgetPrefs);
        this.configureDisplayInfo();
        this.publishWidgetDimension();
        if (this.activatedRoute.snapshot.queryParamMap.get('detailWidget')) {
            setTimeout(() => this.detailWidgetSelectedEvent.next(true), 0);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    override onInterWidgetCommunication(data: any): void {
        super.onInterWidgetCommunication(data);

        if (!this.widgetPrefs?.datasetDefinition) {
            return;
        }

        if (data === WIDGET_LIFECYCLE_EVENT.LOADING_DATA) {
            this.onLoadingData(data);
        } else if (data?.action) {
            switch (data.action) {
                case 'RESET_VIEW':
                    this.isWidgetRendered = false;
                    this.destroyComponentRef();
                    this.widgetPrefs.visualizationConfigs = [];
                    this.widgetPrefs.summaryConfig = undefined;
                    this.subscribeHeaderToVizSlicerChanges();
                    break;
                case WIDGET_LIFECYCLE_EVENT.RESET_WIDGET:
                    this.isWidgetRendered = false;
                    this.destroyComponentRef(true);
                    this.updateMWDimensionPosition();
                    this.initWidget();
                    this.removeDataset();
                    this.subscribeHeaderToVizSlicerChanges();
                    break;
                case 'UPDATE_DATASET':
                    if (this.widgetPrefs.datasetDefinition?.id === data.datasetDefinition?.id) {
                        break;
                    }
                    this.removeDataset();
                    this.widgetPrefs.datasetDefinition.name = data.datasetDefinition.name;
                    this.widgetPrefs.datasetDefinition.id = data.datasetDefinition.id;
                    this.widgetPrefs.datasetDefinition.conversableType = data.datasetDefinition.conversableType ||
                        data.datasetDefinition.crosstalkOptions?.conversableType;
                    if (typeof this.widgetPrefs.datasetDefinition?.id === 'string') {
                        this.widgetPrefs.datasetDefinition.namedQueryId = this.widgetPrefs.datasetDefinition?.id;
                    }
                    this.workspaceManager.setWidgetExtraPreferences(this.id, this.widgetPrefs);
                    this.selectedWidgetRelay.updateCurrentDatasetDefinition(data.datasetDefinition);
                    this.fetchMetaData();
                    this.fetchDataset();
                    break;
                case 'UPDATE_WIDGET_FILTERS':
                    this.widgetPrefs.widgetFilters = data.filters;
                    this.workspaceManager.setWidgetExtraPreferences(this.id, this.widgetPrefs);
                    this.queryParamsService.addWidgetQueryParam(MANAGE_WIDGET_ID, this.widgetPrefs.widgetFilters);
                    break;
                case 'RENDER_VIEW':
                    this.destroyComponentRef();
                    this.updateWidgetPreferences(data);
                    this.renderVisualization(data);
                    break;
                case 'UPDATE_WIDGET_TITLE':
                    this.updateWidgetTitle(data.titleInfo);
                    break;
                case 'UPDATE_ENABLE_COMPARE_MODE':
                    this.updateEnableCompareMode(data.info);
                    break;
                case 'UPDATE_WIDGET_PREFERENCES':
                    this.updateWidgetPreferences(data);
                    break;
                case 'EDIT_WIDGET':
                    this.updateMWDimensionPosition(data.widgetInfo.widgetPosition, true);
                    break;
                case WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED:
                    this.destroyComponentRef();
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
    onLoadingData(data: any): void {
        if (!this.componentRef || this.widgetPrefs?.coreWidgetType === 'VISUALIZATION') {
            if ((this.isWidgetRendered || this.invokeRenderVisualization) && (this.isLoaderCancelled || this.isErrorOccurred)) {
                this.isLoaderCancelled = false;
                this.isErrorOccurred = false;
                this.cdr.detectChanges();
                this.renderVisualization(this.componentData);
            }
            this.onDataLoading();
            this.triggerCompLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.LOADING_DATA, data);
        } else {
            this.triggerCompLifeCycleCallBack(WIDGET_LIFECYCLE_EVENT.INTER_WIDGET_COMMUNICATION, data);
        }
    }

    override shouldDisplayMessage(): boolean {
        return !this.isErrorOccurred && (!this.componentRef || this.componentRef.hostView.destroyed || this.isLoaderCancelled);
    }

    override getErrorMessage(): string {
        if (this.isLoaderCancelled) {
            return LOADER_CANCELLED_MESSAGE;
        }
        return MANAGE_WIDGET_DEFAULT_MESSAGE;
    }

    private renderVisualization(data?: CreateLiveDemoWidgetEvent): void {
        this.componentData = data;
        if (!data) {
            return;
        }

        if (this.isErrorOccurred) {
            this.invokeRenderVisualization = true;
            return;
        }

        this.invokeRenderVisualization = false;
        this.isWidgetRendered = true;

        let gridState: GridState | undefined;
        if (this.widgetPrefs?.coreWidgetType === 'VISUALIZATION' &&
            this.componentRef &&
            this.componentRef.instance instanceof BaseGridVisualizationComponent
        ) {
            gridState = this.componentRef.instance.getGridState();
        }

        this.destroyComponentRef();

        if (this.isLoaderCancelled) {
            return;
        }

        if (!this.container || !this.widgetPrefs) {
            return;
        }

        this.container.remove();
        this.componentRef = this.container.createComponent(data.component);
        const visualizationComponent = this.componentRef.instance;
        visualizationComponent.widgetId = this.id;
        visualizationComponent.datasetId = this.widgetPrefs.datasetDefinition?.id ?? 0;
        visualizationComponent.isMaster = !!this.widgetPrefs.isMaster;
        visualizationComponent.isManagingWidget = this.isManagingWidget;
        visualizationComponent.useNewLegend = this.useNewLegend;
        visualizationComponent.fieldMetadata = this.fieldMetadata;

        if (this.selectedSlicer) {
            visualizationComponent.vizInfo = { slicer: this.selectedSlicer, values: [] };
        }
        visualizationComponent.datasetId = this.widgetPrefs.datasetDefinition?.id ?? 0;

        if (this.widgetPrefs.coreWidgetType === 'VISUALIZATION') {
            this.widgetPrefs.currentVisualization = this.widgetPrefs.visualizationConfigs[0].visualizationType;
            const vizConfig = this.widgetPrefs.visualizationConfigs[0];
            visualizationComponent.preferences = vizConfig;
            if (vizConfig.visualizationType.indexOf('GRID') !== -1) {
                this.subscribeToGridChanges(vizConfig, gridState!);
            }
            this.subscribeHeaderToVizSlicerChanges();
        } else { // This is SUMMARY
            this.widgetPrefs.currentVisualization = undefined;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (visualizationComponent as any).preferences = this.widgetPrefs;
        }
        this.updateComponentData();
        this.updateWidgetTitle();

        if (this.componentRef.instance instanceof AdvancedGridVisualizationComponent) {
            this.componentRef.instance.isGridReadyObs?.subscribe((isReady) => {
                if (isReady) {
                    this.instanceLoadedSubject.next(true);
                }
            });
        }
    }

    private subscribeToGridChanges(vizConfig: VisualizationConfigs, gridState: GridState): void {
        if (!this.componentRef) {
            return;
        }

        const visualization = this.componentRef.instance as BaseGridVisualizationComponent;
        this.subscriptions.push(visualization.gridStateUpdated
            .subscribe((gridEvent: GridEvent) => {
                this.widgetService.publishGridStateUpdated(gridEvent);
            }));

        this.subscriptions.push(visualization.detailWidgetClicked
            .subscribe(async ({ detailWidgetId, clientCode }) => {
                await this.router.navigate(['../../../widgets/edit/', detailWidgetId],
                    {
                        queryParams: { detailWidget: true, clientCode, return_to: this.activatedRoute.snapshot.params.coreWidgetId },
                        relativeTo: this.activatedRoute,
                    });
            }));

        if (vizConfig.visualizationType === 'ADVANCED_GRID') {
            this.showMessage = true;
            setTimeout(() => {
                this.showMessage = false;
            }, 3000);
        }
        setTimeout(() => {
            // Set time out is used to make sure grid loads with data first before applying the grid state.
            if (gridState) {
                this.applyGridState(gridState);
            }
        }, 100);
    }

    private subscribeHeaderToVizSlicerChanges(): void {
        if (this.isCustomFilterVisible()) {
            this.showIcons(['custom-filter']);
            this.widgetHeader?.updateFilterOptions(this.widgetPrefs?.visualizationConfigs[0].configs?.slicers ?? []);
            if (this.componentRef?.instance.vizSlicer) {
                this.subscriptions.push(this.componentRef.instance.vizSlicer
                    .subscribe((slicer) => {
                        this.widgetHeader?.onFilterChanged(slicer);
                        this.updateWidgetTitle();
                    }));
            }
        } else {
            this.updateWidgetTitle();
            this.hideIcons(['custom-filter']);
        }
    }

    private destroyComponentRef(resetError = false): void {
        if (this.componentRef) {
            this.componentRef.destroy();
            this.componentRef = undefined;
        }
        if (resetError) {
            this.isErrorOccurred = false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateWidgetPreferences(data: any): void {
        if (!this.widgetPrefs) {
            return;
        }

        this.widgetPrefs.coreWidgetType = data.coreWidgetType;
        switch (data.coreWidgetType) {
            case 'VISUALIZATION':
                this.updateSelectedSlicer(data.preferences[0].configs.slicers);
                this.widgetPrefs.visualizationConfigs = [...data.preferences];
                break;
            case 'SUMMARY':
                this.widgetPrefs.summaryConfig = data.preferences[0];
                break;
        }
    }

    private updateSelectedSlicer(slicers?: ConfigItem[]): void {
        if (!slicers?.length) {
            this.selectedSlicer = undefined;
            return;
        }
        if (!this.selectedSlicer || !slicers.find((slicer) => this.selectedSlicer?.value === slicer.value)) {
            // Selected slicer is not set or is no longer in the list
            this.onFilterChanged(slicers[0]);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateEnableCompareMode({ enableCompareMode }: any): void {
        if (this.widgetPrefs) {
            this.widgetPrefs.enableCompareMode = enableCompareMode;
        }
    }

    private isCustomFilterVisible(): boolean {
        const vizConfigs = this.widgetPrefs?.visualizationConfigs[0];
        if (vizConfigs) {
            const vizType = vizConfigs.visualizationType;
            const slicers = vizConfigs.configs?.slicers || [];
            return vizType === 'LINE_CHART' || vizType === 'STACKED_AREA_CHART' ?
                !!slicers.length :
                vizType !== 'SIMPLE_GRID' && vizType !== 'ADVANCED_GRID';
        }
        return false;
    }

    private configureDisplayInfo(): void {
        const maximizeButton: Element = document.getElementsByClassName('iconGroup maxBtn')[0];
        if (maximizeButton) {
            maximizeButton.addEventListener('mouseenter', () => {
                const vizConfig = this.widgetPrefs?.visualizationConfigs[0];
                if (vizConfig?.visualizationType === 'ADVANCED_GRID') {
                    this.showMessage = true;
                }
            });
            maximizeButton.addEventListener('mouseleave', () => {
                this.showMessage = false;
            });
        }
    }

    private applyGridState(prevGridState: GridState): void {
        delete prevGridState.columnHeaderGroupState;
        const visualization = this.componentRef?.instance as BaseGridVisualizationComponent;
        const setState = deepExtend({}, [visualization.getGridState(), prevGridState]);
        visualization.setGridState(setState);
    }

    private updateMWDimensionPosition(widgetPosition?: WidgetPosition, isEdit = false): void {
        if (this.workspaceManager.getWidgetState(this.id)?.maximized) {
            this.workspaceManager.triggerWidgetAction(this.id, WidgetAction.RESTORE);
        }
        const widgetSize = (!widgetPosition || typeof widgetPosition.endColumn !== 'number' ||
            typeof widgetPosition.startingColumn !== 'number' || typeof widgetPosition.endRow !== 'number' ||
            typeof widgetPosition.startingRow !== 'number') ?
            this.layoutService.getWidgetScaledPosition(this.layoutService.getWidgetDefaultPosition(undefined)!) :
            this.layoutService.getWidgetScaledPosition(widgetPosition);

        this.workspaceManager.triggerWidgetAction(this.id, WidgetAction.RESIZE, {
            height: widgetSize?.height,
            width: widgetSize?.width,
        });
        this.workspaceManager.triggerWidgetAction(this.id, WidgetAction.MOVE, {
            left: isEdit ? 0 : widgetSize?.left,
            top: isEdit ? 0 : widgetSize?.top,
        });
    }

    areFiltersApplied(): boolean {
        if (this.componentRef?.instance instanceof AdvancedGridVisualizationComponent ||
            this.componentRef?.instance instanceof SimpleGridVisualizationComponent
        ) {
            return this.componentRef.instance.areFiltersApplied();
        }

        return false;
    }
}
