import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { ColorMetadataService } from '@ddv/charts';
import { ModalDialogService } from '@ddv/common-components';
import { GridState } from '@ddv/data-grid';
import { MetadataService } from '@ddv/datasets';
import {
    FieldMetadata,
    MetadataFormatUtils,
    SummaryConfig,
    ConfigItem,
    AUTO_GROUP_COLUMN_ID,
    VizConfigs,
} from '@ddv/models';
import { clone, uid } from '@ddv/utils';
import { Theme, ThemeService } from '@hs/ui-core-presentation';

import { FieldMetadataService } from '../../../services/field-metadata.service';
import { ColumnFormattingGridComponent } from '../column-formatting-grid/column-formatting-grid.component';
import { ConfigureComponent, CONVERSABLE_TYPE_ATTRIBUTES, DropZoneConfig } from '../configure/configure.component';
import * as dropZoneConfig from '../drop-zone-config';

@Component({
    selector: 'app-mw-grid-configure',
    templateUrl: './configure-grid.component.html',
    styleUrls: ['../configure/configure.component.scss'],
})
export class ConfigureGridComponent extends ConfigureComponent {
    @Input() isAttached = true;

    gridDataConfigs: ConfigItem[] = [];
    private theme: Theme = Theme.light;

    constructor(
        private readonly modalService: ModalDialogService,
        fieldMetadataService: FieldMetadataService,
        metadataService: MetadataService,
        private readonly themeService: ThemeService,
    ) {
        super(metadataService, fieldMetadataService);

        this.themeService.currentTheme$.subscribe((theme: Theme) => {
            this.theme = theme;
            this.updateColumnConditionColors();
        });
    }

    override onInit(
        datasetDefinitionId: number,
        widgetId: number,
        configs: VizConfigs | SummaryConfig,
        visualizationType: string,
    ): void {
        super.onInit(datasetDefinitionId, widgetId, configs, visualizationType);
    }

    getDropZones(): DropZoneConfig[] {
        return dropZoneConfig.grid;
    }

    dragStarted(_: FieldMetadata): void { }

    showColumnFormatters(config: ConfigItem): void {
        const dialogRef = this.modalService.open(ColumnFormattingGridComponent, { windowClass: 'column-formatter-popup' });
        dialogRef.componentInstance.parentData = config;
        dialogRef.result.then(
            (response: ConfigItem | 'cancel') => {
                if (response !== 'cancel') {
                    Object.assign(config, response);
                    this.renderPreview();
                }
            },
            (dismissData) => dismissData,
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onCdkDropSuccess(data: CdkDragDrop<any[]>): void {
        if (data.previousContainer === data.container) {
            moveItemInArray(this.gridDataConfigs, data.previousIndex, data.currentIndex);
        } else {
            this.updateConfigList(data.item.data, data.currentIndex);
        }
        this.activelyDragging = false;
        this.renderPreview();
    }

    updateConfigList(metadata: FieldMetadata, index: number): void {
        this.gridDataConfigs = [
            ...this.gridDataConfigs.slice(0, index),
            clone(
                metadata,
                MetadataFormatUtils.getDefaultColFormatters(),
                {
                    label: metadata.displayName,
                    colId: uid(),
                    aggregationType: metadata.displayType === 'value' ? 'sum' : null,
                },
            ),
            ...this.gridDataConfigs.slice(index),
        ];
    }

    removeConfigItemAtIndex(index: number): void {
        this.gridDataConfigs.splice(index, 1);
        this.renderPreview();
    }

    setDataConfigs(configs: VizConfigs): void {
        this.gridDataConfigs = configs.values;
    }

    override getSelectedConfigs(): { values: ConfigItem[] } {
        return { values: [...this.gridDataConfigs] };
    }

    updateGridColumnsSort(columns: ConfigItem[]): void {
        columns.forEach((column) => {
            const dataConfigById = this.dataConfigById(column.colId);
            if (dataConfigById) {
                dataConfigById.agSort = column.agSort;
                dataConfigById.agSortIndex = column.agSortIndex;
            }
        });
    }

    updateGridColumnsPinned(columns: ConfigItem[]): void {
        columns.forEach((column) => {
            const dataConfigById = this.dataConfigById(column.colId);
            if (dataConfigById) {
                dataConfigById.pinned = column.pinned;
            }
        });
    }

    updateGridColumnsFilter(columns: ConfigItem[]): void {
        columns.forEach((column) => {
            const dataConfigById = this.dataConfigById(column.colId);
            if (dataConfigById) {
                dataConfigById.agFilter = column.agFilter;
            }
        });
    }

    // This appears to be unsetting some configurations from configuration modals
    updateGridColumnState(gridState: GridState): void {
        if (gridState) {
            // This is a lie.  I'm not sure what we got wrong here, but these are not always ConfigItem instances
            const groupColumnId = gridState.columnState.findIndex((col) => col.colId === AUTO_GROUP_COLUMN_ID);
            gridState.columnState.forEach((column: ConfigItem) => {
                const configuredColumn = this.gridDataConfigs.find((dataConfig) => dataConfig.colId === column.colId);
                if (configuredColumn) {
                    // isHidden is stored in MW. Not sure why we need hide
                    configuredColumn.isHidden = column.hide;
                    configuredColumn.isSuppressedFromPanel = column.suppressColumnsToolPanel;
                    configuredColumn.pivotIndex = column.pivotIndex;
                    configuredColumn.rowGroupIndex = column.rowGroupIndex;
                    configuredColumn.width = column.width;
                    configuredColumn.pinned = column.pinned;

                    configuredColumn.customWidthWhenGroup = column.rowGroupIndex != null && groupColumnId !== -1 ?
                        gridState.columnState[groupColumnId].width :
                        undefined;

                    if ((configuredColumn.canAggregate || configuredColumn.datatype === 'number' || configuredColumn.datatype === 'decimal')
                            && Object.prototype.hasOwnProperty.call(column, 'aggFunc')) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        configuredColumn.aggregationType = (column as any).aggFunc || 'none';
                    }
                }
            });
        }
    }

    override hasRequiredFields(): boolean {
        return !!this.gridDataConfigs.length;
    }

    afterMetadataUpdate(): void {
        if (this.isCrosstalkGrid()) {
            const udfColumnNames = this.getUdfColumnNames();
            this.removeDeletedUdfColumnsFromGridConfigs(udfColumnNames);
        }
    }

    private dataConfigById(colId: string): ConfigItem | undefined {
        return this.gridDataConfigs.find((column) => column.colId === colId);
    }

    private getUdfColumnNames(): string[] {
        return this.metadata.reduce((udfColumnNamesList, metadataObj) => {
            if (this.isUserDefinedFieldColumn(metadataObj.value)) {
                udfColumnNamesList.push(metadataObj.value!);
            }
            return udfColumnNamesList;
        }, [] as string[]);
    }

    private removeDeletedUdfColumnsFromGridConfigs(udfColumnNames: string[]): void {
        for (let i = this.gridDataConfigs.length - 1; i >= 0; i--) {
            const config = this.gridDataConfigs[i];
            if (this.isUserDefinedFieldColumn(config.value) && !udfColumnNames.includes(config.value!)) {
                this.gridDataConfigs.splice(i, 1);
            }
        }
    }

    private isCrosstalkGrid(): boolean | undefined {
        return this.groupedMetadata?.some((metadataGroup) => metadataGroup.displayName === CONVERSABLE_TYPE_ATTRIBUTES);
    }

    private isUserDefinedFieldColumn(field: string | undefined): boolean | undefined {
        return field?.startsWith('udf_');
    }

    private updateColumnConditionColors(): void {
        if (this.gridDataConfigs?.length) {
            this.gridDataConfigs.forEach((config) =>
                config.columnCondition?.forEach((c) => ColorMetadataService.mapThemeColors(this.theme, c)));
        }
    }
}
