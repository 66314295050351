import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorsModule } from '@ddv/behaviors';
import { CommonComponentsModule } from '@ddv/common-components';
import { DataGridModule } from '@ddv/data-grid';
import { DatasetDefinitionsModule } from '@ddv/dataset-definitions';
import { EntitlementsModule } from '@ddv/entitlements';
import { FiltersModule } from '@ddv/filters';
import { HttpModule } from '@ddv/http';
import { LayoutModule } from '@ddv/layout';
import { ReferenceDataModule } from '@ddv/reference-data';
import { LibSvgIconsModule } from '@ddv/svg-icons';
import { UsageTrackingModule } from '@ddv/usage-tracking';
import { VisualizationsModule } from '@ddv/visualizations';
import { DateSelectDropdownComponent, DateRangeSelectDropdownComponent } from '@hs/ui-core-date';
import { CheckboxComponent, OptionToggleComponent } from '@hs/ui-core-inputs';
import { IconComponent } from '@hs/ui-core-presentation';

import { LiveDemoWidgetComponent } from './components/live-demo-widget/live-demo-widget.component';
import { SaveWidgetFormComponent } from './components/save-widget-form/save-widget-form.component';
import { SummaryWidgetComponent } from './components/summary-widget/summary-widget.component';
import { VisualizationWidgetComponent } from './components/visualization-widget/visualization-widget.component';
import { WidgetFinderComponent } from './components/widget-finder/widget-finder.component';
import { VisMenuComponent } from './components/widget-header/vis-menu/vis-menu.component';
import { WidgetHeaderComponent } from './components/widget-header/widget-header.component';
import {
    WidgetSettingsOverridesComponent,
} from './components/widget-header/widget-settings-overrides/widget-settings-overrides.component';
import { FilteredDataService } from './services/filtered-data.service';
import { SummaryService } from './services/summary.service';
import { WidgetExportService } from './services/widget-export.service';
import { WidgetSettingsOverridesService } from './services/widget-settings-overrides.service';
import { WidgetService } from './services/widget.service';
import { WidgetsStateRelayService } from './services/widgets-state-relay.service';
import { WidgetsService } from './services/widgets.service';

@NgModule({
    imports: [
        BehaviorsModule,
        CommonComponentsModule,
        CommonModule,
        DataGridModule,
        DatasetDefinitionsModule,
        EntitlementsModule,
        FiltersModule,
        FormsModule,
        HttpModule,
        LayoutModule,
        ReactiveFormsModule,
        ReferenceDataModule,
        UsageTrackingModule,
        VisualizationsModule,
        LibSvgIconsModule,
        CheckboxComponent,
        DateSelectDropdownComponent,
        DateRangeSelectDropdownComponent,
        IconComponent,
        OptionToggleComponent,
    ],
    providers: [
        WidgetExportService,
        FilteredDataService,
        SummaryService,
        WidgetService,
        WidgetsService,
        WidgetSettingsOverridesService,
        WidgetsStateRelayService,
    ],
    declarations: [
        LiveDemoWidgetComponent,
        SaveWidgetFormComponent,
        SummaryWidgetComponent,
        VisMenuComponent,
        WidgetFinderComponent,
        WidgetHeaderComponent,
        WidgetSettingsOverridesComponent,
        VisualizationWidgetComponent,
    ],
    exports: [
        LiveDemoWidgetComponent,
        SummaryWidgetComponent,
        VisualizationWidgetComponent,
        WidgetFinderComponent,
    ],
})
export class WidgetsModule {}
