import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Self, Optional, OnChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { DropdownOption } from './dropdown-option';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements ControlValueAccessor, OnChanges {
    @Input() disabled = false;
    @Input() options: DropdownOption[] | undefined;
    @Input() selected: DropdownOption | undefined;
    @Output() selectedChange = new EventEmitter<DropdownOption>();
    @Input() showDropdown = false;
    @Input() wrapperCssClass: string | undefined;
    @Input() readonly = false;

    @ViewChild('list', { static: false }) set content(content: ElementRef) {
        if (content) {
            const elementList = content.nativeElement.querySelectorAll('.dropdown-selected');
            const element = elementList[0] as HTMLElement;
            if (element) {
                element.scrollIntoView({ block: 'nearest', inline: 'nearest' });
            }
        }
    }

    private value: unknown;

    constructor(@Self() @Optional() private readonly ngControl: NgControl) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnChanges(): void {
        if (this.value) {
            this.selected = this.options?.find((o) => o.value === this.value);
        }

        if (this.selected && this.selected.key === '') {
            this.selected = this.options?.find((o) => o.value === this.selected?.value);
        }
    }

    chooseOption(option: DropdownOption): void {
        this.selected = option;
        this.selectedChange.emit(option);
        this.onChange(option.value);
    }

    writeValue(value: unknown): void {
        this.value = value;
        this.selected = this.options?.find((o) => o.value === value);
    }

    private onChange(_: unknown): void {}
    registerOnChange(fn: (value: unknown) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(): void {}

    getClass(): string | undefined {
        return this.readonly ? 'readonly' : this.selected?.css;
    }
}
