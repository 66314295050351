<div class="datepicker__wrapper">

    <div
            class="datepicker"
            appMousedownOutside
            (mousedownOutside)="showFuzzyDates=false">

        <div
                class="datepicker__control"
                [ngClass]="{'active': !calendarOptions.allowRange || dateRangeValue === nextRangeDate}"
                (click)="setNextRangeDate(dateRangeValue)">

            <input
                class="datepicker__input-date"
                [(ngModel)]="selectedFuzzyDate"
                type="text"
                placeholder="MM/DD/YYYY"
                autocomplete="off"
                (keyup)="onUserInput($event)"
                (blur)="updateDateFromInput()"/>

            <span
                id="iconClear"
                class="datepicker__icon icon-close1"
                tabindex="0"
                title="Clear"
                (click)="clearDate(); setNextRangeDate(dateRangeValue);">
            </span>

            <span
                id="iconSelect"
                class="datepicker__icon icon-arrow-down"
                tabindex="0"
                title="Open"
                (click)="toggleFuzzyDatesList()">
            </span>

            <div class="fuzzy-dates" *ngIf="showFuzzyDates && filteredFuzzyDates.length !== 0">
                <div
                    class="fuzzy-date"
                    *ngFor="let fuzzyDate of filteredFuzzyDates"
                    (click)="updateDateFromList($event, fuzzyDate.name)"
                    (mousedown)="$event.preventDefault()"
                    [innerHTML]="fuzzyDate.name | highlight : selectedFuzzyDate">
                </div>
            </div>
        </div>

        <app-calendar
            *ngIf="showCalendar"
            [options]="calendarOptions"
            [year]="year"
            [month]="month"
            [date]="selectedDate"
            [nextRangeDate]="nextRangeDate"
            [isHIDataAvailable]="isHIDataAvailable"
            (nextRangeDateChange)="setNextRangeDate($event)"
            (errorMessageChange)="handleError($event)"
            (dateSelected)="updateSelectedDate($event)"
            (yearMonthChange)="handleYearMonthChange($event)">
        </app-calendar>

        <app-calendar-list-view
            *ngIf="showListView"
            [isDateRangePicker]="calendarOptions.allowRange"
            [selectedDate]="selectedDate"
            (selectedHiDate)="resetSelectedDate($event)">
        </app-calendar-list-view>
    </div>
</div>
