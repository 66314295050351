import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { BaseChartService } from './base-chart.service';
import { BrushService } from './brush.service';

@Injectable()
export class LineChartBrushService extends BrushService {
    private zoom: d3.ZoomBehavior<Element, unknown> | undefined;

    addBrush(parentChart: BaseChartService): void {
        this.setParentChartProperties(parentChart);
        this.setBrushProperties();

        const offset = 20;
        this.setBrushDimensions();
        const height2 = (this.brushHeight < 0) ? 5 : (this.config?.brushSize ?? 0);

        this.x2 = d3.scaleTime().range([0, this.brushWidth]);
        const yAxis = this.y || this.yAxisRight;
        const y2 = d3.scaleLinear().range([height2, 0]);
        this.xAxis2 = d3.axisBottom(this.x2).ticks(Math.max(this.brushWidth / this.tickOffset, 2));

        this.brush = d3.brushX()
            .extent([[0, 0], [this.brushWidth, height2]])
            .on('brush end', (event: { selection: number[] }) => this.brushed(event));

        this.zoom = d3.zoom()
            .scaleExtent([1, Infinity])
            .translateExtent([[0, 0], [this.brushWidth, this.brushHeight]])
            .extent([[0, 0], [this.brushWidth, this.brushHeight]]);

        this.x2.domain(this.x.domain());
        y2.domain(yAxis.domain());

        this.context = this.getContext(offset);
        this.setXAxis2();

        this.gBrush = this.context.append('g')
            .attr('class', 'brush')
            .call(this.brush);

        if (this.config?.brushRange && d3.min(this.config.brushRange) as any >= 0) { // eslint-disable-line @typescript-eslint/no-explicit-any
            this.config.brushRange[0] = this.x2(this.config.brushRangeDates?.[0] as any); // eslint-disable-line @typescript-eslint/no-explicit-any
            this.config.brushRange[1] = this.x2(this.config.brushRangeDates?.[1] as any); // eslint-disable-line @typescript-eslint/no-explicit-any
            this.gBrush.call(this.brush.move, this.config.brushRange); // eslint-disable-line @typescript-eslint/unbound-method
        } else {
            this.gBrush.call(this.brush.move, this.x.range()); // eslint-disable-line @typescript-eslint/unbound-method
        }

        this.svg.append('rect')
            .attr('class', 'zoom')
            .attr('width', this.brushWidth)
            .attr('height', this.brushHeight + height2 + offset)
            .attr('transform', 'translate(0,0)')
            .call(this.zoom);

        this.formatTicks('.tick');
    }

    resizeBrush(timeline: { notation: string, scale?: number }, parentChart: BaseChartService): void {
        this.setParentChartProperties(parentChart);

        const secondDate = this.x2!.domain()[1];
        const firstDate = this.getFirstDateOnBrushResize(timeline, secondDate);

        this.gBrush.call(this.brush!.move, [firstDate, secondDate].map(this.x2!)); // eslint-disable-line @typescript-eslint/unbound-method
    }

    protected override setBrushProperties(): void {
        super.setBrushProperties();

        this.dataSource = this.parentChartProperties?.dataSource;

        this.x = this.parentChartProperties?.x;
        this.lines = this.parentChartProperties?.lines;
    }

    private brushed(event: { selection: number[] }): void {
        if (!this.lines) {
            return;
        }
        const s = event.selection || this.x2!.range();
        this.config!.brushRange = s;
        this.config!.brushRangeDates = s.map(this.x2!.invert, this.x2); // eslint-disable-line @typescript-eslint/unbound-method
        const brushTickOffset = 120;
        const brushWidth = this.brushWidth;
        const xAxis = d3.axisBottom(this.x).ticks(Math.max(brushWidth / brushTickOffset, 2));
        this.x.domain(s.map(this.x2!.invert, this.x2)); // eslint-disable-line @typescript-eslint/unbound-method
        const xConfig = this.config?.axis[0][0];
        const tickSize = this.x.domain();
        const hasVerticalLabel = (tickSize.length > this.calculatedWidth / this.tickOffset) || xConfig?.rotate;
        const labelPosition = xConfig?.position === 'top' ? 'start' : 'end';
        const translatePos = xConfig?.position === 'bottom' && xConfig.rotate === 270 ? -10 : 0;
        this.lines.forEach((line) => {
            if (this.config!.showMultipleSeries) {
                this.svg.selectAll('.line').attr('d', (d: any) => line.path(d.values)); // eslint-disable-line @typescript-eslint/no-explicit-any
            } else {
                this.svg.selectAll('.line')
                    .filter((el: any) => el.key === line.key) // eslint-disable-line @typescript-eslint/no-explicit-any
                    .attr('d', (d: any) => line.path(d.values)); // eslint-disable-line @typescript-eslint/no-explicit-any
            }
            this.svg.select('.axis--x').call(xAxis)
                .selectAll('text')
                .style('text-anchor', hasVerticalLabel ? labelPosition : 'middle')
                .attr('transform', `rotate(${xConfig?.rotate}) translate(${translatePos},${translatePos})`);
            this.svg.select('.zoom').call(this.zoom?.transform, d3.zoomIdentity.scale(this.width / (s[1] - s[0])).translate(-s[0], 0)); // eslint-disable-line @typescript-eslint/unbound-method
        });
        if (this.config?.showCurveValues) {
            this.addInlineValues();
        }
    }
}
