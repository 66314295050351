import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Entitlements } from '../models/entitlement.constants';
import { UserEntitlementService } from './user-entitlement.service';

export abstract class EntitlementBackedRouteActivateGuardService implements CanActivate {
    protected constructor(private readonly userEntitlementsService: UserEntitlementService, private readonly entitlement: Entitlements) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const clientCode = route.parent?.paramMap.get('clientCode');
        if (!clientCode) {
            return false;
        }

        return this.userEntitlementsService.earlyStageFetch(clientCode)
            .pipe(map((userEntitlements) => userEntitlements.hasPermission(this.entitlement)));
    }
}
