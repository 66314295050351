import { ColDef, ColGroupDef } from '@ag-grid-community/core';

import { GridConfiguration, GridState, InfiniteScrollModel } from './interfaces';
import { DataGridOptions, DefaultDataGridOptions } from './options';

export class DefaultDataGridConfiguration implements GridConfiguration {
    columnDefinitions: (ColDef | ColGroupDef)[] = [];
    rowData = [];
    uniqueId = '';
    gridOptions = new DefaultDataGridOptions();
    autoFitColumns = true;

    constructor(config: GridConfiguration) {
        completeAssign(this, config);
        if ((this.gridOptions as DataGridOptions).rowModelType === 'infinite') {
            (this as GridConfiguration).dataSource = {
                rowCount: undefined, // behave as infinite scroll
                getRows: (params): void => {
                    const prepareCallbackParams: InfiniteScrollModel = {
                        startRow: params.startRow,
                        endRow: params.endRow,
                        callback: (data: unknown[], totalRowCount) => {
                            const rowsThisPage = data;
                            const lastRow = totalRowCount <= params.endRow ? totalRowCount : -1;
                            // call the success callback
                            params.successCallback(rowsThisPage, lastRow);
                        },
                    };
                    // At this point in your code, you would call the server
                    (this as GridConfiguration).serverSideCallback?.(prepareCallbackParams);
                },
            };
        }
    }
}

function completeAssign(target: unknown, _: unknown): unknown {
    'use strict';
    if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
    }
    const to = Object(target);
    for (let index = 1; index < arguments.length; index++) {
        // eslint-disable-next-line prefer-rest-params
        const nextSource = arguments[index];
        if (nextSource != null) { // Skip over if undefined or null
            if (nextSource instanceof Object) {
                for (const nextKey in nextSource) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        if (to[nextKey] instanceof Object || to[nextKey] instanceof Array) {
                            completeAssign(to[nextKey], nextSource[nextKey]);
                        } else {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            } else {
                for (let ind = 0; ind < nextSource.length; ++ind) {
                    // Avoid bugs when hasOwnProperty is shadowed
                    if (to[ind] instanceof Object || to[ind] instanceof Array) {
                        completeAssign(to[ind], nextSource[ind]);
                    } else {
                        to[ind] = nextSource[ind];
                    }
                }
            }
        }
    }
    return to;
}

export class DefaultGridState implements GridState {
    filterState = {};
    columnState = [];
    sortState = [];
    columnHeaderGroupState = [];
    pivotMode = false;
}
