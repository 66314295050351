// don't split these models into separate files.  it REALLY angers webpack

import { TMode } from '../application-mode';
import { MODE } from '../constants/dashboard-constants';
import { DatasetDefinition } from '../dataset-definitions/dataset-definition';
import { UserPermission } from '../entitlements/user-permission';
import { Tag } from '../tag';
import { AppWidgetState } from '../widgets/app-widget-state';
import { DashboardPreference } from './dashboard-preference';
import { TDeviceMode } from './device-mode';
import { WidgetOnBoard } from './widget-on-board';

export class DashboardSnapshot {
    id?: string;
    name?: string;
    description?: string;
    isShared?: boolean;
    dashboardTags: Tag[] = [];
    isDefault?: boolean;
    isGlobal?: boolean;
    createdBy?: string;
    createdDate?: string;
    abbreviation?: string;
    allowInfiniteVScroll?: boolean;
    allowInfiniteHScroll?: boolean;
    privilege?: string;
    clientId?: string;
    iconClass?: string;
    updatedDate?: string;

    public constructor(init?: Partial<DashboardSnapshot>) {
        Object.assign(this, init);

        this.iconClass = this.deriveIconClass();
        if (this.id) {
            this.id = this.id.toString();
        }
    }

    deriveIconClass(): string {
        if (this.isGlobal) {
            return 'global';
        }

        if (this.isShared) {
            return 'shared';
        }

        return 'private';
    }

    cloneForCopy(): DashboardModel {
        const clone = new DashboardModel(this);
        clone.isGlobal = false;
        clone.dashboardPermissions = [];
        clone.dashboardTags = clone.dashboardTags.filter((tags) => tags.type === 'USER');
        clone.name += ' copy';
        return clone;
    }
}

export class DashboardModel extends DashboardSnapshot {
    widgets: AppWidgetState[] = [];
    dashboardPreferences: DashboardPreference[] = [];
    dashboardPermissions: UserPermission[] = [];
    mode: TMode = 'view';
    deviceMode: TDeviceMode = 'DESKTOP';
    version = Number.MIN_VALUE;
    override updatedDate?: string = undefined;

    public constructor(init?: Partial<DashboardModel>) {
        super();

        Object.assign(this, init);

        this.widgets = (this.widgets || []).map((widget) => new AppWidgetState(widget));
    }

    getQueryParamVersion(): number {
        return this.dashboardPreferences.find((preference) => {
            return preference.dashboardPreferenceMode === MODE.EDIT_WORKSPACE;
        })?.version ?? Number.MIN_VALUE;
    }
}

export class DashboardDetails extends DashboardSnapshot {
    widgets: AppWidgetState[] = [];
    widgetOnBoards: WidgetOnBoard[] = [];
    dashboardPreferences: DashboardPreference[] = [];
    dashboardPermissions: UserPermission[] = [];
    layoutType?: string;
    parentDashboardId?: string;
    deviceMode?: TDeviceMode;
    version?: number;

    public constructor(init?: Partial<DashboardDetails>) {
        super();

        Object.assign(this, init);

        // This is because sometimes they come in as numbers
        this.id = this.id?.toString();
        this.widgetOnBoards = this.widgetOnBoards?.map((json) => new WidgetOnBoard(json));
        // This cast is because wob.widget is defined as optional so the array ends up as (AppWidgetState | undefined)[]
        this.widgets = this.widgetOnBoards?.map((wob) => wob.widget) as AppWidgetState[];
    }

    public isSingleDataset(): boolean {
        return this.widgetOnBoards.reduce((distinctDSDIds: (number | string)[], wob) => {
            const dsdId = wob.widget?.datasetDefinition?.id;
            if (dsdId && !distinctDSDIds.includes(dsdId)) {
                distinctDSDIds.push(dsdId);
            }

            return distinctDSDIds;
        }, []).length === 1;
    }

    public isInvestorOnly(): boolean {
        return !!this.widgetOnBoards.length && this.widgetOnBoards.every((wob) => wob.widget?.datasetDefinition?.dataType === 'Investor');
    }

    public firstDatasetDefinition(): DatasetDefinition | undefined {
        const firstWidget = this.widgetOnBoards[0].widget;
        if (firstWidget?.namedQueryId && firstWidget.datasetDefinition) {
            firstWidget.datasetDefinition.namedQueryId = firstWidget.namedQueryId;
        }
        return firstWidget?.datasetDefinition;
    }

    public getAllFundCodes(): { code: string }[] {
        return this.dashboardPreferences?.[0]?.funds?.map((fund) => ({ code: fund.fundId })) ?? [];
    }

    public getAllDSDIds(): number[] {
        // ?? Number.MIN_VALUE is to solve for the fact that the types say the DSD id can be undefined
        return this.widgetOnBoards?.map((wob) => Number(wob.widget?.datasetDefinition?.id) ?? Number.MIN_VALUE) ?? [];
    }
}
