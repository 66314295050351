import { Injectable } from '@angular/core';
import { Theme } from '@hs/ui-core-presentation';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import canvg from 'canvg-browser';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

interface PdfExportConfig {
    mode: 'p' | 'landscape' | 'portrait' | 'l';
    unit: 'em' | 'mm' | 'px' | 'pt' | 'in' | 'cm' | 'ex' | 'pc';
    format: string;
    pdfImageFormat: string;
    html2canvasScale: number;
    html2canvasLogging: boolean;
}

@Injectable()
export class PDFExportService {
    private readonly htmlCanvas;
    // cannot be readonly because it's stomped on in unit tests
    // eslint-disable-next-line @typescript-eslint/prefer-readonly
    private canvg;
    private readonly config: PdfExportConfig = {
        mode: 'l',
        unit: 'pt',
        format: 'a4',
        pdfImageFormat: 'jpeg',
        html2canvasScale: 2,
        html2canvasLogging: false,
    };

    constructor() {
        this.htmlCanvas = html2canvas;
        this.canvg = canvg;
    }

    generatePDF(
        element: HTMLElement,
        fileName: string,
        width: number,
        height: number,
        docModificationCallback?: (document: Document) => void,
        theme: Theme = Theme.light,
    ): void {
        this.convertToImage(element, theme, (canvasImage: string) => {
            this.saveImageToPDF(canvasImage, fileName, width, height, theme);
        }, docModificationCallback);
    }

    svgToCanvas(svg: string): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        this.canvg(canvas, svg, {});
        return canvas;
    }

    private convertToImage(
        element: HTMLElement,
        theme: Theme = Theme.light,
        callBack: (canvasImage: string) => void,
        docModificationCallback?: (document: Document) => void,
    ): void {
        this.htmlCanvas(element, {
            backgroundColor: theme === Theme.light ? '#fff' : '#1f2122',
            useCORS: true, allowTaint: true, scale: this.config.html2canvasScale, logging: this.config.html2canvasLogging,
            onclone: (document) => {
                if (docModificationCallback) {
                    docModificationCallback(document);
                }
            },
        }).then((canvas) => {
            const canvasimage = canvas.toDataURL(`image/${this.config.pdfImageFormat}`, 1);
            callBack(canvasimage);
        }).catch((error) => console.error(error));
    }

    private saveImageToPDF(data: string, fileName: string, width: number, height: number, theme: Theme = Theme.light): void {
        const doc = new jsPDF(this.config.mode, this.config.unit, this.config.format);

        const pdfWidth = doc.internal.pageSize.width;
        const pdfHeight = doc.internal.pageSize.height;
        const imageSizeForPDF = this.getImageSizeToFitInPage(pdfWidth, pdfHeight, width, height);

        doc.setFillColor(theme === Theme.light ? '#fff' : '#1f2122');
        doc.rect(0, 0, pdfWidth, pdfHeight, 'f');
        doc.addImage(data, this.config.pdfImageFormat, 0, 0, imageSizeForPDF.width, imageSizeForPDF.height);
        doc.save(`${fileName}.pdf`);
    }

    private getImageSizeToFitInPage(availableWidth: number, availableHeight: number,
        imageWidth: number, imageHeight: number): { height: number, width: number } {
        const aspectRatio = imageWidth / imageHeight;
        let height = imageHeight;
        let width = imageWidth;
        if (imageHeight > availableHeight && (imageWidth <= availableWidth)) {
            height = availableHeight;
            width = height * aspectRatio;
        } else if (width > availableWidth && (height <= availableHeight)) {
            width = availableWidth;
            height = width / aspectRatio;
        } else if (height > availableHeight && width > availableWidth) {
            const percentageDecreaseInHeight = (height - availableHeight) / height;
            const percentageDecreaseInWidth = (width - availableWidth) / width;

            if (percentageDecreaseInHeight > percentageDecreaseInWidth) {
                height = availableHeight;
                width = height * aspectRatio;
            } else {
                width = availableWidth;
                height = width / aspectRatio;
            }
        } else if (height < availableHeight && width < availableWidth) {
            const percentageIncreaseInHeight = (availableHeight - height) / height;
            const percentageIncreaseInWidth = (availableWidth - width) / width;

            if (percentageIncreaseInHeight < percentageIncreaseInWidth) {
                height = availableHeight;
                width = height * aspectRatio;
            } else {
                width = availableWidth;
                height = width / aspectRatio;
            }
        }
        return { height, width };
    }
}

