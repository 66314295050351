import { Injectable } from '@angular/core';
import * as d3 from 'd3';

import { BarChartData } from '../../models/chart-data';
import { hasOwnProperty } from '../../utils';

const defaultColors = ['#6b486b', '#ff8c00', '#98abc5', '#8a89a6', '#7b6888', '#a05d56', '#d0743c'];

@Injectable()
export class HorizontalBarChartColorProviderService {
    private interpolatedRange: d3.ScaleLinear<number, string> | undefined;
    private discreteRange: d3.ScaleOrdinal<number, string> | undefined;

    assignColorToBars(
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        colorSortBy: string | null | undefined,
        colorSortDirection: string | null | undefined,
        colorRange: string[] | undefined,
        attributeCustomColors: { [key: string]: string }[] | undefined,
    ): void {
        this.applyColorsToDataset(data, colorSortBy, colorSortDirection, colorRange, attributeCustomColors);
        if (compareData) {
            this.applyColorsToDataset(compareData, colorSortBy, colorSortDirection, colorRange, attributeCustomColors);
        }
    }

    private applyColorsToDataset(
        data: BarChartData[],
        colorSortBy: string | null | undefined,
        colorSortDirection: string | null | undefined,
        colorRange: string[] | undefined,
        attributeCustomColors: { [key: string]: string }[] | undefined,
    ): void {
        const isColorSorting = !!(colorSortBy && colorSortDirection);
        const distinctSlicerValues = data.map((d) => d.key.value);

        this.updateColorRange(distinctSlicerValues.length, colorRange);

        let columnsBasedOnColorSorting: string[] = [];
        let dataBasedOnColorSorting: BarChartData[] = [];

        if (isColorSorting) {
            const field: 'key' | 'value' = colorSortBy === 'value' ? 'value' : 'key';
            dataBasedOnColorSorting = sortDataBasedOnColorSorting(colorSortDirection, data, field);
            columnsBasedOnColorSorting = dataBasedOnColorSorting.map((d) => d.key.value);
        }

        this.assignOrderedColorsToBars(
            isColorSorting ? columnsBasedOnColorSorting : distinctSlicerValues,
            isColorSorting ? dataBasedOnColorSorting : data,
            attributeCustomColors,
        );
    }

    private assignOrderedColorsToBars(
        orderedSlicerValues: string[],
        data: BarChartData[],
        attributeCustomColors: { [key: string]: string }[] | undefined,
    ): void {
        orderedSlicerValues.forEach((slicerValue: string, index: number) => {
            const barDataForSlicer = data.find((d) => d.key.value === slicerValue);
            if (!barDataForSlicer) {
                throw new Error(`no bar for slicer value=${slicerValue}`);
            }

            barDataForSlicer.color = this.getColor(slicerValue, index, attributeCustomColors);
        });
    }

    private getColor(key: string, index: number, attributeCustomColors: { [key: string]: string }[] | undefined): string {
        const customColor = attributeCustomColors?.find((mapping) => hasOwnProperty(mapping, key))?.[key];
        if (customColor) {
            return customColor;
        }

        return this.interpolatedRange?.(index) ?? this.discreteRange?.(index) ?? '';
    }

    private updateColorRange(numberOfBars: number, colorRange: string[] | undefined): void {
        if (colorRange && colorRange.length <= 3) {
            this.interpolateColorRange(colorRange, numberOfBars);
        } else {
            this.createDiscreteColorRange(colorRange);
        }
    }

    // this is applicable for month mono-chromatic and solid-color
    private interpolateColorRange(colorRange: string[] | undefined, dataLength: number): void {
        let colorDomain: number[];
        switch (colorRange?.length) {
            case 1:
                colorDomain = [dataLength];
                break;
            case 2:
                colorDomain = [0, dataLength];
                break;
            case 3:
                colorDomain = [0, dataLength / 2, dataLength];
                break;
            default: colorDomain = [];
        }

        // i cannot figure out how to get rid of the casts to any below
        // we need the scale to be number to string because we pass it
        // the index of the bar and need it to return a color
        this.interpolatedRange = d3.scaleLinear<number, string>().domain(colorDomain)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .interpolate((d3 as any).interpolateHcl)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .range(colorRange as any);
    }

    // this is only applicable when Multi-Colored is chosen
    private createDiscreteColorRange(colorRange: string[] | undefined): void {
        const colorsToUse = colorRange?.length ? colorRange : defaultColors;
        this.discreteRange = d3.scaleOrdinal<number, string>().range(colorsToUse);
    }
}

function sortDataBasedOnColorSorting(colorSortDirection: string | null | undefined, data: BarChartData[], field: 'key' | 'value'): BarChartData[] {
    if (colorSortDirection === 'asc') {
        return [...data].sort((a, b) => a[field].value! > b[field].value! ? 1 : -1);
    }
    return [...data].sort((a, b) => a[field].value! < b[field].value! ? 1 : -1);
}
