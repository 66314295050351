import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsageTrackingToken } from '@ddv/models';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpCommunicationService } from './http-communication.service';
import { ResponseInterceptor } from './model/response-interceptor.interface';

export interface ExecutorOptions {
    catchError?: boolean;
    usageTracker?: UsageTrackingToken;
}

@Injectable()
export class ExecutorService {
    // TODO: public state
    responseInterceptor?: ResponseInterceptor;

    constructor(private readonly communicationService: HttpCommunicationService) {}

    invokeServiceWithParams<T>(
        url: string,
        params?: Record<string, unknown>,
        options: ExecutorOptions = { catchError: true },
    ): Observable<T> {
        const result = this.communicationService.fireService<T>(removeRedundantSlashesFromUrl(url), params, options.usageTracker);
        return options.catchError ?
            result.pipe(catchError((error: HttpErrorResponse) => this.onError<T>(error, params))) :
            result;
    }

    invokeServiceWithBody<T>(url: string, method: string, body: unknown, options: ExecutorOptions = { catchError: true }): Observable<T> {
        const result = this.communicationService.fireServiceWithBody<T>(
            removeRedundantSlashesFromUrl(url),
            method,
            body,
            options.usageTracker);

        return options.catchError ?
            result.pipe(catchError((error: HttpErrorResponse) => this.onError<T>(error, undefined))) :
            result;
    }

    invokeServiceWithBodyAndReturnFullResponse<T>(
        url: string,
        method: string,
        body: unknown,
        options: ExecutorOptions = { catchError: true },
    ): Observable<HttpResponse<T>> {
        const result = this.communicationService.fetchFullResponseFromService<T>(
            removeRedundantSlashesFromUrl(url),
            method,
            body,
            options.usageTracker);

        return options.catchError ?
            result.pipe(catchError((error: HttpErrorResponse) => this.onError<HttpResponse<T>>(error, undefined))) :
            result;
    }

    invokeService<T>(
        url: string,
        method: string,
        responseType: ('json' | 'text') = 'json',
        options: ExecutorOptions = { catchError: true },
    ): Observable<T | string> {
        let result: Observable<T | string>;
        if (responseType === 'json') {
            result = this.communicationService.fireServiceWithBody(removeRedundantSlashesFromUrl(url), method, null, options.usageTracker);
        } else {
            result = this.communicationService.fetchTextFromService(removeRedundantSlashesFromUrl(url), method, null, options.usageTracker);
        }

        return options.catchError ?
            result.pipe(catchError((error: HttpErrorResponse) => this.onError<T>(error))) :
            result;
    }

    registerInterceptors(responseInterceptor: ResponseInterceptor): void {
        this.responseInterceptor = responseInterceptor;
        this.communicationService.registerInterceptors(responseInterceptor);
    }

    onError<T>(error: HttpErrorResponse, requestParameters?: Record<string, unknown>): Observable<T> {
        if (this.responseInterceptor) {
            const errorDetails = this.communicationService.getErrorResponseDetail(error);
            errorDetails.requestParameters = requestParameters;
            this.responseInterceptor.handleException(errorDetails);

            return throwError(() => errorDetails);
        }

        return throwError(() => error);
    }
}

export function removeRedundantSlashesFromUrl(urlString: string): string {
    if (urlString.includes('://')) {
        const url = new URL(urlString);
        url.pathname = removeRedundantSlashesFromPath(url.pathname);
        return url.toString();
    } else {
        return removeRedundantSlashesFromPath(urlString);
    }
}

export function removeRedundantSlashesFromPath(url: string): string {
    // regex to remove // or /// from the URI
    return url.replace(/\/{2,}/g, '/');
}
