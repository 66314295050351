import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CustomCellRendererParams } from '@ddv/data-grid';

@Component({
    selector: 'app-crosstalk-custom-checkbox',
    templateUrl: './crosstalk-custom-checkbox.component.html',
    styleUrls: ['./crosstalk-custom-checkbox.component.scss'],
})
export class CrosstalkCustomCheckboxComponent implements ICellRendererAngularComp {
    value: boolean | undefined;
    renderCheckbox: boolean | undefined;
    private params: CustomCellRendererParams | undefined;

    agInit(params: CustomCellRendererParams): void {
        this.params = params;
        this.value = this.params.node.isSelected();
        this.renderCheckbox = !this.params.node.group && Object.keys(this.params.node.data).length > 0;
    }

    refresh(): boolean {
        this.value = this.params?.node.isSelected();
        return true;
    }

    onClick(value: boolean): void {
        this.params?.node.setSelected(value);
        this.params?.api.refreshHeader();
    }
}
