import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TradeFileDetailsService {
    /*
        This service is not at all a "TradeFileDetails" service.
        It just a wrapper around an observable that is triggered for "grids with tfl data"
        when the grid-level filters have changed, and checkboxes in the grid should be reset
     */
    public readonly gridFilterChanged: Observable<boolean>;
    private readonly gridFilterChangedSubject: Subject<boolean> = new Subject();

    constructor() {
        this.gridFilterChanged = this.gridFilterChangedSubject.asObservable();
    }

    onGridFilterChanged(): void {
        this.gridFilterChangedSubject.next(true);
    }
}
