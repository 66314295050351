import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices, SharedApiExecutorService } from '@ddv/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUserEntitlement } from '../models/user-entitlement.model';
import { UserEntitlements } from '../models/user-entitlements';

export interface UserData {
    userName: string;
    fullName: string;
    email: string;
    canEmulate: boolean;
    isEmulating: boolean;
    emulator: string | null;
    internal: boolean;
}

@Injectable()
export class UserEntitlementService {
    public readonly entitlementsForClientCode$: Observable<UserEntitlements>;
    public readonly userData$: Observable<UserData>;
    public readonly isApprover$: Observable<boolean>;

    private readonly isApprover = new ReplaySubject<boolean>(1);
    private readonly userData = new ReplaySubject<UserData>(1);
    private readonly entitlementsForClientCode = new ReplaySubject<UserEntitlements>(1);

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
        @Inject(ApiServices.wauth) private readonly wauthApiService: SharedApiExecutorService,
        @Inject(ApiServices.xtlk) private readonly xtlkApiService: ApiExecutorService,
    ) {
        this.entitlementsForClientCode$ = this.entitlementsForClientCode.asObservable();
        this.userData$ = this.userData.asObservable();
        this.isApprover$ = this.isApprover.asObservable();

        this.currentStateService.clientCode$.subscribe({
            next: (clientCode) => {
                this.fetchUserData();
                this.fetchUserEntitlements(clientCode).subscribe({
                    next: (entitlements) => this.entitlementsForClientCode.next(entitlements),
                });
                this.fetchUserApproverStatus(clientCode);
            },
        });
    }

    fetchUserEntitlements(clientCode: string): Observable<UserEntitlements> {
        return this.ddvApiService.invokeServiceWithParams<IUserEntitlement>(clientCode, 'entitlements')
            .pipe(
                map((data) => new UserEntitlements(data.entitlements, data.user)),
            );
    }

    private fetchUserData(): void {
        this.wauthApiService.invokeServiceWithParams<UserData>('/user').subscribe((data) => {
            this.userData.next(data);
        });
    }

    private fetchUserApproverStatus(clientCode: string): void {
        this.xtlkApiService.invokeServiceWithParams<{ isApprover: boolean }>(clientCode, '/user-roles').subscribe((approverStatus) => {
            this.isApprover.next(approverStatus.isApprover);
        });
    }

    earlyStageFetch(clientCode: string): Observable<UserEntitlements> {
        return this.ddvApiService.invokeServiceWithParams<IUserEntitlement>(clientCode, 'entitlements')
            .pipe(map((data: IUserEntitlement) => new UserEntitlements(data.entitlements, data.user)));
    }
}
