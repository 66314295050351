<cc-filter-list
    #ccFilterList
    selectionMode="multi"
    [items]="items"
    [enableBlanksSelection]="itemsContainBlanksOption"
    [search]="searchOptions"
    [(selectedChoices)]="selectedItems"
    (selectedOperatorChange)="onSelectedOperatorChange($event)"
    (selectedChoicesChange)="onSelectedChoicesChange($event)"
    (selectBlanksChange)="selectBlanksChange($event)">
</cc-filter-list>
