import { Inject, Injectable } from '@angular/core';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { UserDefinedField } from '@ddv/models';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConversableType } from '../models/conversable-type';
import { ConversationHighlight } from '../models/conversation-highlight';
import { CrosstalkComment } from '../models/crosstalk-comment';

@Injectable()
export class CrosstalkService {
    public readonly userDefinedFields: Observable<Map<string, UserDefinedField[]>>;
    public readonly gridFilterChanged: Observable<boolean>;

    private readonly userDefinedFieldsSubject: BehaviorSubject<Map<string, UserDefinedField[]>> = new BehaviorSubject(new Map());
    private readonly gridFilterChangedSubject: Subject<boolean> = new Subject();
    private readonly schemas: Map<string, Observable<ConversableType>> = new Map();

    constructor(@Inject(ApiServices.xtlk) private readonly xtlkApiExecutor: ApiExecutorService) {
        this.userDefinedFields = this.userDefinedFieldsSubject.asObservable();
        this.gridFilterChanged = this.gridFilterChangedSubject.asObservable();
    }

    getSchema(clientCode: string, conversableType: string): Observable<ConversableType> {
        const conversableTypeObservable = this.schemas.get(conversableType);
        if (!conversableTypeObservable) {
            return this.xtlkApiExecutor.invokeServiceWithParams<Partial<ConversableType>>(clientCode, `/schema/${conversableType}`)
                .pipe(map((init) => {
                    const schema = new ConversableType(init);
                    this.updateUserDefinedFields(schema);
                    this.addSchemaToCache(conversableType, schema);
                    return schema;
                }));
        }

        return conversableTypeObservable;
    }

    fetchUpdatedConversations(clientCode: string, conversableType: string, compoundKeys: string[][]): Observable<ConversationHighlight[]> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            `/conversations/${conversableType}/bulk-fetch`,
            'POST',
            { data: compoundKeys });
    }

    addCommentToConversation(clientCode: string, conversationId: string, message: string): Observable<CrosstalkComment> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            `/conversations/${conversationId}/comments/`,
            'POST',
            { message });
    }

    addAttachmentsToConversation(clientCode: string, conversationId: string, attachments: string[]): Observable<CrosstalkComment> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            `/conversations/${conversationId}/comments/`,
            'POST',
            { attachments });
    }

    addCommentToManyConversations(
        clientCode: string,
        conversableType: string,
        conversations: string[],
        message: string,
    ): Observable<CrosstalkBulkAddResponse> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            `/conversations/${conversableType}/bulk-add/`,
            'POST',
            { message, conversations });
    }

    addAttachmentsToManyConversations(
        clientCode: string,
        conversableType: string,
        conversations: string[],
        attachments: string[],
    ): Observable<CrosstalkBulkAddResponse> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            `/conversations/${conversableType}/bulk-add/`,
            'POST',
            { conversations, attachments });
    }

    addTemporaryAttachmentsToClient(clientCode: string, data: FormData): Observable<TemporaryFile[]> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            '/attachments/upload',
            'POST',
            data,
        );
    }

    updateEditedUserDefinedFields(
        id: string,
        clientCode: string,
        data: { [key: string]: string | number },
    ): Observable<UserDefinedField[]> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            `/conversations/${id}/user-defined-fields/`,
            'POST',
            data,
        );
    }

    bulkUpdateUserDefinedFields(
        clientCode: string,
        conversableType: string,
        conversations: string[],
        userDefinedFields: { [key: string]: string | number },
    ): Observable<UserDefinedFieldBulkEditResponse> {
        return this.xtlkApiExecutor.invokeServiceWithBody(
            clientCode,
            `/conversations/${conversableType}/user-defined-fields/bulk-edit`,
            'POST',
            { conversations, userDefinedFields },
        );
    }

    onGridFilterChanged(): void {
        this.gridFilterChangedSubject.next(true);
    }

    private updateUserDefinedFields(schema: ConversableType): void {
        const currentUserDefinedFields = this.userDefinedFieldsSubject.getValue();
        currentUserDefinedFields.set(schema.type, schema.getUserDefinedFields());
        this.userDefinedFieldsSubject.next(currentUserDefinedFields);
    }

    private addSchemaToCache(conversableType: string, schema: ConversableType): void {
        this.schemas.set(conversableType, of(schema));
    }
}

export interface CrosstalkBulkAddResponse {
    comment: CrosstalkComment;
    conversations: string[];
    requiresApproval?: boolean;
}

export interface TemporaryFile {
    id: string;
}

export interface UserDefinedFieldBulkEditResponse {
    [conversationId: string]: UserDefinedField[];
}
