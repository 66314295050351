<div
    class="no-data"
    *ngIf="noDataAvailable && !isDataLoading"
    [hidden]="!noDataAvailable"
>
    <span>NO DATA AVAILABLE</span>
</div>

<div
    *ngIf="!noDataAvailable"
    class="clearfix"
    [hidden]="noDataAvailable"
    [ngClass]="{'maximized': maximized,'tableHidden': (maximized && !showVizTable), 'compare-enabled': enableCompareMode}"
>
    <div id="vizChartArea" class="vizChart">
        <app-hbar-chart
            [parentId]="chartConfig?.parentSelector"
            [slicerAxisConfig]="chartConfig?.axis?.[1]?.[0]"
            [valueAxisConfig]="chartConfig?.axis?.[0]?.[0]"
            [dataSource]="chartConfig?.dataSource ?? []"
            [compareDataSource]="chartConfig?.dataCompareSource"
            [margin]="chartConfig?.margin"
            [colorConfig]="colorConfig"
            [valueFormatSettings]="chartConfig?.formatter"
            [isMaximized]="maximized"
            [useNewLegend]="useNewLegend"
            [legendConfig]="!useNewLegend && chartConfig?.legend?.showCustom && !showVizTable ? chartConfig?.legend : undefined"
            [highlightValueOnHover]="!!chartConfig?.highlightYValueOnHover"
            [gridLineConfig]="chartConfig?.showGridLines ? chartConfig?.gridLines?.x : undefined"
            [labelPosition]="chartConfig?.showCurveValues && chartConfig?.curveValues ? (chartConfig!.curveValues!.enableInsideLabel ? 'inside' : 'outside') : 'none'"
            [highlight]="chartConfig?.highlight?.data"
            [tooltipConfig]="chartConfig?.showTooltip ? chartConfig?.tooltip ?? [] : undefined"
            [hasASelectedSlicer]="!!chartConfig?.selectedSlicer"
            (rootElement)="setSelector($event)"
            (chartClicked)="onChartClicked($event.bar, $event.alreadySelected)"
            (sizeChange)="onChartSizeChange($event)"
        >
        </app-hbar-chart>
    </div>

    <div id="vizBigW" class="vizChart-table" *ngIf="maximized">
        <div class="vizChart-inner">
            <app-bigw-table-toggle [(shown)]="showVizTable" (shownChange)="onTableToggled()"></app-bigw-table-toggle>
            <div #vizDataArea id="vizDataArea" class="table-wrap" [hidden]="!showVizTable">
                <app-big-w-table
                    [widgetId]="widgetId"
                    [chartConfig]="chartConfig"
                    [plottedChartData]="bigWTableData"
                    [plottedCompareData]="bigWTableCompareData"
                    [slicerInfo]="slicerInfo"
                    [availableHeight]="vizDataArea.getBoundingClientRect().height"
                >
                </app-big-w-table>
<!--                This needs tests when its time to switch-->
<!--                <app-chart-data-table-->
<!--                    [slicerDisplayName]="slicerDisplayName"-->
<!--                    [valueDisplayName]="valueDisplayName"-->
<!--                    [data]="chartTableData"-->
<!--                    [compareData]="chartTableCompareData"-->
<!--                >-->
<!--                </app-chart-data-table>-->
            </div>
        </div>
    </div>
</div>
