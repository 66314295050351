<svg version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px"
        width="20px" height="20px"
        viewBox="0 0 20 20" enable-background="new 0 0 20 20"
        xml:space="preserve">
    <path opacity="0.5" fill="#78797C" enable-background="new    " d="M7.857,14.339H6.66V7.05h10.53v1.357L18,8.837V6.239H5.851v8.908
                        h2.063L7.857,14.339z"/>
    <path opacity="0.5" fill="#78797C" enable-background="new    " d="M18,5.44L0.99,5.427V2.78c0-0.766,0.546-1.391,1.215-1.391h14.58
                        C17.457,1.39,18,2.015,18,2.78V5.44z M1.801,4.618L17.19,4.63V2.78c0-0.314-0.185-0.58-0.405-0.58H2.205
                        c-0.219,0-0.404,0.266-0.404,0.58V4.618z"/>
    <path opacity="0.5" fill="#78797C" enable-background="new    " d="M5.041,15.147H2.387c-0.766,0-1.39-0.543-1.39-1.215L0.99,6.239
                        h4.051V15.147z M1.801,10.013l0.007,0.404v3.518c0,0.219,0.265,0.404,0.579,0.404H4.23V7.05h-2.43V10.013z"/>
    <g>
        <circle cx="14.505" cy="14.151" r="4.541"/>
        <path fill="#78797C" d="M17.809,11.005c-0.935-0.932-2.16-1.396-3.383-1.395c-1.225,0.002-2.447,0.471-3.38,1.404
                            c-1.864,1.871-1.86,4.9,0.011,6.764c0.935,0.932,2.159,1.398,3.383,1.396c1.225-0.004,2.447-0.473,3.38-1.406
                            C19.684,15.897,19.679,12.87,17.809,11.005 M17.297,17.249c-0.762,0.764-1.777,1.188-2.857,1.189
                            c-1.082,0.002-2.098-0.418-2.863-1.182c-1.582-1.574-1.584-4.143-0.01-5.723c0.764-0.764,1.778-1.188,2.86-1.189
                            c1.08,0,2.097,0.418,2.862,1.182c0.765,0.764,1.188,1.779,1.189,2.859S18.061,16.483,17.297,17.249 M14.796,12.185h-0.735
                            l0.003,1.84l-1.839,0.002l0.001,0.736l1.839-0.002l0.003,1.838h0.737l-0.004-1.84l1.839-0.002l-0.001-0.736L14.8,14.024
                            L14.796,12.185z"/>
    </g>
</svg>
