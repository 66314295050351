import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MultiSubscriptionComponent } from '@ddv/common-components';
import { MetadataService } from '@ddv/datasets';
import { FieldMetadata, SummaryConfig, VizConfigs, DatasetMetadata, FieldMetadataGroup, MetadataLookup } from '@ddv/models';

import { FieldMetadataService } from '../../../services/field-metadata.service';
import { DragStateEvent } from '../field-picker-tree/field-picker-tree.component';
import { IConfigure } from './manage-widgets.model';

export const CONVERSABLE_TYPE_ATTRIBUTES = 'Conversable Type Attributes';

@Component({ template: '' })
export abstract class ConfigureComponent extends MultiSubscriptionComponent implements IConfigure {
    @Input() isAttached = true;
    @Output() updatePreview = new EventEmitter();
    isReadOnly = false;

    activelyDragging = false;
    dropZones: DropZoneConfig[] | undefined;
    searchValue: string | undefined;
    metadata: FieldMetadata[] = [];
    groupedMetadata: FieldMetadataGroup[] | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataConfigs: any = {};
    protected datasetDefinitionId: number | undefined;
    protected widgetId: number | undefined;
    protected visualizationType: string | undefined;

    protected constructor(
        private readonly metadataService: MetadataService,
        private readonly fieldMetadataService: FieldMetadataService,
    ) {
        super();
    }

    abstract getDropZones(): DropZoneConfig[];
    abstract setDataConfigs(configs: VizConfigs | SummaryConfig): void;
    abstract afterMetadataUpdate(): void;

    dragStateChanged(event: DragStateEvent): void {
        if (event.state === 'start') {
            this.activelyDragging = true;
            return this.dragStarted(event.data);
        }

        this.activelyDragging = false;
        this.dragEnded();
    }

    abstract dragStarted(metadata: FieldMetadata): void;
    dragEnded(): void {}

    // though the name is similar, is is NOT @angular/core/OnInit.  this is FB-custom
    onInit(datasetDefinitionId: number,
           widgetId: number,
           configs: VizConfigs | SummaryConfig,
           visualizationType: string): void {
        this.datasetDefinitionId = datasetDefinitionId;
        this.widgetId = widgetId;
        this.visualizationType = visualizationType;

        this.dropZones = this.getDropZones();
        if (configs) {
            this.setDataConfigs(configs);
        } else {
            this.initDataConfigs();
        }
        this.setMetadata();
    }

    preventDefault(event: { dragData: FieldMetadata, mouseEvent: DragEvent }): false {
        event.mouseEvent.preventDefault();
        return false;
    }

    // this function takes the metadata hash for each column and adds to it:  value=columnId and then turns it into an array
    setMetadata(): void {
        this.subscribeTo(this.metadataService.metadataState, (param: DatasetMetadata) => {
            if (param.lastChangedWidgetId === this.widgetId) {
                this.processFieldMetadata(param.metadata.get(this.widgetId ?? 0));
            }
        });
    }

    processFieldMetadata(fieldMetadata?: MetadataLookup): void {
        if (!fieldMetadata) {
            return;
        }

        this.groupedMetadata = this.fieldMetadataService.convertToTreeByHierarchy(fieldMetadata);

        this.metadata = Object.keys(fieldMetadata).map((key) => {
            fieldMetadata[key].value = key;
            return fieldMetadata[key];
        });

        this.afterMetadataUpdate();
    }

    initDataConfigs(): void {
        this.dropZones?.forEach((zone) => this.dataConfigs[zone.configKey] = []);
    }

    getSelectedConfigs(): VizConfigs | SummaryConfig | object {
        return this.dataConfigs;
    }

    renderPreview(): void {
        this.updatePreview.emit();
    }

    hasRequiredFields(): boolean {
        return !this.dropZones?.some((zone) => zone.mandatory && !this.dataConfigs[zone.configKey].length);
    }
}

export interface DropZoneConfig {
    id: string;
    allowed: boolean;
    allowMultiple: boolean;
    configKey: string;
    draggable: boolean;
    visualizationType?: string[];
    maximumAllowed?: number;
    dataTypes?: string[];
    placeholder?: string;
    mandatory?: boolean;
    label?: string;
    enableAggDropDown?: boolean;
    allowDuplicate?: boolean;
    readOnly?: boolean;
}
