import { getUTCDate } from '@ddv/utils';

import { DdvDate } from './ddv-date';

export class FuzzyDate {
    constructor(public name: string, public value: string) { }

    get actualDate(): Date {
        return getUTCDate(this.value);
    }

    get formattedString(): string {
        return DdvDate.fromISOFormat(this.value).toUSPaddedFormat();
    }
}
