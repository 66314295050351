import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { BarChartData } from '../../models/chart-data';
import { BarHoverEvent } from '../../models/tooltip';
import { safeStringFormatter } from '../../safe-string-formatter';
import { setD3FormatDefaultLocale } from '../../services/base-chart.service';
import { HorizontalBarChartBarLabelPositionService } from './horizontal-bar-chart-bar-label-position.service';
import { ChartPosition } from './horizontal-bar-chart-position.service';
import { HorizontalBarChartScaleService } from './horizontal-bar-chart-scale.service';
import { HorizontalBarChartTextFormatterService } from './horizontal-bar-chart-text-formatter.service';

@Injectable()
export class HorizontalBarChartBarsService {
    public readonly barClicked$: Observable<BarClickEvent>;
    public readonly barHovered$: Observable<BarHoverEvent | undefined>;

    private bars: d3.Selection<SVGRectElement, BarChartData, SVGGElement, BarChartData> | undefined;
    private readonly barClickedSubject: Subject<BarClickEvent> = new Subject();
    private readonly barHoveredSubject: ReplaySubject<BarHoverEvent | undefined> = new ReplaySubject(1);

    constructor(
        private readonly scales: HorizontalBarChartScaleService,
        private readonly textFormatter: HorizontalBarChartTextFormatterService,
        private readonly labelPositionService: HorizontalBarChartBarLabelPositionService,
    ) {
        // Added this because in v6 of D3 the hyphen-minus was replaced with minus
        // that broke the negative values for our charts (which were previously using the hyphen-minus).
        // So now we are replacing the minus with a hyphen-minus again.
        setD3FormatDefaultLocale();

        this.barClicked$ = this.barClickedSubject.asObservable();
        this.barHovered$ = this.barHoveredSubject.asObservable();
        this.barHoveredSubject.next(undefined);
    }

    draw(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        hasNegativeValues: boolean,
        chartPosition: ChartPosition,
        clipPathId: string,
        barToHighlight: BarChartData | undefined,
        labelPosition: 'inside' | 'outside' | 'none',
        hasASelectedSlicer: boolean,
    ): void {
        this.drawBars(
            chartRootGSelection,
            data,
            compareData,
            hasNegativeValues,
            clipPathId,
            hasASelectedSlicer);

        if (labelPosition !== 'none') {
            this.addLabelsToBars(
                chartRootGSelection,
                data,
                compareData,
                hasNegativeValues,
                clipPathId,
                labelPosition === 'inside');
        }

        if (hasNegativeValues) {
            this.drawLineAtZeroValue(chartRootGSelection, chartPosition.heightForBars);
        }

        if (barToHighlight) {
            this.highlightBar(barToHighlight);
        }
    }

    clearHighlight(): void {
        this.bars?.classed('disabled', false)
            .classed('enabled', false);
    }

    highlightBar(data: BarChartData): void {
        if (!this.bars) {
            return;
        }

        const barKey = convertSafeNameToId(data);
        const selBar = this.bars.filter(`._${barKey}`);
        if (selBar.size() === 0) {
            return;
        }

        const disabledBars = this.bars.filter('.disabled').size();
        if (disabledBars === 0 || (disabledBars > 0 && !selBar.classed('enabled'))) {
            this.bars.classed('disabled', true).classed('enabled', false);
            selBar.classed('disabled', false).classed('enabled', true);
        } else {
            this.clearHighlight();
        }
    }

    private drawBars(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        hasNegativeValues: boolean,
        clipPathId: string,
        hasASelectedSlicer: boolean,
    ): void {
        let barThickness = this.scales.barThickness();
        if (compareData) {
            barThickness = barThickness / 2 - 5;
        }

        this.bars = chartRootGSelection.selectAll()
            .data(data)
            .enter()
            .append('rect')
            .attr('class', (d) => `bar _${convertSafeNameToId(d)}`)
            .attr('x', (d) => this.scales.horizontalValueForBar(d))
            .attr('y', (d) => this.scales.verticalPositionForBar(d))
            .attr('width', (d) => this.scales.widthForBar(d, hasNegativeValues))
            .attr('height', barThickness)
            .attr('fill', (d) => d.color ?? '')
            .attr('stroke', 'gray')
            .attr('clip-path', () => `url(#${clipPathId})`);

        this.applyMouseInteractionToBars(this.bars, true);

        if (compareData) {
            const compareSelection = this.bars
                .exit()
                .remove()
                .data(compareData)
                .enter()
                .append('rect')
                .attr('class', (d) => `bar _${convertSafeNameToId(d)} compare`)
                .attr('x', (d) => this.scales.horizontalValueForBar(d))
                .attr('y', (d) => this.scales.verticalPositionForBar(d) + this.scales.barThickness() / 2)
                .attr('width', (d) => this.scales.widthForBar(d, hasNegativeValues))
                .attr('height', () => barThickness)
                .attr('fill', (d) => hasASelectedSlicer ? d.color ?? '' : 'lightgray')
                .attr('stroke', 'gray')
                .attr('clip-path', () => `url(#${clipPathId})`);

            this.applyMouseInteractionToBars(compareSelection, false);
        }
    }

    private applyMouseInteractionToBars(
        selection: d3.Selection<SVGRectElement, BarChartData, SVGGElement, BarChartData>,
        includeClick: boolean,
    ): void {
        selection.on('mousemove', (event: MouseEvent, d) => {
            this.barHoveredSubject.next({
                data: d,
                mouseEvent: event,
                coordinates: d3.pointer(event),
            });
        }).on('mouseout', () => {
            this.barHoveredSubject.next(undefined);
        });

        if (includeClick) {
            selection.on('click touchend', (event: MouseEvent, d) => {
                const gList = selection.nodes();
                const clickedIndex = gList.indexOf(event.currentTarget as SVGRectElement);
                const alreadySelected = d3.select(gList[clickedIndex]).classed('enabled');
                this.highlightBar(d);
                this.barClickedSubject.next({
                    data: d,
                    enabled: gList.length === 1 ? alreadySelected : !d3.select(gList[clickedIndex]).classed('enabled'),
                });
            });
        }
    }

    private drawLineAtZeroValue(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        heightForBars: number,
    ): void {
        const customClass = null;
        chartRootGSelection.append('g')
            .attr('class', `x axis negative ${customClass}`)
            .append('line')
            .attr('y2', heightForBars)
            .attr('x1', this.scales.valueToHorizontalPosition(0))
            .attr('x2', this.scales.valueToHorizontalPosition(0));
    }

    private addLabelsToBars(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        compareData: BarChartData[] | undefined,
        hasNegativeValues: boolean,
        clipPathId: string,
        insideLabel: boolean,
    ): void {
        this.labelPositionService.configure(insideLabel, !!compareData);

        this.addBarLabelsForDataset(
            chartRootGSelection,
            data,
            hasNegativeValues,
            clipPathId,
            false);

        if (compareData) {
            this.addBarLabelsForDataset(
                chartRootGSelection,
                compareData,
                hasNegativeValues,
                clipPathId,
                true);
        }
    }

    private addBarLabelsForDataset(
        chartRootGSelection: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        hasNegativeValues: boolean,
        clipPathId: string,
        isCompareData: boolean,
    ): void {
        chartRootGSelection.select('g.labels-group').remove();

        const group = chartRootGSelection.append<SVGGElement>('g')
            .attr('class', 'labels-group ')
            .attr('clip-path', () => `url(#${clipPathId})`);

        this.positionLabelsOnBars(group, data, hasNegativeValues, isCompareData);
        this.positionBackgroundsBehindLabels(group, data);
    }

    private positionLabelsOnBars(
        labelsGroup: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
        hasNegativeValues: boolean,
        isCompareData: boolean,
    ): void {
        labelsGroup.selectAll()
            .data(data)
            .enter()
            .append('text')
            .attr('dy', '.35em')
            .attr('pointer-events', 'none')
            .classed('label', true)
            .text((d) => this.textFormatter.formatValue(Number(d.value.value)))
            .attr('transform', (bar: BarChartData, index: number, textList: SVGTextElement[] | ArrayLike<SVGTextElement>) => {
                const position = this.labelPositionService.positionLabelForBar(
                    bar,
                    textList[index].getBBox(),
                    hasNegativeValues,
                    isCompareData);

                return `translate(${position.coordinates.x},${position.coordinates.y})`;
            })
            .text((d: BarChartData, index: number, textList: SVGTextElement[] | ArrayLike<SVGTextElement>) => {
                const position = this.labelPositionService.positionForBar(d);
                const textValue = d3.select(textList[index]).text();

                return position.fitsInsideBar && Number(textValue) !== 0 ? textValue : '';
            })
            .style('fill', (d: BarChartData) => {
                const position = this.labelPositionService.positionForBar(d);
                if (position.isInside) {
                    return !position.fitsInsideBar ? false : 'rgb(255, 255, 255)';
                }

                return 'var(--main-text-color)';
            });
    }

    private positionBackgroundsBehindLabels(
        labelsGroup: d3.Selection<SVGGElement, BarChartData, null, undefined>,
        data: BarChartData[],
    ): void {
        labelsGroup.selectAll()
            .data(data)
            .enter()
            .insert('rect', '.label')
            .attr('pointer-events', 'none')
            .attr('transform', (d) => {
                const position = this.labelPositionService.positionForBar(d);
                return position.background ? `translate(${position.background.x},${position.background.y})` : '';
            })
            .attr('height', (d) => {
                const position = this.labelPositionService.positionForBar(d);
                return position.background?.height ?? 0;
            })
            .attr('width', (d) => {
                const position = this.labelPositionService.positionForBar(d);
                return position.background?.width ?? 0;
            })
            .attr('rx', 2)
            .attr('ry', 2)
            .style('fill', (d: BarChartData) => {
                const position = this.labelPositionService.positionForBar(d);
                return position.background ? 'rgba(35, 31, 32, .54)' : 'transparent';
            });
    }
}

export interface BarClickEvent {
    data: BarChartData;
    enabled: boolean;
}

// exporting really only so we can write unit tests on it
export function convertSafeNameToId(data: { key: { value: string } }, defaultName = 'Blanks'): string {
    let name: string;

    if (safeStringFormatter(data.key.value)) {
        name = safeStringFormatter(data.key.value);
    } else {
        name = defaultName;
    }

    return name ? name.replace(/[^a-zA-Z\d]/g, '') : name;
}
