import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CommentCellEditorComponent } from './comment-cell-editor/comment-cell-editor.component';
import {
    UserDefinedFieldCellEditorComponent,
} from './user-defined-field-cell-editor/user-defined-field-cell-editor.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        CommentCellEditorComponent,
        UserDefinedFieldCellEditorComponent,
    ],
    exports: [
        CommentCellEditorComponent,
        UserDefinedFieldCellEditorComponent,
    ],
})
export class CellEditorsModule {}
