import { Component } from '@angular/core';
import { DataRange, IAxis, VisualizationPreferences, VizConfigs } from '@ddv/models';

import { BarAxisID } from '../../../models/chart.constants';
import { FormatVizComponent } from '../format-viz/format-viz.component';

@Component({
    templateUrl: '../format-viz/format-viz.component.html',
    styleUrls: ['../format-viz/format-viz.component.scss'],
})
export class FormatBarComponent extends FormatVizComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override getSelectedConfigs(dataConfig: VizConfigs): any {
        if (super.visualizationAllowsCustomSort()) {
            super.setupCustomSort(dataConfig);
        }
        const sliceManagementFormatter = this.formatters.find((f) => f.id === 'enableSliceManagement');
        if (sliceManagementFormatter) {
            super.setSliceManagement(sliceManagementFormatter);
        }
        const appliedFormatters = {};
        this.formatters.forEach((config) => this.getMergedConfigs(config as VisualizationPreferences, appliedFormatters));
        if (this.vizType === 'VERTICAL_BAR_CHART' || this.vizType === 'VERTICAL_STACKED_BAR_CHART' ||
            this.vizType === 'VERTICAL_MIRRORED_BAR_CHART') {
            this.formatVerticalBarAxisConfigs(appliedFormatters);
        }
        if (this.vizType === 'HORIZONTAL_BAR_CHART' || this.vizType === 'HORIZONTAL_STACKED_BAR_CHART') {
            this.formatHorizontalBarAxisConfigs(appliedFormatters);
        }
        return appliedFormatters;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override renderPreview(event?: { target: any }, configId?: string): void {
        if (this.vizType === 'HORIZONTAL_BAR_CHART' && configId === 'barYAxis') {
            this.setLegendConfig(this.formatters, event?.target as boolean);
        }
        super.renderPreview(event, configId);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private formatVerticalBarAxisConfigs(configs: any): void {
        configs.axisConfigurations = [
            {
                position: 'bottom',
                dataRangeMode: DataRange.AUTO,
                ...this.getAxisCommonProperty('xAxis', configs),
            },
            {
                position: configs.barYAxisPosition ? 'left' : 'right',
                dataRangeMode: DataRange.AUTO,
                ...this.getAxisCommonProperty('yAxis', configs),
            },
        ];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private formatHorizontalBarAxisConfigs(configs: any): void {
        configs.axisConfigurations = [
            {
                position: 'bottom',
                dataRangeMode: DataRange.AUTO,
                ...this.getAxisCommonProperty('xAxis', configs),
            },
            {
                position: 'left',
                dataRangeMode: DataRange.AUTO,
                ...this.getAxisCommonProperty('yAxis', configs),
            },
        ];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getAxisCommonProperty(axisType: AxisType, configs: any): Partial<IAxis> {
        switch (axisType) {
            case 'xAxis':
                return {
                    axis: BarAxisID.X_AXIS,
                    enableAxis: configs.barXAxis,
                    displayAxisLabels: configs.barXAxisLabels,
                    enableAxisLine: configs.barXAxisLine,
                    maxDataRange: undefined,
                    minDataRange: undefined,
                };
            case 'yAxis':
                return {
                    axis: BarAxisID.Y_AXIS,
                    enableAxis: configs.barYAxis,
                    displayAxisLabels: configs.barYAxisLabels,
                    enableAxisLine: configs.barYAxisLine,
                    maxDataRange: undefined,
                    minDataRange: undefined,
                };
        }
    }
}

export type AxisType = 'xAxis' | 'yAxis';
