import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
    selector: 'app-conversation-history-icon',
    templateUrl: './conversation-history-icon.component.html',
    styleUrls: ['./conversation-history-icon.component.scss'],
})
export class ConversationHistoryIconComponent {
    @Output() pressed: EventEmitter<MouseEvent> = new EventEmitter();

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        event.stopImmediatePropagation();
        event.preventDefault();

        this.pressed.emit(event);
    }
}
