import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    // this thing is garbage.  it gets thrown so many types and returns so many types that its basically unusable in a template
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform<T>(items: T[] | string[] | null, searchText: string, filterParam?: string): any { // T[] | string[] {
        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        const text = searchText.toLowerCase();

        if (filterParam) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (items as T[]).filter((item) => `${(item as any)[filterParam]}`.toLowerCase().indexOf(text) !== -1);
        }

        return (items as string[]).filter((item) => item.toLowerCase().indexOf(text) !== -1);
    }
}
