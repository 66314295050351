import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { Observable } from 'rxjs';

@Injectable()
export class PublicApiService {
    private clientCode = '';

    constructor(
        private readonly currentStateService: CurrentStateService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
    ) {
        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    getSavedViewAsQuery(savedViewId: number): Observable<string> {
        return this.trebekApiExecutor.invokeService(
            this.clientCode,
            `/saved-view-to-query/${savedViewId}`,
            'POST',
            'text');
    }
}
