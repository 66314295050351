import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { UserService } from '@ddv/entitlements';
import { ApiServices, ApiExecutorService } from '@ddv/http';
import { Fund } from '@ddv/models';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FundsService {
    private funds$: Observable<Fund[]> | undefined;

    constructor(
        private readonly currentState: CurrentStateService,
        private readonly userService: UserService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
    ) {}

    private get fundsEndpoint(): Observable<{ clientCode: string, route: string}> {
        return this.currentState.clientCode$.pipe(map((clientCode) => {
            return {
                clientCode,
                route: '/reference-data/funds',
            };
        }));
    }

    funds(): Observable<Fund[]> {
        if (!this.funds$) {
            this.funds$ = combineLatest([this.userService.userPreferences$, this.getFunds()])
                .pipe(map(([preferences, data]) => this.mapFunds(data, preferences.fundNamesSource)))
                .pipe(shareReplay(1)); // replays only most recent
        }

        return this.funds$;
    }

    private getFunds(): Observable<Partial<Fund>[]> {
        return this.fundsEndpoint.pipe(
            mergeMap(({ clientCode, route }) => this.trebekApiExecutor.invokeServiceWithParams<Partial<Fund>[]>(clientCode, route)),
        );
    }

    private mapFunds(data: Partial<Fund>[], fundNamesSource: string): Fund[] {
        const funds: Fund[] = data.map((fund) => new Fund(fund, fundNamesSource));
        funds.sort((a, b) => a.fundName.toLowerCase().localeCompare(b.fundName.toLowerCase()));
        return funds;
    }
}
