import { Component, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
    selector: 'app-download-attachments-icon',
    templateUrl: './download-attachments-icon.component.html',
    styleUrls: ['./download-attachments-icon.component.scss'],
})
export class DownloadAttachmentsIconComponent {
    @Output() pressed: EventEmitter<void> = new EventEmitter();

    @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
        event.stopImmediatePropagation();
        event.preventDefault();

        this.pressed.emit();
    }
}
