import { ColumnDisplayType } from '../fields/column-display-type';
import { FieldMetadata } from '../fields/field-metadata';

export class HSColumnDefinition implements FieldMetadata {
    agBand = '';
    alignment = '';
    canAggregate = false;
    canPivotOn = false;
    datatype = '';
    description = '';
    displayName = '';
    hierarchy = '';
    isAttribute = false;
    isDynamic = false;
    isOrdinal = false;
    name = '';
    zeroIfNone = false;
    displayType: ColumnDisplayType = 'string';
    format = '';
    value: string | undefined;
    nameChecked = false;
    editable = false;

    public constructor(init?: FieldMetadata) {
        Object.assign(this, init);
    }
}
