<div class="action-icons">
    @for (descriptor of iconDescriptors; track descriptor.actionName) {
        <ddv-action-icon
            [actionName]="descriptor.actionName"
            [iconName]="descriptor.iconName"
            (actionPressed)="actionPressed.emit($event)"
        >
        </ddv-action-icon>
    }
</div>
@if (filterName) {
    <div class="vertical-line-break">|</div>
    <cc-option-toggle
        [labels]="{ trueLabel: filterName, falseLabel: '' }"
        [(value)]="filterActive"
        (valueChange)="filterActiveChange.emit($event)"
    >
    </cc-option-toggle>
}
