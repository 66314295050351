<div class="configure">
    <span class="label-text">* Required Fields</span>

    <div class="disp-flex" cdkDropListGroup>
        <div class="left-flex">
            <app-field-picker-tree
                [tree]="groupedMetadata"
                [readonly]="isReadOnly"
                (dragStateChanged)="dragStateChanged($event)">
            </app-field-picker-tree>
        </div>

        <div class="right-flex">
            <ng-container *ngFor="let dataConfig of dataConfigs; let i = index;">
                <ng-container *ngFor="let zone of dropZones;">
                    <div class="value-drag-parent" [ngClass]="{'readonly': isReadOnly}">
                        <h3>{{ zone.label }} {{i + 1}}{{ zone.mandatory ? '*' : '' }}
                            <span *ngIf="zone.configKey === 'summary'">: {{ dataConfig.summary && !dataConfig.summary.isEmpty && dataConfig.summary.summaryWidgetColumnConfiguration
                                ? dataConfig.summary.summaryWidgetColumnConfiguration.label || dataConfig.summary.summaryLabel
                                : '' }}</span>
                        </h3>

                        <div
                                class="drag-wrap"
                                cdkDropList
                                [ngClass]="{'disabled':!zone.allowed && activelyDragging,'allowed-area':zone.allowed && activelyDragging}"
                                (cdkDropListDropped)="onCdkDropSuccess($event, zone, i)"
                                [cdkDropListData]="dataConfig">

                            <div cdkDropList [cdkDropListDisabled]="true">
                                <ng-container *ngIf="zone.configKey === 'summary'; else filtersBlock">
                                    <div class="drag-list" *ngIf="!dataConfig.summary.isEmpty">
                                        <span class="icon-threedots"></span>

                                        <label class="item">{{ dataConfig.summary.summaryLabel }}</label>

                                        <ng-container
                                            *ngTemplateOutlet="downArrowTemplate; context: {zone: zone, item: dataConfig.summary, i: i}">
                                        </ng-container>

                                        <span class="icon-close1" (click)="removeConfigItemAtIndex('summary', i)"></span>
                                    </div>
                                </ng-container>

                                <ng-template #filtersBlock>
                                    <div class="drag-list" [id]="j" cdkDrag *ngFor="let item of dataConfig.filters; let j = index;">
                                        <span class="icon-threedots"></span>

                                        <label class="item">{{ item.filterLabel }}</label>

                                        <ng-container
                                            *ngTemplateOutlet="downArrowTemplate; context: {zone: zone, item: item, i: i, j: j}">
                                        </ng-container>

                                        <span class="icon-close1" (click)="removeConfigItemAtIndex('filters', i, j)"></span>
                                    </div>
                                </ng-template>
                            </div>

                            <label class="drag-placeholder"> {{ zone.placeholder }} </label>
                        </div>
                    </div>


                </ng-container>
                <hr class="hr-line">
            </ng-container>

            <div
                class="add-summary"
                [ngClass]="{'readonly': isReadOnly, 'disabled': dataConfigs.length === maxSummaries}"
                (click)="initDataConfigs()"
            >
                <cc-icon
                    [icon]="'plus-circle'"
                    class="add-visualization-icon"
                    [size]="'xsmall'"
                    [disabled]="isReadOnly || dataConfigs.length === maxSummaries"
                    [hoverColor]="'var(--dsd-selected-text)'"
                    (click)="initDataConfigs()">
                </cc-icon>

                <label>Add Summary</label>
            </div>
        </div>
    </div>
</div>

<ng-template #downArrowTemplate let-zone="zone" let-item="item" let-i="i" let-j="j">
    <span class="icon-arrow-down" *ngIf="zone.configKey === 'summary'" (click)="showColumnFormatters(item)"></span>

    <div class="dropdown-wrapper summary-wrapper" *ngIf="zone.configKey === 'filters'" appMousedownOutside (mousedownOutside)="hideFiltersValues(filterValueList, i, j)">
        <span class="icon-arrow-down" (click)="toggleFilterValues(filterValueList, item, i, j)"></span>

        <div #filterValueList class="dropdown summary-list" [hidden]=true>
            <div class="search-box">
                <input
                    type="search"
                    maxlength="50"
                    name="filterSearchValue"
                    autocomplete="off"
                    [(ngModel)]="filterSearchValue">

                <span class="icon-search1"></span>

                <button
                        class="empty-btn"
                        type="button"
                        (click)="addToFilterValues()">
                    Add
                </button>
            </div>

            <p (click)="uncheckFilterValues()">Clear Selected Items</p>

            <cc-checkbox
                    class="checkbox"
                    *ngFor="let filterValue of filterValues | textValueFilter : filterSearchValue"
                    [label]="!filterSearchValue ? filterValue.text : ''"
                    [value]="getFilterChecked(filterValue)"
                    (valueChange)="setFilterChecked($event, filterValue)">
                <label
                    class="highlight"
                    [innerHTML]="filterSearchValue && filterValue.value | highlight : filterSearchValue">
                </label>
            </cc-checkbox>
        </div>
    </div>
</ng-template>
