import { NgModule } from '@angular/core';
import { NavigationModule } from '@ddv/navigation';

import { WINDOW } from './injection-tokens';

@NgModule({
    imports: [
        NavigationModule,
    ],
    providers: [
        { provide: WINDOW, useValue: window },
    ],
})
export class WrapperModule {}
