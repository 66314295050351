import { Injectable } from '@angular/core';
import { QueryParamsService } from '@ddv/filters';
import { FilterPreference, WidgetFilterParams } from '@ddv/models';

@Injectable()
export class WidgetQueryparamService {
    // TODO: public state
    widgetFilterParams: Map<number, FilterPreference> = new Map();

    constructor(private readonly queryParamsService: QueryParamsService) {
        this.queryParamsService.widgetQueryParams?.subscribe((params: WidgetFilterParams | undefined) => {
            if (params?.lastChangedWidgetId) {
                this.widgetFilterParams = params.widgetFilters;
            }
        });
    }
}
