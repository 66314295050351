<div class="no-data"
        *ngIf="noDataAvailable && !isDataLoading"
        [hidden]="!noDataAvailable">
    <span>NO DATA AVAILABLE</span>
</div>

<div class="clearfix"
        *ngIf="!noDataAvailable"
        [hidden]="noDataAvailable"
        [ngClass]="{'maximized': (maximized && !isGridViz),'tableHidden': (maximized && !showVizTable && !isGridViz), 'compare-enabled': enableCompareMode}">
    <div [hidden]="isGridViz" id="vizChartArea" class="vizChart">
        <app-chart #chartViz [chartConfiguration]="chartConfig" [isMaster]="isMaster" [isMaximized]="maximized"></app-chart>
    </div>

    <!-- unused -->
    <div class="vizChart" *ngIf="isGridViz">
        <app-data-grid
            #dataGrid
            [gridConfiguration]="gridConfig"
            [frameworkComponents]="allFrameworkComponents"
            (gridReadyEvent)="onGridPrepared()">
        </app-data-grid>
    </div>

    <div id="vizBigW" class="vizChart-table" *ngIf="maximized && !isGridViz">
        <div class="vizChart-inner">
            <div class="vizChart-btn-wrap">
                <button (click)="toggleVizDataArea()" class="toggle-btn">
                    <span [ngClass]="{'icon-left-expand' : !showVizTable, 'icon-right-expand' : showVizTable}"></span>
                    {{ !showVizTable ? 'Show' : 'Hide' }} Table
                </button>
            </div>
            <div #vizDataArea id="vizDataArea" class="table-wrap" [hidden]="!showVizTable && isManualToggle">
                <app-big-w-table
                    #bigWTable
                    [widgetId]="widgetId"
                    [chartConfig]="chartConfig"
                    [plottedChartData]="vizChartData"
                    [slicerInfo]="slicerInfo"
                    [chartComponent]="chartViz"
                    [availableHeight]="vizDataArea.getBoundingClientRect().height">
                </app-big-w-table>
            </div>
        </div>
    </div>
</div>
