import { Injectable } from '@angular/core';
import { AggType } from '@ddv/models';

@Injectable()
export class ValueAggregationService<T = unknown> {
    aggregateValue(
        source: T[] = [],
        valueFieldName: keyof T,
        type: AggType | undefined | null,
    ): string | number | undefined {
        switch (type) {
            case 'sum':
                return getSumValueObj(source, valueFieldName);
            case 'min':
                return getMinimumValueObj(source, valueFieldName);
            case 'max':
                return getMaximumValueObj(source, valueFieldName);
            case 'avg':
                return getAvgValueObj(source, valueFieldName);
            case 'count':
                return getCountValueObj(source);
            case 'countExcludingZeros':
                return getCountValueExcludingZerosObj(source, valueFieldName);
            case 'countDistinct':
                return getCountDistinctValueObj(source, valueFieldName);
            case 'countDistinctExcludingZeros':
                return getCountDistinctValueExcludingZerosObj(source, valueFieldName);
            case 'first':
                return getMinimumValueObj(source, valueFieldName);
            case 'last':
                return getMaximumValueObj(source, valueFieldName);
            case 'zero':
                return 0;
            default: return;
        }
    }
}

export function getSumValueObj<T>(source: T[] | undefined, valueField: keyof T): number {
    return source?.reduce((sum: number, column) => sum + (column[valueField] as number), 0) ?? 0;
}

function getMinimumValueObj<T>(source: T[], valueField: keyof T): string | number {
    return source.reduce((min, column) => {
        const rowValue = column[valueField] as string | number;
        return rowValue < min ? rowValue : min;
    }, source[0][valueField] as string | number);
}

function getMaximumValueObj<T>(source: T[], valueField: keyof T): string | number {
    return source.reduce((max, column) => {
        const rowValue = column[valueField] as string | number;
        return rowValue > max ? rowValue : max;
    }, source[0][valueField] as string | number);
}

function getAvgValueObj<T>(source: T[], valueField: keyof T): number {
    return getSumValueObj(source, valueField) / getCountValueObj(source);
}

function getCountValueObj<T>(source: T[]): number {
    return source.length;
}

function getCountValueExcludingZerosObj<T>(source: T[], valueField: keyof T): number {
    return source.filter((column) => column[valueField] !== 'N/A' && column[valueField] !== 0).length;
}

function getCountDistinctValueObj<T>(source: T[], valueField: keyof T): number {
    const tracker: Record<string | number, number> = {};
    source.forEach((item) => {
        tracker[item[valueField] as string | number] = 1;
    });
    return Object.keys(tracker).length;
}

function getCountDistinctValueExcludingZerosObj<T>(source: T[], valueField: keyof T): number {
    const tracker: Record<string | number, number> = {};
    source.forEach((item) => {
        const itemValue = item[valueField] as string | number;
        if (itemValue !== 'N/A' && itemValue !== 0) {
            tracker[itemValue] = 1;
        }
    });
    return Object.keys(tracker).length;
}
