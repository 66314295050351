import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonComponentsModule } from '@ddv/common-components';
import { HttpModule } from '@ddv/http';
import { NamedQueriesModule } from '@ddv/named-queries';
import { SocketModule } from '@ddv/socket';

import { DatasetDefinitionSelectorComponent } from './components/dataset-definition-selector/dsd-selector.component';
import { CanActivateDSDGuard } from './services/can-activate-dsd-guard.service';
import { DatasetDefinitionsService } from './services/dataset-definitions.service';
import { SavedViewService } from './services/saved-view.service';

@NgModule({
    imports: [
        CommonComponentsModule,
        CommonModule,
        SocketModule,
        HttpModule,
        RouterModule,
        NamedQueriesModule,
    ],
    providers: [
        SavedViewService,
        CanActivateDSDGuard,
        DatasetDefinitionsService,
    ],
    declarations: [
        DatasetDefinitionSelectorComponent,
    ],
    exports: [
        DatasetDefinitionSelectorComponent,
    ],
})
export class DatasetDefinitionsModule { }
