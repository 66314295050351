import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { AlertService } from '@ddv/common-components';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { Subscription } from 'rxjs';

import { ServerSocketService } from './server-socket.service';

export interface ServerSocketMessage {
    type: string;
    payload: { fileId?: string };
    error?: string;
}

@Component({
    selector: 'app-socket',
    template: '<div><iframe id="hiddenDownloader" #silentDownloader style="display:none;"></iframe></div>',
})
export class SocketUserComponent implements OnInit, OnDestroy {
    private clientCodeSubscription: Subscription | undefined;
    private isSendingSubscription: Subscription | undefined;
    private socketSubscription: Subscription | undefined;
    private clientCode: string | undefined;

    @ViewChild('silentDownloader', { static: true }) silentDownloader: ElementRef | undefined;

    constructor(
        private readonly socketService: ServerSocketService,
        private readonly currentStateService: CurrentStateService,
        private readonly alertService: AlertService,
        @Inject(ApiServices.fmp) private readonly fmpApiExecutor: ApiExecutorService,
    ) {}

    ngOnDestroy(): void {
        this.clientCodeSubscription?.unsubscribe();
        this.isSendingSubscription?.unsubscribe();
        this.socketSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.clientCodeSubscription = this.currentStateService.clientCode$.subscribe({
            next: (clientCode: string) => {
                this.clientCode = clientCode;
            },
        });

        this.isSendingSubscription = this.socketService.isSending$.subscribe({
            next: (value) => {
                if (value) {
                    this.connect();
                }
            },
        });
    }

    private connect(): void {
        const stream = this.socketService.connect();
        this.socketSubscription = stream.subscribe({
            next: (message) => {
                this.onMessageReceived(message);
            },
            error: (error) => {
                console.error('WebSocket connection error:', error);
                this.socketSubscription?.unsubscribe();
                this.connect();
            }, complete: () => {
                // WebSocket connection closed
            },
        });
    }

    private onMessageReceived(message: ServerSocketMessage): void {
        if (message.error) {
            this.alertService.error('Could not export dashboard', message.error, [], '');
        } else if (message.type === 'dashboard export') {
            if (message.error) {
                this.alertService.error('Could not export dashboard', message.error, [], '');
            } else if (!message.payload.fileId) {
                this.alertService.error('Could not export dashboard', 'No export file name', [], '');
            } else {
                const iframe = this.silentDownloader?.nativeElement as HTMLIFrameElement;
                iframe.src = `${this.fmpApiExecutor.apiRoot}/${this.clientCode}/temp-file/${message.payload.fileId}`;
            }
        } else {
            console.error('Received invalid web socket message:', message);
        }

        this.socketService.disconnect();
    }
}
