import { Component, Injector } from '@angular/core';
import { UserEntitlementService } from '@ddv/entitlements';
import { SummaryConfig } from '@ddv/models';
import { SelectedWidgetRelayService } from '@ddv/visualizations';

import { DefaultWidgetPreferences } from '../../../models/default-widget.preferences';
import { FormatComponent } from '../format.component';

@Component({
    selector: 'app-mw-format-summary',
    templateUrl: '../format-viz/format-viz.component.html',
    styleUrls: ['../format-viz/format-viz.component.scss'],
})
export class FormatSummaryComponent extends FormatComponent {
    constructor(
        private readonly injector: Injector,
        userEntitlementService: UserEntitlementService,
        selectedWidgetRelayService: SelectedWidgetRelayService,
    ) {
        super(userEntitlementService, selectedWidgetRelayService);
        this.initialize();
    }

    initialize(): void {
        this.formatters = DefaultWidgetPreferences.getSummaryPreferences().filter((formatter) => {
            return formatter.id === 'isLineSeparatorEnabled' || formatter.id === 'textSize';
        }) || [];
        const appliedFormatters: SummaryConfig = this.injector.get('appliedFormatters');
        if (!appliedFormatters) {
            return;
        }

        this.formatters.forEach((formatter) => {
            switch (formatter.id) {
                case 'isLineSeparatorEnabled':
                    formatter.selectedValue = appliedFormatters.isLineSeparatorEnabled;
                    break;
                case 'textSize':
                    formatter.selectedValue = Number(appliedFormatters.textSize);
                    break;
                case 'display':
                    formatter.selectedValue = appliedFormatters.display;
                    break;
                case 'textAlignment':
                    formatter.selectedValue = appliedFormatters.textAlignment;
            }
        });

        // eslint-disable-next-line no-self-assign
        this.formatters = this.formatters;
    }
}
