import { Injectable } from '@angular/core';
import { AlertService } from '@ddv/common-components';
import { ExecutorService } from '@ddv/http';
import { ManagerService } from '@ddv/layout';

import { ErrorHandlingResponseInterceptor } from './error-handling-response.interceptor';

@Injectable()
export class ApplicationService {
    constructor(
        private readonly executor: ExecutorService,
        private readonly alertService: AlertService,
        private readonly managerService: ManagerService,
    ) {}

    registerInterceptors(): void {
        this.executor.registerInterceptors(new ErrorHandlingResponseInterceptor(this.managerService, this.alertService));
    }
}
