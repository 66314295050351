import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { DatasetDefinitionsService } from '@ddv/dataset-definitions';
import { UserService } from '@ddv/entitlements';
import { ClientDatasetFilterService, QueryParamsService } from '@ddv/filters';
import { ApiServices, ApiExecutorService, ExecutorService } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import {
    LinkConfiguration,
    BoardWidgetDefinitionIds,
    Client,
    FLAG_NOT_SET,
    GRAPHQL_ERROR_MESSAGE,
    HI_DATA_CLIENT_ERROR_MESSAGE,
    HI_DATA_USER_ERROR_MESSAGE,
    MANAGE_WIDGET_WS_ID,
    NO_ENTITLEMENTS,
    WIDGET_LIFECYCLE_EVENT,
    CompareMode,
    DashboardPreference,
    toPrioritizedFilterParams,
    WidgetData,
    DatasetFetchRuntimeParams,
    Query,
    FilterPreference,
    FilterQueryParam,
    Fund,
    WidgetFilterParams,
    isTradeFileDetails,
    crosstalkColumns,
    DatasetDefinitionDetails,
    NamedQuery,
    UserPreferences,
    QueryTypeName,
    FuzzyDates,
    DatasetFetchKey,
} from '@ddv/models';
import { NamedQueriesService } from '@ddv/named-queries';
import { ClientsService, FundsService, FuzzyDatesService } from '@ddv/reference-data';
import { UsageTracker, UsageTrackingService } from '@ddv/usage-tracking';
import { deepCompare } from '@ddv/utils';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IDataLoad } from '../models/data-load';
import { CompareModeService } from './compare-mode.service';
import { addCrosstalkLinksToData } from './crosstalk-data.service';
import { MetadataService, splitEndpointIntoClientAndRoute } from './metadata.service';
import { areQueryParamsEqual, QueryParamsDiffer, queryParamsDifferInjectionToken } from './query-params-differ';
import { WidgetDataSourceService } from './widget-datasource.service';

interface DatasetLoadState {
    key: DatasetFetchKey;
    publicSubject?: BehaviorSubject<IDataLoad>;
    loadTime?: string;
    fetchedData?: WidgetData[];
    isLoading?: boolean;
    error?: string; // even though this has long been called "error" it seems to just be a timestamp for when an error occurred
    fetchDatasetSubscription?: Subscription;
    sendQuerySubscription?: Subscription;
    fetchDatasetCanceled?: boolean;
    widgetData?: PublicApiResponseRow[];
    widgetCompareData?: PublicApiResponseRow[];
}

@Injectable()
export class DatasetManagerService {
    private readonly stateByWidgetId: Map<number, DatasetLoadState> = new Map();

    private sourceDataCopy: PublicApiResponseRow[] | undefined;
    private dashboardPref: DashboardPreference | undefined;
    private dashboardQueryParam: FilterQueryParam | undefined;
    private readonly widgetFilterParam: Map<number, FilterQueryParam> = new Map();
    private widgetQueryParam: FilterPreference | undefined;
    private dashboardSubscription: Subscription | undefined;
    private widgetSubscription: Subscription | undefined;
    private fundOptions: Fund[] = [];
    private clientOptions: Client[] = [];
    private userPreference: UserPreferences | undefined;
    private isMultiClient = false;
    private fullClientsList = '';
    private readonly areParamsTheSame: QueryParamsDiffer;
    private fuzzyDates: FuzzyDates = new FuzzyDates(); // this will get overwritten by the FuzzyDatesService

    constructor(
        currentStateService: CurrentStateService,
        private readonly managerService: ManagerService,
        private readonly fundsService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly userService: UserService,
        private readonly widgetDataSourceService: WidgetDataSourceService,
        private readonly queryParamsService: QueryParamsService,
        private readonly datasetDefinitionsService: DatasetDefinitionsService,
        private readonly usageTracking: UsageTrackingService,
        fuzzyDatesService: FuzzyDatesService,
        private readonly metadataService: MetadataService,
        private readonly compareModeService: CompareModeService,
        private readonly clientDatasetFilterService: ClientDatasetFilterService,
        private readonly namedQueriesService: NamedQueriesService,
        @Inject(ApiServices.trebek) private readonly trebekApiExecutor: ApiExecutorService,
        private readonly executor: ExecutorService,
        @Inject(queryParamsDifferInjectionToken) @Optional() injectedQueryParamsDiffer: QueryParamsDiffer | null,
    ) {
        this.areParamsTheSame = injectedQueryParamsDiffer ?? areQueryParamsEqual;

        combineLatest([this.widgetDataSourceService.dataSource$, this.compareModeService.currentWidgetInCompareMode])
            .subscribe({
                next: ([res, currentWidgetId]) => {
                    if (!res.lastChangedDataset) {
                        return;
                    }
                    const datasource = res.datasources.find((dw) => dw.uniqueKey === res.lastChangedDataset);
                    if (!datasource) {
                        return;
                    }

                    this.stateByWidgetId.forEach((state, widgetId) => {
                        if (state.key === res.lastChangedDataset) {
                            if (widgetId === currentWidgetId) {
                                this.compareModeService.updateCompareDataByWidgetId(widgetId, datasource.compareData);
                            }

                            this.stateByWidgetId.get(widgetId)?.publicSubject?.next({
                                isLoaded: true,
                                data: datasource?.data,
                                compareData: this.compareModeService.compareDataMap.get(widgetId),
                                compareMode: datasource?.compareMode,
                                key: state.key,
                            });

                            state.fetchedData = datasource.data;
                        }
                    });
                },
            });

        // this is only here to get a user's default dates
        // which is used to check if the dashboard query params have changed
        // and to build default query params
        this.userService.userPreferences$.subscribe({
            next: (userPreferences) => {
                this.userPreference = userPreferences;
            },
        });

        currentStateService.isMultiClient$
            .pipe(switchMap((isMultiClient) => {
                this.isMultiClient = isMultiClient;

                if (this.isMultiClient) {
                    return this.clientsService.clients();
                }

                return this.fundsService.funds();
            }))
            .subscribe({
                next: (data: Fund[] | string[]) => {
                    if (data.some((datum) => datum instanceof Fund)) {
                        this.fundOptions = data as Fund[];
                    } else {
                        this.fullClientsList = data.join(',');
                        this.clientOptions = (data as string[]).map((clientCode) => {
                            return { clientId: clientCode, clientName: clientCode };
                        });
                    }
                },
            });

        fuzzyDatesService.fuzzyDates()
            .subscribe({
                next: (fuzzyDates) => this.fuzzyDates = fuzzyDates,
            });

        fuzzyDatesService.fuzzyDates()
            .subscribe({
                next: (fuzzyDates) => this.fuzzyDates = fuzzyDates,
            });
    }

    // this is only called when the user switches dashboards in view mode
    clearDashboardPreference(): void {
        this.dashboardPref = undefined;
        this.dashboardQueryParam = undefined;
    }

    getUniqueKey(
        dashboardId: string | number,
        widgetId: number,
        namedQueryId: string | number,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): DatasetFetchKey {
        const state = this.stateByWidgetId.get(widgetId);
        if (state) {
            return state.key;
        }

        let key = this.findSharedKey(namedQueryId, isSubscribedToDashboardFilters);
        if (!key) {
            key = {
                namedQueryId,
                dashboardId,
                sourceType: isSubscribedToDashboardFilters ? 'dashboard' : 'widget',
                sourceId: isSubscribedToDashboardFilters ? namedQueryId : widgetId,
            };
        }

        this.stateByWidgetId.set(widgetId, { key });
        return key;
    }

    // this method looks super dodgy.  how could it have only a single set of rows?  this class loads many row-sets
    getSourceDataCopy(): PublicApiResponseRow[] | undefined {
        return this.sourceDataCopy;
    }

    // Only called by ApplicationBaseWidget.fetchDataset
    getDataset(
        clientCode: string,
        dashboardId: string | number,
        widgetId: number, // i dont think this is the widgetId.  i think it's actually the widgetPreferencesId which is something else
        namedQueryId: number | string,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): Observable<IDataLoad> {
        const state = this.getOrCreateState(dashboardId, widgetId, namedQueryId, isSubscribedToDashboardFilters);

        // the dashboard and widget params subscriptions below do not belong here:
        //  - there is an implicit assumption that by subscribing HERE, that they won't emit while the current round of fetching data completes
        //  - something else should be aware of these changes and then just calling here to initiate a new fetch
        if (!this.dashboardSubscription) {
            this.subscribeDashboardQueryParams(clientCode);
        }
        if (!this.widgetSubscription) {
            this.subscribeWidgetQueryParams(clientCode, isSubscribedToDashboardFilters);
        }

        if (isSubscribedToDashboardFilters) {
            if (state.fetchedData) {
                if (state.error) {
                    this.sendMessage(
                        widgetId,
                        {
                            action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                            exception: {
                                message: GRAPHQL_ERROR_MESSAGE,
                                time: state.error,
                            },
                        });
                }
                setTimeout(
                    () => state.publicSubject.next({
                        isLoaded: !state.isLoading,
                        data: state.fetchedData,
                        key: state.key,
                    }), 100);
            } else {
                if (!state.fetchDatasetSubscription) {
                    state.isLoading = true;
                    if (this.dashboardPref) {
                        const ids = new BoardWidgetDefinitionIds(dashboardId, widgetId, namedQueryId);

                        const widgetComparing = this.widgetQueryParam?.comparing;
                        const dashboardComparing = this.dashboardQueryParam?.comparing;
                        const mode = !isSubscribedToDashboardFilters ?
                            this.widgetQueryParam?.comparing :
                            ids.dashboardId === undefined ? widgetComparing : dashboardComparing;

                        this.fetchData(
                            ids,
                            isSubscribedToDashboardFilters ? this.dashboardPref : this.widgetQueryParam,
                            mode,
                            state.key,
                            clientCode);
                    }
                }
            }

            if (state.fetchDatasetCanceled) {
                this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.DATA_LOADING_CANCELLED);
            } else if (state.isLoading) {
                this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);
            }
        }

        return state.publicSubject.asObservable();
    }

    refreshDataset(clientCode: string, key: DatasetFetchKey): void {
        const widgetId = [...this.stateByWidgetId.entries()].find(([, state]) => state.key === key)?.[0];
        if (!widgetId) {
            return;
        }

        if (this.dashboardPref) {
            const ids = new BoardWidgetDefinitionIds(key.dashboardId, widgetId, key.namedQueryId);

            const widgetComparing = this.widgetQueryParam?.comparing;
            const dashboardComparing = this.dashboardQueryParam?.comparing;
            const mode = key.sourceType === 'widget' ?
                this.widgetQueryParam?.comparing :
                ids.dashboardId === undefined ? widgetComparing : dashboardComparing;

            this.fetchData(
                ids,
                key.sourceType === 'widget' ? this.widgetQueryParam : this.dashboardPref,
                mode,
                key,
                clientCode);
        }
    }

    loadNoData(
        dashboardId: string | number,
        widgetId: number | undefined,
        namedQueryId: number | string | undefined,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): void {
        if (!widgetId || !namedQueryId) {
            return;
        }

        const uniqueKey = this.getUniqueKey(dashboardId, widgetId, namedQueryId, isSubscribedToDashboardFilters);
        setTimeout(() => {
            const dataWrapper = { uniqueKey, data: [], compareMode: undefined };
            this.widgetDataSourceService.addDataSource(dataWrapper);
        }, 0);
    }

    removeDataset(widgetId: number | undefined): void {
        if (!widgetId) {
            return;
        }

        const key = this.stateByWidgetId.get(widgetId)?.key;

        this.stateByWidgetId.delete(widgetId);
        this.widgetFilterParam.delete(widgetId);
        this.queryParamsService.removeWidgetQueryParam(widgetId);

        if (key) {
            this.cancelRequest(key);
            for (const state of this.stateByWidgetId.values()) {
                if (state.key === key) {
                    state.fetchedData = undefined;
                    state.error = undefined;
                    state.fetchDatasetCanceled = false;
                }
            }
            this.deleteDataLoadedTime(key);
            this.widgetDataSourceService.removeDataSource(key);
        }
    }

    // TODO: this method should not exist.  this property should just be on the IDataLoad interface
    getDataLoadedTime(widgetId: number): string | undefined {
        return this.stateByWidgetId.get(widgetId)?.loadTime;
    }

    private findSharedKey(
        namedQueryId: string | number,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): DatasetFetchKey | undefined {
        return [...this.stateByWidgetId.values()].find((s) => {
            // only subscribed widgets share datasets
            if (isSubscribedToDashboardFilters && s.key.sourceType === 'dashboard' && s.key.namedQueryId === namedQueryId) {
                return s.key;
            }
        })?.key;
    }

    private getQueryParam(queryParam: DashboardPreference | FilterPreference | undefined): FilterQueryParam {
        return {
            startDate: queryParam?.startDate,
            endDate: queryParam?.endDate,
            activeDate: queryParam?.activeDate,
            funds: queryParam?.funds,
            comparing: queryParam?.comparing,
            isComparing: queryParam?.isComparing,
            compareDates: queryParam?.compareDates,
            isPreferenceChangedOnRefresh: (queryParam as DashboardPreference)?.isPreferenceChangedOnRefresh, // only on DashboardPreference
            timestamp: (queryParam as FilterPreference)?.timestamp, // only on FilterPreference
            clients: queryParam?.clients,
            acknowledged: (queryParam as DashboardPreference)?.acknowledged, // only on DashboardPreference
            // for net-settlement, we have no reason to stash this on the dashboard preference as we never save the state
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            includeManuallyReleased: (queryParam as any)?.includeManuallyReleased, // only relevant for net-settlement breaks
        };
    }

    private subscribeDashboardQueryParams(clientCode: string): void {
        this.dashboardSubscription = this.queryParamsService.dashboardQueryParams.subscribe({
            next: (dashboardPref: DashboardPreference) => {
                // stashing this here because its needed by the realtime refresh
                this.dashboardPref = dashboardPref;

                const dashboardId = this.managerService.getCurrentDashboardId();
                const currentQueryParam = this.getQueryParam(dashboardPref);

                const paramsAreTheSame = this.areParamsTheSame(
                    this.dashboardQueryParam,
                    currentQueryParam,
                    this.fuzzyDates,
                    this.userPreference);

                if (currentQueryParam.comparing === CompareMode.BOTH || !paramsAreTheSame) {
                    delete dashboardPref.isPreferenceChangedOnRefresh;
                    this.dashboardQueryParam = this.getQueryParam(dashboardPref);

                    this.sendDataLoadingMessage();

                    for (const state of this.stateByWidgetId.values()) {
                        if (state.key.sourceType !== 'dashboard') {
                            continue;
                        }

                        let datasetId: string | number = state.key.namedQueryId;
                        datasetId = isNaN(Number(datasetId)) ? datasetId : Number(datasetId);

                        state.isLoading = true;

                        const widgetComparing = this.widgetQueryParam?.comparing;
                        const dashboardComparing = this.dashboardQueryParam?.comparing;
                        const mode = dashboardId === undefined ? widgetComparing : dashboardComparing;

                        this.fetchData(
                            new BoardWidgetDefinitionIds(dashboardId, undefined, datasetId),
                            dashboardPref,
                            mode,
                            state.key,
                            clientCode);
                    }
                } else {
                    if (!deepCompare(this.dashboardQueryParam, currentQueryParam)) {
                        this.dashboardQueryParam = this.getQueryParam(dashboardPref);
                        this.sendDataUpdateMessage(dashboardPref);
                    }
                }
            },
        });
    }

    private subscribeWidgetQueryParams(clientCode: string, isSubscribedToDashboardFilters: boolean | undefined): void {
        this.widgetSubscription = this.queryParamsService.widgetQueryParams.subscribe({
            next: (params: WidgetFilterParams | undefined) => {
                if (!params?.lastChangedWidgetId) {
                    return;
                }

                this.widgetQueryParam = params.widgetFilters.get(params.lastChangedWidgetId);
                const currentQueryParam = this.getQueryParam(this.widgetQueryParam);
                const widgetPreferences = this.managerService.getWidgetPreferences(params.lastChangedWidgetId);
                if (!widgetPreferences?.id) {
                    return;
                }

                if (
                    currentQueryParam.isComparing && currentQueryParam.comparing === CompareMode.BOTH ||
                    (
                        widgetPreferences?.datasetDefinition?.id &&
                        !deepCompare(this.widgetFilterParam.get(params.lastChangedWidgetId), currentQueryParam)
                    ) ||
                    widgetPreferences?.realtimeUpdates && !isSubscribedToDashboardFilters
                ) {
                    const dashboardId = this.managerService.getCurrentDashboardId();
                    const state = this.stateByWidgetId.get(params.lastChangedWidgetId);
                    if (!state) {
                        return;
                    }

                    if (widgetPreferences.realtimeUpdates) {
                        state.isLoading = true;
                    }
                    this.sendMessage(widgetPreferences.id, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);

                    this.fetchData(
                        new BoardWidgetDefinitionIds(
                            dashboardId,
                            widgetPreferences.id,
                            widgetPreferences.namedQueryId ?? widgetPreferences.datasetDefinition?.id,
                        ),
                        this.widgetQueryParam,
                        this.widgetQueryParam?.comparing,
                        state.key,
                        widgetPreferences.clientCode ?? clientCode);
                    this.widgetFilterParam.set(params.lastChangedWidgetId, currentQueryParam);
                }
            },
        });
    }

    private cancelRequest(uniqueKey: DatasetFetchKey, widgetId?: number): void {
        this.deleteDataLoadedTime(uniqueKey);

        if (uniqueKey.sourceType === 'dashboard' && widgetId) {
            this.stateByWidgetId.forEach((state, wId: number) => {
                state.fetchDatasetCanceled = true;
                if (uniqueKey === state.key && widgetId !== wId) {
                    this.sendMessage(wId, WIDGET_LIFECYCLE_EVENT.DATA_LOADING_CANCELLED);
                }
            });
        }

        for (const state of this.stateByWidgetId.values()) {
            if (state.key === uniqueKey) {
                state.fetchDatasetSubscription?.unsubscribe();
                state.fetchDatasetSubscription = undefined;

                state.sendQuerySubscription?.unsubscribe();
                state.sendQuerySubscription = undefined;

                state.isLoading = false;
            }
        }
    }

    private sendMessage(widgetId: number, message: unknown): void {
        this.managerService.sendMessageToExistingWidget(widgetId, message);
    }

    private setDataLoadedTime(uniqueKey: DatasetFetchKey, date: string): void {
        for (const state of this.stateByWidgetId.values()) {
            if (state.key === uniqueKey) {
                state.loadTime = date;
            }
        }
    }

    private deleteDataLoadedTime(uniqueKey: DatasetFetchKey): void {
        for (const state of this.stateByWidgetId.values()) {
            if (state.key === uniqueKey) {
                state.loadTime = undefined;
            }
        }
    }

    private setSourceDataCopy(uniqueKey: DatasetFetchKey): void {
        const stateForKey = [...this.stateByWidgetId.values()].find((s) => s.key === uniqueKey);
        this.sourceDataCopy = stateForKey?.widgetData;
    }

    /*
        Called by:
            - getDataSet (which is public and used by widgets directly)
            - The functions that subscribe to board and widget queryParam changes
     */
    private fetchData(
        boardWidgetDefinitionId: BoardWidgetDefinitionIds,
        preferences: DashboardPreference | FilterPreference | undefined,
        mode: string | undefined,
        uniqueKey: DatasetFetchKey,
        clientCode: string,
    ): void {
        const requestParamsArr: { queryParams: DatasetFetchRuntimeParams, compareMode: string | undefined }[] = [];

        requestParamsArr.push({
            queryParams: this.getQueryParams(preferences, uniqueKey, mode),
            compareMode: mode === CompareMode.BOTH ? CompareMode.ORIGINAL : mode,
        });
        const modeParam = this.widgetQueryParam?.isComparing ?? this.dashboardQueryParam?.isComparing;
        if (mode === CompareMode.BOTH && modeParam) {
            requestParamsArr.push({
                queryParams: this.getQueryParams(this.widgetQueryParam, uniqueKey, CompareMode.COMPARED),
                compareMode: CompareMode.COMPARED,
            });
        }

        this.cancelRequest(uniqueKey);
        const dsdId = boardWidgetDefinitionId.definitionId;
        if (!dsdId) {
            return console.error('cannot fetchData when bwd has no definitionId');
        }

        const details$: Observable<NamedQuery | DatasetDefinitionDetails> =
            typeof dsdId === 'number' ?
                this.datasetDefinitionsService.fetchDatasetDefinitionDetails(dsdId) :
                this.namedQueriesService.fetchNamedQuery(dsdId);

        const fetchDataSubscription = details$.subscribe({
            next: (datasetDefinition) => {
                // eslint-disable-next-line complexity
                requestParamsArr.forEach((requestParam) => {
                    try {
                        let queryTypeName: string;
                        let isQueryTemplateStacked: boolean;
                        let conversableType: string | undefined;
                        if (datasetDefinition instanceof DatasetDefinitionDetails) {
                            queryTypeName = datasetDefinition.queryType.name;
                            isQueryTemplateStacked = datasetDefinition.isQueryTemplateStacked;
                            conversableType = datasetDefinition.conversableType;
                        } else {
                            queryTypeName = datasetDefinition.type.name;
                            isQueryTemplateStacked = datasetDefinition.isQueryTemplateStacked();
                            conversableType = datasetDefinition.crosstalkOptions?.conversableType;
                        }
                        const queryEndpoint = datasetDefinition instanceof DatasetDefinitionDetails ?
                            datasetDefinition.queryEndpoint(clientCode) :
                            datasetDefinition.type.dataEndpoint;
                        const startTime = new Date().getTime();
                        const dashboardId = this.managerService.getCurrentDashboardId();

                        if (isQueryTemplateStacked) {
                            const dataQueries = datasetDefinition.populateStackedQueryTemplate(requestParam.queryParams, this.fundOptions);

                            if (isTradeFileDetails(queryTypeName) && !this.dashboardQueryParam?.acknowledged) {
                                dataQueries.forEach((dataQuery) => dataQuery.acknowledged = this.dashboardQueryParam?.acknowledged);
                            } else if (isNetSettlementBreaksQuery(queryTypeName, this.dashboardQueryParam)) {
                                dataQueries.forEach((dataQuery) => {
                                    dataQuery.includeManuallyReleased = this.dashboardQueryParam?.includeManuallyReleased;
                                });
                            }

                            if (conversableType) {
                                dataQueries.forEach((dataQuery) => {
                                    // some datasets, like adhoc, don't have the "selectedColumns" property
                                    // and for those Trebek is automatically adding the crosstalk columns to the response
                                    // without us requesting them, we just need to add the "conversationType"
                                    dataQuery.selectedColumns?.push(...crosstalkColumns, { columnId: 'conversationId' });
                                    dataQuery.conversationType = conversableType;
                                });
                            }

                            if (this.isMultiClient && dashboardId === MANAGE_WIDGET_WS_ID) {
                                this.stateByWidgetId.forEach((state, widgetId) => {
                                    if (state.key === uniqueKey) {
                                        this.metadataService
                                            .fetchMetadataAllMetadata([new BoardWidgetDefinitionIds(undefined, widgetId, dsdId)])
                                            .subscribe();
                                    }
                                });
                            }

                            this.sendStackedDataQuery(
                                boardWidgetDefinitionId,
                                dataQueries,
                                queryEndpoint,
                                requestParam,
                                startTime,
                                uniqueKey);
                        } else {
                            const dataQuery = datasetDefinition.populateQueryTemplate(requestParam.queryParams, this.fundOptions);

                            if (
                                dataQuery.timeseriesRange &&
                                (
                                    dataQuery.timeseriesRange.startDate !== requestParam.queryParams.dateRange.from ||
                                    dataQuery.timeseriesRange.endDate !== requestParam.queryParams.dateRange.to
                                )
                            ) {
                                dataQuery.timeseriesRange.startDate = requestParam.queryParams.dateRange.from;
                                dataQuery.timeseriesRange.endDate = requestParam.queryParams.dateRange.to;
                            }

                            if (isTradeFileDetails(queryTypeName) && !this.dashboardQueryParam?.acknowledged) {
                                dataQuery.acknowledged = this.dashboardQueryParam?.acknowledged;
                            } else if (isNetSettlementBreaksQuery(queryTypeName, this.dashboardQueryParam)) {
                                dataQuery.includeManuallyReleased = this.dashboardQueryParam?.includeManuallyReleased;
                            }

                            if (this.isMultiClient && dashboardId === MANAGE_WIDGET_WS_ID) {
                                this.stateByWidgetId.forEach((state, widgetId) => {
                                    if (state.key === uniqueKey) {
                                        this.metadataService
                                            .fetchMetadataAllMetadata([new BoardWidgetDefinitionIds(undefined, widgetId, dsdId)])
                                            .subscribe();
                                    }
                                });
                            }

                            if (conversableType) {
                                // some datasets, like adhoc, don't have the "selectedColumns" property
                                // and for those Trebek is automatically adding the crosstalk columns to the response
                                // without us requesting them, we just need to add the "conversationType"
                                dataQuery.selectedColumns?.push(...crosstalkColumns, { columnId: 'conversationId' });
                                dataQuery.conversationType = conversableType;
                            }

                            this.sendDataQuery(
                                boardWidgetDefinitionId,
                                dataQuery,
                                queryEndpoint,
                                requestParam,
                                startTime,
                                uniqueKey,
                                clientCode);
                        }
                    } catch (error) {
                        const message = {
                            action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                            exception: { message: (error as Error).message },
                        };
                        if (boardWidgetDefinitionId.widgetId) {
                            this.sendMessage(boardWidgetDefinitionId.widgetId, message);
                        } else {
                            this.stateByWidgetId.forEach((state, widgetId) => {
                                if (state.key === uniqueKey) {
                                    this.sendMessage(widgetId, message);
                                }
                            });
                        }
                    }
                });
            },
        });

        for (const state of this.stateByWidgetId.values()) {
            if (state.key === uniqueKey) {
                state.fetchDatasetSubscription = fetchDataSubscription;
            }
        }
    }

    private sendDataQuery(
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        dataQuery: Query,
        queryEndpoint: string,
        requestParam: { queryParams: { uniqueId: DatasetFetchKey, clientCodeList: Client[] }, compareMode?: string },
        startTime: number,
        uniqueKey: DatasetFetchKey,
        clientCode: string,
    ): void {
        const { queryParams: { uniqueId, clientCodeList }, compareMode } = requestParam;

        const states = [...this.stateByWidgetId.values()].filter((state) => state.key === uniqueKey);

        const sendQuerySubscription = this.invokePublicAPI(boardWidgetDefinitionIds, dataQuery, queryEndpoint, uniqueId, clientCodeList)
            .subscribe({
                next: (response) => {
                    if (!response.success && response.retryAfter) {
                        setTimeout(() => {
                            this.sendDataQuery(
                                boardWidgetDefinitionIds,
                                dataQuery,
                                queryEndpoint,
                                requestParam,
                                startTime,
                                uniqueKey,
                                clientCode);
                        }, response.retryAfter);
                        return;
                    }

                    response.data.forEach((datum) => addCrosstalkLinksToData(clientCode, datum));

                    this.sendHideCancelMessage(uniqueId);

                    if (compareMode === CompareMode.COMPARED) {
                        states.forEach((state) => state.widgetCompareData = response.data);
                    } else {
                        states.forEach((state) => state.widgetData = response.data);
                    }

                    const dataSourceName = response.dataSourceName;

                    setTimeout(() => {
                        this.updateDatasource(uniqueId, compareMode, boardWidgetDefinitionIds, dataSourceName, uniqueKey);
                    }, 0);
                },
            });

        states.forEach((state) => state.sendQuerySubscription = sendQuerySubscription);
    }

    private sendStackedDataQuery(
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        dataQueries: Query[],
        queryEndpoint: string,
        requestParam: { queryParams: { uniqueId: DatasetFetchKey, clientCodeList: Client[] }, compareMode?: string },
        startTime: number,
        uniqueKey: DatasetFetchKey,
    ): void {
        const { queryParams: { uniqueId, clientCodeList }, compareMode } = requestParam;
        const dataQueryObs: Observable<PublicAPIQueryResponse>[] = dataQueries.map((dataQuery) => {
            return this.invokePublicAPI(boardWidgetDefinitionIds, dataQuery, queryEndpoint, uniqueId, clientCodeList);
        });

        const states = [...this.stateByWidgetId.values()].filter((state) => state.key === uniqueKey);

        const sendQuerySubscription = combineLatest(dataQueryObs)
            .subscribe({
                next: (res) => {
                    if (res.some((response) => !response.success && response.retryAfter)) {
                        const timeout = res.find((response) => !response.success && response.retryAfter)?.retryAfter;
                        setTimeout(() => {
                            this.sendStackedDataQuery(
                                boardWidgetDefinitionIds,
                                dataQueries,
                                queryEndpoint,
                                requestParam,
                                startTime,
                                uniqueKey);
                        }, timeout);
                        return;
                    }

                    this.sendHideCancelMessage(uniqueId);

                    let stackedData: PublicApiResponseRow[] = [];
                    res.forEach((response) => stackedData.push(...response.data));
                    const columnsToConsider = dataQueries[0].selectedColumns.map((column) => column.columnId);
                    stackedData = this.clientDatasetFilterService.removeDuplicateRows(stackedData, columnsToConsider);
                    const dataSourceName = res[0].dataSourceName;
                    states.forEach((state) => state.widgetData = stackedData);

                    setTimeout(() => {
                        this.updateDatasource(uniqueId, compareMode, boardWidgetDefinitionIds, dataSourceName, uniqueKey);
                    }, 0);
                },
            });

        states.forEach((state) => state.sendQuerySubscription = sendQuerySubscription);
    }

    private updateDatasource(
        uniqueId: DatasetFetchKey,
        compareMode: string | undefined,
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        datasourceName: string | undefined,
        uniqueKey: DatasetFetchKey,
    ): void {
        const stateForKey = [...this.stateByWidgetId.values()].find((s) => s.key === uniqueKey);
        const dataWrapper = {
            uniqueKey: uniqueId,
            data: stateForKey?.widgetData ?? [],
            compareData: stateForKey?.widgetCompareData ?? [],
            compareMode,
            dataLoaded: false,
        };

        this.setSourceDataCopy(uniqueKey);
        this.widgetDataSourceService.addDataSource(dataWrapper);

        if (boardWidgetDefinitionIds.definitionId && datasourceName) {
            this.widgetDataSourceService.updateDataSourcesNames({
                [boardWidgetDefinitionIds.definitionId]: datasourceName,
            });
        }

        this.setDataLoadedTime(uniqueId, new Date().toISOString());

        for (const state of this.stateByWidgetId.values()) {
            if (state.key === uniqueKey) {
                state.isLoading = false;
                state.fetchDatasetCanceled = false;
            }
        }
    }

    private invokePublicAPI(
        boardWidgetDefinitionIds: BoardWidgetDefinitionIds,
        dataQuery: Query,
        queryEndpoint: string,
        uniqueId: DatasetFetchKey,
        clientCodeList: Client[],
    ): Observable<PublicAPIQueryResponse> {
        const usage: UsageTracker = this.usageTracking.startTracking('DDV.Dataset.Data', boardWidgetDefinitionIds.toRecord());
        const id = boardWidgetDefinitionIds.definitionId;
        const clientCodeAndRoute = typeof id !== 'string' ?
            splitEndpointIntoClientAndRoute(queryEndpoint) :
            { clientCode: '', route: queryEndpoint }; // for named queries we don't want to split the route

        if (this.isMultiClient) {
            const clients = clientCodeList.map((el) => el.clientId).join(',');
            clientCodeAndRoute.route += `?clients=${clients.length ? clients : this.fullClientsList}`;
        }

        const query$: Observable<HttpResponse<TrebekQueryResponse>> = this.getQueryObservable(
            id,
            clientCodeAndRoute,
            dataQuery,
            usage,
        );

        return query$.pipe(
            map((response: HttpResponse<TrebekQueryResponse>): PublicAPIQueryResponse => {
                if (response?.body?.error) {
                    return this.handleQueryError((response)?.body, usage, uniqueId);
                }

                if (response?.headers?.get('x-hedgeserv-retry-after')) {
                    return {
                        success: false,
                        data: [],
                        retryAfter: response.headers.get('x-hedgeserv-retry-after') ? Number(response.headers.get('x-hedgeserv-retry-after')) : undefined,
                    };
                }

                usage.succeeded({ numRows: response.body?.data.length });

                return {
                    success: true,
                    data: postProcessPublicApiResponse(response.body?.data ?? []),
                    dataSourceName: response.body?.tracingInfo?.upstream,
                };
            }),
            catchError((error) => of(this.handleQueryError(error, usage, uniqueId))));
    }

    private getQueryObservable(
        definitionId: string | number | undefined,
        clientCodeAndRoute: { clientCode: string, route: string },
        dataQuery: Query,
        usage: UsageTracker,
    ): Observable<HttpResponse<TrebekQueryResponse>> {
        let query$: Observable<HttpResponse<TrebekQueryResponse>>;
        const method = 'POST';
        if (typeof definitionId === 'string') { // widget is using a named query
            query$ = this.executor.invokeServiceWithBodyAndReturnFullResponse<TrebekQueryResponse>(
                clientCodeAndRoute.route,
                method,
                dataQuery,
                { usageTracker: usage },
            );
        } else {
            query$ = this.trebekApiExecutor.invokeServiceWithBodyAndReturnFullResponse<TrebekQueryResponse>(
                clientCodeAndRoute.clientCode,
                clientCodeAndRoute.route,
                method,
                dataQuery,
                { usageTracker: usage },
            );
        }
        return query$;
    }

    private handleQueryError(
        response: TrebekQueryResponse | HttpErrorResponse,
        usage: UsageTracker,
        uniqueId: DatasetFetchKey,
    ): PublicAPIQueryResponse {
        usage.failed((response instanceof HttpErrorResponse) ? response.error || response.message : response.error);
        console.error(response);

        setTimeout(() => {
            this.sendErrorMessage(uniqueId, new Date().toISOString(), response);
        }, 0);

        return {
            success: false,
            error: (response instanceof HttpErrorResponse) ? response.error || response.message : response.error,
            data: [],
        };
    }

    private sendHideCancelMessage(uniqueKey: DatasetFetchKey): void {
        if (uniqueKey.sourceType === 'dashboard') {
            this.stateByWidgetId.forEach((state, widgetId) => {
                if (state.key === uniqueKey) {
                    this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.HIDE_CANCEL);
                }
            });
        } else {
            this.sendMessage(Number(uniqueKey.sourceId), WIDGET_LIFECYCLE_EVENT.HIDE_CANCEL);
        }
    }

    private sendErrorMessage(
        requestId: DatasetFetchKey,
        time: string,
        response: TrebekQueryResponse | HttpErrorResponse,
    ): void {
        if (requestId.sourceType === 'dashboard') {
            this.stateByWidgetId.forEach((state, widgetId) => {
                if (state.key === requestId) {
                    state.error = time;
                    if (this.checkForHIError(response, NO_ENTITLEMENTS) || this.checkForHIError(response, FLAG_NOT_SET)) {
                        this.sendMessage(
                            widgetId,
                            {
                                action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                                exception: {
                                    message: this.checkForHIError(response, NO_ENTITLEMENTS) ?
                                        HI_DATA_USER_ERROR_MESSAGE :
                                        HI_DATA_CLIENT_ERROR_MESSAGE,
                                },
                            });
                    } else {
                        this.sendMessage(
                            widgetId,
                            { action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED, exception: { message: GRAPHQL_ERROR_MESSAGE, time } });
                    }
                }
            });
        } else {
            if (this.checkForHIError(response, NO_ENTITLEMENTS) || this.checkForHIError(response, FLAG_NOT_SET)) {
                this.sendMessage(
                    Number(requestId.sourceId),
                    {
                        action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED,
                        exception: {
                            message: this.checkForHIError(response, NO_ENTITLEMENTS) ?
                                HI_DATA_USER_ERROR_MESSAGE :
                                HI_DATA_CLIENT_ERROR_MESSAGE,
                        },
                    });
            } else {
                this.sendMessage(
                    Number(requestId.sourceId),
                    { action: WIDGET_LIFECYCLE_EVENT.ERROR_OCCURRED, exception: { message: GRAPHQL_ERROR_MESSAGE, time } });
            }
        }
    }

    private checkForHIError(response: TrebekQueryResponse | HttpErrorResponse, error: { CODE: string, TEXT: string }): boolean {
        return response.error?.errorCode === error.CODE && response.error.errorMessage === error.TEXT;
    }

    private sendDataLoadingMessage(): void {
        this.stateByWidgetId.forEach((state, widgetId) => {
            if (state.key.sourceType === 'dashboard') {
                this.sendMessage(widgetId, WIDGET_LIFECYCLE_EVENT.LOADING_DATA);
            }
        });
    }

    private sendDataUpdateMessage(queryParams: FilterQueryParam): void {
        this.stateByWidgetId.forEach((state, widgetId) => {
            if (state.key.sourceType === 'dashboard') {
                const widget = this.managerService.getWidgetById(widgetId);
                if (!widget) {
                    return;
                }

                queryParams.isComparing = this.widgetQueryParam?.isComparing;
                widget.lifeCycleCallBack?.(
                    WIDGET_LIFECYCLE_EVENT.DATA_UPDATE,
                    {
                        data: state.fetchedData,
                        filters: queryParams, compareMode: queryParams.comparing,
                    });
            }
        });
    }

    private getQueryParams(
        preference: FilterPreference | FilterQueryParam | undefined,
        uniqueId: DatasetFetchKey,
        mode?: string,
    ): DatasetFetchRuntimeParams {
        const hsDefaults = toPrioritizedFilterParams(
            this.fundOptions,
            this.clientOptions,
            this.managerService.getExtraParametersForWorkspace(),
            this.userPreference);
        const fundCodes = (preference?.funds?.length ? preference : hsDefaults)?.funds?.map((fund) => fund.fundId) ?? [];

        const dateFrom = mode && mode === CompareMode.COMPARED && preference?.compareDates ?
            preference?.compareDates.dateFrom :
            preference?.startDate ?? hsDefaults.startDate;

        const dateTo = mode && mode === CompareMode.COMPARED && preference?.compareDates ?
            preference.compareDates.dateTo :
            preference?.endDate ?? hsDefaults.endDate;

        const dateRange = {
            from: dateFrom ?? '',
            to: dateTo ?? '',
        };

        if (this.isMultiClient) {
            const clientCodeList = preference?.clients;
            return new DatasetFetchRuntimeParams(uniqueId, 'daily', dateRange, fundCodes, true, true, clientCodeList);
        }

        return new DatasetFetchRuntimeParams(uniqueId, 'daily', dateRange, fundCodes, true);
    }

    private getOrCreateState(
        dashboardId: string | number,
        widgetId: number,
        namedQueryId: number | string,
        isSubscribedToDashboardFilters: boolean | undefined,
    ): Required<DatasetLoadState> {
        let state = this.stateByWidgetId.get(widgetId);
        if (!state) {
            // this forces the state to be created with the correct key
            this.getUniqueKey(dashboardId, widgetId, namedQueryId, isSubscribedToDashboardFilters);
            state = this.stateByWidgetId.get(widgetId);
        }

        if (!state!.publicSubject) {
            state!.publicSubject = new BehaviorSubject<IDataLoad>({ isLoaded: false, key: state!.key });
        }

        return state as unknown as Required<DatasetLoadState>;
    }
}

interface Links {
    links?: { [key: string]: LinkConfiguration };
}

interface TrebekQueryResponseRow extends Links {
    selectedColumnData: WidgetData;
}

interface TrebekTracingInfo {
    upstream?: string;
    routedTo?: string;
    upstreamQuery?: {
        apiRouteUsed: string;
        method: string;
        body: Record<string, unknown> | null;
    }[];
}

export interface TrebekQueryResponse extends Links {
    data: TrebekQueryResponseRow[];
    tracingInfo?: TrebekTracingInfo;
    error?: string;
    queryId?: string;
}

export type PublicApiResponseRow = (WidgetData & Links);

export type DatedPublicApiResponseRow = (PublicApiResponseRow & { date?: string });

export interface PublicAPIQueryResponse {
    success: boolean;
    data: PublicApiResponseRow[];
    dataSourceName?: string;
    retryAfter?: number;
    error?: unknown;
}

export function postProcessPublicApiResponse(data: TrebekQueryResponseRow[]): PublicApiResponseRow[] {
    return data.map((row) => {
        const processed: PublicApiResponseRow = {
            ...row.selectedColumnData,
            links: row.links ?? {},
        } as PublicApiResponseRow;

        if (Object.prototype.hasOwnProperty.call(processed, 'date') && !Object.prototype.hasOwnProperty.call(processed, 'Date')) {
            processed.Date = processed.date;
        }

        return processed;
    });
}

// we need a smarter way to do this
// we also need to be sure we only send it to net-settlement queries
// or the other queries will break because it's not allowed per the OpenAPI spec
function isNetSettlementBreaksQuery(queryTypeName: string, dashboardQueryParam: FilterQueryParam | undefined): boolean {
    return queryTypeName === QueryTypeName.SETTLEMENT_BREAKS && dashboardQueryParam?.includeManuallyReleased !== undefined;
}
