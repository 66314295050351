import { Injectable } from '@angular/core';
import { deepClone } from '@ddv/utils';
import * as d3 from 'd3';

import { BaseChartService } from './base-chart.service';
import { BrushService } from './brush.service';

@Injectable()
export class StackedAreaChartBrushService extends BrushService {
    addBrush(parentChart: BaseChartService): void {
        this.setParentChartProperties(parentChart);
        this.setBrushProperties();

        const offset = 20;
        this.setBrushDimensions();
        const height2 = (this.brushHeight < 0) ? 5 : (this.config?.brushSize ?? 0);

        this.x2 = d3.scaleTime().range([0, this.brushWidth]);
        const yAxis = this.y || this.yAxisRight;
        const y2 = d3.scaleLinear().range([height2, 0]);
        this.xAxis2 = d3.axisBottom(this.x2).ticks(Math.max(this.brushWidth / this.tickOffset, 2));

        this.brush = d3.brushX()
            .extent([[0, 0], [this.brushWidth, height2]])
            .on('brush end', (event: { selection: number[] }) => this.brushed(event));

        this.x2.domain(this.x.domain());
        y2.domain(yAxis.domain());

        this.context = this.getContext(offset);
        this.setXAxis2();

        this.gBrush = this.context.append('g')
            .attr('class', 'brush')
            .call(this.brush)
            .call(this.brush.move, this.x.range()); // eslint-disable-line @typescript-eslint/unbound-method

        this.formatTicks('.tick');
    }

    resizeBrush(timeline: { notation: string, scale?: number }, parentChart: BaseChartService): void {
        this.setParentChartProperties(parentChart);

        const secondDate = this.x2!.domain()[1];
        const firstDate = this.getFirstDateOnBrushResize(timeline, secondDate);

        this.gBrush.call(this.brush!.move, [firstDate, secondDate].map(this.x2!)); // eslint-disable-line @typescript-eslint/unbound-method
    }

    protected override setBrushProperties(): void {
        super.setBrushProperties();

        this.dataSource = deepClone(this.parentChartProperties?.config.dataSource);

        this.x = this.parentChartProperties?.x;
        this.area = this.parentChartProperties?.area;
    }

    private brushed(event: { selection: number[] }): void {
        const s = event.selection || this.x2!.range();
        this.x.domain(s.map(this.x2!.invert, this.x2!)); // eslint-disable-line @typescript-eslint/unbound-method
        this.svg.selectAll('.stacked-area').attr('d', this.area);
        this.svg.select('.axis--x').call(d3.axisBottom(this.x).ticks(Math.max(this.brushWidth / this.tickOffset, 2)));
        this.formatTicks('.axis--x .tick');
    }
}
