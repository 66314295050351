import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartComponent } from './components/chart.component';
import { HorizontalBarChartComponent } from './components/horizontal-bar-chart/horizontal-bar-chart.component';
import { LegendsModule } from './components/legends/legends.module';
import { ChartsDirectivesModule } from './directives/charts-directives.module';
import { BarChartBrushService } from './services/bar-chart-brush.service';
import { BarChartService } from './services/bar-chart.service';
import { BrushService } from './services/brush.service';
import { ColorMetadataService } from './services/color-metadata.service';
import { LegendsService } from './services/legends.service';
import { LineChartBrushService } from './services/line-chart-brush.service';
import { LineChartService } from './services/line-chart.service';
import { MultiSeriesChartService } from './services/multi-series-chart.service';
import { PieChartService } from './services/pie-chart.service';
import { StackedAreaChartService } from './services/stacked-area-chart.service';
import { ValueAggregationService } from './services/value-aggregation.service';
import { ValueFormatterService } from './services/value-formatter.service';

@NgModule({
    imports: [
        ChartsDirectivesModule,
        CommonModule,
        LegendsModule,
    ],
    declarations: [
        ChartComponent,
        HorizontalBarChartComponent,
    ],
    exports: [
        ChartComponent,
        HorizontalBarChartComponent,
    ],
    providers: [
        BarChartBrushService,
        BarChartService,
        BrushService,
        ColorMetadataService,
        LegendsService,
        LineChartBrushService,
        LineChartService,
        MultiSeriesChartService,
        PieChartService,
        StackedAreaChartService,
        ValueAggregationService,
        ValueFormatterService,
    ],
})
export class ChartsModule{}
