import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { CustomCellRendererParams } from '@ddv/data-grid';

@Component({
    selector: 'app-author-cell-renderer',
    styleUrls: ['./author-cell-renderer.component.scss'],
    template: '<div class="author-wrapper">{{ author }}</div>',
})
export class AuthorCellRendererComponent implements ICellRendererAngularComp {
    author: string | undefined;

    agInit(params: CustomCellRendererParams): void {
        this.author = params.value;
    }

    refresh(params: CustomCellRendererParams): boolean {
        this.author = params.value;
        return true;
    }
}
