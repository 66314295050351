/* eslint-disable class-methods-use-this */
import { Inject, Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { ApiExecutorService, ApiServices } from '@ddv/http';
import { ManagerService } from '@ddv/layout';
import { Client, MODE, Fund, Tag, VisualizationType, AppWidgetState, IWidgetSettings, WidgetState } from '@ddv/models';
import { ClientsService, FundsService } from '@ddv/reference-data';
import { GridEvent } from '@ddv/visualizations';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class WidgetService {
    public readonly gridStateObs: Observable<GridEvent>;

    private readonly gridStateEvent = new Subject<GridEvent>();
    private funds: Fund[] = [];
    private clients: Client[] = [];
    private isMultiClient = false;
    private clientCode = '';

    constructor(
        @Inject(ApiServices.ddvMW) private readonly ddvApiService: ApiExecutorService,
        private readonly managerService: ManagerService,
        private readonly fundsService: FundsService,
        private readonly clientsService: ClientsService,
        private readonly currentStateService: CurrentStateService,
    ) {
        this.gridStateObs = this.gridStateEvent.asObservable();

        this.currentStateService.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);

        this.currentStateService.isMultiClient$
            .pipe(switchMap((isMultiClient) => {
                this.isMultiClient = isMultiClient;
                if (this.isMultiClient) {
                    return this.clientsService.clients();
                }

                return this.fundsService.funds();
            }))
            .subscribe((data: Fund[] | string[]) => {
                if (data.some((datum) => datum instanceof Fund)) {
                    this.funds = data as Fund[];
                } else {
                    this.clients = (data as string[]).map((clientCode) => {
                        return { clientId: clientCode, clientName: clientCode };
                    });
                }
            });
    }

    getWidgetPreferences(widgetId: number, clientCode: string): Observable<AppWidgetState> {
        return this.ddvApiService.invokeServiceWithParams<Partial<AppWidgetState>>(clientCode, `widgets/${widgetId}`)
            .pipe(map((w) => {
                if (this.isMultiClient) {
                    return new AppWidgetState(w, [], this.clients);
                }
                return new AppWidgetState(w, this.funds);
            }));
    }

    updateWidgetTags(widgetId: number, widgetTags: Tag[]): Observable<Tag[]> {
        return this.ddvApiService.invokeServiceWithBody(this.clientCode, `widgets/${widgetId}/tags`, 'POST', widgetTags);
    }

    updateDashboardWidget(widget: AppWidgetState): Observable<AppWidgetState> {
        return this.ddvApiService.invokeServiceWithBody<Partial<AppWidgetState>>(this.clientCode, 'widgets', 'PUT', widget)
            .pipe(map((w) => {
                if (this.isMultiClient) {
                    return new AppWidgetState(w, [], this.clients);
                }
                return new AppWidgetState(w, this.funds);
            }));
    }

    getWidgetSetting(widget: AppWidgetState): IWidgetSettings {
        const widgetSetting = widget.widgetSettings?.find((widSetting) => widSetting.mode === MODE.EDIT_WORKSPACE);
        const vizType = widget.visualizationConfigs[0]?.visualizationType;
        /* eslint-disable eqeqeq */
        let isActiveDate = widgetSetting?.isActiveDate !== null ? widgetSetting?.isActiveDate : widget.isActiveDate;
        if (isActiveDate === null) {
        /* eslint-enable eqeqeq */
            isActiveDate = this.getDefaultIsActiveDate(vizType);
        }

        if (this.isMultiClient && widgetSetting) {
            widgetSetting.isSubscribedToDashboardFilters = true;
        }

        return {
            enableAutoRefresh: widgetSetting?.enableAutoRefresh,
            isActiveDate,
            sortTableBy: widgetSetting?.sortTableBy ?? widget.sortTableBy ?? 'SLICER',
            tableSortDirection: widgetSetting?.tableSortDirection ?? widget.tableSortDirection,
            isSubscribedToDashboardFilters: widgetSetting?.isSubscribedToDashboardFilters,
            widgetFilters: widget.widgetFilters ?? widgetSetting?.widgetFilters,
        };
    }

    getDefaultWidgetState(widgetId?: number): AppWidgetState {
        let widgetState: WidgetState | undefined = {};
        if (widgetId) {
            widgetState = this.managerService.getWidgetState(widgetId);
        }

        return new AppWidgetState({
            id: undefined,
            selector: '',
            name: '',
            visualizationConfigs: [],
            datasetDefinition: { id: 0, name: '' },
            isActiveDate: false,
            sortTableBy: 'SLICER',
            tableSortDirection: 'ASC',
            isSubscribedToDashboardFilters: true,
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            maximized: false,
            widgetFilters: { funds: [], filters: [] },
            clientCode: this.clientCode,
            ...widgetState,
        });
    }

    updateIsMasterOnWidgetExtraParams(widgetId: number, isMaster: boolean): void {
        const widgetParams = this.managerService.getWidgetPreferences(widgetId);
        if (widgetParams) {
            widgetParams.isMaster = isMaster;
            this.managerService.setWidgetExtraPreferences(widgetId, widgetParams);
        }
    }

    publishGridStateUpdated(gridState: GridEvent): void {
        this.gridStateEvent.next(gridState);
    }

    getDefaultIsActiveDate(vizType: VisualizationType | undefined): boolean {
        switch (vizType) {
            case 'PIE_CHART':
            case 'DONUT_CHART':
            case 'HORIZONTAL_BAR_CHART':
            case 'VERTICAL_BAR_CHART':
            case 'HORIZONTAL_STACKED_BAR_CHART':
            case 'VERTICAL_STACKED_BAR_CHART':
            case 'VERTICAL_MIRRORED_BAR_CHART':
                return true;
            default:
                return false;
        }
    }
}
