import { NgModule } from '@angular/core';

import { ConversationHistoryIconComponent } from './conversation-history-icon/conversation-history-icon.component';
import { DownloadAttachmentsIconComponent } from './download-attachment-icon/download-attachments-icon.component';
import { UploadAttachmentIconComponent } from './upload-attachment-icon/upload-attachment-icon.component';

@NgModule({
    declarations: [
        ConversationHistoryIconComponent,
        DownloadAttachmentsIconComponent,
        UploadAttachmentIconComponent,
    ],
    exports: [
        ConversationHistoryIconComponent,
        DownloadAttachmentsIconComponent,
        UploadAttachmentIconComponent,
    ],
})
export class IconsModule {}
