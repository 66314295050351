<div class="configure">
    <span class="label-text">* Required Fields</span>

    <div class="disp-flex" cdkDropListGroup>
        <div class="left-flex">
            <app-field-picker-tree
                [isAttached]="isAttached"
                [tree]="groupedMetadata"
                [readonly]="isReadOnly"
                (dragStateChanged)="dragStateChanged($event)">
            </app-field-picker-tree>
        </div>

        <div class="right-flex">
            <div *ngFor="let zone of dropZones;">
                <div class="value-drag-parent" [ngClass]="{'readonly': isReadOnly}">
                    <h3>{{ zone.label }}{{ zone.mandatory ? '*' : '' }}</h3>

                    <div
                            class="drag-wrap grid-boundary"
                            cdkDropList
                            [cdkDropListData]="gridDataConfigs"
                            (cdkDropListDropped)="onCdkDropSuccess($event)"
                            [ngClass]="{'disabled':!zone.allowed && activelyDragging,
                                        'allowed-area':zone.allowed && activelyDragging}">

                        <div
                                class="drag-list"
                                cdkDrag
                                [id]="i"
                                *ngFor="let item of gridDataConfigs; let i = index;"
                                cdkDragBoundary=".grid-boundary"
                                [cdkDragDisabled]="isReadOnly">

                            <span class="icon-threedots"></span>

                            <label class="item">{{ item.label }}</label>

                            <span class="icon-arrow-down" (click)="showColumnFormatters(item)"></span>

                            <span class="icon-close1" (click)="removeConfigItemAtIndex(i)"></span>
                        </div>

                        <label class="drag-placeholder">
                            {{ zone.placeholder }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
