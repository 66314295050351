import { Injectable } from '@angular/core';

import { BarChartData } from '../../models/chart-data';
import { HorizontalBarChartScaleService } from './horizontal-bar-chart-scale.service';

export interface LabelCoordinates {
    x: number;
    y: number;
}

interface BackgroundPosition {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface BarLabelPosition {
    coordinates: LabelCoordinates;
    isInside: boolean;
    fitsInsideBar: boolean;
    background?: BackgroundPosition;
}

const textBoxBarHeightPadding = 10;
const textBoxBarWidthPadding = 2;
const textBoxHorizontalPositionPadding = 10;

type LabelPlacement = 'inside' | 'outside';

@Injectable()
export class HorizontalBarChartBarLabelPositionService {
    private labelPlacement: LabelPlacement = 'inside';
    private inCompareMode = false;
    private readonly positionData: Map<BarChartData, BarLabelPosition> = new Map();

    constructor(private readonly scales: HorizontalBarChartScaleService) {}

    configure(labelsShouldBeInsideBars: boolean, inCompareMode: boolean): void {
        this.labelPlacement = labelsShouldBeInsideBars ? 'inside' : 'outside';
        this.inCompareMode = inCompareMode;
        this.positionData.clear();
    }

    positionLabelForBar(
        barData: BarChartData,
        labelBBox: { width: number, height: number },
        chartHasNegativeValues = false,
        isCompareData = false,
    ): BarLabelPosition {
        const coordinates = this.determineInitialPositionForLabel(barData, labelBBox.width, isCompareData);

        let labelIsInside = this.labelPlacement === 'inside';
        if (this.labelPlacement === 'outside') {
            // this function will update the coordinates to be back inside if it does not fit outside
            labelIsInside = this.moveLabelBackInsideIfNoRoomOutside(coordinates, barData, labelBBox.width);
        }

        // this ignores the padding
        const maxWidth = this.scales.widthForBar(barData, chartHasNegativeValues);
        const maxHeight = this.maximumHeightForLabel(isCompareData);
        const fitsInsideBar = checkIfLabelFitsInsideBar(labelIsInside, maxWidth, maxHeight, labelBBox);

        const background = labelIsInside && fitsInsideBar ?
            determineBackgroundPosition(coordinates, maxHeight, labelBBox.width, labelBBox.height) :
            undefined;

        const position: BarLabelPosition = {
            coordinates,
            isInside: labelIsInside,
            fitsInsideBar,
            background,
        };

        this.positionData.set(barData, position);

        return position;
    }

    positionForBar(bar: BarChartData): BarLabelPosition {
        const position = this.positionData.get(bar);
        if (!position) {
            throw new Error('no position for bar');
        }

        return position;
    }

    private determineInitialPositionForLabel(
        barData: BarChartData,
        labelWidth: number,
        isCompareData: boolean,
    ): LabelCoordinates {
        return {
            x: this.determineInitialXPosition(barData, labelWidth),
            y: this.determineInitialYPosition(barData, isCompareData),
        };
    }

    private determineInitialXPosition(barData: BarChartData, labelWidth: number): number {
        const value = Number(barData.value.value);
        const edgeOfBar = this.scales.valueToHorizontalPosition(value);

        if (this.labelPlacement === 'inside') {
            return placeLabelInsideBar(value, edgeOfBar, labelWidth);
        } else {
            return placeLabelOutsideBar(value, edgeOfBar, labelWidth);
        }
    }

    private determineInitialYPosition(barData: BarChartData, isCompareData: boolean): number {
        const yColumnPos = this.scales.verticalPositionForBar(barData) + this.scales.barThickness() / (this.inCompareMode ? 4 : 2);
        return this.inCompareMode ?
            (yColumnPos - 3) + (isCompareData ? this.scales.barThickness() / 2 : 0) :
            yColumnPos;
    }

    private moveLabelBackInsideIfNoRoomOutside(
        coordinates: LabelCoordinates,
        barData: BarChartData,
        labelWidth: number,
    ): boolean {
        const value = Number(barData.value.value);
        const labelWouldNotFitOutside = this.wouldLabelNotFitOutside(coordinates.x, labelWidth, value);

        if (labelWouldNotFitOutside) {
            coordinates.x = placeLabelInsideBar(value, this.scales.valueToHorizontalPosition(value), labelWidth);
            return true;
        }

        return false;
    }

    private wouldLabelNotFitOutside(xCoordinate: number, labelWidth: number, value: number): boolean {
        if (value < 0) {
            // left edge would be clipped
            return xCoordinate < 0;
        } else {
            // right edge would be clipped
            return xCoordinate + labelWidth > this.scales.maxHorizontalPosition();
        }
    }

    private maximumHeightForLabel(isCompareData: boolean): number {
        return isCompareData ? this.scales.barThickness() / 2 : this.scales.barThickness();
    }
}

function placeLabelInsideBar(value: number, edgeOfBar: number, labelWidth: number): number {
    if (value < 0) {
        // left edge of label inside of the left edge of the bar
        return edgeOfBar + textBoxHorizontalPositionPadding;
    } else {
        // right edge of the label inside of the right edge of the bar
        return edgeOfBar - labelWidth - textBoxHorizontalPositionPadding;
    }
}

function placeLabelOutsideBar(value: number, edgeOfBar: number, labelWidth: number): number {
    if (value < 0) {
        // right edge of the label outside of the left edge of the box
        return edgeOfBar - labelWidth - textBoxHorizontalPositionPadding;
    } else {
        // left edge of the label outside of the right edge of the box
        return edgeOfBar + textBoxHorizontalPositionPadding;
    }
}

// this requires the label to be at least 10 pixel shorter than the bar thickness
// this requires the label to be at least 2 pixel narrower than the width of the bar
function checkIfLabelFitsInsideBar(
    isInside: boolean,
    maxWidth: number,
    maxHeight: number,
    labelBBox: { width: number, height: number },
): boolean {
    if (isInside) {
        return (maxWidth > (labelBBox.width + textBoxBarHeightPadding)) &&
            (maxHeight > (labelBBox.height + textBoxBarWidthPadding));
    }

    return maxHeight > labelBBox.height;
}

function determineBackgroundPosition(
    coordinates: LabelCoordinates,
    maxHeight: number,
    labelWidth: number,
    labelHeight: number,
): BackgroundPosition {
    let height = labelHeight;

    if (maxHeight >= labelHeight + 8) {
        height += 3.8;
    }
    if (maxHeight >= height + 4) {
        height += 0.1;
    }

    return {
        x: coordinates.x - 6,
        y: maxHeight >= labelHeight + 8 ? coordinates.y - 9 : coordinates.y - 7,
        height,
        width: labelWidth + 12,
    };
}
