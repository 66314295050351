export enum Entitlements {
    DASHBOARD_VIEW_ALL = 'Dashboard View All',
    DASHBOARD_VIEW = 'Dashboard View',
    DASHBOARD_EDIT_ALL = 'Dashboard Edit All',
    DASHBOARD_CREATE = 'Dashboard Create',
    DASHBOARD_ADD = 'Dashboard Add',
    DASHBOARD_EDIT = 'Dashboard Edit',
    DASHBOARD_SHARE = 'Dashboard Share',
    MANAGE_DASHBOARD_HS_SUPPORTED = 'Manage Dashboard HS Supported',
    MANAGE_DASHBOARD_SHARED_TAGS = 'Manage Dashboard Shared Tags',
    DASHBOARD_DELETE_HS_SUPPORTED = 'Dashboard Delete HS Supported',
    DASHBOARD_DELETE_SHARED = 'Dashboard Delete Shared',
    DASHBOARD_DELETE = 'Dashboard Delete',

    WIDGET_EDIT_ALL = 'Widget Edit All',
    WIDGET_VIEW = 'Widget View',
    WIDGET_VIEW_ALL = 'Widget View All',
    WIDGET_CREATE = 'Widget Create',
    WIDGET_EDIT = 'Widget Edit',
    WIDGET_SHARE = 'Widget Share',
    MANAGE_WIDGET_HS_SUPPORTED = 'Manage Widget HS Supported',
    MANAGE_WIDGET_SHARED_TAGS = 'Manage Widget Shared Tags',
    WIDGET_DELETE_SHARED = 'Widget Delete Shared',
    WIDGET_DELETE_HS_SUPPORTED = 'Widget Delete HS Supported',
    WIDGET_DELETE = 'Widget Delete',

    EXPORT = 'Export',

    DATASET_DEFINITION_VIEW_ALL = 'Dataset Definition View All',
    DATASET_DEFINITION_EDIT_ALL = 'Dataset Definition Edit All',
    DATASET_DEFINITION_DELETE_ALL = 'Dataset Definition Delete All',
    DATASET_DEFINITION_MANAGE = 'Dataset Definition Manage',
    DATASET_DEFINITION_COPY = 'Dataset Definition Copy',
    DATASET_DEFINITION_CREATE = 'Dataset Definition Create',
    DATASET_DEFINITION_VIEW = 'Dataset Definition View',
    DATASET_DEFINITION_EDIT = 'Dataset Definition Edit',
    DATASET_DEFINITION_SHARE = 'Dataset Definition Share',
    DATASET_DEFINITION_DELETE = 'Dataset Definition Delete',
    DATASET_DEFINITION_DELETE_SHARED = 'Dataset Definition Delete Shared',
    DATASET_DEFINITION_DELETE_HS_SUPPORTED = 'Dataset Definition Delete HS Supported',
    DATASET_DEFINITION_MANAGE_HS_SUPPORTED = 'Manage Dataset Definition HS Supported',

    DATA_TYPE_CREATE = 'Data Type Create',
    QUERY_TYPE_CREATE = 'Query Type Create',

    ADMIN_EDIT = 'DDV Admin View and Edit',
    ADMIN_READ_ONLY = 'DDV Admin Read Only',

    VIEW_AND_EDIT_ALL = 'DDV View and Edit All',

    VIEW_GROUP_MANAGE = 'Manage View Family',

    EXTERNAL_SHARE = 'External Share',

    MANAGE_CONVERSABLE_TYPE = 'Manage Conversable Type',

    ATTACHMENT_EDIT = 'Attachment Edit',
    CROSSTALK_RESTRICTED_ATTACHMENT = 'Crosstalk Restricted Attachment',

    GLOBAL_EDIT_PARTIAL = 'Global Edit Partial',

    CROSSTALK_COMMENT_IMPORT = 'Crosstalk Comment Import'
}

export enum Roles {
    /* eslint-disable @typescript-eslint/naming-convention */
    DDV_Editor = 'DDV_Editor',
    DDV_PowerUser = 'DDV_PowerUser',
    DDV_SuperUser = 'DDV_SuperUser',
    DDV_ClientBaseUser = 'DDV_ClientBaseUser',
    DDV_HSBaseUser = 'DDV_HSBaseUser'
    /* eslint-enable @typescript-eslint/naming-convention */
}
