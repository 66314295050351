import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { NumberFormat, NumberUnit } from '@ddv/models';

import { LOCALE_ID, numberFormatter, percentageFormatter } from './number-formatting';

const currencyPipe = new CurrencyPipe(LOCALE_ID);

const currencyFormatter = {
    asDisplayValue: (value: number, decimalPlaces: number | string): string | null => {
        return currencyPipe.transform(value, 'USD', 'symbol', `.${decimalPlaces}-${decimalPlaces}`);
    },
    asFilterValue: numberFormatter.asFilterValue,
};

const numberNotationMap = {
    thousands: (val: number): { suffix: string, value: number } => ({ suffix: 'K', value: val * 1e-3 }),
    millions: (val: number): { suffix: string, value: number } => ({ suffix: 'M', value: val * 1e-6 }),
    billions: (val: number): { suffix: string, value: number } => ({ suffix: 'B', value: val * 1e-9 }),
    actual: (val: number): { suffix: string, value: number } => ({ suffix: '', value: val }),
};

const numberFormatMap = {
    // eslint-disable-next-line quote-props
    '$': (value: number, decimalPlaces: number | string): string | null => currencyFormatter.asDisplayValue(value, decimalPlaces),
    '%': (value: number, decimalPlaces: number | string): string | null => percentageFormatter.asDisplayValue(value, decimalPlaces),
    /* eslint-disable @typescript-eslint/naming-convention */
    // eslint-disable-next-line quote-props
    'XX': (value: number, _: number | string): string | null => numberFormatter.asDisplayValue(value, 0),
    'XX.00': (value: number, decimalPlaces: number | string): string | null => numberFormatter.asDisplayValue(value, decimalPlaces),
    'XX.00 Trim': (value: number, decimalPlaces: number | string): string => numberFormatter.asTrimmedValue(value, decimalPlaces),
    /* eslint-enable @typescript-eslint/naming-convention */
    '': (value: number, decimalPlaces: number | string): string | null => numberFormatter.asDisplayValue(value, decimalPlaces),
};

@Injectable({ providedIn: 'root' })
export class FormatterService {
    displayFormat(
        value: string | number,
        decimalPlaces: number | string,
        numberFormat: NumberFormat | '',
        unitSuffix: string,
        useParens: boolean,
    ): string {
        const valueAsNumber = Number(value);

        const formatFunction = numberFormatMap[numberFormat];
        if (useParens && valueAsNumber < 0) {
            if (numberFormat === '%') {
                const formatted = formatFunction(Math.abs(valueAsNumber), decimalPlaces)?.replace('%', `${unitSuffix} %`);
                return `(${formatted})`;
            }
            return `(${formatFunction(Math.abs(valueAsNumber), decimalPlaces)}${unitSuffix})`;
        } else if (numberFormat === '%') {
            return `${formatFunction(valueAsNumber, decimalPlaces)?.replace('%', `${unitSuffix} %`)}`;
        } else {
            return `${formatFunction(valueAsNumber, decimalPlaces)}${unitSuffix}`;
        }
    }

    summaryFormatting(
        value: string | number,
        decimalPlaces: number | string,
        numberFormat: NumberFormat | '',
        numberUnits: NumberUnit,
        useParens: boolean,
        useRed: boolean,
    ): { isRed: boolean, summaryColumnValue: string } {
        const unit = numberNotationMap[numberUnits](Number(value));
        const formatted = this.displayFormat(unit.value, decimalPlaces, numberFormat, unit.suffix, useParens);
        return {
            summaryColumnValue: formatted,
            isRed: useRed && Number(value) < 0,
        };
    }
}
