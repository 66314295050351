export interface DatasetFetchKey {
    sourceType: 'dashboard' | 'widget';
    sourceId: number | string;
    namedQueryId: string | number;
    dashboardId: string | number;
}

export function dummyKey(): DatasetFetchKey {
    return { dashboardId: 0, sourceId: 0, sourceType: 'dashboard', namedQueryId: 0 };
}
