/* eslint-disable @typescript-eslint/no-explicit-any */
import { CellStyle } from '@ag-grid-community/core';
import { CellFormat, DdvDate, ColumnDisplayType, DateOccurring, FormatOperator } from '@ddv/models';

// Note that except for ADO for dates, all parameters will be the same type. It's just that typescript makes overloaded functions
// as types very difficult to implement.
declare function compareFunction<T = string | number | DdvDate>(
    value: T,
    from: T,
    to?: T,
    isBlank?: boolean,
    decimalPlaces?: number | string): boolean;

const DAY_OCCURRING_MAP: { [key in DateOccurring]: (d: DdvDate) => boolean } = {
    /* eslint-disable @typescript-eslint/naming-convention */
    // yesterday
    YST: (d) => d.sameAs(DdvDate.today.addDays(-1)),
    // today
    TDY: (d) => d.sameAs(DdvDate.today),
    // tomorrow
    TMW: (d) => d.sameAs(DdvDate.today.addDays(1)),
    // last seven days
    LSD: (d) => d.isBetween(DdvDate.today.addDays(-7), DdvDate.today.addDays(-1)),
    // last week
    LWK: (d) => d.isBetween(DdvDate.today.startOfWeek().addWeeks(-1), DdvDate.today.endOfWeek().addWeeks(-1)),
    // this week
    TWK: (d) => d.isBetween(DdvDate.today.startOfWeek(), DdvDate.today.endOfWeek()),
    // next week
    NWK: (d) => d.isBetween(DdvDate.today.startOfWeek().addWeeks(1), DdvDate.today.endOfWeek().addWeeks(1)),
    // we can't use moment#diff for months because the last day of a month with 31 days is less than a month away from the last day of a
    // month with 28, 29, or 30 days
    // last month
    LMT: (d) => {
        const today = DdvDate.today;
        return ((d.year === today.year && (d.month! - today.month! === -1)) ||
            ((d.year! - today.year! === -1) && (d.month! - today.month! === 11)));
    },
    // this month
    TMT: (d) => {
        const today = DdvDate.today;
        return (d.year === today.year && (d.month! - today.month! === 0));
    },
    // next month
    NMT: (d) => {
        const today = DdvDate.today;
        return ((d.year === today.year && (d.month! - today.month! === 1)) ||
            ((d.year! - today.year! === 1) && (d.month! - today.month! === -11)));
    },
};

const CONDITIONAL_OPERATORS: { [A in ColumnDisplayType]: { [B in FormatOperator]?: typeof compareFunction } } = {
    date: {
        // between
        BTW: ((cellValue: DdvDate, from: DdvDate, to: DdvDate): boolean => cellValue.isBetween(from, to)) as any,
        // not between
        NBTW: ((cellValue: DdvDate, from: DdvDate, to: DdvDate): boolean => !cellValue.isBetween(from, to)) as any,
        // equal to
        EQL: ((cellValue: DdvDate, compareValue: DdvDate): boolean => cellValue.sameAs(compareValue)) as any,
        // not equal to
        NEQL: ((cellValue: DdvDate, compareValue: DdvDate): boolean => !cellValue.sameAs(compareValue)) as any,
        // greater than
        GT: ((cellValue: DdvDate, compareValue: DdvDate): boolean => cellValue.isAfter(compareValue)) as any,
        // greater than or equal to
        GTET: ((cellValue: DdvDate, compareValue: DdvDate): boolean => !cellValue.isBefore(compareValue)) as any,
        // less than
        LT: ((cellValue: DdvDate, compareValue: DdvDate): boolean => cellValue.isBefore(compareValue)) as any,
        // less than or equal to
        LTET: ((cellValue: DdvDate, compareValue: DdvDate): boolean => !cellValue.isAfter(compareValue)) as any,
        // a date occurring
        ADO: ((cellValue: DdvDate, timeFrame: DateOccurring) => DAY_OCCURRING_MAP[timeFrame](cellValue)) as any,
    },
    value: {
        // between
        BTW: ((cellValue: number, from: number, to: number): boolean => cellValue >= from && cellValue <= to) as any,
        // not between
        NBTW: ((cellValue: number, from: number, to: number): boolean => cellValue < from || cellValue > to) as any,
        // equals
        EQL: ((cellValue: number, compareValue: number, _: number, isBlank?: boolean, decimalPlaces?: number): boolean => {
            let formattedCellValue = (cellValue === 0 && isBlank) ? '' : cellValue;
            if (typeof formattedCellValue === 'number') {
                const areAllDigitsZeros = areAllDigitsInDecimalNumberZeros(formattedCellValue, decimalPlaces ?? 0);
                formattedCellValue = areAllDigitsZeros && isBlank ? '' : formattedCellValue;
            }
            return formattedCellValue === compareValue;
        }) as any,
        // does not equal
        NEQL: ((cellValue: number, compareValue: number, _: number, isBlank?: boolean, decimalPlaces?: number): boolean => {
            let formattedCellValue = (cellValue === 0 && isBlank) ? '' : cellValue;
            if (typeof formattedCellValue === 'number') {
                const areAllDigitsZeros = areAllDigitsInDecimalNumberZeros(formattedCellValue, decimalPlaces ?? 0);
                formattedCellValue = areAllDigitsZeros && isBlank ? '' : formattedCellValue;
            }
            return formattedCellValue !== compareValue;
        }) as any,
        // greater than
        GT: ((cellValue: number, compareValue: number): boolean => cellValue > compareValue) as any,
        // greater than or equal to
        GTET: ((cellValue: number, compareValue: number): boolean => cellValue >= compareValue) as any,
        // less than
        LT: ((cellValue: number, compareValue: number): boolean => cellValue < compareValue) as any,
        // less than or equal to
        LTET: ((cellValue: number, compareValue: number): boolean => cellValue <= compareValue) as any,
    },
    // eslint-disable-next-line id-blacklist
    string: {
        // contains
        CNT: ((cellValue: string, contains: string): boolean => cellValue.toLowerCase().includes(contains.toLowerCase())) as any,
        // does not contain
        NCNT: ((cellValue: string, contains: string): boolean => !cellValue.toLowerCase().includes(contains.toLowerCase())) as any,
        // begins with
        BEG: ((cellValue: string, startsWith: string): boolean => cellValue.toLowerCase().startsWith(startsWith.toLowerCase())) as any,
        // ends with
        END: ((cellValue: string, endsWith: string): boolean => cellValue.toLowerCase().endsWith(endsWith.toLowerCase())) as any,
        // equal to
        EQL: ((cellValue: string, equalTo: string): boolean => cellValue.toLowerCase() === (equalTo.toLowerCase())) as any,
        // not equal to
        NEQL: ((cellValue: string, notEqualTo: string): boolean => cellValue.toLowerCase() !== (notEqualTo.toLowerCase())) as any,
    },
    // bar charts aren't really formatted conditionally, but they're run through this, so we have to provide a dummy implementation
    bar: {
        // auto
        AUTO: () => false,
        // percent
        PRCT: () => false,
        // number
        NUM: () => false,
    },
    // eslint-disable-next-line id-blacklist
    boolean: {},
    /* eslint-enable @typescript-eslint/naming-convention */
};

export function checkCondition(
    cellValue: string,
    displayType: ColumnDisplayType,
    format: CellFormat,
    isBlank?: boolean,
    decimalPlaces?: number | string,
): boolean {
    const condition = CONDITIONAL_OPERATORS[displayType][format.operatorCondition];

    switch (displayType) {
        case 'string':
            return !!condition?.(
                cellValue ? String(cellValue) : '',
                String(format.rangeFrom) ?
                    String(format.rangeFrom) :
                    String(format.value) ? String(format.value) : '',
                String(format.rangeTo) ? String(format.rangeTo) : '');
        case 'value':
            return !!condition?.(
                Number(cellValue),
                format.operatorCondition === 'EQL' || format.operatorCondition === 'NEQL' ?
                    format.rangeFrom != null ? Number(format.rangeFrom) : '' :
                    Number(format.rangeFrom),
                Number(format.rangeTo),
                isBlank,
                decimalPlaces);
        case 'date':
            return !!condition?.(
                DdvDate.fromDashFormat(cellValue),
                // "A day occurring" uses fuzzy-ish dates rather than real dates, so we have to treat it differently
                format.operatorCondition === 'ADO' ? format.rangeFrom : DdvDate.fromUSFormat(String(format.rangeFrom)),
                DdvDate.fromUSFormat(String(format.rangeTo)));
        default:
            return !!condition?.(cellValue, format.rangeFrom, format.rangeTo);
    }
}

export function getCellStyle(
    value: string,
    displayType?: ColumnDisplayType,
    formats?: CellFormat[],
    isBlank?: boolean,
    decimalPlaces?: number | string,
): CellStyle {
    if (!formats || !displayType) {
        return {};
    } else if (!Object.prototype.hasOwnProperty.call(CONDITIONAL_OPERATORS, displayType)) {
        throw new Error(`Invalid grid display type '${displayType}'`);
    }

    // loop through all the conditional formatting options until we find one that fits
    for (const format of formats) {
        if (!Object.prototype.hasOwnProperty.call(CONDITIONAL_OPERATORS[displayType], format.operatorCondition)) {
            throw new Error(`Invalid condition ${format.operatorCondition} for display type '${displayType}'`);
        } else {
            if (checkCondition(value, displayType, format, isBlank, decimalPlaces)) {
                return { color: format.fontColor, backgroundColor: format.cellColor };
            }
        }
    }

    return {};
}

export function areAllDigitsInDecimalNumberZeros(decimalNumber: number, decimalPlaces: number): boolean {
    const fixedDecimalNumber: string[] = decimalNumber.toFixed(decimalPlaces).split('');
    return !fixedDecimalNumber.find((el) => Number(el) && Number(el) !== 0);
}
