<div class="search-box">
    <input
        type="text"
        class="form-control"
        autocomplete="off"
        [placeholder]="quickSearchPlaceholder"
        [value]="rowSearchText"
        (input)="onInputChange($event)"/>

    <span class="icon-search1"></span>
</div>
