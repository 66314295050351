export type URLTargetAttribute = '_self' | '_blank';

// eslint-disable-next-line max-len
const URL_REGEX = /^(?:https?:\/\/)?[-a-zA-Z0-9@:%_+~#=]{2,256}\.\b(?![0-9])[a-zA-Z0-9()/]{2,256}(.\b[-a-zA-Z0-9()@:%_+~#!?&//=]{2,})*$/gi;
// RFC 2822 standard email validation
// eslint-disable-next-line max-len,no-control-regex
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

export function linkify(text: string, target: URLTargetAttribute = '_self'): string {
    const replaceStringWithURL = (textToReplace: string): string => textToReplace.replace(URL_REGEX, (match) =>
        `<a href="${match.startsWith('http') ? match : `http://${match}`}" target="${target}">${match}</a>`);
    const replaceStringWithEmail = (textToReplace: string): string => textToReplace.replace(EMAIL_REGEX, (match) =>
        `<a href="mailto:${match}">${match}</a>`);
    const modifiedString = text
        .split(' ')
        .map((partialText) => {
            let modifiedText: string | undefined;

            if (partialText.match(URL_REGEX)) {
                modifiedText = replaceStringWithURL(partialText);
            }

            if (partialText.match(EMAIL_REGEX)) {
                modifiedText = replaceStringWithEmail(partialText);
            } else if (partialText.includes('@')) {
                // catches one of the edge cases where email is not valid (according to linkify.js)
                // but the url at the end of the string is valid
                // for example: user1.@email.com
                modifiedText = partialText.split('@').map(replaceStringWithURL).join('@');
            }

            if (!modifiedText) {
                modifiedText = partialText;
            }

            return modifiedText;
        });

    return modifiedString.join(' ');
}

export function isUrlValid(url: string): boolean {
    return URL_REGEX.test(url);
}
