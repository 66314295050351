export const LINE_CHART_COLOR_FILL = 'Line Chart Color Fill';

export const enum MultiColorNames {
    GROUP_ONE = 'Group1',
    GROUP_TWO = 'Group2',
    GROUP_THREE = 'Group3',
    DIVERSE_GROUP = 'Diverse Group',
}

export const enum SolidColorNames {
    LIGHT_GREEN_FILL = 'Light Green Fill',
    LIGHT_YELLOW_FILL = 'Light Yellow Fill',
    LIGHT_ORANGE_FILL = 'Light Orange Fill',
    DARK_GRAY_FILL_WRONG = 'Dark GrayFill',
    GREEN_FILL = 'Green Fill',
    YELLOW_FILL = 'Yellow Fill',
    ORANGE_FILL = 'Orange Fill',
    DARK_GRAY_FILL = 'Dark Gray Fill',
}
