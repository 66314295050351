import { Injectable } from '@angular/core';
import { StackedAreaKey } from '@ddv/models';
import * as d3 from 'd3';

import { Dimensions } from '../models/dimensions';
import { Legend, Pagination, Rect, WrapperPos } from '../models/legend';
import { ParentScopeProperties } from '../models/parent-scope-properties';
import { Series } from '../models/series';
import { WrapperDimensions } from '../models/wrapper-dimensions';
import { safeStringFormatter } from '../safe-string-formatter';

@Injectable()
export class LegendsService {
    private dims: Dimensions | undefined;
    private legendWrapperDims: WrapperDimensions | undefined;
    private carousalDims: WrapperDimensions | undefined;
    private wrapperPos: WrapperPos | undefined;
    private data: any[] | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    private pageNo = 0;
    private parentScope: ParentScopeProperties | undefined;
    private pElem: any | undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    private paginationInfo: Pagination[] | undefined;
    private totalPages = 0;
    private legendRectWrapper: Legend | undefined;
    private legendInitialized = false;

    setDefaults(parentScopeProperties: ParentScopeProperties): void {
        this.parentScope = { ...parentScopeProperties };
        this.pElem = d3.select(this.parentScope.svg.node().parentNode);
    }

    createCustomLegends(): void {
        this.init();
        this.drawLegends(true);
        this.computeLegendDims();
    }

    setLegendsVisibility(action: 'show' | 'hide'): void {
        // for legend off configuration, pElem will not be present
        if (this.pElem?.selectAll('.legend')) {
            this.pElem.selectAll('.legend').classed('hide', action === 'hide');
        }
    }

    private init(): void {
        this.data = this.parentScope!.dataSource || this.parentScope!.config.dataSource;

        if (this.parentScope?.config?.legend?.values) {
            this.data?.forEach((field, i) => {
                const fieldValue = this.parentScope!.config.legend!.values![i];

                if (fieldValue?.showCustomName) {
                    field.displayName = fieldValue.customName;
                } else {
                    field.displayName = field.displayName || fieldValue?.displayName;
                }
            });
        }

        this.pageNo = 0;
        this.dims = {
            fontSize: 12,
            rect: this.parentScope!.config.legend!.rectSize ?? 10,
            offset: 10,
        };
        this.carousalDims = {
            width: 20,
            height: 20,
        };
        const svgRect = this.getSvgDims();
        this.legendWrapperDims = {
            height: svgRect.height - 2 * (this.carousalDims.height + (this.parentScope!.config.legend!.nav!.y as number)),
            width: svgRect.width - 2 * (this.carousalDims.width + (this.parentScope!.config.legend!.nav!.x as number)),
        };
        this.wrapperPos = this.calculateWrapperPosition();
        this.legendRectWrapper = {
            lastLegendRight: 0,
            wrapperRectRight: 0,
        };
    }

    private initEvents(legendI: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>): void {
        legendI.on('click', (event: MouseEvent, d: any) => {  // eslint-disable-line @typescript-eslint/no-explicit-any
            const gList: SVGGElement[] = legendI.nodes();
            const i = gList.indexOf(event.currentTarget as SVGGElement);
            if (this.parentScope!.clickFn) {
                this.parentScope!.clickFn(d);

                if (this.parentScope!.onChartClicked) {
                    this.parentScope!.onChartClicked(d.data ? d : { data: d }, d3.select(gList[i]).classed('enabled'));
                }
                return;
            } else {
                if (this.parentScope!.onChartClicked) {
                    this.parentScope!.onChartClicked(d.data ? d : { data: d }, d3.select(gList[i]).classed('enabled'));
                }
            }

            if (this.parentScope!.config.series[0].type === 'stacked-area') {
                this.onStackedAreaChartLegendClicked(d);
            } else {
                this.onLineChartLegendClicked(d, gList, i);
            }
        });
    }

    private onStackedAreaChartLegendClicked(d: StackedAreaKey): void {
        const gStackedArea = this.pElem.selectAll('.stacked-area');
        const selected = gStackedArea.filter((area: any) => area.key === d.key); // eslint-disable-line @typescript-eslint/no-explicit-any
        const unselected = gStackedArea.filter((area: any) => area.key !== d.key); // eslint-disable-line @typescript-eslint/no-explicit-any

        if (selected.filter('.disabled').size()) {
            selected.classed('disabled', false);
            unselected.classed('disabled', true);
            this.highlightStackedAreaLegends(d);
        } else if (unselected.filter('.disabled').size()) {
            gStackedArea.classed('disabled', false).classed('enabled', false);
            this.pElem.selectAll('.legend-items')
                .classed('disabled', false)
                .classed('enabled', false);
        } else {
            unselected.classed('disabled', true);
            this.highlightStackedAreaLegends(d);
        }
        this.parentScope!.config.hiddenLegends = this.parentScope!.svg
            .selectAll('.stacked-area.disabled')
            .data()
            .map((datum: any) => datum.key);  // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private highlightStackedAreaLegends(data: StackedAreaKey): void {
        this.pElem.selectAll('.legend-items')
            .classed('disabled', (n: { key?: string }, index: number, elem: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                elem[index].enabled = false;
                return data.key !== n.key;
            })
            .classed('enabled', (n: { key?: string }) => data.key === n.key);
    }

    private onLineChartLegendClicked(d: any, gList: any[], index: number): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        const lineElem = this.pElem.selectAll('.line');
        const selLine = lineElem.filter((el: any) => el.key === d.key); // eslint-disable-line @typescript-eslint/no-explicit-any
        const disabledLines = lineElem.filter('.hidden').size();
        if (disabledLines === 0 || (disabledLines > 0 && !selLine.classed('enabled'))) {
            lineElem.classed('hidden', true);
            selLine.classed('hidden', false).classed('enabled', true);
            d3.select(this.parentScope!.svg.node().parentNode).selectAll('.legend-items')
                .classed('disabled', (n: any) => d.key !== n.key); // eslint-disable-line @typescript-eslint/no-explicit-any
            d3.select(this.parentScope!.svg.node().parentNode).selectAll('.labels-group')
                .classed('hidden', (n: any) => d.key !== n.key); // eslint-disable-line @typescript-eslint/no-explicit-any
            d3.select(gList[index]).classed('enabled', true);
        } else {
            lineElem.classed('hidden', false);
            selLine.classed('enabled', false);
            d3.select(this.parentScope!.svg.node().parentNode).selectAll('.legend-items')
                .classed('disabled', false)
                .classed('enabled', false);
            d3.select(this.parentScope!.svg.node().parentNode).selectAll('.labels-group')
                .classed('hidden', false)
                .classed('enabled', false);
        }
        this.parentScope!.config.hiddenLegends = this.parentScope!.svg
            .selectAll('.line.hidden')
            .data()
            .map((datum: any) => datum.key); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private addLeftNav(): void {
        const lPos = this.parentScope!.config.legend!.docked!;
        const pPos = {
            top: lPos !== 'right' ? -11 : 0,
            left: 2,
        };
        const legendG: d3.Selection<d3.BaseType, unknown, HTMLElement, any> = this.pElem.select('.legend'); // eslint-disable-line @typescript-eslint/no-explicit-any
        const prevTriangle = legendG.append('g')
            .attr('class', 'prev')
            .attr('transform', `translate(${pPos.left}, ${pPos.top})`)
            .on('click', () => {
                this.pageNo = this.pageNo - 1;
                this.drawLegends(false);
            })
            .style('cursor', 'pointer');
        this.addNav(prevTriangle, !!this.parentScope?.config?.legend?.nav, lPos);
    }

    private addRightNav(textHeight: number, pos: { top: number, left: number }): void {
        const lPos = this.parentScope!.config.legend!.docked!;
        const legendG: d3.Selection<d3.BaseType, unknown, HTMLElement, any> = this.pElem.select('.legend'); // eslint-disable-line @typescript-eslint/no-explicit-any
        const nextPos = { top: 0, left: 0 };
        if (lPos === 'right') {
            nextPos.top = pos.top + textHeight - 5;
        } else {
            const rectWrapper = d3.select('.rectWrapper').node() as SVGGraphicsElement;
            nextPos.left = rectWrapper.getBoundingClientRect().width;
            nextPos.top = -10;
        }
        const nextTriangle = legendG.append('g')
            .attr('class', 'next')
            .attr('id', 'legendRightNav')
            .attr('transform', `translate(${nextPos.left}, ${nextPos.top})`)
            .on('click', () => {
                this.pageNo = this.pageNo + 1;
                this.drawLegends(false);
            })
            .style('cursor', 'pointer');
        const rectWrapper = this.pElem.select('.rectWrapper').node() as SVGGraphicsElement;
        const nPos = { top: 0, left: 0 };
        if (lPos === 'right') {
            nPos.top = pos.top + textHeight - 5;
        } else {
            nPos.left = rectWrapper.getBoundingClientRect().width;
            nPos.top = -10;
        }
        this.addNav(nextTriangle, !!this.parentScope?.config?.legend?.nav, lPos, true);
    }

    private addNav(parentElem: d3.Selection<SVGGElement, unknown, HTMLElement, any>, nav: boolean, lPos: string, isNext?: boolean): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        let dValue = null;
        if (nav) {
            if (lPos === 'right') {
                dValue = isNext ?
                    'M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z' :
                    'M7.41,15.41L12,10.83l4.59,4.58L18,14l-6-6-6,6z';
            } else {
                dValue = isNext ?
                    'M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z' :
                    'M15.41,16.59L10.83,12l4.58-4.59L14,6l-6,6l6,6L15.41,16.59z';
            }
            parentElem.append('path')
                .attr('class', 'domain')
                .attr('stroke', '#a4a3a2')
                .attr('fill', '#a4a3a2')
                .attr('d', dValue);
        }
    }

    private removeLegends(): void {
        this.pElem.selectAll('g.legend').remove();
        this.pElem.select('.rectWrapper').remove();
        this.pElem.select('.prev').remove();
        this.pElem.select('.next').remove();
    }

    private calculateWrapperPosition(): { top: number, left: number } {
        const position = {
            top: 0,
            left: 0,
        };
        const offset = 30;
        const svgBx = this.parentScope!.svg.node().getBoundingClientRect();
        const svgRect = this.getSvgDims();
        const topMargin = Math.max(svgBx.top - svgRect.top, 0);
        const dims = this.dims ?? { rect: 0, offset: 0, fontSize: 0 };
        switch (this.parentScope!.config.legend!.docked) {
            case 'right':
                position.left = this.parentScope!.config.width! * 0.8 + 10;
                break;
            case 'top':
                position.top = Math.max(
                    (Math.max(dims.rect, dims.fontSize) + 2) / 2, topMargin - offset,
                );
                break;
            case 'bottom':
                position.top = svgRect.height - offset;
                if (this.parentScope!.config.showBrush) {
                    position.top = position.top - this.parentScope!.config.margin!.top;
                }
        }
        return position;
    }

    private computeLegendDims(): void {
        const lPos = this.parentScope!.config.legend!.docked;
        const textNodes = this.pElem.selectAll('.legend-items text').nodes();
        const svgLen = (lPos === 'right' ?
            this.legendWrapperDims?.height :
            (d3.select('.rectWrapper').node() as SVGGraphicsElement).getBBox().width - 30) ?? 0;
        this.paginationInfo = [];
        this.totalPages = 1;
        let computedDim = 0;
        let pageInfo = {
            start: 0, end: 0,
        };
        this.pageNo = 1;
        const nodeLength = textNodes.length;
        textNodes.forEach((node: any, index: number) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            let tempIndex = index;
            const dims = this.dims ?? { rect: 0, offset: 0, fontSize: 0 };
            let textDim = lPos === 'right' ?
                Math.max(dims.rect, dims.fontSize) + 2 :
                (node.parentElement.getBBox().width as number) + 2;
            computedDim = computedDim + textDim;
            if (computedDim > svgLen) {
                const prevDim = computedDim - textDim;
                if (Math.ceil(svgLen - prevDim) >= 35) {
                    tempIndex = tempIndex + 1;
                    textDim = 0;
                }
                pageInfo.end = tempIndex;
                this.paginationInfo?.push(pageInfo);
                pageInfo = {
                    start: tempIndex,
                    end: 0,
                };
                if (tempIndex !== nodeLength) {
                    this.totalPages = this.totalPages + 1;
                }
                computedDim = textDim;
            }
        });
        pageInfo.end = this.data?.length ?? 0;
        this.paginationInfo.push(pageInfo);
        this.drawLegends(false);
        if (!this.legendInitialized) {
            this.legendInitialized = true;
        }
    }

    private drawLegends(isInit: boolean): void {
        if (!isInit) {
            this.removeLegends();
            this.initWrapperPos();
        }

        const svgRect = this.getSvgDims();
        const lPos = this.parentScope!.config.legend!.docked!;
        const legendCircle = this.parentScope!.config.legend!.type === 'circle';
        const dims = this.dims ?? { rect: 0, offset: 0, fontSize: 0 };
        const textHeight = Math.max(dims.rect, dims.fontSize) + 2;
        const legendG: d3.Selection<d3.BaseType, unknown, HTMLElement, any> = this.pElem // eslint-disable-line @typescript-eslint/no-explicit-any
            .append('g')
            .attr('class', 'legend')
            .attr('transform', `translate(${this.wrapperPos?.left},${this.wrapperPos?.top})`);
        const pos = this.wrapperPos ? { ...this.wrapperPos } : { top: 0, left: 0 };

        // LEFT PAGING ADDED
        if (isInit || this.pageNo > 1) {
            this.addLeftNav();
        }

        if (lPos !== 'right') {
            this.pElem.append('rect')
                .attr('width', svgRect.width - 10)
                .attr('height', dims.rect)
                .attr('fill', 'transparent')
                .attr('class', lPos === 'top' ? 'rectWrapper rectWrapperTop' : 'rectWrapper');
        }

        const dataset = this.getSortedDataset(isInit);

        const legendI = this.createLegendItems(legendG, dataset);

        this.setLegendItemAttributes(legendI, svgRect, dims, lPos, legendCircle);

        this.handleLegendItemTransformations(legendI, lPos, dims, textHeight, pos);

        if (!isInit) {
            this.initEvents(legendI);
            this.disableLegends(legendI);
        }

        // RIGHT PAGING ADDED
        if (isInit || this.pageNo < this.totalPages) {
            this.addRightNav(textHeight, pos);
        }

        this.updateWrapperPos(isInit);

        this.animateLegends(() => {
            if (!isInit) {
                this.dotme(legendI.select('text'), lPos);
            }
        });

        this.handleHiddenLegends();
    }

    private getSortedDataset(isInit: boolean): any[] { // eslint-disable-line @typescript-eslint/no-explicit-any
        const dataset = isInit ? [...this.data ?? []] : [...this.getDataset(this.pageNo)];
        const formatter = this.parentScope!.config?.formatter;
        if (!formatter?.sortDirection || formatter.sortDirection !== 'CUSTOM') {
            dataset.sort((a, b) => a.key < b.key ? -1 : a.key > b.key ? 1 : 0);
        }
        return dataset;
    }

    private createLegendItems(
        legendG: d3.Selection<d3.BaseType, unknown, HTMLElement, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
        dataset: any[], // eslint-disable-line @typescript-eslint/no-explicit-any
    ): d3.Selection<SVGGElement, unknown, d3.BaseType, unknown> {
        return legendG.selectAll('.legend-items')
            .data(this.parentScope!.pie ? this.parentScope!.pie(dataset) : dataset)
            .enter()
            .append('g')
            .attr('class', 'legend-items')
            .attr('data-legend', (d: any) => d.key); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private setLegendItemAttributes(
        legendI: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>,
        svgRect: Rect,
        dims: Dimensions,
        lPos: string,
        legendCircle: boolean,
    ): void {
        if (lPos === 'right') {
            legendI.append('rect')
                .attr('width', svgRect.width * this.parentScope!.config.legend!.width!)
                .attr('height', dims.rect)
                .attr('fill', 'transparent');
        }

        legendI.append('svg:title').text((d: any) => d.data ? (d.data.key || 'Blanks') : (d.displayName || d.key)); // eslint-disable-line @typescript-eslint/no-explicit-any

        if (legendCircle) {
            legendI.append('circle')
                .attr('r', dims.rect / 2)
                .attr('class', (d: any) => safeStringFormatter(d).replace(' ', '')) // eslint-disable-line @typescript-eslint/no-explicit-any
                .attr('data-legend', (d: any) => d.key) // eslint-disable-line @typescript-eslint/no-explicit-any
                .attr('fill', (d: any) => d.data ? d.data.color : d.color); // eslint-disable-line @typescript-eslint/no-explicit-any
        } else {
            legendI.append('rect')
                .attr('width', dims.rect)
                .attr('height', dims.rect)
                .attr('class', (d: any) => safeStringFormatter(d).replace(' ', '')) // eslint-disable-line @typescript-eslint/no-explicit-any
                .attr('data-legend', (d: any) => d.key) // eslint-disable-line @typescript-eslint/no-explicit-any
                .attr('fill', (d: any) => d.data ? d.data.color : d.color); // eslint-disable-line @typescript-eslint/no-explicit-any
        }

        legendI.append('text')
            .text((d: any) => {  // eslint-disable-line @typescript-eslint/no-explicit-any
                if (d.data && typeof d.data.key !== 'undefined' && d.data.key != null) {
                    return d.data.key.toString().trim() ? safeStringFormatter(d.data.key) : 'Blanks';
                }
                return d.displayName || (d.key == null ? 'Null' : d.key.toString().trim() ? this.getSafeName(d) : 'Blanks');
            })
            .style('font-size', dims.fontSize)
            .attr('y', (legendCircle ? dims.rect / 2 : dims.rect))
            .attr('x', (legendCircle ? dims.rect : dims.rect + 10));
    }

    private handleLegendItemTransformations(
        legendI: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>,
        lPos: string,
        dims: Dimensions,
        textHeight: number,
        pos: { top: number, left: number },
    ): void {
        let textOffset = 0;
        const textNodes = legendI.select('text').nodes();

        legendI.attr('transform', (d: any, i: number) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            if (lPos === 'right') {
                textOffset += (i === 0) ? (this.carousalDims?.height ?? 0) : textHeight;
                pos.top = textOffset;
                return `translate(0, ${pos.top})`;
            }
            textOffset +=
                i === 0
                    ? (this.carousalDims?.width ?? 0) + 8
                    : ((d3.select(textNodes[i - 1]).node() as SVGGraphicsElement).parentNode! as SVGGraphicsElement).getBoundingClientRect()
                        .width + 10;
            pos.left = textOffset;
            return `translate(${pos.left}, 0)`;
        });
    }

    private handleHiddenLegends(): void {
        if (this.parentScope!.config.hiddenLegends) {
            this.pElem.selectAll('.legend-items').classed('enabled', true);
            this.parentScope!.config.hiddenLegends.forEach((legend: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                this.pElem.selectAll('.legend-items').filter((el: any) => el.key === legend) // eslint-disable-line @typescript-eslint/no-explicit-any
                    .classed('disabled', true)
                    .classed('enabled', false);
                this.pElem.selectAll('.line').filter((el: any) => el.key === legend) // eslint-disable-line @typescript-eslint/no-explicit-any
                    .classed('hidden', true);
                this.pElem.selectAll('.labels-group').filter((el: any) => el.key === legend) // eslint-disable-line @typescript-eslint/no-explicit-any
                    .classed('hidden', true);
            });
        }
    }

    private getDataset(pageNo: number): { key: string, value: any }[] { // eslint-disable-line @typescript-eslint/no-explicit-any
        const pageInfo = this.paginationInfo?.length ? this.paginationInfo[pageNo - 1] : { start: 0, end: this.data?.length ?? 0 };
        return [...(this.data ?? [])].slice(pageInfo.start, pageInfo.end);
    }

    private initWrapperPos(): void {
        const svgRect = this.getSvgDims();
        this.wrapperPos = this.wrapperPos ?? { top: 0, left: 0 };

        if (this.parentScope!.config.legend!.docked === 'right') {
            this.wrapperPos.top = svgRect.top;
        } else {
            this.wrapperPos.left = svgRect.right;
            if (this.parentScope!.config.legend!.docked === 'top') {
                this.wrapperPos.top = 10;
            }
        }
    }

    private updateWrapperPos(isInit: boolean): void {
        const lRect = this.pElem.selectAll('.legend').node().getBBox();
        const svgRect = this.getSvgDims();

        this.wrapperPos = this.wrapperPos ?? { top: 0, left: 0 };
        this.legendRectWrapper = this.legendRectWrapper ?? { wrapperRectRight: 0, lastLegendRight: 0 };

        if (this.parentScope!.config.legend!.docked === 'right') {
            this.wrapperPos.top = (svgRect.height - lRect.height) / 2;
        } else {
            if (isInit) {
                const lastLegend = this.pElem.selectAll('.legend-items text').nodes();
                this.legendRectWrapper.lastLegendRight = lastLegend[lastLegend.length - 1].getBoundingClientRect().right;
                this.legendRectWrapper.wrapperRectRight = this.pElem.selectAll('.rectWrapper').node().getBoundingClientRect().right;
            }
            if (this.legendRectWrapper.lastLegendRight > this.legendRectWrapper.wrapperRectRight) {
                this.wrapperPos.left = -10;
            } else {
                this.wrapperPos.left = Math.ceil(this.legendRectWrapper.wrapperRectRight - this.legendRectWrapper.lastLegendRight);
            }
        }
    }

    private animateLegends(cb: () => void): void {
        let legend = this.pElem.selectAll('.legend');

        if (!this.legendInitialized) {
            legend = legend.transition().duration(300).delay(0);
        }

        legend.attr('transform', `translate(${this.wrapperPos?.left},${this.wrapperPos?.top})`).on('end', cb);
    }

    private dotme(textGp: any, lPos: string): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        const svgRect = (lPos === 'right') ? this.getSvgDims() : this.pElem.select('.rectWrapper')?.node()?.getBoundingClientRect();

        // this should never happen, but...
        // it is happening in tests, only in FF, only when running the whole batch
        // it is impossible to trace
        if (!svgRect) {
            return;
        }

        let visChars: number | undefined;
        if (!this.pElem.select('#legendRightNav').node() && lPos !== 'right') {
            return;
        }
        textGp.each(function (this: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
            const txtNode = d3.select(this); // eslint-disable-line no-invalid-this
            const txtRect = txtNode.node() ? txtNode.node().getBoundingClientRect() : { right: 0, width: 0 };
            const text = txtNode.text();
            if (txtRect.right > svgRect.right) {
                if (!visChars) {
                    visChars = text.length - Math.ceil((txtRect.right - svgRect.right) / (Math.floor(txtRect.width / text.length)));
                }
                txtNode.text(text.substring(0, visChars - 3));
                txtNode.append('tspan').attr('class', 'elip').text('...');
            } else if (text.length > 30) {
                txtNode.text(text.substring(0, 30));
                txtNode.append('tspan').attr('class', 'elip').text('...');
            }
        });
        if (visChars && visChars <= 3 && lPos === 'right') {
            this.setLegendsVisibility('hide');
        }
    }

    private disableLegends(legendI: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>): void {
        const series = this.parentScope!.config.series[0];
        const chartType = series.type;

        if (chartType === 'pie' || chartType === 'donut') {
            this.disableCircleChartLegends(legendI);
        }

        if (chartType === 'bar') {
            this.disableBarChartLegends(legendI, series);
        }

        if (chartType === 'stacked-area') {
            this.disableStackedAreaChartLegends(legendI);
        }
    }

    private disableCircleChartLegends(legendI: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>): void {
        const circleNodeData = this.parentScope!.svg
            .selectAll('.arc')
            .select('path')
            .filter('.disabled')
            .data();
        legendI.classed('disabled', (d: any) => circleNodeData.filter((n: any) => n.data.key === d.data.key).length); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private disableBarChartLegends(legendI: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>, series: Series): void {
        const key = series.horizontal ? series.yField[0] : series.xField[0];
        const disabledData = this.parentScope!.svg.selectAll('.bar').filter('.disabled').data();
        if (disabledData?.length) {
            legendI.classed('disabled', (d: any, index: number, elem: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                const legendValue = this.getSafeName(d, key, '');
                const filteredData = disabledData.filter((n: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
                    const disabledValue = this.getSafeName(n, key, '');
                    if (disabledValue && legendValue) {
                        return disabledValue.replace(/[^\w]/g, '') === legendValue.replace(/[^\w]/g, '');
                    }
                    return disabledValue === legendValue;
                });
                if (filteredData.length === 0) {
                    elem[index].enabled = false;
                }
                return filteredData.length;
            });
        }
    }

    private disableStackedAreaChartLegends(legendI: d3.Selection<SVGGElement, unknown, d3.BaseType, unknown>): void {
        const stackedAreaNodeData = this.parentScope!.svg
            .selectAll('.areas')
            .select('path')
            .filter('.disabled')
            .data();
        legendI.classed('disabled', (d: any) => stackedAreaNodeData.filter((n: any) => n.key === d.key).length); // eslint-disable-line @typescript-eslint/no-explicit-any
    }

    private getSvgDims(): Rect {
        return (d3.select(this.parentScope!.config.selector)?.select('svg')?.node() as SVGGraphicsElement)?.getBoundingClientRect() ??
            {
                height: 0,
                width: 0,
                top: 0,
                bottom: 0,
            };
    }

    private getSafeName(hash: any, propertyName?: string, defaultName = 'N/A'): string { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (safeStringFormatter(hash.key)) {
            return safeStringFormatter(hash.key);
        } else if (propertyName && Object.prototype.hasOwnProperty.call(hash, propertyName) && safeStringFormatter(hash[propertyName])) {
            return safeStringFormatter(hash[propertyName]);
        } else {
            return defaultName;
        }
    }
}
