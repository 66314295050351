<h2 class="title-modal">WIDGET FILTER</h2>
<div>
    <app-dashboard-filters #filtersComponent
        [clientCode]="clientCode ?? ''"
        [isMultiClient]="isMultiClient"
        [isQueryTypeRecon]="isQueryTypeRecon()"
        [reducer]="reducer"
        [filterType]="filterType"
        [widgetId]="widgetId ?? 0"
        [isSubscribedToDashboardFilters]="isSubscribedToDashboardFilters"
        (fundsApply)="onApplySelectedFunds($event)"
        (clientsApply)="onApplySelectedClients($event)"
        (dateFilterApply)="onApplyDateChanges($event)"
        (restoreBtnClicked)="onRestoreCurrentWidget()"
        (refreshBtnClicked)="onRefreshCurrentWidget()"
        (filterApply)="onDbFiltersApplied($event)"
        (clearHighlight)="onClearHighlight()">
    </app-dashboard-filters>
</div>
