import { Injectable } from '@angular/core';
import { CurrentStateService } from '@ddv/behaviors';
import { FuzzyDate as FuzzyDateCC, FuzzyDatesService as FuzzyDatesServiceCC } from '@hs/ui-core-date';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FuzzyDatesServiceV2 {
    private fuzzyDates$: Observable<FuzzyDateCC[]> | undefined;
    private clientCode = '';

    constructor(
        private readonly currentState: CurrentStateService,
        private readonly fuzzyDatesServiceCC: FuzzyDatesServiceCC,
    ) {
        this.currentState.clientCode$.subscribe((clientCode) => this.clientCode = clientCode);
    }

    getFuzzyDatesCC(): Observable<FuzzyDateCC[]> {
        if (!this.fuzzyDates$) {
            this.fuzzyDates$ = this.fuzzyDatesServiceCC.loadFuzzyDates(this.clientCode)
                .pipe(shareReplay(1));
        }
        return this.fuzzyDates$;
    }
}

