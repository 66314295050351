import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '@ddv/entitlements';
import { UserPreferences } from '@ddv/models';

import { MultiSubscriptionComponent } from '../multi-subscription-component';
import { AlertService } from './alert.service';
import { Alert, AlertType } from './model';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent extends MultiSubscriptionComponent implements OnInit {
    alert: Alert | undefined;
    showAlert = false;
    userPreference: UserPreferences | undefined;
    defaultAutoDismissTimeout = 3000;
    showDetails = false;
    hasDetails = false;

    constructor(
        private readonly alertService: AlertService,
        private readonly cdr: ChangeDetectorRef,
        private readonly userService: UserService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeTo(this.userService.userPreferences$, (userPreferences: UserPreferences) => {
            this.userPreference = userPreferences;

            this.alertService
                .getAlert()
                .subscribe((alert: Alert | undefined) => {
                    this.alert = alert;
                    this.hasDetails = !!alert?.rootCauseMessage;
                    this.showAlert = true;
                    this.cdr.detectChanges();

                    if (this.userPreference?.autoDismissErrors) {
                        this.removeAlertAfter(this.defaultAutoDismissTimeout);
                    }
                });
        });
    }

    removeAlert(): void {
        this.showAlert = false;
        this.showDetails = false;
        this.hasDetails = false;
        this.cdr.detectChanges();
    }

    removeAlertAfter(timeout: number): void {
        setTimeout(() => {
            this.removeAlert();
        }, timeout);
    }

    cssClass(alert?: Alert): { cssClass?: string, message?: string } {
        if (!alert) {
            return {};
        }

        switch (alert.type) {
            case AlertType.Success:
                return { cssClass: 'icon-notification-success', message: 'success' };
            case AlertType.Error:
                return { cssClass: 'icon-notification-error', message: 'error' };
            case AlertType.Info:
                return { cssClass: 'icon-notification-info', message: 'info' };
            case AlertType.Warning:
                return { cssClass: 'icon-notification-warning', message: 'warning' };
            case AlertType.Message:
                return { cssClass: 'icon-notification_message', message: 'message' };
        }

        return {};
    }
}
