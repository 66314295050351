export class Alert {
    type?: AlertType;
    message?: string;
    rootCauseMessage?: string;
    rootCauseStacktrace?: string[];
    requestId?: string;
    cssClass?: string;
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning,
    Message
}
